import {
  DeclarationStatus,
  IE3Transhipment,
  IE3TranshipmentSummary
} from "@portbase/bezoekschip-service-typescriptmodels";
import {cloneDeep} from "lodash";

export const transhipment = {
  "consignmentNumber": "MS-LISA-001",
  "dischargeTerminal": {
    "terminalName": "AMALIAH APMT DSQ",
    "terminalCode": "4254",
    "organisationShortName": "APMII",
    "organisationName": "APM Terminals Maasvlakte II B.V.",
    "deprecatedSwLocationCode": false
  },
  "placeOfDestination": {
    "name": "Belvedere",
    "locationUnCode": "USVD7",
    "countryUnCode": "US",
    "euLocation": false
  },
  "consignmentDeclarant": {
    "fullName": "Portbase B.V.",
    "shortName": "PORTINFOLINK",
    "iamConnectedId": "7f39cd62-200b-11ea-b1d6-bdcb1c2e96a0",
    "portAuthorityId": null,
    "emailAddress": "interface1.specialist@portbase.com",
    "address": "Blaak 16",
    "city": "Rotterdam",
    "zipCode": "3011 TA",
    "countryUnCode": "NL",
    "countryName": "Netherlands",
    "phoneNumber": "+31-10-2522222",
    "faxNumber": "+31-10-2522250",
    "contact": "Interface Specialist",
    "arrivalLocationHouseNumber": null,
    "customsZipCode": null,
    "customsEORINumber": "NL100000897",
    "ean": "123456780",
    "chamberOfCommerceNumber": "24338021",
    "scacCode": "sdgs",
    "ensEnabled": false,
    "cargoImportEnabled": true,
    "cargoImportEnabledByDefault": true
  },
  "transhipmentDeclarant": {
    "fullName": "Portbase B.V.",
    "shortName": "PORTINFOLINK",
    "iamConnectedId": "7f39cd62-200b-11ea-b1d6-bdcb1c2e96a0",
    "portAuthorityId": null,
    "emailAddress": "interface1.specialist@portbase.com",
    "address": "Blaak 16",
    "city": "Rotterdam",
    "zipCode": "3011 TA",
    "countryUnCode": "NL",
    "countryName": "Netherlands",
    "phoneNumber": "+31-10-2522222",
    "faxNumber": "+31-10-2522250",
    "contact": "Interface Specialist",
    "arrivalLocationHouseNumber": null,
    "customsZipCode": null,
    "customsEORINumber": "NL100000897",
    "ean": "123456780",
    "chamberOfCommerceNumber": "24338021",
    "scacCode": "sdgs",
    "ensEnabled": false,
    "cargoImportEnabled": true,
    "cargoImportEnabledByDefault": true
  },
  "declarationTime": "2023-06-30T12:52:59.018049580Z",
  "processStartTime": "2023-06-29T07:37:13.198599755Z",
  "renStatus": null,
  "midStatus": null,
  "cancelled": false,
  "mrn": "23NL000000004D38D3",
  "carrier": {
    "customsId": "NL100004064",
    "name": "MSC , S.A.",
    "scacCode": "MSCU",
    "smdgCode": "MSC",
    "cargoDeclarantShortName": "MSCRTM",
    "boxOperators": [
      "MSCRTM"
    ]
  },
  "items": [
    {
      "goodsItem": {
        "itemNumber": 1,
        "description": "tafels",
        "grossWeight": 1243,
        "numberOfOuterPackages": 2,
        "outerPackageType": {
          "code": "CE",
          "name": "Creel",
          "bulk": false
        },
        "netWeight": null,
        "numberOfInnerPackages": null,
        "innerPackageType": null,
        "classification": null,
        "dangerInformation": null,
        "marksAndNumbers": [],
        "minimumTemperature": null,
        "maximumTemperature": null,
        "placements": [
          {
            "equipmentNumber": "CONT-LISA-001",
            "numberOfPackages": 2,
            "grossWeight": 1234
          }
        ],
        "producedDocuments": []
      },
      "writeOff": {
        "grossWeight": 1243,
        "numberOfOuterPackages": 2,
        "outerPackageType": {
          "code": "CE",
          "name": "Creel",
          "bulk": false
        },
        "numberOfInnerPackages": null,
        "innerPackageType": null
      }
    }
  ],
  "bulk": false
};

export function transhipmentToSummary(transhipment: IE3Transhipment): IE3TranshipmentSummary {
  return {
    crn: null,
    etaFirstBerth: "2023-05-30T00:00:00Z",
    ataFirstBerth: "2023-05-29T21:59:59Z",
    declarantShortName: transhipment.transhipmentDeclarant.shortName,
    declarationTime: transhipment.declarationTime,
    midStatus: transhipment.midStatus,
    renStatus: transhipment.renStatus,
    processStartTime: transhipment.processStartTime,
    cancelled: transhipment.cancelled
  }
}

function declareRen(transhipment: IE3Transhipment) {
  const t = cloneDeep(transhipment);
  t.renStatus = {};
  t.renStatus.status = DeclarationStatus.DECLARED;
  return t;
}

function acceptRen(transhipment: IE3Transhipment) {
  const t = cloneDeep(transhipment);
  t.renStatus = {};
  t.renStatus.status = DeclarationStatus.ACCEPTED;
  t.renStatus.reason = "Accepted from customs";
  return t;
}

function rejectRen(transhipment: IE3Transhipment) {
  const t = cloneDeep(transhipment);
  t.renStatus = {};
  t.renStatus.status = DeclarationStatus.REJECTED;
  t.renStatus.reason = "Terminal does not support this type of cargo!";
  return t;
}

function declareMid(transhipment: IE3Transhipment) {
  const t = cloneDeep(transhipment);
  t.midStatus = {};
  t.midStatus.status = DeclarationStatus.DECLARED;
  t.midStatus.sent = true;
  return t;
}

function acceptMid(transhipment: IE3Transhipment) {
  const t = cloneDeep(transhipment);
  t.midStatus = {};
  t.midStatus.status = DeclarationStatus.ACCEPTED;
  t.midStatus.reason = "Accepted from customs";
  t.midStatus.sent = true;
  return t;
}

function rejectMid(transhipment: IE3Transhipment) {
  const t = cloneDeep(transhipment);
  t.midStatus = {};
  t.midStatus.status = DeclarationStatus.REJECTED;
  t.midStatus.reason = "Terminal does not support this type of cargo!";
  t.midStatus.sent = true;
  return t;
}

function cancel(transhipment: IE3Transhipment) {
  const t = cloneDeep(transhipment);
  t.cancelled = true;
  return t;
}

export const TranshipmentMock = [
  transhipment,
  declareMid(transhipment),
  declareRen(transhipment),
  acceptRen(transhipment),
  acceptMid(transhipment),
  rejectRen(transhipment),
  rejectMid(transhipment),
  cancel(transhipment),
  declareMid(declareRen(transhipment)),
  acceptRen(acceptMid(transhipment)),
  {
    "consignmentNumber": "NIET-MOKKE-LEKKER-WOKKE",
    "dischargeTerminal": {
      "terminalName": "NIJLH GATE STG 1",
      "terminalCode": "39486982"
    },
    "placeOfDestination": {
      "name": "Akkarfjordneringen Losbordingspunkt",
      "locationUnCode": "NOZAK"
    },
    "transhipmentDeclarant": {
      "shortName": "CROSSOCEANRTM"
    },
    "declarationTime": "2023-07-06T13:37:00Z",
    "processStartTime": "2023-07-06T13:37:59Z",
    "renStatus": {
      "status": "ACCEPTED"
    },
    "midStatus": {
      "status": "ACCEPTED",
      "sent": true
    },
    "cancelled": false,
    "mrn": "Trans Ship Ren Ren",
    "carrier": {
      "name": "Cross-Ocean B.V."
    },
    "items": []
  }
]
