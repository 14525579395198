import {Component, Input} from '@angular/core';
import {AppContext} from '../../../../../app-context';
import {VisitContext} from '../../../../visit-context';
import {
  AcceptCargoImport,
  AcceptEns,
  Declaration,
  DeclarationType,
  RejectCargoImport,
  RejectEns
} from '@portbase/bezoekschip-service-typescriptmodels';
import {InjectorProvider} from '../../../../../common/injector-provider';
import {CommandGateway} from '../../../../../common/command-gateway';
import {Manifest} from '../../../cargo-import.model';
import {DeclarationUtils} from "../../../../../refdata/declaration-utils";
import {combineLatest, Observable, of} from "rxjs";
import moment from "moment";
import {flatten} from "lodash";
import {PortvisitUtils} from '../../../../../refdata/portvisit-utils';

@Component({
  selector: 'app-import-declaration-admin',
  templateUrl: './import-manifest-admin.component.html',
  styleUrls: ['./import-manifest-admin.component.css']
})
export class ImportManifestAdminComponent {
  appContext = AppContext;
  context = VisitContext;

  manifests: Manifest[];
  lastSDTs: Declaration[];
  lastENSs: Declaration[];

  @Input() set setManifests(manifests: Manifest[]) {
    this.manifests = manifests;
    this.lastSDTs = [].concat.apply([], manifests
      .filter(manifest => DeclarationUtils.getLastDeclarations(manifest.declarations, [DeclarationType.ENS])
        .every(ens => ens.status === 'ACCEPTED'))
      .map(manifest => DeclarationUtils.getLastDeclarations(manifest.declarations, [DeclarationType.SDT])));
    this.lastENSs = [].concat.apply([], manifests
      .filter(manifest => DeclarationUtils.getLastDeclarations(manifest.declarations, [DeclarationType.ENS])
        .every(ens => ens.status === 'DECLARED' || ens.status === 'REJECTED'))
      .map(manifest => DeclarationUtils.getLastDeclarations(manifest.declarations, [DeclarationType.ENS])));
  }

  openMessages(declaration: Declaration) {
    if (declaration) {
      let index = VisitContext.cargoImportModel.declarations.indexOf(declaration)
      if (index === -1) {
        AppContext.registerError('Failed to find declaration in saved model.')
        return;
      }
      PortvisitUtils.redirectToMessageManagement(this.context.savedVisit.crn + declaration.type
        + moment(declaration.timeStamp).format('DDHHmmssSSS') + index);
    }
    setTimeout(() => this.declaration = null, 0)
  }

  searchMessages = term => {
    return of(flatten(this.manifests.map(m => this.getFirstDeclarations(m)))
      .filter(v => v.id.toUpperCase().indexOf(term.toUpperCase()) > -1 || v.type.indexOf(term.toUpperCase()) > -1));
  };

  formatDeclaration = (declaration: Declaration) => {
    return declaration.type + ' of ' + declaration.id + ' to Singlewindow';
  }
  declaration: Declaration;


  getFirstDeclarations(manifest: Manifest) {
    return DeclarationUtils.getFirstDeclarations(manifest.declarations, [DeclarationType.SDT, DeclarationType.ENS],
      d => "Automatic reject by portbase" === d.rejectReasons);
  }

  rejectAllowed() {
    return !AppContext.isProduction() && this.lastSDTs.some(d => d && d.status === 'DECLARED');
  }

  acceptAllowed() {
    return !AppContext.isProduction() && this.lastSDTs.some(d => d && (d.status === 'DECLARED' || d.status === 'REJECTED'));
  }

  reject(reason: string) {
    AppContext.clearVisitAlerts();
    const results = this.lastSDTs.filter(d => d && d.status === 'DECLARED')
      .map(d => InjectorProvider.injector.get(CommandGateway).send(
        'com.portbase.bezoekschip.common.api.cargo.RejectCargoImport', <RejectCargoImport>{
          crn: this.context.visit.crn,
          cargoDeclarantShortName: this.context.cargoImportModel.cargoDeclarant.shortName,
          portOfLoadingUnCode: d.id,
          reason: reason
        }));
    const onComplete: Observable<any> = results.length > 0 ? combineLatest(results) : of(null);
    onComplete.subscribe(() => AppContext.registerSuccess('The import declarations were rejected successfully'));
  }

  accept() {
    AppContext.clearVisitAlerts();
    const results = this.lastSDTs.filter(d => d && d.status === 'DECLARED' || d.status === 'REJECTED')
      .map(d => InjectorProvider.injector.get(CommandGateway).send(
        'com.portbase.bezoekschip.common.api.cargo.AcceptCargoImport', <AcceptCargoImport>{
          crn: this.context.visit.crn,
          cargoDeclarantShortName: this.context.cargoImportModel.cargoDeclarant.shortName,
          portOfLoadingUnCode: d.id,
        }));
    const onComplete: Observable<any> = results.length > 0 ? combineLatest(results) : of(null);
    onComplete.subscribe(() => AppContext.registerSuccess('The import declarations were accepted successfully'));
  }

  rejectEnsAllowed() {
    return !AppContext.isProduction() && this.lastENSs.some(d => d && d.status === 'DECLARED');
  }

  acceptEnsAllowed() {
    return !AppContext.isProduction() && this.lastENSs.some(d => d && (d.status === 'DECLARED' || d.status === 'REJECTED'));
  }

  rejectEns(reason: string) {
    AppContext.clearVisitAlerts();
    const results = this.lastENSs.filter(d => d && d.status === 'DECLARED')
      .map(d => InjectorProvider.injector.get(CommandGateway).send(
        'com.portbase.bezoekschip.common.api.cargo.RejectEns', <RejectEns>{
          crn: this.context.visit.crn,
          cargoDeclarantShortName: this.context.cargoImportModel.cargoDeclarant.shortName,
          consignmentNumber: d.id,
          reason: reason
        }));
    const onComplete: Observable<any> = results.length > 0 ? combineLatest(results) : of(null);
    onComplete.subscribe(() => AppContext.registerSuccess('The ENS declarations were rejected successfully'));
  }

  acceptEns(movementReferenceNumber: string) {
    AppContext.clearVisitAlerts();
    const results = this.lastENSs.filter(d => d && d.status === 'DECLARED' || d.status === 'REJECTED')
      .map(d => InjectorProvider.injector.get(CommandGateway).send(
        'com.portbase.bezoekschip.common.api.cargo.AcceptEns', <AcceptEns>{
          crn: this.context.visit.crn,
          cargoDeclarantShortName: this.context.cargoImportModel.cargoDeclarant.shortName,
          movementReferenceNumber: movementReferenceNumber,
          consignmentNumber: d.id,
        }));
    const onComplete: Observable<any> = results.length > 0 ? combineLatest(results) : of(null);
    onComplete.subscribe(() => AppContext.registerSuccess('The ENS declarations were accepted successfully'));
  }
}
