import {Component, Input, TemplateRef} from '@angular/core';

@Component({
  selector: 'modal-error-autofocus',
  templateUrl: './modal-error.component.html',
  styleUrls: ['./modal-error.component.scss']
})
export class ModalErrorAutofocus {
  @Input() data: ModalErrorAutofocusData;
  callback: (boolean, string?) => any;

  get title() {
    return this.data?.title || "Error";
  }

  get points() {
    return this.data?.points || [];
  }

  get confirmText() {
    return this.data?.confirmText || "Ok";
  }

}

export interface ModalErrorAutofocusData {
  type: string;
  title: string;
  modalSize: null | 'sm' | 'lg' | 'xl';
  message: string;
  beforePointsMessage: string;
  points: string[];
  afterPointsMessage: string;
  question: string;
  confirmText: string;
  cancelText: string;
  withReason?: boolean;
  withChosableReason?: boolean;
  chosableMessage: string;
  chosableReasons: string[];
  chosableReasonFormatter: any;
  reason?: string;
  body: TemplateRef<any>;
}
