<div class="row">
  <div class="d-flex" [ngClass]="editMode ? 'col-4' : 'col-3'">
    <div class="d-flex align-items-center border border-primary rounded-circle me-3"
         style="width: 1.5rem; height: 1.5rem;" [style.margin-top]="editMode ? '1.5rem' : '1rem'">
      <span class="text-primary text-center w-100">{{index + 1}}</span>
    </div>
    <div class="d-flex align-items-stretch flex-column">
      <app-form-field label="Country" [includeMargin]="false" [value]="routeInfo.country?.name" [editMode]="editMode">
        <app-search required [(ngModel)]="routeInfo.country" [searchFunction]="portVisitUtils.findCountries"
                    [resultFormatter]="portVisitUtils.countryFormatter" [inputFormatter]="countryFormatter"
                    (ngModelChange)="routeInfo.country = $event; refreshRoutes();">
        </app-search>
      </app-form-field>
    </div>
  </div>
  <ng-container *ngIf="editMode else displayMode">
    <div class="col-auto d-flex align-items-center" style="padding-top: 2em;">
      <button class="btn rounded-circle p-1 px-2 text-primary h1 mb-0"
              [disabled]="index === 0" (click)="moveUp()">
        <i class="fa-light fa-angle-up"></i>
      </button>
    </div>
    <div class="col-auto d-flex align-items-center" style="padding-top: 2em;">
      <button class="btn rounded-circle p-1 px-2 text-primary h1 mb-0"
              [disabled]="index + 1 === allRoutes.length" (click)="moveDown()">
        <i class="fa-light fa-angle-down"></i>
      </button>
    </div>
    <div *ngIf="allRoutes.length > 2" class="col-auto d-flex align-items-center" style="padding-top: 2em;">
      <button class="btn rounded-circle p-1 px-2 text-danger h1 mb-0" (click)="routeDeleted()">
        <i class="fa-light fa-trash-can"></i>
      </button>
    </div>
  </ng-container>
  <ng-template #displayMode>
    <div [ngClass]="editMode ? 'col-8' : 'col-9'">
      <div class="row gy-3">
        <app-form-field class="col-6 col-md-4" *ngIf="routeInfo.placeOfLoading" label="Port of loading" [includeMargin]="false"
                        [value]="getPlaceLabel(routeInfo.placeOfLoading)" [editMode]="false">
        </app-form-field>
        <app-form-field class="col-6 col-md-4" *ngIf="routeInfo.placeOfUnloading" label="Port of discharge" [includeMargin]="false"
                        [value]="getPlaceLabel(routeInfo.placeOfUnloading)" [editMode]="false">
        </app-form-field>
        <app-form-field class="col-6 col-md-4" *ngIf="routeInfo.placeOfAcceptance" label="Place of acceptance" [includeMargin]="false"
                        [value]="getPlaceLabel(routeInfo.placeOfAcceptance)" [editMode]="false">
        </app-form-field>
        <app-form-field class="col-6 col-md-4" *ngIf="routeInfo.placeOfDelivery" label="Place of acceptance" [includeMargin]="false"
                        [value]="getPlaceLabel(routeInfo.placeOfDelivery)" [editMode]="false">
        </app-form-field>
        <app-form-field class="col-6 col-md-4" *ngIf="routeInfo.dischargeTerminal" label="Terminal" [includeMargin]="false" [editMode]="false"
                        [value]="routeInfo.dischargeTerminal.terminalName">
        </app-form-field>
        <app-form-field class="col-6 col-md-4" *ngIf="routeInfo.eta || routeInfo.etd" [label]="routeInfo.eta ? 'Arrival date & time' : 'Departure date & time'" [value]="formatDate(routeInfo.eta || routeInfo.etd)"
                        [includeMargin]="false" [editMode]="false">
        </app-form-field>
        <app-form-field class="col-6 col-md-4" *ngIf="routeInfo.customsOfficeOfFirstEntry" label="Customs office of first entry" [value]="utils.customsOfficeFormatter(routeInfo.customsOfficeOfFirstEntry)"
                        [includeMargin]="false" [editMode]="false">
        </app-form-field>
      </div>
    </div>
  </ng-template>
</div>
