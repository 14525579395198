import {WasteDisplayItem, wasteDisplayItems} from '../visit-details/waste/waste.categories';
import moment from "moment";
import lodash from 'lodash';
import {
  BerthVisitSummary,
  CollectedItem,
  CollectorSummary, InputCollectedItem, InputWasteCollection, WasteCollection, WasteCollector,
  WasteItem, WasteMaterial,
  WasteTypeSsn
} from "@portbase/bezoekschip-service-typescriptmodels";
import {Item} from "./collector-summary/collector-summary.component";

export class WasteCollectorUtils {
  static wasteDisplayItems: WasteDisplayItem[] = wasteDisplayItems;

  static formatWasteType(ssnCode: string) {
    var wasteDisplayItem = wasteDisplayItems.find(v => v.ssn.code === ssnCode);
    return !!wasteDisplayItem ? wasteDisplayItem.ssn.description : "unknown";
  }

  static formatBerth = (berthVisit: BerthVisitSummary) => {
    return berthVisit && berthVisit.name;
  };

  static joinItems(items: WasteItem[]) {
    return items.map(value => this.formatWasteType(value.ssn.code)).join(", ")
  }

  static formatDate = (dateString: string): string => !!dateString ? (moment().isSame(dateString, 'day')
    ? 'Today, ' + moment(dateString).format('HH:mm') : moment(dateString).format('ddd D MMM, HH:mm')) : "";


  static wasteTypeFormatter = (ssn: WasteTypeSsn) => {
    return ssn.description;
  }

  static wasteMaterialFormatter = (material: WasteMaterial) => {
    return material.nameEN;
  }

  static isBefore(startTime: string, endTime: string): boolean {
    return startTime && endTime && moment(startTime).isBefore(moment(endTime));
  }

  static formatFacilities(items: CollectedItem[]) {
    return lodash.uniq(items.map(value => value.receptionFacility)).join(", ");
  }

  static getAllItems(summary: CollectorSummary): { [index: string]: Item } {
    let result = {};
    summary.expectedItems.forEach(i => result[i.ssn.code] = <Item>{
      quantityToBeCollected: i.quantityToBeCollected,
      quantityCollected: 0,
      specificationFromAgent: i.specification
    });

    lodash.flatMap(summary.collections, v => v.items).forEach(item => {
      let element = <Item>result[item.wasteMaterial.ssn.code];
      if (element) {
        result[item.wasteMaterial.ssn.code] = <Item>{
          quantityCollected: element.quantityCollected + item.amount,
          quantityToBeCollected: element.quantityToBeCollected,
          specificationFromAgent: element.specificationFromAgent,
          specification: element.specification
        }
      } else {
        result[item.wasteMaterial.ssn.code] = {
          quantityCollected: item.amount,
          quantityToBeCollected: 0,
          specificationFromAgent: null,
          specification: item.specification
        }
      }
    });

    return result;
  }

  static mapWasteCollection(wasteCollection: WasteCollection, wasteCollector: WasteCollector): InputWasteCollection {
    return <InputWasteCollection>{
      id: wasteCollection.id,
      startTime: wasteCollection.startTime,
      endTime: wasteCollection.endTime,
      berthVisitId: wasteCollection.berthVisitId,
      locationName: wasteCollection.locationName,
      sformNumber: WasteCollectorUtils.formatSformNumber(wasteCollector?.collectorNumber, wasteCollection.fileNumber),
      remarks: wasteCollection.remarks,
      fixedCosts: wasteCollection.fixedCosts,
      items: wasteCollection.items.map(i => this.mapToExternalCollectedItem(i))
    }
  }

  static formatSformNumber(collectorNumber: string, fileNumber: string): string {
    if (!fileNumber || !collectorNumber) {
      return null;
    }
    return collectorNumber + '/S' + fileNumber;
  }

  static mapToExternalCollectedItem(item: CollectedItem): InputCollectedItem {
    return <InputCollectedItem>{
      ssnCode: item.wasteMaterial.ssn.code,
      typeCode: item.wasteMaterial.typeCode,
      amount: item.amount,
      specification: item.specification,
      receptionFacility: item.receptionFacility,
      directCost: item.directCost,
      indirectCost: item.indirectCost
    }
  }

}
