<!--PILOTSTATION - NIET INKOMENDE REIZEN-->
<div div class="d-flex flex-column" *ngIf="!context.visit.orderIncomingMovement">
  <div class="fieldset">
    <div class="row g-0" *ngIf="context.visit.visitDeclaration.portVisit.pilotStation != null">
      <div class="col">
        <app-select [(ngModel)]="context.visit.visitDeclaration.portVisit.pilotStation"
                    [optionsProvider]="pilotStations"
                    dataKey="name" [formatter]="pilotBoardingPlaceFormatter"
                    placeholder="– Select pilot boarding place –"
                    required
                    [disabled]="context.isArrived()" id="pilotStation">
          <div class="invalid-feedback">Please select a pilot boarding place</div>
        </app-select>
      </div>
      <div class="col-3 d-none d-xl-block ps-2 validatable" style="min-width: 16rem">
        <div>
          <app-date-field *ngIf="!context.visit.orderIncomingMovement"
                         [(ngModel)]="context.visit.visitDeclaration.portVisit.etaPort" required
                         (ngModelChange)="context.shiftTime(context.visit.visitDeclaration.portVisit, 'etaPort', $event)"
                         [calendarStartDate]="context.visit.visitDeclaration.portVisit.etdPort"
                         [disabled]="context.isArrived()"
                         id="portEta"></app-date-field>

        </div>
        <span *ngIf="validator.etaPortIsSameOrBeforePreviousPort()" class="ng-invalid invalid-feedback mb-2">Last previous port should be
        before eta pilot boarding place</span>
        <span *ngIf="validator.etaPortIsSameOrAfterFirstBerth() " class="ng-invalid invalid-feedback mb-2">First berth visit should be after
        eta port</span>
        <span *ngIf="validator.etaPortIsSameOrAfterEtdPort()" class="ng-invalid invalid-feedback mb-2">Eta port should be before etd
        port</span>
      </div>
    </div>
  </div>
</div>

