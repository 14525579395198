<!-- Movement icons (pilot / tugboats / boatment / etc. ) -->
<div class="d-none d-md-block text-muted" [id]="'movement-summary-icons-' + berthVisit.id">
  <!-- Movement duration -->
  <app-movement-duration-summary [currentBerthVisit]="berthVisit"></app-movement-duration-summary>

  <!-- Vessel draft -->
  <span *ngIf="!berthVisitInfo?.harbourMasterInfo?.vesselDraught ? berthVisit.nextMovement.vesselDraft :
  berthVisitInfo.harbourMasterInfo.vesselDraught as draft" title="{{draft}} m vessel draft" class="me-3">
    <span class="fa fa-fw fa-ruler-vertical me-1"></span>{{draft}} m</span>

  <!-- Pilot service -->
  <span *ngIf="berthVisit.nextMovement.pilotService?.required" title="Pilot service" class="me-3">
    <span class="far fa-fw fa-compass me-1"></span>
    {{berthVisitInfo?.harbourMasterInfo?.pilotFromDistance === 'ACCEPTED' ? 'Shore based pilotage (LOA)'
    : berthVisit.nextMovement.pilotService.serviceProvider?.name}}
    <span *ngIf="berthVisitInfo?.harbourMasterInfo?.pilotFromDistance === 'ACCEPTED'"
          class="far fa-fw fa-check me-1 text-portbase-green" title="Shore based pilotage accepted"></span>
    <span *ngIf="berthVisitInfo?.harbourMasterInfo?.pilotFromDistance === 'NOT_ACCEPTED'"
          class="far fa-fw fa-xmark me-1 text-portbase-red" title="Shore based pilotage not accepted"></span>
  </span>

  <!-- FROM BERTH -->
  <span *ngIf="berthVisit.tugboatAtDeparture?.requiredQuantity || berthVisit.boatmenAtDeparture?.required" class="me-3">
    <!-- Tugboats from berth -->
    <span *ngIf="berthVisit.tugboatAtDeparture?.requiredQuantity" class="me-3">
      <app-tugboats-summary [currentBerth]="berthVisit" [title]="'Tugboats at departure'"></app-tugboats-summary>
    </span>
    <!-- Boatment from berth -->
    <span *ngIf="berthVisit.boatmenAtDeparture?.required" title="Boatmen from berth">
      <span class="fa fa-fw fa-link me-1"></span>{{berthVisit.boatmenAtDeparture.serviceProvider?.name}}
    </span>
  </span>

  <!-- TO NEXT BERTH -->
  <span>
    <!-- Tugboats to next berth -->
    <span class="me-3" *ngIf="differentTugs">
      <app-tugboats-summary [currentBerth]="berthVisit" [title]="'Tugboats at arrival'" [atArrival]="true">
      </app-tugboats-summary>
    </span>
    <!-- Boatment to next berth -->
    <span *ngIf="differentBoatmen">
      <app-boatmen-to-berth-summary [currentBerth]="berthVisit"></app-boatmen-to-berth-summary>
    </span>
  </span>
</div>
