import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BezoekschipOrganisation, Consignment, DeclarationStatus, Terminal} from '@portbase/bezoekschip-service-typescriptmodels';
import {VisitContext} from '../../../visit-context';
import {cloneObject, dispatchChangeEvent, lodash, removeIf, removeItem, replaceItem} from '../../../../common/utils';
import {PortvisitUtils} from '../../../../refdata/portvisit-utils';
import {ConsignmentModel, Manifest} from '../../cargo-import.model';
import {AppContext} from "../../../../app-context";
import {CollapseComponent} from '../../../../common/collapse/collapse.component';

const blRegex = /^(([A-Z]{4}[0-9]{4,8}).+)$/;

@Component({
  selector: 'app-consignments',
  templateUrl: './consignments.component.html',
  styleUrls: ['./consignments.component.css']
})
export class ConsignmentsComponent implements OnInit, OnDestroy {
  context = VisitContext;
  appContext = AppContext;
  utils = PortvisitUtils;
  visitTerminals : Terminal[] =  [];

  @Input() manifest : Manifest;

  ngOnInit(): void {
    PortvisitUtils.getVisitTerminals().subscribe(terminals => this.visitTerminals = terminals);
  }

  ngOnDestroy(): void {
    this.selectedItems.forEach(c => delete c['selected']);
  }

  getDeclarationStatus(consignment: ConsignmentModel) : string {
    const declaration = lodash.last(consignment.declarations);
    return <DeclarationStatus>(declaration && declaration.status);
  }

  getRejectReasons(consignment: ConsignmentModel): string[] {
    const declaration = lodash.last(consignment.declarations);
    return declaration && declaration.rejectReasons
      ? declaration.rejectReasons.split(";").map(s => s.trim()).filter(s => s.length > 0) : [];
  }

  removeConsignment(consignment : Consignment) {
    return () => {
      removeItem(this.manifest.consignments, consignment);
      const containersForRemoval : Set<string> = new Set(lodash.flatMap(consignment.goodsItems, g => g.placements.map(p => p.equipmentNumber)));
      this.manifest.consignments.forEach(c => c.goodsItems.forEach(g => g.placements.forEach(p => containersForRemoval.delete(p.equipmentNumber))));
      removeIf(this.manifest.containers, c => containersForRemoval.has(c.number));
    }
  }

  /*
    Bulk actions
   */

  get selectedItems() : ConsignmentModel[] {
    return this.manifest.consignments.filter(s => !!s['selected'] && !s['hidden']);
  }

  get hiddenItems(): ConsignmentModel[] {
    return this.manifest.consignments.filter(g => g['hidden']);
  }

  get visibleItems() : ConsignmentModel[] {
    return this.manifest.consignments.filter(s => !s['hidden']);
  }

  showAll() {
    this.manifest.consignments.forEach(g => {
      delete g['hidden'];
      g['forceVisible'] = true;
    });
  }

  changeTerminal(terminal: Terminal) {
    this.selectedItems.forEach(c => {
      const containers = lodash.flatMap(c.goodsItems, g => g.placements).map(p => p.equipmentNumber).filter(p => !!p);
      this.manifest.containers
        .filter(c => containers.indexOf(c.number) >= 0)
        .forEach(c => c.terminal = terminal);
      this.manifest.consignments
        .filter(c => c.goodsItems.find(g => g.placements.some(p => containers.indexOf(p.equipmentNumber) >= 0)))
        .forEach(c => c.terminal = terminal);
    });
  }

  addBulkAuthorisation(authorisation: BezoekschipOrganisation) {
    if (authorisation) {
      this.selectedItems.forEach(c => replaceItem(c.bulkAuthorisations,
        c.bulkAuthorisations.find(o => o.shortName === authorisation.shortName), authorisation));
    }
  }

  copyConsignmentDetails = (to: ConsignmentModel, from: ConsignmentModel) => {
    let consignmentNr = to.consignmentNumber;
    lodash.assign(to, cloneObject(from));
    to.consignmentNumber = consignmentNr;
    to.declared = false;
    to.goodsItems.forEach(g => g.placements.forEach(p => delete p.equipmentNumber));
    dispatchChangeEvent(document.body);
  };

  duplicate(consignment: ConsignmentModel, consignmentCollapse: CollapseComponent) {
    const newConsignment = this.manifest.addConsignment();
    this.copyConsignmentDetails(newConsignment, consignment);
    consignmentCollapse.toggle(false, false);
    setTimeout(() => {
      if (consignment.consignmentNumber && blRegex.test(consignment.consignmentNumber)) {
        const match = blRegex.exec(consignment.consignmentNumber);
        newConsignment.consignmentNumber = match[2];
      }
    }, 100);
  }

  deleteSelected() {
    this.selectedItems.forEach(c => this.removeConsignment(c)());
  }

  toggleSelectAll() {
    if (this.selectedItems.length === this.visibleItems.length) {
      this.visibleItems.forEach(c => c['selected'] = false);
    } else {
      this.visibleItems.forEach(c => c['selected'] = true);
    }
  }

  getContainers(consignment: ConsignmentModel) {
    const containers = lodash.keyBy(this.manifest.containers, c => c.number);
    return lodash.uniqBy(lodash.flatMap(consignment.goodsItems, g => g.placements)
      .map(p => containers[p.equipmentNumber]).filter(c => !!c), c => c.number);
  }
}
