import {
  BezoekschipOrganisation,
  BulkDischargeResult,
  Consignment,
  ConsignmentDifference,
  Declaration,
  DischargeResult,
  GetDischargeListAsCsv,
  GoodPlacement,
  GoodsItem,
  ImportContainer,
  ImportDeclaration,
  IncomingMessage,
  InspectionStatus,
  InspectionUpdate,
  Port,
  Terminal
} from '@portbase/bezoekschip-service-typescriptmodels';
import {VisitContext} from '../visit-context';
import {cloneObject, lodash, replaceItem, sendQuery} from '../../common/utils';
import {ComparatorChain} from '../../common/comparator-chain';
import {formatTimestamp} from '../../common/date/time-utils';
import {exportDataAsExcel} from '../../common/upload/excel.utils';
import {PortvisitUtils} from '../../refdata/portvisit-utils';
import {AppContext} from '../../app-context';

const compareByTerminal = (m1: any, m2: any) => {
  if (!m1.terminal) {
    return m2.terminal ? -1 : 0;
  }
  if (!m2.terminal) {
    return 1;
  }
  const terminals = VisitContext.savedVisit.visitDeclaration.portVisit.berthVisits.map(p => p.berth && p.berth.terminalCode);
  return terminals.indexOf(m1.terminal.terminalCode) - terminals.indexOf(m2.terminal.terminalCode);
};

const containerComparator = new ComparatorChain(compareByTerminal, '!!dischargeResult', 'number').compare;

export class CargoImportModel {
  readonly cargoDeclarant: BezoekschipOrganisation;
  readonly declarations: Declaration[];
  readonly discharges: { [index: string]: DischargeResult };
  readonly manifests: Manifest[];
  readonly dischargeLists: DischargeList[];
  readonly overlandersWithoutPort: DischargeResult[];
  readonly clearedManifestIds: string[];
  readonly consignmentDifferences: ConsignmentDifference[];
  readonly inspections: Inspection[];
  readonly bulkDischarges: BulkDischargeResult[];
  readonly incomingMessages: IncomingMessage[];

  constructor(importDeclaration: ImportDeclaration) {
    this.cargoDeclarant = importDeclaration.cargoDeclarant;
    this.declarations = importDeclaration.declarations;
    this.discharges = <{ [index: string]: DischargeResult }>lodash.keyBy(importDeclaration.discharges, d => d.number);
    this.inspections = Inspection.asInspectionModel(importDeclaration);
    this.manifests = Manifest.asManifests(importDeclaration, this.inspections);
    this.dischargeLists = DischargeList.asDischargeLists(importDeclaration);
    this.overlandersWithoutPort = <DischargeResult[]>lodash.values(importDeclaration.discharges)
      .filter(d => !d.portOfLoading && !importDeclaration.containers.find(c => c.number === d.number));
    this.clearedManifestIds = importDeclaration.clearedManifestIds;
    this.consignmentDifferences = importDeclaration.consignmentDifferences;
    this.bulkDischarges = importDeclaration.bulkDischarges;
    this.incomingMessages = importDeclaration.incomingMessages;

    //listen for change and button click events
    CargoImportModel.listenForChanges();
  }

  isEditor() {
    return AppContext.isAdmin() || AppContext.isCargoImportEditor();
  }

  downloadDischargeList(terminalCode?: string) {
    sendQuery("com.portbase.bezoekschip.common.api.cargo.GetDischargeListAsCsv", <GetDischargeListAsCsv>{
      crn: VisitContext.savedVisit.crn,
      cargoDeclarantShortName: VisitContext.cargoImportModel.cargoDeclarant.shortName,
      terminalCode: terminalCode
    }, {responseType: 'text'}).subscribe(csv => {
      const data: string[][] = [];
      csv.split('\n').forEach(line => data.push(line.split(';').map(s => s.replace(/"+/g, ''))));

      const containers = lodash.flatMap(VisitContext.cargoImportModel.manifests, m => m.containers);
      const filteredData = [data[0]];
      const containerNrColumn = data[0].findIndex(v => v === 'Container nr');
      if (containerNrColumn >= 0) {
        for (let i = 1; i < data.length; i++) {
          const containerNr = data[i][containerNrColumn];
          if (containers.some(c => c.number === containerNr && !c['hidden'])) {
            filteredData.push(data[i]);
          }
        }
      }
      exportDataAsExcel(filteredData, 'dischargeList-' + VisitContext.savedVisit.crn + (terminalCode ? '-' + terminalCode : '') + '.xlsx');
    })
  }

  downloadConsignments(manifests: Manifest[]) {
    const data =
      [["Call Reference Number", "Vessel name", "Voyage No", "PoL-UnCode", "PoL-Name", "B/L Number",
        "GI no", "Out.pkg", "Pack type", "Goods description",
        "Cargo weight", "Marks and numbers", "UNNO", "IMO",
        "Flashpoint", "Flashp.unit", "Temp.set", "Temp.unit",
        "ETA", "Terminal", "Place of acceptance", "Port of origin (code)", "Port of origin (name)",
        "In transit to (code)", "In transit to (name)", "place of delivery",
        "Custom process", "Custom status", "Warehouse licence", "Cust. auth. nr", "Issue place", "Shipper",
        "Consignee", "Notify1", "Notify2", "Notify3", "Notify4", "Notify5", "Inner pkge", "Pack type", "Commodity code",
        "Container number", "Size type", "No. pkgs", "Cargo weight", "Tare weight", "Seal Shipper",
        "Seal Carrier", "Seal Customs", "Empty"]];

    manifests.forEach(m => {
      let consignments = m.consignments.filter(s => !!s['selected'] && !s['hidden']);
      consignments = consignments.length > 0 ? consignments : m.consignments.filter(s => !s['hidden']);

      consignments.forEach(consignment => {
        const berthVisits = lodash.keyBy(VisitContext.savedVisit.visitDeclaration.portVisit.berthVisits, v => v?.berth?.terminalCode);
        const berthVisit = consignment.terminal && berthVisits[consignment.terminal.terminalCode];

        consignment.goodsItems.forEach(g => {
          const dangerInfo = g.dangerInformation;
          const goodData = [VisitContext.savedVisit.crn, VisitContext.savedVisit.vessel.name,
            VisitContext.savedVisit.visitDeclaration.arrivalVoyage.voyageNumber,
            m.portOfLoading.locationUnCode, m.portOfLoading.name, consignment.consignmentNumber,

            g.itemNumber, g.numberOfOuterPackages, g.outerPackageType && g.outerPackageType.code, g.description,
            g.grossWeight, g.marksAndNumbers.join(', '), dangerInfo && dangerInfo.unCode, dangerInfo && dangerInfo.hazardClass,
            dangerInfo && dangerInfo.flashPoint, "CEL", g.minimumTemperature || g.maximumTemperature, "CEL",

            formatTimestamp(berthVisit && berthVisit.eta, 'D/M/YY H:mm'),
            berthVisit && berthVisit.berth.name, null,
            consignment.placeOfOrigin && consignment.placeOfOrigin.locationUnCode, consignment.placeOfOrigin && consignment.placeOfOrigin.name,
            consignment.placeOfDestination && consignment.placeOfDestination.locationUnCode, consignment.placeOfDestination && consignment.placeOfDestination.name,
            null, consignment.customsProcess, consignment.customsStatus,
            consignment.warehouseLicense && consignment.warehouseLicense.licenseNumber,
            consignment.warehouseLicense && consignment.warehouseLicense.customsAuthorityId,
            consignment.warehouseLicense && consignment.warehouseLicense.holderName,
            consignment.consignor && consignment.consignor.name, consignment.consignee && consignment.consignee.name,
            consignment.partiesToNotify.length > 0 ? consignment.partiesToNotify[0].name : null,
            consignment.partiesToNotify.length > 1 ? consignment.partiesToNotify[1].name : null,
            consignment.partiesToNotify.length > 2 ? consignment.partiesToNotify[2].name : null,
            consignment.partiesToNotify.length > 3 ? consignment.partiesToNotify[3].name : null,
            consignment.partiesToNotify.length > 4 ? consignment.partiesToNotify[4].name : null,

            g.numberOfInnerPackages, g.innerPackageType && g.innerPackageType.code,
            g.classification && g.classification.code,
          ];

          if (g.placements.length > 0) {
            g.placements.forEach(p => {
              const containerData = cloneObject(goodData);
              const container = m.containers.find(c => c.number === p.equipmentNumber);
              containerData.push(
                p.equipmentNumber, container && container.sizeType && container.sizeType.code,
                p.numberOfPackages, p.grossWeight, container && container.tareWeight,
                container && container.shipperSealNumber, container && container.carrierSealNumber,
                container && container.customsSealNumber, container && (container.empty ? 'Y' : 'N')
              );
              data.push(containerData);
            })
          } else {
            data.push(goodData);
          }
        });
      });
    });

    exportDataAsExcel(data, VisitContext.savedVisit.crn + '_blDetails.xlsx');
  }

  downloadMrns(manifests: Manifest[]) {
    const data = [["Port of discharge", "Port of loading", "B/L number", "MRN number"]];
    manifests.forEach(m => {
      let consignments = m.consignments.filter(s => !!s['selected'] && !s['hidden']);
      consignments = consignments.length > 0 ? consignments : m.consignments.filter(s => !s['hidden']);

      consignments.filter(
        c => m.portOfLoading && m.portOfDischarge && c.consignmentNumber)
        .forEach(c => {
          if (c.movementReferenceNumbers.length > 0) {
            c.movementReferenceNumbers.forEach(mrn => data.push([
              m.portOfDischarge.locationUnCode,
              m.portOfLoading.locationUnCode,
              c.consignmentNumber,
              mrn
            ]))
          } else {
            data.push([
              m.portOfDischarge.locationUnCode,
              m.portOfLoading.locationUnCode,
              c.consignmentNumber,
              ''
            ])
          }
        });
    });
    exportDataAsExcel(data, VisitContext.savedVisit.crn + '_movementReferenceNumbers.xlsx');
  }

  downloadClearanceDifferences(manifests: Manifest[]) {
    const data =
      [["Vessel Call", "Vessel", "Voyage nr", "ATA-date", "ATA-time", "POL", "POD", "B/L nr", "PCS colli",
        "PCS weight (KGM)", "Remaining colli", "Remaining weight (KGM)", "Differences report date"]];

    manifests.forEach(m => {
      let consignments = m.consignments.filter(s => !!s['selected'] && !s['hidden']);
      consignments = consignments.length > 0 ? consignments : m.consignments.filter(s => !s['hidden']);
      const berthVisits = lodash.keyBy(VisitContext.savedVisit.visitDeclaration.portVisit.berthVisits, v => v.berth.terminalCode);
      const selectedPort = m.portOfLoading.locationUnCode;
      const selectedDifferences = VisitContext.cargoImportModel.consignmentDifferences
        .filter(d => consignments.find(c => c.consignmentNumber === d.consignmentNumber
          || d.portOfLoadingUnCode === selectedPort))
        .sort(new ComparatorChain('consignmentNumber').compare);

      selectedDifferences.forEach(d => {
        const consignment = consignments.find(c => c.consignmentNumber === d.consignmentNumber)
          || <Consignment>{terminal: {}, goodsItems: []};
        const berthVisit = berthVisits[consignment.terminal.terminalCode];
        const row = [VisitContext.savedVisit.crn, VisitContext.savedVisit.vessel.name,
          VisitContext.savedVisit.visitDeclaration.arrivalVoyage.voyageNumber,
          formatTimestamp(berthVisit && berthVisit.ata, 'D-M-YYYY'),
          formatTimestamp(berthVisit && berthVisit.ata, 'HH:mm'),
          d.portOfLoadingUnCode, VisitContext.savedVisit.portOfCall.port.locationUnCode,
          d.consignmentNumber,
          lodash.sumBy(consignment.goodsItems, g => Number(g.numberOfOuterPackages || 0)),
          lodash.sumBy(consignment.goodsItems, g => Number(g.grossWeight || 0)),
          lodash.sumBy(d.itemDifferences, d => Number(d.remainingPackages || 0)),
          lodash.sumBy(d.itemDifferences, d => Number(d.remainingWeight || 0)),
          formatTimestamp(d.timestamp, 'D-M-YYYY'),
        ];
        data.push(row);
      });
    });

    exportDataAsExcel(data, VisitContext.savedVisit.crn + '_clearanceDifferences.xlsx');
  }

  static initializeForCargoAgent(organisation: string) {
    if (organisation) {
      let importDeclaration = VisitContext.visit.importDeclarations.find(d => d.cargoDeclarant.shortName === organisation);
      if (!importDeclaration) {
        const cargoAgent = VisitContext.savedVisit.cargoDeclarants.find(c => c.shortName === organisation);
        if (!cargoAgent) {
          delete VisitContext.cargoImportModel;
        } else {
          VisitContext.visit.importDeclarations.push(importDeclaration = <ImportDeclaration>{
            cargoDeclarant: cargoAgent, discharges: [], bulkDischarges: [], containers: [], consignments: [],
            declarations: [], clearedManifestIds: [], consignmentDifferences: [], inspectionUpdates: [],
            incomingMessages: [], receivedMrns: null
          });
        }
      }

      if (importDeclaration) {
        importDeclaration.containers.forEach(c =>
          (<ImportContainerModel>c).dischargeResult = importDeclaration.discharges.find(d => d.number === c.number));
        importDeclaration.containers.sort(containerComparator).forEach(c => c['declared'] = true);
        importDeclaration.discharges.sort(containerComparator);
        importDeclaration.inspectionUpdates = importDeclaration.inspectionUpdates || [];
        importDeclaration.consignments.forEach(c => c['declared'] = true);
        VisitContext.cargoImportModel = new CargoImportModel(importDeclaration);
        if (!VisitContext.savedImportDeclaration || VisitContext.savedImportDeclaration.cargoDeclarant.shortName !==
          importDeclaration.cargoDeclarant.shortName) {
          VisitContext.savedImportDeclaration = cloneObject(importDeclaration);
        }
      }
    } else {
      delete VisitContext.cargoImportModel;
    }
  }

  getAllPlacements(): GoodPlacement[] {
    return lodash.flatMap(this.manifests, m => lodash.flatMap(m.consignments, c => lodash.flatMap(c.goodsItems, g => g.placements)));
  }

  addConsignment = (portOfLoading: Port): Manifest => {
    const manifest = Manifest.computeManifest(portOfLoading);
    manifest.addConsignment();
    return manifest;
  }

  addCargo(containers: ImportContainer[], consignments: Consignment[]) {
    containers.forEach(c => {
      const model = <ImportContainerModel>c;
      Manifest.computeManifest(c.portOfLoading, c.portOfDischarge).containers.push(model);
    });
    consignments.forEach(c => {
      const model = <ConsignmentModel>c;
      model.declarations = [];
      model.bulkDischarges = [];
      model.inspections = [];
      model.incomingMessages = [];
      model.bulkAuthorisations = [];
      Manifest.computeManifest(c.portOfLoading, c.portOfDischarge).consignments.push(model);
    });
    CargoImportModel.afterChange(true);
  }

  private static changeHandler;
  private static listenForChanges = () => {
    if (!CargoImportModel.changeHandler) {
      $(document.body).on('change', CargoImportModel.changeHandler = () => {
        if (VisitContext.cargoImportModel) {
          CargoImportModel.afterChange();
        }
      });

      $(document.body).on('click', e => {
        if (VisitContext.cargoImportModel) {
          let element = e.target;
          while (!!element && element != document.body) {
            switch (lodash.lowerCase(element.tagName)) {
              case 'a':
              case 'button':
                setTimeout(() => CargoImportModel.afterChange(true), 0);
                return;
            }
            element = element.parentElement;
          }
        }
      });
    }
  };

  static rerender() {
    VisitContext.cargoImportModel = lodash.cloneDeepWith(VisitContext.cargoImportModel, v => lodash.isArray(v) ? lodash.assign([], v) : undefined);
    CargoImportModel.afterChange(true);
  }

  private static afterChange(force = false) {
    const before = VisitContext.cargoImportModel;
    const importDeclaration = toImportDeclaration();
    const after = new CargoImportModel(importDeclaration);
    if (force) {
      console.log("carge import change was forced");
      doUpdate();
    } else {
      const replacer = (key, value) => key === 'ngInvalid' || key === 'selected' || key === 'hidden' || key === 'forceVisible'
        ? undefined : value;
      if (JSON.stringify(before, replacer) !== JSON.stringify(after, replacer)) {
        console.log("carge import has changed significantly");
        doUpdate();
      }
    }

    function doUpdate() {
      after.manifests.forEach(manifest => {
        const previous = before.manifests.find(m => m.id === manifest.id);
        if (previous) {
          manifest['selected'] = previous['selected'];
          manifest['hidden'] = previous['hidden'];
          manifest['forceVisible'] = previous['forceVisible'];
          manifest['ngInvalid'] = previous['ngInvalid'];
        }
      });
      after.dischargeLists.forEach(list => {
        const previous = before.dischargeLists.find(m => m.terminal.terminalCode === list.terminal.terminalCode);
        if (previous) {
          list['selected'] = previous['selected'];
          list['hidden'] = previous['hidden'];
          list['forceVisible'] = previous['forceVisible'];
          list['ngInvalid'] = previous['ngInvalid'];
        }
      });
      const oldDeclaration = VisitContext.visit.importDeclarations.find(
        d => d.cargoDeclarant.shortName === importDeclaration.cargoDeclarant.shortName);
      replaceItem(VisitContext.visit.importDeclarations, oldDeclaration, importDeclaration);
      VisitContext.cargoImportModel = after;
    }

    function toImportDeclaration(): ImportDeclaration {
      return <ImportDeclaration>{
        declarations: before.declarations,
        cargoDeclarant: before.cargoDeclarant,
        refusedDischarges: [],
        receivedMrns: null,
        discharges: lodash.values(before.discharges),
        containers: lodash.flatMap(before.manifests, m => m.containers),
        consignments: lodash.flatMap(before.manifests, m => m.consignments),
        bulkDischarges: before.bulkDischarges,
        clearedManifestIds: before.clearedManifestIds,
        consignmentDifferences: before.consignmentDifferences,
        inspectionUpdates: lodash.flatMap(before.inspections, m => m.inspectionUpdates),
        incomingMessages: before.incomingMessages,
        timestamp: null
      }
    }
  }
}

export class Manifest {
  static comparator = new ComparatorChain((m1: Manifest, m2: Manifest) => {
    const previousPorts = VisitContext.savedVisit.visitDeclaration.previousPorts.map(p => p.port && p.port.locationUnCode);
    return previousPorts.indexOf(m1.portOfLoading.locationUnCode) - previousPorts.indexOf(m2.portOfLoading.locationUnCode);
  }).compare;

  static consignmentComparator = new ComparatorChain(compareByTerminal, "consignmentNumber").compare;

  readonly id: string;
  readonly portOfLoading: Port;
  readonly portOfDischarge: Port;
  readonly consignments: ConsignmentModel[] = [];
  readonly containers: ImportContainerModel[] = [];
  readonly overlanders: DischargeResult[] = [];
  readonly declarations: Declaration[] = [];
  readonly cleared: boolean;

  constructor(portOfLoading: Port, portOfDischarge: Port, cargoDeclarant: BezoekschipOrganisation) {
    const importDeclaration = VisitContext.visit.importDeclarations
      .find(d => cargoDeclarant && d.cargoDeclarant.shortName === cargoDeclarant.shortName);
    this.portOfLoading = portOfLoading;
    this.portOfDischarge = portOfDischarge || VisitContext.savedVisit.portOfCall.port;
    this.cleared = importDeclaration && this.portOfLoading && this.portOfDischarge &&
      importDeclaration.clearedManifestIds.indexOf(this.portOfLoading.locationUnCode) >= 0 &&
      this.portOfDischarge.locationUnCode == VisitContext.savedVisit.portOfCall.port.locationUnCode;
    this.id = getManifestId(this.portOfLoading, this.portOfDischarge);
  }

  addConsignment(): ConsignmentModel {
    const result = this.addGoodsItem(<ConsignmentModel>{
      partiesToNotify: [],
      bulkAuthorisations: [],
      goodsItems: [],
      movementReferenceNumbers: [],
      portOfLoading: this.portOfLoading,
      portOfDischarge: this.portOfDischarge,
      actualDeparture: this.consignments.map(c => c.actualDeparture).find(d => !!d) ||
        VisitContext.savedVisit.visitDeclaration.previousPorts.filter(
          p => p.port && p.port.locationUnCode === this.portOfLoading.locationUnCode)
          .map(p => p.departure).find(p => !!p),
      declarations: []
    });
    this.consignments.splice(0, 0, result);
    return result;
  }

  setActualDeparture(actualDeparture: string) {
    if (!!actualDeparture) {
      this.consignments.forEach(c => c.actualDeparture = actualDeparture);
      this.containers.forEach(c => c.actualDeparture = actualDeparture);
    }
  }

  addEmptyContainer() {
    this.containers.splice(0, 0, <ImportContainerModel>{
      portOfLoading: this.portOfLoading,
      portOfDischarge: this.portOfDischarge,
      actualDeparture: this.getActualDeparture(),
      empty: true
    });
  }

  getActualDeparture(): string {
    return this.consignments.map(c => c.actualDeparture).find(d => !!d) ||
      VisitContext.savedVisit.visitDeclaration.previousPorts.filter(
        p => p.port && p.port.locationUnCode === this.portOfLoading.locationUnCode)
        .map(p => p.departure).find(p => !!p);
  }

  addGoodsItem(consignment: Consignment): ConsignmentModel {
    const item = <GoodsItem>{itemNumber: nextItemNumber(), placements: [], producedDocuments: [], marksAndNumbers: []};
    consignment.goodsItems.splice(0, 0, item);
    item['ngInvalid'] = true;
    consignment['ngInvalid'] = true;
    return <ConsignmentModel>consignment;

    function nextItemNumber() {
      const lastItem = lodash.max(consignment.goodsItems.map(g => g.itemNumber));
      return lastItem ? lastItem + 1 : 1;
    }
  }

  static computeManifest(portOfLoading: Port, portOfDischarge = VisitContext.savedVisit.portOfCall.port): Manifest {
    const manifestId = getManifestId(portOfLoading, portOfDischarge);
    let result = VisitContext.cargoImportModel.manifests.find(m => m.id === manifestId);
    if (!result) {
      result = new Manifest(portOfLoading, portOfDischarge, VisitContext.cargoImportModel.cargoDeclarant);
      VisitContext.cargoImportModel.manifests.push(result);
    }
    return result;
  }

  static asManifests(importDeclaration: ImportDeclaration, inspections: Inspection[]): Manifest[] {
    removeUnusedContainers();
    const manifests = new Map<string, Manifest>();
    const portOfCall = VisitContext.savedVisit.portOfCall.port.locationUnCode;

    importDeclaration.declarations.filter(d => d.type === 'SDT')
      .forEach(d => {
        const portOfLoading = VisitContext.savedVisit.visitDeclaration.previousPorts.map(p => p.port)
          .find(p => p && p.locationUnCode === d.id);
        // passingThrough
        if (portOfLoading && VisitContext.isPassingThrough()) {
          const dischargePorts = lodash.uniqBy(importDeclaration.consignments
            .filter(p => p.portOfLoading.locationUnCode).map(p => p.portOfDischarge)
            .concat(importDeclaration.containers
              .filter(p => p.portOfLoading.locationUnCode).map(p => p.portOfDischarge)), p => p.locationUnCode);

          dischargePorts.forEach(dischargePort => {
            const manifest = computeManifestFromPort(portOfLoading, <Port>(dischargePort.locationUnCode ===
            VisitContext.savedVisit.portOfCall.port.locationUnCode ? null : dischargePort));
            if (manifest) {
              manifest.declarations.push(d);
            }
          })

          //normal
        } else {
          const manifest = portOfLoading && computeManifestFromPort(portOfLoading);
          if (manifest) {
            manifest.declarations.push(d);
          }
        }
      });

    importDeclaration.consignments.forEach(c => {
      c.goodsItems.forEach(g => g.placements.filter(p => p.equipmentNumber).forEach(p => {
        const container = importDeclaration.containers.find(container => container.number === p.equipmentNumber);
        if (!container) {
          delete p.equipmentNumber;
          g['ngInvalid'] = true;
          c['ngInvalid'] = true;
        } else {
          container.portOfLoading = c.portOfLoading;
          container.portOfDischarge = c.portOfDischarge;
          container.actualDeparture = c.actualDeparture;
          container.terminal = c.terminal;
        }
      }));
      c.terminal = c.portOfDischarge && portOfCall === c.portOfDischarge.locationUnCode ? c.terminal : null;
      const consignmentModel = <ConsignmentModel>c;
      consignmentModel.declarations = importDeclaration.declarations.filter(d => d.id === c.consignmentNumber);
      consignmentModel.incomingMessages = importDeclaration.incomingMessages.filter(d => d.id === c.consignmentNumber);
      consignmentModel.inspections = inspections.filter(i => i.consignmentNumber === c.consignmentNumber);
      consignmentModel.bulkDischarges = importDeclaration.bulkDischarges.filter(i => i.consignmentNumber === c.consignmentNumber);
      consignmentModel.goodsItems.forEach(g => g.placements.forEach(p =>
        p.inspections = p.equipmentNumber ? consignmentModel.inspections.filter(i => i.containerNumber === p.equipmentNumber) : []))
      const manifest = computeManifest(c);
      manifest.consignments.push(consignmentModel);
      consignmentModel.declarations.forEach(d => manifest.declarations.push(d));
    });

    importDeclaration.containers.forEach(c => {
      c.terminal = c.portOfDischarge && portOfCall === c.portOfDischarge.locationUnCode ? c.terminal : null;
      const containerModel = <ImportContainerModel>c;
      containerModel.dischargeResult = containerModel.dischargeResult
        || importDeclaration.discharges.find(d => d.number === c.number);
      containerModel.inspections = inspections.filter(i => i.containerNumber === c.number);
      computeManifest(c).containers.push(containerModel);
    });

    importDeclaration.discharges
      .filter(d => d.portOfLoading && !importDeclaration.containers.find(c => c.number === d.number))
      .forEach(v => computeManifest(v).overlanders.push(v));

    const result: Manifest[] = Array.from(manifests.values());

    // Determine shortlandeds
    const coarriTerminals: string[] = getTerminalsThatShouldReceiveCoarris(result);
    result.forEach(manifest => {
      manifest.consignments.forEach(c => c.goodsItems.forEach(g => g.placements.forEach(p =>
        p.shortlanded = p.equipmentNumber && c.terminal
          && coarriTerminals.indexOf(c.terminal.terminalCode) >= 0
          && !importDeclaration.discharges.find(d => d.number === p.equipmentNumber))));
      manifest.containers.forEach(c =>
        c.shortlanded = !c.dischargeResult && c.terminal && coarriTerminals.indexOf(c.terminal.terminalCode) >= 0)
    })

    result.forEach(m => {
      m.consignments.sort(Manifest.consignmentComparator);
      m.containers.sort(containerComparator);
    });
    return result.sort(Manifest.comparator);

    function computeManifestFromPort(portOfLoading: Port, portOfDischarge?: Port) {
      const manifestId = getManifestId(portOfLoading, portOfDischarge);
      let result = manifests.get(manifestId);
      if (!result) {
        manifests.set(manifestId, result
          = new Manifest(portOfLoading, portOfDischarge, importDeclaration.cargoDeclarant));
      }
      return result;
    }

    function computeManifest(entity: Consignment | ImportContainer | DischargeResult): Manifest {
      return computeManifestFromPort(entity.portOfLoading, entity['portOfDischarge']);
    }

    function removeUnusedContainers() {
      const placed: string[] = lodash.flatMap(importDeclaration.consignments,
        n => lodash.flatMap(n.goodsItems, g => g.placements)).map(p => p.equipmentNumber).filter(nr => !!nr);
      const discharged: string[] = importDeclaration.discharges.map(d => d.number);
      importDeclaration.containers = importDeclaration.containers.filter(
        c => c.empty || placed.indexOf(c.number) >= 0 || discharged.indexOf(c.number) >= 0);
    }
  }
}

export function getTerminalsThatShouldReceiveCoarris(manifests: Manifest[]): string[] {
  const hasAtdPort = !!VisitContext.savedVisit.visitDeclaration.portVisit.atdPort;
  const receivedCoarri = lodash.uniq(lodash.flatMap(manifests, m => m.containers)
    .filter(c => !!c.dischargeResult && !!c.terminal && !!c.terminal.terminalCode)
    .map(c => c.terminal.terminalCode));
  return VisitContext.savedVisit.visitDeclaration.portVisit.berthVisits
    .filter(v => v.atd || hasAtdPort).map(v => v.berth)
    .filter(b => PortvisitUtils.dischargeResultTerminals.indexOf(b.organisationShortName) >= 0
      || receivedCoarri.indexOf(b.terminalCode) >= 0)
    .map(b => b.terminalCode).filter(c => !!c);
}

export function getTerminalsWhereCoarrisNotExpected(manifests: Manifest[]): string[] {
  const receivedCoarri = lodash.uniq(lodash.flatMap(manifests, m => m.containers)
    .filter(c => !!c.dischargeResult && !!c.terminal && !!c.terminal.terminalCode)
    .map(c => c.terminal.terminalCode));
  return VisitContext.savedVisit.visitDeclaration.portVisit.berthVisits
    .map(v => v.berth)
    .filter(b => PortvisitUtils.dischargeResultTerminals.indexOf(b.organisationShortName) == -1
      && receivedCoarri.indexOf(b.terminalCode) == -1)
    .map(b => b.terminalCode).filter(c => !!c);
}

export interface ConsignmentModel extends Consignment {
  goodsItems: GoodsItemModel[];
  declarations: Declaration[];
  declared: boolean;
  inspections: Inspection[];
  bulkDischarges: BulkDischargeResult[];
  incomingMessages: IncomingMessage[];
}

export interface ImportContainerModel extends ImportContainer {
  selected: boolean;
  ngInvalid: boolean;
  dischargeResult: DischargeResult;
  declared: boolean;
  inspections: Inspection[];
  shortlanded: boolean;
}

export interface GoodsItemModel extends GoodsItem {
  placements: GoodPlacementModel[];
}

export interface GoodPlacementModel extends GoodPlacement {
  inspections: Inspection[];
  shortlanded: boolean;
}


export class DischargeList {
  readonly terminal: Terminal;
  readonly containers: ImportContainerModel[] = [];
  readonly overlanders: DischargeResult[] = [];
  readonly declarations: Declaration[] = [];

  constructor(terminal: Terminal) {
    this.terminal = terminal;
  }

  static asDischargeLists(importDeclaration: ImportDeclaration): DischargeList[] {
    const lists = new Map<string, DischargeList>();
    importDeclaration.containers.filter(c => c.terminal)
      .forEach(c => {
        const containerModel = <ImportContainerModel>c;
        containerModel.dischargeResult = containerModel.dischargeResult
          || importDeclaration.discharges.find(d => d.number === c.number);
        computeDischargeList(c.terminal).containers.push(containerModel)
      });
    importDeclaration.discharges.filter(d => d.terminal && d.portOfLoading
      && !importDeclaration.containers.find(c => c.number === d.number))
      .forEach(c => computeDischargeList(c.terminal).overlanders.push(c));
    importDeclaration.declarations.filter(d => d && d.type === 'COPRAR')
      .forEach(d => computeDischargeList(<any>{terminalName: d.id, terminalCode: d.id}).declarations.push(d));
    return sortLists(Array.from(lists.values()));

    function computeDischargeList(terminal: Terminal) {
      const terminalCode = terminal.terminalCode;
      let result = lists.get(terminalCode);
      if (!result) {
        lists.set(terminalCode, result = new DischargeList(terminal));
      }
      return result;
    }

    function sortLists(dischargeLists: DischargeList[]): DischargeList[] {
      const listComparator = new ComparatorChain((i1: DischargeList, i2: DischargeList) => {
        const terminals = VisitContext.savedVisit.visitDeclaration.portVisit.berthVisits.map(b => b.berth && b.berth.terminalCode);
        return terminals.indexOf(i1.terminal.terminalCode) - terminals.indexOf(i2.terminal.terminalCode);
      }).compare;

      return Array.from(dischargeLists.values()).sort(listComparator);
    }
  }
}


export class Inspection {
  status: InspectionStatus;
  inspectionUpdates: InspectionUpdate[];
  consignmentNumber: string;
  containerNumber: string;


  constructor(inspectionUpdates: InspectionUpdate[]) {
    this.inspectionUpdates = inspectionUpdates;
    this.consignmentNumber = inspectionUpdates[0].consignmentNumber;
    this.containerNumber = inspectionUpdates[0].containerNumber;
    this.status = inspectionUpdates[inspectionUpdates.length - 1].status;
  }

  static asInspectionModel(importDeclaration: ImportDeclaration): Inspection[] {
    return lodash.toArray(lodash.groupBy(importDeclaration.inspectionUpdates, u => u.consignmentNumber + "|" + u.containerNumber))
      .map(updates => new Inspection(<InspectionUpdate[]>updates))
  }
}

function getManifestId(portOfLoading: Port, portOfDischarge?: Port) {
  portOfDischarge = portOfDischarge || VisitContext.savedVisit.portOfCall.port;
  return (portOfLoading && portOfLoading.locationUnCode || '') + (portOfDischarge && portOfDischarge.locationUnCode || '');
}
