<app-table-view [fields]="fieldsDefinition" [data]="data" [recordTrackBy]="portVisitUtils.trackByIndex"
                (termUpdated)="term = $event" (addButtonClicked)="addRecord()"
                [editable]="editable" [editMode]="editMode"></app-table-view>

<ng-template #identificationTypeRef let-record="value" let-field="field">
  <app-select [ngModel]="record.typeOfIdentification" [options]="typeOfIdentifications"
              (ngModelChange)="tableView.onFieldChanged(record, field, $event)"
              [formatter]="typeOfIdentificationFormatter" [required]="field.required"></app-select>
</ng-template>

<ng-template #typeOfMeansOfTransportRef let-record="value" let-field="field">
  <app-search [ngModel]="record.typeOfMeansOfTransport" [searchFunction]="searchTypeOfMeansOfTransport"
              (ngModelChange)="tableView.onFieldChanged(record, field, $event ? $event['code'] : null)"
              [inputFormatter]="typeOfMeansOfTransportFormatter" dataKey="code"
              [required]="field.required"></app-search>
</ng-template>

<ng-template #nationalityRef let-record="value" let-field="field">
  <app-search required [ngModel]="record.nationality" [searchFunction]="portVisitUtils.findCountries"
              [inputFormatter]="portVisitUtils.countryFormatter" [resultFormatter]="portVisitUtils.countryFormatter"
              (ngModelChange)="tableView.onFieldChanged(record, field, $event)">
  </app-search>
</ng-template>

<ng-template #actionColumnRef let-record="value">
  <ng-container *ngIf="editable && editMode">
    <a class="btn rounded-circle p-1 text-danger h1 mb-0 action-btn" type="button" (click)="deleteRecord(record)">
      <i class="fa-fw fa-light fa-trash-can"></i>
    </a>
  </ng-container>
</ng-template>
