import {Component, Input} from '@angular/core';
import {PortvisitUtils} from "../../refdata/portvisit-utils";
import {getPhase} from "../commercial-release.utils";
import {CommercialRelease} from "@portbase/bezoekschip-service-typescriptmodels";

@Component({
  selector: 'app-commercial-release-summary',
  templateUrl: './commercial-release-summary.component.html',
  styleUrls: ['./commercial-release-summary.component.css']
})
export class CommercialReleaseSummaryComponent {
  @Input() keepTooltipOpen: boolean;
  model: CommercialRelease;
  refData = PortvisitUtils;

  packageCount = () => 0;
  weight = () => 0;
  phase = (): string => null;
  phaseType = (): 'danger' | 'warning' | 'info' | 'success' | 'secondary' | 'dark' => null;

  @Input() set setModel(model: CommercialRelease) {
    this.model = model;
    this.phase = () => getPhase(model);
    this.phaseType = () => {
      switch (getPhase(model)) {
        case 'rejected':
        case 'cancel rejected':
          return 'danger';
        case 'accepted':
          return 'success';
        case 'declared':
        case 'cancel declared':
          return 'info';
        default:
          return 'secondary';
      }
    };

  }
}
