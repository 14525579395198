<app-table-view [fields]="fieldsDefinition" [data]="_data" [recordTrackBy]="trackByContainerNumber"
                (termUpdated)="term = $event" [editable]="false"></app-table-view>

<ng-template #actionColumnRef let-record="value">
  <div class="form-check">
    <input class="form-check-input" type="checkbox" [(ngModel)]="record['selected']">
  </div>
</ng-template>

<ng-template #equipmentRef let-equipment="value">
  <div class="container-wrapper">
    <div class="d-inline-flex placement-item pe-2">
      <span class="sub-text me-2">
        <i class="fa fa-fw fa-light fa-xl" [ngClass]="utils.getEquipmentIconCls(equipment.sizeType, equipment.empty)"></i>
      </span>
      <span class="d-inline-block text-truncate align-self-end">
        <app-highlight [term]="term" [result]="equipment.containerIdentificationNumber"></app-highlight>
      </span>
    </div>
  </div>
</ng-template>

<ng-template #goodsRef let-goods="value">
  <app-consignment-goods-column [term]="term" [goods]="goods"></app-consignment-goods-column>
</ng-template>
