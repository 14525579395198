import {Component, forwardRef} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {AbstractValueAccessorComponent} from '../../../../common/component/value-accessor.component';
import {OnCarriageDetails} from '@portbase/bezoekschip-service-typescriptmodels';
import {PortvisitUtils} from '../../../../refdata/portvisit-utils';

@Component({
  selector: 'app-on-carriage-details',
  templateUrl: './on-carriage-details.component.html',
  styleUrls: ['./on-carriage-details.component.css'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => OnCarriageDetailsComponent), multi: true}
  ],
})
export class OnCarriageDetailsComponent extends AbstractValueAccessorComponent<OnCarriageDetails> {
  utils = PortvisitUtils;
  model : OnCarriageDetails = <any>{};

  get value(): OnCarriageDetails {
    return this.model;
  }

  writeValue(value: OnCarriageDetails): void {
    if (value) {
      this.model = value;
    } else {
      this.model = <any>{};
      this.onModelChange();
    }
  }
}
