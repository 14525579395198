import {Component, Directive, EventEmitter, Input, Output} from "@angular/core";
import {VisitContext} from "../../../visit-context";
import {sendQuery} from "../../../../common/utils";
import {DeclarationType, ExitPoint} from "@portbase/bezoekschip-service-typescriptmodels";
import moment from "moment";
import {AppContext} from "../../../../app-context";

@Directive()
export abstract class BaseExitPointComponent{
	context = VisitContext;

  @Input()
  exitPoint:ExitPoint;
  @Output('exitPointChange')
  exitPointUpdate = new EventEmitter();

  @Input()
  etdPort:string;
  @Output('etdPortChange')
  etdPortUpdate = new EventEmitter();

  @Input()
  atdPort:string;
  @Output('atdPortChange')
  atdPortUpdate = new EventEmitter();

  exitPointProvider = sendQuery('com.portbase.bezoekschip.common.api.visit.GetAccessPoints',
    {portUnCode: VisitContext.visit.portOfCall.port.locationUnCode});

  exitPointFormatter = (exitPoint: ExitPoint) => exitPoint ? exitPoint.name : '';

  public abstract onPortEtdChanged(event: any): void;
  public abstract onExitPointChanged(event: any): void;
}

@Component({
  selector: 'app-next-exit-point',
  templateUrl: './next-exit-point.component.html',
  styleUrls: ['./exit-point.component.css']
})
export class NextExitPointComponent extends BaseExitPointComponent{
  onPortEtdChanged(event: any): void {
    this.etdPortUpdate.emit(event);
  }

  onExitPointChanged(event: any): void {
    this.exitPointUpdate.emit(event);
  }
}

@Component({
  selector: 'app-exit-point',
  templateUrl: './exit-point.component.html',
  styleUrls: ['./exit-point.component.css']
})
export class ExitPointComponent extends BaseExitPointComponent{

  @Input()
  etaPort:string;

  @Input()
  atdPort:string;

  @Input()
  atdPortReported:boolean;

  onPortEtdChanged(event: any) {
    this.etdPortUpdate.emit(event);
    VisitContext.shiftTime(VisitContext.visit.visitDeclaration.portVisit, 'etdPort', event)
  }

  onExitPointChanged(event: any): void {
    this.exitPointUpdate.emit(event);
  }

  etdPortIsSameOrAfterNextPort() {
    if (VisitContext.visit.visitDeclaration.nextPorts.length === 0) {
      return false;
    }
    const portVisit = VisitContext.visit.visitDeclaration.portVisit;
    const firstNextPort = VisitContext.visit.visitDeclaration.nextPorts[0];
    return !portVisit.atdPort && portVisit.etdPort && firstNextPort.arrival
      && moment(portVisit.etdPort).isSameOrAfter(moment(firstNextPort.arrival));
  }

	etdPortIsSameOrBeforeLastBerthWithVisitTime() {
    if (VisitContext.visit.visitDeclaration.portVisit.berthVisits.length === 0) {
      return false;
    }
    const portVisit = VisitContext.visit.visitDeclaration.portVisit;
    var berthVisitsWithVisitTimes = portVisit.berthVisits.filter(value => value.etd || value.eta || value.ata || value.ata);
    if(berthVisitsWithVisitTimes.length === 0) {
    	return false;
		}
		var lastBerthVisitWithVisitTime = berthVisitsWithVisitTimes[berthVisitsWithVisitTimes.length -1];
    const lastBerthDate = lastBerthVisitWithVisitTime.atd ? lastBerthVisitWithVisitTime.atd :
      lastBerthVisitWithVisitTime.etd ? lastBerthVisitWithVisitTime.etd :
        lastBerthVisitWithVisitTime.ata ? lastBerthVisitWithVisitTime.ata : lastBerthVisitWithVisitTime.eta;
    return !portVisit.atdPort && portVisit.etdPort && lastBerthDate && moment(lastBerthDate).isSameOrAfter(moment(portVisit.etdPort));
  }

  showAtdPort() : any {
    if (VisitContext.visit.portOfCall.atdPortReported) {
      return VisitContext.visit.visitDeclaration.portVisit.atdPort;
    }
    if (VisitContext.visit.portOfCall.swDeclarationRequired || VisitContext.visit.portOfCall.paDeclarationRequired) {
      return VisitContext.visit.visitDeclaration.portVisit.atdPort
        || VisitContext.visit.visitDeclaration.portVisit.berthVisits.every(b => !!b.ata || !!b.atd);
    }
    return !!VisitContext.findLatestDeclaration(DeclarationType.WPCS);
  }

  hasAtdPort() : boolean {
    return !!VisitContext.visit.visitDeclaration.portVisit.atdPort;
  }

  isWaitingForOrders() {
    return VisitContext.isWaitingForOrders();
  }

  isAdmin() {
    return AppContext.isAdmin();
  }

  atdPortInFuture: boolean = false;
  onAtdPortChanged($event) {
    this.atdPortInFuture =  moment($event).isAfter(moment.now());
    this.atdPortUpdate.emit($event);
  }
}
