<div class="mx-auto mb-5" style="max-width: 1150px">

  <!-- Filters -->
  <div class="row mt-2 px-1 d-flex justify-content-between">
    <div class="col-auto mx-2">
      <app-visit-filter (changed)="applyVisitFilters($event)"></app-visit-filter>
    </div>

    <div class="col-md-4 col-xs-12 pe-5" style="min-width: 19rem">
      <app-date-range [ngModel]="overviewFilter.dateRange" (ngModelChange)="onDateSelection($event)"
                      [disabled]="!!overviewFilter.searchTerm"></app-date-range>
    </div>
    <div class="col mb-3">
      <app-filter [(ngModel)]="overviewFilter.searchTerm" (ngModelChange)="from = 0; endReached = false"
                  [showTooltip]="true"
                  [minCharacters]="3" [searchFunction]="searchFunction" (resultsFound)="renderVisits($event)"
                  placeholder="Search by CRN, vessel, port or client reference"></app-filter>
    </div>
    <div
      *ngIf="appContext.isVisitDeclarant() && (appContext.isCargoDeclarant() || appContext.isDangerousGoodsReporter() || appContext.isTerminalOperatorViewer()) && !appContext.isAdminOrCustoms()"
      class="col-auto form-check form-switch mt-2 ms-3 mb-3 me-n3 me-lg-n4 me-xl-n5" style="min-width: 10rem">
      <input type="checkbox" class="form-check-input" id="ownCallsOnly"
             [(ngModel)]="overviewFilter.ownCallsOnly" (ngModelChange)="loadVisits()">
      <label class="form-check-label"
             for="ownCallsOnly">{{overviewFilter.ownCallsOnly ? 'Own calls only' : 'All calls'}}</label>
    </div>
  </div>

  <!--Visits-->
  <app-scroller (endReached)="loadNextPage()" [windowScroll]="true">
    <div class="mx-sm-0 visit-container" *ngIf="visitOverview">

      <ng-container *ngFor="let category of categories">
        <ng-container *ngIf="category.visible">
          <app-collapse [collapse]="collapse" [showOnLoad]="category.showOnLoad" [animateOnLoad]="false"
                        [hideCollapseIcon]="true">
            <div class="row py-2 px-3 bg-portbase-blue text-white">
              <div class="col-auto">
                <h4 class="m-0 fa fa-chevron-right"></h4>
              </div>
              <div class="col fw-bold">
                <h5 class="my-0 align-bottom">{{category.name | titlecase}}</h5>
              </div>
            </div>

            <ng-template #collapse>
              <div class="collapse px-sm-2">
                <a *ngFor="let visitSummary of getVisits(category)" (click)="navigateToVisit(visitSummary)"
                   class="mb-2 text-decoration-none cursor-pointer">

                  <div class="row g-0 align-items-center text-dark border border-top-0 p-2 link">
                    <div class="col">
                      <div class="row narrow-gutters align-items-center">

                        <div class="col-md-3 col-lg-4">
                          <div>
                            <h5
                              class="d-inline-block text-portbase-blue m-0">{{visitSummary.vesselName | titlecase}}</h5>
                            <span *ngIf="visitSummary['tidalShip']" class="ms-2 fa text-info fa-fw fa-water"
                                  title="Tidal restricted"></span>
                            <span *ngIf="!!visitSummary.arrivalVoyageNumber" title="Arrival voyage number"
                                  class="small align-text-top">
                              <span class="ms-2 fa fa-dharmachakra text-secondary"></span>
                              {{visitSummary.arrivalVoyageNumber}}
                            </span>
                          </div>
                          <div class="d-none d-lg-block" *ngIf="appContext.isAdmin() || appContext.isCustoms()">
                          <span class="small">
                            {{visitSummary.crn}}
                          </span>
                          </div>
                        </div>

                        <div class="col" style="max-width: 22rem">
                          <div class="d-none d-lg-block text-portbase-blue fst-italic text-truncate"
                               *ngIf="appContext.isAdmin() || appContext.isCustoms() ? responsibleParties(visitSummary) : forOwner(visitSummary) as otherDeclarant">
                            <span>{{otherDeclarant}}</span>
                          </div>
                          <div [ngSwitch]="visitSummary.visitStatus">
                            <ng-container *ngSwitchCase="'EXPECTED'">
                              <span class="fa fa-sign-in-alt text-secondary me-2"></span>
                              <span>{{visitSummary.portName}}, </span>
                              <span>{{formatDate(visitSummary.etaPort)}}</span>
                              <app-tooltip class="ms-2" *ngIf="showEtaPortAis(visitSummary)">
                                <span class="fa fa-exclamation-triangle text-warning"></span>
                                <div class="tooltipContent">AIS ETA: {{formatDate(visitSummary.etaPortAis)}}</div>
                              </app-tooltip>
                              <ng-container *ngIf="visitSummary.dependencyType === 'EXCHANGE'">
                              <span
                                class="fa fa-fw fa-exchange-alt text-secondary mx-1"></span><span>{{visitSummary.dependentVesselName | titlecase}}</span>
                              </ng-container>
                              <ng-container *ngIf="visitSummary.dependencyType === 'ENTRY_AFTER'">
                              <span
                                class="fa fa-fw fa-angle-double-right text-secondary mx-1"></span><span>{{visitSummary.dependentVesselName | titlecase}}</span>
                              </ng-container>

                            </ng-container>

                            <ng-container *ngSwitchCase="'ARRIVED'">
                              <span class="fa fa-sign-out-alt text-secondary me-2"></span>
                              <span *ngIf="visitSummary.nextBerthDeparture?.name as name">{{name | titlecase}}</span>
                              <span *ngIf="visitSummary.nextBerthDeparture?.etd as etd">, {{formatDate(etd)}}</span>
                            </ng-container>

                            <ng-container *ngSwitchCase="'DEPARTED'">
                              <span class="fa fa-sign-out-alt text-secondary me-2"></span>
                              <span>{{visitSummary.portName}}, </span>
                              <span>{{formatDate(visitSummary.atdPort)}}</span>
                            </ng-container>

                          </div>
                        </div>

                        <div class="ms-auto col-3 text-end d-none d-md-block" style="min-width: 20rem">
                          <app-all-clearances-status *ngIf="appContext.isClearancesActive()" [summary]="visitSummary">
                          </app-all-clearances-status>
                          <ng-container *ngIf="getInspectionStatus(visitSummary) as inspectionStatus">
                          <span class="fa ps-1 pe-2 {{inspectionStatus.icon}} {{inspectionStatus.color}}"
                                [title]="inspectionStatus.tooltip"></span>
                          </ng-container>
                          <ng-container *ngIf="getOrderStatus(visitSummary) as orderStatus">
                          <span class="fa px-2 {{orderStatus.icon}} {{orderStatus.color}}"
                                [title]="orderStatus.tooltip"></span>
                          </ng-container>
                          <ng-container *ngIf="!!visitSummary.harbourMasterRemarks">
                          <span class="fa px-2 fa-comment-alt {{hasUnreadRemarks(visitSummary)}}"
                                [title]="visitSummary.harbourMasterRemarks"></span>
                          </ng-container>

                          <ng-container *ngFor="let task of visitSummary.calculatedOpenTasks;">
                            <span class="fa px-2 {{task.icon}} {{task.color}}" [title]="task.tooltip"></span>
                          </ng-container>
                          <h4
                            class="d-none d-md-inline fa fa-angle-right ms-2 my-0 align-bottom text-portbase-blue"></h4>
                        </div>
                      </div>
                    </div>

                    <div class="col-auto d-md-none">
                      <ng-container *ngIf="getOrderStatus(visitSummary) as orderStatus">
                        <span class="fa pe-2 {{orderStatus.icon}} {{orderStatus.color}}"></span>
                      </ng-container>
                      <span *ngIf="getTaskCount(visitSummary) > 0"
                            [ngClass]="{'bg-danger' : visitSummary.rejectedTasks.length > 0,
                                        'bg-success' : visitSummary.rejectedTasks.length === 0}"
                            class="me-2 badge rounded-pill" style="font-size: 100%">{{getTaskCount(visitSummary)}}
                      </span>
                      <h4 class="fa fa-angle-right my-0 align-bottom text-portbase-blue"></h4>
                    </div>

                  </div>
                </a>
              </div>
            </ng-template>
          </app-collapse>
        </ng-container>
      </ng-container>

    </div>
  </app-scroller>
</div>


