import {Component, ElementRef, Input} from '@angular/core';
import {checkValidity, sendCommand, sendQuery} from "../../common/utils";
import {
  IE3CancelTranshipment,
  IE3ConsignmentProcess,
  IE3GetConsignment,
  IE3Transhipment,
  IE3TranshipmentSummary
} from "@portbase/bezoekschip-service-typescriptmodels";
import {Observable} from "rxjs";
import {AppContext} from "../../app-context";
import {PortvisitUtils} from "../../refdata/portvisit-utils";

@Component({
  selector: 'app-view-transhipment',
  templateUrl: './view-transhipment.component.html',
  styleUrls: ['./view-transhipment.component.css']
})
export class ViewTranshipmentComponent {

  constructor(private element: ElementRef) {
  }

  appContext = AppContext;

  @Input() summary: IE3TranshipmentSummary;
  consignmentProcess$: Observable<IE3ConsignmentProcess>;

  ngOnInit() {
    this.consignmentProcess$ = sendQuery('com.portbase.bezoekschip.common.api.consignments.queries.GetConsignment',
      <IE3GetConsignment>{
        consignmentProcessId: this.summary.cargoDeclarantIamConnectedId + "_" + this.summary.consignmentNumber
      });
  }

  openMessages(type: string) {
    PortvisitUtils.fetchProcessIdAndOpenMessageManagement("ren-out-" + this.summary.crn + "-" + this.summary.consignmentNumber);
  }

  getRejectReasons = (transhipment: IE3Transhipment): string[] => {
    const reasons: string[] = [];
    if (transhipment.renStatus && transhipment.renStatus.status === "REJECTED" && transhipment.renStatus.reason) {
      reasons.push(transhipment.renStatus.reason);
    }
    if (transhipment.midStatus && transhipment.midStatus.status === "REJECTED" && transhipment.midStatus.reason) {
      reasons.push(transhipment.midStatus.reason);
    }
    return reasons;
  }

  cancel(form: HTMLElement) {
    if (checkValidity(form)) {
      sendCommand('com.portbase.bezoekschip.common.api.consignments.commands.CancelTranshipment',
        <IE3CancelTranshipment>{
          cargoDeclarantId: this.summary.cargoDeclarantIamConnectedId,
          consignmentNumber: this.summary.consignmentNumber
        },
        () => AppContext.registerSuccess('The declaration was cancelled successfully.'));
      const modal = $('#cancelTranshipment');
      modal.modal('hide');
    }
  }
}
