<div class="dropend always-enabled">
  <div data-bs-toggle="dropdown" style="cursor: pointer;">
    <ng-content></ng-content>
  </div>
  <div class="dropdown-menu m-2">
    <ng-container *ngIf="isAddingFirstBerth; then firstBerth; else additionalBerths">
    </ng-container>
    <ng-template #firstBerth>
      <a class="dropdown-item" (click)="addFirstBerth()" [ngClass]="{'disabled' : !isPermittedToAddFirstBerth()}">Add berth</a>
    </ng-template>
    <ng-template #additionalBerths>
      <a class="dropdown-item" href="#" (click)="$event.preventDefault(); addBerthBefore()" [ngClass]="{'disabled' : !isPermittedToAddBerthBefore()}">Add berth before</a>
      <div class="d-flex align-items-center">
        <div class="flex-fill">
          <a class="dropdown-item" href="#" (click)="$event.preventDefault(); addBerthAfter()" [ngClass]="{'disabled' : !isPermittedToAddBerthAfter()}">Add berth after</a>
        </div>
        <div *ngIf="isBerthOfTransfer()" class="flex-fill me-2">
          <i class="fa fa-sm fa-people-arrows text-warning" title="Only {{this.context.visit.nextOwner.fullName}} can add berths after the berth of transfer" aria-hidden="true"></i>
        </div>
      </div>
      <a *ngIf="isPermittedToAddTerminalPlanningBefore()" class="dropdown-item" href="#" (click)="$event.preventDefault(); addTerminalPlanningBefore()">Add terminal planning before</a>
      <a *ngIf="isPermittedToAddTerminalPlanningAfter()" class="dropdown-item" href="#" (click)="$event.preventDefault(); addTerminalPlanningAfter()">Add terminal planning after</a>
      <a class="dropdown-item" href="#" (click)="$event.preventDefault(); moveBerthUp()" [ngClass]="{'disabled' : !isPermittedToMoveBerthUp()}">Move berth up</a>
      <a class="dropdown-item" href="#" (click)="$event.preventDefault(); moveBerthDown()" [ngClass]="{'disabled' : !isPermittedToMoveBerthDown()}">Move berth down</a>
      <div class="d-flex align-items-center">
        <div class="flex-fill">
          <a class="dropdown-item" href="#" (click)="$event.preventDefault(); deleteBerth()" [ngClass]="{'disabled' : !isPermittedToDeleteBerth()}">Delete berth</a>
        </div>
        <div *ngIf="hasWasteItemsScheduledForPickup()" class="flex-fill me-2">
          <i class="fa fa-sm fa-recycle text-warning" title="Cannot delete berth where waste is declared" aria-hidden="true"></i>
        </div>
        <div *ngIf="isBerthOfTransfer()" class="flex-fill me-2">
          <i class="fa fa-sm fa-people-arrows text-warning" title="Cannot delete berth of transfer" aria-hidden="true"></i>
        </div>
      </div>
    </ng-template>
  </div>
</div>
