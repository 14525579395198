<ng-container *ngFor="let quay of stevedore?.quaysForPlanning">
  <ng-container *ngIf="loadDischargeMap.has(quay)">
    <app-form-group label="Discharge {{quay}}">
      <input [(ngModel)]="loadDischargeMap.get(quay).discharge" (ngModelChange)="updateVisitContextLoadDischargeInfo()"
             required
             id="{{stevedore.shortName}}-{{quay}}-discharge" appDecimalNumber [maxDecimals]="0" class="form-control"
             (change)="onValueChanged();">
    </app-form-group>
    <app-form-group label="Load {{quay}}">
      <input [(ngModel)]="loadDischargeMap.get(quay).load" (ngModelChange)="updateVisitContextLoadDischargeInfo()"
             required
             id="{{stevedore.shortName}}-{{quay}}-load" appDecimalNumber [maxDecimals]="0" class="form-control"
             (change)="onValueChanged();">
    </app-form-group>
    <app-form-group label="Restow {{quay}}">
      <input [(ngModel)]="loadDischargeMap.get(quay).restow" (ngModelChange)="updateVisitContextLoadDischargeInfo()"
             id="{{stevedore.shortName}}-{{quay}}-restow" appDecimalNumber [maxDecimals]="0" class="form-control"
             (change)="onValueChanged();">
    </app-form-group>
  </ng-container>
</ng-container>
<app-form-group>
  <app-info class="formGroupLabel">
    <label>Operator remarks</label>
    <div class="tooltipContent">These remarks are shared between operator and terminal</div>
  </app-info>
  <textarea class="form-control" [(ngModel)]="berthVisit.operatorRemarks"></textarea>
</app-form-group>
