import {Component, Input} from '@angular/core';
import {DischargeList} from '../../cargo-import.model';
import {Declaration} from "@portbase/bezoekschip-service-typescriptmodels";
import moment from "moment";
import {VisitContext} from "../../../visit-context";
import {PortvisitUtils} from '../../../../refdata/portvisit-utils';

@Component({
  selector: 'app-discharge-list-admin',
  templateUrl: './discharge-list-admin.component.html',
  styleUrls: ['./discharge-list-admin.component.css']
})
export class DischargeListAdminComponent {
  context = VisitContext;

  @Input() lists: DischargeList[];

  openMessages(declaration: Declaration) {
    PortvisitUtils.redirectToMessageManagement('COPRAR' + moment(declaration.timeStamp).format('YYMMDDHHmmssSSS')
      + '_' + declaration.id);
  }

  getDeclarations(dischargeList: DischargeList) {
    return dischargeList.declarations.filter(d => 'COPRAR' === d.type);
  }
}
