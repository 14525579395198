import {Component, Input} from '@angular/core';
import {ClearanceService} from "./clearance-service";
import {VisitContext} from "../../visit-context";
import {AttentionPointContext, ClearanceStatus, MessageStatus} from "@portbase/bezoekschip-service-typescriptmodels";
import {AppContext} from "../../../app-context";

@Component({
  selector: 'app-movement-clearance-status',
  templateUrl: './ekh-clearance-status.component.html',
  styleUrls: ['./ekh-clearance-status.component.scss']
})
export class EkhClearanceStatusComponent {
  @Input() tooltipTitle: string;
  @Input() clearanceKey: AttentionPointContext;
  @Input() arrivalBerthVisitId?: string;
  @Input() departureBerthVisitId?: string;

  constructor(private clearanceService: ClearanceService) {
  }

  get clearance() {
    return this.clearanceService.getClearance(VisitContext.visit?.clearances, this.clearanceKey, this.arrivalBerthVisitId, this.departureBerthVisitId);
  }

  getMessageStatus(): MessageStatus {
    switch (this.clearance?.status) {
      case ClearanceStatus.APPROVED:
        return MessageStatus.ACCEPTED;
      case ClearanceStatus.REJECTED:
        return MessageStatus.REJECTED;
      case ClearanceStatus.PENDING:
        return MessageStatus.PENDING;
      case ClearanceStatus.APPROVED_WITH_REMARKS:
        return MessageStatus.WARNING;
      case ClearanceStatus.UNKNOWN:
        return MessageStatus.UNKNOWN;
      default:
        return null;
    }
  }

  get getTooltipMessage(): string {
    switch (this.clearance?.status) {
      case ClearanceStatus.APPROVED:
        return "The Port Authority has given clearance status 'Approved' for this movement.";
      case ClearanceStatus.APPROVED_WITH_REMARKS:
        return "The Port Authority has given clearance status 'Actions required' for this movement.";
      case ClearanceStatus.REJECTED:
        return "The Port Authority has given clearance status 'Rejected' for this movement.";
      case ClearanceStatus.PENDING:
        return "The Port Authority has given clearance status 'Pending' for this movement.";
      case ClearanceStatus.UNKNOWN:
        return "The Port Authority has not yet determined whether approval of the movement can be granted.";
    }
  }

  get getTooltipHeader(): string {
    switch (this.clearance?.status) {
      case ClearanceStatus.APPROVED:
        return "Approved";
      case ClearanceStatus.APPROVED_WITH_REMARKS:
        return "Actions required";
      case ClearanceStatus.REJECTED:
        return "Rejected";
      case ClearanceStatus.UNKNOWN:
        return "Pending";
      default:
        return "Pending";
    }
  }

  isClearancesActive() : boolean {
    return AppContext.isClearancesActive();
  }


}
