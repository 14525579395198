<div class="card-body">
  <div class="row">
    <div class="col-6 fw-bold">Itinerary</div>
    <div class="col-2 fw-bold">Arrival</div>
    <div class="col-2 fw-bold">Departure</div>
    <div class="col-2 fw-bold">Status</div>
  </div>
  <hr>
  <ng-container *ngFor="let berthVisit of berthVisits">
    <div class="row">
      <div class="col-6">{{berthVisit.name}} ({{berthVisit.code}})</div>
      <div
          class="col-2">{{berthVisit.ata ? utils.formatDate(berthVisit.ata) : utils.formatDate(berthVisit.eta)}}</div>
      <div
          class="col-2">{{berthVisit.atd ? utils.formatDate(berthVisit.atd) : utils.formatDate(berthVisit.etd)}}</div>
      <div class="col-2">{{getStatus(berthVisit)}}</div>
    </div>
  </ng-container>
</div>
