import * as Sentry from '@sentry/browser';
import { ErrorHandler, Injectable } from '@angular/core';
import {environment} from '../environments/environment';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    if (environment.production) {
      console.warn(err);
      Sentry.captureException(err);
    } else {
      console.error(err);
    }
  }
}
