import {Component, Input} from '@angular/core';
import {BerthVisitSelection} from "@portbase/bezoekschip-service-typescriptmodels";

@Component({
  selector: 'app-selection-bunkering-berth-visits',
  templateUrl: './selection-bunkering-berth-visits.component.html',
  styleUrls: ['../../bunkering.scss', './selection-bunkering-berth-visits.component.css']
})
export class SelectionBunkeringBerthVisitsComponent {

  @Input()
  selection: BerthVisitSelection;

}
