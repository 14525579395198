<app-collapse [rounded]="true" [collapse]="readonly ? null : collapsePortDepartureDetails"
              [showOnLoad]="(!context.visit.terminalPlanningEnabled && !context.hasBeenDeclared()) ||
               (context.visitInTerminalPlanning() && !context.visit.visitDeclaration?.departureVoyage)"
              [animateOnLoad]="true"
              [validationModel]="context.visit.visitDeclaration.departureVoyage"
              [renderWhenCollapsed]="true">
  <!-- port departure heading -->
  <div class="row p-3 g-0 align-items-center text-dark">
    <div class="col fw-bold">
      <span>{{portDepartureTitle}}</span>
    </div>
    <div class="col-auto d-none d-md-block text-dark">
      <!-- Placeholder for icons to the right -->
    </div>
  </div>
</app-collapse>
<ng-template #collapsePortDepartureDetails>
  <div class="collapse">
    <div class="border rounded-bottom p-3">
      <div class="row">
        <div class="col-md-6" *ngIf="!context.visitInTerminalPlanning()">
          <!-- Current declarant -->
          <app-exit-point *ngIf="!userIsNextOwnerOrDeclarant()"
            [(exitPoint)]="context.visit.visitDeclaration.portVisit.exitPoint"
            [(etdPort)]="context.visit.visitDeclaration.portVisit.etdPort"
            [(atdPort)]="context.visit.visitDeclaration.portVisit.atdPort"
            [etaPort]="context.visit.visitDeclaration.portVisit.etaPort"
            [atdPortReported]="context.visit.portOfCall.atdPortReported"></app-exit-point>
          <!-- Next declarant -->
          <div *ngIf="userIsNextOwnerOrDeclarant()" class="always-enabled validate-for-next-declarant">
            <app-next-exit-point [(exitPoint)]="context.visit.nextVisitDeclaration.exitPoint"
              [(etdPort)]="context.visit.nextVisitDeclaration.etdPort">
            </app-next-exit-point>
          </div>
        </div>
        <div class="col-md-6">
          <!-- Voyage -->
          <ng-container *ngIf="userIsNextOwnerOrDeclarant(); then nextDepartureVoyage else departureVoyage">
          </ng-container>
          <ng-template #departureVoyage>
            <div class="fieldset">
              <app-departure-voyage [voyage]="context.visit.visitDeclaration.departureVoyage">
              </app-departure-voyage>
            </div>
          </ng-template>
          <ng-template #nextDepartureVoyage>
            <div [class]="'always-enabled'">
              <app-departure-voyage [(voyage)]="context.visit.nextVisitDeclaration.departureVoyage">
              </app-departure-voyage>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</ng-template>
