import {Component, Input} from '@angular/core';
import {
  BezoekschipOrganisation,
  Carrier,
  FindCargoDeclarants,
  GetCarrierAddress,
  IE3Address
} from "@portbase/bezoekschip-service-typescriptmodels";
import {PortvisitUtils} from "../portvisit-utils";
import {sendQuery} from "../../common/utils";
import {AppContext} from "../../app-context";
import lodash from "lodash";

@Component({
  selector: 'app-edit-carrier',
  templateUrl: './edit-carrier.component.html',
  styleUrls: ['./edit-carrier.component.css']
})
export class EditCarrierComponent {
  utils = PortvisitUtils;
  appContext = AppContext;
  @Input() _carrier: Carrier;
  unsavedChanges: boolean = false;
  organisationFound: boolean = false;

  @Input()
  set carrier(carrier: Carrier) {
    this._carrier = carrier;
    if (carrier.cargoDeclarantShortName) {
      if (!carrier.boxOperators) {
        carrier.boxOperators = [];
      }
      if (!carrier.boxOperators.includes(carrier.cargoDeclarantShortName)) {
        carrier.boxOperators.unshift(carrier.cargoDeclarantShortName);
      }
    }
    if (!carrier.address) {
      carrier.address = {};
      if (carrier.customsId) {
        this.updateAddress(true);
      }
    }
  }

  searchCargoDeclarant = term => sendQuery('com.portbase.bezoekschip.common.api.authorisation.FindCargoDeclarants',
    <FindCargoDeclarants>{term: term});

  setOrganisation = (organisation: BezoekschipOrganisation) => {
    if (!organisation) {
      this._carrier.cargoDeclarantShortName = null;
    } else {
      this._carrier.cargoDeclarantShortName = organisation.shortName;
    }
  };

  setBoxOperators = (boxOperators: any[]) => {
    if (!boxOperators) {
      this._carrier.boxOperators = [];
    } else {
      this._carrier.boxOperators = boxOperators
        .map(operator => operator ? (typeof operator === "string" ? operator : operator.shortName) : "")
        .filter(operator => operator && typeof operator === "string" && operator.trim().length > 0);
    }
  };

  updateAddress = (automatically?: boolean) => sendQuery("com.portbase.bezoekschip.common.api.authorisation.GetCarrierAddress", <GetCarrierAddress> {
    eoriNumber: this._carrier.customsId
  }).subscribe((a: IE3Address) => {
    this.unsavedChanges = automatically && !lodash.isEqual(a, this._carrier.address);
    this._carrier.address = a || this._carrier.address;
    this.organisationFound = !!a;
  });
}
