<ng-container *ngIf="houseConsignment">
  <div class="modal-header">
    <div class="flex-container align-items-center">
      <button type="button" class="btn-close me-2" data-bs-dismiss="modal" aria-label="Close"></button>
      <div class="d-inline-flex me-1">
        <i class="fa fa-fw fa-light fa-lg fa-file-lines text-muted"></i>
      </div>
      <button class="btn btn-link p-0 page-title align-baseline" (click)="processModelAndOpenMasterConsignment()">
        <span class="page-title">{{ consignmentProcess.consignmentMasterLevel.consignmentNumber }}</span>
      </button>
      <app-tooltip *ngIf="consignmentProcess.cancelled" placement="bottom" class="ms-2">
        <span class="fa text-danger fa-fw fa-ban"></span>
        <span class="tooltipContent">Cancelled</span>
      </app-tooltip>
      <div class="d-inline-flex mx-2">
        <i class="fa fa-fw fa-light fa-lg fa-chevron-right text-muted"></i>
      </div>
      <div class="d-inline-flex me-1">
        <i class="fa fa-kit fa-fw fa-light fa-lg fa-pb-file-line text-muted"></i>
      </div>
      <span class="page-title">{{ houseConsignment.consignmentNumber }}</span>
    </div>
  </div>

  <div class="modal-body p-0">
    <app-tab-panel [sticky]="true" [alwaysRender]="true">
      <app-tab-item [templateRef]="detailsRef">
        <span class="tab-item">Details</span>
        <ng-template #detailsRef>
          <div class="container py-3">
            <div class="card">
              <div class="card-body p-5">
                <div class="row gy-4 my-0">
                  <h5 class="h1 col-12 m-0">House bill of lading details</h5>
                  <div class="col-12">
                    <div class="row gy-4">
                      <app-form-field label="House bill of lading number" class="col-12 col-md-6"
                                      [(value)]="houseConsignment.consignmentNumber"
                                      [includeMargin]="false" [editMode]="editMode" [renderOptional]="false">
                        <ng-container *ngIf="isNewConsignment else blAsLabel">
                          <input class="input-group rounded border form-control"
                                 [(ngModel)]="houseConsignment.consignmentNumber" required/>
                        </ng-container>
                        <ng-template #blAsLabel>
                          <span>{{ houseConsignment.consignmentNumber }}</span>
                        </ng-template>
                      </app-form-field>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row gy-4">
                      <app-form-field label="Trader assigned reference" class="col-6 col-md-4"
                                      [(value)]="houseConsignment.traderAssignedReference" [editMode]="editMode"
                                      [includeMargin]="false">
                        <input class="input-group rounded border form-control"
                               [(ngModel)]="houseConsignment.traderAssignedReference"/>
                      </app-form-field>
                    </div>
                  </div>
                  <ng-container *ngIf="editMode">
                    <div class="col-12">
                      <div class="row gy-4">
                        <app-form-field label="Place of acceptance" class="col-6 col-md-4"
                                        [(value)]="houseConsignment.placeOfAcceptance"
                                        [editMode]="editMode"
                                        [includeMargin]="false" [formatter]="utils.placeFormatter">
                          <app-search [(ngModel)]="houseConsignment.placeOfAcceptance"
                                      [inputFormatter]="refData.locationFormatter" (ngModelChange)="updateRoutes()"
                                      [required]="utils.hasHouseLevelConsignments(consignmentProcess)"
                                      [searchFunction]="refData.findLocation"></app-search>
                        </app-form-field>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="row gy-4">
                        <app-form-field label="Place of delivery" class="col-6 col-md-4"
                                        [(value)]="houseConsignment.placeOfDelivery"
                                        [editMode]="editMode" [includeMargin]="false" [formatter]="utils.placeFormatter">
                          <app-search [(ngModel)]="houseConsignment.placeOfDelivery"
                                      [inputFormatter]="refData.locationFormatter" (ngModelChange)="updateRoutes()"
                                      [required]="utils.hasHouseLevelConsignments(consignmentProcess)"
                                      [searchFunction]="refData.findLocation"></app-search>
                        </app-form-field>
                      </div>
                    </div>
                  </ng-container>
                  <div *ngIf="!editMode || !consignmentProcess.filing.crn" class="col-12">
                    <span class="d-block fw-regular mb-3">Route</span>
                    <p *ngIf="editMode">A minimum of 2 countries is required, one of which must be a member of the
                      European Union.</p>
                    <div class="consignment-routes row gy-4">
                      <div class="col-12" *ngFor="let route of routes; let i = index">
                        <app-master-consignment-route [routeInfo]="route" [index]="i" [editMode]="editMode"
                                                      [(allRoutes)]="routes"></app-master-consignment-route>
                      </div>
                    </div>
                    <div *ngIf="editMode" class="row mt-3">
                      <div class="col-auto">
                        <button class="btn btn-outline-primary text-decoration-none" (click)="addRoute()">
                          <i class="button-icon fa-light fa-plus"></i>
                          <span class="ps-2 d-none d-md-inline">Add country</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-12" *ngIf="houseConsignment.transportCharges">
                    <div class="row">
                      <app-form-field label="Payment method" class="col col-md-6"
                                      [(value)]="houseConsignment.transportCharges.methodOfPayment"
                                      [editMode]="editMode" [includeMargin]="false"
                                      [formatter]="utils.methodOfPaymentFormatter">
                        <app-select [(ngModel)]="houseConsignment.transportCharges.methodOfPayment"
                                    [options]="utils.methodsOfPayment"
                                    [required]="utils.hasHouseLevelConsignments(consignmentProcess)"
                                    [formatter]="utils.methodOfPaymentFormatter"></app-select>
                      </app-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </app-tab-item>
      <app-tab-item [templateRef]="supplyChainActorsRef">
        <span class="tab-item">Parties</span>
        <ng-template #supplyChainActorsRef>
          <div class="container py-3">
            <div class="card">
              <div class="card-body p-5">
                <div class="row gy-4 my-0">
                  <h5 class="h1 col-12 m-0">Supply chain actors</h5>
                  <div class="col-12">
                    <app-consignment-party-details [(party)]="houseConsignment.consignee" [editMode]="editMode"
                                                   [cargoDeclarantId]="consignmentProcess.filing.declarant.iamConnectedId"
                                                   label="Consignee"></app-consignment-party-details>
                  </div>
                  <div class="col-12">
                    <app-consignment-party-details [(party)]="houseConsignment.consignor" [editMode]="editMode"
                                                   [cargoDeclarantId]="consignmentProcess.filing.declarant.iamConnectedId"
                                                   [required]="true" label="Consignor"></app-consignment-party-details>
                  </div>
                  <div class="col-12" *ngIf="consignmentProcess.filing.filingType === 'F13'">
                    <app-consignment-supplementary-declarant
                      [(supplementaryDeclarant)]="houseConsignment.supplementaryDeclarant"
                      [supplementaryFilingType]="'N2'"
                      [editMode]="editMode" required label="Supplementary declarant"></app-consignment-supplementary-declarant>
                  </div>
                  <ng-container *ngIf="houseConsignment.goodsShipment && consignmentProcess.filing.filingType !== 'F13'">
                    <div class="col-12">
                      <app-consignment-party-details [(party)]="houseConsignment.goodsShipment.buyer"
                                                     [editMode]="editMode" [cargoDeclarantId]="consignmentProcess.filing.declarant.iamConnectedId"
                                                     [required]="utils.isStraightConsignment(consignmentProcess) && utils.isEuCountry(houseConsignment.placeOfDelivery)"
                                                     label="Buyer"></app-consignment-party-details>
                    </div>
                    <div class="col-12">
                      <app-consignment-party-details [(party)]="houseConsignment.goodsShipment.seller"
                                                     [editMode]="editMode" [cargoDeclarantId]="consignmentProcess.filing.declarant.iamConnectedId"
                                                     [required]="utils.isStraightConsignment(consignmentProcess) && utils.isEuCountry(houseConsignment.placeOfDelivery)"
                                                     label="Seller"></app-consignment-party-details>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="houseConsignment.notifyParties?.length || editMode">
                    <h5 class="h1 col-12">Notify parties</h5>
                    <div class="col-12" *ngFor="let party of houseConsignment.notifyParties; index as i; trackBy: refData.trackByIndex">
                      <app-consignment-party-details
                        [party]="party" (partyChange)="checkPartyDeletion($event, i)"
                        [cargoDeclarantId]="consignmentProcess.filing.declarant.iamConnectedId"
                        [editMode]="editMode" [required]="true" [deletionAllowed]="true" [communicationRequired]="true"
                        [label]="party.name"></app-consignment-party-details>
                    </div>
                    <div class="col-12" *ngIf="editMode">
                      <div class="col-auto">
                        <button type="button" class="btn btn-primary" (click)="addNotifyParty()">Add notify party</button>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <div class="row gy-4 my-3">
                  <ng-container *ngIf="houseConsignment.additionalSupplyChainActors?.length || editMode">
                    <h5 class="h1 col-12 m-0">Supply chain actors</h5>
                    <div class="col-12" *ngFor="let supplyChainActor of houseConsignment.additionalSupplyChainActors; index as i; trackBy: refData.trackByIndex">
                      <app-additional-supply-chain-actor-details [consignmentProcess]="consignmentProcess"
                        [supplyChainActor]="supplyChainActor" [editMode]="editMode" (supplyChainActorChange)="checkSupplyChainActorDeletion($event, i)"
                        [label]="utils.supplyChainTypeFormatter(supplyChainActor.type)"></app-additional-supply-chain-actor-details>
                    </div>
                    <div class="col-12" *ngIf="editMode">
                      <div class="col-auto">
                        <button type="button" class="btn btn-primary" (click)="addSupplyChainActor()">Add actor</button>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </app-tab-item>
      <app-tab-item [templateRef]="goodsRef">
          <span class="tab-item">Goods
            <span class="ps-2 sub-text fw-light">{{ goodsItems?.length }}</span>
          </span>
        <ng-template #goodsRef>
          <app-consignment-goods-table [data]="goodsItems" [editable]="isEditable"
                                       [consignmentProcess]="consignmentProcess"
                                       [houseConsignment]="houseConsignment"></app-consignment-goods-table>
        </ng-template>
      </app-tab-item>
      <app-tab-item [templateRef]="equipmentRef">
        <span class="tab-item">Equipment
          <span class="ps-2 sub-text fw-light">{{ equipmentListSummaries?.length }}</span>
        </span>
        <ng-template #equipmentRef>
          <app-consignment-equipments-table [data]="equipmentListSummaries" [editable]="isEditable"
                                            [consignmentProcess]="consignmentProcess"
                                            [houseConsignmentNumber]="houseConsignment.consignmentNumber"></app-consignment-equipments-table>
        </ng-template>
      </app-tab-item>
      <app-tab-item *ngIf="canHavePassiveBorderTransportMeans()" [templateRef]="vehiclesRef">
        <span class="tab-item">Vehicles</span>
        <ng-template #vehiclesRef>
          <app-consignment-vehicles-table [(data)]="houseConsignment.passiveBorderTransportMeans" [editable]="isEditable" [nationalityRequired]="false"
                                          [(editMode)]="editMode"></app-consignment-vehicles-table>
        </ng-template>
      </app-tab-item>
    </app-tab-panel>
  </div>

  <div class="modal-footer" *ngIf="!consignmentProcess.cancelled">
    <div class="d-flex flex-grow-1">
      <div class="container">
        <div class="row mx-4">
          <div class="col-auto">
            <button type="button" class="btn btn-outline-danger" (click)="remove()">Remove</button>
          </div>
          <div class="col-auto ms-auto">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" aria-label="Close"
                    *ngIf="!editMode">
              Close
            </button>
            <button type="button" class="btn btn-outline-secondary" *ngIf="editMode" (click)="toggleEdit()">
              Cancel
            </button>
          </div>
          <div class="col-auto">
            <ng-container *ngIf="editMode else readMode">
              <button type="button" class="btn btn-primary me-4" (click)="save()">Save</button>
            </ng-container>
            <ng-template #readMode>
              <button *ngIf="isEditable" type="button" class="btn btn-outline-secondary me-4" (click)="toggleEdit()">
                Edit
              </button>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<div class="w-100 h-100 position-absolute d-flex flex-column-reverse" [ngClass]="showSubModal ? '' : 'd-none'">
  <div class="w-100 h-100 position-absolute modal-backdrop show" (click)="closeConsignmentSubModal()"></div>
  <div class="bg-body" style="z-index: 1050; height: calc(100% - 110px);">
    <ng-template #subModalContainer></ng-template>
  </div>
</div>
