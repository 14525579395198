import {Component, Input} from '@angular/core';
import {BunkerProduct} from "@portbase/bezoekschip-service-typescriptmodels";
import {PortvisitUtils} from '../portvisit-utils';
import {Observable, of} from "rxjs";

@Component({
  selector: 'app-edit-bunker-product',
  templateUrl: './edit-bunker-product.component.html',
  styleUrls: ['./edit-bunker-product.component.css']
})
export class EditBunkerProductComponent {
  utils = PortvisitUtils;
  @Input() bunkerProduct: BunkerProduct;
  unitsOfMeasure: Observable<string[]> = of(["MT", "m3", "ltr"]);
  provisionTypes: Observable<string[]> = of(["fuel", "lubricant", "other"]);

}
