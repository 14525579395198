import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {dispatchChangeEvent} from '../utils';

@Component({
  selector: 'app-collapse',
  templateUrl: './collapse.component.html',
  styleUrls: ['./collapse.component.scss']
})
export class CollapseComponent implements OnInit, AfterViewInit {

  collapsing : boolean;
  showing : boolean;
  @Output() show = new EventEmitter();
  @Output() hide = new EventEmitter();
  @Input() validationModel : any = this; //using 'this' might not work if this collapse may disappear from the page. In that case use a model
  @Input() collapse : TemplateRef<any>;
  @Input() deleteButton : TemplateRef<any>;
  @Input() showOnLoad : boolean;
  @Input() rounded : boolean = false;
  @Input() animateOnLoad: boolean;
  @Input() renderWhenCollapsed: boolean; // Setting to true can help with validation
  @Input() deleteHandle : () => any;
  @Input() hideCollapseIcon : boolean;

  constructor(private elementRef: ElementRef) {
  }

  ngOnInit(): void {
    if (this.showOnLoad) {
      this.showing = true;
    }
  }

  ngAfterViewInit(): void {
    const element = <Element>this.elementRef.nativeElement;
    const collapsible = element.querySelector(".collapsible");

    if (this.showOnLoad) {
      collapsible.classList.remove("collapsed");
    } else {
      collapsible.classList.add("collapsed");
    }

    $(collapsible).on('click', e => {
      this.toggle(!this.showing);
      e.stopPropagation();
    });

    if (this.showOnLoad) {
      this.doShow(this.animateOnLoad, false);
    }
  }

  public toggle = (show: boolean, animate: boolean = true) => {
    if (show !== this.showing) {
      show ? this.doShow(animate): this.doHide(animate)
    }
  };

  deleteCollapse() {
    if (this.deleteHandle) {
      this.deleteHandle();
      dispatchChangeEvent(this.elementRef.nativeElement);
    }
  }

  private doShow = (animate: boolean, nextFrame: boolean = true) => {
    const element = <Element>this.elementRef.nativeElement;
    const collapsible = element.querySelector(".collapsible");
    this.showing = true; //renders the collapsed content!
    this.collapsing = false;

    const action = () => {
      const collapse = $(element.querySelector(".collapse"));
      animate ? collapse.removeClass("no-transition") : collapse.addClass("no-transition");
      collapse.on('hide.bs.collapse', e => {
        e.stopPropagation();
        collapsible.classList.add("collapsed");
        this.hide.emit();
        this.collapsing = true;
      });
      collapse.on('show.bs.collapse', e => {
        e.stopPropagation();
        collapsible.classList.remove("collapsed");
        this.show.emit();
      });
      collapse.on('hidden.bs.collapse', e => {
        e.stopPropagation();
        this.showing = false;
      });
      collapse.collapse('toggle');
    };

    if (nextFrame) {
      setTimeout(action,  0);
    } else {
      action();
    }
  };

  private doHide = (animate: boolean) => {
    const element = <Element>this.elementRef.nativeElement;
    this.showing = true; //renders the collapsed content!

    setTimeout(() => {
      const collapse = $(element.querySelector(".collapse"));
      animate ? collapse.removeClass("no-transition") : collapse.addClass("no-transition");
      collapse.collapse('toggle');
    },  0);
  };
}
