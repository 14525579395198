import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ConsignmentUtils} from "../../../consignment.utils";
import {IE3AdditionalSupplyChainActor, IE3ConsignmentProcess} from "@portbase/bezoekschip-service-typescriptmodels";
import {cloneDeep} from "lodash";

@Component({
  selector: 'app-additional-supply-chain-actor-details',
  templateUrl: './additional-supply-chain-actor-details.component.html',
  styleUrls: ['./additional-supply-chain-actor-details.component.scss']
})
export class AdditionalSupplyChainActorDetailsComponent implements OnInit {
  utils = ConsignmentUtils;

  @Input() consignmentProcess: IE3ConsignmentProcess;
  @Input() label: string;
  @Input() supplyChainActor: IE3AdditionalSupplyChainActor;
  @Input() editMode: boolean;

  supplyChainActorEditMode: boolean;
  backupSupplyChainActor: IE3AdditionalSupplyChainActor;

  @Output() supplyChainActorChange: EventEmitter<IE3AdditionalSupplyChainActor> = new EventEmitter<IE3AdditionalSupplyChainActor>();

  ngOnInit(): void {
    this.supplyChainActorEditMode = this.editMode;
    if (this.editMode) {
      this.backupSupplyChainActor = cloneDeep(this.supplyChainActor);
    }
  }

  toggleEditMode = () => {
    this.supplyChainActorEditMode = !this.supplyChainActorEditMode;
    if (this.supplyChainActorEditMode) {
      this.backupSupplyChainActor = cloneDeep(this.supplyChainActor);
    }
  }

  cancel = () => {
    this.supplyChainActor = this.backupSupplyChainActor;
    this.toggleEditMode();
  }

  save = () => {
    this.toggleEditMode();
    this.supplyChainActorChange.emit(this.supplyChainActor);
  }

  deleteActor = () => {
    this.supplyChainActorChange.emit(null);
  }
}
