import {AfterViewInit, Component, EventEmitter, OnDestroy} from '@angular/core';
import lodash from "lodash";
import {sendQuery} from "../../common/utils";
import {
  DateTimeRange,
  GetCommercialReleaseStats,
  GetReleaseToPartyStats
} from "@portbase/bezoekschip-service-typescriptmodels";
import moment, {unitOfTime} from "moment/moment";
import {ChartData, ChartDataset} from "chart.js";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {DateRange} from "../../common/date/date-range/date-range";

type TimeDefinitions = 'relative' | 'absolute';

@Component({
  selector: 'app-commercial-release-dashboard',
  templateUrl: './commercial-release-dashboard.component.html',
  styleUrls: ['./commercial-release-dashboard.component.scss']
})
export class CommercialReleaseDashboardComponent implements AfterViewInit, OnDestroy {
  static dateTimeRange = new EventEmitter<DateTimeRange>();
  static zoomSubject = new EventEmitter<number>();
  static resetZoom = new EventEmitter();
  static destroyNotifier = new Subject();

  zoomed: boolean;
  dateTimeRange: DateTimeRange;
  timeFilter: TimeDefinitions = 'relative';
  timeValue: number = 1;
  measurement: unitOfTime.Base = "days";
  measurements: any[] = Object.values(MeasurementTypes).filter((v) => isNaN(Number(v)));

  autoRefresh: boolean;
  currentRefreshTimeout: number;

  // Datasets
  private commercialReleases: CommercialReleasesResult = {};
  private releaseToParties: ReleaseToPartiesResult = {};
  commercialReleasesPerOrganisation: ChartData;
  releaseToPartiesPerOrganisation: ChartData;

  get getCurrentTimeRange(): string {
    return this.timeFilter === 'relative'
      ? `${this.timeValue} ${this.measurement} ago`
      : `${moment(this.dateTimeRange.start).format("DD-MMM-YYYY HH:mm:ss")}
      → ${moment(this.dateTimeRange.end).format("DD-MMM-YYYY HH:mm:ss")}`;
  }

  computeHash(str: string): number {
    var hash = 0;

    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
      hash = hash & hash;
    }

    return hash;
  }

  stringToRgb(str: string): string {
    const hash = this.computeHash(str);
    const red = hash & 255;
    const green = (hash >> 8) & 255;
    const blue = (hash >> 16) & 255;

    return `rgb(${red}, ${green}, ${blue})`;
  }

  createCommercialReleaseDatasets(result: CommercialReleasesResult) {
    delete result["Hapag-Lloyd Rotterdam"];

    this.commercialReleases = result;
    this.commercialReleasesPerOrganisation = {
      labels: Object.keys(this.commercialReleases),
      datasets: [<ChartDataset<'pie'>>{
        backgroundColor: Object.keys(this.commercialReleases).map(user => this.stringToRgb(user)),
        hoverBackgroundColor: Object.keys(this.commercialReleases).map(user => this.stringToRgb(user)),
        hoverBorderColor: "rgb(0,0,0,0)",
        data: Object.values(this.commercialReleases),
        datalabels: {
          labels: {
            value: {
              align: 'center',
              backgroundColor: 'white',
              borderColor: 'white',
              borderWidth: 2,
              borderRadius: 4,
              padding: 4
            }
          }
        }
      }]
    };
  }

  createReleaseToPartiesDatasets(result: ReleaseToPartiesResult) {
    this.releaseToParties = result;
    this.releaseToPartiesPerOrganisation = {
      labels: Object.keys(this.releaseToParties),
      datasets: [<ChartDataset<'pie'>>{
        backgroundColor: Object.keys(this.releaseToParties).map(user => this.stringToRgb(user)),
        hoverBackgroundColor: Object.keys(this.releaseToParties).map(user => this.stringToRgb(user)),
        hoverBorderColor: "rgb(0,0,0,0)",
        data: Object.values(this.releaseToParties),
        datalabels: {
          labels: {
            value: {
              align: 'center',
              backgroundColor: 'white',
              borderColor: 'white',
              borderWidth: 2,
              borderRadius: 4,
              padding: 4
            }
          }
        }
      }]
    };
  }

  ngAfterViewInit(): void {
    CommercialReleaseDashboardComponent.zoomSubject
      .pipe(takeUntil(CommercialReleaseDashboardComponent.destroyNotifier)).subscribe(() => {
      this.zoomed = true;
    });
    setTimeout(() => {
      this.loadFromLocalStorage();
      this.setDateRange();
      this.updateDashboard();
    }, 0);
  }

  ngOnDestroy(): void {
    CommercialReleaseDashboardComponent.destroyNotifier.next();
    CommercialReleaseDashboardComponent.destroyNotifier.complete();
    this.stopRefresh();
  }

  resetZoom() {
    this.zoomed = false;
    this.setDateRange();
    CommercialReleaseDashboardComponent.resetZoom.next();
  }

  onRefreshButtonClick = () => {
    if (this.currentRefreshTimeout) {
      this.stopRefresh();
    } else {
      this.updateDashboard();
    }
  }

  private updateDashboard = () => {
    sendQuery("com.portbase.bezoekschip.common.api.commercialrelease.stats.GetCommercialReleaseStats",
      <GetCommercialReleaseStats>{
        dateTimeRange: this.getDataPayloadDateRange()
      }, {
        caching: true
      }).subscribe((result: CommercialReleasesResult) => {
      this.createCommercialReleaseDatasets(result);
    });
    sendQuery("com.portbase.bezoekschip.common.api.commercialrelease.stats.GetReleaseToPartyStats",
      <GetReleaseToPartyStats>{
        dateTimeRange: this.getDataPayloadDateRange()
      }, {
        caching: true
      }).subscribe((result: ReleaseToPartiesResult) => {
      this.createReleaseToPartiesDatasets(result);
    });
  }

  refreshOverTime = (): void => {
    this.autoRefresh = true;
    this.updateDashboard();
    this.startRefresh();
  }

  onSelectionChange = (): void => {
    this.setLocalStorage();
    this.resetZoom();
    this.updateDashboard();
  };

  measurementSelected = (value: unitOfTime.Base) => {
    if (value) {
      this.measurement = value;
      this.timeFilter = 'relative';
      this.onSelectionChange();
    }
  }

  onTimeValueChange = (timeValue: number) => {
    if (timeValue) {
      this.timeValue = timeValue;
      this.timeFilter = 'relative';
      this.onSelectionChange();
    }
  }

  onDateSelection = (dateRange: DateRange): void => {
    if (dateRange) {
      this.dateTimeRange = dateRange;
      this.timeFilter = 'absolute';
      this.stopRefresh();
      this.onSelectionChange();
    }
  }

  private setDateRange() {
    this.dateTimeRange = this.timeFilter === "relative" ? {
      start: moment().subtract(this.timeValue, this.measurement).toISOString(),
      end: moment().toISOString()
    } : this.dateTimeRange;
    CommercialReleaseDashboardComponent.dateTimeRange.next(this.dateTimeRange);
  };

  private getDataPayloadDateRange = (): DateTimeRange => ({
    start: moment(this.dateTimeRange.start).toISOString(),
    end: moment(this.dateTimeRange.end).toISOString()
  })

  private startRefresh = (): void => {
    if (this.currentRefreshTimeout) {
      clearTimeout(this.currentRefreshTimeout);
      this.currentRefreshTimeout = null;
    }
    this.currentRefreshTimeout = setTimeout(() => {
      if (this.currentRefreshTimeout) {
        this.setDateRange();
        this.updateDashboard();
        this.startRefresh();
      }
    }, 900000);
  }

  private stopRefresh() {
    if (this.currentRefreshTimeout) {
      clearTimeout(this.currentRefreshTimeout);
      this.currentRefreshTimeout = null;
    }
    this.autoRefresh = false;
  }

  private setLocalStorage() {
    localStorage.setItem('commercial-release-dashboard-days-preference', JSON.stringify(<LocalStorageData>{
      timeValue: this.timeValue,
      measurement: this.measurement,
      timeFilter: this.timeFilter,
      range: this.dateTimeRange
    }));
  }

  private loadFromLocalStorage(): void {
    const item = localStorage.getItem('commercial-release-dashboard-days-preference');
    if (item) {
      const preference: LocalStorageData = JSON.parse(item);
      this.timeValue = preference.timeValue;
      this.measurement = preference.measurement;
      this.timeFilter = preference.timeFilter;
      this.dateTimeRange = preference.range;
    }
  }

  get totalNumberOfCommercialReleases(): number {
    return lodash.sum(Object.values(this.commercialReleases));
  }

  get totalNumberOfReleaseToParties(): number {
    return lodash.sum(Object.values(this.releaseToParties));
  }
}

interface LocalStorageData {
  timeValue: number;
  range: DateTimeRange;
  measurement: unitOfTime.Base;
  timeFilter: TimeDefinitions;
}

enum MeasurementTypes {
  days = 86400,
  weeks = 604800,
  months = 2592000,
  years = 31536000,
}

interface CommercialReleasesResult {
  [organisation: string]: number;
}

interface ReleaseToPartiesResult {
  [organisation: string]: number;
}
