import { Pipe, PipeTransform } from '@angular/core';
import lodash from "lodash";
import {TableField} from "./table-view/table-view.component";

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

  transform(array: any[], field: TableField, order: 'asc' | 'desc' = 'asc'): any[] {
    if (!Array.isArray(array)) {
      return [];
    }
    if (!field) {
      return array;
    }
    return lodash.orderBy(array, [field.name], order);
  }
}
