<div class="row">
  <div class="col-md-6">
    <app-form-group><label>Id</label><input class="form-control" [(ngModel)]="vesselService['id']" disabled required>
    </app-form-group>
    <app-form-group><label>Code</label><input class="form-control" [(ngModel)]="vesselService.code" required>
    </app-form-group>
    <app-form-group><label>Name</label><input class="form-control" [(ngModel)]="vesselService.name" required>
    </app-form-group>
  </div>
  <div class="col-md-6">
    <app-form-group label="Stevedore organisation">
      <app-search [ngModel]="vesselService.stevedoreOrganisationShortName" [searchFunction]="searchOrganisation"
                  (ngModelChange)="setStevedoreOrganisationShortName($event)" [resultFormatter]="formatter"
                  placeholder="Find stevedore organisation by short name" required>
      </app-search>
    </app-form-group>
    <app-form-group label="Carrier organisation">
      <app-multi-search [ngModel]="vesselService.carrierOrganisationsShortNames" [searchFunction]="searchOrganisation"
                        (ngModelChange)="setCarrierOrganisationsShortNames($event)" [resultFormatter]="formatter"
                        placeholder="Find carrier organisation by short name" required>
      </app-multi-search>
    </app-form-group>
  </div>
</div>
