<div class="row narrow-gutters mb-2">
  <div class="col-md-auto" *ngIf="!!declaration.releaseStatus.status">
    <span class="btn-group dropup mx-1 always-enabled">
        <button class="btn btn-outline-secondary dropdown-toggle py-1" style="min-width: 7rem" type="button"
                data-bs-toggle="dropdown">Incoming
        <span class="caret"></span></button>
        <ul class="dropdown-menu dropdown-menu-end">
              <li (click)="utils.fetchProcessIdAndOpenMessageManagement('coreor-in-' + declaration.id)"
                  class="dropdown-item">Commercial release from carrier</li>
        </ul>
    </span>
  </div>
  <div class="col-md-auto ms-auto" *ngIf="!!declaration.releaseStatus.status">
    <span class="btn-group dropup mx-1 always-enabled">
        <button class="btn btn-outline-secondary dropdown-toggle py-1" style="min-width: 7rem" type="button"
                data-bs-toggle="dropdown">Outgoing
        <span class="caret"></span></button>
        <ul class="dropdown-menu dropdown-menu-end">
              <li (click)="utils.fetchProcessIdAndOpenMessageManagement('coreor-out-' + declaration.id)"
                  class="dropdown-item">Commercial release to terminal</li>
        </ul>
    </span>
  </div>

  <div class="col-md-auto" *ngIf="declaration.releaseStatus?.status === 'DECLARED'">
    <app-confirmation-toggle>
      <button class="btn btn-outline-secondary py-1" style="min-width: 7rem" type="button">Resubmit</button>
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Resubmit release</h5>
        </div>
        <div class="modal-body">

          <p>Are you sure that you want to resubmit this release as admin?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">No</button>
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="resubmit()">Yes
          </button>
        </div>
      </div>
    </app-confirmation-toggle>
  </div>

  <div class="col-md-auto" *ngIf="declaration.releaseStatus?.status === 'DECLARED'">
    <app-confirmation-toggle>
      <button class="btn btn-outline-success py-1" style="min-width: 7rem" type="button">Accept</button>
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Accept release</h5>
        </div>
        <div class="modal-body">

          <p>Are you sure that you want to accept this release as admin?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">No</button>
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="acceptRelease()">Yes
          </button>
        </div>
      </div>
    </app-confirmation-toggle>
  </div>
</div>
