import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BerthVisit, DeclarationType, LocalMovement, Visit} from '@portbase/bezoekschip-service-typescriptmodels';
import moment from 'moment';
import {DragulaService} from 'ng2-dragula';
import {AppContext} from 'src/app/app-context';
import {VisitContext} from '../visit-context';
import {ShipMovementService} from '../visit/ship-movement.service';
import {OrderMovementService} from '../visit/nautical-services/order-movement.service';
import {cloneObject} from "../../common/utils";

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent {
  context = VisitContext;
  appContext = AppContext;

  @Input() berthVisits: BerthVisit[];
  @Input() readonly: boolean;
  @Output() berthVisitsChange = new EventEmitter<BerthVisit[]>();

  constructor(private dragulaService: DragulaService, private shipMovementService: ShipMovementService, private orderMovementService: OrderMovementService) {

  }

  ngAfterViewInit(): void {
    this.dragulaService.find('berthVisits').options.moves = (el: Element, handle) => {
      const index = TimelineComponent.getElementIndex(el);
      const berthVisit = this.berthVisits[index];
      return !berthVisit.ata && !berthVisit.atd && !this.context.isVisitReadonly() && handle.className === 'handle';
    };
    this.dragulaService.find('berthVisits').options.accepts = (el, target, source, sibling) => {
      if (sibling == null) { // last berth visit
        return true;
      }
      const nextIndex = TimelineComponent.getElementIndex(sibling);
      const nextBerthVisit = this.berthVisits[nextIndex];
      return nextBerthVisit && !nextBerthVisit.ata && !nextBerthVisit.atd;
    };
    this.dragulaService.find('berthVisits').options.invalid = () => false;
    this.dragulaService.find('berthVisits').options.moves = (el, container, handle) => {
      if (this.movePrevented(handle, container)) {
        return false;
      }
      const index = TimelineComponent.getElementIndex(el);
      const berthVisit = this.berthVisits[index];
      return !berthVisit.ata && !berthVisit.atd && !this.context.isVisitReadonly();
    };
    this.dragulaService.dropModel('berthVisits').subscribe(value => {
      console.log('dropped');
      const berthVisit: BerthVisit = value.item;
      if (!VisitContext.visit.terminalPlanningEnabled) {
        berthVisit.eta = null;
        berthVisit.etd = null;
      }
      if (this.context.visit.orderIncomingMovement && value.targetIndex === 0) {
        // this.setBerthVisitModelValidation(berthVisit, value.el);

        this.context.updateCalculatedEtas();
        this.context.clearDependency();
      }
      this.berthVisitsChange.emit(this.berthVisits);
    });
  }

  private movePrevented(handle: Element, container: Element) {
    const selectedElement = $(handle);
    const preventClassName = "prevent-dragula-move";
    return selectedElement.hasClass(preventClassName) ||
      selectedElement.parentsUntil(container, `.${preventClassName}`).length > 0;
  }

  private static getElementIndex(el: any) {
    return [].slice.call(el.parentElement.children).indexOf(el);
  }

  getShipStatus(berthVisit: BerthVisit): string {
    return this.shipMovementService.shipStatus(berthVisit);
  }

  asTime(date: string) {
    if (date) {
      return moment(date).format('HH:mm');
    }
  }

  asDate(date: string, errorText: String) {
    if (date) {
      return date ? moment().isSame(date, 'day') ? 'Today' : moment(date).format('D MMM') : errorText;
    }
  }

  arrivalTime(berthVisit: BerthVisit) {
    return berthVisit.ata ? berthVisit.ata : berthVisit.eta || berthVisit.requestedEta;
  }

  departureTime(berthVisit: BerthVisit) {
    return berthVisit.atd ? berthVisit.atd : berthVisit.etd || berthVisit.requestedEtd;
  }


  vesselHasArrivedAtPort(): boolean {
    return !!this.context.visit.visitDeclaration.portVisit.ataPort && !this.vesselHasArrivedAtBerth(this.berthVisits[0]);
  }

  vesselHasArrivedAtBerth(berthVisit: BerthVisit): boolean {
    return !!berthVisit && !!berthVisit.ata;
  }

  userIsNextOwnerOrDeclarant() {
    return VisitContext.isOrganisationNextDeclarant();
  }

  getStatusOfIncomingOrder(visit: Visit): ("ORDERED" | "CONFIRMED" | "CONFIRMED_NEW_PROPOSAL" | "NOT_ORDERED" | 'REJECTED') {
    if (!visit.orderIncomingMovement || !this.context.findLatestDeclaration(DeclarationType.VISIT)) {
      return 'NOT_ORDERED';
    }
    const harbourMasterInfo = visit.incomingMovementHarbourMasterInfo;
    const additionalInfo = visit.additionalIncomingMovementInfo;

    if (harbourMasterInfo && (harbourMasterInfo.rejectionReason)) {
      return 'REJECTED';
    }
    if (additionalInfo && additionalInfo.etaPilotBoardingPlace) {
      return !this.context.savedVisit.visitDeclaration.portVisit.portEntry.requestedEtaPilotBoardingPlace ||
      moment(this.context.savedVisit.visitDeclaration.portVisit.portEntry.requestedEtaPilotBoardingPlace)
        .isSame(additionalInfo.etaPilotBoardingPlace) ? 'CONFIRMED' : 'CONFIRMED_NEW_PROPOSAL';
    }
    if (!this.context.savedVisit.visitDeclaration.portVisit.firstMovement.order) {
      return 'NOT_ORDERED';
    }
    return 'ORDERED';
  }

  orderMovement(berthVisit: BerthVisit) {
    this.orderMovementService.orderMovement(berthVisit);
    berthVisit.nextMovement.orderCancellationReason = null;
  }

  orderIncomingMovement() {
    this.context.visit.visitDeclaration.portVisit.firstMovement.order = true;
    this.context.visit.visitDeclaration.portVisit.firstMovement.orderCancellationReason = null;
  }

  cancelMovement(berthVisit: BerthVisit) {
    this.orderMovementService.cancelMovement(berthVisit);
  }

  get firstBerthVisitId(): string {
    return this.berthVisits[0]?.id;
  }

  afterEtaPort() {
    let etaPort = this.context.visit.visitDeclaration.portVisit.etaPort;
    let now = moment();
    if (this.context.visit.orderIncomingMovement) {
      return !!etaPort && moment(now).isAfter(etaPort) && this.context.visit.incomingMovementHarbourMasterInfo && !!this.context.visit.visitDeclaration.portVisit.firstMovement.order
        && (this.context.visit.incomingMovementHarbourMasterInfo.orderStatus === 'CONFIRMED' || this.context.visit.incomingMovementHarbourMasterInfo.orderStatus === 'CONFIRMED_NEW_PROPOSAL');
    } else {
      return !!etaPort && moment(now).isAfter(etaPort);
    }
  }

  isBetweenBerthsOrDeparting(idx: number) {
    let berthVisits = this.berthVisits;
    if (berthVisits.length > idx + 1) {
      return !!berthVisits[idx + 1].ata || !!berthVisits[idx + 1].atd;
    } else if (berthVisits.length === idx + 1) {
      return !!berthVisits[idx].atd && !!this.context.visit.visitDeclaration.portVisit.atdPort;
    }
    return false;
  }

  copyMovement() {
    this.berthVisits[0].nextMovement ||= <LocalMovement>{};
    let target = this.berthVisits[0].nextMovement;
    let source = cloneObject(this.context.visit.visitDeclaration.portVisit.firstMovement);
    ["vesselDraft", "vesselMasterName", "numberOfCrew", "numberOfPassengers", "cargo", "pilotExemption", "offStandardBeam"].forEach(property => {
      target[property] = source[property];
    });
  }

  copyNauticalServices() {
    let berthVisits = this.berthVisits;
    berthVisits[0].tugboatAtDeparture = cloneObject(berthVisits[0].tugboatAtArrival);
    berthVisits[0].boatmenAtDeparture = cloneObject(berthVisits[0].boatmenAtArrival);
    berthVisits[0].nextMovement.pilotService = cloneObject(this.context.visit.visitDeclaration.portVisit.firstMovement.pilotService);
    if (berthVisits.length > 1) {
      berthVisits[1].tugboatAtArrival = cloneObject(berthVisits[0].tugboatAtArrival);
      berthVisits[1].boatmenAtArrival = cloneObject(berthVisits[0].boatmenAtArrival);
    }
  }

  anyNextBerthVisitHasAta(idx: number) {
    let berthVisits = this.berthVisits;
    if (idx === berthVisits.length) {
      return false;
    }
    for (let i = idx; i < berthVisits.length; i++) {
      if (berthVisits[i].ata) {
        return true;
      }
    }
    return false;
  }
}
