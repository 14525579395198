import {Component, OnInit} from '@angular/core';
import {Carrier, DefectType, ShipConfiguration, Vessel} from '@portbase/bezoekschip-service-typescriptmodels';
import {AppContext} from 'src/app/app-context';
import {sendQuery} from 'src/app/common/utils';
import {VisitContext} from '../../visit-context';
import {map} from "rxjs/operators";

@Component({
  selector: 'app-general-information',
  templateUrl: './general-information.component.html',
  styleUrls: ['./general-information.component.css']
})
export class GeneralInformationComponent implements OnInit {

  context = VisitContext;
  appContext = AppContext;

  constructor() {
  }

  ngOnInit() {
  }

  userIsNextOwnerOrDeclarant() {
    return VisitContext.isOrganisationNextDeclarant();
  }

  shipConfigurations: ShipConfiguration[] = [ShipConfiguration.SINGLE_HULL_TANKER, ShipConfiguration.DOUBLE_HULL_TANKER,
    ShipConfiguration.SINGLE_HULL_WITH_SEGREGATED_BALLAST_TANKS];
  shipConfigurationFormatter = (value: ShipConfiguration) => {
    switch (value) {
      case 'SINGLE_HULL_TANKER':
        return 'Single hull tanker';
      case 'DOUBLE_HULL_TANKER':
        return 'Double hull tanker';
      case 'SINGLE_HULL_WITH_SEGREGATED_BALLAST_TANKS':
        return 'Single hull with segregated ballast tanks';
      default:
        throw Error('Unknown ship configuration: ' + value);
    }
  };

  defectTypes: DefectType[] = [DefectType.QUA, DefectType.DAI, DefectType.DSA, DefectType.DWA, DefectType.DWE,
    DefectType.DCA, DefectType.DCO, DefectType.DEN, DefectType.DNA, DefectType.DST, DefectType.TOW, DefectType.DFI,
    DefectType.OHC, DefectType.DSM, DefectType.LIP, DefectType.LIS, DefectType.GAS, DefectType.DEFECT_TO_STERN_THRUSTER,
    DefectType.DEFECT_TO_BOW_THRUSTER];
  defectsFormatter = (type: DefectType) => {
    switch (type) {
      case 'QUA':
        return 'Contagious diseased on board (quarantine)';
      case 'DAI':
        return 'Defect to AIS Automated Identification System';
      case 'DSA':
        return 'Defect to astern power and steering gear';
      case 'DWA':
        return 'Defect to body or hull, causing water to run into the vessel';
      case 'DWE':
        return 'Defect to body or hull, weakened construction';
      case 'DCA':
        return 'Defect to cargo lashing ropes/equipment';
      case 'DCO':
        return 'Defect to communication equipment';
      case 'DEN':
        return 'Defect to engine or propulsion system';
      case 'DNA':
        return 'Defect to navigation equipment';
      case 'DST':
        return 'Defect to steering gear equipment';
      case 'TOW':
        return 'Disabled vessel towed by tugboat(s)';
      case 'DFI':
        return 'Fire on board of the vessel';
      case 'OHC':
        return 'Overheated cargo';
      case 'DSM':
        return 'Smoke on board of the vessel';
      case 'LIP':
        return 'Vessel listing over portside';
      case 'LIS':
        return 'Vessel listing over starboard';
      case 'GAS':
        return 'Vessel not gas free';
      case 'DEFECT_TO_STERN_THRUSTER' :
        return 'Defect to stern thruster';
      case 'DEFECT_TO_BOW_THRUSTER' :
        return 'Defect to bow thruster';
      default:
        return 'Unrecognized';
    }
  };

  searchVessel = term => sendQuery('com.portbase.bezoekschip.common.api.visit.FindVessels', {term: term});
  vesselFormatter = (vessel: Vessel) => vessel.name + ' – ' + vessel.imoCode;

  searchCarrier = term => sendQuery("com.portbase.bezoekschip.common.api.visit.FindCarriers",
    {withCustomsId: true, term: term}).pipe(map(carriers => carriers.filter(c => c.smdgCode)));

  carrierFormatter = (carrier: Carrier) => carrier && carrier.name
    ? carrier.name + (carrier.customsId ? ' - ' + carrier.customsId : '') : '';

  inboundVoyageNumberRequired() {
    const multiBerthVisitIds = Array.from(this.context.multiBerthLoadDischargeInfo.keys());
    return this.context.visit.visitDeclaration.portVisit.berthVisits
        .filter(bv => !multiBerthVisitIds.includes(bv.id)).some(bv => bv.discharge > 0)
      || Array.from(this.context.multiBerthLoadDischargeInfo.values())
        .some(l => l.some(i => i.discharge > 0));
  }
}
