<div class="my-1">
  <div class="col-md-12">
    <app-alerting></app-alerting>
  </div>
  <app-collapse [collapse]="collectionTemplate" [showOnLoad]="!collection.endTime" class="card card-body bg-light">
    <div class="row">
      <div class="col-md-4 fw-bold">{{formatBerth(collection.berthVisitId)}}</div>
      <div class="col-md-4" *ngIf="!collection.endTime">Start time: {{utils.formatDate(collection.startTime)}}</div>
      <div class="col-md-4" *ngIf="collection.endTime">{{utils.formatDate(collection.startTime)}}
        - {{utils.formatDate(collection.endTime)}}</div>
      <div class="col-md-2 fst-italic">{{utils.formatFacilities(collection.items)}}</div>
      <div class="col-md-2 d-flex justify-content-around">
        <span class="fa fas fa-truck-loading" [ngClass]="wasteRegistered() ? 'text-portbase-green' : 'text-muted'"
              [title]="'Registered collection'"></span>
        <span class="fa fas fa-calendar-check"
              [ngClass]="wasteCompleted() ? 'text-portbase-green' : 'text-muted'"
              [title]="wasteCompleted() ? 'Completed collection' : ''"></span>
        <span class="fa fas fa-money-check-alt"
              [ngClass]="wasteFinancialsSubmitted() ? 'text-portbase-green' : 'text-muted'"
              [title]="wasteFinancialsSubmitted() ? 'Submitted financials' : ''"></span>
      </div>
    </div>
  </app-collapse>
  <ng-template #collectionTemplate>
    <div class="collapse fieldset"  [ngSwitch]="wasteCompleted()" [ngClass]="{'disabled' : wasteCompleted()}">
      <div class="d-none d-md-block" *ngSwitchCase="true">
        <hr>
        <div class="row">
          <div class="col-md-5 small">Waste category - type
            <app-info><span
              class="tooltipContent">specification, when applicable</span>
            </app-info>
          </div>
          <div class="col-md-2 small">Barge name/ Truck number</div>
          <div class="col-md small">To be collected (m3)</div>
          <div class="col-md small">Collected</div>
          <div class="col-md small">Direct cost (&euro;)</div>
          <div class="col-md small">Indirect cost (&euro;)</div>
        </div>
      </div>
      <div class="d-none d-md-block" *ngSwitchCase="false">
        <hr>
        <div class="row">
          <div class="col-md-2 small">Waste category</div>
          <div class="col-md small">Waste Type</div>
          <div class="col-md-2 small">Waste specification</div>
          <div class="col-md small">Barge name/ Truck number</div>
          <div class="col-md small">To be collected (m3)</div>
          <div class="col-md small">Collected</div>
          <div class="col-auto" style="min-width: 2em"></div>
        </div>
      </div>
      <hr>
      <ng-container *ngFor="let item of collection.items; let idx = index">
        <div class="d-none d-md-block mb-2" [ngSwitch]="wasteCompleted()">
          <div class="row" *ngSwitchCase="true">
            <div class="col-md-5 small">
              {{item.wasteMaterial.ssn.description}} - {{item.wasteMaterial.nameEN}}
              <app-info *ngIf="getSpecificationFromAgent(item)"><span
                class="tooltipContent">{{getSpecificationFromAgent(item)}}</span>
              </app-info>
            </div>
            <div class="col-md-2 form-group">
              {{item.receptionFacility}}
            </div>
            <div class="col-md text-center small">{{getToBeCollectedAmount(item)}}</div>
            <div class="col-md form-group text-end small">
              {{item.amount}} {{item.wasteMaterial.unitOfMeasure}}
            </div>
            <div class="col-md form-group" [ngClass]="{'always-enabled' : !collection.fixedCosts}">
              <input appDecimalNumber [maxDecimals]="2" min="0" required
                     class="form-control form-control-sm"
                     [(ngModel)]="item.directCost" style="text-align:right; max-width: 7em">
            </div>
            <div class="col-md form-group" [ngClass]="{'always-enabled' : !collection.fixedCosts}">
              <input appDecimalNumber [maxDecimals]="2" min="0" required
                     class="form-control form-control-sm"
                     [(ngModel)]="item.indirectCost" style="text-align:right; max-width: 7em">
            </div>
          </div>
          <div class="row" *ngSwitchCase="false">
            <div class="col-md-2" *ngIf="item.wasteMaterial.ssn">
              {{item.wasteMaterial.ssn.description}}
              <app-info *ngIf="getSpecificationFromAgent(item)"><span
                class="tooltipContent">{{getSpecificationFromAgent(item)}}</span>
              </app-info>
            </div>
            <div class="col-md-2" *ngIf="!item.wasteMaterial.ssn">
              <app-select
                [small]="true"
                [options]="wasteTypeOptions()"
                [(ngModel)]="item.wasteMaterial.ssn"
                [equalsFunction]="ssnEquals"
                [formatter]="utils.wasteTypeFormatter"
                required>
              </app-select>
            </div>
            <div class="col-md">
              <app-select
                required
                [small]="true"
                [options]="wasteOptions(item.wasteMaterial.ssn)"
                [autoSelectSingleOption]="true"
                [(ngModel)]="item.wasteMaterial"
                [equalsFunction]="materialEquals"
                [formatter]="utils.wasteMaterialFormatter"></app-select>
            </div>
            <div class="col-md-2 form-group">
              <input type="text"
                     [required]="item.wasteMaterial.ssn != null && item.wasteMaterial.ssn.specificationRequired"
                     class="form-control form-control-sm" [(ngModel)]="item.specification">
            </div>
            <div class="col-md form-group">
              <input type="text" required class="form-control form-control-sm" [(ngModel)]="item.receptionFacility">
            </div>
            <div class="col-md text-center small">{{getToBeCollectedAmount(item)}}</div>
            <div class="col-md form-group">
              <input appDecimalNumber [maxDecimals]="3" min="0" required class="form-control form-control-sm"
                     [(ngModel)]="item.amount" style="text-align:right; max-width: 6em;display:inline">
              <span style="padding-left: 0.3em">{{item.wasteMaterial.unitOfMeasure}}</span>
            </div>
            <div class="col-auto" style="min-width: 2em"><span
              class="fa fa-trash text-secondary" (click)="deleteItem(collection.items, idx)"></span>
            </div>
          </div>
        </div>
      </ng-container>

      <div *ngIf="collection && !collection.endTime" class="row">
        <div class="col text-end">
          <button (click)="addWasteItem(collection)" class="btn btn-info mt-1 me-1" type="button">Add Waste
          </button>
        </div>
      </div>
      <hr>

      <div class="row">
        <div class="col-md-6">
          <app-form-group>
            <label>Remarks</label>
            <input class="form-control" type="text" [(ngModel)]="collection.remarks">
          </app-form-group>
        </div>
        <div class="col-md-6"
             [ngClass]="{'validatable ng-invalid' : alreadyUsed(collection.fileNumber)}">
          <app-form-group>
            <label>File number
              <app-info><span class="tooltipContent">The 's-number'</span></app-info>
            </label>
            <div class="input-group">
              <span class="input-group-text" style="min-width: 5em">{{collector?.collectorNumber}}/S</span>
              <input class="form-control rounded-end" type="text" required
                     [(ngModel)]="collection.fileNumber">
            </div>
          </app-form-group>
          <span class="invalid-feedback"
                *ngIf="alreadyUsed(collection.fileNumber)">File number has already been used</span>
        </div>
      </div>
      <div class="row pt-1" [ngClass]="{'validatable ng-invalid' : invalidTimes(collection)}">
        <div class="col-md-6">
          <app-form-group>
            <label for="actualStartTime">Start time</label>
            <app-date-field required id="actualStartTime" [(ngModel)]="startTime"></app-date-field>
          </app-form-group>
        </div>
        <div class="col-md-6">
          <app-form-group>
            <label for="actualEndTime">End time</label>
            <app-date-field required id="actualEndTime" [(ngModel)]="endTime"></app-date-field>
          </app-form-group>
        </div>
      </div>
      <span *ngIf="invalidTimes(collection)" class="invalid-feedback mb-2">Start time should be before end time</span>
    </div>

    <div class="row" *ngIf="wasteCompleted()">
      <div class="col-md-6">
      </div>
      <div class="col-md-6">
        <app-form-group>
          <label>Fixed costs</label>
          <div class="input-group">
            <input class="form-control" appDecimalNumber [maxDecimals]="2" min="0" required
                   [disabled]="wasteFinancialsSubmitted()"
                   [(ngModel)]="fixedCosts">
            <span class="input-group-text">&euro;</span>
          </div>
        </app-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-auto" *ngIf="wasteCompleted()">
        <ng-container>
          <app-waste-collection-pdf class="always-enabled" [collection]="collection"
                                    [collector]="collector"
                                    [summary]="summary"></app-waste-collection-pdf>
        </ng-container>
      </div>
      <div *ngIf="appContext.isAdmin() || registrationPhase()" class="col-auto">
        <button (click)="cancelCollection(collection.id)"
                class="btn btn-secondary"
                type="button">Cancel collection
        </button>
      </div>
      <div class="col-auto ms-auto">
        <ng-container *ngIf="completionPhase()" class="col-auto">
          <button (click)="revertCompletion(collection.id)"
                  class="btn btn-outline-secondary me-1"
                  type="button">Edit Completion
          </button>
        </ng-container>
        <ng-container *ngIf="registrationPhase()">
          <button (click)="completeCollection(collection)"
                  class="btn btn-info me-1"
                  type="button">Complete collection
          </button>
        </ng-container>
        <ng-container *ngIf="wasteFinancialsSubmitted()" class="col-auto">
          <button (click)="revertFinancials(collection.id)"
                  class="btn btn-outline-secondary me-1"
                  type="button">Edit Financials
          </button>
        </ng-container>
        <ng-container *ngIf="completionPhase()">
          <button (click)="submitFinancials(collection)" class="btn btn-info me-1" type="button">
            Submit Financials
          </button>
        </ng-container>
        <ng-container *ngIf="allowRollback">
          <button (click)="undo()" class="btn btn-outline-secondary me-1" type="button">
            <span class="fa fa-fw fa-undo-alt"></span>
          </button>
        </ng-container>
      </div>
    </div>
  </ng-template>
</div>
