<div class="row">
  <div class="col-md-6">
    <app-form-group><label>Id</label><input class="form-control" [(ngModel)]="dangerInformation['id']" disabled required></app-form-group>
    <app-form-group><label>Name</label><input class="form-control" [(ngModel)]="dangerInformation.name" required></app-form-group>
    <app-form-group><label>UN code</label><input class="form-control" [(ngModel)]="dangerInformation.unCode"></app-form-group>
  </div>
  <div class="col-md-6">
    <app-form-group><label>Hazard class</label><input class="form-control" [(ngModel)]="dangerInformation.hazardClass"></app-form-group>
    <app-form-group><label>Packing group</label><app-select [(ngModel)]="dangerInformation.packingGroup" [options]="context.packingGroups"
                                                            [formatter]="context.packingGroupFormatter"></app-select></app-form-group>
    <app-form-group><label>Flash point (&deg;C)</label><input class="form-control" [(ngModel)]="dangerInformation.flashPoint"></app-form-group>
  </div>
</div>
