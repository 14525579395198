import {Component, OnInit} from '@angular/core';
import {$try, openConfirmationModal, sendCommand} from '../../common/utils';
import {VisitContext} from '../visit-context';
import {
  CancelPax,
  DeclarationType,
  DeclarePax,
  PaxDeclaration,
  PaxDeclarationSummary,
} from '@portbase/bezoekschip-service-typescriptmodels';
import {uploadPax} from './passengers.upload';
import {AppContext} from '../../app-context';

@Component({
  selector: 'app-passengers',
  templateUrl: './passengers.component.html',
  styleUrls: ['./passengers.component.css']
})

export class PassengersComponent implements OnInit {
  protected readonly openConfirmationModal = openConfirmationModal;
  PAX = DeclarationType.PAX;

  context = VisitContext;
  appContext = AppContext;
  paxDeclarationXls: PaxDeclaration;
  paxDeclaration: PaxDeclaration;

  ngOnInit(): void {
    this.context.visit.paxDeclarationSummaries = this.context.visit.paxDeclarationSummaries || <PaxDeclarationSummary[]>[];
  }

  upload(file: File) {
    AppContext.clearAlerts();
    uploadPax(file).subscribe(([paxDeclarationXls, paxDeclaration]) => {
      this.paxDeclarationXls = paxDeclarationXls;
      this.paxDeclaration = paxDeclaration;
    });
  }

  sendCrewAndPassengers() {
    sendCommand('com.portbase.bezoekschip.common.api.pax.DeclarePax', <DeclarePax>{
      crn: this.context.visit.crn,
      paxDeclaration: this.paxDeclaration,
      paxDeclarationSummary: toPaxDeclarationSummary(this.paxDeclaration)
    }, () => {
      AppContext.registerSuccess('Crew and passenger list was sent successfully.');
      this.context.visit.paxDeclarationSummaries.push(toPaxDeclarationSummary(this.paxDeclaration));
      this.context.replaceVisit(this.context.visit);
      this.paxDeclaration = null;
      this.paxDeclarationXls = null;
    });
  }

  cancelAll() {
    sendCommand('com.portbase.bezoekschip.common.api.pax.CancelPax', <CancelPax>{
      crn: this.context.visit.crn,
    }, () => {
      AppContext.registerSuccess('Crew and passenger list was cancelled successfully.');
      this.context.visit.paxDeclarationSummaries = [];
      this.context.replaceVisit(this.context.visit);
    });
  }

  removeUpload() {
    this.paxDeclarationXls = null;
    this.paxDeclaration = null;
  }

  getRejectReason() {
    return $try(() => this.context.findLatestDeclaration(DeclarationType.PAX).rejectReasons);
  }

  hasSearchAndRescue() {
    return this.context.visit.paxDeclarationSummaries.some(summary => summary?.searchAndRescue != null);
  }
}

function toPaxDeclarationSummary(declaration: PaxDeclaration) {
  return <PaxDeclarationSummary>{
    numberOfCrew: declaration.crewMembers.length,
    numberOfCrewOtherShip: declaration.crewMembersOtherShip.length,
    numberOfPassengers: declaration.passengers.length,
    numberOfStowaways: declaration.stowaways.length,
    searchAndRescue: declaration.passengers.length > 12
  };
}
