import {Component, EventEmitter, Input, Output} from "@angular/core";
import {Carrier, VisitStatus, Voyage} from "@portbase/bezoekschip-service-typescriptmodels";
import {sendQuery} from "../../../common/utils";
import {VisitContext} from "../../visit-context";

@Component({
  selector: 'app-arrival-voyage',
  templateUrl: './arrival-voyage.component.html',
  styleUrls: ['./arrival-voyage.component.css']
})
export class ArrivalVoyageComponent {

  @Input()
  voyage: Voyage;

  carrierFormatter
    = (carrier: Carrier) => carrier && carrier.name ? carrier.name + (carrier.customsId ? ' - ' + carrier.customsId : '') : '';

  searchCarrier = term => sendQuery("com.portbase.bezoekschip.common.api.visit.FindCarriers",
    {withCustomsId: true, term: term});

  getVisitStatus() : VisitStatus {
    return VisitContext.getVisitStatus();
  }
}
