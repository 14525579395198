<div class="row always-enabled mt-3" *ngIf="appContext.isAdmin() && !context.eventId">
  <div class="col-md-12">
    <div class="row g-0 justify-content-end my-1">
      <div class="col-auto">
         <span>
          <label *ngIf="!context.savedVisit.cargoImportEnabled || !context.cargoImportModel?.cargoDeclarant.cargoImportEnabled"
                 (click)="enableCargoImport()"
                 class="btn btn-outline-secondary ms-2" style="min-width: 11rem" tabindex="-1">Enable cargo import for agent
          </label>
         </span>
      </div>
      <div class="col-auto">
        <button *ngIf="resendAllowed()" (click)="resend()" type="button" class="btn btn-outline-info ms-2"
                style="min-width: 11rem" tabindex="-1">Redeclare all
        </button>
      </div>
      <div class="col-auto">
        <button *ngIf="resubmitAllowed()" (click)="resubmit()" type="button" class="btn btn-outline-info ms-2"
                style="min-width: 11rem" tabindex="-1">Resubmit all with
          <span class="fa fa-fw fa-paper-plane text-info"></span>
        </button>
      </div>
    </div>
  </div>
</div>
