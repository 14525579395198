import {Component, Input} from '@angular/core';
import {DischargeResult, Port, RefuseDischargeOverlanded, Terminal} from '@portbase/bezoekschip-service-typescriptmodels';
import {cloneObject, sendCommand} from '../../../../common/utils';
import {VisitContext} from '../../../visit-context';
import {PortvisitUtils} from '../../../../refdata/portvisit-utils';
import {AppContext} from '../../../../app-context';
import {ImportContainerModel, Manifest} from '../../cargo-import.model';
import {ComparatorChain} from '../../../../common/comparator-chain';

@Component({
  selector: 'app-overlanders',
  templateUrl: './overlanders.component.html',
  styleUrls: ['./overlanders.component.css']
})
export class OverlandersComponent {
  private static itemComparator = new ComparatorChain('empty', 'number').compare;

  utils = PortvisitUtils;
  overlanders : DischargeResult[] = [];
  @Input() manifest : Manifest;
  @Input() showTerminal : boolean;
  @Input() readonly : boolean;

  @Input() set setOverlanders(overlanders : DischargeResult[]) {
    this.overlanders = overlanders.sort(OverlandersComponent.itemComparator);
  }

  get hiddenItems(): DischargeResult[] {
    return this.overlanders.filter(g => g['hidden']);
  }

  get visibleItems(): DischargeResult[] {
    return this.overlanders.filter(g => !g['hidden']);
  }

  showAll() {
    this.overlanders.forEach(g => {
      delete g['hidden'];
      g['forceVisible'] = true;
    });
  }

  get selectedItems() : DischargeResult[] {
    return this.overlanders.filter(s => !!s['selected'] && !s['hidden']);
  }

  refuseSelectedOverlanders() {
    const selection = this.selectedItems.map(c => c.number);
    sendCommand("com.portbase.bezoekschip.common.api.cargo.RefuseDischargeOverlanded",
      <RefuseDischargeOverlanded>{
        crn: VisitContext.savedVisit.crn,
        cargoDeclarantShortName: VisitContext.cargoImportModel.cargoDeclarant.shortName,
        containerNumbers: selection
      }, () => {
        this.selectedItems.forEach(c => delete VisitContext.cargoImportModel.discharges[c.number]);
        VisitContext.replaceVisit(VisitContext.visit);
        AppContext.registerSuccess('Selected containers have been refused successfully');
      });
  }

  addSelectedToManifest(portOfLoading : Port) {
    this.selectedItems.forEach(o => {
      const container = createImportContainerFromDischargeResult(o, portOfLoading);
      Manifest.computeManifest(portOfLoading).containers.push(container);
    });

  }

  toggleSelectAll() {
    if (this.selectedItems.length === this.visibleItems.length) {
      this.visibleItems.forEach(c => c['selected'] = false);
    } else {
      this.visibleItems.forEach(c => c['selected'] = true);
    }
  }
}

export function createImportContainerFromDischargeResult(o: DischargeResult, portOfLoading: Port) {
  const container: ImportContainerModel = <any>cloneObject(o);
  container.portOfLoading = portOfLoading;
  container.portOfDischarge = VisitContext.savedVisit.portOfCall.port;
  container.dischargeResult = o;
  resolveTerminal(container);
  delete container['selected'];
  container.actualDeparture = Manifest.computeManifest(portOfLoading).getActualDeparture();
  return container;
}

function resolveTerminal(container : ImportContainerModel) {
  const berthVisits = VisitContext.savedVisit.visitDeclaration.portVisit.berthVisits;
  const berthVisit = container.terminal
    && (berthVisits.find(v => v.berth.terminalCode === container.terminal.terminalCode)
      || berthVisits.find(v => v.berth.organisationShortName === container.terminal.organisationShortName));
  if (!berthVisit) {
    container.terminal = null;
    container.ngInvalid = true;
  } else if (berthVisit.berth.terminalCode !== container.terminal.terminalCode) {
    container.terminal = <Terminal>berthVisit.berth;
  }
}
