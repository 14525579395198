import {Component, Input} from '@angular/core';
import {BerthVisit, TerminalVisit} from "@portbase/bezoekschip-service-typescriptmodels";
import {VisitContext} from "../../../../visit-context";
import {AppContext} from "../../../../../app-context";
import {PortvisitUtils} from "../../../../../refdata/portvisit-utils";

@Component({
  selector: 'app-terminal-planning-admin',
  templateUrl: './terminal-planning-admin.component.html',
  styleUrls: ['./terminal-planning-admin.component.css']
})
export class TerminalPlanningAdminComponent {
  context = VisitContext;
  appContext = AppContext;
  utils = PortvisitUtils;

  @Input() berthVisit: BerthVisit;
  @Input() terminalVisit: TerminalVisit;

  openAcceptPlanningModal() {
    $('#' + this.berthVisit.id + '-acceptPlanningModal').modal("show");
  }

  openIftsaiOut() {
    this.utils.fetchProcessIdAndOpenMessageManagement("iftsai-out-" + this.context.visit.crn + "-" + this.berthVisit.callId);
  }
}
