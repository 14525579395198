import {Component, Input, OnInit} from '@angular/core';
import {WashingModel} from '../../dangerous-goods.model';
import {VisitContext} from "../../../visit-context";
import {BerthVisit, OtherDestination} from "@portbase/bezoekschip-service-typescriptmodels";
import moment from "moment";

@Component({
  selector: 'app-edit-cleaning',
  templateUrl: './edit-washing.component.html',
  styleUrls: ['./edit-washing.component.css']
})

export class EditWashingComponent implements OnInit {
  context = VisitContext;
  @Input() handling: WashingModel;
  startDateEntered: boolean = false;
  berthVisit: BerthVisit;

  get berthVisitEta(): string {
    return this.berthVisit != null ? this.berthVisit.eta : null;
  }

  get berthVisitEtd(): string {
    return this.berthVisit != null ? this.berthVisit.etd : null;
  }

  formatter = value => value ? "Commercial Wash" : "PreWash";

  ngOnInit(): void {
    this.berthVisit = VisitContext.berthVisitById(this.handling.berthVisitId);
    if (this.handling.otherDestination === "OTHER_CARGO_TANK") {
      this.handling.otherDestinationTank = this.handling.otherCargoTank;
    } else {
      this.handling.otherDestinationTank = this.handling.otherDestination;
    }
  }

  otherDestinations = () => <(OtherDestination | string)[]>['SAME_CARGO_TANK', 'OFF_BOARD'].concat(this.handling.destinationTanks).sort();

  otherDestinationsFormatter = (value: OtherDestination | string) => {
    switch (value) {
      case "OFF_BOARD":
        return 'Off board';
      case "SAME_CARGO_TANK":
        return 'Same cargo tank';
    }
    return value;
  };

  isOtherCargoTank = (value: OtherDestination | string) => {
    return value !== 'SAME_CARGO_TANK' && value !== 'OFF_BOARD';
  };


  flushWashWater(value) {
    if (value === 'SAME_CARGO_TANK') {
      this.handling.ventilated = null;
    }
    if (this.isOtherCargoTank(value)) {
      this.handling.otherDestination = OtherDestination.OTHER_CARGO_TANK;
      this.handling.otherCargoTank = value;
    } else {
      this.handling.otherDestination = <OtherDestination>value;
      this.handling.otherCargoTank = null;
    }
  }

  blurStartDate() {
    this.startDateEntered = true;
  }

  beforeEtaBerth() {
    return this.berthVisitEta && moment(this.handling.timestamp).isBefore(this.berthVisit.eta);
  }

  afterEtdBerth() {
    return this.berthVisitEtd && moment(this.handling.timestamp).isAfter(this.berthVisit.etd);
  }
}
