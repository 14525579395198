import {Component, Input} from '@angular/core';
import {
  AdditionalIncomingMovementInfo,
  EntryDependency,
  HarbourMasterInfo,
  Vessel
} from "@portbase/bezoekschip-service-typescriptmodels";
import moment from "moment";

@Component({
  selector: 'app-port-entry-warnings',
  templateUrl: './port-entry-warnings.component.html',
  styleUrls: ['./port-entry-warnings.component.css']
})
export class PortEntryWarningsComponent {

  @Input() additionalIncomingMovementInfo: AdditionalIncomingMovementInfo;
  @Input() incomingMovementHarbourMasterInfo: HarbourMasterInfo;
  @Input() entryDependency: EntryDependency;
  @Input() isRotterdam: boolean;
  @Input() isAmsterdam: boolean;
  @Input() pilotStationCode: string;
  @Input() atSea: boolean;
  @Input() vesselDraft: number;
  @Input() vessel: Vessel;
  @Input() portEntryIsOrdered: boolean;
  @Input() requestedEtaPilotBoardingPlace: string;
  @Input() etaPilotBoardingPlace: string;
  @Input() cancellationReason: string;

  determineProperPilotBoardingPlaceAtSeaForAmsterdam(vesselDraft: number): string {
    return vesselDraft < 14.1 ? "KP" : "RV";
  }

  determineProperPilotBoardingPlaceAtSeaForRotterdam(vesselDraft: number): string {
    if (vesselDraft >= 17.4) {
      return "RV";
    } else if (this.vessel.lngShip && this.vessel.fullLength >= 180) {
      return "LNG";
    } else if (vesselDraft >= 14.3 || this.vessel.fullLength >= 350) {
      return "E13";
    } else {
      return "MC";
    }
  }

  get pilotBoardingPlaceWarning(): string {
    if (this.pilotStationCode && this.atSea) {
      const vesselDraft = !this.incomingMovementHarbourMasterInfo.vesselDraught ?
        this.vesselDraft : this.incomingMovementHarbourMasterInfo.vesselDraught;
      const properCode = this.isRotterdam ? this.determineProperPilotBoardingPlaceAtSeaForRotterdam(vesselDraft)
        : (this.isAmsterdam ? this.determineProperPilotBoardingPlaceAtSeaForAmsterdam(vesselDraft) : null);

      if (properCode === "RV" && this.pilotStationCode !== "RV") {
        return "Rendez-vous should be chosen as pilot boarding place for this vessel draft";
      } else if (properCode === "LNG" && this.pilotStationCode !== "LNG") {
        return "LNG should be chosen as pilot boarding place for this vessel";
      } else if (properCode === "E13" && this.pilotStationCode !== "E13") {
        return "E13 should be chosen as pilot boarding place for this vessel or vessel draft";
      } else if (properCode === "MC" && this.pilotStationCode !== "MC") {
        return "Maascenter should be chosen as pilot boarding place for this vessel or vessel draft";
      } else if (properCode === "KP" && this.pilotStationCode !== "KP") {
        return "Kruispost should be chosen as pilot boarding place for this vessel draft";
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  get getShiftedIncomingOrder(): boolean {
    return this.requestedEtaPilotBoardingPlace && this.additionalIncomingMovementInfo?.etaPilotBoardingPlace
      && !moment(this.requestedEtaPilotBoardingPlace).isSame(this.additionalIncomingMovementInfo.etaPilotBoardingPlace)
  }

  get orderingPossible(): boolean {
    return this.entryDependency && !this.entryDependency.autoOrder &&
      (["ORDERED", "CONFIRMED"].indexOf(this.entryDependency.orderStatus) > -1);
  }

  get shouldChangeEtaPbp(): boolean {
    return this.entryDependency && !this.entryDependency.autoOrder && this.portEntryIsOrdered
      && this.orderingPossible && this.additionalIncomingMovementInfo.entryDependencyRemarks == null
      && (this.etaPbpIsBeforeEtdBerth);
  }

  get etaPbpIsBeforeEtdBerth() {
    return this.entryDependency && this.entryDependency.dependencyType === 'EXCHANGE' &&
      this.etaPilotBoardingPlace && this.entryDependency.estimatedTimeBerth &&
      moment(this.etaPilotBoardingPlace).isBefore(this.entryDependency.estimatedTimeBerth);
  }

  get orderCancelledOrRejected(): boolean {
    return this.entryDependency && !this.entryDependency.autoOrder &&
      (["CANCELLED", "REJECTED"].indexOf(this.entryDependency.orderStatus) > -1);
  }

  get dependentVesselOnHold(): boolean {
    return this.entryDependency && this.entryDependency.movementOnHold;
  }

  get isAutoOrdered(): boolean {
    return this.entryDependency && this.entryDependency.autoOrder &&
      (["ORDERED", "CONFIRMED", "ETD_CHANGED", "ETA_CHANGED"].indexOf(this.entryDependency.orderStatus) > -1);
  }

  get movementOnHold(): boolean {
    return this.incomingMovementHarbourMasterInfo && this.incomingMovementHarbourMasterInfo.movementOnHold;
  }
}
