<div class="row sticky-top bg-white" style="top:6.125rem">
  <div class="col-md-12">
    <app-alerting></app-alerting>
  </div>
</div>

<div class="pt-3">
  <div class="tab-pane" role="tabpanel">
    <div class="p-4 border rounded-bottom">
      <app-form #form>
        <app-form-group label="Type">
          <input [ngModel]="null" #type class="form-control" required>
        </app-form-group>
        <app-form-group label="Payload">
          <textarea [ngModel]="null" #payload required class="form-control" style="min-height: 10rem"></textarea>
        </app-form-group>
        <div class="row">
          <div class="col-auto ms-auto">
            <button class="btn btn-success" (click)="form.reset(); queryResult = null">Reset</button>
          </div>
          <div class="col-auto">
            <button class="btn btn-warning" (click)="sendQuery(type.value, payload.value)">Send query</button>
          </div>
          <div class="col-auto">
            <button class="btn btn-danger" (click)="sendCommand(type.value, payload.value)">Send command</button>
          </div>
        </div>
      </app-form>
      <div class="my-2" *ngIf="queryResult">
        <pre>{{queryResult | json}}</pre>
      </div>
    </div>
  </div>
</div>
