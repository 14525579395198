import {Component, Input} from "@angular/core";
import {BerthVisit, BunkerOperatorInfo} from "@portbase/bezoekschip-service-typescriptmodels";
import moment from "moment";

@Component({
  templateUrl: "./bunkering-etc.component.html"
})

export class BunkeringEtcComponent {
  @Input() bunkerVisit: BunkerOperatorInfo;
  @Input() berthVisit: BerthVisit;

  possibleDelay(bunkerVisit: BunkerOperatorInfo, berthVisit: BerthVisit) {
    if (bunkerVisit.atc || !berthVisit.etd || berthVisit.atd || this.certainDelay(bunkerVisit, berthVisit)) return false;
    const etc = moment (bunkerVisit.etc);
    const etd = moment(berthVisit.etd);
    return etc.add(30, "minutes").isSameOrAfter(etd);
  }

  certainDelay(bunkerVisit: BunkerOperatorInfo, berthVisit: BerthVisit) {
    if (bunkerVisit.atc || !berthVisit.etd || berthVisit.atd) return false;
    const etc = moment (bunkerVisit.etc);
    const etd = moment(berthVisit.etd);
    return etc.isAfter(etd);
  }
}
