<div class="row">
  <div class="col-md-6">
    <app-form-group>
      <label>Select tanks</label>
      <app-multiselect [(ngModel)]="handling.tankNumbers" [options]="handling.availableTanks"
                       [hideSelected]="true" [enableSelectAll]="true" [autoSelectOnlyOption]="true"
                       placement="top-end" title="Select tanks"></app-multiselect>
    </app-form-group>
  </div>
</div>
