import {Component} from '@angular/core';
import {AppContext} from '../../../app-context';
import {reduceCargoDeclaration, VisitContext} from '../../visit-context';
import {CargoImportModel, getTerminalsWhereCoarrisNotExpected} from '../cargo-import.model';
import {
  Declaration,
  IE3DeclarantWithConsignmentProcesses,
  IE3GetCargoDeclarantsWithConsignmentProcesses,
  ImportDeclaration,
  SyncWpcsManifests
} from '@portbase/bezoekschip-service-typescriptmodels';
import {sendCommand, sendQuery, toTitleCase} from '../../../common/utils';
import {exportDataAsExcel} from '../../../common/upload/excel.utils';
import moment from 'moment';
import {ComparatorChain} from '../../../common/comparator-chain';
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";

@Component({
  selector: 'app-cargo-import-overview',
  templateUrl: './cargo-import-overview.component.html',
  styleUrls: ['./cargo-import-overview.component.scss']
})
export class CargoImportOverviewComponent {
  appContext = AppContext;
  context = VisitContext;
  filterTerm: string;
  filteredDeclarations: ImportDeclaration[] = [];
  amountOfNewCargoDeclarants: number = 0;

  getCargoDeclarantsWithNewConsignments = (): Observable<IE3DeclarantWithConsignmentProcesses[]> =>
    sendQuery("com.portbase.bezoekschip.common.api.consignments.queries.GetCargoDeclarantsWithConsignmentProcesses", <IE3GetCargoDeclarantsWithConsignmentProcesses>{
      crn: this.context.visit.crn,
      startDate: this.context.visit.created
    }).pipe(tap((b: IE3DeclarantWithConsignmentProcesses[]) => this.amountOfNewCargoDeclarants = b.length));

  getDeclarationStatus(declaration: ImportDeclaration): Declaration {
    return reduceCargoDeclaration([declaration]);
  }

  asImportModel(declaration: ImportDeclaration): CargoImportModel {
    return new CargoImportModel(declaration);
  }

  downloadDischargeDifferences() {
    const result = [["Call reference nr", "Cargo handling agent", "Container nr", "Shortlanded", "Overlanded", "Empty", "Discharge date"]];
    const rows = [];
    VisitContext.savedVisit.importDeclarations.filter(d => d.declarations.length > 0 || d.discharges.length > 0).forEach(d => {
      const model = new CargoImportModel(d);
      const noCoarriTerminals: string[] = getTerminalsWhereCoarrisNotExpected(model.manifests);
      model.overlandersWithoutPort.forEach(value => rows.push([VisitContext.savedVisit.crn, toTitleCase(d.cargoDeclarant.fullName),
        value.number, 'N', 'Y', value.empty ? 'Y' : 'N', moment(value.actualTimeOfHandling).format('DD-MM-YYYY HH:mm')]));
      model.manifests.filter(m => m.portOfDischarge.locationUnCode == VisitContext.savedVisit.portOfCall.port.locationUnCode).forEach(m => {
        m.containers.forEach(c => {
          const row = [VisitContext.savedVisit.crn, toTitleCase(d.cargoDeclarant.fullName), c.number,
            c.shortlanded ? 'Y' : 'N', 'N', c.empty ? 'Y' : 'N',
            c.dischargeResult ? moment(c.dischargeResult.actualTimeOfHandling).format('DD-MM-YYYY HH:mm') :
              c.terminal && noCoarriTerminals.indexOf(c.terminal.terminalCode) >= 0 ? 'Not expected to receive discharge date from terminal' : ''];
          rows.push(row);
        });
        m.overlanders.forEach(c => {
          const row = [VisitContext.savedVisit.crn, toTitleCase(d.cargoDeclarant.fullName), c.number,
            'N', 'Y', c.empty ? 'Y' : 'N', moment(c.actualTimeOfHandling).format('DD-MM-YYYY HH:mm')];
          rows.push(row);
        });
      });
    });
    const byAgent = (a, b) => a[1] < b[1] ? -1 : a[1] > b[1] ? 1 : 0;
    const byContainerNr = (a, b) => a[2] < b[2] ? -1 : a[2] > b[2] ? 1 : 0;
    rows.sort(new ComparatorChain(byAgent, byContainerNr).compare);
    rows.forEach(r => result.push(r));
    exportDataAsExcel(result, VisitContext.savedVisit.crn + '_dischargeDifferences.xlsx');
  }

  trackByAgent(index: number, declaration: ImportDeclaration) {
    return declaration.cargoDeclarant.shortName;
  }

  syncToWpcs() {
    AppContext.clearVisitAlerts();
    sendCommand('com.portbase.bezoekschip.common.api.cargo.SyncWpcsManifests', <SyncWpcsManifests>{
      crn: VisitContext.savedVisit.crn
    }, () => AppContext.registerSuccess('The wpcs sync was retried successfully'))
  }
}
