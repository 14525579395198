<div class="d-inline-flex text-truncate mw-100">
  <ng-container *ngFor="let houseConsignment of houseConsignments; index as i">
    <ng-container *ngIf="i === 0">
      <span class="cursor-pointer underline-tertiary link-underline-tertiary link-primary text-body"
            (click)="editHouseConsignment(houseConsignment)">
       <app-highlight [term]="term" [result]="houseConsignment"></app-highlight>
    </span>
    </ng-container>
  </ng-container>
  <button *ngIf="houseConsignments?.length > 1" onPopover data-bs-toggle="popover" [popoverContent]="popoverContent" placement="auto"
          popoverClass="pop-auto-container" class="btn btn-link link-dark p-0 ms-2">
    +{{houseConsignments.length - 1}}
  </button>
</div>

<ng-template #popoverContent>
  <div class="w-100 h-100 text-start p-1 pe-4" [style.max-width.px]="320">
    <div class="btn-close-wrapper rounded-circle position-absolute end-0 top-0 mt-2 me-2">
      <button type="button" class="btn-close" data-bs-toggle="popover" aria-label="Close"></button>
    </div>
    <div class="row gy-3">
      <ng-container *ngFor="let houseConsignment of houseConsignments; index as i">
        <div class="col-12">
          <button class="btn btn-plain p-0" data-bs-toggle="popover" (click)="editHouseConsignment(houseConsignment)">
            {{ houseConsignment }}
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
