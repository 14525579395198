import {Component, ElementRef} from '@angular/core';
import {checkValidity, sendCommand, sendQuery} from "../../../common/utils";
import {
  IE3ConsignmentProcessSummary,
  IE3InputDeclareTranshipment
} from "@portbase/bezoekschip-service-typescriptmodels";
import {AppContext} from "../../../app-context";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-declare-transhipment-own-consignment',
  templateUrl: './declare-transhipment-own-consignment.component.html',
  styleUrls: ['./declare-transhipment-own-consignment.component.css']
})
export class DeclareTranshipmentOwnConsignmentComponent {

  constructor(private element: ElementRef) {
  }

  cargoDeclarantId: string;
  consignmentNumber: string;

  selectConsignment(summary: IE3ConsignmentProcessSummary) {
    this.cargoDeclarantId = summary?.iamConnectedId;
    this.consignmentNumber = summary?.masterConsignment.consignmentNumber;
  }

  reset() {
    this.cargoDeclarantId = '';
    this.consignmentNumber = '';
  }

  submit() {
    if (checkValidity(this.element)) {
      const declaration: IE3InputDeclareTranshipment = {
        cargoDeclarantId: this.cargoDeclarantId,
        consignmentNumber: this.consignmentNumber
      }

      sendCommand('com.portbase.bezoekschip.common.api.consignments.commands.input.InputDeclareTranshipment',
        declaration, () => {
          AppContext.registerSuccess("Declaration transhipment sent successfully");
        });
    }
  }

  findConsignments = term => sendQuery('com.portbase.bezoekschip.common.api.consignments.queries.FindConsignments',
    {term: term, maxHits: 10})
    .pipe(map(content => content.consignments));

}
