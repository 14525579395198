import {Component, ElementRef, EventEmitter, Output, ViewChild} from '@angular/core';
import {checkValidity} from '../utils';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent {
  @ViewChild('form', {static: true}) form: ElementRef;
  @Output() submit = new EventEmitter<SubmitEvent>();

  doSubmit(event: SubmitEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (checkValidity(this.form)) {
      this.submit.emit(event);
    }
  }

  checkValidity() : boolean {
    return this.form.nativeElement.checkValidity();
  }

  reset() {
    this.form.nativeElement.reset();
  }
}
