<div [ngClass]="{'fieldset ' : !isEditable()}">
  <div *ngFor="let item of declaration.data.goodsItems; let index = index">
    <app-collapse [collapse]="c" [deleteHandle]="isEditable() && !declaration.status.sent?.date && removeItem(item)"
                  [showOnLoad]="declaration.data.goodsItems.length === 1 || !item.description" [renderWhenCollapsed]="true">
      <div class="p-2">
        <div class="row narrow-gutters align-items-center">
          <div class="col-auto">
            <span class="fa fa-fw fa-boxes text-dark"></span>
          </div>
          <ng-container *ngIf="!!item.description; else noDescription">
            <div class="col-auto">
                <span class="d-inline-block align-top text-truncate" style="width: 25rem; max-width: 100%">
                  {{item.description | titlecase}}
                </span>
            </div>
            <div class="col-auto d-none d-xl-block" *ngIf="item.previousDocument?.type">
              <span class="fa fa-fw fa-file-invoice mx-1"></span> {{item.previousDocument.documentNumber}}
              / {{item.previousDocument.type.code}}
            </div>
            <div class="col text-end d-none d-md-block text-dark">
              <app-transit-summary [items]="declaration.data.goodsItems"></app-transit-summary>
            </div>
          </ng-container>

          <ng-template #noDescription>
            <div class="col">New good</div>
          </ng-template>
        </div>
      </div>

      <ng-template #c>
        <div class="collapse">
          <div class="py-2 ms-2">
            <app-transit-good [item]="item" [declaration]="declaration"
                              [itemFromCustoms]="declaration.dataFromCustoms.goodsItems.length>index? declaration.dataFromCustoms.goodsItems[index]:emptyGoodsItem()"
                              [isTemplate]="isTemplate"
                              [isEditable]="isEditable()">
            </app-transit-good>
          </div>
        </div>
      </ng-template>
    </app-collapse>
  </div>
</div>
