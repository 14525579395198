<div class="my-3"><i>Locations are synced to WPCS refdata</i></div>
<div class="row">
  <div class="col-md-6">
    <app-form-group><label>Id</label><input class="form-control" [(ngModel)]="location['id']" disabled required></app-form-group>
    <app-form-group><label>Name</label><input class="form-control" [(ngModel)]="location.name" required></app-form-group>
    <app-form-group><label>Location unCode</label><input class="form-control" [(ngModel)]="location.locationUnCode" required></app-form-group>
  </div>
  <div class="col-md-6">
    <app-form-group label="Country">
      <app-search required [ngModel]="location.countryUnCode" [searchFunction]="utils.findCountries"
                  (ngModelChange)="location.countryUnCode = $event?.code" [resultFormatter]="utils.countryFormatter">
      </app-search>
    </app-form-group>
    <app-form-group><label>EU location</label><app-yes-no [(ngModel)]="location.euLocation" required id="euPort"></app-yes-no></app-form-group>
  </div>
</div>
