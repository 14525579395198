import {Component, Input} from '@angular/core';
import {BerthVisit, TerminalVisit} from "@portbase/bezoekschip-service-typescriptmodels";

@Component({
  selector: 'app-terminal-planning-number-info',
  templateUrl: './terminal-planning-number-info.component.html',
  styleUrls: ['./terminal-planning-number-info.component.scss']
})
export class TerminalPlanningNumberInfoComponent {
  @Input() terminalVisit: TerminalVisit;
  @Input() berthVisit: BerthVisit;
}
