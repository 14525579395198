<div class="form-group mb-3 row">
  <span class="col-xl-5 col-form-label {{omitVerticalPadding ? 'pt-0 pb-0' : ''}} pe-0"><ng-container *ngIf="label; else fromTemplate"><label>{{label}}</label></ng-container></span>
    <div class="col-xl">
        <ng-content></ng-content>
    </div>
</div>

<ng-template #fromTemplate>
  <ng-content select="label"></ng-content><ng-content select=".formGroupLabel"></ng-content>
</ng-template>
