<div [ngClass]="{'always-enabled': enabled}">
  <app-form-group label="Transfer to">
    <app-search *ngIf="!context.visit.nextDeclarant && !nextOwner" [disabled]="!enabled" [(ngModel)]="nextOwner"
      [searchFunction]="searchShipOperator" placeholder="Find organisation" dataKey="fullName">
    </app-search>

    <div *ngIf="nextOwner && !context.visit.nextDeclarant">
      <button class="btn btn-secondary me-1" (click)="authorise()">Authorise {{nextOwner?.fullName}}</button>
      <button class="btn btn-secondary me-1" (click)="undoAuthorise()"><span class="fa fa-fw fa-undo-alt"></span>
      </button>
    </div>

    <ng-container *ngIf="context.visit.nextDeclarant && !context.isOrganisationNextDeclarant()">
      <button *ngIf="context.visit.nextDeclarant" (click)="transferVisit()" class="btn btn-secondary me-1">
        <span class="fa fa-fw fa-people-arrows me-1"></span>Transfer to {{this.context.visit.nextOwner.fullName}}
      </button>
      <button *ngIf="context.visit.nextDeclarant" (click)="unAuthorise()" class="btn btn-secondary me-1"><span
          class="fa fa-fw fa-undo-alt"></span></button>
    </ng-container>
  </app-form-group>
</div>
