import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import moment from 'moment';
import {sendQuery} from '../../common/utils';
import {
  CollectorSummary,
  GetCollectorOverview,
  SearchCollectorOverview,
  WasteCollector,
} from '@portbase/bezoekschip-service-typescriptmodels';
import {AppContext} from '../../app-context';
import {WasteCollectorUtils} from '../waste-utils';
import {DateTimeRange} from "../../common/date/date-range/date-time-range";
import {DateRange} from "../../common/date/date-range/date-range";


@Component({
  selector: 'app-collector-overview',
  templateUrl: './collector-overview.component.html',
  styleUrls: ['./collector-overview.component.css']
})
export class CollectorOverviewComponent implements OnInit, AfterViewInit {

  appContext = AppContext;

  private static filter: OverviewFilter;
  summaries: CollectorSummary[];

  utils = WasteCollectorUtils;

  from: number = 0;
  maxItems: number = 100;
  endReached: boolean;
  loading: boolean;

  collector: WasteCollector;

  constructor() { }

  ngOnInit() {
    this.getWasteCollector().subscribe((collector) => {
      this.collector = collector;
    });
  }

	overviewFilter = CollectorOverviewComponent.filter || (CollectorOverviewComponent.filter = {
    searchTerm: '',
    dateRange: getDateRange()
  });

  getWasteCollector = () => sendQuery('com.portbase.bezoekschip.common.api.waste.GetWasteCollector',
    {collectorShortName: AppContext.userProfile.organisation?.shortName});

  ngAfterViewInit(): void {
    this.loadWasteCollections();
  }

  searchFunction = (searchTerm: string): Observable<any> => {
    const dateTimeRange = !!searchTerm ? null : <DateTimeRange>{
      start: moment(this.overviewFilter.dateRange.start).toISOString(),
      end: moment(this.overviewFilter.dateRange.end).add(1, 'days').toISOString()
    };
    const payload = <GetCollectorOverview>{dateTimeRange: dateTimeRange};
    let type = 'com.portbase.bezoekschip.common.api.waste.collector.GetCollectorOverview';

    if (searchTerm) {
      payload.maxHits = this.maxItems;
      (<SearchCollectorOverview>payload).term = searchTerm;
      type = 'com.portbase.bezoekschip.common.api.waste.collector.SearchCollectorOverview';
    }
    return sendQuery(type, payload, {caching: false, showSpinner: !this.summaries});
  };


  loadWasteCollections = (): void => {
    this.searchFunction(this.overviewFilter.searchTerm).subscribe(result => this.renderWasteCollections(result));
  };

  onDateSelection = (dateRange: DateRange): void => {
    if (dateRange) {
      this.overviewFilter.dateRange = dateRange;
      this.from = 0;
      this.endReached = false;
      this.loadWasteCollections();
      const now = moment().startOf('day');
      localStorage.setItem('waste-overview-days-preference', JSON.stringify({
        daysBefore: now.diff(this.overviewFilter.dateRange.start, 'days'),
        daysAfter: moment(this.overviewFilter.dateRange.end).diff(now, 'days')
      }));
    }
  };

  renderWasteCollections = (wasteOverview: CollectorSummary[]) => {
    if (this.from > 0) {
      if (this.summaries.length < this.maxItems) {
        this.endReached = true;
      }
      wasteOverview = this.summaries.concat(wasteOverview);
    }
    this.summaries = wasteOverview;
  };

	addNewCollection() {
	  this.summaries.unshift(null);
	}

  cancelNewCollection() {
	  this.summaries.splice(0, 1);
  }

  get hasNewVisit() {
	  return this.summaries && this.summaries.length > 0 && this.summaries[0] === null;
  }
}

function getDateRange(): DateRange {
  const preference: OverviewPreference = JSON.parse(localStorage.getItem('waste-overview-days-preference'));
  return preference ? {
      start: moment().subtract(preference.daysBefore, 'd').format('YYYY-MM-DD'),
      end: moment().add(preference.daysAfter, 'd').format('YYYY-MM-DD')
    } :
    {
      start: moment().subtract(7, 'd').format('YYYY-MM-DD'),
      end: moment().add(7, 'd').format('YYYY-MM-DD')
    };
}

interface OverviewPreference {
  daysBefore: number;
  daysAfter: number;
}

interface OverviewFilter {
  dateRange: DateRange;
  searchTerm;
}
