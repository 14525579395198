import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from "@angular/router";
import {Observable} from "rxjs";
import {AppContext} from "../app-context";

@Injectable({
  providedIn: 'root'
})
export class NewRouteGuard implements CanActivate {
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    AppContext.isNewRoute = true;
    AppContext.routeData = route.routeConfig.data;
    $(window.document.body).addClass("new-portvisit");
    return true;
  }
}
