<div class="row" *ngIf="declaration.status.info as info" [ngClass]="{'fieldset disabled' : !!declaration.status.infoDeclared?.date}">
  <div class="col-md">
    <app-form-group label="Information available">
      <app-yes-no [(ngModel)]="info.informationAvailable"></app-yes-no>
    </app-form-group>

    <app-form-group label="Information" *ngIf="info.informationAvailable">
      <input class="form-control" placeholder="Enter information about the cargo"
             [(ngModel)]="info.information">
    </app-form-group>

    <app-form-group label="Actual consignee">
      <app-search [(ngModel)]="info.actualConsignee"
                  [minCharacters]="0" [newValueTemplate]="newParty" valueType="party"
                  [searchFunction]="refData.findParties" [inputFormatter]="refData.partyFormatter">
      </app-search>
    </app-form-group>
  </div>

  <div class="col-md">
    <app-form-group label="Received customs receipt">
      <app-yes-no [(ngModel)]="info.receivedCustomsReceipt"></app-yes-no>
    </app-form-group>

    <app-form-group label="Received delivery date" *ngIf="info.receivedCustomsReceipt">
      <app-date-field [(ngModel)]="info.receiptDeliveryDate"></app-date-field>
    </app-form-group>

    <app-form-group label="Presentation office">
      <app-search [(ngModel)]="info.presentationOffice"
                  [searchFunction]="refData.findTransitOffices" [resultFormatter]="refData.transitOfficesFormatter"
                  [inputFormatter]="refData.transitOfficesFormatter" [minCharacters]="2">
      </app-search>
    </app-form-group>
  </div>
</div>

<div class="row" *ngIf="!declaration.status.infoDeclared?.date">
  <div class="col-auto ms-auto">
    <button (click)="send()" class="btn btn-outline-info" tabindex="-1">Declare Proof</button>
  </div>
</div>

<ng-template #newParty let-party="model">
  <app-party [model]="party"></app-party>
</ng-template>
