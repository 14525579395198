<div class="row g-0 mt-3 mb-5 fieldset enabled" [ngClass]="{'disabled' :!(appContext.isAdmin())}">
  <div class="col">
    <ng-container *ngIf="context.cargoLoadingModel">
      <app-tab-panel>
        <app-tab-item [templateRef]="loadingListRef">
          <span class="tab-item">Loading list</span>
          <ng-template #loadingListRef>
            <app-load-list class="d-block mt-4"></app-load-list>
          </ng-template>
        </app-tab-item>
      </app-tab-panel>
    </ng-container>
    <div id="agent-switch-tab" class="always-enabled">
      <app-select *ngIf="appContext.isAdmin()"
                  [ngModel]="context.cargoImportModel?.cargoDeclarant" dataKey="shortName"
                  (ngModelChange)="initializeForCargoAgent($event.shortName)" [formatter]="utils.agentFormatter"
                  [options]="context.savedVisit.cargoDeclarants" placeholder="Select agent"></app-select>
    </div>
  </div>
</div>

<div *ngIf="!context.cargoLoadingModel" class="text-muted text-center" style="margin-top: 50px">
  Please add cargo declarants to this visit.
</div>
