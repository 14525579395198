import {Pipe, PipeTransform} from '@angular/core';
import {lodash} from './utils';

@Pipe({
  name: 'largeNumberFormat'
})
export class LargeNumberFormatPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (!lodash.isNumber(value)) {
      return "";
    }
    return nFormatter(Number(value));
  }
}

//borrowed from https://stackoverflow.com/questions/9461621/format-a-number-as-2-5k-if-a-thousand-or-more-otherwise-900
function nFormatter(number : number, decimals: number = 0) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup.slice().reverse().find(function (item) {
    return number >= item.value;
  });
  return item ? (number / item.value).toFixed(decimals).replace(rx, "$1") + item.symbol : "0";
}
