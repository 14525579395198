import {Injectable} from '@angular/core';
import {of} from 'rxjs';
import {QueryGateway} from '../common/query-gateway';
import {CommandGateway} from '../common/command-gateway';
import {CookieService} from 'ngx-cookie';
import {delay} from 'rxjs/operators';
import {mockProfiles} from "../../../utils/jwt-generator/mocks/user-profiles.mock";
import tokens from "../../../utils/jwt-generator/src/tokens";
import {UserProfile} from "@portbase/bezoekschip-service-typescriptmodels";

@Injectable()
export class UserHandlerMock {
  private static readonly JWT_COOKIE_NAME = "portvisitjwt";

  private currentUser: UserProfile;

  constructor(queryGateway: QueryGateway, commandGateway: CommandGateway, private cookieService: CookieService) {
    queryGateway.registerLocalHandler(this);
    commandGateway.registerLocalHandler(this);

    const contactName = this.cookieService.get("ActiveUser");
    if (contactName) {
      this.currentUser = mockProfiles.find(p => p.organisation?.contact === contactName);
    } else {
      // Default is admin
      this.currentUser = mockProfiles.find(p => p.organisation?.contact === 'System Administrator');
    }
  }

  private findTokenForUser() {
    return tokens[this.currentUser.userName];
  }

  "com.portbase.bezoekschip.common.api.authorisation.GetUserProfile" = (query) => {
    console.log("GetUserProfile is mocked for %s", this.currentUser.organisation?.contact);
    this.cookieService.put(UserHandlerMock.JWT_COOKIE_NAME, this.findTokenForUser());
    return of(this.currentUser).pipe(delay(100));
  };

  "updateUserProfile" = (userProfile: UserProfile) => {
    this.cookieService.put(UserHandlerMock.JWT_COOKIE_NAME, this.findTokenForUser());
    this.cookieService.put("ActiveUser", userProfile.organisation?.contact);
    window.location.reload();
  };

}
