<app-overview [overviewFilters]="filters.overviewFilters" [searchFunction]="searchTermFunction" (termChanged)="searchTermChanged($event)" (resultsFound)="renderRecords($event, false)"
              [isEmpty]="isEmpty" [facetNameFormatter]="nameFormatter" [facets]="facets" [otherFacets]="otherFacets"
              (facetFiltersChanged)="facetFiltersChanged($event)" (loadNextPage)="loadNextPage()" (filtersCleared)="clearFilters($event)"
              [quickViews]="quickViews" [specificFacetFiltersTemplate]="specificFacetFilters">
  <div class="header-bar d-flex">
    <a *ngIf="appContext.isVisitDeclarant()" type="button" class="btn btn-primary text-decoration-none" [routerLink]="'/newVisit'">
      <i class="button-icon fa-light fa-plus"></i>
      <span class="ps-2 d-none d-md-inline">New visit</span>
    </a>
    <div *ngIf="!appContext.isAdmin()" class="d-flex align-items-center ms-3">
      <div class="dropdown">
        <button class="btn btn-plain text-primary d-inline-flex align-items-center py-1 p-md-2 p-1" data-bs-toggle="dropdown" aria-expanded="false">
          <div class="d-none d-md-block">
            {{ownCallsFormatter(filters?.ownCallsOnly)}}
            <div class="d-inline-block"><i class="fa fa-fw fa-light fa-chevron-down ms-1"></i></div>
          </div>
          <div class="d-block d-md-none">
            <div class="d-inline-block">
              <i class="fa fa-fw fa-light fa-eye"></i>
            </div>
          </div>
        </button>
        <ul class="dropdown-menu">
          <li>
            <button class="dropdown-item d-flex justify-content-between px-3"
                    [ngClass]="filters?.ownCallsOnly ? 'text-primary' : ''" (click)="setOwnsCallsOnly(true)">
              <span>{{ownCallsFormatter(true)}}</span>
              <ng-container *ngIf="filters?.ownCallsOnly">
                <i class="fa fa-fw fa-light fa-check"></i>
              </ng-container>
            </button>
          </li>
          <li>
            <button class="dropdown-item d-flex justify-content-between px-3"
                    [ngClass]="!filters?.ownCallsOnly ? 'text-primary' : ''" (click)="setOwnsCallsOnly(false)">
              <span>{{ownCallsFormatter(false)}}</span>
              <ng-container *ngIf="!filters?.ownCallsOnly">
                <i class="fa fa-fw fa-light fa-check"></i>
              </ng-container>
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div class="d-none d-sm-flex align-items-center ms-3">
      <span class="text-muted">{{totalCount}} visits</span>
    </div>
    <div class="d-flex align-items-center ms-3">
      <div class="dropdown">
        <button class="btn btn-plain text-primary d-inline-flex align-items-center py-1 p-md-2 p-1" data-bs-toggle="dropdown" aria-expanded="false">
          <div class="d-none d-md-block">
            {{sortingFormatter(sortingProperty)}}
            <div class="d-inline-block"><i class="fa fa-fw fa-light fa-chevron-down ms-1"></i></div>
          </div>
          <div class="d-block d-md-none">
            <div class="d-inline-block">
              <i class="fa fa-fw fa-light fa-long-arrow-up"></i>
              <i class="fa fa-fw fa-light fa-long-arrow-down" [style.margin-left.rem]="-0.7"></i>
            </div>
          </div>
        </button>
        <ul class="dropdown-menu">
          <ng-container *ngFor="let property of sortingProperties">
            <li>
              <button class="dropdown-item d-flex justify-content-between px-3"
                      [ngClass]="sortingProperty === property ? 'text-primary' : ''"
                      (click)="changeSorting(property)">
                <span>{{sortingFormatter(property)}}</span>
                <ng-container *ngIf="sortingProperty === property">
                  <i class="fa fa-fw fa-light fa-check"></i>
                </ng-container>
              </button>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
  <div class="overview-items">
    <app-visit-overview-item *ngFor="let summary of data; trackBy: trackByRecord" [summary]="summary" [term]="filters.overviewFilters.term"></app-visit-overview-item>
  </div>
  <div class="empty-container col-12 h-100 d-flex align-items-center justify-content-center flex-column">
    <img src="/assets/new-portvisit/no-visits-found.svg" alt="No visits found icon" class="mb-2"/>
    <span class="h1 mb-2">No ship ahoy...</span>
    <span class="text-secondary">Try adjusting your filters or search term</span>
  </div>
</app-overview>
<a *ngIf="feedbackEnabled()" href="" target="_blank" class="feedback-button" id="feedbackButton">Feedback</a>

<ng-template #specificFacetFilters>
  <div class="col-md-12">
    <app-facet-date-range [ranges]="ranges" [filterName]="'Arrival date'" (ngModelChange)="arrivalDateChanged($event)" [(ngModel)]="filters.overviewFilters.filters.timeRange"></app-facet-date-range>
  </div>
</ng-template>
