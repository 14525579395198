import {Component, Input} from '@angular/core';
import {DeclarationStatus, IE3TranshipmentSummary,} from "@portbase/bezoekschip-service-typescriptmodels";
import {formatDateString} from "../../common/utils";

@Component({
  selector: 'app-transhipment-summary',
  templateUrl: './transhipment-summary.component.html',
  styleUrls: ['./transhipment-summary.component.css']
})
export class TranshipmentSummaryComponent {
  @Input() keepTooltipOpen: boolean;
  model: IE3TranshipmentSummary;

  renPhase = (): string => null;
  renPhaseType = (): 'danger' | 'warning' | 'info' | 'success' | 'secondary' | 'dark' | 'light' => null;
  midPhase = (): string => null;
  midPhaseType = (): 'danger' | 'warning' | 'info' | 'success' | 'secondary' | 'dark' | 'light' => null;

  @Input() set setModel(model: IE3TranshipmentSummary) {
    this.model = model;
    this.renPhase = () => {
      if (!model.renStatus) {
        return "REN Scheduled"
      }
      switch (model.renStatus.status) {
        case DeclarationStatus.DECLARED:
          return 'REN sent';
        case DeclarationStatus.REJECTED:
          return 'REN rejected';
        case DeclarationStatus.ACCEPTED:
          return 'REN accepted';
      }
    };
    this.midPhase = () => {
      if (!model.midStatus) {
        return ""
      }
      switch (model.midStatus.status) {
        case DeclarationStatus.DECLARED:
          return 'MID sent';
        case DeclarationStatus.REJECTED:
          return 'MID rejected';
        case DeclarationStatus.ACCEPTED:
          return 'MID accepted';
      }
    };
    this.renPhaseType = () => {
      if (!model.renStatus) {
        return 'dark';
      }
      switch (model.renStatus.status) {
        case DeclarationStatus.DECLARED:
          return 'info';
        case DeclarationStatus.REJECTED:
          return 'danger';
        case DeclarationStatus.ACCEPTED:
          return 'success';
      }
    }
    this.midPhaseType = () => {
      if (!model.midStatus) {
        return 'dark';
      }
      switch (model.midStatus.status) {
        case DeclarationStatus.DECLARED:
          return 'info';
        case DeclarationStatus.REJECTED:
          return 'danger';
        case DeclarationStatus.ACCEPTED:
          return 'success';
      }
    }

  };

  formatDate = (dateString: string): string => formatDateString(dateString);
}

