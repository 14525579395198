<div class="row">
  <div class="col-md-6">
    <app-form-group><label>Id</label><input class="form-control" [(ngModel)]="wasteMaterial['id']" disabled required>
    </app-form-group>
    <app-form-group><label>Type Code</label><input class="form-control" [(ngModel)]="wasteMaterial.typeCode"
                                                   required></app-form-group>
    <app-form-group><label>Eural Code</label><input class="form-control" [(ngModel)]="wasteMaterial.euralCode">
    </app-form-group>
    <app-form-group><label>IL&T Code</label><input class="form-control" [(ngModel)]="wasteMaterial.iltCode">
    </app-form-group>
    <app-form-group label="SSN">
      <app-search required [(ngModel)]="wasteMaterial.ssn" [searchFunction]="getAllWasteTypes"
                  [inputFormatter]="ssnFormatter" placeholder="Find SSN code">
      </app-search>
    </app-form-group>
    <app-form-group><label>Name EN</label><input class="form-control" [(ngModel)]="wasteMaterial.nameEN" required>
    </app-form-group>
    <app-form-group><label>Name NL</label><input class="form-control" [(ngModel)]="wasteMaterial.nameNL" required>
    </app-form-group>
    <app-form-group><label>Specification Required</label>
      <app-yes-no [(ngModel)]="wasteMaterial.ssn.specificationRequired" readonly required
                  id="specificationRequired"></app-yes-no>
    </app-form-group>
  </div>
  <div class="col-md-6">
    <app-form-group><label>Start</label>
      <app-date-field [(ngModel)]="wasteMaterial.start" [dateOnly]="true" [yearSpan]="1000" id="start"
                     required></app-date-field>
    </app-form-group>
    <app-form-group><label>End</label>
      <app-date-field [(ngModel)]="wasteMaterial.end" [dateOnly]="true" [yearSpan]="1000" id="end"></app-date-field>
    </app-form-group>
    <app-form-group><label>Unit Of Measure</label>
      <app-select id="port" [(ngModel)]="wasteMaterial.unitOfMeasure" [optionsProvider]="unitsOfMeasure"
                  [addSelectedIfNotExists]="false" placeholder="– Select a unit of measure –" required></app-select>
    </app-form-group>
    <app-form-group><label>Unit Conversion To Cubic Meters</label><input class="form-control"
                                                                         [disabled]="wasteMaterial.unitOfMeasure == 'm3'"
                                                                         [(ngModel)]="wasteMaterial.unitConversionToCubicMeters"
                                                                         appDecimalNumber [maxDecimals]="3">
    </app-form-group>
  </div>
</div>
