import {Component, ElementRef, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {
  Country,
  IE3AddressBook,
  IE3CL707,
  IE3Communication,
  IE3Party,
  IE3UpsertPartyInAddressBook
} from "@portbase/bezoekschip-service-typescriptmodels";
import {PortvisitUtils} from "../../../../../refdata/portvisit-utils";
import {ConsignmentUtils} from "../../../consignment.utils";
import {cloneDeep} from "lodash";
import {checkValidity, clearValidation, sendCommand, uuid} from "../../../../../common/utils";
import {AppContext} from "../../../../../app-context";
import {Observable} from "rxjs";
import countryValidations from "./country-validation.json";

@Component({
  selector: 'app-consignment-party-details',
  templateUrl: './consignment-party-details.component.html',
  styleUrls: ['./consignment-party-details.component.scss']
})
export class ConsignmentPartyDetailsComponent implements OnInit {
  portVisitUtils = PortvisitUtils;
  utils = ConsignmentUtils;
  elementRef: ElementRef = inject(ElementRef);
  validations: CountryValidation[] = countryValidations as CountryValidation[];

  @Input() cargoDeclarantId: string;
  @Input() label: string;
  @Input() party: IE3Party;
  @Input() editMode: boolean;
  @Input() required: boolean;
  @Input() deletionAllowed: boolean;
  @Input() communicationRequired: boolean;

  addressBookId: string;
  partyEditMode: boolean;
  backupParty: IE3Party;
  addressBook: IE3AddressBook;
  countryValidation: CountryValidation;
  defaultTelephone: IE3Communication = { type: IE3CL707.TE };
  defaultEmail: IE3Communication = { type: IE3CL707.EM };
  countries: Map<string, Country>;

  @Output() partyChange: EventEmitter<IE3Party> = new EventEmitter<IE3Party>();

  countryFormatter = (value: Country | string) => typeof value === "string"
    ? this.countries?.get(value) ? this.countryFormatter(this.countries.get(value)) : value
    : ((value.name && value.code) ? `${value.name} - ${value.code}` : '');

  ngOnInit() {
    ConsignmentUtils.getCountries().subscribe(c => this.countries = c);
    if (this.party) {
      ConsignmentUtils.findParties(null, this.cargoDeclarantId, this.party)
        .subscribe((p: IE3AddressBook[]) => this.updateAddressBook(p[0]));
      this.updateCountryValidation();
      if (this.party["isNewRecord"]) {
        this.onPartyChange(this.newParty(this.party.name));
      }
    }
  }

  findParties = (term: string): Observable<IE3AddressBook[]> => ConsignmentUtils.findParties(term, this.cargoDeclarantId);

  get streetRequired() {
    return ['POSTAL_CODE_AND_STREET'].includes(this.countryValidation?.validationCode) && !this.party.address.poBox;
  }

  get telephone() {
    return this.party.communications.find(c => c.type === IE3CL707.TE) || this.defaultTelephone;
  }

  get email() {
    return this.party.communications.find(c => c.type === IE3CL707.EM) || this.defaultEmail;
  }

  toggleEditMode = () => {
    this.partyEditMode = !this.partyEditMode;
    if (this.partyEditMode) {
      this.backupParty = cloneDeep(this.party);
    }
  }

  cancel = () => {
    this.party = this.backupParty;
    this.toggleEditMode();
    clearValidation(this.elementRef);
  }

  save = () => {
    if (checkValidity(this.elementRef)) {
      this.processModel();
      this.toggleEditMode();
      this.partyChange.emit(this.party);
      if (this.addressBook["saveInAddressBook"]) {
        this.addressBookId = this.addressBookId || uuid();
        sendCommand("com.portbase.bezoekschip.common.api.consignments.addressbook.UpsertPartyInAddressBook", <IE3UpsertPartyInAddressBook> {
          addressBookId: this.addressBookId,
          party: this.party
        }, () => AppContext.registerSuccess("Successfully saved party in address book"));
      }
    }
  }

  processModel = () => {
    if (!this.party.communications?.find(c => c.type === IE3CL707.TE) && this.defaultTelephone.identifier) {
      this.party.communications.push(this.defaultTelephone);
    }
    if (!this.party.communications?.find(c => c.type === IE3CL707.EM) && this.defaultEmail.identifier) {
      this.party.communications.push(this.defaultEmail);
    }
    this.party.communications = this.party.communications?.filter(c => c.identifier) || [];
  }

  newParty = (value: string): IE3AddressBook => {
    const addressBook = this.createAddressBookFromParty({
      name: value,
      address: {},
      communications: []
    });
    addressBook["isNewRecord"] = true;
    return addressBook;
  }

  onPartyChange = (value: IE3AddressBook) => {
    this.addressBook = value;
    this.party = value?.party;
    if (value && value["isNewRecord"]) {
      this.partyEditMode = true;
    }
    if (!this.partyEditMode) {
      this.partyChange.emit(this.party);
    }
  }

  private updateAddressBook(addressBook: IE3AddressBook) {
    this.addressBook = addressBook || this.createAddressBookFromParty(this.party);
    this.addressBookId = this.addressBook.addressBookId;
    if (addressBook) {
      this.addressBook["saveInAddressBook"] = true;
    }
  }

  private createAddressBookFromParty = (party: IE3Party): IE3AddressBook => ({
    party: party,
    addressBookId: uuid(),
    ownerIamConnectedId: this.cargoDeclarantId
  });

  onCountryChange(country: Country) {
    this.party.address.country = country?.code;
    if (this.party.address.country) {
      this.updateCountryValidation();
    }
  }

  updateCountryValidation = () => {
    this.countryValidation = this.party.address.country
      ? this.validations.find(c => c.countryCode === this.party.address.country) : null;
  }

  deleteParty = () => this.partyChange.emit(null)
}

interface CountryValidation {
  countryCode: string;
  validationCode: "POSTAL_CODE_AND_STREET" | "POSTAL_CODE" | "COUNTRY_ONLY";
}
