<div class="row narrow-gutters align-items-center position-relative">
  <div class="col" (change)="refresh()">
    <ng-content></ng-content>
  </div>
  <div class="col-auto position-absolute" style="right: 1rem" *ngIf="status && status.tidalShip">
    <app-tooltip [wide]="true">
      <span class="fa text-info fa-fw" [ngClass]="status.tidalWindowType === 'ASTRO' ? 'fa-satellite-dish' : 'fa-water'"></span>
      <div class="tooltipContent">
        <div class="mb-2">
          Please select a time in one of the tidal windows:
        </div>
        <div *ngFor="let window of status.tidalWindows" class="my-1">
          <app-formatted-date-range [start]="window.start" [end]="window.end"></app-formatted-date-range>
        </div>
        <div *ngIf="status.remarks">
          Harbour master remarks: {{status.remarks}}
        </div>
        <div *ngIf="status.tidalWindowType === 'ASTRO'">
          <div>These tidal windows are based on astro data and are indicative</div>
          <div>No rights can be derived from this information</div>
        </div>
        <div *ngIf="status.tidalWindowType === 'ACTUAL'">
          This tidal window has been determined by HCC
        </div>
      </div>
    </app-tooltip>
  </div>
</div>
