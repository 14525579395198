import {Component, OnInit} from '@angular/core';
import {DeclarationType, PilotStation, SeaEntry} from '@portbase/bezoekschip-service-typescriptmodels/common';
import {sendQuery} from 'src/app/common/utils';
import {VisitContext} from '../../../../visit-context';
import {VisitValidator} from "../../../../visit-validator";

@Component({
  selector: 'app-pilot-boarding-place',
  templateUrl: './pilot-boarding-place.component.html',
  styleUrls: ['./pilot-boarding-place.component.css']
})
export class PilotBoardingPlaceComponent implements OnInit {

  context = VisitContext;

  validator = VisitValidator;

  constructor() {
  }

  ngOnInit() {
  }

  pilotStations = sendQuery(
    'com.portbase.bezoekschip.common.api.visit.GetPilotStations',
    {
      portUnCode: VisitContext.visit.portOfCall.port.locationUnCode,
      atSea: this.context.visit.orderIncomingMovement && this.context.visit.visitDeclaration.portVisit.portEntry.origin === 'SEA',
      isOrderIncomingMovement: this.context.visit.orderIncomingMovement
    });

  pilotBoardingPlaceFormatter = (pilotBoardingPlace: PilotStation) => pilotBoardingPlace ? pilotBoardingPlace.name : '';

  setEtaIfPilotBoardingPlaceIsMaascenter($event: PilotStation) {
    if (this.context.visit.visitDeclaration.portVisit.portEntry.baseForPlanning === 'PILOT_BOARDING_PLACE') {
      if ($event && $event.code === "MC" && !this.context.hasBeenAcceptedAtLeastOnce(DeclarationType.VISIT)) {
        this.context.visit.visitDeclaration.portVisit.portEntry.etaPilotBoardingPlace = (<SeaEntry>this.context.visit.visitDeclaration.portVisit.portEntry).etaSeaBuoy;
      } else {
        this.context.visit.visitDeclaration.portVisit.portEntry.etaPilotBoardingPlace = null;
        this.context.visit.visitDeclaration.portVisit.portEntry.requestedEtaPilotBoardingPlace = null;
        if (this.context.visit.visitDeclaration.portVisit.berthVisits.length > 0) {
          this.context.visit.visitDeclaration.portVisit.berthVisits[0].eta = null;
        }
      }
    }
    this.context.updateCalculatedEtas();
  }

  onEtaPbpChanged(eta: any) {
    this.context.shiftTime(this.context.visit.visitDeclaration.portVisit.portEntry, 'etaPilotBoardingPlace', eta);
    this.removeConfirmedEta();
    if (eta) {
      setTimeout(() => this.context.updateCalculatedEtas(), 0);
    }
  }

  removeConfirmedEta() {
    if (this.context.visit.additionalIncomingMovementInfo?.etaPilotBoardingPlace
      != this.context.visit.visitDeclaration.portVisit.portEntry.etaPilotBoardingPlace) {
      const berthVisits = this.context.visit.visitDeclaration.portVisit.berthVisits;

      if (berthVisits.length > 0) {
        const harbourMasterInfo = this.context.visit.berthVisitInfos[berthVisits[0]?.id]?.harbourMasterInfo;

        if (harbourMasterInfo != null) {
          harbourMasterInfo.eta = null;
        }
      }

      if (this.context.visit.additionalIncomingMovementInfo) {
        this.context.visit.additionalIncomingMovementInfo.etaPilotBoardingPlace = null;
      }
    }
  }
}
