<ng-container *ngIf="visibleItems as items">
  <app-pagination #pagination [items]="items">
    <ng-container *ngFor="let container of pagination.getItemsOnPage(); let index = index;">

      <!-- render terminal -->
      <ng-container *ngIf="showTerminal && container.terminal && (index === 0
          || container.terminal?.terminalCode !== items[index - 1].terminal?.terminalCode)">
        <div class="mt-3 row g-0 align-items-center">
          <div class="col-auto me-2 invisible" *ngIf="!readonly">
            <div class="form-check form-check-inline m-0" style="height: 2.5rem">
              <input class="form-check-input m-0" type="checkbox"></div>
          </div>
          <div class="col py-1 ps-3 border border-white" style="border-width: 2px!important;">
            <span class="fa fa-fw fa-map-marker-alt text-portbase-blue pe-2 me-2"></span>
            <span class="ms-1 fw-bold text-dark">{{utils.terminalFormatter(container.terminal)}}</span>
          </div>
        </div>
      </ng-container>

      <div class="row g-0">
        <div class="col-auto me-2" *ngIf="!readonly">
          <div class="form-check form-check-inline m-0 always-enabled" style="height: 2rem">
            <input [(ngModel)]="container['selected']" class="always-enabled form-check-input m-0" type="checkbox"></div>
        </div>
        <div class="col">
          <app-collapse #containerCollapse [collapse]="containerTemplate" [showOnLoad]="!container.number && !container['duplicate']"
                        [deleteHandle]="context.cargoImportModel.isEditor() && removeContainer(container)" [validationModel]="container">
            <div class="row g-0 py-1 px-3 align-items-center text-dark">
              <div class="col">
                <span class="fa fa-fw fa-cube pe-2 me-2"></span>
                <span class="d-inline-block align-top text-truncate">{{container.number || 'New container'}}</span>
              </div>
              <div class="col d-none d-xl-flex text-dark" *ngIf="container.sizeType">
                <span class="d-none d-sm-inline-block align-top text-truncate" *ngIf="container.sizeType"><span class="fa fa-expand-alt ms-3 me-2"></span>{{container.sizeType.code}}</span>
              </div>
              <div class="col text-end me-3 d-none d-md-block text-dark">
                <app-cargo-summary [container]="container" [keepTooltipOpen]="containerCollapse.showing"></app-cargo-summary>
              </div>
            </div>
          </app-collapse>
        </div>
      </div>

      <ng-template #containerTemplate>
        <div class="collapse">
          <div class="mx-3 pt-3">
            <app-import-container [container]="container"></app-import-container>
          </div>
        </div>
      </ng-template>
    </ng-container>

    <div class="row g-0 text-secondary" *ngIf="items.length > 1 && !readonly">
      <label class="py-2">
        <input [(ngModel)]="items.length === selectedItems.length"
               (click)="toggleSelectAll()" class="always-enabled" type="checkbox">
        <span class="ms-2">Select all</span>
      </label>
    </div>
  </app-pagination>
</ng-container>

<div *ngIf="hiddenItems.length as hiddenCount" class="text-center text-muted" style="padding: 20px 0">
  Hiding {{hiddenCount}} filtered container{{hiddenCount == 1 ? '' : 's'}}
  <button type="button" class="btn btn-outline-info btn-sm ms-2" (click)="showAll()">Show all</button>
</div>


<!--Bulk actions-->
<ng-container *ngIf="selectedItems.length > 0">
  <div class="fixed-bottom row g-0 justify-content-center bg-white py-3 border-top">
    <div class="col-auto mx-1">
      <button class="btn btn-outline-danger" style="min-width: 10rem" type="button"
              (click)="removeSelected()">Delete</button>
    </div>
    <div class="col-auto mx-1" *ngIf="mayBeDuplicated(selectedItems)">
      <button class="btn btn-outline-info" style="min-width: 10rem" type="button"
              (click)="duplicatedSelected()">Duplicate</button>
    </div>
    <div class="col-auto mx-1">
      <div class="dropdown">
        <button class="btn btn-outline-secondary dropdown-toggle" style="min-width: 10rem" type="button" data-bs-toggle="dropdown">Change port of loading
          <span class="caret"></span></button>
        <ul class="dropdown-menu dropdown-menu-start">
          <li class="dropdown-item" *ngFor="let port of utils.getPreviousPorts()" (click)="moveSelectedContainers(port)">
            {{port.name}}
          </li>
        </ul>
      </div>
    </div>
    <div class="col-auto mx-1">
      <div class="dropdown">
        <button class="btn btn-outline-secondary dropdown-toggle" style="min-width: 10rem" type="button" data-bs-toggle="dropdown">Update
          <span class="caret"></span></button>
        <ul class="dropdown-menu dropdown-menu-start">
          <li class="dropdown-item" data-bs-toggle="modal" data-bs-target="#demurrageModal">
            Demurrage date
          </li>
          <li class="dropdown-item" data-bs-toggle="modal" data-bs-target="#redeliveryModal">
            Redelivery address
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div #demurrageModal class="modal fade" id="demurrageModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Bulk update demurrage</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal">
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md">
              <app-form-group  label="Initial demurrage">
                <app-date-field [ngModel]="currentDemurrage" #demurrage required></app-date-field>
              </app-form-group>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-info" style="min-width: 10rem"
                  (click)="bulkUpdateDemurrage(demurrageModal, demurrage)">Update demurrage</button>
        </div>
      </div>
    </div>
  </div>

  <div #redeliveryModal class="modal fade" id="redeliveryModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Bulk update redelivery address</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal">
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md">
              <app-form-group label="Address">
                <textarea #redelivery [ngModel]="currentRedelivery" required
                          class="form-control" style="min-height: 5.75rem;"></textarea>
              </app-form-group>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-info" style="min-width: 10rem"
                  (click)="bulkUpdateRedelivery(redeliveryModal, redelivery)">Update redelivery</button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
