<ng-container *ngIf="(appContext.isAdmin() || context.isOrganisationPortAuthority()) && !context.eventId">
  <div *ngIf="context.isOrganisationPortAuthority()" class="always-enabled">
    <button *ngIf="'DECLARED' === context.declarationStatusOf(WASTE)" data-bs-toggle="modal"
            data-bs-target="#rejectWasteModal" id="rejectWaste"
            type="button" class="btn btn-secondary me-2" style="min-width: 6rem" tabindex="-1">Reject
    </button>
    <button *ngIf="'DECLARED' === context.declarationStatusOf(WASTE)
    || 'REJECTED' === context.declarationStatusOf(WASTE)" (click)="accept()" id="acceptWaste"
            type="button" class="btn btn-secondary me-2" style="min-width: 6rem" tabindex="-1">Accept
    </button>
  </div>

  <div class="dropdown always-enabled" *ngIf="!context.isOrganisationPortAuthority()">
    <button class="btn btn-secondary" type="button" id="adminHamburgerMenu" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    <!-- Hamburger menu icon -->
    <i class="fa fa-user-shield" aria-hidden="true"></i>
    </button>
    <div class="dropdown-menu dropdown-menu-end" aria-labelledby="adminHamburgerMenu">
      <!-- Reject declaration -->
      <a *ngIf="'DECLARED' === context.declarationStatusOf(WASTE)" data-bs-toggle="modal" data-bs-target="#rejectWasteModal"
        id="rejectWaste" class="dropdown-item text-danger" tabindex="-1" role="button">
        Reject
      </a>
      <!-- Accept declaration -->
      <a *ngIf="'DECLARED' === context.declarationStatusOf(WASTE) || 'REJECTED' === context.declarationStatusOf(WASTE)"
         (click)="accept()" id="acceptWaste" class="dropdown-item text-success" tabindex="-1" role="button">Accept</a>
      <ng-container *ngIf="appContext.isAdmin()">
      <!-- Upload declaration -->
      <a download="{{context.visit.crn}} + '_waste.xls'" *ngIf="downloadLink" href="{{downloadLink}}" class="dropdown-item" tabindex="-1">
        <i class="fas fa-file-excel"></i> Download uploaded sheet
      </a>
      <!-- Messages -->
      <ng-container *ngIf="appContext.isAdmin()">
        <h6 class="dropdown-header" style="font-size: 18px;">Messages</h6>
        <a href="#" (click)="$event.preventDefault(); openPaMessages()" class="dropdown-item">
          WASTE to Port Authority
        </a>
        <a href="#" (click)="$event.preventDefault(); openSwMessages()" class="dropdown-item">
          WAS to Singlewindow
        </a>
      </ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>

<div id="rejectWasteModal" class="modal fade always-enabled" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Reject reason</h5>
      </div>
      <div class="modal-body">
        <input id="rejectWaste-reason" type="text" class="form-control">
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Back</button>
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                (click)="reject(appContext.getDocument().getElementById('rejectWaste-reason')['value'])">Send
        </button>
      </div>
    </div>
  </div>
</div>
