<ng-container [ngSwitch]="messageStatus">
  <span *ngSwitchCase="'UNKNOWN'" class="fa-kit fa-fw fa-pb-circle-solid me-1 text-unknown"></span>
  <span *ngSwitchCase="'SAVED'" class="fa-kit fa-fw fa-pb-circle-pencil me-1 text-saved"></span>
  <span *ngSwitchCase="'DEADLINE_CLOSE'" class="fa-kit fa-fw fa-pb-clock-nine-thick me-1 text-warning"></span>
  <span *ngSwitchCase="'DEADLINE_MISSED'" class="fa-kit fa-fw fa-pb-clock-twelve-thick me-1 text-portbase-red"></span>
  <span *ngSwitchCase="'PENDING'" class="fa fa-solid fa-fw fa-circle-ellipsis me-1 text-primary"></span>
  <span *ngSwitchCase="'DELIVERED'" class="fa-kit fa-fw fa-pb-circle-arrow-down me-1 text-primary"></span>
  <span *ngSwitchCase="'ACCEPTED'" class="fa-kit fa-fw fa-pb-circle-check-solid me-1 text-portbase-green"></span>
  <span *ngSwitchCase="'ACCEPTED_WITH_CONDITIONS'" class="fa-kit fa-fw fa-pb-circle-comment me-1 text-portbase-green"></span>
  <span *ngSwitchCase="'WARNING'" class="fa-kit fa-fw fa-pb-circle-exclamation-solid me-1 text-warning"></span>
  <span *ngSwitchCase="'REJECTED'" class="fa-kit fa-fw fa-pb-circle-xmark-solid me-1 text-portbase-red"></span>
  <span *ngSwitchCase="'ERROR'" class="fa-kit fa-fw fa-pb-circle-exclamation-solid me-1 text-portbase-red"></span>
</ng-container>

