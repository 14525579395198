<div *ngIf="isPlanningBasedOnPbp">
  <div class="validatable">
    <app-form-group>
      <label for="earliest-etaPBP">Earliest time of port entry</label>
      <app-date-field [(ngModel)]="context.visit.visitDeclaration.portVisit.portEntry.earliestTimeOfPortEntry"
        [calendarStartDate]="context.visit.visitDeclaration.portVisit.portEntry.etaPilotBoardingPlace"
        [disabled]="context.isArrived()" id="earliest-etaPBP"></app-date-field>
    </app-form-group>
  </div>
  <span *ngIf="etaEarliestPBPIsAfterPBP()" class="ng-invalid invalid-feedback mb-2">Earliest time of port entry should be before
    ETA pilot boarding place</span>
</div>
