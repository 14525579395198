<ng-container *ngIf="true">
  <app-collapse [rounded]="true" [collapse]="collapseMovement" [showOnLoad]="!context.hasBeenDeclared() || (context.visit.orderNauticalServices && berthVisit.ata && !berthVisit.atd && !berthVisit.nextMovement.order)" [animateOnLoad]="true"
                [validationModel]="berthVisit.nextMovement" [renderWhenCollapsed]="true">
    <!-- Movement summary (icons: pilot / tugboats / boatment / draft etc. ) -->
    <div class="d-flex p-2">
      <!-- Order status icon (plane / check / cross / etc.)-->
      <span class="me-3">
        <app-order-status [orderStatus]="getOrderStatus()"></app-order-status>

      <ng-container *ngIf="context.visit.orderNauticalServices">
        <ng-container *ngIf="context.visit.berthVisitInfos[berthVisit.id] as berthVisitInfo">

            <span *ngIf="berthVisitInfo?.harbourMasterInfo?.remarks"
              title="{{berthVisitInfo.harbourMasterInfo.remarks.text}}"
              class="ms-1 fa fa-fw fa-comment-alt text-warning"></span>
            <span *ngIf="berthVisitInfo?.harbourMasterInfo?.rejectionReason"
                  title="{{berthVisitInfo.harbourMasterInfo.rejectionReason}}"
              class="ms-1 fa fa-fw fa-comment-alt text-warning"></span>
            <span *ngIf="berthVisitInfo?.harbourMasterInfo?.movementOnHold"
                  title="Your departure from berth {{berthVisit.berth.code}} has been put on hold by the port
                  authorities and may proceed as conditions allow."
              class="ms-1 far fa-fw fa-hand-paper text-danger"></span>

      </ng-container>
      </ng-container>
        </span>
      <div class="d-flex align-items-center">
      <app-movement-summary [berthVisit]="berthVisit" [nextBerthVisit]="getNextBerthVisit"
                            [berthVisitInfo]="getBerthVisitInfo()"></app-movement-summary>

      </div>
      <app-movement-clearance-status
        [clearanceKey]="'ekh'"
        [arrivalBerthVisitId]="arrivalBerthVisitId"
        [departureBerthVisitId]="departureBerthVisitId">
      </app-movement-clearance-status>
    </div>
  </app-collapse>
  <ng-template #collapseMovement>
    <div class="collapse">
      <div class="border rounded-bottom p-3 mb-3">
        <!-- CURRENT DECLARANT -->
        <ng-container *ngIf="!(isBerthOfTransfer && context.isOrganisationNextDeclarant())">
          <div class="fieldset" [ngClass]="{'disabled' : !isNextBerthVisits && nextBerthVisitHasAta()}">
            <app-movement [ngModel]="berthVisit.nextMovement" [id]="berthVisit.id + '-nextMovement'">
            </app-movement>
          </div>
        </ng-container>
        <!-- NEXT DECLARANT -->
        <div class="always-enabled validate-for-next-declarant"
          *ngIf="isBerthOfTransfer && context.isOrganisationNextDeclarant()">
          <ng-container *ngIf="context.visit.nextVisitDeclaration?.lastBerthVisitDeparture">
            <app-movement [ngModel]="context.visit.nextVisitDeclaration.lastBerthVisitDeparture.nextMovement"
              [id]="berthVisit.id + '-nextMovement'"></app-movement>
          </ng-container>
        </div>
        <!-- NAUTICAL SERVICES -->
        <div class="row" *ngIf="areNauticalServicesApplicable(context.visit.portOfCall.port.locationUnCode)">
          <div class="declaration-block col-md-12">
            <app-nautical-services [berthVisit]="berthVisit" [firstBerth]="firstBerth" [lastBerth]="lastBerth"
              [nextBerthVisit]="getNextBerthVisit" [disabled]="berthVisit.atd" [isNextBerthVisits]="isNextBerthVisits"
              [isLastBerthVisitDeparture]="isBerthOfTransfer && context.isOrganisationNextDeclarant()">
            </app-nautical-services>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>
