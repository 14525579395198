import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { VisitContext } from '../../visit-context';

@Component({
  selector: 'app-eta-eu-entry',
  templateUrl: './eta-eu-entry.component.html',
  styleUrls: ['./eta-eu-entry.component.css']
})
export class EtaEuEntryComponent {

  @Input()
  etaFirstEntryEu: string;
  
  @Output('etaFirstEntryEuChange')
  etaFirstEntryEuUpdate = new EventEmitter();

  constructor() { }

  showEtaEuEntry = (): boolean => {
    let previousPorts = VisitContext.visit.visitDeclaration.previousPorts;
    let moreThanZeroPreviousPorts = previousPorts.length > 0;
    let firstPreviousPortisNotEuPort = previousPorts[0] && previousPorts[0].port && !previousPorts[0].port.euPort;

    const result = previousPorts
      && moreThanZeroPreviousPorts
      && firstPreviousPortisNotEuPort;

    if (!result) {
      VisitContext.visit.visitDeclaration.etaFirstEntryEu = null;
    }
    return result;
  };

  onEtaFirstEntryEuChanged(event) {
    this.etaFirstEntryEuUpdate.emit(event);
  }
}
