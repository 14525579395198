<div id="#test1234" class="modal-dialog modal-xl" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Select consignment for transhipment declaration</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
      </button>
    </div>
    <div class="modal-body">
      <div class="row narrow-gutters always-enabled mb-3">
        <div class="col">
          <app-form-group label="Consignment number">
            <app-search required [ngModel]="consignmentNumber" [minCharacters]="3" [searchFunction]="findConsignments"
                        resultFormatter="masterConsignment.consignmentNumber" (ngModelChange)="selectConsignment($event)">
            </app-search>
          </app-form-group>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary me-auto" (click)="reset()" data-bs-dismiss="modal">Cancel</button>
      <button type="button" class="btn btn-primary" (click)="submit()" data-bs-dismiss="modal">Declare transhipment
      </button>
    </div>
  </div>
</div>
