import {InjectorProvider} from './injector-provider';
import {CommandGateway} from './command-gateway';
import { Injectable } from "@angular/core";

@Injectable()
export abstract class CommandHandler {
  constructor() {
    const commandGateway = InjectorProvider.injector.get(CommandGateway);
    commandGateway.registerLocalHandler(this);
  }
}
