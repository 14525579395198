<ng-container *ngIf="(appContext.isAdmin() || context.isOrganisationPortAuthority()) && !context.eventId">
  <div *ngIf="context.isOrganisationPortAuthority()" class="always-enabled">
    <button *ngIf="'DECLARED' === context.declarationStatusOf(VISIT)" (click)="openConfirmationModal(rejectModal)"
            type="button" class="btn btn-secondary me-2" style="min-width: 5rem" tabindex="-1">Reject
    </button>
    <button *ngIf="'DECLARED' === context.declarationStatusOf(VISIT)
    || 'REJECTED' === context.declarationStatusOf(VISIT)" (click)="acceptVisit()"
            type="button" class="btn btn-secondary me-2" style="min-width: 5rem" tabindex="-1">Accept
    </button>
  </div>

  <div class="dropdown always-enabled" *ngIf="!context.isOrganisationPortAuthority()">
    <button class="btn btn-secondary" type="button" id="adminHamburgerMenu" data-bs-toggle="dropdown" aria-haspopup="true"
      aria-expanded="false">
      <!-- Hamburger menu icon -->
      <i class="fa fa-user-shield" aria-hidden="true"></i>
    </button>
    <div class="dropdown-menu dropdown-menu-end" aria-labelledby="adminHamburgerMenu">
      <a *ngIf="context.hasBeenDeclared() && appContext.isAdmin()" (click)="retrySendToWpcs()"
         id="retrySendToWpcs" class="dropdown-item" tabindex="-1" role="button">Retry wpcs-sync
      </a>
      <a *ngIf="appContext.isAdmin()" (click)="tryRefreshRefData()"
         class="dropdown-item" tabindex="-1" role="button">Refresh reference data
      </a>
      <a *ngIf="appContext.isAdmin()" (click)="tryRefreshVisitSummary()"
         class="dropdown-item" tabindex="-1" role="button">Refresh visit summary
      </a>
      <!-- Reject declaration -->
      <a *ngIf="'DECLARED' === context.declarationStatusOf(VISIT)" (click)="openConfirmationModal(rejectModal)"
        class="dropdown-item text-danger" tabindex="-1" role="button">
        Reject
      </a>
      <!-- Accept declaration -->
      <a *ngIf="'DECLARED' === context.declarationStatusOf(VISIT) || 'REJECTED' === context.declarationStatusOf(VISIT)"
        (click)="acceptVisit()" class="dropdown-item text-success" tabindex="-1" role="button">
        Accept
      </a>
      <!-- Messages -->
      <h6 class="dropdown-header" style="font-size: 18px;">Messages</h6>
      <a href="#" (click)="$event.preventDefault(); openMessages('eta2pa')" class="dropdown-item">
        VISIT to Port Authority
      </a>
      <a href="#" (click)="$event.preventDefault(); openMessages('noa2sw')" class="dropdown-item">
        NOA to Singlewindow
      </a>
      <a href="#" (click)="$event.preventDefault(); openMessages('nod2sw')" class="dropdown-item">
        NOD to Singlewindow
      </a>
      <a href="#" (click)="$event.preventDefault(); openMessages('ata2sw')" class="dropdown-item">
        ATA to Singlewindow
      </a>
      <a href="#" (click)="$event.preventDefault(); openMessages('atd2sw')" class="dropdown-item">
        ATD to Singlewindow
      </a>
      <a href="#" (click)="$event.preventDefault(); openMessages('exp2sw')" class="dropdown-item">
        EXP to Singlewindow
      </a>
      <a href="#" (click)="$event.preventDefault(); openMessages('coa2sw')" class="dropdown-item">
        COA to Singlewindow
      </a>
    </div>
  </div>
</ng-container>

<ng-template #rejectModal>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Reject reason</h5>
    </div>
    <div class="modal-body">
      <input id="rejectVisit-reason" type="text" class="form-control">
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Back</button>
      <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
        (click)="rejectVisit(appContext.getDocument().getElementById('rejectVisit-reason')['value'])">Send</button>
    </div>
  </div>
</ng-template>
