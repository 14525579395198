import {Sender, UserProfile} from "@portbase/bezoekschip-service-typescriptmodels";
import helpTheCustomer from "../src/help-the-customer-sender.json";
import _ from "lodash";

export const mockProfiles = <UserProfile[]>[
  realAdmin(),
  admin(),
  crossOceanKt(),
  _.isEmpty(helpTheCustomer) ? null : mockFromSender(helpTheCustomer),
  portAuthority(),
  visitDeclarant(),
  terminalPlanner(),
  visitDeclarantBackOffice(),
  visitDeclarantBackOfficeRmmbr(),
  nextVisitDeclarant(),
  portVisitWasteCollector(),
  bunkerOperator(),
  bunkerSkipper(),
  cargoDeclarant(),
  cargoDeclarantEditor(),
  cargoDeclarantViewer(),
  cargoDeclarantEditorNoDischargeList(),
  cargoDeclarantOnly(),
  customs(),
  transitEditor(),
  transitViewer(),
  cargoAndTransitEditor(),
  commercialReleaseDeclarant(),
  commercialReleaseEditor(),
  commercialReleaseViewer(),
  terminal(),
].filter(u=> u);


function realAdmin() {
  return <UserProfile>{
    userName: "admin@portbase.com",
    roles: ["VisitPortProcessAdministrator", "SystemUsers"],
    customsResponsibleForOrganisations: [],
    organisation: {
      iamConnectedId: "20ae4078-712e-4286-abd5-9d8724f555e9",
      fullName: "Admin",
      portAuthorityId: "0813",
      shortName: "PORTINFOLINK",
      emailAddress: "admin@portbase.com",
      address: "Blaak 16",
      city: "Rotterdam",
      countryUnCode: "NL",
      countryName: "Netherlands",
      zipCode: "3195 ND",
      phoneNumber: "+31-10-2522222",
      faxNumber: null,
      contact: "System Administrator",
      customsEORINumber: "NL811203992",
      ean: "190000111"
    }
  }
}

function crossOceanKt() {
  return <UserProfile>{
    userName: "admin@portbase.com",
    roles: ["CargoDeclarant", "ImportCargoDeclarant", "VisitDeclarant", "eri.em.cargo.declarant.cargohandlingagent.cargo"],
    customsResponsibleForOrganisations: [],
    organisation: {
      iamConnectedId: "19431da8-4118-11ea-a528-e77ac7ae1b2f",
      fullName: "Admin (CrossOcean KT)",
      portAuthorityId: "0813",
      shortName: "CROSSOCEAN",
      emailAddress: "admin@portbase.com",
      address: "Blaak 16",
      city: "Rotterdam",
      countryUnCode: "NL",
      countryName: "Netherlands",
      zipCode: "3195 ND",
      phoneNumber: "+31-10-2522222",
      faxNumber: null,
      contact: "Admin (CrossOcean KT)",
      customsEORINumber: "NL811203992",
      ean: "190000111"
    }
  }
}

function admin() {
  return <UserProfile>{
    userName: "pi.administrator@AGENT16",
    roles: ["VisitPortProcessAdministrator"],
    customsResponsibleForOrganisations: [],
    organisation: {
      iamConnectedId: "bae6b334-152f-472b-a953-9622179f641f",
      fullName: "AGENT16",
      portAuthorityId: "0813",
      shortName: "AGENT16",
      emailAddress: "vl-test1@AGENT16.com",
      address: "Blaak 16",
      city: "Rotterdam",
      countryUnCode: "NL",
      countryName: "Netherlands",
      zipCode: "3195 ND",
      phoneNumber: "+31-10-2522222",
      faxNumber: null,
      contact: "Old System Administrator",
      customsEORINumber: "NL811203992",
      ean: "190000111"
    }
  }
}

function portAuthority() {
  return <UserProfile>{
    userName: "sd@HBHHAR",
    customsResponsibleForOrganisations: [],
    roles: [
      "PcsUser",
      "PortAuthority",
      "VisitDeclarant",
      "sve.dangerousgoods.viewer.portauthority"
    ],
    givenAuthorisations: {},
    organisation: {
      iamConnectedId: "5a4e9752-42b6-4f97-af4e-2a07a1cd6dfd",
      fullName: "Havenbedrijf Harlingen",
      shortName: "HBHHAR",
      portAuthorityId: null,
      emailAddress: "servicedesk@portbase.com",
      address: "Waddenpromenade 9-3",
      city: "Harlingen",
      countryUnCode: "NL",
      countryName: "Netherlands",
      zipCode: "8861 NT",
      phoneNumber: "0517-723333",
      faxNumber: null,
      contact: "HB HARLINGEN",
      customsEORINumber: "NL001608836",
      ean: null,
      chamberOfCommerceNumber: "68920644",
      scacCode: null,
      ensEnabled: false,
      cargoImportEnabled: true,
      cargoImportEnabledByDefault: true
    }
  }
}

function customs() {
  return <UserProfile>{
    userName: "pi.administrator@DOUAAPELD",
    roles: [],
    customsResponsibleForOrganisations: [],
    organisation: {
      iamConnectedId: "79cf6577-0141-4207-80a3-d8529c22d2d5",
      fullName: "DOUAAPELD",
      portAuthorityId: "DOUAAPELD",
      shortName: "DOUAAPELD",
      emailAddress: "vl-test1@DOUAAPELD.com",
      address: "Blaak 16",
      city: "Rotterdam",
      countryUnCode: "NL",
      countryName: "Netherlands",
      zipCode: "3195 ND",
      phoneNumber: "+31-10-2522222",
      faxNumber: null,
      contact: "Customs"
    }
  }
}

function visitDeclarant() {
  return {
    organisation: {
      iamConnectedId: "69e70493-4440-45af-bb15-4b093ce157c1",
      fullName: "Ship Operator 1",
      portAuthorityId: "SO1",
      shortName: "SO1",
      emailAddress: "j.johnson@portbase.com",
      address: "Some Street 12",
      city: "New York",
      countryUnCode: "NL",
      countryName: "Nederland",
      zipCode: "2023AA",
      phoneNumber: "-",
      faxNumber: undefined,
      contact: "Jane Johnson (VisitDeclarant SO1)",
      customsEORINumber: "custardpie",
      ean: "ean"
    },
    organisationShortName: "SO1",
    userName: "JJ@SO1",
    customsResponsibleForOrganisations: ["BPA"],
    roles: ["VisitDeclarant", "PaxDeclarant", "ShipSuppliesDeclarant", "DangerousGoodsDeclarantCargoHandlingAgent",
      "DangerousGoodsReporterShipOperator", "ImportCargoDeclarant", "ImportCargoDischargeListSender",
      "eri.em.cargo.declarant.cargohandlingagent.cargo", "TranshipmentDeclarant", "CommercialReleaseEditor"],
    customers: [
      {
        ownerPortbaseId: "SO1",
        ownerFullName: "Ship Operator 1",
        portbaseId: "SO1CUST01",
        verified: false,
        references: {
          one: {reference: "one", status: 'VERIFIED', verified: true},
          two: {reference: "two", status: 'NEW', verified: false}
        },
        customerData: {
          fullName: "SO1 Customer 1",
          address: "Test adres 2",
          zipCode: "3036 AA",
          city: "Rotterdam",
          chamberOfCommerceNumber: "kvk1"
        }
      }, {
        ownerPortbaseId: "SO1",
        ownerFullName: "Ship Operator 1",
        portbaseId: "SO1CUST02",
        verified: true,
        references: {
          xxx: {"reference": "xxx", status: 'VERIFIED', "verified": true},
          zzz: {"reference": "zzz", status: 'REJECTED', "verified": true, rejectReason: 'niet dan?'}
        },
        customerData: {
          fullName: "SO1 Customer 2",
          address: "Test adres 1",
          zipCode: "3036 AA",
          city: "Rotterdam",
          chamberOfCommerceNumber: "kvk2"
        }
      }
    ]

  }
}

function visitDeclarantBackOffice() {
  return {
    organisation: {
      iamConnectedId: "b4c47345-8d29-4418-a7db-318afe751235",
      fullName: "Back Office 1",
      portAuthorityId: "BO1",
      shortName: "BO1",
      emailAddress: "b.blake@portbase.com",
      address: "Some Street 12",
      city: "New York",
      countryUnCode: "NL",
      countryName: "Nederland",
      zipCode: "2023AA",
      phoneNumber: "-",
      faxNumber: undefined,
      contact: "Boris Blake (Backoffice BO1)",
      customsEORINumber: "custardpie",
      ean: "ean"
    },
    organisationShortName: "BO1",
    userName: "BB@BO1",
    customsResponsibleForOrganisations: ["BPA"],
    roles: ["VisitDeclarant", "PaxDeclarant", "ShipSuppliesDeclarant",
      "DangerousGoodsDeclarantCargoHandlingAgent", "DangerousGoodsReporterShipOperator"]
  }
}

function visitDeclarantBackOfficeRmmbr() {
  return {
    organisation: {
      iamConnectedId: "7d632a8e-8ee7-43aa-86c8-99dc8f0e3820",
      fullName: "Back Office Rmmbr",
      portAuthorityId: "RMMBR",
      shortName: "RMMBR",
      emailAddress: "s.sanders@rmmbr.com",
      address: "Some Street 12",
      city: "New York",
      countryUnCode: "NL",
      countryName: "Nederland",
      zipCode: "2023AA",
      phoneNumber: "-",
      faxNumber: undefined,
      contact: "Sarah Sanders (Other VisitDeclarant RMMBR)",
      customsEORINumber: "custardpie",
      ean: "ean"
    },
    organisationShortName: "RMMBR",
    userName: "SS@RMMBR",
    customsResponsibleForOrganisations: ["BPA"],
    roles: ["VisitDeclarant", "PaxDeclarant", "ShipSuppliesDeclarant",
      "DangerousGoodsDeclarantCargoHandlingAgent", "DangerousGoodsReporterShipOperator"]
  }
}

function nextVisitDeclarant() {
  return {
    organisation: {
      iamConnectedId: "b927336a-a02b-426b-b2c9-ac75d7cec347",
      fullName: "Schip Operator 2",
      portAuthorityId: "SO2",
      shortName: "SO2",
      emailAddress: "f.covey@portbase.com",
      address: "Some Street 12",
      city: "New York",
      countryUnCode: "NL",
      countryName: "Nederland",
      zipCode: "2023AA",
      phoneNumber: "-",
      faxNumber: undefined,
      contact: "Frank Covey (Next VisitDeclarant SO2)",
      customsEORINumber: "custardpie",
      ean: "ean"
    },
    organisationShortName: "SO2",
    userName: "FC@SO2",
    customsResponsibleForOrganisations: ["BPA"],
    roles: ["VisitDeclarant", "PaxDeclarant", "ShipSuppliesDeclarant"]
  }
}

function cargoDeclarant() {
  return {
    organisation: {
      iamConnectedId: "4882075e-89f4-4f4c-b101-470ad2a06709",
      fullName: "Zilvervis",
      portAuthorityId: "SO2SSA",
      shortName: "SILVERSTAR",
      emailAddress: "longjohn.silver@portbase.com",
      address: "Some Street 12",
      city: "New York",
      countryUnCode: "NL",
      countryName: "Nederland",
      zipCode: "2023AA",
      phoneNumber: "-",
      faxNumber: undefined,
      contact: "Long John Silver (Other VisitDeclarant SILVERSTAR )",
      customsEORINumber: "custardpie",
      ean: "ean"
    },
    organisationShortName: "SILVERSTAR",
    userName: "LJS@SILVERSTAR",
    customsResponsibleForOrganisations: ["BPA"],
    roles: ["VisitDeclarant", "DangerousGoodsDeclarantCargoHandlingAgent", "transhipmentDeclarant"]
  }
}

function cargoDeclarantViewer() {
  return {
    organisation: {
      iamConnectedId: "40425430-0772-47cb-a592-2115c90b72df",
      fullName: "Cargo agent 1",
      portAuthorityId: "CHA1",
      shortName: "CHA1",
      emailAddress: "longjohn.silver@portbase.com",
      address: "Some Street 12",
      city: "Rotterdam",
      countryUnCode: "NL",
      countryName: "Nederland",
      zipCode: "2023AA",
      phoneNumber: "-",
      faxNumber: undefined,
      contact: "CHA1 (Cargo declarant viewer)",
      customsEORINumber: "NL10354684",
      ean: "ean"
    },
    organisationShortName: "CHA1",
    userName: "viewer@CHA1",
    customsResponsibleForOrganisations: ["CHA1"],
    roles: ["DangerousGoodsDeclarantCargoHandlingAgent", "ImportCargoDeclarant", "ImportCargoDischargeListSender",
      "eri.em.cargo.declarant.cargohandlingagent.cargo.view"]
  }
}

function cargoDeclarantEditor() {
  return {
    organisation: {
      iamConnectedId: "40425430-0772-47cb-a592-2115c90b72df",
      fullName: "Cargo agent 1",
      portAuthorityId: "CHA1",
      shortName: "CHA1",
      emailAddress: "longjohn.silver@portbase.com",
      address: "Some Street 12",
      city: "New York",
      countryUnCode: "NL",
      countryName: "Nederland",
      zipCode: "2023AA",
      phoneNumber: "-",
      faxNumber: undefined,
      contact: "CHA1 (Cargo agent editor)",
      customsEORINumber: "custardpie",
      ean: "ean"
    },
    organisationShortName: "CHA1",
    userName: "editor@CHA1",
    customsResponsibleForOrganisations: ["CHA1"],
    roles: ["DangerousGoodsDeclarantCargoHandlingAgent", "ImportCargoDeclarant", "ImportCargoDischargeListSender",
      "eri.em.cargo.declarant.cargohandlingagent.cargo", "transhipmentDeclarant"]
  }
}

function transitEditor() {
  return {
    organisation: {
      iamConnectedId: "40425430-0772-47cb-a592-2115c90b72df",
      fullName: "Cargo agent 1",
      portAuthorityId: "CHA1",
      shortName: "CHA1",
      emailAddress: "longjohn.silver@portbase.com",
      address: "Some Street 12",
      city: "New York",
      countryUnCode: "NL",
      countryName: "Nederland",
      zipCode: "2023AA",
      phoneNumber: "-",
      faxNumber: undefined,
      contact: "CHA1 (Transit editor)",
      customsEORINumber: "NL32412333",
      ean: "ean"
    },
    organisationShortName: "CHA1",
    userName: "editor@CHA1",
    customsResponsibleForOrganisations: ["CHA1"],
    roles: ["TransitDeclarant", "eri.ncts.transitdeclaration.declarant.cargohandlingagent.overview.edit"]
  }
}

function transitViewer() {
  return {
    organisation: {
      iamConnectedId: "40425430-0772-47cb-a592-2115c90b72df",
      fullName: "Cargo agent 1",
      portAuthorityId: "CHA1",
      shortName: "CHA1",
      emailAddress: "viewer.silver@portbase.com",
      address: "Some Street 12",
      city: "New York",
      countryUnCode: "NL",
      countryName: "Nederland",
      zipCode: "2023AA",
      phoneNumber: "-",
      faxNumber: undefined,
      contact: "CHA1 (Transit viewer)",
      customsEORINumber: "NL32412333",
      ean: "ean"
    },
    organisationShortName: "CHA1",
    userName: "viewer@CHA1",
    customsResponsibleForOrganisations: ["CHA1"],
    roles: ["TransitDeclarant", "eri.ncts.transitdeclaration.declarant.cargohandlingagent.overview.view"]
  }
}

function transitGuaranteeEditor() {
  return {
    organisation: {
      iamConnectedId: "40425430-0772-47cb-a592-2115c90b72df",
      fullName: "Cargo agent 1",
      portAuthorityId: "CHA1",
      shortName: "CHA1",
      emailAddress: "longjohn.silver@portbase.com",
      address: "Some Street 12",
      city: "New York",
      countryUnCode: "NL",
      countryName: "Nederland",
      zipCode: "2023AA",
      phoneNumber: "-",
      faxNumber: undefined,
      contact: "CHA1 (Transit guarantee editor)",
      customsEORINumber: "custardpie",
      ean: "ean"
    },
    organisationShortName: "CHA1",
    userName: "editor@CHA1",
    customsResponsibleForOrganisations: ["CHA1"],
    roles: ["TransitDeclarant", "eri.npcs.external.vesselnotification2.transitguaranteedeclarant"]
  }
}


function cargoAndTransitEditor() {
  return {
    organisation: {
      iamConnectedId: "40425430-0772-47cb-a592-2115c90b72df",
      fullName: "Cargo agent 1",
      portAuthorityId: "CHA1",
      shortName: "CHA1",
      emailAddress: "longjohn.silver@portbase.com",
      address: "Some Street 12",
      city: "New York",
      countryUnCode: "NL",
      countryName: "Nederland",
      zipCode: "2023AA",
      phoneNumber: "-",
      faxNumber: undefined,
      contact: "CHA1 (Cargo and transit editor)",
      customsEORINumber: "custardpie",
      ean: "ean"
    },
    organisationShortName: "CHA1",
    userName: "editor@CHA1",
    customsResponsibleForOrganisations: ["CHA1"],
    roles: ["TransitDeclarant", "DangerousGoodsDeclarantCargoHandlingAgent", "ImportCargoDeclarant",
      "ImportCargoDischargeListSender", "eri.em.cargo.declarant.cargohandlingagent.cargo"]
  }
}

function cargoDeclarantEditorNoDischargeList() {
  return {
    organisation: {
      iamConnectedId: "40425430-0772-47cb-a592-2115c90b72df",
      fullName: "Cargo agent 1",
      portAuthorityId: "CHA1",
      shortName: "CHA1",
      emailAddress: "longjohn.silver@portbase.com",
      address: "Some Street 12",
      city: "New York",
      countryUnCode: "NL",
      countryName: "Nederland",
      zipCode: "2023AA",
      phoneNumber: "-",
      faxNumber: undefined,
      contact: "CHA1 (Cargo agent editor no dischargelist)",
      customsEORINumber: "custardpie",
      ean: "ean"
    },
    organisationShortName: "CHA1",
    userName: "nodischargelist@CHA1",
    customsResponsibleForOrganisations: ["CHA1"],
    roles: ["DangerousGoodsDeclarantCargoHandlingAgent", "ImportCargoDeclarant",
      "eri.em.cargo.declarant.cargohandlingagent.cargo"]
  }
}

function portVisitWasteCollector() {
  return {
    organisation: {
      iamConnectedId: "e32b61d8-0d1e-4aa6-ac30-9f36a2168d44",
      fullName: "Dutch Cleaning Service",
      portAuthorityId: "0000",
      shortName: "ATM1",
      emailAddress: "mr.proper@portbase.com",
      address: "Some Street 12",
      city: "New York",
      countryUnCode: "NL",
      countryName: "Nederland",
      zipCode: "2023AA",
      phoneNumber: "-",
      faxNumber: undefined,
      contact: "Mr Proper (Collector at ATM)",
      customsEORINumber: "orie",
      ean: "eanie"
    },
    organisationShortName: "ATM1",
    userName: "ron@ATM",
    customsResponsibleForOrganisations: [],
    roles: ["PortVisitWasteCollector"]
  }
}

function bunkerOperator() {
  return {
    organisation: {
      iamConnectedId: "dfc2b6b7-aa1d-4f6d-b609-70d0d595ebd9",
      fullName: "Oil Shipping (Bunkering) B.V.",
      portAuthorityId: "0000",
      shortName: "OIL_SHIPPING",
      emailAddress: "oilman@portbase.com",
      address: "Some Street 12",
      city: "New York",
      countryUnCode: "NL",
      countryName: "Nederland",
      zipCode: "2023AA",
      phoneNumber: "-",
      faxNumber: undefined,
      contact: "The Oilman (Bunker Operator)",
      customsEORINumber: "orie",
      ean: "eanie"
    },
    organisationShortName: "OIL_SHIPPING",
    userName: "oilman@OIL_SHIPPING",
    customsResponsibleForOrganisations: [],
    roles: ["BunkerOperator", "eri.npcs.external.vesselnotification2.bunkeroperator"]
  }
}

function bunkerSkipper() {
  return {
    organisation: {
      iamConnectedId: "dfc2b6b7-aa1d-4f6d-b609-70d0d595ebd9",
      fullName: "Oil Shipping (Bunkering) B.V.",
      portAuthorityId: "0000",
      shortName: "OIL_SHIPPING",
      emailAddress: "oilskipper@portbase.com",
      address: "Some Street 12",
      city: "New York",
      countryUnCode: "NL",
      countryName: "Nederland",
      zipCode: "2023AA",
      phoneNumber: "-",
      faxNumber: undefined,
      contact: "The Oilman (Bunker Skipper)",
      customsEORINumber: "orie",
      ean: "eanie"
    },
    organisationShortName: "OIL_SHIPPING",
    userName: "oilskipper@OIL_SHIPPING",
    customsResponsibleForOrganisations: [],
    roles: ["BunkerOperator", "eri.npcs.external.vesselnotification2.bunkerskipper"]
  }
}

function cargoDeclarantOnly() {
  return {
    organisation: {
      iamConnectedId: "69e70493-4440-45af-bb15-4b093ce157c1",
      fullName: "Ship Operator 1",
      portAuthorityId: "SO1",
      shortName: "SO1",
      emailAddress: "j.johnson@portbase.com",
      address: "Some Street 12",
      city: "New York",
      countryUnCode: "NL",
      countryName: "Nederland",
      zipCode: "2023AA",
      phoneNumber: "-",
      faxNumber: undefined,
      contact: "Dung Nguyen (Declarant, but no VisitDeclaration role for SO1)",
      customsEORINumber: "custardpie",
      ean: "ean"
    },
    organisationShortName: "SO1",
    userName: "cargoOnly@SO1",
    customsResponsibleForOrganisations: ["BPA"],
    roles: ["DangerousGoodsDeclarantCargoHandlingAgent",
      "ImportCargoDeclarant",
      "eri.em.cargo.declarant.cargohandlingagent.cargo",
      "eri.em.cargo.declarant.cargohandlingagent.cargo.view"]
  }
}

function commercialReleaseDeclarant() {
  return {
    organisation: {
      iamConnectedId: "40425430-0772-47cb-a592-2115c90b72df",
      fullName: "Cargo agent 1",
      portAuthorityId: "CHA1",
      shortName: "CHA1",
      emailAddress: "longjohn.silver@portbase.com",
      address: "Some Street 12",
      city: "New York",
      countryUnCode: "NL",
      countryName: "Nederland",
      zipCode: "2023AA",
      phoneNumber: "-",
      faxNumber: undefined,
      contact: "CHA1 (Commercial release declarant)",
      customsEORINumber: "NL32412333",
      ean: "ean"
    },
    organisationShortName: "CHA1",
    userName: "commercialReleaseDeclarant@CHA1",
    customsResponsibleForOrganisations: ["CHA1"],
    roles: ["CommercialReleaseDeclarant"]
  }
}

function commercialReleaseEditor() {
  return {
    organisation: {
      iamConnectedId: "40425430-0772-47cb-a592-2115c90b72df",
      fullName: "Cargo agent 1",
      portAuthorityId: "CHA1",
      shortName: "CHA1",
      emailAddress: "longjohn.silver@portbase.com",
      address: "Some Street 12",
      city: "New York",
      countryUnCode: "NL",
      countryName: "Nederland",
      zipCode: "2023AA",
      phoneNumber: "-",
      faxNumber: undefined,
      contact: "CHA1 (Commercial release editor)",
      customsEORINumber: "NL32412333",
      ean: "ean"
    },
    organisationShortName: "CHA1",
    userName: "commercialReleaseEditor@CHA1",
    customsResponsibleForOrganisations: ["CHA1"],
    roles: ["CommercialReleaseEditor"]
  }
}

function commercialReleaseViewer() {
  return {
    organisation: {
      iamConnectedId: "40425430-0772-47cb-a592-2115c90b72df",
      fullName: "Cargo agent 1",
      portAuthorityId: "CHA1",
      shortName: "CHA1",
      emailAddress: "longjohn.silver@portbase.com",
      address: "Some Street 12",
      city: "New York",
      countryUnCode: "NL",
      countryName: "Nederland",
      zipCode: "2023AA",
      phoneNumber: "-",
      faxNumber: undefined,
      contact: "CHA1 (Commercial release viewer)",
      customsEORINumber: "NL32412333",
      ean: "ean"
    },
    organisationShortName: "CHA1",
    userName: "commercialReleaseViewer@CHA1",
    customsResponsibleForOrganisations: ["CHA1"],
    roles: ["CommercialReleaseViewer"]
  }
}

function terminal() {
  return {
    organisation: {
      iamConnectedId: "2162047e-056d-4d61-b54c-6d6520270ac4",
      fullName: "RWG",
      portAuthorityId: "RWG",
      shortName: "RWG",
      emailAddress: "longjohn.silver@portbase.com",
      address: "Some Street 12",
      city: "New York",
      countryUnCode: "NL",
      countryName: "Nederland",
      zipCode: "2023AA",
      phoneNumber: "-",
      faxNumber: undefined,
      contact: "CHA1 (Terminal)",
      customsEORINumber: "NL32412333",
      ean: "ean"
    },
    organisationShortName: "RWG",
    userName: "commercialReleaseEditor@RWG",
    customsResponsibleForOrganisations: ["RWG"],
    roles: ["HinterlandTerminal"]
  }
}

function terminalPlanner() {
  return {
    organisation: {
      iamConnectedId: "69e70493-4440-45af-bb15-4b093ce157c1",
      fullName: "Terminal planner 1",
      portAuthorityId: "SO1",
      shortName: "SO1",
      emailAddress: "j.johnson@portbase.com",
      address: "Some Street 12",
      city: "New York",
      countryUnCode: "NL",
      countryName: "Nederland",
      zipCode: "2023AA",
      phoneNumber: "-",
      faxNumber: undefined,
      contact: "Jane Johnson (Terminalplanner SO1)",
      customsEORINumber: "custardpie",
      ean: "ean"
    },
    organisationShortName: "SO1",
    userName: "TP@SO1",
    customsResponsibleForOrganisations: ["BPA"],
    roles: ["VisitDeclarant", "TerminalPlanner"]
  }
}

function mockFromSender(sender: Sender): UserProfile {
  return {
    userName: sender.userName,
    roles: sender.roles,
    organisation: {
      shortName: sender.organisationShortName,
      iamConnectedId: sender.iamConnectedId,
      ean: sender.ean,
      customsEORINumber: sender.eori,
      scacCode: sender.scac,
      fullName: sender.organisationFullName,
      contact: "Help the customer – " + sender.organisationFullName,
    },
    iamConnectedUserId: sender.iamConnectedUserId,
    givenAuthorisations: sender.givenAuthorisations,
  }
}
