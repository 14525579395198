import {Component, Input} from '@angular/core';
import {PortvisitUtils} from "../../../../refdata/portvisit-utils";
import {IE3Packaging} from "@portbase/bezoekschip-service-typescriptmodels";

@Component({
  selector: 'app-goods-packaging',
  templateUrl: './goods-packaging.component.html',
  styleUrls: ['./goods-packaging.component.scss']
})
export class GoodsPackagingComponent {
  refData = PortvisitUtils;
  @Input() packaging: IE3Packaging;
  @Input() editMode: boolean;
  @Input() label: string = "Package type";
}
