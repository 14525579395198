<div class="row">
  <div class="col-md-6">
    <app-form-group><label>Type</label><input class="form-control" [(ngModel)]="solid.type" disabled required></app-form-group>
    <app-form-group><label>Id</label><input class="form-control" [(ngModel)]="solid['id']" disabled required></app-form-group>
  </div>
  <div class="col-md-6">
    <app-form-group><label>Name</label><input class="form-control" [(ngModel)]="solid.name" required></app-form-group>
    <app-form-group><label>UN code</label><input class="form-control" [(ngModel)]="solid.unCode"></app-form-group>
    <app-form-group><label>Hazard class</label><input class="form-control" [(ngModel)]="solid.hazardClass"></app-form-group>
  </div>
</div>
