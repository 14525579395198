<!--table header-->
<div class="row g-0 bg-secondary text-light py-2">
  <div class="col required"><span style="padding-left: 2.5rem">Port or facility</span></div>
  <div class="col-1 required ps-2 d-none d-sm-block" style="min-width: 5rem">Level</div>
  <div class="col-2 ps-2 d-none d-xl-block" style="min-width: 10rem">Arrival</div>
  <div class="col-2 ps-2 d-none d-xl-block" style="min-width: 10rem">Departure</div>
  <div class="col-3 ps-2 d-none d-xl-block">
    <app-info>
      <span>Security measures</span>
      <span class="tooltipContent">Indicate which special or additional security measures (beyond the measures in the approved SSP) have been taken.</span>
    </app-info>
  </div>
  <div class="col-auto ps-2 d-none d-xl-block" style="min-width: 1.5rem"></div>
  <div class="col-auto ps-2 text-end d-none d-sm-block" style="min-width: 5rem">
  </div>
  <div class="col-auto ps-2 text-end d-xl-none" style="min-width: 5rem"></div>
</div>

<!--loop over all ports-->
<div dragula="previousPortsSecurity" [(dragulaModel)]="context.visit.visitDeclaration.previousPorts">
  <div *ngFor="let portVisit of context.visit.visitDeclaration.previousPorts; let portIdx = index;">
    <div *ngIf="portVisit" class="row g-0 my-2 validatable">
      <div class="col">
        <app-search required [ngModel]="portVisit.port" [searchFunction]="searchPort" [inputFormatter]="portInputFormatter"
                    (ngModelChange)="context.selectPreviousPort(portVisit, $event)"
                    [id]="'previousPort-port-' + portIdx" placeholder="Find port" dataKey="name">
          <span class="input-group-prepend input-group-text port-drag-handle grabbable bg-light border-0 port-drag-handle" style="width: 2.5rem"><span
            class="fa fa-map-marker-alt text-portbase-blue port-drag-handle"></span></span>
        </app-search>
      </div>
      <div class="col-1 ps-2 d-none d-sm-block" style="min-width: 5rem"></div>
      <div class="col-2 ps-2 d-none d-xl-block" style="min-width: 10rem">
        <app-date-field [(ngModel)]="portVisit.arrival" defaultTime="12:00"
                        [calendarStartDate]="portVisit.departure"
                        [dateOnly]="true"></app-date-field>
      </div>
      <div class="col-2 ps-2 d-none d-xl-block" style="min-width: 10rem">
        <app-date-field [(ngModel)]="portVisit.departure" defaultTime="12:00"
                        [calendarStartDate]="portVisit.arrival"
                        [dateOnly]="true"></app-date-field>
      </div>
      <div class="col-3 ps-2 d-none d-xl-block" style="min-width: 1.5rem"></div>
      <div class="col-auto ps-2 text-end d-none d-xl-block">
        <button class="btn px-0" type="button" title="Delete port" (click)="context.deletePreviousPort(portVisit)"
                [id]="'previousPort-delete-' + portIdx" tabindex="-1"><span class="fa fa-trash text-secondary"></span></button>

      </div>
      <div class="col-auto ps-2 text-end d-none d-sm-block" style="min-width: 5rem">
        <button (click)="addPortFacility(portVisit)" id="addPortFacility" type="button" class="btn btn-info"
                style="width: 3rem">+</button>
      </div>
      <div class="col-auto ps-2 text-end d-xl-none" style="min-width: 5rem">
        <label class="btn btn-info" onclick="" [id]="'port-details-' + portIdx"
                [attr.data-bs-target]="'#details-collapse-' + portIdx"
                data-bs-toggle="collapse">Details</label>
      </div>

      <!--Port details (only for small screens)-->
      <div class="col-12 my-2 collapse" [id]="'details-collapse-' + portIdx">
        <div class="card card-body bg-light">
          <div class="card-title row">
            <h5 class="col">{{portInputFormatter(portVisit.port)}}</h5>
            <button (click)="context.deletePreviousPort(portVisit)" [id]="'delete-port-' + portIdx"
                    class="btn mt-n2 col-auto" type="button" title="Delete port" tabindex="-1"><span class="fa fa-trash text-secondary"></span></button>
          </div>
          <div class="row">
            <div class="col-md-6">
              <app-form-group>
                <label [attr.for]="'details-arrival-port-' + portIdx">Arrival</label>
                <app-date-field [(ngModel)]="portVisit.arrival" [dateOnly]="true"
                               [calendarStartDate]="portVisit.departure" [noDefaultTime]="true"
                               [id]="'arrival-port-' + portIdx"></app-date-field>
              </app-form-group>
            </div>
            <div class="col-md-6">
              <app-form-group>
                <label [attr.for]="'details-departure-port-' + portIdx">Departure</label>
                <app-date-field [(ngModel)]="portVisit.departure" [dateOnly]="true"
                               [calendarStartDate]="portVisit.arrival" [noDefaultTime]="true"
                               [id]="'departure-port-' + portIdx"></app-date-field>
              </app-form-group>
            </div>
          </div>
        </div>
      </div>
    </div>
    <span *ngIf="portNotChronological(portVisit)" class="ng-invalid invalid-feedback mb-2" style="margin-left: 2.5rem">Departure should be after arrival</span>
    <span *ngIf="twoPortsNotChronological(portIdx)" class="ng-invalid invalid-feedback mb-2" style="margin-left: 2.5rem">This port should be in chronological order with the port above</span>
    <span *ngIf="someFacilitiesAreOutsidePortVisit(portVisit)" class="ng-invalid invalid-feedback mb-2" style="margin-left: 2.5rem">All facility dates should be between the port arrival and departure</span>

    <!--loop over port facilities-->
    <div dragula="portFacilities" [(dragulaModel)]="portVisit.portFacilityVisits">
      <div *ngFor="let facilityVisit of portVisit.portFacilityVisits; let facilityIdx = index;">
        <div class="row g-0 my-2 validatable">
          <div class="col">
            <app-select required addSelectedIfNotExists [(ngModel)]="facilityVisit.portFacility"
                        [refreshProvider]="getFacilityProvider" [refreshWatch]="portVisit.port"
                        [formatter]="facilityFormatter" dataKey="code"
                        [id]="'port-' + portIdx + '-facility-' + facilityIdx" class="input-group">
                <span class="input-group-prepend input-group-text facility-drag-handle grabbable bg-light border-0 facility-drag-handle" style="width: 2.5rem">#{{facilityNumber(facilityVisit)}}</span>
            </app-select>
          </div>
          <div class="col-1 ps-2 d-none d-sm-block" style="min-width: 5rem">
            <app-select required [(ngModel)]="facilityVisit.securityLevel"
                        [options]='["SL1", "SL2", "SL3"]'
                        [id]="'securityLevel-port-' + portIdx + '-facility-' + facilityIdx"></app-select>
          </div>
          <div class="col-2 ps-2 d-none d-xl-block" style="min-width: 10rem">
            <app-date-field [(ngModel)]="facilityVisit.arrivalDate"
                           [calendarStartDate]="facilityVisit.departureDate" [noDefaultTime]="true"
                           [dateOnly]="true" [id]="'arrival-port-' + portIdx + '-facility-' + facilityIdx"></app-date-field>
          </div>
          <div class="col-2 ps-2 d-none d-xl-block" style="min-width: 10rem">
            <app-date-field [(ngModel)]="facilityVisit.departureDate"
                           [calendarStartDate]="facilityVisit.arrivalDate" [noDefaultTime]="true"
                           [dateOnly]="true" [id]="'departure-port-' + portIdx + '-facility-' + facilityIdx"></app-date-field>
          </div>
          <div class="col-3 ps-2 d-none d-xl-block">
            <input type="text" [(ngModel)]="facilityVisit.additionalSecurityMeasures"
                   [id]="'measures-port-' + portIdx + '-facility-' + facilityIdx" maxlength="512" class="form-control">
          </div>
          <div class="col-auto ps-2 text-end d-none d-xl-block" style="min-width: 1.5rem">
            <button class="btn px-0" type="button" title="Delete facility" (click)="deletePortFacility(portIdx, facilityIdx)"
                    [id]="'delete-port-' + portIdx + '-facility-' + facilityIdx" tabindex="-1"><span class="fa fa-trash text-secondary"></span></button>
          </div>
          <div class="col-auto ps-2 text-end d-none d-sm-block" style="min-width: 5rem">
          </div>
          <div class="col-auto ps-2 text-end d-xl-none" style="min-width: 5rem">
            <label class="btn btn-info" onclick="" [id]="'details-port-' + portIdx + '-facility-' + facilityIdx"
                    [ngClass]="{'btn-invalid' : facilityVisit.ngInvalid}"
                    [attr.data-bs-target]="'#details-collapse-' + portIdx + '-facility-' + facilityIdx"
                    data-bs-toggle="collapse">Details</label>
          </div>

          <!--Port facility details (only for small screens)-->
          <div class="col-12 my-2 collapse" [id]="'details-collapse-' + portIdx + '-facility-' + facilityIdx" [appValidator]="facilityVisit">
            <div class="card card-body bg-light">
              <div class="card-title row">
                <h5 class="col">{{facilityFormatter(facilityVisit.portFacility)}}</h5>
                <button (click)="deletePortFacility(portIdx, facilityIdx)" [id]="'delete-port-' + portIdx + '-facility-' + facilityIdx"
                        class="btn mt-n2 col-auto" type="button" title="Delete facility" tabindex="-1"><span class="fa fa-trash text-secondary"></span></button>
              </div>
              <div class="row">
                <div class="col-md-6 d-sm-none">
                  <app-form-group>
                    <label [attr.for]="'details-level-port-' + portIdx + '-facility-' + facilityIdx">Security Level</label>
                    <app-select required [(ngModel)]="facilityVisit.securityLevel"
                                [options]='["SL1", "SL2", "SL3"]'
                                [id]="'level-port-' + portIdx + '-facility-' + facilityIdx"></app-select>
                  </app-form-group>
                </div>
                <div class="col-md-6">
                  <app-form-group>
                    <label [attr.for]="'details-arrival-port-' + portIdx + '-facility-' + facilityIdx">Arrival</label>
                    <app-date-field [(ngModel)]="facilityVisit.arrivalDate" [dateOnly]="true"
                                   [calendarStartDate]="facilityVisit.departureDate" [noDefaultTime]="true"
                                   [id]="'arrival-port-' + portIdx + '-facility-' + facilityIdx"></app-date-field>
                  </app-form-group>
                </div>
                <div class="col-md-6">
                  <app-form-group>
                    <label [attr.for]="'details-departure-port-' + portIdx + '-facility-' + facilityIdx">Departure</label>
                    <app-date-field [(ngModel)]="facilityVisit.departureDate" [dateOnly]="true"
                                   [calendarStartDate]="facilityVisit.arrivalDate" [noDefaultTime]="true"
                                   [id]="'departure-port-' + portIdx + '-facility-' + facilityIdx"></app-date-field>
                  </app-form-group>
                </div>
                <div class="col-md-6">
                  <app-form-group>
                    <app-info class="formGroupLabel">
                      <label>Security measures</label>
                      <span class="tooltipContent">Indicate which special or additional security measures (beyond the measures in the approved SSP) have been taken.</span>
                    </app-info>
                    <textarea [(ngModel)]="facilityVisit.additionalSecurityMeasures"
                              [id]="'measures-port-' + portIdx + '-facility-' + facilityIdx" maxlength="512" class="form-control"></textarea>
                  </app-form-group>
                </div>
              </div>
            </div>
          </div>
        </div>
        <span *ngIf="facilityNotChronological(facilityVisit)" class="ng-invalid invalid-feedback mb-2" style="margin-left: 2.5rem">Departure should be after arrival</span>
        <span *ngIf="twoFacilitiesNotChronological(portVisit, facilityIdx)" class="ng-invalid invalid-feedback mb-2" style="margin-left: 2.5rem">This facility should be in chronological order with the facility above</span>
      </div>
    </div>
  </div>
</div>

<!--add port-->
<div class="row justify-content-between my-3">
  <div class="col-auto"></div>
  <div class="col-auto">
    <button (click)="addPreviousPort()" id="addPreviousPort" type="button" class="btn btn-info"
            style="width: 8rem">Add port</button>
  </div>
</div>
