import {Component, Input} from '@angular/core';
import {OilData} from "@portbase/bezoekschip-service-typescriptmodels";

@Component({
  selector: 'app-edit-oil',
  templateUrl: './edit-oil.component.html',
  styleUrls: ['./edit-oil.component.css']
})
export class EditOilComponent {
  @Input() oil: OilData;
}
