<div class="row">

  <div class="col-md-12">
    <app-action-nav-bar></app-action-nav-bar>
  </div>

  <!-- Border Bottom -->
  <div class="col-md-12 mt-1">
    <div class="border-bottom">
    </div>
  </div>

</div>

<div class="row always-enabled">
  <!--Select financial declarant-->
  <div class="declaration-block col-md-12">
    <h2>Financial declarant</h2>
    <div class="row g-0 bg-secondary text-light py-2">
      <div class="col required"><span class="ps-2">Name</span></div>
      <div class="col-3 d-none d-xl-block">Address</div>
      <div class="col-3 d-none d-lg-block">City</div>
      <div class="col-2 d-none d-md-block">Country</div>
    </div>
    <div class="row g-0 my-2 mx-0">
      <div class="col pe-3">
        <app-search [(ngModel)]="financialDeclarant" [searchFunction]="searchFinancialDeclarant"
                    id="financial-declarant-name" placeholder="Find organisation" dataKey="fullName">
        </app-search>
      </div>
      <div class="col-3 form-control border-0 d-none d-xl-block">{{financialDeclarant?.address}}</div>
      <div class="col-3 form-control border-0 d-none d-lg-block">{{financialDeclarant?.city}}</div>
      <div class="col-2 form-control border-0 d-none d-md-block">{{financialDeclarant?.countryName}}</div>
    </div>
    <div class="row my-3">
      <div class="col-auto">
        <button (click)="nominate()" id="transferVisit" type="button" class="btn btn-info"
                style="width: 8rem" [disabled]="disableNominate">Save</button>
      </div>
    </div>
  </div>


  <!--Choose which credit number to use if back office-->
  <div *ngIf="context.visit.owner.shortName !== context.visit.declarant.shortName"
       class="declaration-block col-md-12">
    <h2>Credit number harbour dues</h2>
    <app-yes-no [(ngModel)]="context.visit.creditNumberHolderSameAsDeclarant"
                [formatter]="creditNumberFormatter" id="creditNumber" [inline]="false"></app-yes-no>
    <div class="row my-3">
      <div class="col-auto">
        <button (click)="updateCreditNumber()" id="updateCreditNumber" type="button" class="btn btn-info"
                style="width: 8rem" [disabled]="disableUpdateCreditNumber">Save</button>
      </div>
    </div>
  </div>
</div>
