<div class="d-flex flex-column">
  <div class="row pt-3 pb-2 border-bottom">
    <h3 class="m-0 col-auto">Dashboard</h3>
    <div class="col row m-0 d-flex justify-content-end">
      <div *ngIf="zoomed" class="col-auto pe-0">
        <button (click)="resetZoom()" class="btn btn-outline-info" tabindex="-1">Reset zoom</button>
      </div>
      <div class="col-auto d-flex flex-row justify-content-end pe-0">
        <a type="button" class="btn btn-outline-info"
           onPopover placement="bottom-end" [popoverContent]="datePopover" popoverClass="pop-container popover-p-0">{{getCurrentTimeRange}}</a>
        <ng-template #datePopover>
          <div class="container p-0" onclick="event.stopPropagation()">

            <app-tab-panel [activeIndex]="timeFilter === 'absolute' ? 0 : 1">
              <app-tab-item [templateRef]="absoluteRef">
                <span class="tab-item">Absolute</span>
                <ng-template #absoluteRef>
                  <div class="p-4">
                    <app-date-range [ngModel]="dateTimeRange" (ngModelChange)="onDateSelection($event)"
                                    [includeTime]="true"></app-date-range>
                  </div>
                </ng-template>
              </app-tab-item>
              <app-tab-item [templateRef]="relativeRef">
                <span class="tab-item">Relative</span>
                <ng-template #relativeRef>
                  <div class="p-4">
                    <div class="row">
                      <div class="col-6">
                        <input class="form-control" type="number" [(ngModel)]="timeValue"
                               (ngModelChange)="onTimeValueChange($event)" max="60" min="1"/>
                      </div>
                      <div class="col-6">
                        <app-select [(ngModel)]="measurement" [options]="measurements" class="input-group"
                                    (ngModelChange)="measurementSelected($event)"></app-select>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </app-tab-item>
            </app-tab-panel>
          </div>
        </ng-template>
      </div>
      <div *ngIf="timeFilter === 'relative'" class="col-auto d-flex justify-content-end pe-0">
        <div class="btn-group">
          <button (click)="onRefreshButtonClick()" class="btn btn-outline-info" tabindex="-1"
                  [ngClass]="currentRefreshTimeout ? 'btn-glow-green' : ''">
            <i class="fa-solid" [ngClass]="currentRefreshTimeout ? 'fa-stop text-danger' : 'fa-arrows-rotate'"></i>
          </button>
          <button type="button" class="btn btn-outline-info dropdown-toggle dropdown-toggle-split"
                  [ngClass]="currentRefreshTimeout ? 'btn-glow-green' : ''"
                  data-bs-toggle="dropdown"></button>
          <div class="dropdown-menu dropdown-menu-end">
            <h6 class="dropdown-item-text">Auto refresh</h6>
            <hr class="dropdown-divider"/>
            <ng-container *ngFor="let time of autoRefreshTimes">
              <button class="dropdown-item" (click)="refreshOverTime(time)">
              <span class="fw-bold" *ngIf="currentAutoRefreshInSeconds === time else notSelectedRefreshTime">
                <i style="left: 5px; margin-top: 5px;" class="position-absolute text-success fa-solid fa-check"></i>
                {{time}} seconds
              </span>
                <ng-template #notSelectedRefreshTime>
                  {{time}} seconds
                </ng-template>
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="pt-2 justify-content-center">
    <app-shared-chart-legend [collapsible]="true" [charts]="charts" [chartData]="[totalAmountOfUpdatesPerConsumer]"
                             (hiddenLabelsOutput)="hiddenLabelsChanged($event)"></app-shared-chart-legend>
  </div>
  <div class="row">
    <div class="col-6 p-2">
      <div class="card">
        <div class="card-body">
          <div class="row align-items-center gx-0">
            <div class="col">
              <h6 class="text-uppercase text-muted mb-2">Outgoing messages</h6>
              <span class="h2 mb-0">{{totalMessagingMetrics["OUTBOUND"] || 0}}</span>
            </div>
            <div class="col-auto">
              <span class="text-muted mb-0"><i class="fa fa-paper-plane"></i></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6 p-2">
      <div class="card">
        <div class="card-body">
          <div class="row align-items-center gx-0">
            <div class="col">
              <h6 class="text-uppercase text-muted mb-2">Incoming messages</h6>
              <span class="h2 mb-0">{{totalMessagingMetrics["INBOUND"] || 0}}</span>
            </div>
            <div class="col-auto">
              <span class="text-muted mb-0"><i class="fa fa-paper-plane"></i></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row flex-grow-1 row-cols-lg-4 row-cols-sm-2">
    <div class="col-sm-6 col-lg-3 p-2">
      <div class="card p-2">
        <div class="h-100" style="min-height: 350px">
          <app-dashboard-doughnut-chart
            [data]="totalAmountOfUpdatesPerConsumer"
            chartTitle="Total amount of updates"
            [totalCount]="totalAmountOfUpdatesPerConsumerCount"></app-dashboard-doughnut-chart>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-lg-5 p-2">
      <div class="card p-2">
        <div class="h-100" style="min-height: 350px">
          <app-dashboard-bar-chart [data]="trackingApiResultData" chartTitle="Tracking API result times (milliseconds)"></app-dashboard-bar-chart>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-lg-4 p-2">
      <div class="card p-2">
        <div class="h-100" style="min-height: 350px">
          <app-dashboard-line-chart [data]="totalUpdates"
                                    chartTitle="All updates"
                                    [totalCount]="getAllUpdateTotalCount(updatesPerUpdateType)"></app-dashboard-line-chart>
        </div>
      </div>
    </div>
    <div *ngFor="let update of context.updatesPerType" class="col-sm-6 col-lg-3 p-2">
      <div class="card p-2">
        <div class="h-100" style="min-height: 350px">
          <app-dashboard-line-chart [data]="updatesPerUpdateType[update]"
                                    chartTitle="{{(update | titlecase).replace('updates', '')}} updates"
                                    [totalCount]="getUpdateTotalCount(updatesPerUpdateType[update])"></app-dashboard-line-chart>
        </div>
      </div>
    </div>
  </div>
</div>
