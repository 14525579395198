<ng-container *ngIf="appContext.isAdmin() && !context.eventId && context.declarationStatusOf(HEALTH)
&& !context.visit.healthDeclarationSummary.healthDeclarationStatus">
  <div class="dropdown always-enabled">
    <button class="btn btn-secondary" type="button" id="adminHamburgerMenu" data-bs-toggle="dropdown" aria-haspopup="true"
      aria-expanded="false">
      <!-- Hamburger menu icon -->
      <i class="fa fa-user-shield" aria-hidden="true"></i>
    </button>
    <div class="dropdown-menu dropdown-menu-end" aria-labelledby="adminHamburgerMenu">
      <!-- Technical reject mdoh -->
      <a *ngIf="'DECLARED' === context.declarationStatusOf(HEALTH)" (click)="reject()" id="rejectHealth"
        class="dropdown-item text-danger" tabindex="-1" type="button">
        <i class="far fa-times-circle" aria-hidden="true"></i> Reject
      </a>
      <!-- Technical accept mdoh -->
      <a *ngIf="'DECLARED' === context.declarationStatusOf(HEALTH)" (click)="accept()" id="acceptHealth"
        class="dropdown-item text-success" tabindex="-1" type="button">
        <i class="far fa-check-circle" aria-hidden="true"></i> Accept
      </a>
      <!-- Functional reject mdoh -->
      <a *ngIf="'ACCEPTED' === context.declarationStatusOf(HEALTH)
      && !context.visit.healthDeclarationSummary.healthDeclarationStatus" id="hamisRejectHealth"
        class="dropdown-item text-danger" tabindex="-1" type="button" (click)="openConfirmationModal(rejectModal)">
        <i class="far fa-times-circle" aria-hidden="true"></i> Reject
      </a>
      <!-- Functional approve mdoh -->
      <a *ngIf="'ACCEPTED' === context.declarationStatusOf(HEALTH)
      && !context.visit.healthDeclarationSummary.healthDeclarationStatus" id="hamisApproveHealth"
        (click)="hamisApprove()" class="dropdown-item text-success" tabindex="-1" type="button">
        <i class="far fa-check-circle" aria-hidden="true"></i> Approve
      </a>
      <!-- Functional approve mdoh with measures -->
      <a *ngIf="'ACCEPTED' === context.declarationStatusOf(HEALTH)
      && !context.visit.healthDeclarationSummary.healthDeclarationStatus" id="hamisApproveHealthMeasuresImposed"
        class="dropdown-item text-warning" tabindex="-1" type="button" (click)="openConfirmationModal(hamisApproveHealthMeasuresImposedModal)">
        <i class="far fa-check-circle" aria-hidden="true"></i> Approve with measures imposed
      </a>
    </div>
  </div>
</ng-container>


<ng-template #rejectModal>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Remarks</h5>
    </div>
    <div class="modal-body">
      <input id="hamisRejectHealth-remarks" type="text" class="form-control">
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Back</button>
      <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
              (click)="hamisReject(appContext.getDocument().getElementById('hamisRejectHealth-remarks')['value'])">Send</button>
    </div>
  </div>
</ng-template>

<ng-template #hamisApproveHealthMeasuresImposedModal>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Remarks</h5>
    </div>
    <div class="modal-body">
      <input id="hamisApproveHealthMeasuresImposed-remarks" type="text" class="form-control">
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Back</button>
      <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
              (click)="hamisApproveMeasuresImposed(appContext.getDocument().getElementById('hamisApproveHealthMeasuresImposed-remarks')['value'])">Send</button>
    </div>
  </div>
</ng-template>
