<div class="my-3"><i>Updates the carrier in both bezoekschip and hinterland</i></div>
<div class="my-3"><i>Carriers are synced to NPCS refdata</i></div>
<div class="my-3"><i>Carriers cannot be selected in bezoekschip without Customs id</i></div>
<div class="my-3"><i>Carriers cannot be selected in hinterland without SCAC code or SMDG code</i></div>
<div class="row">
  <div class="col-md-6">
    <app-form-group><label>Id</label><input class="form-control" [(ngModel)]="_carrier['id']" disabled required></app-form-group>
    <app-form-group><label>Customs id</label><input class="form-control" [(ngModel)]="_carrier.customsId" (blur)="updateAddress()"></app-form-group>
    <app-form-group><label>Name</label><input class="form-control" [(ngModel)]="_carrier.name" required></app-form-group>
  </div>
  <div class="col-md-6">
    <app-form-group><label>SCAC code</label><input class="form-control" [(ngModel)]="_carrier.scacCode"></app-form-group>
    <app-form-group><label>SMDG code</label><input class="form-control" [(ngModel)]="_carrier.smdgCode"></app-form-group>
    <app-form-group><label>BIC code</label><input class="form-control" [(ngModel)]="_carrier.bicCode"></app-form-group>
    <app-form-group>
      <label><app-info>
        Cargo declarant <span class="tooltipContent">Migrated to Box operators field in order to have support for multiple cargo declarants</span>
      </app-info></label>
      <app-search [disabled]="true" [ngModel]="_carrier.cargoDeclarantShortName" [searchFunction]="searchCargoDeclarant"
                  (ngModelChange)="setOrganisation($event)" [resultFormatter]="utils.agentFormatter"
                  placeholder="Find cargo declarant by short name">
      </app-search>
    </app-form-group>
    <app-form-group>
      <label>
        <app-info>
          Box operators <span class="tooltipContent">Affects to which agent we assign overlanded import containers, and from which agent we accept commercial releases</span>
        </app-info>
      </label>
      <app-multi-search [ngModel]="_carrier.boxOperators" [searchFunction]="searchCargoDeclarant"
                        (ngModelChange)="setBoxOperators($event)" [resultFormatter]="utils.agentFormatter"
                        placeholder="Find cargo declarant by short name">
      </app-multi-search>
    </app-form-group>
  </div>
</div>
<ng-container>
  <div class="my-3">
    <i><b>Address information</b> (automatically retrieved by Customs id and used for STI ENS messages)</i>
  </div>
  <div *ngIf="unsavedChanges" class="my-3">
    <i><b>Below changes are automatically retrieved and unsaved!</b></i>
  </div>
  <app-edit-address [(address)]="_carrier.address" [editable]="true"></app-edit-address>
</ng-container>
