import {Injectable} from '@angular/core';
import {BerthVisit} from '@portbase/bezoekschip-service-typescriptmodels';
import {VisitContext} from '../visit-context';

@Injectable({
  providedIn: 'root'
})
export class ShipMovementService {

  context = VisitContext;

  constructor() { }

  /**
  * Ship movement status with respect to the given berth.
  * 
  * @param berthVisit berth visit
  */
  shipStatus(berthVisit: BerthVisit): string {
		let nextBerth = this.nextBerth(berthVisit);
		if (VisitContext.hasDeparted() || (nextBerth && nextBerth.ata )) {
      return 'DEPARTED'
    } else if (berthVisit.ata && !berthVisit.atd) {
      return 'ARRIVED';
    } else if (this.moving(berthVisit)) {
      return 'MOVING'
    } else if (berthVisit.atd) {
      return 'DEPARTED'
    } else {
      return 'EXPECTED'
    }
  }

  private moving(berthVisit: BerthVisit): Boolean {
    var movingToExit = this.isFinalBerth(berthVisit) && !this.isDepartedPort;
    var departedBerth = berthVisit.atd != null;
    var notArrivedNextBerth = !this.isArrivedAtNextBerth(berthVisit);
    return movingToExit || (departedBerth && notArrivedNextBerth);
  }

  private isArrivedAtNextBerth(berthVisit: BerthVisit): Boolean {
    return this.nextBerth(berthVisit) === undefined ? false : this.nextBerth(berthVisit).ata != null;
  }

  private isDepartedPort(): Boolean {
    return this.context.visit.visitDeclaration.portVisit.atdPort != null;
  }

  /**
   * The next berth the ship will visit or null if there are no more.
   * 
   * @param berthVisit 
   */
  nextBerth(berthVisit: BerthVisit): BerthVisit | undefined {
    let berthVisits = this.fullBerthList;
    if (this.isFinalBerth(berthVisit)) {
      return undefined;
    } else {
      return berthVisits[berthVisits.indexOf(berthVisit) + 1]
    }
  }

  private isFinalBerth(berthVisit: BerthVisit): Boolean {
    let berthVisits = this.fullBerthList;
    var currentBerthIndex = berthVisits.indexOf(berthVisit);
    return currentBerthIndex === berthVisits.length - 1;
  }

  /**
   * All the berths regardless of whether it's the 'current' agent or the 'next' agent.
   */
  private get fullBerthList(): BerthVisit[] {
    let berthVisits = this.context.visit.visitDeclaration.portVisit.berthVisits;
    let nextBerthVisits = [];
    if(this.context.visit.nextVisitDeclaration) {
      nextBerthVisits = this.context.visit.nextVisitDeclaration.nextBerthVisits ? this.context.visit.nextVisitDeclaration.nextBerthVisits : [];
    }
    return [...berthVisits, ...nextBerthVisits];
  }
}
