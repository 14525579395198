import {AfterViewInit, Directive, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {NgModel} from '@angular/forms';
import {lodash} from '../utils';

@Directive({
  selector: '[appCompare]'
})
export class CompareDirective implements AfterViewInit, OnChanges, OnDestroy {

  private initialized: boolean;

  @Input() appCompare;
  @Input() compareFormatter = v => v;
  @Input() comparePreamble = "Actual value: ";
  @Input() compareInline: boolean;
  @Input() showComparison: boolean = true;

  constructor(private elementRef: ElementRef, private ngModel: NgModel) {
    ngModel.valueChanges.subscribe(() => this.updateElement());
  }

  ngAfterViewInit(): void {
    this.initialized = true;
    this.updateElement();
  }

  private updateElement = () => {
    if (this.initialized) {
      if (this.compareInline) {
        this.renderInlineAndMarkYellow();
      } else {
        this.renderTooltipAndMarkYellow();
      }
    }
  }

  private renderInlineAndMarkYellow = () => {
    const wrapper = $(this.ensureWrapped());
    if (this.isDifferent()) {
      wrapper.addClass("has-diff");
      if (this.showComparison) this.inlineElement().text(this.comparePreamble + this.compareFormatter(this.appCompare));
    }
  }

  private renderTooltipAndMarkYellow = () => {
    const wrapper = $(this.ensureWrapped());
    if (this.isDifferent()) {
      wrapper.addClass("has-diff");
      wrapper.attr("data-bs-toggle", "tooltip");
      wrapper.attr("data-bs-title", this.comparePreamble + this.compareFormatter(this.appCompare));
      wrapper.attr("data-bs-placement", "bottom");
      if (this.showComparison) wrapper.tooltip('toggleEnabled').tooltip("enable");
    } else {
      wrapper.removeClass("has-diff");
      wrapper.tooltip('disable');
    }
  }

  private inlineElement() {
    const wrapper = $(this.ensureWrapped());
    const spanElement = wrapper.find(".compare-inline");
    return spanElement.length > 0 ? spanElement : wrapper
      .append(this.compareInlineElement())
      .find(".compare-inline");
  }

  private compareInlineElement = () => {
    return $(
      `<div class="prevent-dragula-move compare-inline-wrapper">
            <span class="text-warning fa fa-solid fa-triangle-exclamation"></span>
            <span class="compare-inline"></span>
      </div>`);
  }

  private ensureWrapped() {
    const element = $(this.elementRef.nativeElement);
    const parent = element.parent();

    if (parent.hasClass('diff-wrapper')) {
      return parent;
    } else if (parent.hasClass('input-group')) {
      return parent.addClass('diff-wrapper');
    } else {
      return element.wrap($(document.createElement("div")).addClass('diff-wrapper')).parent();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateElement();
  }

  ngOnDestroy(): void {
    if (!this.compareInline) {
      $(this.elementRef.nativeElement).tooltip('dispose');
    }
  }

  private isDifferent = () => this.isDefined(this.appCompare) &&
    lodash.upperCase(this.compareFormatter(this.ngModel.model)) !== lodash.upperCase(this.compareFormatter(this.appCompare));

  isDefined = (value) => value !== null && value !== undefined;
}
