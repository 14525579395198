import {Component, Input} from '@angular/core';
import {InertingModel} from '../../dangerous-goods.model';

@Component({
  selector: 'app-edit-inerting',
  templateUrl: './edit-inerting.component.html',
  styleUrls: ['./edit-inerting.component.css']
})
export class EditInertingComponent {
  @Input() handling: InertingModel;
}
