<div class="fieldset" [ngClass]="{'disabled' : readonly}">

  <div *ngIf="!loading && !readonly" class="mt-3 row g-0 py-3 bg-location-light">
    <div class="col">
      <div class="fa fa-lg fa-map-marker-alt text-portbase-blue mx-2"></div>
      <span>{{context.visit.visitDeclaration.portVisit.entryPoint?.name | titlecase}}</span>
    </div>
    <div class="col-auto always-enabled" style="width: 1.5rem">
      <button class="btn btn-link p-0" type="button" data-bs-toggle="collapse"
              data-bs-target="#allStowage">
        <span class="fa fa-chevron-right"></span>
      </button>
    </div>
  </div>

  <div class="collapse show" [id]="!loading && !readonly ? 'allStowage' : undefined">
    <div *ngIf="stowages.length == 0" class="row">
      <div class="declaration-block col-12">
        <h2>Stowage<span *ngIf="!loading"> at arrival</span></h2>
      </div>
    </div>

    <div class="accordion" id="stowagesAccordion">
      <app-pagination #pagination [items]="stowages" [(page)]="page" [maxPerPage]="maxPerPage">
        <ng-container *ngFor="let stowage of pagination.getItemsOnPage(); let index = index; trackBy: stowageById">
          <div #stowageRow class="stowageRow">
            <!-- headers -->
            <div *ngIf="index === 0 || stowage.type !== pagination.getItemsOnPage()[index - 1].type || stowage['trailer'] !== pagination.getItemsOnPage()[index-1]['trailer']" [ngSwitch]="stowage.type">
              <div class="row">
                <div class="declaration-block col-12">
                  <h2>{{stowage.trailer ? 'Trailers' : stowage.type === 'breakBulk' ? 'Break bulk' : (stowage.type + 's' | titlecase)}}<span *ngIf="!loading"> at arrival</span></h2>
                </div>
              </div>
              <div class="row g-0 bg-secondary text-light py-2 my-2">
                <ng-container *ngSwitchCase="'container'">
                  <div class="col ps-2 required" style="max-width: 10.5rem">{{stowage.trailer ? 'Trailer nr' : 'Container nr'}}</div>
                  <div class="col ps-2 d-none d-xl-block required" style="max-width: 9rem">Position</div>
                </ng-container>
                <ng-container *ngSwitchCase="'breakBulk'">
                  <div class="col ps-2" style="max-width: 6rem">Item nr</div>
                  <div class="col ps-2 d-none d-xl-block required" style="max-width: 10.5rem">Position</div>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <div class="col ps-2 required" style="max-width: 6rem">Position</div>
                </ng-container>
                <div class="col ps-2 d-none d-xl-block required" style="min-width: 14.5rem" *ngIf="!loading && stowage.type === 'tank'">Status</div>
                <div class="col ps-2 d-none d-xl-block" style="max-width: 10rem" *ngIf="!loading">Loading</div>
                <div class="col ps-2 d-none d-xl-block" style="max-width: 10rem">Discharge</div>
                <div class="col ps-2 d-none d-xl-block required">{{loading?.type === 'blending' ? 'Final good' : 'Good'}}</div>
                <div class="col ps-2 required" style="max-width: 9rem">{{stowage.type === 'container' || stowage.type === 'breakBulk' ? 'Gross weight (kg)' : loading?.type === 'blending' ? 'Added (TNE)' : 'Weight (TNE)'}}</div>
                <div class="col-auto ps-2" style="min-width: 5rem" *ngIf="!readonly"></div>
                <div class="col" style="max-width: 2rem" *ngIf="!readonly && stowage.type==='container'"></div>
                <div class="col-auto d-none d-xl-block" style="width: 2rem" *ngIf="!readonly"></div>
              </div>
            </div>

            <!-- stowage row -->
            <div class="row g-0 my-2 validatable" [ngSwitch]="stowage.type">
              <ng-container *ngSwitchCase="'container'">
                <div class="col" style="max-width: 10.5rem">
                  <div *ngIf="getContainerNumbers(stowage.trailer) as containerNumbers">
                    <app-select class="stowageNumber"
                                *ngIf="!stowage['newContainerNumber'] && containerNumbers.length > 1; else containerNumberElse"
                                [(ngModel)]="stowage.stowageNumber"
                                [addSelectedIfNotExists]="true"
                                [options]="containerNumbers" [formatter]="stowage.trailer ? trailerNumberFormatter : containerNumberFormatter"
                                (ngModelChange)="$event === null ? stowage['newContainerNumber'] = true : null; onContainerNumberSelected(stowage, stowageRow)"></app-select>
                    <ng-template #containerNumberElse>
                      <input [ngModel]="stowage.stowageNumber"
                             required
                             [maxlength]="stowage.trailer? 17 : 11"
                             (blur)="$event.target['value'] ? stowage['newContainerNumber'] = undefined : null; onNewContainerNumberCreated(stowage, $event.target['value'])"
                             class="new-container form-control stowageNumber">
                    </ng-template>
                  </div>
                </div>
                <div class="col ps-2 d-none d-xl-block" style="max-width: 9rem">
                  <input [(ngModel)]="stowage.position" required placeholder="BBBRRTT"
                         (ngModelChange)="onContainerChanged(stowage)" class="form-control" *ngIf="!hideContainerDetails(stowage)">
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="'breakBulk'">
                <div class="col" style="max-width: 6rem">
                  <input [ngModel]="stowage.stowageNumber" disabled class="form-control stowageNumber">
                </div>
                <div class="col ps-2 d-none d-xl-block" style="max-width: 10.5rem">
                  <input [(ngModel)]="stowage.position" required class="form-control">
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="'tank'">
                <div class="col" style="max-width: 6rem">
                  <ng-container *ngIf="loading; else notLoading">
                    <app-select [(ngModel)]="stowage.stowageNumber" [options]="stowage.stowageNumber ? [stowage.stowageNumber].concat(loading.availableTanks).sort() : loading.availableTanks"
                                required (ngModelChange)="onStowageSelected(stowage)"
                                [formatter]="positionFormatter" class="stowageNumber"></app-select>
                  </ng-container>
                  <ng-template #notLoading>
                    <input [(ngModel)]="stowage.stowageNumber" required class="form-control stowageNumber">
                  </ng-template>
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="'hold'">
                <div class="col" style="max-width: 6rem">
                  <input [(ngModel)]="stowage.stowageNumber" (blur)="onStowageSelected(stowage)" required class="form-control stowageNumber">
                </div>
              </ng-container>

              <div class="col ps-2 d-none d-xl-block" *ngIf="!loading && stowage.type === 'tank'" style="min-width: 14.5rem">
                <app-select [(ngModel)]="stowage.tankStatus" [options]="refData.tankStatuses"
                            [formatter]="refData.tankStatusFormatter"
                            (ngModelChange)="onTankStatusSelected(stowage)"></app-select>
              </div>

              <div class="col ps-2 d-none d-xl-block" *ngIf="!loading" style="max-width: 10rem">
                <app-select *ngIf="!stowage.emptyTank && !hideContainerDetails(stowage)" [(ngModel)]="stowage.portOfLoading"
                            [options]="dangerousGoods.loadingOptions" [addSelectedIfNotExists]="true"
                            (ngModelChange)="stowage.type === 'container' ? onContainerChanged(stowage) : null"
                            [formatter]="refData.portFormatterCompact" dataKey="name"></app-select>
              </div>
              <div class="col ps-2 d-none d-xl-block" style="max-width: 10rem">
                <app-select *ngIf="!stowage.emptyTank && !hideContainerDetails(stowage)" [(ngModel)]="stowage.portOfDischarge"
                            [addSelectedIfNotExists]="true"
                            [options]="stowage.emptyTank ? dangerousGoods.loadingOptions : dangerousGoods.dischargeOptions"
                            (ngModelChange)="stowage.type === 'container' ? onContainerChanged(stowage) : null"
                            [formatter]="refData.portFormatterCompact" dataKey="name"></app-select>
              </div>

              <div class="col ps-2 d-none d-xl-block">
                <div *ngIf="stowage.allowGoodSelection">
                  <ng-container *ngIf="getGoodIds(stowage) as goodIds">
                    <app-select *ngIf="!!stowage.good?.goodData" [(ngModel)]="stowage.goodId" [options]="goodIds"
                                [disabled]="loading?.type === 'loading' && stowage.previous?.weight > 0"
                                [formatter]="goodFormatter"
                                (ngModelChange)="$event === null ? stowage['newGood'] = true : null; onGoodSelected(stowage, stowageRow)"
                                placeholder="– Select good –"></app-select>
                  </ng-container>
                  <app-search *ngIf="!stowage.good?.goodData" required class="find-new-good"
                              [(ngModel)]="stowage['goodData']" dataKey="name" [inputFormatter]="refData.goodDataFormatter"
                              (ngModelChange)="onNewGoodFound(stowage)"
                              [searchFunction]="findDangerousGood(stowage)"
                              [resultFormatter]="refData.goodDataFormatter"
                              placeholder="Find dangerous good"></app-search>
                </div>
              </div>
              <div class="col ps-2" style="max-width: 9rem">
                <input *ngIf="!stowage.emptyTank && !stowage.uncleanTankContainer" [(ngModel)]="stowage.weight"
                       appDecimalNumber [maxDecimals]="3" class="form-control" required
                       [max]="stowage.type === 'container' || stowage.type === 'breakBulk' ||
                     context.visit.vessel.summerDeadWeight <= 0 ? undefined: context.visit.vessel.summerDeadWeight">
              </div>
              <div class="col-auto ps-2 text-end" style="min-width: 5rem" *ngIf="!readonly">
                <label [attr.data-bs-target]="'#stowage-collapse' + id + index"
                       [ngClass]="{'btn-invalid' : stowage.ngInvalid}"
                       data-bs-toggle="collapse" class="btn btn-info d-xl-none" onclick="">Details
                </label>
                <label [attr.data-bs-target]="'#stowage-collapse' + id + index" *ngIf="stowage.allowGoodSelection || (context.isAmsterdamVisit() && isEmptyTank(stowage))"
                       [ngClass]="{'btn-invalid' : stowage.ngInvalid}"
                       data-bs-toggle="collapse" class="btn btn-info d-none d-xl-block" onclick="">Details
                </label>
              </div>
              <div class="col" style="max-width: 2rem" *ngIf="!readonly && stowage.type==='container'">
                <button *ngIf="!stowage.uncleanTankContainer" title="Add container item" (click)="addContainerItem(stowage)"
                        class="btn" type="button" tabindex="-1"><span class="fa fa-plus"></span>
                </button>
              </div>
              <div class="col-auto text-end d-none d-sm-block" style="width: 2rem" *ngIf="!readonly">
                <button title="Delete stowage" (click)="deleteStowage(stowage)"
                        class="btn" type="button" tabindex="-1"><span class="fa fa-trash text-secondary"></span>
                </button>
              </div>
            </div>

            <!-- stowage details -->
            <div class="row g-0" *ngIf="!readonly" [appValidator]="stowage">
              <div class="col-12 collapse" data-bs-parent="#stowagesAccordion" [id]="'stowage-collapse' + id + index">
                <div class="card card-body bg-light">
                  <div class="card-title row">
                    <button title="Delete stowage" (click)="deleteStowage(stowage)"
                            class="btn mt-n2 ms-auto d-sm-none" type="button" tabindex="-1"><span
                      class="fa fa-trash text-secondary"></span>
                    </button>
                  </div>


                  <!-- Stowage details on mobile -->

                  <div class="row d-xl-none">
                    <div class="declaration-block col-12">
                      <h2>Stowage details</h2>
                    </div>
                    <div class="col-md">
                      <app-form-group *ngIf="stowage.allowGoodSelection">
                        <label>Good</label>
                        <ng-container *ngIf="getGoodIds(stowage) as goodIds">
                          <app-select *ngIf="!!stowage.good?.goodData" [(ngModel)]="stowage.goodId" [options]="goodIds"
                                      [disabled]="loading?.type === 'loading' && stowage.previous?.weight > 0"
                                      [formatter]="goodFormatter"
                                      (ngModelChange)="$event === null ? stowage['newGood'] = true : null; onGoodSelected(stowage, stowageRow)"
                                      placeholder="– Select good –"></app-select>
                        </ng-container>
                        <app-search *ngIf="!stowage.good?.goodData" class="find-new-good"
                                    [(ngModel)]="stowage['goodData']" dataKey="name" [inputFormatter]="refData.goodDataFormatter"
                                    (ngModelChange)="onNewGoodFound(stowage)"
                                    [searchFunction]="findDangerousGood(stowage)"
                                    [resultFormatter]="refData.goodDataFormatter"
                                    placeholder="Find dangerous good"></app-search>
                      </app-form-group>
                      <app-form-group *ngIf="stowage.type === 'tank' && !loading">
                        <label>Tank status</label>
                        <app-select [(ngModel)]="stowage.tankStatus" [options]="refData.tankStatuses"
                                    [formatter]="refData.tankStatusFormatter"
                                    (ngModelChange)="onTankStatusSelected(stowage)"></app-select>
                      </app-form-group>
                      <app-form-group *ngIf="stowage.type === 'container' && !hideContainerDetails(stowage)">
                        <label>Position</label>
                        <input [(ngModel)]="stowage.position" class="form-control" placeholder="BBBRRTT">
                      </app-form-group>
                      <app-form-group *ngIf="stowage.type === 'breakBulk'">
                        <label>Position</label>
                        <input [(ngModel)]="stowage.position" class="form-control">
                      </app-form-group>
                    </div>
                    <div class="col-md" *ngIf="!stowage.emptyTank">
                      <app-form-group *ngIf="!loading">
                        <label>Port of loading</label>
                        <app-select [(ngModel)]="stowage.portOfLoading" [options]="dangerousGoods.loadingOptions"
                                    [addSelectedIfNotExists]="true"
                                    [formatter]="refData.portFormatterCompact" dataKey="name"></app-select>
                      </app-form-group>
                      <app-form-group>
                        <label>Port of discharge</label>
                        <app-select [(ngModel)]="stowage.portOfDischarge" [addSelectedIfNotExists]="true"
                                    [options]="stowage.emptyTank ? dangerousGoods.loadingOptions : dangerousGoods.dischargeOptions"
                                    [formatter]="refData.portFormatterCompact" dataKey="name"></app-select>
                      </app-form-group>
                    </div>
                  </div>

                  <div class="row" *ngIf="context.isAmsterdamVisit() && isEmptyTank(stowage)">
                    <div class="col-6">
                      <app-form-group>
                        <label>Previous good</label>
                        <app-search class="find-previous-good"
                                    required
                                    [(ngModel)]="stowage.previousGood" dataKey="name"
                                    [inputFormatter]="refData.goodDataFormatter"
                                    [resultFormatter]="refData.goodDataFormatter"
                                    [searchFunction]="findDangerousGood(stowage)"
                                    placeholder="Find dangerous good"></app-search>
                      </app-form-group>
                    </div>
                  </div>

                  <!-- Stowage details -->
                  <app-edit-stowage [stowage]="stowage" [toggleTankContainer]="toggleTankContainer" [isContainerItem]="isContainerItem(stowage)" [disableGoodEdit] = "loading?.type === 'loading'" (goodDataChanged)="onGoodSelected(stowage, stowageRow)"></app-edit-stowage>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </app-pagination>
    </div>

    <!-- new stowage-->
    <div class="row justify-content-between mt-4 mb-2">
      <div class="col-auto pb-2">
        <ng-container *ngIf="!loading || loading.type === 'loading'">
          <button class="btn btn-info" type="button" (click)="uploadInput.click()">
            <span class="fa fa-file-upload"></span>
            Upload <input type="file" hidden (change)="onUpload($event.target.files[0]); $event.target.value = '';" #uploadInput>
          </button>
          <a href="/assets/templates/dangerous-goods-declaration-template-v1.0.xls"
             class="align-middle pe-2 text-portbase-blue ms-2" tabindex="-1">
            Download template <span class="fa fa-file-download"></span></a>
        </ng-container>
      </div>
      <div class="col-auto pb-2">
        <div class="dropdown" *ngIf="!lastAddedStowage; else addWithExistingStowages">
          <button type="button" class="btn btn-info dropdown-toggle" data-bs-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false">Add Stowage
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <button *ngFor="let type of types" type="button" class="dropdown-item" (click)="addStowage(type)"
                    data-bs-toggle="collapse">{{renderType(type)}}
            </button>
          </div>
        </div>

        <ng-template #addWithExistingStowages>
          <div *ngIf="!readonly">
            <span class="dropdown" *ngIf="!loading || loading.type === 'loading'">
              <button type="button" class="btn btn-info dropdown-toggle" data-bs-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">Add other
              </button>
              <span class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <button *ngFor="let type of removeItem(types.slice(), lastAddedStowage.trailer ? 'trailer': lastAddedStowage.type )"
                        type="button" class="dropdown-item" (click)="addStowage(type)"
                        data-bs-toggle="collapse">{{renderType(type)}}
                </button>
              </span>
            </span>
            <button type="button" class="btn btn-info ms-2" (click)="addStowage(lastAddedStowage.trailer ? 'trailer': lastAddedStowage.type)"
                    [disabled]="loading && lastAddedStowage.type === 'tank' && loading.availableTanks.length === 0 && !context.visitHasBeenTransferred() && !context.isOrganisationNextDeclarant()">
              Add {{renderType(lastAddedStowage.trailer ? 'trailer': lastAddedStowage.type)}}
            </button>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
