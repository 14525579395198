import { Component } from '@angular/core';
import { SeaEntry } from '@portbase/bezoekschip-service-typescriptmodels/common';
import { VisitContext } from '../../visit-context';

@Component({
  selector: 'app-entry-dependency-icon',
  templateUrl: './entry-dependency-icon.component.html',
  styleUrls: ['./entry-dependency-icon.component.css']
})
export class EntryDependencyIconComponent {

  context = VisitContext;

  constructor() { }

  get dependency(): string {
    if(this.context.visit.visitDeclaration.portVisit.portEntry && this.context.visit.visitDeclaration.portVisit.portEntry.origin === 'SEA')
    {
      let portEntry = (<SeaEntry>this.context.visit.visitDeclaration.portVisit.portEntry);
      if(portEntry.entryDependency) {
        return portEntry.entryDependency.dependencyType;
      }
    }
  }

  get thisShipName(): string {
    return this.context.visit.vessel.name;
  }

  get otherShipName(): string {
    let portEntry = (<SeaEntry>this.context.visit.visitDeclaration.portVisit.portEntry);
    return portEntry.entryDependency.vesselName;
  }

}
