<div class="row sticky-top bg-white" style="top:6.125rem">
  <div class="col-md-12">
    <app-alerting></app-alerting>
  </div>
  <div class="col-md-12">
    <app-action-nav-bar></app-action-nav-bar>
  </div>
</div>

<div class="declaration-block my-3">
  <h2>Import declarations</h2>
</div>

<div class="row narrow-gutters my-4">
  <div class="col always-enabled">
    <app-local-filter (termChanged)="filterTerm = $event"
                      (updated)="filteredDeclarations = $event" [data]="context.savedVisit.importDeclarations"
                      [placeholder]="'Filter by consignment nr, good name, etc.'"></app-local-filter>
  </div>
</div>

<div *ngFor="let declaration of filteredDeclarations; trackBy : trackByAgent" class="mb-3"
     [routerLink]="'/details/' + context.savedVisit.crn + '/cargoImport/' + declaration.cargoDeclarant.shortName + (filterTerm ? ('/' + filterTerm) : '')"
     [queryParams]="{eventId: context.eventId}">
  <div class="mx-0 row align-items-center g-0 import-declaration border border-light"
       style="border-width: 2px!important; margin-top: -2px">
    <div class="col">
      <div class="row pe-3 g-0 align-items-center">
        <ng-container [ngSwitch]="getDeclarationStatus(declaration)?.status">
          <div class="col-auto me-3 p-3 bg-info" *ngSwitchCase="'DECLARED'"
               title="Declaration has been sent but we haven't received a response">
            <span class="fa fa-fw fa-paper-plane text-white"></span>
          </div>
          <div class="col-auto me-3 p-3 bg-portbase-green" *ngSwitchCase="'ACCEPTED'"
               title="ATO has been accepted">
            <span class="fa fa-fw fa-check text-white"></span>
          </div>
          <div class="col-auto me-3 p-3 bg-portbase-red" *ngSwitchCase="'REJECTED'"
               title="Declaration has been rejected">
            <span class="fa fa-fw fa-times text-white"></span>
          </div>
          <div class="col-auto me-3 p-3" *ngSwitchDefault>
            <span class="fa fa-fw fa-route text-dark"></span>
          </div>
        </ng-container>
        <div class="col fw-bold text-dark">
          {{declaration.cargoDeclarant.fullName | titlecase}}
        </div>
        <div class="col-auto d-none d-md-block text-dark">
          <app-cargo-summary [importModel]="asImportModel(declaration)"></app-cargo-summary>
        </div>
      </div>
    </div>
    <div class="col-auto">
      <div class="text-end pe-2" [ngStyle]="{'width.rem' : 2}">
        <h4 class="m-0 align-top fa fa-chevron-right text-secondary"></h4>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="appContext.isAdminOrCustoms() && (getCargoDeclarantsWithNewConsignments() | async) as declarantsWithProcesses">
  <ng-container *ngFor="let declarantWithProcesses of declarantsWithProcesses">
    <ng-template [ngTemplateOutlet]="cargoDeclarantWithConsignmentProcesses"
                 [ngTemplateOutletContext]="{ declarantWithProcesses: declarantWithProcesses }">
    </ng-template>
  </ng-container>
</ng-container>

<div class="row">
  <div class="col">
    <div *ngIf="filteredDeclarations.length === 0" class="text-center text-muted py-2">
      <ng-container *ngIf="filterTerm && context.savedVisit.importDeclarations.length > 0; else noDeclarationsYet">
        No matching import declarations found.
      </ng-container>
      <ng-template #noDeclarationsYet>
        <ng-container *ngIf="context.savedVisit.cargoImportEnabled; else legacyVisit">
          <ng-container *ngIf="amountOfNewCargoDeclarants === 0">
            No import declarations found.
          </ng-container>
        </ng-container>
      </ng-template>
      <ng-template #legacyVisit>
        Import declared in <a href="https://www.pcs.portbase.com" target="_blank">Cargo Import 1.0</a>
      </ng-template>
    </div>
  </div>
</div>

<div class="row always-enabled" *ngIf="context.savedVisit.importDeclarations.length > 0">
  <div class="col-auto ms-auto">
    <button (click)="downloadDischargeDifferences()"
      class="btn btn-info border-0 bg-transparent text-info" style="min-width: 8rem"
      type="button" data-bs-toggle="dropdown"><span class="fa fa-file-download me-1"></span> Download differences
    </button>
  </div>
</div>

<div class="row always-enabled mt-3" *ngIf="appContext.isAdmin() && !context.eventId">
  <div class="col-md-12">
    <div class="row g-0 justify-content-end my-1">
      <div class="col-auto">
        <button *ngIf="context.savedVisit.importDeclarations.length > 0" (click)="syncToWpcs()"
                type="button" class="btn btn-outline-info ms-2" style="min-width: 11rem" tabindex="-1">Retry wpcs-sync
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #cargoDeclarantWithConsignmentProcesses let-declarantWithProcesses="declarantWithProcesses">
  <div class="mx-0 row align-items-center g-0 import-declaration border border-light mb-3" style="border-width: 2px!important; margin-top: -2px"
       [routerLink]="'/cargo/' + context.savedVisit.crn + '/' + declarantWithProcesses.declarant.iamConnectedId">
    <div class="col">
      <div class="row pe-3 g-0 align-items-center">
        <div class="col-auto me-3 p-3">
          <span class="fa fa-fw fa-route text-dark"></span>
        </div>
        <div class="col fw-bold text-dark">
          <span>{{declarantWithProcesses.declarant.fullName | titlecase}}</span>
          <h5 class="fa fa-arrow-up fa-rotate-by ms-2 mb-0" style="--fa-rotate-angle: 45deg;" aria-hidden="true"></h5>
        </div>
        <div class="ms-auto col-auto">
          <span class="mx-2" title="{{declarantWithProcesses.amountOfConsignments}} consignment{{declarantWithProcesses.amountOfConsignments > 1 ? 's' : ''}}">
            <span class="fa fa-file-invoice me-1"></span>
            {{declarantWithProcesses.amountOfConsignments}}
          </span>
        </div>
      </div>
    </div>
  </div>
</ng-template>
