import {ConsignmentEntity} from '@portbase/bezoekschip-service-typescriptmodels';

export const consignment1 = {
  "@class": "com.portbase.bezoekschip.common.api.cargo.common.ConsignmentEntity",
  "consignment": {
    "consignmentNumber": "CMDULPL1068118",
    "portOfLoading": {
      "name": "Antwerpen",
      "locationUnCode": "BEANR",
      "countryUnCode": "BE",
      "euPort": true
    },
    "portOfDischarge": {
      "name": "Rotterdam",
      "locationUnCode": "NLRTM",
      "countryUnCode": "NL",
      "euPort": true
    },
    "actualDeparture": "2021-10-30T22:00:00Z",
    "terminal": {
      "terminalName": "AMAZONEH ECT DDE",
      "terminalCode": "4810",
      "organisationShortName": "ECTDELTA",
      "organisationName": "ECT Delta Terminal B.V."
    },
    "movementReferenceNumbers": [
      "21BEN0000017514498"
    ],
    "partShipment": false,
    "movementType": "DOOR_TO_PIER",
    "customsStatus": null,
    "customsProcess": "SEA_IN_SEA_OUT",
    "warehouseLicense": null,
    "bulkAuthorisations": [],
    "exchangeRate": {
      "rate": 1.1634,
      "currency": {
        "unCode": "USD",
        "description": "U.S.A.-dollar"
      }
    },
    "consignor": {
      "eoriNumber": null,
      "name": "SACKERS RECYCLING RAILWAY SIDINGS",
      "address": "GREAT BLAKENHAM",
      "zipCode": "IP6 0JB",
      "city": "IPSWICH",
      "countryCode": "GB",
      "id": null,
      "ownerShortName": null
    },
    "consignee": {
      "eoriNumber": null,
      "name": "WORLD METAL RECOVERY PTE LTD",
      "address": "NO 2 TUAS AVENUE 13",
      "zipCode": "638974",
      "city": "SINGAPORE",
      "countryCode": "SG",
      "id": null,
      "ownerShortName": null
    },
    "partiesToNotify": [
      {
        "eoriNumber": null,
        "name": "WORLD METAL RECOVERY PTE LTD",
        "address": "NO 2 TUAS AVENUE 13",
        "zipCode": "638974",
        "city": "SINGAPORE",
        "countryCode": "SG",
        "id": null,
        "ownerShortName": null
      }
    ],
    "placeOfOrigin": null,
    "placeOfDestination": {
      "name": "Singapore",
      "locationUnCode": "SGSIN",
      "countryUnCode": "SG",
      "euLocation": false
    },
    "placeOfDelivery": "ROTTERDAM",
    "onCarriageDetails": null,
    "agentVoyageNumber": "0HFAGS1MA",
    "goodsItems": [
      {
        "itemNumber": 1,
        "description": "Waste and scrap, of aluminium (excl. slags, scale and the li",
        "grossWeight": 25010,
        "numberOfOuterPackages": 1,
        "outerPackageType": {
          "code": "LT",
          "name": "Lot",
          "bulk": false
        },
        "netWeight": null,
        "numberOfInnerPackages": null,
        "innerPackageType": null,
        "classification": {
          "code": "7602",
          "description": "Aluminium waste and scrap"
        },
        "dangerInformation": null,
        "marksAndNumbers": [],
        "minimumTemperature": null,
        "maximumTemperature": null,
        "placements": [
          {
            "equipmentNumber": "BMOU5658104",
            "numberOfPackages": 1,
            "grossWeight": 25010
          },
          {
            "equipmentNumber": "TRHU7170440",
            "numberOfPackages": 1,
            "grossWeight": 25010
          },
          {
            "equipmentNumber": "BMOU5658105",
            "numberOfPackages": 1,
            "grossWeight": 25010
          },
          {
            "equipmentNumber": "BMOU5658106",
            "numberOfPackages": 1,
            "grossWeight": 25010
          }
        ],
        "producedDocuments": []
      },
      {
        "itemNumber": 2,
        "description": "Other goods item",
        "grossWeight": 25010,
        "numberOfOuterPackages": 1,
        "outerPackageType": {
          "code": "LT",
          "name": "Lot",
          "bulk": false
        },
        "netWeight": null,
        "numberOfInnerPackages": null,
        "innerPackageType": null,
        "classification": {
          "code": "7602",
          "description": "Aluminium waste and scrap"
        },
        "dangerInformation": null,
        "marksAndNumbers": [],
        "minimumTemperature": null,
        "maximumTemperature": null,
        "placements": [
          {
            "equipmentNumber": "BMOU5658120",
            "numberOfPackages": 1,
            "grossWeight": 25010
          },
          {
            "equipmentNumber": "TRHU7170440",
            "numberOfPackages": 1,
            "grossWeight": 25010
          }
        ],
        "producedDocuments": []
      }
    ]
  },
  "crn": "NLRTM21024496",
  "cargoDeclarant": "CMACGMRHN",
  "allowedViewers": [
    "CMACGMRHN"
  ]
};
export const consignment1SameContainer = <ConsignmentEntity>{
  "@class": "com.portbase.bezoekschip.common.api.cargo.common.ConsignmentEntity",
  "consignment": {
    "consignmentNumber": "OTHER0001",
    "portOfLoading": {
      "name": "Antwerpen",
      "locationUnCode": "BEANR",
      "countryUnCode": "BE",
      "euPort": true
    },
    "portOfDischarge": {
      "name": "Rotterdam",
      "locationUnCode": "NLRTM",
      "countryUnCode": "NL",
      "euPort": true
    },
    "actualDeparture": "2021-10-30T22:00:00Z",
    "terminal": {
      "terminalName": "AMAZONEH ECT DDE",
      "terminalCode": "4810",
      "organisationShortName": "ECTDELTA",
      "organisationName": "ECT Delta Terminal B.V."
    },
    "movementReferenceNumbers": [
      "21BEN0000017514498"
    ],
    "partShipment": false,
    "movementType": "DOOR_TO_PIER",
    "customsStatus": null,
    "customsProcess": "SEA_IN_SEA_OUT",
    "warehouseLicense": null,
    "bulkAuthorisations": [],
    "exchangeRate": {
      "rate": 1.1634,
      "currency": {
        "unCode": "USD",
        "description": "U.S.A.-dollar"
      }
    },
    "consignor": {
      "eoriNumber": null,
      "name": "SACKERS RECYCLING RAILWAY SIDINGS",
      "address": "GREAT BLAKENHAM",
      "zipCode": "IP6 0JB",
      "city": "IPSWICH",
      "countryCode": "GB",
      "id": null,
      "ownerShortName": null
    },
    "consignee": {
      "eoriNumber": null,
      "name": "WORLD METAL RECOVERY PTE LTD",
      "address": "NO 2 TUAS AVENUE 13",
      "zipCode": "638974",
      "city": "SINGAPORE",
      "countryCode": "SG",
      "id": null,
      "ownerShortName": null
    },
    "partiesToNotify": [
      {
        "eoriNumber": null,
        "name": "WORLD METAL RECOVERY PTE LTD",
        "address": "NO 2 TUAS AVENUE 13",
        "zipCode": "638974",
        "city": "SINGAPORE",
        "countryCode": "SG",
        "id": null,
        "ownerShortName": null
      }
    ],
    "placeOfOrigin": null,
    "placeOfDestination": {
      "name": "Singapore",
      "locationUnCode": "SGSIN",
      "countryUnCode": "SG",
      "euLocation": false
    },
    "placeOfDelivery": "ROTTERDAM",
    "onCarriageDetails": null,
    "agentVoyageNumber": "0HFAGS1MA",
    "goodsItems": [
      {
        "itemNumber": 1,
        "description": "other good in container BMOU5658104",
        "grossWeight": 25010,
        "numberOfOuterPackages": 1,
        "outerPackageType": {
          "code": "LT",
          "name": "Lot",
          "bulk": false
        },
        "netWeight": null,
        "numberOfInnerPackages": null,
        "innerPackageType": null,
        "classification": {
          "code": "7602",
          "description": "Aluminium waste and scrap"
        },
        "dangerInformation": null,
        "marksAndNumbers": [],
        "minimumTemperature": null,
        "maximumTemperature": null,
        "placements": [
          {
            "equipmentNumber": "MSKU0608439-OnATA",
            "numberOfPackages": 1,
            "grossWeight": 25010
          }
        ],
        "producedDocuments": []
      }
    ]
  },
  "crn": "NLRTM21024496",
  "cargoDeclarant": "CMACGMRHN",
  "allowedViewers": [
    "CMACGMRHN"
  ]
};
export const consignment2 = {
  "@class": "com.portbase.bezoekschip.common.api.cargo.common.ConsignmentEntity",
  "consignment": {
    "consignmentNumber": "CMDULPL1068963",
    "portOfLoading": {
      "name": "London Gateway Port",
      "locationUnCode": "GBLGP",
      "countryUnCode": "GB",
      "euPort": false
    },
    "portOfDischarge": {
      "name": "Rotterdam",
      "locationUnCode": "NLRTM",
      "countryUnCode": "NL",
      "euPort": true
    },
    "actualDeparture": "2021-10-23T22:00:00Z",
    "terminal": {
      "terminalName": "AMALIAH RWG DS 1",
      "terminalCode": "4284",
      "organisationShortName": "RWG",
      "organisationName": "Rotterdam World Gateway (RWG)"
    },
    "movementReferenceNumbers": [
      "21NL1ACB5A4D083034"
    ],
    "partShipment": false,
    "movementType": "PIER_TO_PIER",
    "customsStatus": null,
    "customsProcess": "SEA_IN_SEA_OUT",
    "warehouseLicense": null,
    "bulkAuthorisations": [],
    "exchangeRate": {
      "rate": 1.1564,
      "currency": {
        "unCode": "USD",
        "description": "U.S.A.-dollar"
      }
    },
    "consignor": {
      "eoriNumber": null,
      "name": "TUSCOR LLOYDS (UK) LIMITED",
      "address": "16 BLACKMORE ROAD",
      "zipCode": "M32 0QY",
      "city": "MANCHESTER",
      "countryCode": "GB",
      "id": null,
      "ownerShortName": null
    },
    "consignee": {
      "eoriNumber": null,
      "name": "TUSCOR LLOYDS U.K DE MEXICO S.A DE",
      "address": "AV LAS AMERICAS 140 PISO 15 INT",
      "zipCode": "94298",
      "city": "BOCA DEL RIO",
      "countryCode": "MX",
      "id": null,
      "ownerShortName": null
    },
    "partiesToNotify": [
      {
        "eoriNumber": null,
        "name": "TUSCOR LLOYDS U.K DE MEXICO S.A DE",
        "address": "AV LAS AMERICAS 140 PISO 15 INT",
        "zipCode": "94298",
        "city": "BOCA DEL RIO",
        "countryCode": "MX",
        "id": null,
        "ownerShortName": null
      }
    ],
    "placeOfOrigin": null,
    "placeOfDestination": {
      "name": "Veracruz",
      "locationUnCode": "MXVER",
      "countryUnCode": "MX",
      "euLocation": false
    },
    "placeOfDelivery": "ROTTERDAM",
    "onCarriageDetails": null,
    "agentVoyageNumber": "0RTAFS1MA",
    "goodsItems": [
      {
        "itemNumber": 1,
        "description": "Parts and accessories, for tractors, motor vehicles for the",
        "grossWeight": 5211,
        "numberOfOuterPackages": 41,
        "outerPackageType": {
          "code": "CS",
          "name": "Case",
          "bulk": false
        },
        "netWeight": null,
        "numberOfInnerPackages": null,
        "innerPackageType": null,
        "classification": {
          "code": "8708",
          "description": "Parts and accessories of the motor vehicles of headings|8701|to 8705"
        },
        "dangerInformation": null,
        "marksAndNumbers": [],
        "minimumTemperature": null,
        "maximumTemperature": null,
        "placements": [
          {
            "equipmentNumber": "TRHU7170440",
            "numberOfPackages": 41,
            "grossWeight": 5211
          }
        ],
        "producedDocuments": []
      }
    ]
  },
  "crn": "NLRTM21021649",
  "cargoDeclarant": "CMACGMRHN",
  "allowedViewers": [
    "CMACGMRHN"
  ]
};
export const consignment3 = {
  "@class": "com.portbase.bezoekschip.common.api.cargo.common.ConsignmentEntity",
  "consignment": {
    "consignmentNumber": "CMDULPL1068358",
    "portOfLoading": {
      "name": "Montevideo",
      "locationUnCode": "UYMVD",
      "countryUnCode": "UY",
      "euPort": false
    },
    "portOfDischarge": {
      "name": "Rotterdam",
      "locationUnCode": "NLRTM",
      "countryUnCode": "NL",
      "euPort": true
    },
    "actualDeparture": "2021-10-04T22:00:00Z",
    "terminal": {
      "terminalName": "HUTCHISON PORTS DELTA II WZ",
      "terminalCode": "4807",
      "organisationShortName": "APMRTM",
      "organisationName": "APM Terminals Rotterdam B.V."
    },
    "movementReferenceNumbers": [
      "21NL18FE6B48969592"
    ],
    "partShipment": false,
    "movementType": "PIER_TO_PIER",
    "customsStatus": null,
    "customsProcess": null,
    "warehouseLicense": null,
    "bulkAuthorisations": [],
    "exchangeRate": {
      "rate": 1.1604,
      "currency": {
        "unCode": "USD",
        "description": "U.S.A.-dollar"
      }
    },
    "consignor": {
      "eoriNumber": null,
      "name": "SEAFAST LOGISTICS LIMITED",
      "address": "FELIXSTOWE MEGA DISTRIBUTION CENTRE",
      "zipCode": "IP11 4BA",
      "city": "FELIXSTOWE",
      "countryCode": "GB",
      "id": null,
      "ownerShortName": null
    },
    "consignee": {
      "eoriNumber": null,
      "name": "SEAFRIGO NETHERLANDS BV",
      "address": "WESTBLAAK 192",
      "zipCode": "3012 KN",
      "city": "ROTTERDAM",
      "countryCode": "NL",
      "id": null,
      "ownerShortName": null
    },
    "partiesToNotify": [
      {
        "eoriNumber": null,
        "name": "SEAFRIGO NETHERLANDS BV",
        "address": "WESTBLAAK 192",
        "zipCode": "3012 KN",
        "city": "ROTTERDAM",
        "countryCode": "NL",
        "id": null,
        "ownerShortName": null
      }
    ],
    "placeOfOrigin": null,
    "placeOfDestination": null,
    "placeOfDelivery": "ROTTERDAM",
    "onCarriageDetails": null,
    "agentVoyageNumber": "0EWAJS1MA",
    "goodsItems": [
      {
        "itemNumber": 1,
        "description": "Frozen cuttle fish and squid",
        "grossWeight": 17942,
        "numberOfOuterPackages": 2467,
        "outerPackageType": {
          "code": "BX",
          "name": "Box",
          "bulk": false
        },
        "netWeight": null,
        "numberOfInnerPackages": null,
        "innerPackageType": null,
        "classification": {
          "code": "0307",
          "description": "Molluscs, whether in shell or not, live, fresh, chilled, frozen, dried, salted or in brine; smoked molluscs, whether in shell or not, whether or not cooked before or during the smoking process; flours, meals and pellets of molluscs, fit for human consumption"
        },
        "dangerInformation": null,
        "marksAndNumbers": [],
        "minimumTemperature": null,
        "maximumTemperature": null,
        "producedDocuments": []
      }
    ]
  },
  "crn": "NLRTM21025296",
  "cargoDeclarant": "CMACGMRHN",
  "allowedViewers": [
    "CMACGMRHN"
  ]
}

export const consignmentCustomsProcessNone = {
  "@class": "com.portbase.bezoekschip.common.api.cargo.common.ConsignmentEntity",
  "consignment": {
    "consignmentNumber": "WECC2163RTM1067",
    "portOfLoading": {
      "name": "London Gateway Port",
      "locationUnCode": "GBLGP",
      "countryUnCode": "GB",
      "euPort": false
    },
    "portOfDischarge": {
      "name": "Rotterdam",
      "locationUnCode": "NLRTM",
      "countryUnCode": "NL",
      "euPort": true
    },
    "actualDeparture": "2021-10-23T22:00:00Z",
    "terminal": {
      "terminalName": "AMALIAH RWG DS 1",
      "terminalCode": "4284",
      "organisationShortName": "RWG",
      "organisationName": "Rotterdam World Gateway (RWG)"
    },
    "movementReferenceNumbers": [
      "21NL1ACB5A4D083034"
    ],
    "partShipment": false,
    "movementType": "PIER_TO_PIER",
    "customsStatus": null,
    "customsProcess": null,
    "warehouseLicense": null,
    "bulkAuthorisations": [],
    "exchangeRate": {
      "rate": 1.1564,
      "currency": {
        "unCode": "USD",
        "description": "U.S.A.-dollar"
      }
    },
    "consignor": {
      "eoriNumber": null,
      "name": "TUSCOR LLOYDS (UK) LIMITED",
      "address": "16 BLACKMORE ROAD",
      "zipCode": "M32 0QY",
      "city": "MANCHESTER",
      "countryCode": "GB",
      "id": null,
      "ownerShortName": null
    },
    "consignee": {
      "eoriNumber": null,
      "name": "TUSCOR LLOYDS U.K DE MEXICO S.A DE",
      "address": "AV LAS AMERICAS 140 PISO 15 INT",
      "zipCode": "94298",
      "city": "BOCA DEL RIO",
      "countryCode": "MX",
      "id": null,
      "ownerShortName": null
    },
    "partiesToNotify": [
      {
        "eoriNumber": null,
        "name": "TUSCOR LLOYDS U.K DE MEXICO S.A DE",
        "address": "AV LAS AMERICAS 140 PISO 15 INT",
        "zipCode": "94298",
        "city": "BOCA DEL RIO",
        "countryCode": "MX",
        "id": null,
        "ownerShortName": null
      }
    ],
    "placeOfOrigin": null,
    "placeOfDestination": {
      "name": "Veracruz",
      "locationUnCode": "MXVER",
      "countryUnCode": "MX",
      "euLocation": false
    },
    "placeOfDelivery": "ROTTERDAM",
    "onCarriageDetails": null,
    "agentVoyageNumber": "0RTAFS1MA",
    "goodsItems": [
      {
        "itemNumber": 1,
        "description": "Parts and accessories, for tractors, motor vehicles for the",
        "grossWeight": 5211,
        "numberOfOuterPackages": 41,
        "outerPackageType": {
          "code": "CS",
          "name": "Case",
          "bulk": false
        },
        "netWeight": null,
        "numberOfInnerPackages": null,
        "innerPackageType": null,
        "classification": {
          "code": "8708",
          "description": "Parts and accessories of the motor vehicles of headings|8701|to 8705"
        },
        "dangerInformation": null,
        "marksAndNumbers": [],
        "minimumTemperature": null,
        "maximumTemperature": null,
        "placements": [
          {
            "equipmentNumber": "TRHU7170450",
            "numberOfPackages": 41,
            "grossWeight": 5211
          }
        ],
        "producedDocuments": []
      }
    ]
  },
  "crn": "NLRTM21021649",
  "cargoDeclarant": "CMACGMRHN",
  "allowedViewers": [
    "CMACGMRHN"
  ]
};
export const consignmentCustomsProcessSeaInSeaOut = {
  "@class": "com.portbase.bezoekschip.common.api.cargo.common.ConsignmentEntity",
  "consignment": {
    "consignmentNumber": "WECC2163RTM1068",
    "portOfLoading": {
      "name": "London Gateway Port",
      "locationUnCode": "GBLGP",
      "countryUnCode": "GB",
      "euPort": false
    },
    "portOfDischarge": {
      "name": "Rotterdam",
      "locationUnCode": "NLRTM",
      "countryUnCode": "NL",
      "euPort": true
    },
    "actualDeparture": "2021-10-23T22:00:00Z",
    "terminal": {
      "terminalName": "AMALIAH RWG DS 1",
      "terminalCode": "4284",
      "organisationShortName": "RWG",
      "organisationName": "Rotterdam World Gateway (RWG)"
    },
    "movementReferenceNumbers": [
      "21NL1ACB5A4D083034"
    ],
    "partShipment": false,
    "movementType": "PIER_TO_PIER",
    "customsStatus": "EU_COMMUNITY_GOODS_IN_TRANSHIPMENT",
    "customsProcess": "SEA_IN_SEA_OUT",
    "warehouseLicense": null,
    "bulkAuthorisations": [],
    "exchangeRate": {
      "rate": 1.1564,
      "currency": {
        "unCode": "USD",
        "description": "U.S.A.-dollar"
      }
    },
    "consignor": {
      "eoriNumber": null,
      "name": "TUSCOR LLOYDS (UK) LIMITED",
      "address": "16 BLACKMORE ROAD",
      "zipCode": "M32 0QY",
      "city": "MANCHESTER",
      "countryCode": "GB",
      "id": null,
      "ownerShortName": null
    },
    "consignee": {
      "eoriNumber": null,
      "name": "TUSCOR LLOYDS U.K DE MEXICO S.A DE",
      "address": "AV LAS AMERICAS 140 PISO 15 INT",
      "zipCode": "94298",
      "city": "BOCA DEL RIO",
      "countryCode": "MX",
      "id": null,
      "ownerShortName": null
    },
    "partiesToNotify": [
      {
        "eoriNumber": null,
        "name": "TUSCOR LLOYDS U.K DE MEXICO S.A DE",
        "address": "AV LAS AMERICAS 140 PISO 15 INT",
        "zipCode": "94298",
        "city": "BOCA DEL RIO",
        "countryCode": "MX",
        "id": null,
        "ownerShortName": null
      }
    ],
    "placeOfOrigin": null,
    "placeOfDestination": {
      "name": "Veracruz",
      "locationUnCode": "MXVER",
      "countryUnCode": "MX",
      "euLocation": false
    },
    "placeOfDelivery": "Apm Terminal Maasvlakte Ii",
    "onCarriageDetails": null,
    "agentVoyageNumber": "0RTAFS1MA",
    "goodsItems": [
      {
        "itemNumber": 1,
        "description": "Parts and accessories, for tractors, motor vehicles for the",
        "grossWeight": 5211,
        "numberOfOuterPackages": 41,
        "outerPackageType": {
          "code": "CS",
          "name": "Case",
          "bulk": false
        },
        "netWeight": null,
        "numberOfInnerPackages": null,
        "innerPackageType": null,
        "classification": {
          "code": "8708",
          "description": "Parts and accessories of the motor vehicles of headings|8701|to 8705"
        },
        "dangerInformation": null,
        "marksAndNumbers": [],
        "minimumTemperature": null,
        "maximumTemperature": null,
        "placements": [
          {
            "equipmentNumber": "TRHU7170460",
            "numberOfPackages": 41,
            "grossWeight": 5211
          }
        ],
        "producedDocuments": []
      }
    ]
  },
  "crn": "NLRTM21021649",
  "cargoDeclarant": "CMACGMRHN",
  "allowedViewers": [
    "CMACGMRHN"
  ]
};
export const consignmentCustomsProcessMilitary = {
  "@class": "com.portbase.bezoekschip.common.api.cargo.common.ConsignmentEntity",
  "consignment": {
    "consignmentNumber": "WECC2163RTM1069",
    "portOfLoading": {
      "name": "London Gateway Port",
      "locationUnCode": "GBLGP",
      "countryUnCode": "GB",
      "euPort": false
    },
    "portOfDischarge": {
      "name": "Rotterdam",
      "locationUnCode": "NLRTM",
      "countryUnCode": "NL",
      "euPort": true
    },
    "actualDeparture": "2021-10-23T22:00:00Z",
    "terminal": {
      "terminalName": "AMALIAH RWG DS 1",
      "terminalCode": "4284",
      "organisationShortName": "RWG",
      "organisationName": "Rotterdam World Gateway (RWG)"
    },
    "movementReferenceNumbers": [
      "21NL1ACB5A4D083034"
    ],
    "partShipment": false,
    "movementType": "PIER_TO_PIER",
    "customsStatus": null,
    "customsProcess": "MILITARY",
    "warehouseLicense": null,
    "bulkAuthorisations": [],
    "exchangeRate": {
      "rate": 1.1564,
      "currency": {
        "unCode": "USD",
        "description": "U.S.A.-dollar"
      }
    },
    "consignor": {
      "eoriNumber": null,
      "name": "TUSCOR LLOYDS (UK) LIMITED",
      "address": "16 BLACKMORE ROAD",
      "zipCode": "M32 0QY",
      "city": "MANCHESTER",
      "countryCode": "GB",
      "id": null,
      "ownerShortName": null
    },
    "consignee": {
      "eoriNumber": null,
      "name": "TUSCOR LLOYDS U.K DE MEXICO S.A DE",
      "address": "AV LAS AMERICAS 140 PISO 15 INT",
      "zipCode": "94298",
      "city": "BOCA DEL RIO",
      "countryCode": "MX",
      "id": null,
      "ownerShortName": null
    },
    "partiesToNotify": [
      {
        "eoriNumber": null,
        "name": "TUSCOR LLOYDS U.K DE MEXICO S.A DE",
        "address": "AV LAS AMERICAS 140 PISO 15 INT",
        "zipCode": "94298",
        "city": "BOCA DEL RIO",
        "countryCode": "MX",
        "id": null,
        "ownerShortName": null
      }
    ],
    "placeOfOrigin": null,
    "placeOfDestination": {
      "name": "Veracruz",
      "locationUnCode": "MXVER",
      "countryUnCode": "MX",
      "euLocation": false
    },
    "placeOfDelivery": "ROTTERDAM",
    "onCarriageDetails": null,
    "agentVoyageNumber": "0RTAFS1MA",
    "goodsItems": [
      {
        "itemNumber": 1,
        "description": "Parts and accessories, for tractors, motor vehicles for the",
        "grossWeight": 5211,
        "numberOfOuterPackages": 41,
        "outerPackageType": {
          "code": "CS",
          "name": "Case",
          "bulk": false
        },
        "netWeight": null,
        "numberOfInnerPackages": null,
        "innerPackageType": null,
        "classification": {
          "code": "8708",
          "description": "Parts and accessories of the motor vehicles of headings|8701|to 8705"
        },
        "dangerInformation": null,
        "marksAndNumbers": [],
        "minimumTemperature": null,
        "maximumTemperature": null,
        "placements": [
          {
            "equipmentNumber": "TRHU7170470",
            "numberOfPackages": 41,
            "grossWeight": 5211
          }
        ],
        "producedDocuments": []
      }
    ]
  },
  "crn": "NLRTM21021649",
  "cargoDeclarant": "CMACGMRHN",
  "allowedViewers": [
    "CMACGMRHN"
  ]
};
export const consignmentCustomsProcessEmptyReturnPackaging = {
  "@class": "com.portbase.bezoekschip.common.api.cargo.common.ConsignmentEntity",
  "consignment": {
    "consignmentNumber": "WECC2163RTM1070",
    "portOfLoading": {
      "name": "London Gateway Port",
      "locationUnCode": "GBLGP",
      "countryUnCode": "GB",
      "euPort": false
    },
    "portOfDischarge": {
      "name": "Rotterdam",
      "locationUnCode": "NLRTM",
      "countryUnCode": "NL",
      "euPort": true
    },
    "actualDeparture": "2021-10-23T22:00:00Z",
    "terminal": {
      "terminalName": "AMALIAH RWG DS 1",
      "terminalCode": "4284",
      "organisationShortName": "RWG",
      "organisationName": "Rotterdam World Gateway (RWG)"
    },
    "movementReferenceNumbers": [
      "21NL1ACB5A4D083034"
    ],
    "partShipment": false,
    "movementType": "PIER_TO_PIER",
    "customsStatus": null,
    "customsProcess": "EMPTY_RETURN_PACKAGING",
    "warehouseLicense": null,
    "bulkAuthorisations": [],
    "exchangeRate": {
      "rate": 1.1564,
      "currency": {
        "unCode": "USD",
        "description": "U.S.A.-dollar"
      }
    },
    "consignor": {
      "eoriNumber": null,
      "name": "TUSCOR LLOYDS (UK) LIMITED",
      "address": "16 BLACKMORE ROAD",
      "zipCode": "M32 0QY",
      "city": "MANCHESTER",
      "countryCode": "GB",
      "id": null,
      "ownerShortName": null
    },
    "consignee": {
      "eoriNumber": null,
      "name": "TUSCOR LLOYDS U.K DE MEXICO S.A DE",
      "address": "AV LAS AMERICAS 140 PISO 15 INT",
      "zipCode": "94298",
      "city": "BOCA DEL RIO",
      "countryCode": "MX",
      "id": null,
      "ownerShortName": null
    },
    "partiesToNotify": [
      {
        "eoriNumber": null,
        "name": "TUSCOR LLOYDS U.K DE MEXICO S.A DE",
        "address": "AV LAS AMERICAS 140 PISO 15 INT",
        "zipCode": "94298",
        "city": "BOCA DEL RIO",
        "countryCode": "MX",
        "id": null,
        "ownerShortName": null
      }
    ],
    "placeOfOrigin": null,
    "placeOfDestination": {
      "name": "Veracruz",
      "locationUnCode": "MXVER",
      "countryUnCode": "MX",
      "euLocation": false
    },
    "placeOfDelivery": "ROTTERDAM",
    "onCarriageDetails": null,
    "agentVoyageNumber": "0RTAFS1MA",
    "goodsItems": [
      {
        "itemNumber": 1,
        "description": "Parts and accessories, for tractors, motor vehicles for the",
        "grossWeight": 5211,
        "numberOfOuterPackages": 41,
        "outerPackageType": {
          "code": "CS",
          "name": "Case",
          "bulk": false
        },
        "netWeight": null,
        "numberOfInnerPackages": null,
        "innerPackageType": null,
        "classification": {
          "code": "8708",
          "description": "Parts and accessories of the motor vehicles of headings|8701|to 8705"
        },
        "dangerInformation": null,
        "marksAndNumbers": [],
        "minimumTemperature": null,
        "maximumTemperature": null,
        "placements": [
          {
            "equipmentNumber": "TRHU7170471",
            "numberOfPackages": 41,
            "grossWeight": 5211
          }
        ],
        "producedDocuments": []
      }
    ]
  },
  "crn": "NLRTM21021649",
  "cargoDeclarant": "CMACGMRHN",
  "allowedViewers": [
    "CMACGMRHN"
  ]
};
export const transitCancelledFound = {
  "@class": "com.portbase.bezoekschip.common.api.cargo.common.ConsignmentEntity",
  "consignment": {
    "consignmentNumber": "WECC2163RTM1080",
    "portOfLoading": {
      "name": "London Gateway Port",
      "locationUnCode": "GBLGP",
      "countryUnCode": "GB",
      "euPort": false
    },
    "portOfDischarge": {
      "name": "Rotterdam",
      "locationUnCode": "NLRTM",
      "countryUnCode": "NL",
      "euPort": true
    },
    "actualDeparture": "2021-10-23T22:00:00Z",
    "terminal": {
      "terminalName": "AMALIAH RWG DS 1",
      "terminalCode": "4284",
      "organisationShortName": "RWG",
      "organisationName": "Rotterdam World Gateway (RWG)"
    },
    "movementReferenceNumbers": [
      "21NL1ACB5A4D083034"
    ],
    "partShipment": false,
    "movementType": "PIER_TO_PIER",
    "customsStatus": null,
    "customsProcess": "EMPTY_RETURN_PACKAGING",
    "warehouseLicense": null,
    "bulkAuthorisations": [],
    "exchangeRate": {
      "rate": 1.1564,
      "currency": {
        "unCode": "USD",
        "description": "U.S.A.-dollar"
      }
    },
    "consignor": {
      "eoriNumber": null,
      "name": "TUSCOR LLOYDS (UK) LIMITED",
      "address": "16 BLACKMORE ROAD",
      "zipCode": "M32 0QY",
      "city": "MANCHESTER",
      "countryCode": "GB",
      "id": null,
      "ownerShortName": null
    },
    "consignee": {
      "eoriNumber": null,
      "name": "TUSCOR LLOYDS U.K DE MEXICO S.A DE",
      "address": "AV LAS AMERICAS 140 PISO 15 INT",
      "zipCode": "94298",
      "city": "BOCA DEL RIO",
      "countryCode": "MX",
      "id": null,
      "ownerShortName": null
    },
    "partiesToNotify": [
      {
        "eoriNumber": null,
        "name": "TUSCOR LLOYDS U.K DE MEXICO S.A DE",
        "address": "AV LAS AMERICAS 140 PISO 15 INT",
        "zipCode": "94298",
        "city": "BOCA DEL RIO",
        "countryCode": "MX",
        "id": null,
        "ownerShortName": null
      }
    ],
    "placeOfOrigin": null,
    "placeOfDestination": {
      "name": "Veracruz",
      "locationUnCode": "MXVER",
      "countryUnCode": "MX",
      "euLocation": false
    },
    "placeOfDelivery": "ROTTERDAM",
    "onCarriageDetails": null,
    "agentVoyageNumber": "0RTAFS1MA",
    "goodsItems": [
      {
        "itemNumber": 1,
        "description": "Parts and accessories, for tractors, motor vehicles for the",
        "grossWeight": 5211,
        "numberOfOuterPackages": 41,
        "outerPackageType": {
          "code": "CS",
          "name": "Case",
          "bulk": false
        },
        "netWeight": null,
        "numberOfInnerPackages": null,
        "innerPackageType": null,
        "classification": {
          "code": "8708",
          "description": "Parts and accessories of the motor vehicles of headings|8701|to 8705"
        },
        "dangerInformation": null,
        "marksAndNumbers": [],
        "minimumTemperature": null,
        "maximumTemperature": null,
        "placements": [
          {
            "equipmentNumber": "MSKU0608450",
            "numberOfPackages": 41,
            "grossWeight": 5211
          }
        ],
        "producedDocuments": []
      }
    ]
  },
  "crn": "NLRTM21021649",
  "cargoDeclarant": "CMACGMRHN",
  "allowedViewers": [
    "CMACGMRHN"
  ]
};

export const consignmentForBulk1 = {
  "consignment": {
    "consignmentNumber": "ONEY183108302",
    "portOfLoading": {
      "name": "Antwerpen",
      "locationUnCode": "BEANR",
      "countryUnCode": "BE",
      "euPort": true
    },
    "portOfDischarge": {
      "name": "Rotterdam",
      "locationUnCode": "NLRTM",
      "countryUnCode": "NL",
      "euPort": true
    },
    "actualDeparture": "2022-06-20T10:00:00Z",
    "terminal": {
      "terminalName": "EEMH RST ZZ",
      "terminalCode": "1222",
      "organisationShortName": "RST",
      "organisationName": "Rotterdam Shortsea Terminal"
    },
    "movementReferenceNumbers": [],
    "partShipment": false,
    "movementType": null,
    "customsStatus": null,
    "customsProcess": null,
    "warehouseLicense": null,
    "bulkAuthorisations": [],
    "exchangeRate": null,
    "consignor": {
      "eoriNumber": "EO123456",
      "name": "Blokker",
      "address": "blokkerstraat",
      "zipCode": "3000AA",
      "city": "Rotterdam",
      "countryCode": "NL",
      "id": "4af5210c-2ea5-4dea-a3b7-5273fc029d4c",
      "ownerShortName": "CROSSOCEANRTM"
    },
    "consignee": null,
    "partiesToNotify": [],
    "placeOfOrigin": null,
    "placeOfDestination": null,
    "placeOfDelivery": null,
    "onCarriageDetails": null,
    "agentVoyageNumber": null,
    "goodsItems": [
      {
        "itemNumber": 1,
        "description": "kolen",
        "grossWeight": 5000,
        "numberOfOuterPackages": 1,
        "outerPackageType": {
          "code": "VO",
          "name": "Bulk, solid, large particles",
          "bulk": true
        },
        "netWeight": 4500,
        "numberOfInnerPackages": null,
        "innerPackageType": null,
        "classification": null,
        "dangerInformation": null,
        "marksAndNumbers": [],
        "minimumTemperature": null,
        "maximumTemperature": null,
        "placements": [],
        "producedDocuments": []
      }
    ],
    "generated": false,
    "bulk": true
  },
  "crn": "NLRTM22901652",
  "cargoDeclarant": "CROSSOCEANRTM",
  "allowedViewers": [
    "CROSSOCEANRTM"
  ]
}

export const consignmentForBulk2 = {
  "@class": "com.portbase.bezoekschip.common.api.cargo.common.ConsignmentEntity",
  "consignment": {
    "consignmentNumber": "ONEY183108301",
    "portOfLoading": {
      "name": "Antwerpen",
      "locationUnCode": "BEANR",
      "countryUnCode": "BE",
      "euPort": true
    },
    "portOfDischarge": {
      "name": "Rotterdam",
      "locationUnCode": "NLRTM",
      "countryUnCode": "NL",
      "euPort": true
    },
    "actualDeparture": "2022-06-20T10:00:00Z",
    "terminal": {
      "terminalName": "EEMH RST ZZ",
      "terminalCode": "1222",
      "organisationShortName": "RST",
      "organisationName": "Rotterdam Shortsea Terminal"
    },
    "movementReferenceNumbers": [],
    "partShipment": false,
    "movementType": null,
    "customsStatus": null,
    "customsProcess": null,
    "warehouseLicense": null,
    "bulkAuthorisations": [],
    "exchangeRate": null,
    "consignor": {
      "eoriNumber": "EO123456",
      "name": "Blokker",
      "address": "blokkerstraat",
      "zipCode": "3000AA",
      "city": "Rotterdam",
      "countryCode": "NL",
      "id": "4af5210c-2ea5-4dea-a3b7-5273fc029d4c",
      "ownerShortName": "CROSSOCEANRTM"
    },
    "consignee": null,
    "partiesToNotify": [],
    "placeOfOrigin": null,
    "placeOfDestination": null,
    "placeOfDelivery": null,
    "onCarriageDetails": null,
    "agentVoyageNumber": null,
    "goodsItems": [
      {
        "itemNumber": 2,
        "description": "Water",
        "grossWeight": 2000,
        "numberOfOuterPackages": 1,
        "outerPackageType": {
          "code": "VL",
          "name": "Bulk, liquid",
          "bulk": true
        },
        "netWeight": 1000,
        "numberOfInnerPackages": null,
        "innerPackageType": null,
        "classification": null,
        "dangerInformation": null,
        "marksAndNumbers": [],
        "minimumTemperature": null,
        "maximumTemperature": null,
        "placements": [],
        "producedDocuments": []
      },
      {
        "itemNumber": 1,
        "description": "olie",
        "grossWeight": 5000,
        "numberOfOuterPackages": 1,
        "outerPackageType": {
          "code": "VL",
          "name": "Bulk, liquid",
          "bulk": true
        },
        "netWeight": 2000,
        "numberOfInnerPackages": null,
        "innerPackageType": null,
        "classification": null,
        "dangerInformation": null,
        "marksAndNumbers": [],
        "minimumTemperature": null,
        "maximumTemperature": null,
        "placements": [],
        "producedDocuments": []
      }
    ],
    "generated": false,
    "bulk": true
  },
  "crn": "NLRTM22901652",
  "cargoDeclarant": "CROSSOCEANRTM",
  "allowedViewers": [
    "CROSSOCEANRTM"
  ]
}
export const consignmentForBulk3 = {
  "@class": "com.portbase.bezoekschip.common.api.cargo.common.ConsignmentEntity",
  "consignment": {
    "consignmentNumber": "ONEY183108303",
    "portOfLoading": {
      "name": "Antwerpen",
      "locationUnCode": "BEANR",
      "countryUnCode": "BE",
      "euPort": true
    },
    "portOfDischarge": {
      "name": "Rotterdam",
      "locationUnCode": "NLRTM",
      "countryUnCode": "NL",
      "euPort": true
    },
    "actualDeparture": "2022-06-20T10:00:00Z",
    "terminal": {
      "terminalName": "EEMH RST ZZ",
      "terminalCode": "1222",
      "organisationShortName": "RST",
      "organisationName": "Rotterdam Shortsea Terminal"
    },
    "movementReferenceNumbers": [],
    "partShipment": false,
    "movementType": null,
    "customsStatus": null,
    "customsProcess": null,
    "warehouseLicense": null,
    "bulkAuthorisations": [],
    "exchangeRate": null,
    "consignor": {
      "eoriNumber": "EO123456",
      "name": "Blokker",
      "address": "blokkerstraat",
      "zipCode": "3000AA",
      "city": "Rotterdam",
      "countryCode": "NL",
      "id": "4af5210c-2ea5-4dea-a3b7-5273fc029d4c",
      "ownerShortName": "CROSSOCEANRTM"
    },
    "consignee": null,
    "partiesToNotify": [],
    "placeOfOrigin": null,
    "placeOfDestination": null,
    "placeOfDelivery": null,
    "onCarriageDetails": null,
    "agentVoyageNumber": null,
    "goodsItems": [
      {
        "itemNumber": 2,
        "description": "Water",
        "grossWeight": 2000,
        "numberOfOuterPackages": 1,
        "outerPackageType": {
          "code": "VL",
          "name": "Bulk, liquid",
          "bulk": true
        },
        "netWeight": 1000,
        "numberOfInnerPackages": null,
        "innerPackageType": null,
        "classification": null,
        "dangerInformation": null,
        "marksAndNumbers": [],
        "minimumTemperature": null,
        "maximumTemperature": null,
        "placements": [],
        "producedDocuments": []
      },
      {
        "itemNumber": 1,
        "description": "olie",
        "grossWeight": 5000,
        "numberOfOuterPackages": 1,
        "outerPackageType": {
          "code": "VL",
          "name": "Bulk, liquid",
          "bulk": true
        },
        "netWeight": 2000,
        "numberOfInnerPackages": null,
        "innerPackageType": null,
        "classification": null,
        "dangerInformation": null,
        "marksAndNumbers": [],
        "minimumTemperature": null,
        "maximumTemperature": null,
        "placements": [],
        "producedDocuments": []
      }
    ],
    "generated": false,
    "bulk": true
  },
  "crn": "NLRTM22901660",
  "cargoDeclarant": "CROSSOCEANRTM",
  "allowedViewers": [
    "CROSSOCEANRTM"
  ]
}


export const consignments = [consignment1, consignment1SameContainer, consignment2, consignment3, consignmentCustomsProcessNone,
  consignmentCustomsProcessSeaInSeaOut, consignmentCustomsProcessMilitary, consignmentCustomsProcessEmptyReturnPackaging, transitCancelledFound,
  consignmentForBulk1, consignmentForBulk2, consignmentForBulk3]
