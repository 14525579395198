import {Declaration, DeclarationType} from "@portbase/bezoekschip-service-typescriptmodels";
import {cloneObject} from "../common/utils";

export class DeclarationUtils {

  static getFirstDeclarations(declarations: Declaration[], types: DeclarationType[], skip?: (Declaration) => boolean) : Declaration[] {
    let firstDeclarations = [];
    declarations.forEach(declaration => {
      if(skip && skip(declaration)){
        return;
      }
      if (types.indexOf(declaration.type) > -1){
        if(firstDeclarations.findIndex(d => declaration.type === d.type && declaration.id === d.id) === -1){
          firstDeclarations.push(declaration)
        }
      }
    })
    return firstDeclarations;
  }

  static getLastDeclarations(declarations: Declaration[], types: DeclarationType[]) : Declaration[] {
    const reverse = cloneObject(declarations).reverse();
    return DeclarationUtils.getFirstDeclarations(reverse, types);
  }
}
