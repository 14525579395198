<div class="row align-items-center">
  <div class="col-3">
    <h5 class="text-portbase-blue m-0">
      <ng-container *ngFor="let visitStatus of getVisitStatusIcon(summary.visitStatus)">
        <span class="fa {{visitStatus.icon}} {{visitStatus.color}}" [title]="visitStatus.tooltip"></span>
      </ng-container>
      {{summary.vesselName | titlecase}}
    </h5>
    <div class="d-none d-lg-block"><span class="small">{{summary.crn}}</span></div>
  </div>
  <div class="col-3">
    <h5 class="text-portbase-blue m-0">
      {{summary.collectorShortName}}
    </h5>
    <div *ngIf="appContext.isAdmin()" class="d-none d-lg-block fst-italic">
      <span class="small">{{summary.shipOperatorName}}</span>
    </div>
  </div>
  <div class="col-5 small">{{formatBerth(summary)}}</div>
  <div class="col-1 text-end spread px-0" style="min-width: 5em">
    <ng-container *ngIf="summary.collections[0]">
          <span class="fa fas fa-truck-loading text-portbase-green me-1"
                [title]="'Registered collection'"></span>
      <span class="fa fas fa-calendar-check me-1"
            [ngClass]="hasOpenCollections(summary.collections) ? 'text-muted' : 'text-portbase-green'"
            [title]="hasOpenCollections(summary.collections) ? '' :  'Completed collection'"></span>
      <span class="fa fas fa-money-check-alt me-1"
            [ngClass]="hasOpenFinancials(summary.collections) ? 'text-muted' : 'text-portbase-green'"
            [title]="hasOpenFinancials(summary.collections) ? '' : 'Submitted financials'"></span>
    </ng-container>
    <ng-container *ngIf="!summary.collections[0]">
      <span class="fa fas fa-truck-loading text-muted me-1"></span>
      <span class="fa fas fa-calendar-check text-muted me-1"></span>
      <span class="fa fas fa-money-check-alt text-muted me-1"></span>
    </ng-container>
  </div>
</div>
