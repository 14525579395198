<div class="row narrow-gutters mb-2 justify-content-end">
  <div class="col-md-auto" *ngIf="outgoingMessages">
    <span class="btn-group dropup always-enabled" >
        <button class="btn btn-outline-secondary dropdown-toggle" style="min-width: 7rem" type="button"
                data-bs-toggle="dropdown">Outgoing
          <span class="caret"></span>
        </button>
        <ul class="dropdown-menu dropdown-menu-end">
          <ng-container *ngFor="let message of outgoingMessages">
              <li *ngIf="message.status.processId" (click)="openMessages(message.status.processId)"
                  class="dropdown-item">{{message.name}}{{message.to ? ' to ' + message.to : ' to NCTS'}}</li>
          </ng-container>
        </ul>
    </span>
  </div>
  <div class="col-md-auto" *ngIf="incomingMessages">
    <span class="btn-group dropup always-enabled">
        <button class="btn btn-outline-secondary dropdown-toggle" style="min-width: 7rem" type="button"
                data-bs-toggle="dropdown">Incoming
          <span class="caret"></span>
        </button>
        <ul class="dropdown-menu dropdown-menu-end">
          <ng-container *ngFor="let message of incomingMessages">
              <li *ngIf="message.status.processId" (click)="openMessages(message.status.processId)"
                  class="dropdown-item">{{message.name}}{{message.from ? ' from ' + message.from : ' from NCTS'}}</li>
          </ng-container>
        </ul>
    </span>
  </div>
  <div class="col-md-auto" *ngIf="adminCommands">
    <span class="btn-group dropup always-enabled">
        <button class="btn btn-outline-secondary dropdown-toggle" style="min-width: 7rem" type="button"
                data-bs-toggle="dropdown">Admin commands
          <span class="caret"></span>
        </button>
        <div class="dropdown-menu dropdown-menu-end">
            <ng-container *ngFor="let command of adminCommands">
              <button class="dropdown-item" data-bs-toggle="modal" data-bs-target="#adminCommand"
                      (click)="selectAdminCommand(command)">{{command.name}}</button>
            </ng-container>
        </div>
    </span>
  </div>
</div>

<div id="adminCommand" class="modal fade" tabindex="-1" role="dialog" *ngIf="adminCommand">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{adminCommand.name}}</h5>
      </div>
      <div class="modal-body">

        <div class="row">
          <div class="col-md">
            <app-form-group *ngIf="adminCommand['@class'].endsWith('AcceptTransit')" label="Mrn">
              <input class="form-control" required placeholder="Enter Mrn" [(ngModel)]="adminCommand.mrn">
            </app-form-group>
            <app-form-group *ngIf="adminCommand['@class'].indexOf('Reject')!==-1
            || adminCommand['@class'].endsWith('StopTransitRelease')
            || adminCommand['@class'].endsWith('RegisterTransitInsufficientGuarantee')"
                            label="Reason">
              <input class="form-control" required placeholder="Enter reason" [(ngModel)]="adminCommand.reason">
            </app-form-group>

            <app-form-group *ngIf="adminCommand['@class'].endsWith('RequestProofOfArrival')" label="Requesting office">
              <app-search [(ngModel)]="adminCommand.requestingOffice"
                          [searchFunction]="refData.findTransitOffices" [resultFormatter]="refData.transitOfficesFormatter"
                          [inputFormatter]="refData.transitOfficesFormatter" [minCharacters]="0">
              </app-search>
            </app-form-group>

            <app-form-group *ngIf="adminCommand['@class'].endsWith('RegisterTransitArrival')" label="Actual destination">
              <input class="form-control" required placeholder="Enter code" [(ngModel)]="adminCommand.actualDestinationCode">
            </app-form-group>

            <app-form-group *ngIf="adminCommand['@class'].endsWith('WriteOffTransit')" label="Clearance code">
              <input class="form-control" required placeholder="Enter code" [(ngModel)]="adminCommand.clearanceCode">
            </app-form-group>

            <ng-container *ngIf="adminCommand['@class'].endsWith('ReleaseTransit')">

              <app-form-group label="Inspection type">
                <app-select [(ngModel)]="adminCommand.inspectionStatus.type"
                            [options]="refData.transitInspectionTypes" [formatter]="refData.enumFormatter">
                </app-select>
              </app-form-group>
              <app-form-group label="Inspection date">
                <app-date-field [(ngModel)]="adminCommand.inspectionStatus.inspectionDate"></app-date-field>
              </app-form-group>
              <app-form-group label="Expiry date">
                <app-date-field  [dateOnly]="true" [(ngModel)]="adminCommand.inspectionStatus.inspectionExpiryDate"></app-date-field>
              </app-form-group>
              <app-form-group label="Inspector name">
                <input class="form-control" placeholder="Enter inspector name" [(ngModel)]="adminCommand.inspectionStatus.inspectorName">
              </app-form-group>
              <app-form-group label="Binding itinerary">
                  <app-yes-no [(ngModel)]="adminCommand.inspectionStatus.bindingItinerary"></app-yes-no>
              </app-form-group>
              <app-form-group label="Authorisation nr">
                <input class="form-control" placeholder="Enter authorisation number" [(ngModel)]="adminCommand.inspectionStatus.authorisationNumber">
              </app-form-group>

            </ng-container>
          </div>
        </div>

        <p>Are you sure that you want to {{adminCommand.name}} as admin?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary me-auto" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="sendAdminCommand()">Send</button>
      </div>
    </div>
  </div>
</div>
