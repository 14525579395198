import {AfterContentInit, Component, ContentChild, Input, OnChanges, SimpleChanges} from '@angular/core';
import {TidalWindowStatus} from '@portbase/bezoekschip-service-typescriptmodels';
import moment from 'moment';
import {DateFieldComponent} from "../../../common/date/date-field/date-field.component";

@Component({
  selector: 'app-tidal-window',
  templateUrl: './tidal-window.component.html',
  styleUrls: ['./tidal-window.component.css']
})
export class TidalWindowComponent implements AfterContentInit, OnChanges {

  @Input() level: 'error' | 'warning' = 'warning';
  @Input() status: TidalWindowStatus;
  @ContentChild(DateFieldComponent) timeComponent : DateFieldComponent;

  ngAfterContentInit() {
    setTimeout(() => this.refresh(), 0);
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (this.timeComponent) {
      this.refresh()
    }
  }

  refresh() {
    this.timeComponent.status = this.timeInWindow() ? null : this.level;
  }

  private timeInWindow() : boolean {
    const status = this.status && this.status.tidalShip && this.status;
    if (status) {
      const timestamp = moment(this.timeComponent.timestamp);
      return this.status.tidalWindows.length === 0
        || this.status.tidalWindows.some(w => timestamp.isBetween(w.start, w.end, undefined, '[]'));
    }
    return true;
  }
}
