import {Component, EventEmitter, Output} from '@angular/core';
import {removeItem} from '../../../../common/utils';

@Component({
  selector: 'app-cargo-filter',
  templateUrl: './cargo-filter.component.html',
  styleUrls: ['./cargo-filter.component.css']
})
export class CargoFilterComponent implements CargoFilter {
  options: CargoSelection[] = ['Reefer', 'Dangerous', 'Empty',  'Overlanded', 'Shortlanded', 'Transhipment', 'Inspection', 'Discharged', 'Differences'];
  selectedOptions: CargoSelection[] = [];
  deselectedOptions: CargoSelection[] = [];

  @Output() changed = new EventEmitter<CargoFilter>();

  isOn(option: any): boolean {
    return this.selectedOptions.some(o => option === o);
  }

  isOff(option: any): boolean {
    return this.deselectedOptions.some(o => option === o);
  }

  isToggled(option: any): boolean {
    return this.isOn(option) || this.isOff(option);
  }

  toggleOn(option: CargoSelection) {
    removeItem(this.deselectedOptions, option);
    const existingOption = this.selectedOptions.find(o => option === o);
    if (existingOption) {
      removeItem(this.selectedOptions, option);
    } else {
      this.selectedOptions.push(option);
    }
    this.changed.emit(this);
  }

  toggleOff(option: CargoSelection) {
    removeItem(this.selectedOptions, option);
    const existingOption = this.deselectedOptions.find(o => option === o);
    if (existingOption) {
      removeItem(this.deselectedOptions, option);
    } else {
      this.deselectedOptions.push(option);
    }
    this.changed.emit(this);
  }

  reset(option : CargoSelection) {
    removeItem(this.selectedOptions, option);
    removeItem(this.deselectedOptions, option);
    this.changed.emit(this);
  }

  resetAll() {
    this.selectedOptions = [];
    this.deselectedOptions = [];
    this.changed.emit(this);
  }
}

export type CargoSelection = 'Reefer' | 'Dangerous' | 'Empty' | 'Overlanded' | 'Shortlanded' | 'Transhipment' | 'Inspection' | 'Discharged' | 'Differences';

export interface CargoFilter {
  selectedOptions: CargoSelection[];
  deselectedOptions: CargoSelection[];
}
