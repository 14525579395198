import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppContext} from '../../app-context';
import {VisitContext} from '../visit-context';
import {Router} from '@angular/router';
import {PortvisitUtils} from '../../refdata/portvisit-utils';
import {CargoLoadingModel} from "./cargo-loading.model";

@Component({
  selector: 'app-cargo-loading',
  templateUrl: './cargo-loading.component.html',
  styleUrls: ['./cargo-loading.component.scss']
})
export class CargoLoadingComponent implements OnInit, OnDestroy {

  initializeForCargoAgent = CargoLoadingModel.initializeForCargoAgent;

  appContext = AppContext;
  context = VisitContext;
  utils = PortvisitUtils;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
    if (AppContext.isAdmin()) {
      CargoLoadingModel.initializeForCargoAgent(VisitContext.savedVisit.cargoDeclarants.map(c => c.shortName).find(c => !!c));
    } else {
      this.router.navigateByUrl('/details/' + VisitContext.savedVisit.crn);
    }
  }

  ngOnDestroy() {
    delete VisitContext.cargoLoadingModel;
  }
}
