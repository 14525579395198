import {Component, Input, OnInit} from '@angular/core';
import {TransitLogItem} from '@portbase/bezoekschip-service-typescriptmodels';
import {VisitContext} from "../../../visit-details/visit-context";
import {ComparatorChain} from "../../../common/comparator-chain";
import {sendQuery} from "../../../common/utils";

@Component({
  selector: 'app-transit-history',
  templateUrl: './transit-history.component.html',
  styleUrls: ['./transit-history.component.scss']
})
export class TransitHistoryComponent implements OnInit {

  context = VisitContext;
  items: TransitLogItem[] = [];
  reverseOrder = true;
  @Input() lrn: string;

  private logComparator = new ComparatorChain("timestamp").compare;

  ngOnInit(): void {
    sendQuery("com.portbase.bezoekschip.common.api.history.GetTransitLog", {lrn: this.lrn},
      {showSpinner: true, caching: false})
      .subscribe((items: TransitLogItem[]) => this.items = items.sort(this.logComparator));
  }

  cast = (item: TransitLogItem): TransitLogItem => item;

  toggleOrder() {
    this.reverseOrder = !this.reverseOrder;
  }
}
