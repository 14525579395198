<div class="col-md-auto ms-auto" *ngIf="getOutgoingMessages()">
    <span class="btn-group dropup mx-1 always-enabled">
        <button class="btn btn-outline-secondary dropdown-toggle" style="min-width: 7rem" type="button"
                data-bs-toggle="dropdown">Outgoing
        <span class="caret"></span></button>
        <ul class="dropdown-menu dropdown-menu-end">
          <ng-container *ngFor="let message of getOutgoingMessages()">
              <li *ngIf="message.id" (click)="openMessages(message.id)"
                  class="dropdown-item"
                  style="text-transform: none;">{{message.name}}{{message.to ? ' to ' + message.to : ' to NCTS'}}</li>
          </ng-container>
        </ul>
    </span>
</div>
