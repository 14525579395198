<div class="fieldset" [ngClass]="{'disabled' :disabled}">
  <ng-template #content>
    <div class="row g-0 pb-1 mb-2 border-bottom" *ngIf="enableSelectAll && options.length > 0">
      <div class="col-sm-6 col-xl-4">
        <div class="form-check">
          <input [checked]="options.length === selectedValues.length" (click)="toggleSelectAll()"
            class="form-check-input" type="checkbox" [id]="id + '-' + 'selectAll'">
          <label class="form-check-label" [attr.for]="id + '-' + 'selectAll'">
            Select all
          </label>
        </div>
      </div>
    </div>
    <div class="row g-0">
      <div *ngFor="let option of options; let index = index" class="col-sm-6 col-xl-4">
        <div class="form-check">
          <input [checked]="isSelected(option)" (click)="toggleOption(option)" class="form-check-input" type="checkbox"
            [id]="id + '-' + index">
          <label class="form-check-label" [attr.for]="id + '-' + index">
            {{formatter(option)}}
          </label>
        </div>
      </div>
    </div>
  </ng-template>

  <div *ngIf="hideButton ; else showButton">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>

  <ng-template #showButton>
    <ng-container *ngIf="!horizontal">
      <button onPopover [popoverContent]="content" popoverClass="multiselect-container"
        [ngClass]="{'btn-invalid': required && selectedValues.length === 0}" popoverTitle="{{title}}"
        [id]="id + '-toggle'" type="button" [placement]="placement" class="btn btn-info col-4">
        Select
      </button>
    </ng-container>
  </ng-template>
</div>
<ng-container *ngIf="!hideSelected">
  <ng-container *ngIf="horizontal">
    <div class="d-flex">
      <div *ngIf="!hideButton">
        <button onPopover [popoverContent]="content" popoverClass="multiselect-container"
          [ngClass]="{'btn-invalid': required && selectedValues.length === 0}" popoverTitle="{{title}}"
          [id]="id + '-toggle'" type="button" [placement]="placement" class="btn btn-info me-2">
          <i class="fa fa-plus" aria-hidden="true"></i>
        </button>
      </div>
      <div>
        <span *ngFor="let option of selectedValues" class="badge text-bg-light text-dark me-1">{{formatter(option)}}</span>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!horizontal">
    <div *ngFor="let option of selectedValues" class="text-muted border p-1 my-2 rounded">{{formatter(option)}}</div>
  </ng-container>
</ng-container>
