import {Pipe, PipeTransform} from '@angular/core';
import {lodash} from './utils';

@Pipe({
  name: 'startCase'
})
export class StartCasePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    return value && lodash.startCase(value);
  }

}
