<div [ngClass]="{'always-enabled' : appContext.hasRole('VisitDeclarant') && !context.visit.cancelled
  && !(context.findLatestDeclaration(VISIT)?.status === 'DECLARED')}">

<div class="row g-0 bg-secondary text-light py-2">
    <div class="col">
      <span style="padding-left: 36px" class="required">Location</span>
    </div>
    <div class="col-3 ps-2 d-none d-md-block" style="min-width: 15rem">
      <app-info>
        <span class="required">Arrival</span>
        <span class="tooltipContent">Make sure the first next port is at the top. At least one next port is required for sending.</span>
      </app-info>
    </div>
    <div class="col-3 ps-2 d-none d-xl-block" style="min-width: 15rem">
      Departure
    </div>
    <div class="col-3 ps-2 d-none d-xl-flex">
      Customs office
    </div>
    <div class="col-1 text-end d-xl-none" style="min-width: 5.5rem">
    </div>
  </div>

  <div dragula="nextPorts" [(dragulaModel)]="nextPorts" (dragulaModelChange)="onDragulaModelChanges($event)" >
    <div *ngFor="let nextPort of nextPorts; let idx = index">
      <div class="row g-0 my-2 validatable">
        <div class="col">
          <app-search [ngModel]="nextPort.port" [searchFunction]="searchPort" [inputFormatter]="portInputFormatter"
                      (ngModelChange)="$event ? selectNextPort(nextPort, $event) : null"
                      required [id]="'nextPort-port-' + idx" placeholder="Find port" dataKey="name">
            <span class="input-group-prepend input-group-text drag-handle grabbable bg-light border-0 drag-handle"><span
                class="fa fa-map-marker-alt text-portbase-blue drag-handle"></span></span>
          </app-search>
        </div>

        <div class="col-3 ps-2 d-none d-md-block" style="min-width: 15rem">
          <app-date-field [required]="idx===0" [ngModel]="nextPort.arrival" defaultTime="12:00"
                         (ngModelChange)="context.shiftTime(nextPort, 'arrival', $event)"
                         [calendarStartDate]="nextPort.departure"></app-date-field>
        </div>

        <div class="col-3 ps-2 d-none d-xl-block" style="min-width: 15rem">
          <app-date-field [ngModel]="nextPort.departure" defaultTime="12:00"
                         (ngModelChange)="context.shiftTime(nextPort, 'departure', $event)"
                         [calendarStartDate]="nextPort.arrival"></app-date-field>
        </div>

        <div class="col-3 ps-2 d-none d-xl-flex">
          <app-select [(ngModel)]="nextPort.customsOffice"
                      [refreshProvider]="getCustomsOfficeProvider" [refreshWatch]="nextPort.port"
                      dataKey="name" [id]="'nextPort-customsOffice-' + idx" class="input-group">
          </app-select>
          <button class="btn ms-2 pe-0" type="button" title="Delete port" (click)="deleteNextPort(nextPort)"
                  [id]="'nextPort-delete-' + idx" tabindex="-1"><span class="fa fa-trash text-secondary"></span>
          </button>
        </div>

        <div class="col-1 text-end d-xl-none" style="min-width: 5.5rem">
          <label class="btn btn-info ms-2" [id]="'nextPort-details-' + idx"
                 [attr.data-bs-target]="'#nextPort-details-collapse-' + idx"
                 [ngClass]="{'btn-invalid' : nextPort['ngInvalid']}"
                 data-bs-toggle="collapse" onclick="">Details</label>
        </div>

        <div class="col-12 my-2 collapse" [id]="'nextPort-details-collapse-' + idx" [appValidator]="nextPort">
          <div class="card card-body bg-light">
            <div class="card-title row">
              <h5 class="col">{{portInputFormatter(nextPort.port)}}</h5>
              <button (click)="deleteNextPort(nextPort)" [id]="'nextPort-delete-' + idx"
                      class="btn mt-n2 col-auto" type="button" title="Delete port" tabindex="-1"><span
                  class="fa fa-trash text-secondary"></span></button>
            </div>
            <div class="row">
              <div class="col-md-6">
                <app-form-group>
                  <label [attr.for]="'nextPort-arrival-' + idx">Arrival</label>
                  <app-date-field [(ngModel)]="nextPort.arrival" defaultTime="12:00"
                                 [calendarStartDate]="nextPort.departure"
                                 [required]="idx === 0" [id]="'nextPort-arrival-' + idx"></app-date-field>
                </app-form-group>
              </div>
              <div class="col-md-6">
                <app-form-group>
                  <label [attr.for]="'nextPort-departure-' + idx">Departure</label>
                  <app-date-field [(ngModel)]="nextPort.departure" defaultTime="12:00"
                                 [calendarStartDate]="nextPort.arrival"
                                 [id]="'nextPort-departure-' + idx"></app-date-field>
                </app-form-group>
              </div>
              <div class="col-md-6">
                <app-form-group>
                  <label [attr.for]="'nextPort-customsOffice-' + idx">Customs office</label>
                  <app-select [(ngModel)]="nextPort.customsOffice"
                              [refreshProvider]="getCustomsOfficeProvider" [refreshWatch]="nextPort.port"
                              dataKey="name" [id]="'nextPort-customsOffice-' + idx" class="input-group">
                  </app-select>
                </app-form-group>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span *ngIf="portNotChronological(nextPort)" class="ng-invalid invalid-feedback mb-2" style="margin-left: 2.25rem">Departure should be after arrival</span>
      <span *ngIf="twoPortsNotChronological(idx)" class="ng-invalid invalid-feedback mb-2" style="margin-left: 2.25rem">This port should be in chronological order with the port above</span>
    </div>
  </div>

  <div class="text-end mt-3">
    <button (click)="addNextPort()" id="addNextPort" type="button" class="btn btn-info" style="width: 8rem">Add Port
    </button>
  </div>
</div>
