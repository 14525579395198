import {AfterViewInit, Directive, ElementRef, Input, OnDestroy} from '@angular/core';
import {lodash} from './utils';

@Directive({
  selector: '[appValidator]'
})
export class ValidatorDirective implements AfterViewInit, OnDestroy {

  @Input() appValidator: any;
  @Input() validatorProperty: string = "ngInvalid";

  private clickHandler = e => {
    if (this.appValidator[this.validatorProperty]) {
      this.checkValidity();
      return;
    }
    let element = e.target;
    while (!!element && element != document.body) {
      switch (lodash.lowerCase(element.tagName)) {
        case 'a':
        case 'button':
          this.checkValidity();
          return;
      }
      element = element.parentElement;
    }
  };

  constructor(private elementRef: ElementRef) {
  }

  ngAfterViewInit(): void {
    if (this.appValidator) {
      this.elementRef.nativeElement.onchange = () => {
        this.checkValidity();
      };
      this.elementRef.nativeElement.oninput = () => {
        this.checkValidity();
      };
      $(document.body).on('click', this.clickHandler);
      this.checkValidity();
    }
  }

  ngOnDestroy(): void {
    $(document.body).off('click', this.clickHandler);
  }

  private checkValidity = () => {
    setTimeout(() => {
      this.appValidator[this.validatorProperty] = !!this.elementRef.nativeElement.querySelector('.ng-invalid');
    }, 0);
  }
}
