<ng-container *ngIf="showManuallyRegisterTerminalStatus()">
  <div class="dropdown always-enabled">
    <button class="btn btn-secondary" type="button" id="adminHamburgerMenu" data-bs-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
      <i class="fa fa-user-shield" aria-hidden="true"></i>
    </button>
    <div class="dropdown-menu dropdown-menu-end" aria-labelledby="adminHamburgerMenu">
      <a class="dropdown-item" tabindex="-1" role="button" (click)="openAcceptStatusModal()">Mock a Terminal Status</a>
    </div>
  </div>

  <app-register-terminal-status [berthVisit]="berthVisit"></app-register-terminal-status>
</ng-container>
