import {Component, Input} from '@angular/core';
import {OrderStatus} from '@portbase/bezoekschip-service-typescriptmodels';

@Component({
  selector: 'app-order-status',
  templateUrl: './order-status.component.html',
  styleUrls: ['./order-status.component.css']
})
export class OrderStatusComponent {

  @Input()
  orderStatus: OrderStatus;
}
