<div class="timeline" [appValidator]="context.visit.visitDeclaration">
  <!-- Entry path -->
  <div class="d-none d-md-block">
    <!-- Top half -->
    <div class="d-flex" style="width: 150px; height: 20px;">
      <div class="flex-fill timeline-start-quadrant-top-left"
           [ngClass]="{'portbase-blue': context.isArrived() || context.hasDeparted() || (context.isExpected() && afterEtaPort())}"></div>
      <!-- Top Left quadrant -->
      <div class="flex-fill"></div> <!-- Top right quadrant -->
    </div>
    <!-- Bottom half -->
    <div class="d-flex" style="width: 150px; height: 20px;">
      <div class="flex-fill"></div> <!-- Bottom left quadrant -->
      <div class="flex-fill timeline-start-quadrant-bottom-right"
           [ngClass]="{'portbase-blue': context.isArrived() || context.hasDeparted() || (context.isExpected() && afterEtaPort())}"></div>
      <!-- Bottom right quadrant -->
    </div>
  </div>

  <!-- General information -->
  <div class="d-flex">
    <!-- Col 1: empty -->
    <div class="flex-timeline-date-time-column d-none d-md-block"
         [ngClass]="{'text-portbase-blue': context.isArrived() || context.hasDeparted() || (context.isExpected() && afterEtaPort())}">
    </div>
    <!-- Col 2: empty -->
    <div class="flex-fixed-width-timeline-column timeline-line-location"
         [ngClass]="{'text-portbase-blue': context.isArrived() || context.hasDeparted() || (context.isExpected() && afterEtaPort())}">
      <ng-container *ngIf="context.isExpected() && !afterEtaPort()">
        <div class="d-flex justify-content-center align-items-center timeline-icon">
              <span class="fa-stack text-portbase-orange" title="{{context.visit.vessel.name}}">
                <i class="fas fa-circle fa-stack-2x"></i>
                <i class="fas fa-lg fa-ship fa-stack-1x fa-inverse"></i>
              </span>
        </div>
      </ng-container>
    </div>
    <!-- Col 3: Timeline items -->
    <div class="flex-grow-1 mb-2">
      <app-collapse [rounded]="true" [collapse]="readonly ? null : generalInformationCollapse"
                    [showOnLoad]="(!context.visit.terminalPlanningEnabled && !context.hasBeenDeclared()) || (context.visitInTerminalPlanning() && !context.visit.visitDeclaration.arrivalVoyage?.carrier)" [animateOnLoad]="true" [renderWhenCollapsed]="true">
        <div class="row p-3 g-0 align-items-center">
          <div class="col text-muted">
            <i class="fas fa-clipboard-list text-portbase-blue me-3"></i>
            <span
              *ngIf="context.visit.visitDeclaration.clientReferenceNumber">{{context.visit.visitDeclaration.clientReferenceNumber}}</span>
            <span *ngIf="!context.visit.visitDeclaration.clientReferenceNumber">General information</span>
          </div>
          <div class="col-auto d-none d-md-block text-dark">
            <!-- Other icons -->
          </div>
        </div>
      </app-collapse>
      <ng-template #generalInformationCollapse>
        <div class="collapse">
          <div class="p-3 border rounded-bottom mb-3">
            <app-general-information></app-general-information>
          </div>
        </div>
      </ng-template>
    </div>
  </div>

  <!-- Previous ports -->
  <div class="d-flex" *ngIf="!context.visitInTerminalPlanning()">
    <!-- Col 1: empty -->
    <div class="flex-timeline-date-time-column d-none d-md-block mt-3"
         [ngClass]="{'text-portbase-blue': context.isArrived() || context.hasDeparted() || (context.isExpected() && afterEtaPort())}">
      <div class="d-flex align-items-center">
        <ng-container *ngIf="context.hasPreviousPorts()">
          <app-timeline-date-time [dateTime]="context.visit.visitDeclaration.previousPorts[0].departure">
          </app-timeline-date-time>
        </ng-container>
      </div>
    </div>
    <!-- Col 2: empty -->
    <div class="flex-fixed-width-timeline-column timeline-line-location"
         [ngClass]="{'text-portbase-blue': context.isArrived() || context.hasDeparted() || (context.isExpected() && afterEtaPort())}">
      <div class="d-flex justify-content-center align-items-center timeline-icon"
           [ngClass]="{'text-portbase-blue': context.isArrived() || context.hasDeparted() || (context.isExpected() && afterEtaPort())}">
        <span class="fa-stack" title="Previous ports">
          <i class="fas fa-circle fa-stack-2x fa-inverse"></i>
          <i class="fas fa-lg fa-map-marker-alt fa-stack-1x"></i>
        </span>
      </div>
    </div>
    <!-- Col 3: Timeline items -->
    <div class="flex-grow-1 mb-2">
      <app-collapse [rounded]="true" [collapse]="readonly ? null : previousPortsCollapse"
                    [showOnLoad]="!context.hasBeenDeclared()" [animateOnLoad]="true" [renderWhenCollapsed]="true">
        <div class="row p-3 g-0 align-items-center">
          <div class="col">
            <div *ngIf="context.hasPreviousPorts(); then displayPreviousPort else displayDefaultPreviousPortTitle">
            </div>
            <ng-template #displayPreviousPort>
              <span class="fw-bold text-dark">
                {{context.visit.visitDeclaration.previousPorts[0].port.name + ' - '
              + context.visit.visitDeclaration.previousPorts[0].port.locationUnCode}}
              </span>
            </ng-template>
            <ng-template #displayDefaultPreviousPortTitle>
              <span class="fw-bold text-dark">Previous ports</span>
            </ng-template>
            <ng-container [ngSwitch]="context.visit.visitDeclaration.previousPorts.length">
              <span class="ms-3 text-muted d-none d-md-inline">
                <span *ngSwitchCase="0"></span>
                <span *ngSwitchCase="1"></span>
                <span *ngSwitchCase="2">1 other previous port</span>
                <span *ngSwitchDefault>
                  {{(context.visit.visitDeclaration.previousPorts.length - 1) + ' other previous ports'}}
                </span>
              </span>
            </ng-container>
          </div>
          <div class="col-auto d-none d-md-block text-dark">
            <!-- Other icons -->
          </div>
        </div>
      </app-collapse>
      <ng-template #previousPortsCollapse>
        <div class="collapse">
          <div class="row g-0 rounded py-1 align-items-center text-dark">
            <div class="col">
              <app-previous-ports></app-previous-ports>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>

  <!-- Port arrival -->
  <div class="d-flex" *ngIf="!context.visitInTerminalPlanning()">
    <!-- Col 1: empty -->
    <div class="flex-timeline-date-time-column d-none d-md-block mt-3"
         [ngClass]="{'text-portbase-blue': context.isArrived() || context.hasDeparted() || (context.isExpected() && afterEtaPort())}">
      <div class="d-flex align-items-center">
        <ng-container
          *ngIf="context.visit.visitDeclaration.portVisit.portEntry?.etaPilotBoardingPlace ; then pbp else portEntry">
        </ng-container>
        <ng-template #pbp>
          <app-timeline-date-time [dateTime]="context.visit.visitDeclaration.portVisit.portEntry.etaPilotBoardingPlace"
                                  postfix="ETA">
          </app-timeline-date-time>
        </ng-template>
        <ng-template #portEntry>
          <ng-container *ngIf="context.visit.visitDeclaration.portVisit.ataPort ; then arrivedAtPort else notAtPortYet">
          </ng-container>
          <ng-template #arrivedAtPort>
            <app-timeline-date-time [dateTime]="context.visit.visitDeclaration.portVisit.ataPort" postfix="ATA">
            </app-timeline-date-time>
          </ng-template>
          <ng-template #notAtPortYet>
            <app-timeline-date-time [dateTime]="context.visit.visitDeclaration.portVisit.etaPort" postfix="ETA">
            </app-timeline-date-time>
          </ng-template>
        </ng-template>
      </div>
    </div>
    <!-- Col 2: empty -->
    <div class="flex-fixed-width-timeline-column timeline-line-location"
         [ngClass]="{'text-portbase-blue': context.isArrived() || context.hasDeparted() || (context.isExpected() && afterEtaPort())}">
      <div class="d-flex justify-content-center align-items-center timeline-icon actions"
           [ngClass]="{'text-portbase-blue': context.isArrived() || context.hasDeparted() || (context.isExpected() && afterEtaPort())}">
        <app-add-remove-berth *ngIf="!readonly" [isAddingFirstBerth]="true" [isNextBerthVisits]="false">
          <span class="fa-stack" title="Port arrival">
            <i class="fas fa-circle fa-stack-2x fa-inverse"></i>
            <i class="fas fa-lg fa-sign-in-alt fa-stack-1x"></i>
          </span>
        </app-add-remove-berth>
      </div>
    </div>
    <!-- Col 3: Timeline items -->
    <div class="flex-grow-1 mb-2">
      <app-port-arrival></app-port-arrival>
    </div>
  </div>

  <!-- Inbound movement -->
  <div class="d-flex" *ngIf="!context.visitInTerminalPlanning()">
    <!-- Col 1: Order/Cancel movement button -->
    <div class="flex-timeline-date-time-column d-none d-md-block"
         [ngClass]="{'text-portbase-blue': context.isArrived() || context.hasDeparted() || (context.isExpected() && afterEtaPort())}">
      <div class="d-flex pt-1">
        <div class="ms-auto">
          <ng-container
            *ngIf="context.visit.orderIncomingMovement && !context.isWaitingForOrders() && !(context.hasEntryDependency() && context.isAutoOrdered()) && context.isExpected()">
            <button *ngIf="context.visit.visitDeclaration.portVisit.firstMovement.order"
                    class="btn btn-outline-danger btn-sm"
                    (click)="this.context.visit.visitDeclaration.portVisit.firstMovement.order = false">Cancel Order
            </button>
            <button *ngIf="!context.visit.visitDeclaration.portVisit.firstMovement.order"
                    class="btn btn-outline-info btn-sm"
                    (click)="orderIncomingMovement()">Order
            </button>
          </ng-container>
        </div>
      </div>
    </div>
    <!-- Col 2: empty -->
    <div class="flex-fixed-width-timeline-column timeline-line-location"
         [ngClass]="{'text-portbase-blue': context.isArrived() || context.hasDeparted()}">
      <ng-container *ngIf="(context.isExpected() && afterEtaPort())">
        <div class="d-flex justify-content-center align-items-center timeline-icon">
              <span class="fa-stack text-portbase-orange" title="{{context.visit.vessel.name}}">
                <i class="fas fa-circle fa-stack-2x"></i>
                <i class="fas fa-lg fa-ship fa-stack-1x fa-inverse"></i>
              </span>
        </div>
      </ng-container>
    </div>
    <!-- Col 3: Timeline items -->
    <div class="flex-grow-1 mb-2">
      <app-collapse [rounded]="true" [collapse]="readonly ? null : collapseFirstMovement"
        [validationModel]="context.visit.visitDeclaration.portVisit.firstMovement" [showOnLoad]="context.incomingMovementRelevantForAgent()"
        [animateOnLoad]="true" [renderWhenCollapsed]="true">
        <!-- Movement summary (icons: pilot / tugboats / boatment / draft etc. ) -->
        <div class="d-flex p-2 align-items-center">
          <!-- Movement icon -->

          <!-- Order status icon (plane / check / cross / etc.)-->
          <ng-container *ngIf="context.visit.incomingMovementHarbourMasterInfo">
              <span class="me-3">
                <app-order-status [orderStatus]="getStatusOfIncomingOrder(this.context.visit)"></app-order-status>
                <span *ngIf="context.visit.incomingMovementHarbourMasterInfo.remarks"
                      title="{{context.visit.incomingMovementHarbourMasterInfo.remarks.text}}"
                      class="ms-1 fa fa-fw fa-comment-alt text-warning"></span>
                <span *ngIf="context.visit.incomingMovementHarbourMasterInfo.rejectionReason"
                      title="{{context.visit.incomingMovementHarbourMasterInfo.rejectionReason}}"
                      class="ms-1 fa fa-fw fa-comment-alt text-warning"></span>
                <span *ngIf="context.visit.incomingMovementHarbourMasterInfo.movementOnHold" title="Your movement to the first berth has been put on hold by the port authorities and
                        may proceed as conditions allow." class="ms-1 far fa-fw fa-hand-paper text-danger"></span>
              </span>
          </ng-container>
          <div class="d-none d-md-block">
            <!-- Movement duration (to first berth) -->
            <app-movement-duration-summary></app-movement-duration-summary>
            <!-- Draft -->
            <span *ngIf="context.visit.incomingMovementHarbourMasterInfo.vesselDraught ?
            context.visit.incomingMovementHarbourMasterInfo.vesselDraught :
            context.visit.visitDeclaration.portVisit.firstMovement.vesselDraft as draft"
                  title="{{draft}}m vessel draft" class="text-muted me-3">
              <span class="fa fa-fw fa-ruler-vertical me-1"></span>{{draft}}m
            </span>
            <!-- Pilot service -->
            <span *ngIf="context.visit.visitDeclaration.portVisit.firstMovement.pilotService?.required"
                  title="Pilot service" class="text-muted me-3">
              <span class="far fa-fw fa-compass me-1"></span>
              {{context.visit.incomingMovementHarbourMasterInfo?.pilotFromDistance === 'ACCEPTED' ? 'Shore based pilotage (LOA)'
              : context.visit.visitDeclaration.portVisit.firstMovement.pilotService.serviceProvider?.name}}
              <span *ngIf="context.visit.incomingMovementHarbourMasterInfo?.pilotFromDistance === 'ACCEPTED'"
                    class="far fa-fw fa-check me-1 text-portbase-green" title="Shore based pilotage accepted"></span>
              <span *ngIf="context.visit.incomingMovementHarbourMasterInfo?.pilotFromDistance === 'NOT_ACCEPTED'"
                    class="far fa-fw fa-xmark me-1 text-portbase-red" title="Shore based pilotage not accepted"></span>
            </span>
            <span class="me-3">
              <app-tugboats-summary [atArrival]="true"></app-tugboats-summary>
            </span>
            <span>
              <app-boatmen-to-berth-summary></app-boatmen-to-berth-summary>
            </span>
            <app-movement-clearance-status [clearanceKey]="'ekh'" [arrivalBerthVisitId]="firstBerthVisitId"></app-movement-clearance-status>
          </div>

          <!-- Icons right -->
          <div class="ms-auto d-none d-md-block text-dark">
            <!-- Storm pilotage icon-->
            <span class="mx-2" *ngIf="context.isStormPilotageInfoRequiredForPortEntry(context.visit)"
                  [title]="'Storm pilotage information'"><span class="fa fa-fw fa-wind me-1"></span></span>
          </div>
        </div>
      </app-collapse>
      <ng-template #collapseFirstMovement>
        <div class="collapse">
          <div class="fieldset card card-body rounded-0 rounded-bottom mb-3"
               [ngClass]="{'disabled': !this.context.isExpected()}">
            <!--Port entry warnings-->
            <div *ngIf="context.visit.visitDeclaration.portVisit.portEntry as portEntry" class="fieldset">
              <app-port-entry-warnings [additionalIncomingMovementInfo]="context.visit.additionalIncomingMovementInfo"
                                       [incomingMovementHarbourMasterInfo]="context.visit.incomingMovementHarbourMasterInfo"
                                       [entryDependency]="portEntry.origin === 'SEA' ? portEntry.entryDependency : null"
                                       [isRotterdam]="context.visit.portOfCall.port.locationUnCode === 'NLRTM'"
                                       [isAmsterdam]="context.visit.portOfCall.port.locationUnCode === 'NLAMS'"
                                       [pilotStationCode]="context.visit.visitDeclaration.portVisit.pilotStation?.code"
                                       [vesselDraft]="context.visit.visitDeclaration.portVisit.firstMovement.vesselDraft"
                                       [vessel]="context.visit.vessel" [atSea]="portEntry.origin === 'SEA'"
                                       [portEntryIsOrdered]="context.visit.visitDeclaration.portVisit.firstMovement.order"
                                       [requestedEtaPilotBoardingPlace]="portEntry.requestedEtaPilotBoardingPlace"
                                       [etaPilotBoardingPlace]="portEntry.etaPilotBoardingPlace"
                                       [cancellationReason]="context.visit.visitDeclaration.portVisit.firstMovement.cancellationReason">
              </app-port-entry-warnings>
            </div>
            <div class="row">
              <div class="col">
                <app-edit-movement [ngModel]="context.visit.visitDeclaration.portVisit.firstMovement"
                                   id="first-movement">
                </app-edit-movement>
              </div>
            </div>
            <div class="row" *ngIf="context.visit.portOfCall.paDeclarationRequired">
              <div class="declaration-block col-md-12">
                <app-nautical-services-inbound></app-nautical-services-inbound>
              </div>
            </div>
            <div class="row g-0 mt-2">
              <div class="col text-end">
                <button *ngIf="!!berthVisits[0]" class="btn btn-info me-1"
                        (click)="copyMovement()">Copy movement
                </button>
                <button *ngIf="!!berthVisits[0]" class="btn btn-info me-1"
                        (click)="copyNauticalServices()">Copy nautical services
                </button>
                <ng-container
                  *ngIf="context.visit.orderIncomingMovement && !context.isWaitingForOrders() &&
                                !(context.hasEntryDependency() && context.isAutoOrdered()) && context.isExpected()">
                  <button *ngIf="context.visit.visitDeclaration.portVisit.firstMovement.order" class="btn btn-danger"
                          (click)="this.context.visit.visitDeclaration.portVisit.firstMovement.order = false">
                    Cancel Order
                  </button>
                  <button *ngIf="!context.visit.visitDeclaration.portVisit.firstMovement.order" class="btn btn-info"
                          (click)="this.context.visit.visitDeclaration.portVisit.firstMovement.order = true">
                    Order Movement
                  </button>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>

  <!-- Berth visits -->
  <div dragula="berthVisits" [(dragulaModel)]="berthVisits">
    <div
      *ngFor="let berthVisit of berthVisits; let idx = index; let firstBerth = first; let lastBerth = last">
      <!-- BERTH -->
      <div class="d-flex">

        <!-- Col 1: Arrival and departure times -->
        <div class="flex-timeline-date-time-column d-none d-md-block"
             [ngClass]="{'text-portbase-blue': (berthVisit.ata && berthVisit.atd) || berthVisit.atd || anyNextBerthVisitHasAta(idx + 1)}">
          <div class="d-flex align-items-center">
            <div>
              <app-timeline-date-time [dateTime]="arrivalTime(berthVisit)"
                                      [postfix]="berthVisit.ata? 'ATA':'ETA'"></app-timeline-date-time>
              <app-timeline-date-time [dateTime]="departureTime(berthVisit)"
                                      [postfix]="berthVisit.atd? 'ATD':'ETD'"></app-timeline-date-time>
            </div>
          </div>
        </div>
        <!-- Col 2: Timeline line/icon -->

        <div class="flex-fixed-width-timeline-column timeline-line-location drag-handle"
             [ngClass]="{'text-portbase-blue': (berthVisit.ata && berthVisit.atd) || berthVisit.atd || anyNextBerthVisitHasAta(idx +1)}">
          <div class="d-flex justify-content-center align-items-center timeline-icon actions drag-handle"
               [ngClass]="{'text-portbase-blue': (berthVisit.ata && berthVisit.atd) || berthVisit.atd || anyNextBerthVisitHasAta(idx + 1)}">
            <app-add-remove-berth *ngIf="!readonly" [berthVisit]="berthVisit" [isNextBerthVisits]="false" class="drag-handle">
              <app-timeline-icon [shipStatus]="getShipStatus(berthVisit)" class="drag-handle"></app-timeline-icon>
            </app-add-remove-berth>
          </div>
        </div>

        <!-- Col 3: Timeline items -->
        <div class="flex-grow-1 mb-2">
          <app-berth-visit [firstBerth]="firstBerth" [lastBerth]="lastBerth" [isNextBerthVisit]="false"
                           [isRotterdam]="context.visit.portOfCall.port.locationUnCode === 'NLRTM'"
                           [berthVisitInfos]="context.visit.berthVisitInfos[berthVisit.id]" [berthVisit]="berthVisit"
                           [idx]="idx" [readonly]="readonly"
                           [shipStatus]="getShipStatus(berthVisit)">
          </app-berth-visit>
        </div>
      </div>

      <!-- MOVEMENT -->
      <div class="d-flex" *ngIf="!context.visitInTerminalPlanning()">
        <!-- Col 1: empty -->
        <div class="flex-timeline-date-time-column d-none d-md-block pt-2"
             [ngClass]="{'text-portbase-blue': isBetweenBerthsOrDeparting(idx)}">
          <div class="d-flex" *ngIf="context.visit.orderNauticalServices && !!berthVisit.berth &&
          !(lastBerth && this.context.visit.nextDeclarant) && !(berthVisit.atd || anyNextBerthVisitHasAta(idx))">
            <div class="ms-auto">
              <button *ngIf="berthVisit.nextMovement.order" class="btn btn-outline-danger btn-sm"
                      (click)="cancelMovement(berthVisit)">Cancel Order
              </button>
              <button *ngIf="!berthVisit.nextMovement.order" class="btn btn-outline-info btn-sm"
                      (click)="orderMovement(berthVisit)">Order
              </button>
            </div>
          </div>
        </div>
        <!-- Col 2: empty -->
        <div class="flex-fixed-width-timeline-column timeline-line-location"
             [ngClass]="{'text-portbase-blue': isBetweenBerthsOrDeparting(idx)}">
          <ng-container [ngSwitch]="getShipStatus(berthVisit)">
            <div *ngSwitchCase="'MOVING'"
                 class="d-flex justify-content-center align-items-center timeline-icon text-portbase-orange">
              <span class="fa-stack" title="{{context.visit.vessel.name}}">
                <i class="fas fa-circle fa-stack-2x"></i>
                <i class="fas fa-lg fa-ship fa-stack-1x fa-inverse"></i>
              </span>
            </div>
          </ng-container>
        </div>
        <!-- Col 3: Timeline items -->
        <div class="flex-grow-1 mb-2">
          <app-movement-between-berths [berthVisit]="berthVisit" [firstBerth]="firstBerth" [lastBerth]="lastBerth"
                                       [isNextBerthVisits]="false" [idx]="idx">
          </app-movement-between-berths>
        </div>
      </div>

    </div>
  </div>

  <!-- Berth visits next declarant -->
  <ng-container *ngIf="context.isOrganisationNextDeclarant() && context.visit.nextVisitDeclaration">
    <ng-container
      *ngFor="let nextBerthVisit of context.visit.nextVisitDeclaration.nextBerthVisits; let idx = index; let firstBerth = first; let lastBerth = last">

      <!-- BERTH -->
      <div class="d-flex always-enabled">
        <!-- Col 1: Arrival and departure times -->
        <div class="flex-timeline-date-time-column d-none d-md-block">
          <div class="d-flex align-items-center">
            <div>
              <app-timeline-date-time [dateTime]="arrivalTime(nextBerthVisit)"
                                      [postfix]="nextBerthVisit.ata? 'ATA':'ETA'"></app-timeline-date-time>
              <app-timeline-date-time [dateTime]="departureTime(nextBerthVisit)"
                                      [postfix]="nextBerthVisit.atd? 'ATD':'ETD'"></app-timeline-date-time>
            </div>
          </div>
        </div>
        <!-- Col 2: Timeline line/icon -->
        <div class="flex-fixed-width-timeline-column timeline-line-location">
          <div class="d-flex justify-content-center align-items-center text-portbase-orange timeline-icon">
            <app-add-remove-berth *ngIf="!readonly" [berthVisit]="nextBerthVisit" [isNextBerthVisits]="true">
              <app-timeline-icon [shipStatus]="getShipStatus(nextBerthVisit)"></app-timeline-icon>
            </app-add-remove-berth>
          </div>
        </div>
        <!-- Col 3: Timeline items -->
        <div class="flex-grow-1 mb-2">
          <app-berth-visit [firstBerth]="false" [lastBerth]="lastBerth" [isNextBerthVisit]="true"
                           [berthVisit]="nextBerthVisit" [idx]="idx" [shipStatus]="null" [readonly]="readonly">
          </app-berth-visit>
        </div>
      </div>

      <!-- MOVEMENT -->
      <div class="d-flex always-enabled" *ngIf="!context.visitInTerminalPlanning()">
        <!-- Col 1: empty -->
        <div class="flex-timeline-date-time-column d-none d-md-block">
        </div>
        <!-- Col 2: empty -->
        <div class="flex-fixed-width-timeline-column timeline-line-location">
          <ng-container [ngSwitch]="getShipStatus(nextBerthVisit)">
            <div *ngSwitchCase="'MOVING'" class="d-flex justify-content-center align-items-center timeline-icon">
              <span class="fa-stack" title="{{context.visit.vessel.name}}">
                <i class="fas fa-circle fa-stack-2x"></i>
                <i class="fas fa-lg fa-ship fa-stack-1x fa-inverse"></i>
              </span>
            </div>
          </ng-container>
        </div>
        <!-- Col 3: Timeline items -->
        <div class="flex-grow-1 mb-2">
          <app-movement-between-berths [berthVisit]="nextBerthVisit" [firstBerth]="firstBerth" [lastBerth]="lastBerth"
                                       [isNextBerthVisits]="true" [idx]="idx">
          </app-movement-between-berths>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <!-- Port departure -->
  <div class="d-flex">
    <!-- Col 1: empty -->
    <div class="flex-timeline-date-time-column d-none d-md-block mt-3"
         [ngClass]="{'text-portbase-blue': context.hasDeparted()}">
      <div class="d-flex align-items-center">
        <app-timeline-date-time postfix="ETD" *ngIf="!context.visit.visitDeclaration.portVisit.atdPort"
                                [dateTime]="context.isOrganisationNextDeclarant() ? context.visit.nextVisitDeclaration.etdPort : context.visit.visitDeclaration.portVisit.etdPort">
        </app-timeline-date-time>
        <app-timeline-date-time postfix="ATD" *ngIf="context.visit.visitDeclaration.portVisit.atdPort"
                                [dateTime]="context.visit.visitDeclaration.portVisit.atdPort">
        </app-timeline-date-time>
      </div>
    </div>
    <!-- Col 2: empty -->
    <div class="flex-fixed-width-timeline-column timeline-line-location"
         [ngClass]="{'text-portbase-blue': context.hasDeparted()}">
      <div class="d-flex justify-content-center align-items-center timeline-icon"
           [ngClass]="{'text-portbase-blue': context.hasDeparted()}">
        <span class="fa-stack" title="Port exit">
          <i class="fas fa-circle fa-stack-2x fa-inverse"></i>
          <i class="fas fa-lg fa-sign-out-alt fa-stack-1x"></i>
        </span>
      </div>
    </div>
    <!-- Col 3: Timeline items -->
    <div class="flex-grow-1 mb-2">
      <app-port-departure [readonly]="readonly"></app-port-departure>
    </div>
  </div>

  <!--  Next ports (original declarant)-->
  <div class="d-flex" *ngIf="!userIsNextOwnerOrDeclarant() && !context.visitInTerminalPlanning()">
    <!-- Col 1: empty -->
    <div class="flex-timeline-date-time-column d-none d-md-block mt-3"
         [ngClass]="{'text-portbase-blue': context.hasDeparted()}">
      <div class="d-flex align-items-center">
        <ng-container *ngIf="context.hasNextPorts()">
          <app-timeline-date-time [dateTime]="context.visit.visitDeclaration.nextPorts[0].arrival">
          </app-timeline-date-time>
        </ng-container>
      </div>
    </div>
    <!-- Col 2: empty -->
    <div class="flex-fixed-width-timeline-column timeline-line-location"
         [ngClass]="{'text-portbase-blue': context.hasDeparted()}">
      <div class="d-flex justify-content-center align-items-center timeline-icon"
           [ngClass]="{'text-portbase-blue': context.hasDeparted()}">
        <ng-container *ngIf="context.visit.visitDeclaration.portVisit.atdPort">
              <span class="fa-stack arrived" title="{{context.visit.vessel.name}}">
                <i class="fas fa-circle fa-stack-2x"></i>
                <i class="fas fa-lg fa-ship fa-stack-1x fa-inverse"></i>
              </span>
        </ng-container>
        <ng-container *ngIf="!context.visit.visitDeclaration.portVisit.atdPort">
                  <span class="fa-stack" title="Next ports">
          <i class="fas fa-circle fa-stack-2x fa-inverse"></i>
          <i class="fas fa-lg fa-map-marker-alt fa-stack-1x"></i>
        </span>
        </ng-container>
      </div>
    </div>
    <!-- Col 3: Timeline items -->
    <div class="flex-grow-1">
      <app-collapse [rounded]="true" [collapse]="readonly ? null : nextPortsCollapse" [showOnLoad]="!context.hasBeenDeclared()"
                    [animateOnLoad]="true" [renderWhenCollapsed]="true">
        <div class="row p-3 g-0 align-items-center">
          <div class="col">
            <div *ngIf="context.hasNextPorts(); then displayNextPort else displayDefaultNextPortTitle">
            </div>
            <ng-template #displayNextPort>
              <span class="fw-bold text-dark">
                {{context.visit.visitDeclaration.nextPorts[0].port.name + ' - '
              + context.visit.visitDeclaration.nextPorts[0].port.locationUnCode}}
              </span>
            </ng-template>
            <ng-template #displayDefaultNextPortTitle>
              <span class="fw-bold text-dark">Next ports</span>
            </ng-template>
            <ng-container [ngSwitch]="context.visit.visitDeclaration.nextPorts.length">
              <span class="ms-3 text-muted d-none d-md-inline">
                <span *ngSwitchCase="0"></span>
                <span *ngSwitchCase="1"></span>
                <span *ngSwitchCase="2">1 other next port</span>
                <span *ngSwitchDefault>
                  {{(context.visit.visitDeclaration.nextPorts.length - 1) + ' other next ports'}}
                </span>
              </span>
            </ng-container>
          </div>
          <div class="col-auto d-none d-md-block text-dark">
            <!-- Icons placeholder -->
          </div>
        </div>
      </app-collapse>
      <ng-template #nextPortsCollapse>
        <div class="collapse">
          <div class="row g-0 py-1 align-items-center text-dark">
            <div class="col">
              <app-next-ports [(ngModel)]="context.visit.visitDeclaration.nextPorts">
              </app-next-ports>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>

  <!--  Next ports (next declarant)-->
  <div class="d-flex" *ngIf="userIsNextOwnerOrDeclarant() && !context.visitInTerminalPlanning()">
    <!-- Col 1: empty -->
    <div class="flex-timeline-date-time-column d-none d-md-block">
      <div class="d-flex align-items-center">
        <app-timeline-date-time [dateTime]="context.visit.nextVisitDeclaration.nextPorts[0]?.arrival">
        </app-timeline-date-time>
      </div>
    </div>
    <!-- Col 2: empty -->
    <div class="flex-fixed-width-timeline-column timeline-line-location">
      <div class="d-flex justify-content-center align-items-center timeline-icon">
        <span class="fa-stack" title="Next ports">
          <i class="fas fa-circle fa-stack-2x fa-inverse"></i>
          <i class="fas fa-lg fa-map-marker-alt fa-stack-1x"></i>
        </span>
      </div>
    </div>
    <!-- Col 3: Timeline items -->
    <div class="flex-grow-1">
      <app-collapse [rounded]="true" [collapse]="readonly ? null : nextNextPortsCollapse"
                    [showOnLoad]="!context.hasBeenDeclared()" [animateOnLoad]="true"
                    [validationModel]="context.visit.nextVisitDeclaration.nextPorts" [renderWhenCollapsed]="true">
        <div class="row p-3 g-0 align-items-center">
          <div class="col">
            <div *ngIf="(context.visit.nextVisitDeclaration.nextPorts.length > 0
			        && !!context.visit.nextVisitDeclaration.nextPorts[0].port);
			        then displayNextPort else displayDefaultNextPortTitle">
            </div>
            <ng-template #displayNextPort>
              <span class="fw-bold text-portbase-dark">
                {{context.visit.nextVisitDeclaration.nextPorts[0].port.name + ' - '
              + context.visit.nextVisitDeclaration.nextPorts[0].port.locationUnCode}}
              </span>
            </ng-template>
            <ng-template #displayDefaultNextPortTitle>
              <span class="fw-bold text-portbase-dark">Next ports</span>
            </ng-template>
            <ng-container [ngSwitch]="context.visit.nextVisitDeclaration.nextPorts.length">
              <span class="ms-3 text-muted d-none d-md-inline">
                <span *ngSwitchCase="0"></span>
                <span *ngSwitchCase="1"></span>
                <span *ngSwitchCase="2">1 other next port</span>
                <span *ngSwitchDefault>
                  {{(context.visit.nextVisitDeclaration.nextPorts.length - 1) + ' other next ports'}}
                </span>
              </span>
            </ng-container>
          </div>
          <div class="col-auto d-none d-md-block text-dark">
            <!-- Icons placeholder -->
          </div>
        </div>
      </app-collapse>
      <ng-template #nextNextPortsCollapse>
        <div class="collapse">
          <div class="row g-0 rounded py-1 align-items-center text-dark">
            <div class="col">
              <app-next-next-ports [(ngModel)]="context.visit.nextVisitDeclaration.nextPorts">
              </app-next-next-ports>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>

  <!-- Exit path -->
  <div class="d-none d-md-block timeline-exit" style="width: 150px; height: 20px;"
       [ngClass]="{'portbase-blue': context.hasDeparted()}">
  </div>
</div>
