<div class="row">
  <div class="col-md-6">
    <app-form-group><label>Type</label><input class="form-control" [(ngModel)]="containerGood.type" disabled required></app-form-group>
    <app-form-group><label>Id</label><input class="form-control" [(ngModel)]="containerGood['id']" disabled required></app-form-group>
    <app-form-group><label>Name</label><input class="form-control" [(ngModel)]="containerGood.name" required></app-form-group>
    <app-form-group><label>UN code</label><input class="form-control" [(ngModel)]="containerGood.unCode"></app-form-group>
    <app-form-group><label>Hazard class</label><input class="form-control" [(ngModel)]="containerGood.hazardClass"></app-form-group>
    <app-form-group><label>Packing group</label><app-select [(ngModel)]="containerGood.packingGroup" [options]="context.packingGroups"
                                                            [formatter]="context.packingGroupFormatter"></app-select></app-form-group>
  </div>
  <div class="col-md-6">
    <app-form-group><label>Good code</label><input class="form-control" [(ngModel)]="containerGood.goodCode"></app-form-group>
    <app-form-group><label>Properties</label><input class="form-control" [(ngModel)]="containerGood.properties"></app-form-group>
    <app-form-group><label>Ems fire code</label><input class="form-control" [(ngModel)]="containerGood.emsFireCode"></app-form-group>
    <app-form-group><label>Ems spillage code</label><input class="form-control" [(ngModel)]="containerGood.emsSpillageCode"></app-form-group>
    <app-form-group><label>Subsidiary risk</label><input class="form-control" [(ngModel)]="containerGood.subsidiaryRisk"></app-form-group>
    <app-form-group><label>Segregation group</label><input class="form-control" [(ngModel)]="containerGood.segregationGroup"></app-form-group>
  </div>
</div>
