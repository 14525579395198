<ng-container *ngIf="visit.terminalPlanningEnabled && !visitStatusHasPriority else defaultDeclarationStatus">
  <a class="nav-link px-1 px-sm-3 text-white" [routerLink]="link"
     [queryParams]="{eventId: context.eventId}" tabindex="-1" [ngClass]="getCssClasses()">
    <!-- Icon -->
    <i class="fas fa-route" aria-hidden="true" data-bs-toggle="tooltip" title="Visit information"></i>
    <!-- Text (only display on smaller devices with vertical nav menu) -->
    <span class="d-sm-none ms-2 text-dark">
    <ng-content></ng-content>
  </span>
  </a>

</ng-container>
<ng-template #defaultDeclarationStatus>
  <app-declaration-status [status]="visitDeclarationStatus" tooltipTitle="Visit information" icon="fas fa-route"
                          [link]="link">
    Visit Information
  </app-declaration-status>
</ng-template>
