import {AfterViewInit, Component, ElementRef} from '@angular/core';
import {VisitContext} from '../../visit-context';
import {
  BerthVisit,
  Discharge,
  HandlingUnion,
  Inerting,
  Loading,
  Restow,
  StowageUnion,
  Ventilation,
  Washing
} from '@portbase/bezoekschip-service-typescriptmodels';
import {dispatchChangeEvent, removeItem} from '../../../common/utils';
import {downloadDangerousGoods} from '../dangerous-goods.upload';
import {
  BlendingModel,
  DischargeModel,
  HandlingModelUnion,
  InertingModel,
  LoadingModel,
  RestowModel,
  VentilationModel,
  WashingModel
} from '../dangerous-goods.model';
import {DragulaService} from "ng2-dragula";

@Component({
  selector: 'app-handlings',
  templateUrl: './handlings.component.html',
  styleUrls: ['./handlings.component.css']
})
export class HandlingsComponent implements AfterViewInit {
  context = VisitContext;
  downloadExcel = downloadDangerousGoods;

  constructor(private elementRef: ElementRef, private dragulaService: DragulaService) {
  }

  ngAfterViewInit(): void {
    this.dragulaService.find('handlings').options.invalid = (el, handle) => {
      return !handle.classList.contains('drag-handle');
    };
    this.dragulaService.find('handlings').options.moves = () => {
      return !this.context.isVisitReadonly();
    };
    this.dragulaService.dropModel('handlings').subscribe(value => {
      setTimeout(() => {
        const handling = VisitContext.dangerousGoodsDeclaration.dangerousGoods.handlings
          .find(h => h.id === value.item.id);
        handling.berthVisitId = value.target.id;
        dispatchChangeEvent(this.elementRef.nativeElement);
      }, 0);
    });
  }

  berthVisitById(index: number, v: BerthVisit) {
    return v.id;
  }

  handlingById(index: number, h: HandlingModelUnion) {
    return h.id;
  }

  addHandling(type: string, berthVisitId: string) {
    const handling = <HandlingUnion>{type: type, berthVisitId: berthVisitId};
    switch (type) {
      case 'blending':
      case 'loading':
        const loading = <Loading>handling;
        loading.shipToShip = false;
        loading.keepInert = false;
        if (VisitContext.dangerousGoodsDeclaration.dangerousGoods.hasTanks) {
          const stowage = <StowageUnion>{type : 'tank', portOfLoading: VisitContext.visit.portOfCall.port, tankStatus: 'NOT_EMPTY' };
          loading.stowages = [stowage];
        } else {
          loading.stowages = [];
        }
        break;
      case 'discharge':
        const discharge = <Discharge>handling;
        discharge.stowages = [];
        discharge.shipToShip = false;
        discharge.keepInert = false;
        break;
      case 'restow':
        const restow = <Restow>handling;
        restow.restowedStowage = [];
        break;
      default:
        (<Washing | Ventilation | Inerting>handling).tankNumbers = [];
        break;
    }
    handling['newHandling']=true;
    VisitContext.dangerousGoodsDeclaration.dangerousGoods.handlings.push(<any>handling);
    dispatchChangeEvent(this.elementRef.nativeElement);

    setTimeout(() => {
      let theHandling = VisitContext.dangerousGoodsDeclaration.dangerousGoods.handlings.find(value => value['newHandling']);
      let index = VisitContext.dangerousGoodsDeclaration.dangerousGoods.handlings.indexOf(theHandling);
      delete theHandling['newHandling'];
      return $('#handling-details-collapse-' + index).collapse('show');
    }, 0);
  }

  deleteHandling = (handling: HandlingUnion) => {
    removeItem(VisitContext.dangerousGoodsDeclaration.dangerousGoods.handlings, handling);
    dispatchChangeEvent(this.elementRef.nativeElement);
  };
  sortLoadingAndBlending = (handling: DischargeModel | WashingModel | VentilationModel | InertingModel | LoadingModel | BlendingModel | RestowModel) => {
    if (handling.type === 'loading' || handling.type === 'blending') {
      handling.stowages.sort((a, b) => a.stowageNumber < b.stowageNumber ? -1 : 0);
    }
  }
}
