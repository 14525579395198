import {Component, ElementRef, Input} from '@angular/core';
import {RestowModel} from '../../dangerous-goods.model';
import {RestowedStowage} from '@portbase/bezoekschip-service-typescriptmodels';
import {dispatchChangeEvent, removeItem} from '../../../../common/utils';

@Component({
  selector: 'app-edit-restow',
  templateUrl: './edit-restow.component.html',
  styleUrls: ['./edit-restow.component.css']
})
export class EditRestowComponent {
  @Input() handling: RestowModel;

  constructor(private elementRef : ElementRef) {
  }

  stowageById(index: number, s: RestowedStowage) {
    return s.type + s.stowageNumber;
  }

  deleteStowage = (stowage: RestowedStowage) => {
    removeItem(this.handling.restowedStowage, stowage);
    dispatchChangeEvent(this.elementRef.nativeElement);
  };
}
