import {Pipe, PipeTransform} from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'calendar'
})
export class CalendarPipe implements PipeTransform {

  transform(value: any): string {
    return CalendarPipe.format(value);
  }

  static format(value: string): string {
    if (!value) {
      return '';
    }
    return moment(value).calendar({
      sameDay: '[Today] [at] HH:mm',
      nextDay: '[Tomorrow] [at] HH:mm',
      nextWeek: 'dddd [at] HH:mm',
      lastDay: '[Yesterday] [at] HH:mm',
      lastWeek: '[Last] dddd [at] HH:mm',
      sameElse: "DD MMM YYYY [at] HH:mm"
    });
  }
}
