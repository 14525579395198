<div *ngIf="!env.maintenance" id='screen' class="container-fluid vh-100 d-flex flex-column">
  <!-- Top navbar -->
  <div class="row bg-portbase-blue sticky-top" style="z-index: 14000">
    <nav class="page-section col navbar navbar-dark">
      <div class="container-fluid">
        <!-- Portbase logo -->
        <a class="navbar-brand" tabindex="-1">
          <img src="assets/logo-portbase.png" height="30" class="d-inline-block align-top" alt="Portbase Portal">
          <span class="d-none d-sm-inline ms-3 ps-3 border-start">Port Visit</span>
        </a>
      </div>
    </nav>
  </div>

  <div class="flex-grow-1">
    <div class="w-100 h-100 d-flex flex-grow-1 justify-content-center align-items-center">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<div *ngIf="env.maintenance" class="maintenance-wrapper row">
  <router-outlet></router-outlet>
</div>
