<div class="p-3">
  <div class="declaration-block">
    <h2>Encrypt a value</h2>
  </div>
  <app-form-group>
    <label>Value to encrypt</label>
    <input class="form-control" [(ngModel)]="secret" (ngModelChange)="encrypt()">
  </app-form-group>
  <app-form-group>
    <label>Encrypted value</label>
    <input class="form-control" [ngModel]="encrypted" readonly>
  </app-form-group>
</div>
