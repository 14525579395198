import {QueryGateway} from './query-gateway';
import {InjectorProvider} from './injector-provider';
import { Injectable } from "@angular/core";

@Injectable()
export abstract class QueryHandler {
  constructor() {
    const queryGateway = InjectorProvider.injector.get(QueryGateway);
    queryGateway.registerLocalHandler(this);
  }
}
