import {Component, Input, OnInit} from '@angular/core';
import {BerthVisit, BerthVisitInfo, DeclarationType} from '@portbase/bezoekschip-service-typescriptmodels';
import {VisitContext} from '../../visit-context';
import moment from 'moment';
import {PortvisitUtils} from "../../../refdata/portvisit-utils";

@Component({
  selector: 'app-movement-between-berths',
  templateUrl: './movement-between-berths.component.html',
  styleUrls: ['./movement-between-berths.component.scss']
})
export class MovementBetweenBerthsComponent implements OnInit {

  areNauticalServicesApplicable = PortvisitUtils.areNauticalServicesApplicable;
  context = VisitContext;

  @Input()berthVisit: BerthVisit;
  @Input() isNextBerthVisits: boolean;
  @Input() idx: number;
	@Input() firstBerth: boolean;
	@Input() lastBerth: boolean;

  constructor() { }

  ngOnInit() {
  }

	getBerthVisitInfo(): BerthVisitInfo {
		return VisitContext.savedVisit.berthVisitInfos[this.berthVisit.id];
	}

	getOrderStatus(): ("ORDERED" | "CONFIRMED" | "CONFIRMED_NEW_PROPOSAL" | "NOT_ORDERED" | 'REJECTED') {
		const status = this.getBerthVisitInfo();
		const savedBerthVisit = VisitContext.savedVisit.visitDeclaration.portVisit.berthVisits.find(value => value.id === this.berthVisit.id);

		if (status && status.harbourMasterInfo && status.harbourMasterInfo.rejectionReason) {
			return 'REJECTED';
		}
		if (status && status.harbourMasterInfo && status.harbourMasterInfo.etd) {
			return !savedBerthVisit.requestedEtd || moment(savedBerthVisit.requestedEtd)
				.isSame(status.harbourMasterInfo.etd) ? 'CONFIRMED' : 'CONFIRMED_NEW_PROPOSAL';
		}
		if (!savedBerthVisit || !this.context.findLatestDeclaration(DeclarationType.VISIT)
      || !savedBerthVisit.nextMovement.order) {
			return 'NOT_ORDERED';
		}
		return 'ORDERED';
	}

  get getNextBerthVisit(): BerthVisit {
		let berthVisits = [];
		if (this.isNextBerthVisits) {
			berthVisits = this.context.visit.nextVisitDeclaration.nextBerthVisits;
		} else {
			if (this.context.isOrganisationNextDeclarant() && this.isBerthOfTransfer && this.context.visit.nextVisitDeclaration?.nextBerthVisits.length > 0) {
				return this.context.visit.nextVisitDeclaration.nextBerthVisits[0];
			}
			berthVisits = this.context.visit.visitDeclaration.portVisit.berthVisits;
		}
		return berthVisits.indexOf(this.berthVisit) + 1 < berthVisits.length ? berthVisits[this.idx + 1] : null;
	}

  get arrivalBerthVisitId(): string {
    return this.getNextBerthVisit?.id;
  }
  get departureBerthVisitId(): string {
    return this.berthVisit?.id;
  }

  get isBerthOfTransfer(): boolean {
		return this.lastBerth && !this.isNextBerthVisits && !!this.context.visit.nextDeclarant;
	}

	nextBerthVisitHasAta(): boolean {
		let nextBerthVisit = this.context.visit.visitDeclaration.portVisit.berthVisits[this.context.visit.visitDeclaration.portVisit.berthVisits.indexOf(this.berthVisit) + 1];
		return nextBerthVisit && nextBerthVisit.ata != null;
	}
}
