import {
  BerthVisit,
  Blending,
  BreakBulk,
  ChemicalData,
  Container,
  DangerousGoods,
  DangerousGoodsDeclaration,
  Discharge,
  DischargedStowage,
  ExitPoint,
  GoodUnion,
  HandlingUnion,
  Hold,
  Inerting,
  Loading,
  OtherDestination,
  Port,
  Restow,
  RestowedStowage,
  StowageUnion,
  Tank,
  TankStatus,
  Ventilation,
  Washing
} from '@portbase/bezoekschip-service-typescriptmodels';
import {VisitContext} from '../visit-context';
import {
  cast,
  cloneObject,
  isEqual,
  lodash,
  removeIf,
  removeItem,
  replaceItem,
  stripNumber,
  toTitleCase,
  uniqueItems,
  uuid
} from '../../common/utils';
import {formatTimestamp} from '../../common/date/time-utils';
import {ComparatorChain} from '../../common/comparator-chain';

export type HandlingType = 'discharge' | 'washing' | 'ventilation' | 'inerting' | 'loading' | 'blending' | 'restow';
const handlingTypes: HandlingType[] = ['discharge', 'washing', 'ventilation', 'loading', 'blending', 'inerting', 'restow'];
export type StowageType = ('tank' | 'hold' | 'container' | 'breakBulk');
const stowageTypes: StowageType[] = ['tank', 'hold', 'container', 'breakBulk'];
export const goodComparator = new ComparatorChain("goodData.name").compare;

function isEmptyTank(stowage: StowageUnion) {
  if (stowage.type !== 'tank') {
    return false;
  }
  if (!stowage.tankStatus) {
    return false;
  }
  switch (stowage.tankStatus) {
    case 'NOT_EMPTY':
    case 'NOT_EMPTY_INERT':
      return false;
    default:
      return true;
  }
}

function isEmptyTankContainer(s: StowageModelUnion): boolean {
  return s.type === "container" && s.uncleanTankContainer;
}

function isDischargeableWeigth(s: StowageModelUnion): boolean {
  return (s.type === "container" && s.weight >= 0) || (s.type != 'container' && s.weight > 0);
}

function mayBeWashed(stowage: StowageUnion) {
  if (isEmptyTank(stowage)) {
    switch ((<Tank>stowage).tankStatus) {
      case 'RESIDUE_INERT':
      case 'RESIDUE':
        return true;
    }
  }
  return false;
}

function mayBeVentilated(stowage: StowageUnion) {
  if (isEmptyTank(stowage)) {
    switch ((<Tank>stowage).tankStatus) {
      case 'EMPTY_INERT':
      case 'EMPTY_INERT_NOT_GAS_FREE':
      case 'EMPTY_NOT_GAS_FREE':
        return true;
    }
  }
  return false;
}

function isInert(tankStatus: TankStatus): boolean {
  switch (tankStatus) {
    case 'NOT_EMPTY_INERT':
    case 'RESIDUE_INERT':
    case 'EMPTY_INERT_NOT_GAS_FREE':
    case 'EMPTY_INERT':
      return true;
  }
  return false;
}

export function sortStowage(stowage: StowageUnion[]) {
  const containerIndices = new Map<StowageUnion, number>();
  const groupIndices = new Map<string, number>();
  for (let index = 0; index < stowage.length; index++) {
    const s = stowage[index];
    if (s.type === 'container') {
      containerIndices.set(s, index);
      if (groupIndices.get(s.stowageNumber) === undefined) {
        groupIndices.set(s.stowageNumber, index);
      }
    }
  }

  stowage.sort((a, b) => {
    let delta = stowageTypes.indexOf(a.type) - stowageTypes.indexOf(b.type);
    if (delta === 0 && a.type === 'container') {
      if (a.trailer === b['trailer']) {
        delta = groupIndices.get(a.stowageNumber) - groupIndices.get(b.stowageNumber);
        if (delta === 0) {
          delta = containerIndices.get(a) - containerIndices.get(b);
        }
      } else {
        delta = a.trailer === true ? 1 : -1;
      }
    }
    return delta;
  });
}

function keepInert(status: TankStatus, keepInert: boolean): TankStatus {
  if (keepInert === false) {
    switch (status) {
      case 'NOT_EMPTY_INERT':
        return TankStatus.NOT_EMPTY;
      case 'RESIDUE_INERT':
        return TankStatus.RESIDUE;
      case 'EMPTY_INERT_NOT_GAS_FREE':
        return TankStatus.EMPTY_NOT_GAS_FREE;
      case 'EMPTY_INERT':
        return TankStatus.EMPTY;
    }
  }
  return status;
}

export interface DangerousGoodsDeclarationModel extends DangerousGoodsDeclaration {
  dangerousGoods: DangerousGoodsModel;
}

export class DangerousGoodsModel implements DangerousGoods {
  goods: GoodUnion[];
  stowageAtArrival: StowageModelUnion[];
  handlings: HandlingModelUnion[];

  berthVisits: DgBerthVisit[];
  exitPoint: DgExitPoint;
  hasTanks: boolean;

  isBulkOnly: boolean
  loadingOptions: Port[];
  dischargeOptions: Port[];

  constructor(dangerousGoods: DangerousGoods, cargoAgent?: string) {
    this.sanitize(dangerousGoods);
    this.goods = this.formatGoodNames(dangerousGoods.goods);
    this.isBulkOnly = !!this.goods && this.goods.map(good => good.goodData).filter(goodData => !!goodData)
      .every(goodData => goodData.stowageType === 'tank' || goodData.stowageType === 'hold');
    [this.stowageAtArrival, this.berthVisits, this.exitPoint, this.handlings] = DangerousGoodsModel.applyHandlings(dangerousGoods, cargoAgent);
    this.hasTanks = dangerousGoods.stowageAtArrival.some(s => s.type === 'tank');
    this.loadingOptions = uniqueItems(VisitContext.visit.visitDeclaration.previousPorts.map(p => p.port).reverse(), p => p.locationUnCode);
    this.dischargeOptions = uniqueItems(lodash.concat([VisitContext.visit.portOfCall.port],
      VisitContext.visit.visitDeclaration.nextPorts.map(p => p.port)), p => p.locationUnCode);
    if (!!cargoAgent && this.isBulkOnly) {
      this.berthVisits = this.berthVisits.filter(v => VisitContext.canEditBerthVisit(v.id, cargoAgent));
    }
  }

  private formatGoodNames(goods: GoodUnion[]): GoodUnion[] {
    const uniqueNamesMap = createUniqueNamesMap(goods);
    goods.forEach(good => good['uniqueGoodName'] = uniqueNamesMap.get(good.id));
    return goods;

    function createUniqueNamesMap(goods: GoodUnion[]) {
      const uniqueGoodNames: Map<string, string> = new Map();
      const names = [];
      goods.forEach(good => {
        if (good.goodData) {
          let prepending = '';
          const formattedGood = toTitleCase(good.goodData.name);

          if (good.type !== 'containerGood') {
            const duplicateNames = names.filter(value => value === formattedGood);
            if (duplicateNames.length > 0) {
              prepending = '(' + duplicateNames.length + ') ';
            }
            names.push(formattedGood);
          }

          return uniqueGoodNames.set(good.id, prepending + formattedGood);
        }
      });
      return uniqueGoodNames;
    }
  }

  private static applyHandlings(dangerousGoods: DangerousGoods, cargoAgent?: string): [StowageModelUnion[], DgBerthVisit[], DgExitPoint, HandlingModelUnion[]] {
    this.sortHandlings(dangerousGoods.handlings);
    const stowageAtArrival = DangerousGoodsModel.initializeStowageOnArrival(dangerousGoods.stowageAtArrival, dangerousGoods.goods);
    let stowage = stowageAtArrival;
    let portVisit = VisitContext.visit.visitDeclaration.portVisit;
    let berthVisits: DgBerthVisit[];

    berthVisits = cast(portVisit.berthVisits);
    berthVisits = berthVisits.filter(bv => !!bv.berth);
    if (VisitContext.isOrganisationNextDeclarant()) {
      let nextBerthVisits = VisitContext.visit.nextVisitDeclaration.nextBerthVisits;
      let lastActualDgBerthVisit = berthVisits.length === 0 ? [] : [berthVisits[berthVisits.length - 1]];
      berthVisits = lastActualDgBerthVisit.concat(cast(nextBerthVisits));
    }
    berthVisits.forEach(v => {
      const toBeRemoved = [];

      v.stowageAtArrival = stowage;
      v.handlings = (<HandlingModelUnion[]>dangerousGoods.handlings)
        .filter(h => h.berthVisitId === v.id)
        .map(h => {
          const result = this.applyHandling(h, stowage, dangerousGoods.goods, cargoAgent);
          if (!result) {
            toBeRemoved.push(h);
            return null;
          } else {
            stowage = h.stowageAfter;
            h.id = h.id || uuid();
            return h;
          }
        })
        .filter(h => !!h);
      toBeRemoved.forEach(h => removeItem(dangerousGoods.handlings, h));
      v.allowedHandlings = DangerousGoodsModel.computeAllowedHandlings(stowage);
    });

    const exitPoint: DgExitPoint = VisitContext.isOrganisationNextDeclarant()
      ? cast(VisitContext.visit.nextVisitDeclaration.exitPoint || {})
      : cast(portVisit.exitPoint || {});

    exitPoint.stowageAtArrival = stowage;
    let handlings = berthVisits.map(v => v.handlings).reduce((a, b) => a.concat(b), []);

    let washings = ([] as string[]).concat(...handlings.filter(value => value.type == "washing")
        .map(value => (<WashingModel>value).tankNumbers));
    handlings.filter(value => value.type == "discharge").forEach(value => {
      let discharge = (<DischargeModel>value);
      discharge.prewashingRequired =
          discharge.prewashingRequired = discharge.bulk
              .some(value => this.prewashingRequired(value) && washings.indexOf(value.stowageNumber) == -1);
    });

    return [stowageAtArrival, berthVisits, exitPoint, handlings];
  }

  private static prewashingRequired(value: DischargedStowageModel) :boolean {
    return value.needsPrewashing && value.emptied;
  }

  private static applyHandling(handling: HandlingModelUnion, stowage: StowageModelUnion[], goods: GoodUnion[], cargoAgent?: string): HandlingModelUnion {
    handling.stowageBefore = stowage;
    stowage = cloneObject(stowage);
    let result: HandlingModelUnion;
    switch (handling.type) {
      case 'discharge':
        result = this.applyDischarge(handling, stowage, goods);
        break;
      case 'washing':
        result = this.applyWashing(handling, stowage, goods);
        break;
      case 'ventilation':
        result = this.applyVentilation(handling, stowage, goods);
        break;
      case 'inerting':
        result = this.applyInerting(handling, stowage, goods);
        break;
      case 'loading':
      case 'blending':
        result = this.applyLoading(handling, stowage, goods, cargoAgent);
        handling.stowages.forEach(s => {
          const after = stowage.find(s2 => s2.type === s.type && s2.stowageNumber === s.stowageNumber);
          s.emptyTank = after && isEmptyTank(after);
          s.good = goods.find(g => g.id === s.goodId);
          s.previous = handling.stowageBefore.find(s2 => s2.type === s.type && s2.stowageNumber === s.stowageNumber);
          s.allowGoodSelection = allowGoodSelection(s);
          if (s.previous) {
            delete s.previous['previous'];
          }
        });
        break;
      case 'restow':
        result = this.applyRestow(handling, stowage, goods);
        break;
    }

    stowage.forEach(s => {
      s.emptyTank = isEmptyTank(s);
      s.good = goods.find(g => g.id === s.goodId);
      s.previous = handling.stowageBefore.find(s2 => s2.type === s.type && s2.stowageNumber === s.stowageNumber);
      s.allowGoodSelection = allowGoodSelection(s);
      if (s.previous) {
        delete s.previous['previous'];
      }
    });

    handling.stowageAfter = stowage;
    return result;
  }

  /*
    Discharge
   */
  private static applyDischarge(handling: DischargeModel, stowage: StowageModelUnion[], goods: GoodUnion[]): DischargeModel {
    const wasEmpty = handling.stowages.length === 0;
    handling.hasInertTanks = false;

    let options: StowageModelUnion[] = stowage
      .filter(s => isEmptyTankContainer(s) || (isDischargeableWeigth(s) && isPortOfCall(s.portOfDischarge)));

    const availableGoodsBefore = uniqueItems(options.map(s => s.stowageNumber && isDischargeableWeigth(s) && s.goodId && goods.find(g => g.id === s.goodId)).filter(g => g && g.goodData));

    options = uniqueItems(options, s => s.type + s.stowageNumber);

    handling.options = options.map(o => {
      return {
        stowageNumber: o.stowageNumber,
        type: o.type,
        amount: o.weight,
        needsPrewashing: !!(<ChemicalData>o.good?.goodData)?.prewashRequired,
        emptied: true,
        availableBulk: null
      }
    }).sort((o1, o2) => o1.stowageNumber < o2.stowageNumber ? -1 : o2.stowageNumber < o1.stowageNumber ? 1 : 0);

    handling.containersOnly = handling.options.filter(value => value.type === 'container').length === handling.options.length;

    const toBeRemoved = [];

    handling.stowages.forEach(s => {
      const existing = options.find(a => a.type === s.type && a.stowageNumber === s.stowageNumber);
      if (existing) {
        s.availableBulk = s.type === 'tank' || s.type === 'hold' ? existing.weight : undefined;
        s.amount = s.emptied ? undefined : s.amount;
        if (s.amount > s.availableBulk) {
          s.emptied = true;
          s.amount = undefined;
        }
      } else if (s.stowageNumber) {
        toBeRemoved.push(s);
      }
    });

    handling.bulk = handling.stowages.filter(s => s.type === 'tank' || s.type === 'hold')
      .sort((o1, o2) => o1.stowageNumber < o2.stowageNumber ? -1 : o2.stowageNumber < o1.stowageNumber ? 1 : 0);

    toBeRemoved.forEach(s => removeItem(handling.stowages, s));
    if (!wasEmpty && handling.stowages.length === 0) {
      return null;
    }

    const discharged = handling.stowages
      .map(d => {
        let items = stowage.filter(s => s.type === d.type && s.stowageNumber === d.stowageNumber);
        if (!d.emptied && items.length > 0) {
          items = cloneObject(items);
          items.forEach(i => i.weight = d.amount);
        }
        return items;
      }).reduce((a, b) => a.concat(b), []);
    handling.details = groupByGoodOrPosition(discharged, availableGoodsBefore);
    handling.details.sort((a, b) => a.stowageNumbers < b.stowageNumbers ? -1 : 0);
    handling.details = dontRepeatStowages(handling.details);

    handling.stowages.forEach(s => {
      stowage.filter(e => e.stowageNumber === s.stowageNumber).forEach(existing => {
        switch (existing.type) {
          case 'hold':
            existing.weight = s.emptied ? 0 : Math.max(0, stripNumber(existing.weight - s.amount));
            existing.goodId = existing.weight <= 0 ? undefined : existing.goodId;
            if (existing.weight === 0) {
              removeItem(stowage, existing);
            }
            break;
          case 'tank':
            existing.weight = s.emptied ? 0 : Math.max(0, stripNumber(existing.weight - s.amount));
            if (isInert(existing.tankStatus)) {
              handling.hasInertTanks = true;
            }
            const status = keepInert(existing.tankStatus, handling.keepInert);
            existing.tankStatus = existing.weight <= 0 ? isInert(status) ?
              TankStatus.RESIDUE_INERT : TankStatus.RESIDUE : status;
            break;
          default:
            removeItem(stowage, existing);
            break;
        }
      });
    });

    handling.availableGoods = uniqueItems(stowage.map(s => isDischargeableWeigth(s) && s.goodId && (s.type === 'tank' || s.type === 'hold')
      && availableGoodsBefore.find(g => g.id === s.goodId)).filter(g => g && g.goodData))
      .sort((a, b) => a.goodData.name < b.goodData.name ? -1 : a.goodData.name > b.goodData.name ? 1 : 0);

    handling.prewashingRequired = handling.stowages.some(value => this.prewashingRequired(value));
    return handling;

    function dontRepeatStowages(details: HandledStowage[]) {
      let tmp = [];
      return details.reduce((acc, it) => {
        if (isEqual(tmp, it.stowageNumbers)) {
          it.stowageNumbers = [];
        } else {
          tmp = it.stowageNumbers;
        }
        acc.push(it);
        return acc;
      }, []);
    }
  }

  /*
    Washing
   */
  private static applyWashing(handling: WashingModel, stowage: StowageModelUnion[], goods: GoodUnion[]): WashingModel {
    const availableTanks = stowage.filter(s => s.stowageNumber && s.type === 'tank' && s.weight <= 0 && mayBeWashed(s));
    handling.availableTanks = availableTanks.map(s => s.stowageNumber).sort();
    const destionationTanks = stowage.filter(s => s.stowageNumber && isEmptyTank(s));
    handling.destinationTanks = destionationTanks.map(s => s.stowageNumber).sort();

    let otherCargoTank: Tank;
    if (handling.otherDestination === 'OTHER_CARGO_TANK' && handling.otherCargoTank) {
      otherCargoTank = <Tank>stowage.find(value => value.stowageNumber === handling.otherCargoTank);
      if (otherCargoTank) {
        otherCargoTank.tankStatus = isInert(otherCargoTank.tankStatus) ? TankStatus.RESIDUE_INERT : TankStatus.RESIDUE;
      }
    }

    const toBeRemoved = [];
    handling.tankNumbers.forEach(s => {
      const existing = <Tank>availableTanks.find(e => e.stowageNumber === s);
      if (existing) {
        if (handling.mediumOrCommercialWash && handling.otherDestination && handling.otherDestination !== 'SAME_CARGO_TANK') {
          if (otherCargoTank) {
            otherCargoTank.goodId = existing.goodId;
          }
          if (handling.ventilated) {
            existing.goodId = undefined;
            existing.tankStatus = TankStatus.EMPTY;
          } else {
            existing.tankStatus = isInert(existing.tankStatus) ?
              TankStatus.EMPTY_INERT_NOT_GAS_FREE : TankStatus.EMPTY_NOT_GAS_FREE;
          }
        }
      } else if (s) {
        toBeRemoved.push(s);
      }
    });

    toBeRemoved.forEach(s => removeItem(handling.tankNumbers, s));
    if (handling.tankNumbers.length === 0) {
      handling.details = []
      return handling;
    }
    handling.details = [{
      stowageNumbers: handling.tankNumbers.sort(), details: handling.timestamp
        ? "Starts at: " + formatTimestamp(handling.timestamp) : ""
    }];
    return handling;
  }

  /*
    Ventilation
   */
  private static applyVentilation(handling: VentilationModel, stowage: StowageModelUnion[], goods: GoodUnion[]): VentilationModel {
    const availableTanks = stowage.filter(s => s.stowageNumber && s.type === 'tank' && s.weight <= 0
      && mayBeVentilated(s));
    handling.availableTanks = availableTanks.map(s => s.stowageNumber).sort();

    const toBeRemoved = [];
    handling.tankNumbers.forEach(s => {
      const existing = <Tank>availableTanks.find(e => e.stowageNumber === s);
      if (existing) {
        existing.goodId = undefined;
        existing.tankStatus = TankStatus.EMPTY;
      } else if (s) {
        toBeRemoved.push(s);
      }
    });

    toBeRemoved.forEach(s => removeItem(handling.tankNumbers, s));
    if (handling.tankNumbers.length === 0) {
      handling.details = []
      return handling;
    }
    handling.details = [{
      stowageNumbers: handling.tankNumbers.sort(), details: handling.timestamp
        ? "Starts at: " + formatTimestamp(handling.timestamp) : ""
    }];
    return handling;
  }

  /*
    Inerting
   */
  private static applyInerting(handling: InertingModel, stowage: StowageModelUnion[], goods: GoodUnion[]): InertingModel {
    const availableTanks = stowage.filter(s => s.stowageNumber && s.type === 'tank' && !isInert(s.tankStatus));
    handling.availableTanks = availableTanks.map(s => s.stowageNumber).sort();

    const toBeRemoved = [];
    handling.tankNumbers.forEach(s => {
      const existing = <Tank>availableTanks.find(e => e.stowageNumber === s);
      if (existing) {
        existing.tankStatus = makeInert(existing.tankStatus);
      } else if (s) {
        toBeRemoved.push(s);
      }
    });

    toBeRemoved.forEach(s => removeItem(handling.tankNumbers, s));
    if (handling.tankNumbers.length === 0) {
      handling.details = []
      return handling;
    }
    handling.details = [{stowageNumbers: handling.tankNumbers.sort(), details: ""}];
    return handling;

    function makeInert(tankStatus: TankStatus): TankStatus {
      switch (tankStatus) {
        case 'NOT_EMPTY':
          return TankStatus.NOT_EMPTY_INERT;
        case 'RESIDUE':
          return TankStatus.RESIDUE_INERT;
        case 'EMPTY_NOT_GAS_FREE':
          return TankStatus.EMPTY_INERT_NOT_GAS_FREE;
        case 'EMPTY':
          return TankStatus.EMPTY_INERT;
      }
      return tankStatus;
    }
  }

  /*
    Loading and blending
   */
  private static applyLoading(handling: LoadingModel | BlendingModel, stowage: StowageModelUnion[], goods: GoodUnion[], cargoAgent?: string): LoadingModel | BlendingModel {
    const isNew = handling.stowages.length === 0;
    let availableTanks = stowage.filter(s => s.stowageNumber && s.type === 'tank');
    if (handling.type === 'blending') {
      availableTanks = availableTanks.filter(s => s.weight > 0);
    }
    handling.availableTanks = availableTanks.map(s => s.stowageNumber).sort();
    handling.hasInertTanks = false;

    const toBeRemoved = [];
    handling.stowages.forEach(s => {
      const existing = availableTanks.find(e => e.type === s.type && e.stowageNumber === s.stowageNumber);
      if (existing) {
        removeItem(handling.availableTanks, s.stowageNumber);
        if (s.type === 'tank') {
          const inert = isInert((<Tank>existing).tankStatus);
          if (inert) {
            handling.hasInertTanks = true;
          }
          s.tankStatus = inert && (handling.keepInert === undefined || handling.keepInert) ?
            TankStatus.NOT_EMPTY_INERT : TankStatus.NOT_EMPTY;
        }
        s = cloneObject(s);
        if (existing.weight > 0) {
          s.weight = Number(existing.weight) + Number(s.weight);
        }
        replaceItem(stowage, existing, s);
      } else {
        if (s.type === 'tank') {
          (<Tank>s).tankStatus = TankStatus.NOT_EMPTY;
        }
        stowage.push(s);
        if (s.type === 'tank' && s.stowageNumber && !VisitContext.visitHasBeenTransferred() && !VisitContext.isOrganisationNextDeclarant()) {
          toBeRemoved.push(s);
          return;
        }
      }
    });

    toBeRemoved.forEach(s => removeItem(handling.stowages, s));
    if (!isNew && handling.stowages.length === 0) {
      return null;
    }
    handling.details = groupByGoodOrPosition(handling.stowages, goods);
    return handling;
  }

  /*
    Restow
   */
  private static applyRestow(handling: RestowModel, stowage: StowageModelUnion[], goods: GoodUnion[]): RestowModel {
    const isNew = handling.restowedStowage.length === 0;

    handling.options = stowage
      .filter(s => s.type === 'container' || s.type === 'breakBulk')
      .sort((o1, o2) => o1.type < o2.type ? -1 : o1.type < o2.type ? 1
        : o1.stowageNumber < o2.stowageNumber ? -1 : o2.stowageNumber < o1.stowageNumber ? 1 : 0)
      .map(o => {
        return <RestowedStowage>{
          stowageNumber: o.stowageNumber,
          type: o.type
        }
      });


    const toBeRemoved = [];
    handling.restowedStowage.forEach(s => {
      const existing = stowage.find(e => e.type === s.type && e.stowageNumber === s.stowageNumber);
      if (existing) {
        existing['position'] = s.newPosition;
      } else {
        toBeRemoved.push(s);
      }
    });

    toBeRemoved.forEach(s => removeItem(handling.restowedStowage, s));
    if (!isNew && handling.restowedStowage.length === 0) {
      return null;
    }
    const groupedByPosition = lodash.groupBy(handling.restowedStowage.filter(s => s.type === 'container'), "newPosition");
    handling.details =
      Object.keys(groupedByPosition).map(position => {
        const stowage: any[] = groupedByPosition[position];
        return {
          stowageNumbers: uniqueItems(stowage.map(s => s.stowageNumber)).sort((a, b) => a < b ? -1 : 0),
          details: position === 'undefined' ? '' : position
        }
      });

    return handling;
  }

  private static computeAllowedHandlings(stowage: StowageModelUnion[]): HandlingType[] {
    let result: HandlingType[] = [];
    result.push('loading');
    stowage.forEach(s => {
      if (isEmptyTankContainer(s) || (isDischargeableWeigth(s) && isPortOfCall(s.portOfDischarge))) {
        result.push('discharge');
      }
      if (s.type === 'tank') {
        if (!isInert(s.tankStatus)) {
          result.push('inerting');
        }
        if (s.weight) {
          result.push('blending');
        } else {
          if (mayBeWashed(s)) {
            result.push('washing');
          }
          if (mayBeVentilated(s)) {
            result.push('ventilation');
          }
        }
      } else if (s.type === 'container' || s.type === 'breakBulk') {
        result.push('restow');
      }
    });
    result = uniqueItems(result);
    return result.sort((a, b) => handlingTypes.indexOf(a) - handlingTypes.indexOf(b));
  }

  private static sortHandlings = (handlings: HandlingUnion[]) => {
    const berthVisits = VisitContext.visit?.visitDeclaration?.portVisit?.berthVisits;

    if (!!berthVisits) {
      const berthVisitIds = berthVisits.map(v => v.id);
      handlings.sort((h1, h2) => {
        const delta = berthVisitIds.indexOf(h1.berthVisitId) - berthVisitIds.indexOf(h2.berthVisitId);
        return delta === 0 ? handlingTypes.indexOf(h1.type) - handlingTypes.indexOf(h2.type) : delta;
      });
    }
  };

  private static initializeStowageOnArrival = (stowages: StowageUnion[], goods: GoodUnion[]): StowageModelUnion[] => {
    const result = <StowageModelUnion[]>stowages;
    result.forEach(s => {
      s.emptyTank = isEmptyTank(s);
      s.good = goods.find(g => g.id === s.goodId);
      s.allowGoodSelection = allowGoodSelection(s)
    });
    return result;
  };

  private sanitize = (dangerousGoods: DangerousGoods) => {
    getAllStowage().forEach(s => s.weight = isEmptyTank(s) ? 0 : s.weight);
    removeUnusedGoods();
    sanitizeChemicals();
    padTankNumbers();
    addMissingBreakBulkNumbers();
    removeUnknownStowage();
    sanitizeNewGoods();
    sanitizeWeights();

    function getAllStowage(): StowageUnion[] {
      let stowage = <StowageUnion[]>dangerousGoods.stowageAtArrival;
      dangerousGoods.handlings.forEach(h => {
        switch (h.type) {
          case 'loading':
          case 'blending':
            stowage = stowage.concat(h.stowages);
        }
      });
      return stowage;
    }

    function sanitizeNewGoods() {
      const goodIds = dangerousGoods.goods.map(g => g.id);
      getAllStowage().forEach(s => {
        // remove new good if known
        if (s['newGood'] && s.goodId && goodIds.indexOf(s.goodId) >= 0) {
          delete s['newGood'];
        }

        // remove unknown good ids
        if (s.goodId && goodIds.indexOf(s.goodId) == -1) {
          s.goodId = null;
        }
      })
    }

    function sanitizeWeights() {
      getAllStowage().forEach(s => {
        s.weight = round(s.weight, 3);
        switch (s.type) {
          case 'breakBulk':
          case 'container':
            s.netWeight = round(s.netWeight, 3);
            s.netExplosiveMass = round(s.netExplosiveMass, 3);
        }
      });
    }

    function sanitizeChemicals() {
      dangerousGoods.goods.forEach(g => g.type === 'chemical' && g.viscosity <= 50 ? g.criticalTemperature = null : null);
    }

    function padTankNumbers() {
      let stowage = <StowageUnion[]>dangerousGoods.stowageAtArrival;
      dangerousGoods.handlings.forEach(h => {
        switch (h.type) {
          case 'loading':
          case 'blending':
            stowage = stowage.concat(h.stowages);
        }
      });
      stowage.forEach(s => {
        if (s.type === 'tank' && s.stowageNumber) {
          s.stowageNumber = pad(('' + s.stowageNumber).toUpperCase());
        }
      });

      function pad(val): string {
        let offset = Math.min(2, positionOfFirstNonDigit(val));
        let numericPart = val.substr(0, offset);
        return ('00' + numericPart).substr(-2) + val.substr(offset);
      }

      function positionOfFirstNonDigit(val) {
        let splitted = lodash.split(val, '');
        for (let i = 0; i < val.length; ++i) {
          if (isNaN(Number(splitted[i]))) {
            return i;
          }
        }
        return val.length;
      }
    }

    function addMissingBreakBulkNumbers() {
      let stowage = <StowageUnion[]>dangerousGoods.stowageAtArrival;
      dangerousGoods.handlings.forEach(h => {
        switch (h.type) {
          case 'loading':
          case 'blending':
            stowage = stowage.concat(h.stowages);
        }
      });
      let last: number = 0;
      stowage.forEach(s => {
        if (s.type === 'breakBulk' && s.stowageNumber) {
          last = Math.max(Number(s.stowageNumber), last);
        }
      });
      stowage.forEach(s => {
        if (s.type === 'breakBulk' && !s.stowageNumber) {
          s.stowageNumber = String(++last);
        }
      });
    }

    function removeUnusedGoods() {
      removeIf(dangerousGoods.goods, g => {
        return !isGoodUsed(dangerousGoods.stowageAtArrival, g.id) && !dangerousGoods.handlings
          .filter(h => h.type === 'loading' || h.type === 'blending')
          .some(h => isGoodUsed((<Loading>h).stowages, g.id));
      });

      function isGoodUsed(stowages: StowageUnion[], goodId: string): boolean {
        if (!stowages) {
          return false;
        }
        for (const stowage of stowages) {
          if (stowage.goodId === goodId) {
            return true;
          }
        }
        return false;
      }
    }

    function removeUnknownStowage() {
      let known: string[] = dangerousGoods.stowageAtArrival.map(s => s.stowageNumber);
      const handlingsToRemove: HandlingUnion[] = [];
      dangerousGoods.handlings.forEach(h => {
        switch (h.type) {
          case 'blending':
          case 'loading':
            known = known.concat(h.stowages.map(s => s.stowageNumber));
            break;
          case 'discharge':
            if (h.stowages.length > 0) {
              removeIf(h.stowages, s => s.stowageNumber && known.indexOf(s.stowageNumber) < 0);
              if (h.stowages.length === 0) {
                handlingsToRemove.push(h);
              }
            }
            break;
          case 'washing':
          case 'ventilation':
          case 'inerting':
            if (h.tankNumbers.length > 0) {
              removeIf(h.tankNumbers, s => s && known.indexOf(s) < 0);
              if (h.tankNumbers.length === 0) {
                handlingsToRemove.push(h);
              }
            }
            break;
        }
      });
      handlingsToRemove.forEach(h => removeItem(dangerousGoods.handlings, h));
    }
  };
}

function allowGoodSelection(stowage: StowageModelUnion): boolean {
  if (stowage.type !== 'tank') {
    return true;
  }
  if (!stowage.tankStatus) {
    return false;
  }
  switch (stowage.tankStatus) {
    case 'EMPTY':
    case 'EMPTY_INERT':
      return false;
  }
  return true;
}

function isPortOfCall(port: Port) {
  return port && port.locationUnCode === VisitContext.visit.portOfCall.port.locationUnCode;
}

function round(num, decimals) {
  if (!num) {
    return num;
  }
  return +(Math.round(Number((num + "e+" + decimals))) + ("e-" + decimals));
}

function groupByGoodOrPosition(stowage: (StowageUnion | RestowedStowage)[], goods: GoodUnion[]): HandledStowage[] {
  const groupedByPosition = lodash.groupBy(stowage.filter(s => s.type === 'container'), "position");
  const groupedByGood = lodash.groupBy(stowage.filter(s => s.type !== 'container'), "goodId");

  const container: HandledStowage[] = Object.keys(groupedByPosition).map(position => {
    const stowage: any[] = groupedByPosition[position];
    return {
      stowageNumbers: uniqueItems(stowage.map(s => s.stowageNumber)).sort((a, b) => a < b ? -1 : 0),
      details: position
    }
  });

  const nonContainer: HandledStowage[] = Object.keys(groupedByGood).map(goodId => {
    const good = goods.find(g => g.id === goodId);
    const stowage: any[] = groupedByGood[goodId];
    let details = '';
    if (good) {
      const uniqueName = good && good['uniqueGoodName'] ? good['uniqueGoodName'] : '';
      let formattedTotal = '';
      const total = round(lodash.sumBy(stowage, s => Number(s.weight || 0)), 2);
      formattedTotal
        = total ? total + (stowage[0].type === 'tank' || stowage[0].type === 'hold' ? " TNE – " : " kg – ") : "";
      details = formattedTotal + uniqueName || "";
    }
    return {
      stowageNumbers: uniqueItems(stowage.map(s => s.stowageNumber)).sort((a, b) => a < b ? -1 : 0),
      details: details
    }
  });

  nonContainer.sort((a, b) => a.stowageNumbers[0] < b.stowageNumbers[0] ? -1 : 0);

  return container.concat(nonContainer);

}

export interface DgBerthVisit extends BerthVisit {
  stowageAtArrival: StowageModelUnion[];
  handlings: HandlingModelUnion[];
  allowedHandlings: HandlingType[];
}

export interface DgExitPoint extends ExitPoint {
  stowageAtArrival: StowageModelUnion[];
}


//handlings

export interface HandlingModel {
  stowageBefore: StowageModelUnion[];
  stowageAfter: StowageModelUnion[];
  details: HandledStowage[];
  id: string;
}

export interface DischargeModel extends HandlingModel, Discharge {
  options: DischargedStowageModel[];
  availableGoods: GoodUnion[];
  stowages: DischargedStowageModel[];
  bulk: DischargedStowageModel[];
  containersOnly: boolean;
  hasInertTanks: boolean;
  prewashingRequired: boolean;
}

export interface DischargedStowageModel extends DischargedStowage {
  availableBulk: number;
  needsPrewashing: boolean;
}

export interface WashingModel extends HandlingModel, Washing {
  availableTanks: string[];
  destinationTanks: string[];
  otherDestinationTank: OtherDestination | string;
}

export interface VentilationModel extends HandlingModel, Ventilation {
  availableTanks: string[];
}

export interface InertingModel extends HandlingModel, Inerting {
  availableTanks: string[];
}

export interface LoadingModel extends HandlingModel, Loading {
  availableTanks: string[];
  hasInertTanks: boolean;
  stowages: StowageModelUnion[];
}

export interface BlendingModel extends HandlingModel, Blending {
  availableTanks: string[];
  hasInertTanks: boolean;
  stowages: StowageModelUnion[];
}

export interface RestowModel extends HandlingModel, Restow {
  options: RestowedStowage[];
}

export interface HandledStowage {
  details: string;
  stowageNumbers: string[];
}

export interface StowageModel {
  emptyTank: boolean;
  previous: StowageModelUnion;
  good: GoodUnion;
  allowGoodSelection: boolean;
}

export interface TankModel extends StowageModel, Tank {

}

export interface HoldModel extends StowageModel, Hold {

}

export interface ContainerModel extends StowageModel, Container {

}

export interface BreakBulkModel extends StowageModel, BreakBulk {

}

export type StowageModelUnion = TankModel | HoldModel | ContainerModel | BreakBulkModel;

export type HandlingModelUnion =
  DischargeModel
  | WashingModel
  | VentilationModel
  | InertingModel
  | LoadingModel
  | BlendingModel
  | RestowModel;
