import { Component, Input, OnInit } from '@angular/core';
import { BerthVisit, BoatmenService } from '@portbase/bezoekschip-service-typescriptmodels';
import { VisitContext } from '../../visit-context';
import { ShipMovementService } from '../ship-movement.service';

@Component({
  selector: 'app-boatmen-to-berth-summary',
  templateUrl: './boatmen-to-berth-summary.component.html',
  styleUrls: ['./boatmen-to-berth-summary.component.css']
})
export class BoatmenToBerthSummaryComponent implements OnInit {

  context = VisitContext;

  @Input() currentBerth: BerthVisit;

  constructor(private shipMovementService: ShipMovementService) { }

  ngOnInit() {
  }

  private nextBerth() : BerthVisit {
    if (this.currentBerth) {
      return this.shipMovementService.nextBerth(this.currentBerth);
    } else {
      return this.context.visit.visitDeclaration.portVisit.berthVisits[0];
    }
  }

  get serviceRequired(): boolean {
    return this.service ? this.service.required : false;
  }

  get service(): BoatmenService {
    let nextBerth = this.nextBerth();
    if (nextBerth) {
      return nextBerth.boatmenAtArrival;
    }
  }

  get nextBerthName(): string {
    let nextBerth = this.nextBerth();
    if (nextBerth && nextBerth.berth) {
      return nextBerth.berth.name;
    }
  }
}
