import {environment} from "../../environments/environment";
import {toWebsocketUrl} from "./utils";
import {Injectable, OnDestroy} from "@angular/core";

@Injectable()
export class WebsocketService<UpdateType> implements OnDestroy {
  private socket: WebSocket;

  private endpoint: string;
  private onMessage: (update: UpdateType) => void;
  private retryOnClose: boolean = false;
  private onClose?: (closeEvent: CloseEvent) => void;

  initialise = (endpoint: string, onMessage: (update: UpdateType) => void,
                retryOnClose: boolean = false, onClose?: (closeEvent: CloseEvent) => void) => {
    this.endpoint = endpoint;
    this.onMessage = onMessage;
    this.retryOnClose = retryOnClose;
    this.onClose = onClose;
    this.openWebsocket();
  }

  ngOnDestroy() {
    try {
      console.info("Closing websocket");
      this.socket.onclose = () => {};
      this.socket.close();
      this.socket = null;
    } catch (ignored) {
    }
  }

  private openWebsocket = () => {
    if (environment.production || environment.docker) {
      try {
        this.socket = new WebSocket(toWebsocketUrl(this.endpoint));
        this.subscribeToSocket();
      } catch (e) {
        console.info(`Could not open websocket (${this.endpoint}). Retrying every minute...`, e);
        setTimeout(() => this.openWebsocket(), 60_000);
        return;
      }
    }
  }

  private subscribeToSocket() {
    this.socket.onmessage = this.onMessageReceived;
    this.socket.onclose = this.onSocketClose;
  }

  private onMessageReceived = (message: MessageEvent) => {
    if (typeof message.data === 'string') {
      const update: UpdateType = JSON.parse(message.data);
      if (update) {
        if (!this.onMessage) {
          this.ngOnDestroy();
          return;
        }
        this.onMessage(update);
      }
    }
  }

  private onSocketClose = (event: CloseEvent) => {
    if (!event.wasClean) {
      console.warn(`Websocket closed with reason: ${event.reason} (${event.code}).${this.retryOnClose ? ' Trying to reconnect...' : ''}`);
    }
    if (this.retryOnClose) {
      setTimeout(() => this.openWebsocket(), 5_000);
    } else if (this.onClose) {
      this.onClose(event);
    }
  }
}
