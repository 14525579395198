import {Component, OnInit} from '@angular/core';
import {
  BezoekschipOrganisation,
  FindOrganisations,
  FindTransitParties,
  MergeOrganisationRefdata,
  TransitParty,
  TransitTemplate
} from '@portbase/bezoekschip-service-typescriptmodels';
import {QueryGateway} from "../../common/query-gateway";
import {removeItem, sendCommand, sendQuery} from "../../common/utils";
import {AppContext} from "../../app-context";
import {PortvisitUtils} from "../../refdata/portvisit-utils";

@Component({
  selector: 'app-transit-address-book',
  templateUrl: './transit-address-book.component.html',
  styleUrls: ['./transit-address-book.component.css']
})
export class TransitAddressBookComponent implements OnInit {
  formatter = PortvisitUtils.agentFormatter;
  values: TransitTemplate[] = [];
  appContext = AppContext;
  filterOrganisationShortName: string = '';

  constructor(private queryGateway: QueryGateway) {
  }

  ngOnInit(): void {
    this.reload();
  }

  searchOrganisation = term => sendQuery('com.portbase.bezoekschip.common.api.authorisation.FindOrganisations',
    <FindOrganisations>{term: term});

  setOrganisationName = (organisation: BezoekschipOrganisation) => {
    this.filterOrganisationShortName = organisation ? organisation.shortName : null;
    this.reload();
  };


  reload = () => {
    sendQuery("com.portbase.bezoekschip.common.api.transit.FindTransitParties", <FindTransitParties>{
      term: '',
      maxHits: 10000,
      ownerShortName: this.filterOrganisationShortName
    }).subscribe(values => this.values = values);
  };

  add() {
    this.values.splice(0, 0, <any>{});
  }

  remove(value) {
    return () => {
      removeItem(this.values, value);

      if (value.id) {
        sendCommand('com.portbase.bezoekschip.common.api.refdata.MergeTransitParty', <MergeOrganisationRefdata>{
          value: value,
          id: value.name,
          delete: true
        }, () => {
          AppContext.registerSuccess("Transit template was deleted successfully");
          this.queryGateway.removeFromCache("com.portbase.bezoekschip.common.api.transit.FindTransitParties");
        });
      }
    }
  }

  save() {
    return true;
  }

  onSaved() {
    AppContext.registerSuccess("Transit party was saved successfully");
  }

  trackById(index: number, obj: TransitParty): any {
    return obj.id;
  };
}
