<div class="row sticky-top bg-white" style="top:6.125rem">
  <div class="col-md-12">
    <app-alerting></app-alerting>
  </div>

  <!-- Reject reasons-->
  <div class="col-md-12" *ngIf="context.findLatestDeclaration(DANGEROUS_GOODS)?.rejectReasons as rejectReason">
    <app-status-alert type="danger">{{rejectReason}}</app-status-alert>
  </div>

  <div class="col-md-12">
    <app-action-nav-bar>
      <ng-container>
        <div class="d-flex flex-row-reverse">
          <div class="ms-2">
            <app-dangerous-goods-admin></app-dangerous-goods-admin>
          </div>
        </div>
      </ng-container>
    </app-action-nav-bar>
  </div>
</div>

<div class="row">
  <app-emgs-clearance class="col-md-12"></app-emgs-clearance>
  <div class="declaration-block col-md-12">
    <h2>Dangerous goods declarations</h2>
    <div class="row g-0 bg-secondary text-light py-2">
      <div class="col"><span class="ps-2">Agency</span></div>
      <div class="col-1 text-center d-none d-lg-block">Discharge</div>
      <div class="col-1 text-center d-none d-xl-block">Transit</div>
      <div class="col-1 text-center d-none d-lg-block">Loading</div>
      <div class="col-1 d-none d-lg-block"></div>
      <div class="col-2 text-center d-none d-lg-block">Missing positions</div>
      <div class="col-auto px-2" style="min-width: 8rem"></div>
      <div class="col-auto px-2" style="min-width: 2rem"></div>
    </div>

    <ng-container *ngFor="let declaration of declarations">
      <div class="always-enabled" *ngIf="declaration.dangerousGoodsSummary.hasDeclaration">
      <a
        class="row g-0 my-2 mx-0 border align-items-center text-body py-1"
        [routerLink]="'/details/' + context.visit.crn + '/dangerousGoods/' + declaration.cargoAgent.shortName">
        <div class="col border-0"><span class="ps-2">{{declaration.cargoAgent.fullName}}</span></div>
        <div class="col-1 text-center border-0 d-none d-lg-block">
          {{declaration.dangerousGoodsSummary.discharges}}</div>
        <div class="col-1 text-center border-0 d-none d-xl-block">
          {{declaration.dangerousGoodsSummary.transits}}</div>
        <div class="col-1 text-center border-0 d-none d-lg-block">
          {{declaration.dangerousGoodsSummary.loadings}}</div>
        <div class="col-1 d-none d-lg-block"></div>
        <div class="col-2 text-center border-0 d-none d-lg-block">{{declaration.dangerousGoodsSummary.missingPositions}}</div>

        <div class="col-auto px-2" style="min-width: 8rem">
          <span *ngIf="declaration.verified" class="badge rounded-pill text-bg-success w-100">Declared</span>
          <span *ngIf="declaration.completed && !declaration.verified"
            class="badge rounded-pill text-bg-info w-100">Completed</span>
        </div>
        <div class="col-auto px-2" style="min-width: 2rem">
          <span class="ms-auto fa fa-chevron-right text-secondary"></span>
        </div>
      </a>
      </div>

      <div *ngIf="!declaration.dangerousGoodsSummary.hasDeclaration"
        class="row g-0 my-2 mx-0 border align-items-center py-1">
        <div class="col form-control border-0"><span
            class="ps-2">{{declaration.cargoAgent.fullName}}</span></div>
        <div class="col-auto px-2" style="min-width: 8rem">
          <span class="badge rounded-pill text-bg-secondary w-100">No declaration</span>
        </div>
        <div class="col-auto px-2" style="min-width: 2rem"></div>
      </div>
    </ng-container>


    <div class="fieldset" [ngClass]="context.findLatestDeclaration(DANGEROUS_GOODS)?.status === 'DECLARED' ? 'disabled'
        : context.visitIsDeparted() && !context.isVisitReadonlyIgnoringDeparture() ? 'always-enabled' : ''">
      <div class="row my-3">
        <div class="col-auto ms-auto">
          <button *ngIf="sendAllowed()" (click)="sendDeclaration()" type="button" class="btn btn-info"
            style="width: 16rem">Send completed declarations</button>
        </div>
      </div>
    </div>
  </div>
</div>
