
<ng-container *ngIf="model && !!model.status.infoRequest?.date">
  <app-tooltip class="formGroupLabel" placement="left" [keepOpen]="keepTooltipOpen">
    <span class="fas fa-exclamation-triangle mx-2"  [ngClass]="model.status.infoDeclared?.date? 'text-success' : 'text-warning'"></span>
    <div class="tooltipContent text-start row">
      <ng-container *ngIf="model.status.infoRequestExpirationDate">
        <div class="col text-portbase-grey">Send proof before:</div>
        <div class="col">{{model.status.infoRequestExpirationDate.substring(0,10)}}</div>
        <div class="w-100"></div>
      </ng-container>
      <ng-container>
        <div class="col text-portbase-grey">Investigating office:</div>
        <div class="col">{{model.status.investigatingOffice}}</div>
        <div class="w-100"></div>
      </ng-container>
      <div class="col pt-3" *ngIf="!model.status.infoDeclared?.date">Open the declaration to declare proof</div>
    </div>
  </app-tooltip>
</ng-container>

<ng-container *ngIf="model && hasMrnStatus()">
  <app-tooltip class="formGroupLabel" placement="left" [keepOpen]="keepTooltipOpen">
    <span class="fas fa-file-download mx-2"  [ngClass]="model.status.mrnAccept ? 'text-success' : model.status.mrnReject ? 'text-danger' : 'text-info'"></span>
    <div class="tooltipContent text-start row">
      <ng-container *ngIf="model.status.mrnSent">
        <div class="col text-portbase-grey">MRN to terminal:</div>
        <div class="col" *ngIf="model.status.mrnSent">{{model.status.mrnSent.date| timestamp}}</div>
        <div class="w-100"></div>
      </ng-container>
      <ng-container *ngIf="model.status.mrnAccept">
        <div class="col text-portbase-grey">Terminal accept:</div>
        <div class="col">{{model.status.mrnAccept.date | timestamp}}</div>
        <div class="w-100"></div>
      </ng-container>
      <ng-container *ngIf="model.status.mrnReject">
        <div class="col text-portbase-grey">Terminal reject:</div>
        <div class="col">{{model.status.mrnReject.date | timestamp}}</div>
        <div class="w-100"></div>
      </ng-container>
      <ng-container *ngIf="model.status.mrnReject">
        <div class="col text-portbase-grey">Reject reason:</div>
        <div class="col">{{model.status.mrnReject.reason}}</div>
        <div class="w-100"></div>
      </ng-container>
    </div>
  </app-tooltip>
</ng-container>

<ng-container *ngIf="model?.data?.carrierRemarks">
  <app-tooltip class="formGroupLabel" placement="left" [keepOpen]="keepTooltipOpen">
    <span class="fas fa-comment-dots mx-2"></span>
    <div class="tooltipContent text-start row">
      <span class="col pt-3">{{model.data.carrierRemarks}}</span>
    </div>
  </app-tooltip>
</ng-container>

<ng-container *ngIf="model && !!model.status.release?.date">
  <app-tooltip *ngIf="model.status.inspectionStatus as status"
               class="formGroupLabel" placement="left" [keepOpen]="keepTooltipOpen">
    <span class="fas fa-info-circle mx-2 text-info"></span>
    <div class="tooltipContent text-start row">
      <ng-container *ngIf="!!model.status.clearanceCode">
        <div class="col text-portbase-grey">Clearance code:</div>
        <div class="col">{{model.status.clearanceCode}}</div>
        <div class="w-100 pb-3"></div>
      </ng-container>
      <ng-container *ngIf="!!model.status.actualDestinationCode">
        <div class="col text-portbase-grey">Actual destination:</div>
        <div class="col">{{model.status.actualDestinationCode}}</div>
        <div class="w-100 pb-3"></div>
      </ng-container>
      <ng-container *ngIf="status.type">
        <div class="col text-portbase-grey">Inspection type:</div>
        <div class="col">{{refData.enumFormatter(status.type)}}</div>
        <div class="w-100"></div>
      </ng-container>
      <ng-container *ngIf="status.inspectionDate">
        <div class="col text-portbase-grey">Inspection date:</div>
        <div class="col">{{status.inspectionDate | timestamp:"DD-MM-YYYY"}}</div>
        <div class="w-100"></div>
      </ng-container>
      <ng-container *ngIf="status.inspectionExpiryDate">
        <div class="col text-portbase-grey">Expiration date:</div>
        <div class="col">{{status.inspectionExpiryDate | timestamp:"DD-MM-YYYY"}}</div>
        <div class="w-100"></div>
      </ng-container>
      <ng-container *ngIf="status.inspectorName">
        <div class="col text-portbase-grey">Inspector name:</div>
        <div class="col">{{status.inspectorName}}</div>
        <div class="w-100"></div>
      </ng-container>
      <ng-container *ngIf="status.bindingItinerary!=null">
        <div class="col text-portbase-grey">Binding itinerary:</div>
        <div class="col">{{status.bindingItinerary? 'Yes' : 'No'}}</div>
        <div class="w-100"></div>
      </ng-container>
      <ng-container *ngIf="status.authorisationNumber">
        <div class="col text-portbase-grey">Authorisation nr.:</div>
        <div class="col">{{status.authorisationNumber}}</div>
        <div class="w-100"></div>
      </ng-container>
    </div>
  </app-tooltip>
</ng-container>

<ng-container *ngIf="showModeOfTransportWarning()">
  <app-tooltip class="formGroupLabel" placement="left" [keepOpen]="keepTooltipOpen">
    <span class="fas fa-exclamation-triangle mx-2 text-warning"></span>
    <div class="tooltipContent text-start row">
      <div class="col pt-3">Transport mode is "{{refData.enumFormatter("NOT_SPECIFIED")}}". This requires a manual call
        to the customs to specify the transport mode in order to proceed
      </div>
    </div>
  </app-tooltip>
</ng-container>

<span *ngIf="phaseType() as type" class="mx-1 text-center"><span class="badge rounded-pill align-text-bottom"
                                                                 [ngClass]="'text-bg-' + type">{{phase() | startCase}}</span></span>

<span *ngIf="model && model.data && model.data.type">
    <span class="badge rounded-pill text-dark text-center align-text-bottom">{{model.data.type?.code}}</span>
</span>
