<div *ngIf="item.goodsPlacements.length > 0">
  <div class="declaration-block my-4">
    <h2>Containers</h2>
  </div>
</div>

<div *ngFor="let placement of item.goodsPlacements" class="my-2">
  <app-collapse #placementCollapse [collapse]="placementTemplate" [showOnLoad]="!placement.containerIdentificationNumber"
                [validationModel]="placement">
    <div class="row pe-3 g-0 align-items-center">
      <div class="col-auto mx-2 p-2">
        <span class="fa fa-fw fa-cube text-dark"></span>
      </div>
      <div class="col text-dark">
        <span class="d-inline-block align-top text-truncate">{{placement.containerIdentificationNumber || 'New container'}}</span>
      </div>
      <div class="col-auto me-n2 d-none d-md-block text-dark">
<!--        <app-cargo-summary [placement]="placement" [keepTooltipOpen]="placementCollapse.showing"></app-cargo-summary>-->
      </div>
    </div>
  </app-collapse>

  <ng-template #placementTemplate>
    <div class="collapse">
      <div class="mx-3 pt-3">
        <div class="row">
          <div class="col-md">
            <app-form-group label="Container number">
              <input [(ngModel)]="placement.containerIdentificationNumber" class="form-control" [disabled]="true">
            </app-form-group>
            <app-form-group label="Outer packages">
              <div class="input-group">
                <input [(ngModel)]="placement.numberOfPackages" appDecimalNumber [maxDecimals]="0" [min]="1"
                       class="form-control" required [disabled]="true">
                <span class="input-group-text">#</span>
              </div>
            </app-form-group>
          </div>
          <div class="col-md">
            <app-form-group label="Gross weight">
              <div class="input-group">
                <input required [(ngModel)]="placement.grossWeight" appDecimalNumber [maxDecimals]="3" class="form-control" [disabled]="true">
                <span class="input-group-text">kg</span>
              </div>
            </app-form-group>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>

<ng-template #newContainer let-container="model"><app-import-container [container]="container" [goodsItem]="item"></app-import-container></ng-template>
