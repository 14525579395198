<div class="row sticky-top bg-white" style="top:6.125rem">
  <div class="col-md-12">
    <app-alerting></app-alerting>
  </div>
</div>

<div class="row narrow-gutters always-enabled mt-3 mb-0">
  <div class="col-auto">
    <app-commercial-release-filter (changed)="applyTransitFilters($event)" [commercialReleases]="getFilteredItems()">
    </app-commercial-release-filter>
  </div>
  <div class="col-md-4" style="min-width: 19rem">
    <app-date-range (ngModelChange)="onDateSelection($event)" [ngModel]="dateRange" [disabled]="!!filterTerm">
    </app-date-range>
  </div>
  <div class="col">
    <app-filter class="mb-4" (resultsFound)="renderFilteredItems()" [(ngModel)]="filterTerm" [searchFunction]="reload"
                placeholder="Filter by B/L nr, container nr, etc..." [appAutoFocus]="true"></app-filter>
  </div>
  <div class="col-auto" *ngIf="appContext.isCommercialReleaseDeclarant()">
    <button (click)="addNewRelease()" class="btn btn-info" tabindex="-1"><span class="pe-2 fa fa-plus-circle"></span>
      New release
    </button>
  </div>
</div>

<ng-container *ngIf="visibleReleases as releases">
  <div class="d-flex flex-column align-items-stretch w-100 position-relative">
    <app-pagination #pagination [items]="commercialReleases" class="accordion" id="releaseAccordion" style="margin-bottom: 80px">
      <ng-container *ngFor="let declaration of pagination.getItemsOnPage(); let index = index; trackBy: trackById">

        <div class="row g-0">
          <div class="col-auto me-2"
               *ngIf="appContext.isCommercialReleaseDeclarant() || appContext.isCommercialReleaseEditor()">
            <div class="form-check form-check-inline m-0 always-enabled" style="height: 2rem">
              <input [(ngModel)]="declaration['selected']" class="always-enabled form-check-input m-0" type="checkbox">
            </div>
          </div>
          <div class="col">
            <app-collapse #releaseCollapse [collapse]="content" [showOnLoad]="isNewDeclaration(declaration)"
                          [deleteHandle]="isNewDeclaration(declaration) && removeNewDeclaration(declaration)">
              <div class="row py-1 px-3 align-items-center text-dark">
                <div class="col text-truncate" title="Equipment number">
                  <span class="fa fa-fw fa-cube"></span>
                  {{isNewDeclaration(declaration) ? 'New declaration' : declaration.releaseData.equipmentNumber }}
                </div>
                <div class="col-2 d-none d-lg-block text-truncate" title="Consignment (B/L) number">
                  <span class="fa fa-fw fa-file-invoice" *ngIf="!!declaration.releaseData.consignmentNumber "></span>
                  {{declaration.releaseData.consignmentNumber }}
                </div>
                <div *ngIf="!appContext.isHinterlandTerminal()"
                     class="col d-none d-md-block small text-secondary text-truncate" title="Terminal">
                  <span class="fa fa-sign-in-alt" *ngIf=" !!declaration.releaseData.terminalShortName"></span>
                  {{declaration.releaseData.terminalShortName}}
                </div>
                <div class="col d-none d-lg-block small text-secondary text-truncate" title="Container operator">
                  <ng-container *ngIf="declaration.releaseData?.containerOperator?.cargoDeclarantShortName &&
                appContext.userProfile.organisation?.shortName !==
                declaration.releaseData?.containerOperator?.cargoDeclarantShortName">
                    <span class="fa fa-ship"></span>
                    {{declaration.releaseData?.containerOperator?.cargoDeclarantShortName}}
                  </ng-container>
                </div>
                <div class="col small text-secondary text-truncate" title="Release-to party">
                  <span class="fa fa-truck" *ngIf="!!declaration.releaseData.releaseToParty"></span>
                  {{declaration.releaseData.releaseToParty?.name }}
                </div>
                <div class="col d-none d-lg-block small text-secondary text-truncate" title="Declaration date">
                  <ng-container *ngIf="declaration.releaseStatus?.declarationDate">
                    <span class="fa fa-paper-plane"></span>
                    {{declaration.releaseStatus.declarationDate | date:'dd-MM-YYYY'}}
                  </ng-container>
                </div>
                <div class="col d-none d-lg-block small text-secondary text-truncate" title="Call reference number">
                  <span class="fas fa-route" *ngIf="!!declaration.crn"></span>
                  {{declaration.crn}}
                </div>
                <div class="col-1 text-end d-none d-md-block text-dark">
                  <app-commercial-release-summary [setModel]="declaration" [keepTooltipOpen]="releaseCollapse.showing">
                  </app-commercial-release-summary>
                </div>
              </div>
            </app-collapse>
          </div>
        </div>

        <ng-template #content>
          <app-commercial-release-declaration [release]="declaration"></app-commercial-release-declaration>
        </ng-template>

      </ng-container>

      <div class="row g-0 text-secondary">
        <div class="col-auto" *ngIf="(appContext.isCommercialReleaseDeclarant() || appContext.isCommercialReleaseEditor())
      && releases.length > 1">
          <label class="mt-3">
            <input [(ngModel)]="releases.length === selectedItems.length"
                   (click)="toggleSelectAll()" class="always-enabled" type="checkbox">
            <span class="ms-2">Select all</span>
          </label>
        </div>
      </div>
    </app-pagination>

    <ng-container *ngIf="selectedItems.length > 0">
      <div class="position-absolute bottom-0 vw-100 row g-0 justify-content-center bg-white py-3 border-top">
        <div class="col-auto mx-1">
          <app-confirmation-toggle>
            <button class="btn btn-outline-danger" style="min-width: 10rem" type="button">Cancel</button>
            <div class="modal-content">
              <div class="modal-header"><h5 class="modal-title">Send cancel request to terminal</h5></div>
              <div class="modal-body"><p>Are you sure that you want to cancel all accepted releases?</p></div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="cancelSelected()">
                  Cancel releases
                </button>
              </div>
            </div>
          </app-confirmation-toggle>
        </div>

        <div class="col-auto mx-1">
          <button class="btn btn-outline-info" style="min-width: 10rem" type="button" (click)="exportSelected()">Export</button>
        </div>
      </div>
    </ng-container>

  </div>

  <div *ngIf="visibleReleases.length === 0" class="text-center text-muted py-2">
    <ng-container *ngIf="items.length > 0; else noReleasesYet">
      No matching releases found.
    </ng-container>
    <ng-template #noReleasesYet>You haven’t added any releases yet.</ng-template>
  </div>
</ng-container>
