<div id="#test1234" class="modal-dialog modal-xl" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Select consignment for transhipment declaration</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
      </button>
    </div>
    <div class="modal-body">
      <app-form-group>
        <label for="consignmentNumber">Consignment number</label>
        <input class="input-group rounded border form-control" id="consignmentNumber"
               [(ngModel)]="consignmentNumber" required/>
      </app-form-group>
      <app-form-group>
        <label for="crn">CRN</label>
        <input class="input-group rounded border form-control" id="crn" [(ngModel)]="crn"/>
      </app-form-group>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary me-auto" (click)="reset()" data-bs-dismiss="modal">Cancel</button>
      <button type="button" class="btn btn-primary" (click)="submit()" data-bs-dismiss="modal">Declare transhipment
      </button>
    </div>
  </div>
</div>
