<ng-container *ngIf="goods && goods[0]">
    <span class="d-inline-block text-truncate align-self-end" [style.max-width.vw]="50">
      <app-highlight [term]="term" [result]="goodsAsText(goods[0]) | middleEllipsis : ellipsisCharacterCount"></app-highlight>
      <button *ngIf="goods.length > 1" onPopover data-bs-toggle="popover" [popoverContent]="goodsPopoverContent" placement="auto"
              popoverClass="pop-auto-container" class="btn btn-link link-dark p-0 ms-2">
        +{{goods.length - 1}}
      </button>
    </span>
</ng-container>


<ng-template #goodsPopoverContent>
  <div class="w-100 h-100 text-start p-1 pe-4" [style.max-width.px]="320">
    <div class="btn-close-wrapper rounded-circle position-absolute end-0 top-0 mt-2 me-2">
      <button type="button" class="btn-close" data-bs-toggle="popover" aria-label="Close"></button>
    </div>
    <div class="row gy-3">
      <ng-container *ngFor="let goodsItem of goods; index as i; trackBy: trackByGoodsItem">
        <div class="col-12">
          <div class="fw-bold">{{goodsItem.commodity?.commodityCode?.harmonizedSystemSubHeadingCode}}{{goodsItem.commodity?.commodityCode?.combinedNomenclatureCode || ''}}</div>
          <span>{{goodsItem.commodity?.descriptionOfGoods}}</span>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
