import {Component} from '@angular/core';
import {$try, sendCommand} from '../../common/utils';
import {VisitContext} from '../visit-context';
import {
  DeclarationType,
  DeclareHealth,
  HealthDeclaration,
  HealthDeclarationSummary,
  SeaEntry
} from '@portbase/bezoekschip-service-typescriptmodels';
import {uploadHealthDeclaration} from './health-declaration.upload';
import {AppContext} from '../../app-context';
import moment from 'moment';

@Component({
  selector: 'app-health-declaration',
  templateUrl: './health-declaration.component.html',
  styleUrls: ['./health-declaration.component.css']
})

export class HealthDeclarationComponent {
  VISIT = DeclarationType.VISIT;

  context = VisitContext;
  appContext = AppContext;
  healthDeclarationXls: HealthDeclaration;
  healthDeclaration: HealthDeclaration;
  warnings: string[] = [];

  upload(file: File) {
    AppContext.clearAlerts();
    uploadHealthDeclaration(file).subscribe(([healthDeclarationXls, healthDeclaration]) => {
      this.healthDeclarationXls = healthDeclarationXls;
      this.healthDeclaration = healthDeclaration;
      AppContext.registerSuccess('Health declaration has been successfully uploaded');
    });
  }

  trySendHealthDeclaration () {
    this.warnings = [];
    if (this.etaPbpOrEtaSeaBoyIsMoreThan24HoursFromNow()) {
      this.warnings.push("ETA Pilot boarding place is more than 24 hours from now. The declaration of health must be sent " +
				"within 24 hours before ETA Pilot Boarding Place. The harbour master might reject this declaration.");
    }

    if (this.etaPbpOrEtaSeaBoyIsLessThan6HoursFromNow()) {
      this.warnings.push("ETA Pilot boarding place is less than 6 hours from now. The declaration of health must be sent at least " +
				"6 hours before ETA Pilot Boarding Place. The harbour master might reject this declaration.");
    }

    if (!this.dateOfFormFillInIsCloseToMastersDeclarationDate()) {
      this.warnings.push("Date of Master's declaration is too old or too far in the future from ETA Pilot boarding place or ETA Sea Buoy")
    }

    if (this.warnings.length === 0) {
      this.sendHealthDeclaration();
    }
  }

  sendHealthDeclaration() {
    this.prepareVesselSurgeonAndAdditionalContactStructure();
    this.warnings = [];
    sendCommand('com.portbase.bezoekschip.common.api.healthdeclaration.DeclareHealth', <DeclareHealth>{
      crn: this.context.visit.crn,
      healthDeclaration: this.healthDeclaration,
      healthDeclarationSummary: toHealthDeclarationSummary(this.healthDeclaration)
    }, () => {
      AppContext.registerSuccess('Maritime declaration of health was sent successfully.');
      this.context.visit.healthDeclarationSummary = toHealthDeclarationSummary(this.healthDeclaration);
      this.context.replaceVisit(this.context.visit);
      this.removeUpload();
    });
  }

  removeUpload() {
    this.healthDeclaration = null;
    this.healthDeclarationXls = null;
  }

  private etaPbpOrEtaSeaBoyIsLessThan6HoursFromNow() {
    const now = moment(new Date());

    let portVisit = this.context.visit.visitDeclaration.portVisit;

    const end = portVisit.portEntry.etaPilotBoardingPlace
               ? moment(portVisit.portEntry.etaPilotBoardingPlace)
               : moment((<SeaEntry> portVisit.portEntry).etaSeaBuoy);

    const difference = moment.duration(end.diff(now));
    const hours = difference.asHours();
    return hours < 6;
  }

  private etaPbpOrEtaSeaBoyIsMoreThan24HoursFromNow() {
    const now = moment(new Date());

    let portVisit = this.context.visit.visitDeclaration.portVisit;

    const end = portVisit.portEntry.etaPilotBoardingPlace
               ? moment(portVisit.portEntry.etaPilotBoardingPlace)
               : moment((<SeaEntry> portVisit.portEntry).etaSeaBuoy);

    const difference = moment.duration(end.diff(now));
    const hours = difference.asHours();
    return hours > 24;
  }

  private dateOfFormFillInIsCloseToMastersDeclarationDate() {
    const healthDeclaration = this.healthDeclaration;

    if (healthDeclaration == null) {
      return false;
    } else {
      const portVisit = this.context.visit.visitDeclaration.portVisit;
      const end = portVisit.portEntry.etaPilotBoardingPlace
        ? moment(portVisit.portEntry.etaPilotBoardingPlace)
        : moment((<SeaEntry>portVisit.portEntry).etaSeaBuoy);
      const endMoment = moment(end).set({"hour": 0, "minute": 0});
      const dateOfFillIn = moment(healthDeclaration.vesselMaster.date).add(1, 'day');

      return dateOfFillIn.isSame(endMoment) || dateOfFillIn.isSame(moment(endMoment).add(1, 'day'))
        || dateOfFillIn.isSame(moment(endMoment).add(2, 'day'));
    }
  }

  getRejectReason() {
    return $try(() => this.context.findLatestDeclaration(DeclarationType.HEALTH).rejectReasons);
  }

  amountRecoveredAndStillOnBoard() {
    if (!this.healthDeclaration) {
      return this.context.visit.healthDeclarationSummary.amountRecoveredAndStillOnBoard
    } else {
      return this.healthDeclaration.recoveredIllOrDeadPeople.filter(person => person.disposalOfCase === "Recovered and still on board").length
    }
  }

  amountRecoveredAndEvacuated() {
    if (!this.healthDeclaration) {
      return this.context.visit.healthDeclarationSummary.amountRecoveredAndEvacuated
    } else {
      return this.healthDeclaration.recoveredIllOrDeadPeople.filter(person => person.disposalOfCase === "Recovered and evacuated").length
    }
  }

  amountIllAndStillOnBoard() {
    if (!this.healthDeclaration) {
      return this.context.visit.healthDeclarationSummary.amountIllAndStillOnBoard
    } else {
      return this.healthDeclaration.recoveredIllOrDeadPeople.filter(person => person.disposalOfCase === "Ill and still on board").length
    }
  }

  amountIllAndEvacuated() {
    if (!this.healthDeclaration) {
      return this.context.visit.healthDeclarationSummary.amountIllAndEvacuated
    } else {
      return this.healthDeclaration.recoveredIllOrDeadPeople.filter(person => person.disposalOfCase === "Ill and evacuated").length
    }
  }

  amountDiedAndStillOnBoard() {
    if (!this.healthDeclaration) {
      return this.context.visit.healthDeclarationSummary.amountDiedAndStillOnBoard
    } else {
      return this.healthDeclaration.recoveredIllOrDeadPeople.filter(person => person.disposalOfCase === "Died and still on board").length
    }
  }

  amountDiedAndEvacuated() {
    if (!this.healthDeclaration) {
      return this.context.visit.healthDeclarationSummary.amountDiedAndEvacuated
    } else {
      return this.healthDeclaration.recoveredIllOrDeadPeople.filter(person => person.disposalOfCase === "Died and evacuated").length
    }
  }

  amountDiedAndBuriedAtSea() {
    if (!this.healthDeclaration) {
      return this.context.visit.healthDeclarationSummary.amountDiedAndBuriedAtSea
    } else {
      return this.healthDeclaration.recoveredIllOrDeadPeople.filter(person => person.disposalOfCase === "Died and buried at sea").length
    }
  }

  noRecoveredIllOrDeadPeople() {
    return !this.amountRecoveredAndStillOnBoard() && !this.amountRecoveredAndEvacuated() && !this.amountIllAndStillOnBoard()
    && !this.amountIllAndEvacuated() && !this.amountDiedAndStillOnBoard() && !this.amountDiedAndEvacuated() && !this.amountDiedAndBuriedAtSea()
  }

  formatHamisStatus() {
    if (this.context.visit.healthDeclarationSummary) {
      switch (this.context.visit.healthDeclarationSummary.healthDeclarationStatus) {
        case 'APPROVED':
            return 'Approved';
        case 'APPROVED_MEASURES_IMPOSED':
            return 'Approved with measures imposed';
        case 'REJECTED':
            return 'Rejected';
        default:
            return null;
      }
    }
  }

  prepareVesselSurgeonAndAdditionalContactStructure() {
    if (this.healthDeclaration?.vesselSurgeon && !this.healthDeclaration.vesselSurgeon.date &&
      !this.healthDeclaration.vesselSurgeon.email && !this.healthDeclaration.vesselSurgeon.name &&
      !this.healthDeclaration.vesselSurgeon.phoneNumber && !this.healthDeclaration.vesselSurgeon.truthfullyDeclared) {
      this.healthDeclaration.vesselSurgeon = null;
    }

    if (this.healthDeclaration?.additionalContact && !this.healthDeclaration.additionalContact.name &&
      !this.healthDeclaration.additionalContact.email && !this.healthDeclaration.additionalContact.phoneNumber) {
    this.healthDeclaration.additionalContact = null;
    }
  }

}

function toHealthDeclarationSummary(declaration: HealthDeclaration) {
  return declaration === null ? null : <HealthDeclarationSummary>{
    amountRecoveredAndStillOnBoard: declaration.recoveredIllOrDeadPeople.filter(person => person.disposalOfCase === "Recovered and still on board").length,
    amountRecoveredAndEvacuated: declaration.recoveredIllOrDeadPeople.filter(person => person.disposalOfCase === "Recovered and evacuated").length,
    amountIllAndStillOnBoard: declaration.recoveredIllOrDeadPeople.filter(person => person.disposalOfCase === "Ill and still on board").length,
    amountIllAndEvacuated: declaration.recoveredIllOrDeadPeople.filter(person => person.disposalOfCase === "Ill and evacuated").length,
    amountDiedAndStillOnBoard: declaration.recoveredIllOrDeadPeople.filter(person => person.disposalOfCase === "Died and still on board").length,
    amountDiedAndEvacuated: declaration.recoveredIllOrDeadPeople.filter(person => person.disposalOfCase === "Died and evacuated").length,
    amountDiedAndBuriedAtSea: declaration.recoveredIllOrDeadPeople.filter(person => person.disposalOfCase === "Died and buried at sea").length
  };
}
