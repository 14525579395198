import {Component, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {FacetStats, OverviewFilters} from "../facet-filter/facet-filter.component";
import {FacetUtils} from "../facet-utils";

@Component({
  selector: 'app-facets-overview',
  templateUrl: './facets-overview.component.html',
  styleUrls: ['./facets-overview.component.scss']
})
export class FacetsOverviewComponent {
  facetUtils = FacetUtils;

  @Input() facets: FacetStats[];
  @Input() overviewFilters: OverviewFilters<any>;
  @Input() otherFacets: FacetStats[];
  @Input() facetNameFormatter: (name: string) => string;
  @Input() specificFacetFiltersTemplate: TemplateRef<any>;

  @Output() filtersCleared: EventEmitter<OverviewFilters<any>> = new EventEmitter<OverviewFilters<any>>();
  @Output() facetFiltersChanged: EventEmitter<OverviewFilters<any>> = new EventEmitter<OverviewFilters<any>>();

  trackByFacetName = (index: number, record: FacetStats) => record.name;

  onFacetValuesChange = (facet: FacetStats, selectedValues: string[]) => {
    if (selectedValues?.length > 0) {
      this.overviewFilters.facets[facet.name] = selectedValues;
    } else {
      delete this.overviewFilters.facets[facet.name];
    }
    this.facetFiltersChanged.emit(this.overviewFilters);
  }

  get selectedOtherFacetValues(): FacetStats[] {
    return this.otherFacets?.filter(f => this.overviewFilters?.facets[f.name]?.length > 0) || [];
  }

  get hasOtherFacetValueSelected(): boolean {
    return this.selectedOtherFacetValues.length > 0;
  }

  get otherValuesSelectedFormatted(): string {
    return this.selectedOtherFacetValues.map(f => this.facetNameFormatter(f.name)).join(", ");
  }

  clearFilters = () => {
    const term = this.overviewFilters.term;
    this.overviewFilters = FacetUtils.defaultOverviewFilters();
    this.overviewFilters.term = term;
    this.filtersCleared.emit(this.overviewFilters);
  }

  removeOtherFacets = () => {
    this.otherFacets.forEach(f => delete this.overviewFilters.facets[f.name]);
    this.facetFiltersChanged.emit(this.overviewFilters);
  }
}
