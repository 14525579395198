import {Component, Input, Output} from '@angular/core';
import {EncryptValue} from "@portbase/bezoekschip-service-typescriptmodels";
import {sendCommandAndWait} from "../../common/utils";

@Component({
  selector: 'app-encrypt',
  templateUrl: './encrypt.component.html',
  styleUrls: ['./encrypt.component.css']
})
export class EncryptComponent {
  @Input() secret: string;
  @Output() encrypted: string;

  encrypt = () => {
    const encryptValue = <EncryptValue>{value: this.secret};
    this.encrypted = null;

    if (encryptValue.value) {
      sendCommandAndWait('com.portbase.bezoekschip.common.api.admin.EncryptValue', encryptValue).subscribe(result => {
        if (this.secret === encryptValue.value) {
          this.encrypted = result;
        }
      });
    }
  }
}
