import {Component, Input} from '@angular/core';
import {BunkerLicense} from "@portbase/bezoekschip-service-typescriptmodels";

@Component({
  selector: 'app-selection-license',
  templateUrl: './selection-bunker-license.component.html',
  styleUrls: ['../../bunkering.scss', './selection-bunker-license.component.css']
})
export class SelectionBunkerLicenseComponent {

  @Input()
  selection: BunkerLicense;

}
