<div class="row sticky-top bg-white mb-3" style="top:6.125rem">
  <div class="col-md-12">
    <app-alerting></app-alerting>
  </div>
</div>

<div class="row narrow-gutters always-enabled mb-1">
  <div class="col-auto">
    <button class="btn"
            [ngClass]="transhipmentFilter.selectedOptions.length > 0 || transhipmentFilter.deselectedOptions.length > 0 ? 'btn-danger' : 'btn-outline-info'"
            (click)="toggleFilter()">
      <span class="fa fa-filter m-0"></span>
    </button>
  </div>
  <div class="col-md-3 pe-4" style="min-width: 19rem; height: 35px;">
    <app-date-range (ngModelChange)="onDateSelection($event)" [ngModel]="dateRange" [disabled]="!!filterTerm">
    </app-date-range>
  </div>
  <div class="col pe-2">
    <app-filter (resultsFound)="renderFilteredItems()" [(ngModel)]="filterTerm" [searchFunction]="reload"
                [showTooltip]="true"
                placeholder="Filter by mrn, consignment nr, etc..." [appAutoFocus]="true"></app-filter>
  </div>
  <div class="col-auto">
    <div class="btn-group">
      <button class="btn btn-info" data-bs-toggle="modal" data-bs-target="#newOwnConsignmentTranshipment">
        <span class="pe-2 fa fa-plus-circle"></span>New transhipment
      </button>
      <button type="button" class="btn btn-info dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown"></button>
      <div class="dropdown-menu dropdown-menu-end">
        <button class="dropdown-item" tabindex="-1" data-bs-toggle="modal"
                data-bs-target="#newContainerConsignmentTranshipment">New transhipment (container consignment)
        </button>
        <button class="dropdown-item" tabindex="-1" data-bs-toggle="modal" data-bs-target="#newBulkConsignmentTranshipment">
          New transhipment (bulk consignment)
        </button>
      </div>
    </div>
  </div>
</div>
<div class="row narrow-gutters my-3" [hidden]="!showFilter">
  <app-transhipment-filter (changed)="applyTranshipmentFilters($event)" [transhipments]="getFilteredItems()"></app-transhipment-filter>
</div>

<ng-container *ngIf="visibleTranshipments as transhipments">
  <app-pagination #pagination [items]="transhipments"
                  [class]="'accordion'" id="transitAccordion">
    <ng-container *ngFor="let transhipment of pagination.getItemsOnPage(); let index = index; trackBy : trackByCrnAndConsignmentNumber;">
      <div class="row g-0">
        <div class="col-auto me-2">
          <div class="form-check form-check-inline m-0 always-enabled" style="height: 2rem">
            <input [(ngModel)]="transhipment['selected']" class="always-enabled form-check-input m-0" type="checkbox"></div>
        </div>
        <div class="col">
          <app-collapse #transhipmentCollapse [collapse]="collapz" [showOnLoad]="false">
            <app-transhipment-summary [setModel]="transhipment"></app-transhipment-summary>
          </app-collapse>
        </div>
      </div>

      <ng-template #collapz>
        <div class="collapse" data-bs-parent="#transitAccordion">
          <div class="py-3 ms-2">
            <app-view-transhipment [summary]="transhipment"></app-view-transhipment>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <div class="row g-0 text-secondary">
      <div class="col-auto" *ngIf="transhipments.length > 1">
        <label class="mt-3">
          <input [(ngModel)]="transhipments.length === selectedItems.length"
                 (click)="toggleSelectAll()" class="always-enabled" type="checkbox">
          <span class="ms-2">Select all</span>
        </label>
      </div>
    </div>
  </app-pagination>

  <div *ngIf="visibleTranshipments.length === 0" class="text-center text-muted py-2">
    <ng-container *ngIf="items.length > 0; else noReleasesYet">
      No matching transhipments found.
    </ng-container>
    <ng-template #noReleasesYet>You haven’t added any transhipments yet.</ng-template>
  </div>
</ng-container>

<ng-container *ngIf="selectedItems.length > 0">
  <div class="fixed-bottom row g-0 justify-content-center bg-white py-3 border-top">
    <div class="col-auto mx-1">
      <button class="btn btn-outline-info mx-1" (click)="cancelSelected()" style="min-width: 10rem" type="button">
        Cancel
      </button>
    </div>
  </div>
</ng-container>

<div id=newOwnConsignmentTranshipment class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
     role="dialog">
  <app-declare-transhipment-own-consignment></app-declare-transhipment-own-consignment>
</div>

<div id=newContainerConsignmentTranshipment class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false"
     tabindex="-1" role="dialog">
  <app-declare-transhipment-container-consignment></app-declare-transhipment-container-consignment>
</div>

<div id=newBulkConsignmentTranshipment class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
     role="dialog">
  <app-declare-transhipment-bulk-consignment></app-declare-transhipment-bulk-consignment>
</div>
