<div class="mb-3">
  <div class="declaration-block">
    <h2>
      <app-info>
        Country specific email settings
        <span class="tooltipContent">
          If the release-to-party is located in this country, then the relevant email will be sent to the provided email address.
        </span>
      </app-info>
    </h2>
  </div>

  <div class="row no-gutters bg-secondary text-light py-2">
    <div class="col-2 ps-2">
      Country
    </div>
    <div class="col-5 ps-2 d-none d-sm-flex" style="min-width: 15rem">
      Registration email
    </div>
    <div class="col-4 ps-2 d-none d-sm-flex" style="min-width: 15rem">
      Rejection email
    </div>
    <div class="col-1 ms-auto">

    </div>
  </div>

  <div *ngFor="let preference of prefs; let idx = index">
    <div class="row no-gutters my-2 validatable">
      <div class="col ps-2 col-sm-2">
        <app-search required [(ngModel)]="preference.country" [searchFunction]="utils.findCountries"
                    [resultFormatter]="utils.countryFormatter" [inputFormatter]="countryFormatter"
                    (ngModelChange)="onChange()">
        </app-search>
      </div>
      <div class="col-5 ps-2 d-none d-sm-flex" style="min-width: 15rem">
        <input class="form-control" placeholder="Email"
               [(ngModel)]="preference.carrierCustomerRegistrationEmail"
               (ngModelChange)="onChange()">
      </div>
      <div class="col-4 ps-2 d-none d-sm-flex" style="min-width: 15rem">
        <input class="form-control" placeholder="Email"
               [(ngModel)]="preference.rejectionEmail"
               (ngModelChange)="onChange()">
      </div>
      <div class="col-1 ms-auto ps-2 d-none d-sm-flex">
        <button (click)="deletePrefs(preference)" class="btn pe-0 ms-auto" type="button"
                title="Delete email preferences" tabindex="-1"><span
          class="fa fa-trash text-secondary"></span></button>
      </div>
      <div class="col-1 text-end d-sm-none" style="min-width: 5.5rem">
        <label class="btn btn-info ms-2" [id]="'email-prefs-details' + idx"
               [attr.data-bs-target]="'#email-prefs-details-' + idx"
               [ngClass]="{'btn-invalid' : preference['ngInvalid']}" data-bs-toggle="collapse">Details
        </label>
      </div>
      <div class="col-12 my-2 collapse d-sm-none" [id]="'email-prefs-details-' + idx" [appValidator]="preference">
        <div class="card card-body bg-light">
          <div class="card-title row">
            <h5 class="col">{{preference.country}}</h5>
            <button (click)="deletePrefs(preference)" class="btn mt-n2 col-auto" type="button"
                    title="Delete email preferences" tabindex="-1">
              <span class="fa fa-trash text-secondary"></span>
            </button>
          </div>

          <div class="row">
            <div class="col-md-6">
              <app-form-group label="Organisation was registered as one of your release-to parties">
                <input class="form-control" placeholder="Email" style="max-width: 30rem"
                       [(ngModel)]="preference.carrierCustomerRegistrationEmail"
                       (ngModelChange)="onChange()">
              </app-form-group>
            </div>
            <div class="col-md-6">
              <app-form-group label="Receive Commercial Release rejection email">
                <input class="form-control" placeholder="Email" style="max-width: 30rem"
                       [(ngModel)]="preference.rejectionEmail"
                       (ngModelChange)="onChange()">
              </app-form-group>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="text-end mt-3">
    <button (click)="addCountrySpecificEmail()" type="button" class="btn btn-info" style="width: 8rem">
      Add email
    </button>
  </div>
</div>
