import {Component, ElementRef, Input, ViewChild, ViewEncapsulation} from '@angular/core';
import {AutoPlacement, BasePlacement, Placement} from "@popperjs/core/lib/enums";

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class InfoComponent {
  @Input() wide: boolean;
  @Input() placement?: 'auto' | 'top' | 'bottom' | 'left' | 'right';
  @Input() iconClass: string = "fa-info-circle";
  @Input() keepOpen: boolean;

  @ViewChild("trigger") trigger: ElementRef;
  @ViewChild("tooltipContent") tooltipContent: ElementRef;
  private tooltipElement: JQuery<any>;

  open() {
    this.tooltipElement = $(this.trigger.nativeElement).tooltip({
      title: this.tooltipContent.nativeElement,
      html: true,
      sanitize: true,
      placement: this.placement || "bottom"
    });
    this.tooltipElement.tooltip("show");
  }

  toggle() {
    this.tooltipElement?.tooltip("toggle");
  }

  close() {
    this.tooltipElement?.tooltip("hide");
  }
}
