<div [id]="berthVisit.id + '-acceptStatusModal'" class="modal fade always-enabled" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Mock a Terminal Planning</h5>
      </div>
      <div class="modal-body">
        <app-form-group label="Estimated Arrival">
          <app-date-field [ngModel]="berthVisit.eta" readonly="true"></app-date-field>
        </app-form-group>
        <app-form-group label="Requested Arrival">
          <app-date-field [(ngModel)]="rta" [required]="modalOpen()"></app-date-field>
        </app-form-group>
        <app-form-group label="Estimated Departure">
          <app-date-field [ngModel]="berthVisit.etd" readonly="true"></app-date-field>
        </app-form-group>
        <app-form-group label="Estimated Completion">
          <app-date-field [(ngModel)]="etc" [required]="modalOpen()"></app-date-field>
        </app-form-group>
        <app-form-group label="Berth Mooring">{{berthVisit.mooring}}</app-form-group>
        <app-form-group label="Terminal Mooring">
          <app-select [(ngModel)]="data.info.mooringOrientation" [options]="moorings"
                      [formatter]="mooringFormatter">
          </app-select>
        </app-form-group>
        <app-form-group label="Berth">{{berthInputFormatter(berthVisit.berth)}}</app-form-group>
        <app-form-group>
          <!-- force  display of validation errors -->
          <label for="{{berthVisit.id}}-bollardAft">Bollards</label>
          <div class="d-flex">
            <div class="input-group me-3">
              <span class="input-group-text" id="bollard-from">aft</span>
              <input [(ngModel)]="data.info.bollardAft" (blur)="updateBollardsAft($event)"
                     [min]="bollardsInfo?.start" [max]="bollardsInfo?.end"
                     id="{{berthVisit.id}}-bollardAft" appDecimalNumber class="form-control">
            </div>
            <div class="input-group">
              <span class="input-group-text" id="bollard-to">fore</span>
              <input [(ngModel)]="data.info.bollardFore" (blur)="updateBollardsFore($event)"
                     [min]="bollardsInfo?.start" [max]="bollardsInfo?.end"
                     id="{{berthVisit.id}}-bollardFore" appDecimalNumber class="form-control">
            </div>
          </div>
        </app-form-group>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="sendMockStatus()">Send</button>
      </div>
    </div>
  </div>
</div>
