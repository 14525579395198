import {Component, Input} from '@angular/core';
import {TransitGoodsItem} from '@portbase/bezoekschip-service-typescriptmodels';
import {removeItem} from '../../../../common/utils';
import {VisitContext} from "../../../../visit-details/visit-context";
import {PortvisitUtils} from "../../../../refdata/portvisit-utils";

@Component({
  selector: 'app-transit-goods-documents',
  templateUrl: './transit-goods-documents.component.html',
  styleUrls: ['./transit-goods-documents.component.css']
})
export class TransitGoodsDocumentsComponent {
  context = VisitContext;
  refData = PortvisitUtils;
  @Input() item: TransitGoodsItem;
  @Input() itemFromCustoms: TransitGoodsItem;
  @Input() isEditable: boolean;

  addItem() {
    this.item.producedDocuments ||= []
    this.item.producedDocuments.push(<any>{});
  }

  removeItem(item: any) {
    return () => removeItem(this.item.producedDocuments, item);
  }
}
