import {Component, Input} from '@angular/core';
import {PortOfCall} from "@portbase/bezoekschip-service-typescriptmodels";
import {PortvisitUtils} from "../portvisit-utils";
import {sendQuery} from "../../common/utils";

@Component({
  selector: 'app-edit-port-of-call',
  templateUrl: './edit-port-of-call.component.html',
  styleUrls: ['./edit-port-of-call.component.css']
})
export class EditPortOfCallComponent {
  @Input() portOfCall: PortOfCall;
  refData = PortvisitUtils;
  customsOffices = sendQuery('com.portbase.bezoekschip.common.api.visit.GetCustomsOffices', {countryCode: 'NL'});

  applicabilityFormatter = value => value ? "Applicable" : "Not applicable";
  negativeLogicFormatter = value => value ? "Disabled" : "Enabled";
  positiveLogicFormatter = value => value ? "Enabled" : "Disabled";
  reportingFormatter = value => value ? "Reported" : "Not reported";
  requirementFormatter = value => value ? "Required" : "Not required";
}
