import {Component} from '@angular/core';
import {AppContext} from "../app-context";

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent {
  appContext = AppContext;

  ngAfterViewInit() {
    let newsItems = document.querySelectorAll('.d-none .news-item').length;
  }
}
