<span class="me-2 d-sm-none" style="height: 100%">
  <a class="nav-item nav-link feedback-btn" type="button" (click)="reset()" data-bs-toggle="popover"
          onPopover [popoverContent]="popContent" [placement]="'bottom-start'" popoverClass="pop-container-small"
          style="outline: none; height: 100%;" aria-describedby="tooltip">
    Report issue
    <span class="fas fa-envelope ms-1"></span>
  </a>
</span>

<span class="me-2 d-none d-sm-block" style="height: 100%">
  <a class="nav-item nav-link feedback-btn" type="button" (click)="reset()" data-bs-toggle="popover"
          onPopover [popoverContent]="popContent" [placement]="'bottom-start'" popoverClass="pop-container"
          style="outline: none; height: 100%" aria-describedby="tooltip">
    Report issue
    <span class="fas fa-envelope ms-1"></span>
  </a>
</span>

<ng-template #popContent>
  <div class="px-2 pt-1 container-fluid">
    <div class="row mb-3">
      <h5 class="col">Report issue</h5>
    </div>
    <div class="row">
      <app-form-group class="col-12" label="Email">
        <input required [(ngModel)]="email" class="form-control">
      </app-form-group>
    </div>
    <div class="row">
      <app-form-group class="col-12" label="Issue">
        <textarea required [(ngModel)]="feedback" class="form-control" style="min-height: 15rem;"> </textarea>
      </app-form-group>
    </div>
    <button [disabled]="!isValid()" class="btn btn-primary float-end mb-3" data-bs-toggle="popover" (click)="sendFeedback();">
      submit
    </button>
  </div>
</ng-template>
