import {
  ArrayTemplate,
  DateField,
  HardCodedField,
  MappedField,
  parseExcel,
  RequiredField,
  toBase64,
  ValidatedField,
  WorkBookTemplate
} from '../../common/upload/excel.utils';
import {
  Availability,
  Port,
  PortFacility,
  PreviousPort,
  SecurityLevel,
  ShipToShipActivityType
} from '@portbase/bezoekschip-service-typescriptmodels';
import {clearValidation, cloneObject, sendQuery} from '../../common/utils';
import {VisitContext} from '../visit-context';
import {v4 as uuid} from 'uuid';
import moment from 'moment';
import {tap} from 'rxjs/operators';
import {AppContext} from '../../app-context';

export function uploadSecurityForm(excelFile: File, cb: (reasonFilled: boolean, mattersFilled: boolean) => void) {
	clearValidation(this.element);
	AppContext.clearAlerts();
	toBase64(excelFile).subscribe(value => this.uploadedXls = value);
	parseExcel(excelFile, securityTemplate).subscribe(model => {
		if (model) {
			VisitContext.visit.securityDeclaration = model.securityDeclaration;
			VisitContext.visit.visitDeclaration.previousPorts = model.previousPorts;
			cb(model.securityDeclaration.reasonLessPortFacilities != null
				&& model.securityDeclaration.reasonLessPortFacilities.length > 0,
				model.securityDeclaration.securityRelatedMatter != null
				&& model.securityDeclaration.securityRelatedMatter.length > 0);
		}
	});
}

const securityTemplate: WorkBookTemplate = {
	sheets: [
		{
			name: 'Ship security information',
			template: {
				imoNumber: new ValidatedField(new RequiredField('F2'),
					value => {
						const expectedImo = VisitContext.visit.vessel.imoCode;
						if (String(value) !== expectedImo) {
							throw 'The IMO code in the Excel file (' + value + ') does not match IMO code of the vessel (' + expectedImo + ').';
						}
					}),
				securityDeclaration: {
          reasonLessPortFacilities: new MappedField('C30', mapReasonForLessPortFacilities),
					securityReportRequired: new HardCodedField('YES'),
					companySecurityOfficer: {
						name: new ValidatedField(new RequiredField('C4'), value => {
							if (value && value.length > 70){
								throw 'CSO name may not be more than 70 characters'
							}
						}),
						email: new ValidatedField('C5', value => {
							if(value){
								if (value.length > 50) {
									throw 'CSO email may not be more than 50 characters';
								}
								let validEmail = new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$");
								if (!validEmail.test(value)) {
									throw 'CSO email: ' + value + ' format is invalid or contains invalid characters';
								}
							}
						}),
						phoneNumber:  new ValidatedField('C6', value => {
							if(value){
								if (value.toString().length > 15) {
									throw 'CSO phone number may not be more than 15 characters';
								}
								let validPhone = new RegExp("^[0-9()（）\\s+\\-]{0,15}$");
								if (!validPhone.test(value)) {
									throw 'CSO phone number: ' + value + ' contains invalid characters';
								}
							}
						}),
					},
					currentSecurityLevel: new MappedField(new RequiredField('C7'), mapSecurityLevel),
					approvedSspDocumentOnBoard: new MappedField(new RequiredField('F4'), mapYesNo),
					isscSecurityDocument: {
						availability: new MappedField(new RequiredField('F5'), mapIsscAvailable),
						expiryDate: new DateField('H5'),
						reasonNoValidIsscOnBoard: 'F7',
						issuer: new MappedField('F6', (issuerName, cell) => {
							return issuerName ? sendQuery('com.portbase.bezoekschip.common.api.security.FindIsscIssuer', {issuerName: issuerName}).pipe(tap(v => {
								if (!v) {
									throw 'Cell ' + cell.cell + ' in sheet "' + cell.sheetName + '\" contains an unknown issuer';
								}
							})) : null;
						}),
					},
					shipToShipActivities: new ArrayTemplate(
						{
							location: new RequiredField('B$'),
							type: new MappedField(new RequiredField('E$'), mapShipToShipActivityType),
							startDate: new DateField('C$'),
							endDate: new DateField('D$'),
							compliantWithSsp: new MappedField(new RequiredField('F$'), mapYesNo),
							securityMeasures: 'G$',
						}, [31, 40]),
					securityRelatedMatter: 'A44',
				},
				previousPorts: new MappedField({
					facilities: new ArrayTemplate({
						portCode: 'B$',
						facilityCode: new RequiredField('E$'),
						arrivalDate: new DateField('C$'),
						departureDate: new DateField('D$'),
						securityLevel: new MappedField(new RequiredField('F$'), mapSecurityLevel),
						additionalSecurityMeasures: 'G$',
					}, [10, 19]),
					ports: new ArrayTemplate({
						portCode: new RequiredField('B$'),
						arrival: new DateField('C$'),
						departure: new DateField('D$'),
					}, [20, 28]),
				}, (upload, cell, parser) =>
          parser.errors.length > 0 ? null : sendQuery('com.portbase.bezoekschip.common.api.visit.EnrichPreviousPorts',
            {previousPorts: asPreviousPorts(upload.facilities, upload.ports)}))
			}
		},
		{
			name: 'Verificatie',
			template: {
				verificationUuid: new ValidatedField(new RequiredField('A1'),
					value => {
						if (value !== '3fcdcb3d0e685f2886c129d8e7bb3a4c8cd8bd25') {
							throw 'Your Excel file could not be verified. Please download the latest template and try again.';
						}
					})
			}
		},
		{
			name: 'General',
			template: {
				version: new ValidatedField(new RequiredField('B3'),
					value => {
						if (value !== '2.0') {
							throw 'The version of your Excel file is not supported. Please download the latest template and try again.';
						}
					})
			}
		}
	]
};

function mapSecurityLevel(value): SecurityLevel {
	value = value.toUpperCase();
	switch (value) {
		case 'SL1' :
		case 'SL2' :
		case 'SL3' :
			return value;
		default:
			throw 'Invalid security level: ' + value;
	}
}

function mapReasonForLessPortFacilities(value): string {
  if (value === 'Ship has a new owner') {
    return 'NEW_OWNER';
  }
  if (value === 'Ship is new') {
    return 'NEW_VESSEL';
  }
  return null;
}

function mapYesNo(value): boolean {
	switch (value) {
		case 'Yes' :
			return true;
		case 'No' :
			return false;
		default:
			throw 'Invalid value: ' + value;
	}
}

function mapIsscAvailable(value): Availability {
	switch (value) {
		case 'Yes' :
			return Availability.YES;
		case 'No' :
			return Availability.NO;
		case 'Interim' :
			return Availability.INTERIM;
		default:
			throw 'Invalid ISSC availability value: ' + value;
	}
}

function mapShipToShipActivityType(value): ShipToShipActivityType {
	switch (value) {
		case 'Cargo tank cleaning' :
			return ShipToShipActivityType.CARGO_TANK_CLEANING;
		case 'Changing Crew' :
			return ShipToShipActivityType.CHANGING_CREW;
		case 'Crew movement' :
			return ShipToShipActivityType.CREW_MOVEMENT;
		case 'De-gassing' :
			return ShipToShipActivityType.DE_GASSING;
		case 'Loading cargo' :
			return ShipToShipActivityType.LOADING_CARGO;
		case 'Miscellaneous' :
			return ShipToShipActivityType.MISCELLANEOUS;
		case 'Passenger movement' :
			return ShipToShipActivityType.PASSENGER_MOVEMENT;
		case 'Quarantine inspection' :
			return ShipToShipActivityType.QUARANTINE_INSPECTION;
		case 'Repair' :
			return ShipToShipActivityType.REPAIR;
		case 'Taking bunkers' :
			return ShipToShipActivityType.TAKING_BUNKERS;
		case 'Taking supplies' :
			return ShipToShipActivityType.TAKING_SUPPLIES;
		case 'Unloading cargo' :
			return ShipToShipActivityType.UNLOADING_CARGO;
		case 'Waste disposal' :
			return ShipToShipActivityType.WASTE_DISPOSAL;
		default:
			throw 'Invalid ship to ship activity type: ' + value;
	}
}

function asPreviousPorts(facilityVisits: any[], portsWithoutFacilities: any[]): PreviousPort[] {
	const result: PreviousPort[] = [];
	let currentPort: PreviousPort;
	for (const facilityVisit of facilityVisits) {
		if (!facilityVisit.portCode && currentPort) {
			facilityVisit.portCode = currentPort.port.locationUnCode;
		}
		if (!currentPort || currentPort.port.locationUnCode !== facilityVisit.portCode) {
			result.push(currentPort = <PreviousPort>{
				id: uuid(),
				port: {locationUnCode: facilityVisit.portCode},
				portFacilityVisits: [],
			});
		}
		currentPort.portFacilityVisits.push(facilityVisit);
		facilityVisit.portFacility = <PortFacility>{
			code: facilityVisit.portCode + '-' + padWithZeros(facilityVisit.facilityCode)
		};

		if (facilityVisit.portCode !== null && typeof facilityVisit.portCode !== 'string') {
			throw 'Port ' + facilityVisit.portCode + ' is invalid: Port code is of type ' + typeof facilityVisit.portCode;
		} else if (facilityVisit.portCode.startsWith("XZ")) {
			throw 'Port ' + facilityVisit.portCode + ' is invalid: Waypoints are not allowed as previous ports';
		}
		delete facilityVisit.portCode;
		delete facilityVisit.facilityCode;
	}
	portsWithoutFacilities.map((p: PreviousPort) => {
		p.id = uuid();
		p.portFacilityVisits = [];
		p.port = <Port>{
			locationUnCode: (<any>p).portCode
		};
		return p;
	}).forEach(p => {if(p.port.locationUnCode.startsWith("XZ")) {
			throw 'Port ' + p.port.locationUnCode + ' is invalid: Waypoints are not allowed as previous ports';
	} else {result.push(p)}});

	return matchWithCurrentPorts(result);

	function matchWithCurrentPorts(uploadedPorts: PreviousPort[]): PreviousPort[] {
		const result: PreviousPort[] = [];
		const currentPorts: PreviousPort[] = cloneObject(VisitContext.savedVisit.visitDeclaration.previousPorts);
		let i = 0;
		for (; i < currentPorts.length; i++) {
			const currentPort = currentPorts[i];
			if(uploadedPorts.length>i) {
				const uploadedPort = uploadedPorts[i];
				if (currentPort.port && uploadedPort.port.locationUnCode !== currentPort.port.locationUnCode) {
					throw 'Port ' + (i + 1) + ' in the upload does not match the current previous port in that position';
				}
				uploadedPort.id = currentPort.id;
				adjustPortTimes(uploadedPort);
				result.push(uploadedPort);
			} else {
				currentPort.portFacilityVisits = [];
				result.push(currentPort);
			}
		}
		for (; i < uploadedPorts.length; i++) {
			const newPort = uploadedPorts[i];
			adjustPortTimes(newPort);
			result.push(newPort);
		}
		return result;
	}

	function adjustPortTimes(previousPort: PreviousPort) {
		const portFacilities = previousPort.portFacilityVisits;
		if (portFacilities.length > 0) {
			const start = portFacilities[portFacilities.length - 1].arrivalDate;
			const end = portFacilities[0].departureDate;
			if (start) {
				previousPort.arrival = atHour(start, 12);
			}
			if (end) {
				previousPort.departure = atHour(end, 12);
			}
		} else {
			previousPort.arrival = atHour(previousPort.arrival, 12);
			previousPort.departure = atHour(previousPort.departure, 12);
		}
	}

	function atHour(date, hour: number): string {
		if (!date) {
			return null;
		}
		const m = moment(date);
		m.hour(hour);
		return m.utc().toISOString();
	}

	function padWithZeros(facilityCode): string {
		facilityCode = String(facilityCode);
		while (facilityCode.length < 4) {
			facilityCode = '0' + facilityCode;
		}
		return facilityCode;
	}
}
