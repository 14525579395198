<div class="row">
  <app-form-field [label]="label" class="col col-md-5"
                  [(value)]="packaging.typeOfPackages" [editMode]="editMode"
                  [includeMargin]="false" [formatter]="refData.packageTypeFormatter">
    <app-select [(ngModel)]="packaging.typeOfPackages" dataKey="code" placeholder="Select package type"
                [optionsProvider]="refData.getCargoPackageTypes"
                [formatter]="refData.packageTypeFormatter" required></app-select>
  </app-form-field>
  <ng-container *ngIf="!packaging.typeOfPackages?.bulk">
    <app-form-field label="Amount" class="col col-md-2"
                    [(value)]="packaging.numberOfPackages" [editMode]="editMode"
                    [includeMargin]="false">
      <input class="input-group rounded border form-control" type="number" required
             [(ngModel)]="packaging.numberOfPackages"/>
    </app-form-field>
    <app-form-field label="Shipping marks" class="col col-md-4"
                    [(value)]="packaging.shippingMarks" [editMode]="editMode"
                    [includeMargin]="false">
      <input class="input-group rounded border form-control"
             [(ngModel)]="packaging.shippingMarks"/>
    </app-form-field>
  </ng-container>
  <ng-content></ng-content>
</div>
