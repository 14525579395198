<div *ngIf="showPortCallOptimization && atLeastOneAtaPresent()">
  <div class="validatable" *ngIf="ataPortPassagePlanningArea!=null">
    <app-form-group>
      <label>ATA PPA</label>
      <app-date-field [(ngModel)]="ataPortPassagePlanningArea"
                      [readonly]="true" id="ata-pppa"></app-date-field>
    </app-form-group>
  </div>
  <div class="validatable" *ngIf="ataLeftPreviousPort!=null">
    <app-form-group>
      <label>ATA PPA from {{previousPortUnloCode}}</label>
      <app-date-field [(ngModel)]="ataLeftPreviousPort"
                      [readonly]="true" id="ata-lpp"></app-date-field>
    </app-form-group>
  </div>
</div>
