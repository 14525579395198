<div *ngIf="this.displayNotificationOfDeparture()">
  <div class="d-none" *ngIf="this.visit">
    <div id="notificationPDF" class="page">
      <h2 class="title d-flex justify-content-center mt-4 mb-3">Notification of Departure</h2>
      <div class="ms-5 me-5">
        <div class="row border border-dark">
          <div class="col-6 border-end border-dark">
            <div class="mb-5">CRN: {{visit.crn}}</div>
          </div>
          <div class="col-3 border-end border-dark">
            <div>Arrival:</div>
            <div></div>
          </div>
          <div class="col-3">Departure: <b>X</b></div>
        </div>
        <div class="row border-start border-end border-bottom border-dark">
          <div class="col-6 border-end border-dark">
            <div>1. Name and description of the vessel</div>
            <div>
              <span>{{visit.vessel.name}}</span><span> {{visit.vessel.imoCode}}</span>
            </div>
            <div class="mb-2">{{visit.vessel.motName}}</div>
          </div>
          <div class="col-3 border-end border-dark">
            <div>2. Port of departure</div>
            <div>{{visit.portOfCall.port.name}}</div>
          </div>
          <div class="col-3">
            <div>3. Departure date</div>
            <div>{{getLastBerthVisit().atd | date: ["dd-MM-yyyy HH:mm"]}}</div>
          </div>
        </div>
        <div class="row border-start border-end border-bottom border-dark">
          <div class="col-3 border-end border-dark">
            <div>4. Flag code</div>
            <div class="mb-3">{{visit.vessel.flagCountryUnCode}}</div>
          </div>
          <div class="col-3 border-end border-dark">
            <div>5. Name of the captain</div>
            <div>{{getLastMovement().vesselMasterName}}</div>
          </div>
          <div class="col-6">
            <div>6. Next port</div>
            <div
              *ngIf="visit.visitDeclaration.nextPorts.length > 0">{{visit.visitDeclaration.nextPorts[0].port?.name}}</div>
          </div>
        </div>
        <div class="row border-bottom border-dark">
          <div class="col-6">
            <div class="row border-start border-end border-bottom border-dark">
              <div class="col-12 border-dark">
                <div>7. Vessel registration</div>
                <div>{{visit.vessel.registrationPlaceName}}</div>
                <div class="mb-3">{{visit.vessel.registrationDate | date: ["dd-MM-yyyy"]}}</div>
              </div>
            </div>
            <div class="row border-start border-end border-bottom border-dark">
              <div class="col-6 border-end border-dark">
                <div>9. Gross tonnage</div>
                <div class="mb-3">{{visit.vessel.grossTonnage}}</div>
              </div>
              <div class="col-6 border-dark">
                <div>10. Net tonnage</div>
                <div>{{visit.vessel.netTonnage}}</div>
              </div>
            </div>
            <div class="row border-start border-end border-dark">
              <div class="col-12 border-dark">
                <div>11. Last berth</div>
                <div class="mb-3" *ngIf="getLastBerth()">{{getLastBerth().name}}</div>
              </div>
            </div>
          </div>
          <div class="col-6 border-end border-dark">
            <div>8. Name and address of shipping agent</div>
            <div class="mt-4">{{context.visit.owner.fullName}}</div>
            <div>{{context.visit.owner.address}}</div>
            <div>{{context.visit.owner.zipCode + " " + context.visit.owner.city}}</div>
            <div>{{context.visit.owner.countryName}}</div>
          </div>
        </div>
        <div class="row border-start border-end border-bottom border-dark">
          <div class="col-12">12. Short summary of the voyage (previous and next ports,
            underline where remaining cargo is to be delivered)
          </div>
          <div class="col-12">Last: {{getLastPreviousPortName()}}</div>
          <div class="col-12 mb-3">Next: {{getNextPortsAsString()}}</div>
        </div>
        <div class="row border-start border-end border-bottom border-dark">
          <div class="col-12">13. Cargo description</div>
          <div class="col-12 mb-3">{{getformatedCargo()}}</div>
        </div>
        <div class="row border-start border-end border-bottom border-dark">
          <div class="col-6">
            <div class="row border-bottom border-dark">
              <div class="col-6 border-end border-dark">
                <div>14. Number of crew members
                  (including the captain)
                </div>
                <div class="mb-3" *ngIf="getLastMovement()">{{getLastMovement().numberOfCrew}}</div>
              </div>
              <div class="col-6">
                <div>15. Number of passengers</div>
                <div *ngIf="getLastMovement()">{{getLastMovement().numberOfPassengers}}</div>
              </div>
            </div>
            <div class="row border border-dark">
              <div class="col-12 border-start border-dark">
                <div style="margin-left: 100px;">Additional documents</div>
                <div class="mb-3" style="margin-left: 70px;">(please state the amount of copies)</div>
              </div>
            </div>
            <div class="row border-start border-end border-dark">
              <div class="col-6 border-start border-end border-dark">
                <div class="mb-5">17. Cargo declaration</div>
              </div>
              <div class="col-6">
                <div>18. Ship's stores declaration</div>
              </div>
            </div>
          </div>
          <div class="col-6 border-start border-dark">
            <div>16. Notes</div>
            <div class="mt-5" *ngIf="visit.visitDeclaration.departureVoyage.carrier">Carrier Customs
              ID: {{visit.visitDeclaration.departureVoyage.carrier.customsId}}</div>
          </div>
        </div>
        <div class="row border-start border-end border-bottom border-dark">
          <div class="col-6 border-start border-bottom border-end border-dark">
            <div class="row border-bottom border-dark">
              <div class="col-6 border-start border-end border-dark">
                <div style="margin-bottom: 70px;">19. List of crew members</div>
              </div>
              <div class="col-6">
                <div>20. List of passengers</div>
              </div>
            </div>
            <div class="row" style="height: 62px">
              <div class="col-6 border-start border-end border-dark">
                <div>22. Declaration of the crew's possessions
                  (*)
                </div>
                <div class="mt-1 mb-3"></div>
              </div>
              <div class="col-6">
                <div>23. Maritime health declaration (*)</div>
                <div class="mt-1 mb-3"></div>
              </div>
            </div>
          </div>
          <div class="col-6 border-start border-dark">
            <div class="col-12 mb-5 px-0">21. Date and signature of captain, authorized agent or officer</div>
            <div class="col-12 pt-5">{{getCurrentDateTime()}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-2">
    <a class="row g-0" style="cursor: pointer" (click)="downloadNotification()">
      <span class="col text-dark justify-content-start">Notification of Departure</span>
    </a>
  </div>
</div>
