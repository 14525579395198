<div *ngIf="voyage" class="fieldset" [ngClass]="{'disabled' : getVisitStatus() !== 'EXPECTED'}">
  <app-form-group>
    <label for="arrivalVoyage-carrierName">Carrier</label>
    <app-search [disabled]="!voyage.carrier?.name && voyage.carrier?.customsId" [(ngModel)]="voyage.carrier"
      dataKey="name" [searchFunction]="searchCarrier" id="arrivalVoyage-carrierName" required
      [inputFormatter]="carrierFormatter">
    </app-search>
  </app-form-group>
  <app-form-group>
    <label for="arrivalVoyage-carrierCustomsId">Carrier customs id</label>
    <input [ngModel]="voyage.carrier && voyage.carrier.customsId"
      (ngModelChange)="voyage.carrier = $event ? {customsId : $event} : null" required
      id="arrivalVoyage-carrierCustomsId" type="search" class="form-control" maxlength="17"
      [disabled]="voyage.carrier?.name && voyage.carrier?.customsId">
  </app-form-group>
  <app-form-group>
    <label for="arrivalVoyage-number">Voyage number</label>
    <input [(ngModel)]="voyage.voyageNumber" id="arrivalVoyage-number" type="text" maxlength="17" class="form-control">
  </app-form-group>
</div>