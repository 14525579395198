import {Component, Input} from '@angular/core';
import {Clearance, ClearanceStatus} from "@portbase/bezoekschip-service-typescriptmodels";
import {ClearanceService} from "./clearance-service";

@Component({
  selector: 'app-clearance',
  templateUrl: './clearance.component.html',
  styleUrls: ['./clearance.component.css']
})
export class ClearanceComponent {

  constructor(private clearanceService: ClearanceService) {
  }

  @Input() clearanceKey: string;
  @Input() clearance: Clearance;

  get alertClass(): string {
    switch (this.clearance?.status) {
      case ClearanceStatus.APPROVED:
        return "alert-success";
      case ClearanceStatus.APPROVED_WITH_REMARKS:
        return "alert-warning"
      case ClearanceStatus.REJECTED:
        return "alert-danger"
      case ClearanceStatus.UNKNOWN:
        return "alert-light";
      default:
        return "alert-dark";
    }
  }

  translate(context: string, attentionPointType: string): string {
    return this.clearanceService.translate(context, attentionPointType);
  }
}
