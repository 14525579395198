import {Component, OnDestroy, OnInit} from '@angular/core';
import {isSameSet, removeItem, sendCommand, sendQuery} from '../../common/utils';
import {VisitContext} from '../visit-context';

import {
	BezoekschipOrganisation,
	FindCargoDeclarants,
	TryUpdateCargoDeclarants,
	UpdateAdditionalViewers
} from '@portbase/bezoekschip-service-typescriptmodels';
import {AppContext} from '../../app-context';

@Component({
  selector: 'app-authorise',
  templateUrl: './authorise.component.html',
  styleUrls: ['./authorise.component.css']
})
export class AuthoriseComponent implements OnInit, OnDestroy {
  appContext = AppContext;
  context = VisitContext;

  constructor() {
  }

  ngOnInit(): void {
  }

  searchCargoDeclarant = term => sendQuery('com.portbase.bezoekschip.common.api.authorisation.FindCargoDeclarants', <FindCargoDeclarants>{
    term: term,
    authorityShortName: this.context.visit.portOfCall.portAuthority.shortName
  });

  deleteCargoDeclarant = (cargoDeclarant: BezoekschipOrganisation) => this.context.visit.cargoDeclarants.splice(
    this.context.visit.cargoDeclarants.indexOf(cargoDeclarant), 1);

  addCargoDeclarant = () => {
    if (!this.isAllowedToAddCargoAgents) {
      return;
    }
    this.context.visit.cargoDeclarants.push(null);
  };

  deleteAdditionalViewer = (o: BezoekschipOrganisation) => removeItem(VisitContext.visit.additionalViewers, o);

  addAdditionalViewer = () => VisitContext.visit.additionalViewers.push(null);

  get disableUpdateCargoDeclarants(): boolean {
    return this.context.visit.cargoDeclarants.some(v => !v)
      || isSameSet(this.context.savedVisit.cargoDeclarants.map(o => o && o.shortName),
        this.context.visit.cargoDeclarants.map(o => o && o.shortName));
  }

  get isAllowedToAddCargoAgents(): boolean {
    let isNotCancelled = !VisitContext.savedVisit.cancelled;
    let involved = VisitContext.isOrganisationCurrentVisitDeclarant() || VisitContext.isOrganisationNextDeclarant();
    return VisitContext.organisationMayUpdateVisit() && isNotCancelled && involved && !VisitContext.isUserOnlyCargoDeclarant();
  }

  get disableUpdateAdditionalViewers(): boolean {
    return this.context.visit.additionalViewers.some(v => !v)
      || isSameSet(this.context.savedVisit.additionalViewers.map(o => o && o.shortName),
        this.context.visit.additionalViewers.map(o => o && o.shortName));
  }

  saveCargoDeclarants = () => {
    this.updateCargoDeclarants();
  };

  updateCargoDeclarants = () => {

    sendCommand('com.portbase.bezoekschip.common.api.authorisation.TryUpdateCargoDeclarants', <TryUpdateCargoDeclarants>{
        crn: this.context.visit.crn,
        cargoDeclarants: this.context.visit.cargoDeclarants,
      },
      resultingOrganisations => {
        const allAgentsHaveBeenRemoved = resultingOrganisations.length === this.context.visit.cargoDeclarants.length;
        this.context.visit.cargoDeclarants = resultingOrganisations;
        VisitContext.replaceVisit(this.context.visit);
        if (allAgentsHaveBeenRemoved) {
          AppContext.registerSuccess('Cargo handling agents were updated successfully.');
        } else {
          AppContext.registerError('Some cargo handling agents were not removed, as they have cargo registered on the visit.');
        }
      });
  };

  updateAdditionalViewers = () => {
    sendCommand('com.portbase.bezoekschip.common.api.authorisation.UpdateAdditionalViewers', <UpdateAdditionalViewers>{
        crn: this.context.visit.crn,
        organisations: this.context.visit.additionalViewers,
      },
      () => {
        AppContext.registerSuccess('Additional viewers have been updated successfully.');
        VisitContext.replaceVisit(this.context.visit);
      });
  };

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  ngOnDestroy(): void {
    this.context.visit.owner = this.context.savedVisit.owner;
  }
}

