<app-collapse *ngIf="collapsible else content" [collapse]="content">
  <div class="p-3 fw-bold text-dark">Legend</div>
</app-collapse>

<ng-template #content>
  <div class="row">
    <div class="col">
      <ul class="d-flex flex-wrap justify-content-center m-0 p-0 collapse">
        <ng-container *ngFor="let label of getAllLabels()">
          <li (click)="toggleVisibility(label)" [ngClass]="hiddenLabels.includes(label) ? 'text-secondary hidden-label' : ''"
              class="list-group-item d-flex flex-row justify-content-center p-2" style="cursor: pointer; border-width: 1px;">
            <div class="me-sm-2 align-self-center" style="width: 20px; height: 20px; ;"
                 [ngStyle]="{'background-color': getDatasetColor(label)}"></div>
            <span class="d-none d-sm-block">{{label}}</span>
          </li>
        </ng-container>
      </ul>
    </div>
    <div class="col-auto" *ngIf="charts && charts.length > 0">
      <a type="button" class="btn btn-outline-info" (click)="toggleSelect()">{{selectText}}</a>
    </div>
  </div>
</ng-template>
