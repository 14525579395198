import {Component, Input, OnInit} from '@angular/core';
import {
  Berth,
  BerthVisit,
  BerthVisitInfo,
  MessageStatus,
  Mooring,
  TerminalMooring,
  TerminalVisit,
} from '@portbase/bezoekschip-service-typescriptmodels';
import {VisitContext} from '../../visit-context';
import moment from 'moment';
import {PortvisitUtils} from "../../../refdata/portvisit-utils";

@Component({
	selector: 'app-berth-visit',
	templateUrl: './berth-visit.component.html',
	styleUrls: ['./berth-visit.component.scss']
})
export class BerthVisitComponent implements OnInit {

	context = VisitContext;
  terminalVisit: TerminalVisit;

	@Input() berthVisit: BerthVisit;
	@Input() shipStatus: string;
	@Input() firstBerth: boolean;
	@Input() lastBerth: boolean;
	@Input() isNextBerthVisit: boolean;
	@Input() berthVisitInfos: BerthVisitInfo;
	@Input() isRotterdam: boolean;
	@Input() idx: number;
  @Input() readonly: boolean;

	constructor() {
	}

	ngOnInit() {
    this.terminalVisit = this.context.getTerminalVisit(this.berthVisit);
	}

	getBerthVisitInfo(): BerthVisitInfo {
		return VisitContext.savedVisit.berthVisitInfos[this.berthVisit.id];
	}

	get isBerthOfTransfer(): boolean {
		return this.lastBerth && !this.isNextBerthVisit && !!this.context.visit.nextDeclarant;
	}

	getTimeAtBerth(berthVisit: BerthVisit): string {
		if ((berthVisit.eta || berthVisit.ata) && (berthVisit.atd || berthVisit.etd)) {
			var duration = moment.duration(moment(berthVisit.atd ? berthVisit.atd : berthVisit.etd)
				.diff(moment(berthVisit.ata ? berthVisit.ata : berthVisit.eta)));
			var days = Math.floor(duration.asDays());
			var hours = Math.floor(duration.hours()); //hours instead of asHours
			var minutes = duration.minutes(); //minutes instead of asMinutes
			return days >= 3 ? days + ' days' : ((days > 0 ? (days + 'd'): '') +  (hours + 'h' + (minutes ? minutes + 'm' : '')));
		}
	}


	showStormPilotage() {
		return !VisitContext.isOrganisationNextDeclarant() && this.isRotterdam && this.berthVisit.berth && this.lastBerth;
	}

	get getPilotOnBoard(): string {
		return this.berthVisitInfos && this.berthVisitInfos.harbourMasterInfo
			&& this.berthVisitInfos.harbourMasterInfo.pilotOnBoard;
	}

	public berthVisitNotChronological() {
    return this.berthVisit.eta
      && this.berthVisit.etd
      && !this.berthVisit.ata
      && !this.berthVisit.atd
      && moment(this.berthVisit.eta).isSameOrAfter(moment(this.berthVisit.etd));
  }

	public previousBerthVisitNotChronological() {
    if (this.firstBerth || this.context.isOrganisationNextDeclarant()) {
      return false;
    }
    let berthVisit = this.berthVisit;
    if (berthVisit.ata || berthVisit.atd) {
      return false;
    }
    const date = berthVisit.eta ? berthVisit.eta : berthVisit.etd;
		let berthVisits = this.context.visit.visitDeclaration.portVisit.berthVisits;
    const previousBerthVisit = berthVisits[berthVisits.indexOf(berthVisit) - 1];
    const previousDate = previousBerthVisit.atd ? previousBerthVisit.atd :
      previousBerthVisit.etd ? previousBerthVisit.etd :
        previousBerthVisit.ata ? previousBerthVisit.ata : previousBerthVisit.eta;
    return date && previousDate && moment(previousDate).isSameOrAfter(moment(date))
  }

	mooringFormatter = (value: Mooring) => {
		switch (value) {
			case 'PORT_SIDE':
				return 'Port side';
			case 'STARBOARD_SIDE':
        return 'Starboard side';
      case 'ALONGSIDE_OTHER_SHIP':
        return 'Alongside other ship';
      case 'NO_PREFERENCE':
        return 'No preference';
      default:
        throw Error('Unknown mooring value: ' + value);
    }
  };

  terminalMooringFormatter = (value: TerminalMooring) => {
    switch (value) {
      case TerminalMooring.PORTSIDE:
        return 'Port side';
      case TerminalMooring.STARBOARD:
        return 'Starboard side';
      default:
        throw Error('Unknown mooring value: ' + value);
    }
  };

  formatBerth(berthVisit: BerthVisit) {
    if (berthVisit.berth?.name) {
      return berthVisit.berth.name;
    } else if (berthVisit.terminal?.terminalName) {
      return berthVisit.terminal.terminalName;
    } else if (this.context.visit.terminalPlanningEnabled && berthVisit.terminalPlanningEnabled) {
      if (!berthVisit.stevedore?.shortName) {
        return "New terminal planning";
      }
      let stevedoreName = PortvisitUtils.stevedoreFormatter(berthVisit.stevedore);
      let quay = berthVisit.quay || this.context.getTerminalVisit(berthVisit)?.info?.quay;
      if (quay) {
        return `${stevedoreName} - ${quay}`;
      }
      return stevedoreName;
    }

    return 'New berth visit';
  }

  formatBerthCode(berth: Berth) {
    let mooringPoles = berth ? berth["mooringPoles"] : null;
    return berth && berth.code ? ' - ' + berth.code.toUpperCase() + (mooringPoles ? " (" + mooringPoles + ")" : (berth.bollards ? ' (' + berth.bollards.start + '-' + berth.bollards.end + ')' : '')) : '';
  }

  getTimeAtTerminal(berthVisit: BerthVisit): string {
    if (berthVisit.eta && berthVisit.etd) {
      const duration = moment.duration(moment(berthVisit.etd)
        .diff(moment(berthVisit.eta)));
      const days = Math.floor(duration.asDays());
      const hours = Math.floor(duration.hours()); //hours instead of asHours
      const minutes = duration.minutes(); //minutes instead of asMinutes
      return days >= 3 ? days + ' days' : ((days > 0 ? (days + 'd') : '') + (hours + 'h' + (minutes ? minutes + 'm' : '')));
    }
  }

  get getTerminalVisitTooltipMessage(): string {
    const messageStatus: MessageStatus = VisitContext.getTerminalDeclarationStatus(this.berthVisit);
    switch (messageStatus) {
      case MessageStatus.DISABLED: return "Terminal schedule is disabled for this berth visit";
      case MessageStatus.UNKNOWN: return "The schedule has not yet been sent to the terminal";
      case MessageStatus.PENDING: return "The current schedule has been sent to the terminal";
      case MessageStatus.WARNING: return "The terminal has responded to the schedule but suggested different times/amount";
      case MessageStatus.DELIVERED: return "Schedule has been delivered to the terminal";
      case MessageStatus.ACCEPTED: return "The terminal has accepted the schedule";
      case MessageStatus.REJECTED: return this.terminalVisit.status.cancelled
        ? (VisitContext.visit.cancelled
          ? "The schedule has been cancelled due to the visit being cancelled"
          : "The terminal has removed the schedule from their system")
        : "The terminal has rejected the schedule with reason: " + (this.terminalVisit.status.rejectReasons?.join("; ") || "");
    }
  }

  get terminalTooltipHeader(): string {
    const messageStatus: MessageStatus = VisitContext.getTerminalDeclarationStatus(this.berthVisit);
      switch(messageStatus) {
        case MessageStatus.DISABLED: return "Disabled";
        case MessageStatus.UNKNOWN: return "Not send";
        case MessageStatus.PENDING: return "Declared";
        case MessageStatus.WARNING: return "Accepted with warnings";
        case MessageStatus.DELIVERED: return "Delivered";
        case MessageStatus.ACCEPTED: return "Accepted";
        case MessageStatus.REJECTED: return this.terminalVisit.status.cancelled
          ? (VisitContext.visit.cancelled ? "Cancelled by agent" : "Cancelled by terminal")
          : "Rejected";
      }
  }

  get getTerminalVisitBollardFrom() {
    return Math.min(this.terminalVisit.info.bollardFore, this.terminalVisit.info.bollardAft);
  }

  get getTerminalVisitBollardTo() {
    return Math.max(this.terminalVisit.info.bollardFore, this.terminalVisit.info.bollardAft);
  }
}
