<div class="row">
  <div class="col-md">
    <app-form-group label="Item number">
      <input required class="form-control" placeholder="Enter item number" [disabled]="true"
             appDecimalNumber [maxDecimals]="0" [(ngModel)]="item.itemNumber">
    </app-form-group>
    <app-form-group label="Description">
      <input class="form-control" placeholder="Enter goods description" required [(ngModel)]="item.description">
    </app-form-group>
    <app-form-group label="Commodity code">
      <app-search [(ngModel)]="item.classification" [inputFormatter]="refData.goodsClassificationFormatter"
                  [searchFunction]="refData.findClassifications"
                  dataKey="code" placeholder="Select a classification"></app-search>
    </app-form-group>
    <app-form-group label="Marks & numbers">
      <app-tags [(ngModel)]="item.marksAndNumbers" placeholder="Add entry"></app-tags>
    </app-form-group>
    <app-form-group label="Temperature range">
      <div class="input-group">
        <input [(ngModel)]="item.minimumTemperature" placeholder="Minimum" appDecimalNumber maxlength="100" class="form-control">
        <input [(ngModel)]="item.maximumTemperature" placeholder="maximum" appDecimalNumber maxlength="100" class="form-control">
        <span class="input-group-text">&deg;C</span>
      </div>
    </app-form-group>

    <app-form-group label="Danger information">
      <app-search [(ngModel)]="item.dangerInformation" dataKey="name"
                  [inputFormatter]="refData.dangerInformationFormatter"
                  [searchFunction]="refData.findDangerInformation"
                  [resultFormatter]="refData.dangerInformationFormatter"
                  placeholder="Find by name or un code"></app-search>
    </app-form-group>
  </div>

  <div class="col-md">
    <app-form-group label="Gross weight">
      <div class="input-group">
        <input required [(ngModel)]="item.grossWeight" [appCompare]="actualWeight" comparePreamble="Terminal reported: "
               appDecimalNumber [maxDecimals]="3" class="form-control">
        <span class="input-group-text">kg</span>
      </div>
    </app-form-group>
    <app-form-group label="Outer packaging">
      <app-select [(ngModel)]="item.outerPackageType" dataKey="code" placeholder="– Select packaging –"
                  (ngModelChange)="onOuterPackageChange()" [optionsProvider]="refData.getCargoPackageTypes"
                  [formatter]="refData.packageTypeFormatter" required></app-select>
    </app-form-group>
    <ng-container *ngIf="!item.outerPackageType?.bulk">
      <app-form-group label="Outer packages">
        <div class="input-group">
          <input [(ngModel)]="item.numberOfOuterPackages" appDecimalNumber [maxDecimals]="0" [min]="1"
                 class="form-control" required>
          <span class="input-group-text">#</span>
        </div>
      </app-form-group>
    </ng-container>
    <ng-container *ngIf="!item.outerPackageType?.bulk">
      <app-form-group label="Net weight">
        <div class="input-group">
          <input [(ngModel)]="item.netWeight" appDecimalNumber [maxDecimals]="3" class="form-control">
          <span class="input-group-text">kg</span>
        </div>
      </app-form-group>
      <app-form-group label="Inner packages">
        <div class="input-group">
          <input [(ngModel)]="item.numberOfInnerPackages" appDecimalNumber [maxDecimals]="0" [min]="1"
                 class="form-control">
          <span class="input-group-text">#</span>
        </div>
      </app-form-group>
      <app-form-group *ngIf="item.numberOfInnerPackages > 0" label="Inner packaging">
        <app-select [(ngModel)]="item.innerPackageType" dataKey="code"
                    [optionsProvider]="refData.getCargoPackageTypes"
                    [formatter]="refData.packageTypeFormatter"
                    required></app-select>
      </app-form-group>
    </ng-container>
  </div>
</div>
