import {Component, ElementRef, OnInit} from '@angular/core';
import {checkValidity, cloneObject, isEqual, removeItem, sendCommand, sendQuery} from '../common/utils';
import {AppContext} from '../app-context';
import {
  AuthoriseOrganisation,
  GetCurrentOrganisationPreferences,
  GetCurrentUserPreferences,
  OrganisationPreferences,
  UserPreferences
} from '@portbase/bezoekschip-service-typescriptmodels';

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.css']
})
export class PreferencesComponent implements OnInit {
  appContext = AppContext;
  organisationPreferences: OrganisationPreferences;
  userPreferences: UserPreferences;
  givenAuthorisations : { [index: string]: string[] } = {};

  mdpVesselVisitDataServiceIds = {
    "kt": "8623d3578aa54cc9bbc04ba0b6667436",
    "pcs": "b9fa74ced6fc4f6abdfd88d8fb89801b"
  };

  gsbnShortName: string = AppContext.isProduction() ? "HDYCTRKCGXAE" : "GSBN";
  autoRtaApplicationOkAgain: false;

  constructor(private element: ElementRef) {
  }

  ngOnInit() {
    this.givenAuthorisations = cloneObject(AppContext.userProfile.givenAuthorisations || {});
    sendQuery("com.portbase.bezoekschip.common.api.authorisation.GetCurrentOrganisationPreferences", <GetCurrentOrganisationPreferences>{})
      .subscribe(result => {
        this.organisationPreferences = result;
      });
    sendQuery("com.portbase.bezoekschip.common.api.authorisation.GetCurrentUserPreferences", <GetCurrentUserPreferences>{})
      .subscribe(result => {
        this.userPreferences = result;
      });
  }

  updateOrganisationPreferences() {
    sendCommand('com.portbase.bezoekschip.common.api.authorisation.UpdateCurrentOrganisationPreferences', {
      preferences: this.organisationPreferences
    }, () => AppContext.registerSuccess('Preferences updated successfully.'));

    const currentAuthorisations = AppContext.userProfile.givenAuthorisations || {};
    if (!isEqual(this.givenAuthorisations, currentAuthorisations)) {
      for (let organisation in this.givenAuthorisations) {
        if (this.givenAuthorisations[organisation].length > 0  && (!currentAuthorisations[organisation] || currentAuthorisations[organisation].length === 0)) {
          sendCommand('com.portbase.bezoekschip.common.api.accountmanagement.AuthoriseOrganisation', <AuthoriseOrganisation>{
            shortName: organisation,
            authorisingOrganisation: AppContext.userProfile.organisation?.shortName,
            roles : this.givenAuthorisations[organisation]
          });
        }
      }
      for (let organisation in currentAuthorisations) {
        if (currentAuthorisations[organisation].length > 0 && (!this.givenAuthorisations[organisation] || this.givenAuthorisations[organisation].length === 0)) {
          sendCommand('com.portbase.bezoekschip.common.api.accountmanagement.AuthoriseOrganisation', <AuthoriseOrganisation>{
            shortName: organisation,
            authorisingOrganisation: AppContext.userProfile.organisation?.shortName,
            roles : []
          });
        }
      }
      AppContext.userProfile.givenAuthorisations = cloneObject(this.givenAuthorisations);
    }
  }

  updateUserPreferences() {
    if (checkValidity(this.element)) {
      sendCommand('com.portbase.bezoekschip.common.api.authorisation.UpdateCurrentUserPreferences', {
        preferences: this.userPreferences
      }, () => AppContext.registerSuccess('Preferences updated successfully.'));
    }
  }

  updateAuthorisations(organisation: string, role: string, enable: boolean) {
    if (!this.givenAuthorisations[organisation]) {
      this.givenAuthorisations[organisation] = [];
    }
    if (enable) {
      this.givenAuthorisations[organisation].push(role);
    } else {
      removeItem(this.givenAuthorisations[organisation], role);
    }
  }

  getMyDataPortalUrl() {
    const mdpDataServiceId = this.mdpVesselVisitDataServiceIds[AppContext.environment];
    if (mdpDataServiceId) {
      return `https://www.mydataportal.${AppContext.environment}.portbase.com/data-services/${mdpDataServiceId}/sharing`;
    }
    return null;
  }

  autoApplyRtaFromFirstBerthTerminal() {
    return this.autoRtaApplicationOkAgain ? this.organisationPreferences.autoApplyRtaFromFirstBerthTerminal : false;
  }
}
