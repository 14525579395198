import {Component} from '@angular/core';
import {AbstractOverviewComponent} from '../../common/abstract-overview.component';
import {DateTimeRange, FindMidUpdates, MidUpdate} from '@portbase/bezoekschip-service-typescriptmodels';
import {Observable} from 'rxjs';
import {sendQuery} from '../../common/utils';

@Component({
  selector: 'app-mid-overview',
  templateUrl: './mid-overview.component.html',
  styleUrls: ['./mid-overview.component.css']
})
export class MidOverviewComponent extends AbstractOverviewComponent<MidUpdate> {
  entries : MidUpdate[] = []

  localStorageKey(): string {
    return "mid-overview";
  }

  doLoad = (dateTimeRange: DateTimeRange): Observable<MidUpdate[]> => sendQuery(
    'com.portbase.bezoekschip.common.api.transit.FindMidUpdates',
    <FindMidUpdates>{term: this.filterTerm, dateTimeRange: this.filterTerm ? null : dateTimeRange},
    {caching: false, showSpinner: this.items.length === 0});

  doRender(entries: MidUpdate[]) {
    this.entries = entries;
  }
}
