import {Component, ElementRef, Input} from '@angular/core';
import {
  BulkItem,
  DeclareManifestTransit,
  Item,
  ManifestBulkConsignmentData,
  ManifestBulkDeclarationData,
  ManifestBulkGoodsItemData,
  ManifestContainerDeclarationData,
  ManifestDeclarationData,
  SaveManifestTransit,
  TransitDeclaration,
  TransitTemplate
} from '@portbase/bezoekschip-service-typescriptmodels';
import {AppContext} from '../../app-context';
import {PortvisitUtils} from '../../refdata/portvisit-utils';
import moment from 'moment';
import {checkValidity, lodash, nanoId, sendCommand} from '../../common/utils';
import {initializeTransit, initializeTransitData} from '../transit.utils';

@Component({
  selector: 'app-batch-transit-declaration',
  templateUrl: './batch-transit-declaration.component.html',
  styleUrls: ['./batch-transit-declaration.component.css']
})
export class BatchTransitDeclarationComponent {
  appContext = AppContext;
  refData = PortvisitUtils;
  @Input() items: Item[] = [];
  templateData = initializeTransit(<TransitDeclaration>{});

  constructor(private element: ElementRef) {
    this.templateData.status.sendOnAta = true;
    this.templateData.status.sendDate = null;
  }

  findTransitTemplates = (term: string) =>
    this.refData.findTransitTemplates(term, AppContext.userProfile.organisation?.shortName);

  useTemplate = ($event: TransitTemplate) => {
    lodash.assign(this.templateData.data, $event.templateData);
    this.templateData.data = initializeTransitData(this.templateData.data);
    this.templateData.requestedTransportExpiryDate = moment().add(this.templateData.data.transportTerm ? this.templateData.data.transportTerm : 0, 'seconds').toISOString();
  }

  reset = () => {
    $('#preselectedDeclaration').modal('hide');
    $('#newContainerManifestDeclaration').modal('hide');
    $('#newBulkManifestDeclaration').modal('hide');
    $('#newManifestDeclarationContainerImport').modal('hide');
    setTimeout(() => {
      this.items.length = 0;
      this.templateData = initializeTransit(<TransitDeclaration>{});
    }, 0);
  };

  save() {
    sendCommand('com.portbase.bezoekschip.common.api.transit.SaveManifestTransit', <SaveManifestTransit>{
      declarantShortName: this.templateData.declarantShortName,
      data: this.templateData.data,
      sendDate: this.templateData.status.sendDate,
      sendOnAta: this.templateData.status.sendOnAta,
      declarations: this.getManifestDeclarationData()
    }, () => {
      AppContext.registerSuccess('The declarations are saved successfully.');
      this.reset();
    });
  }

  send() {
    if (checkValidity(this.element)) {
      sendCommand('com.portbase.bezoekschip.common.api.transit.DeclareManifestTransit', <DeclareManifestTransit>{
        declarantShortName: this.templateData.declarantShortName,
        data: this.templateData.data,
        sendDate: this.templateData.status.sendDate,
        sendOnAta: this.templateData.status.sendOnAta,
        declarations: this.getManifestDeclarationData()
      }, (numberSucceeded) => {
        if (numberSucceeded == this.items.length) {
          AppContext.registerSuccess(`${numberSucceeded === 1 ? 'Declaration' : 'All ' + numberSucceeded + ' declarations'} declared successfully`);
        } else {
          let remaining = this.items.length - numberSucceeded;
          AppContext.registerError(`${numberSucceeded} declaration${numberSucceeded === 1 ? '' : 's'} declared successfully. The other ${remaining === 1 ? 'declaration needs' : remaining + ' declarations need'} to be sent manually.`, "warning");
        }
        this.reset();
      });
    }
  }

  private getManifestDeclarationData(): ManifestDeclarationData[] {
    let containerData: ManifestContainerDeclarationData[] = this.items.filter(i => i.containerNumber)
      .map(c => {
        return <ManifestContainerDeclarationData>{
          "@class": "com.portbase.bezoekschip.common.api.transit.common.ManifestContainerDeclarationData",
          crn: c.crn,
          lrn: nanoId(),
          consignmentNumber: c.consignmentNumber,
          containerNumber: c.containerNumber
        }
      });

    let bulkData: ManifestBulkDeclarationData[] = lodash.chain(this.items.filter(i => !i.containerNumber))
      .groupBy(item => item.crn)
      .map((values, crn) => <ManifestBulkDeclarationData>{
        "@class": "com.portbase.bezoekschip.common.api.transit.common.ManifestBulkDeclarationData",
        crn: crn,
        lrn: nanoId(),
        consignments: lodash.chain(values.map(item => <BulkItem>item))
          .groupBy(item => item.consignmentNumber)
          .map((items, consignmentNumber) => <ManifestBulkConsignmentData>{
            consignmentNumber: consignmentNumber,
            goods: items.map(item => <ManifestBulkGoodsItemData>{
              itemNumber: item.itemNumber,
              grossWeight: item.grossWeight,
              nettWeight: item.netWeight
            })
          })
          .value()
      })
      .value();

    return lodash.merge(containerData, bulkData);
  }

}
