import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { VisitContext } from '../../../../visit-context';

@Component({
  selector: 'app-earliest-time-port-entry',
  templateUrl: './earliest-time-port-entry.component.html',
  styleUrls: ['./earliest-time-port-entry.component.css']
})
export class EarliestTimePortEntryComponent implements OnInit {

  context = VisitContext;

  constructor() { }

  ngOnInit() {
  }

  etaEarliestPBPIsAfterPBP() {
    const portEntry = VisitContext.visit.visitDeclaration.portVisit.portEntry;
    return !VisitContext.visit.visitDeclaration.portVisit.ataPort && portEntry && portEntry.etaPilotBoardingPlace
      && portEntry.earliestTimeOfPortEntry && moment(portEntry.earliestTimeOfPortEntry).isAfter(moment(portEntry.etaPilotBoardingPlace));
  }

  get isPlanningBasedOnPbp(): boolean {
    return this.context.visit.visitDeclaration.portVisit.portEntry != null &&
      this.context.visit.visitDeclaration.portVisit.portEntry.baseForPlanning == 'PILOT_BOARDING_PLACE';
  }
}
