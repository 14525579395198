import {Component, Input} from '@angular/core';
import {IE3GoodsItem} from '@portbase/bezoekschip-service-typescriptmodels/common';

@Component({
  selector: 'app-transhipment-goods',
  templateUrl: './transhipment-goods.component.html',
  styleUrls: ['./transhipment-goods.component.css']
})
export class TranshipmentGoodsComponent {
  @Input() goodsItems: IE3GoodsItem[];

  getContainerNumbers(item: IE3GoodsItem): string {
    return item.goodsPlacements.map(p => p.containerIdentificationNumber).join(", ");
  }
}
