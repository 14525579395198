import lodash, {cloneDeep} from "lodash";
import {FacetUtils} from "../facets/facet-utils";
import {FacetStats, OverviewFilters, QuickView} from "../facets/facet-filter/facet-filter.component";
import {FacetedOverviewFilters} from "./overview.component";
import {Observable} from "rxjs";
import {AfterViewInit, Directive} from "@angular/core";
import {FacetMatch, FacetValueResult} from "@portbase/bezoekschip-service-typescriptmodels";
import {CheckboxSelectionState, mergeIfNotExists} from "../../../common/utils";

@Directive()
export abstract class FacetedOverview<RESULT, FILTERS extends FacetedOverviewFilters<any>> implements AfterViewInit {
  private localStorageKey: string;
  filters: FILTERS;
  from: number = 0;
  maxItems: number = 100;
  endReached: boolean;
  loading: boolean;
  loadCount: number = 0;
  data: any[];
  hiddenFacets: string[] = [];
  facetOrdering: string[] = [];
  otherFacetNames: string[] = [];
  facets: FacetStats[];
  otherFacets: FacetStats[];
  allFacets: FacetStats[];
  overviewFiltersBeforeSearch: FILTERS;

  nameFormatter: (name: string) => string;
  getValueFormatter: (name: string) => (value: string) => string;
  filterFacetValues: (name: string, values: FacetValueResult[]) => FacetValueResult[];
  getValuesSelectedCallback: (name: string) => (facet: FacetStats, selectedValues: string[]) => string[];
  isSelectable: (record: RESULT) => boolean;

  abstract getData(term: string): Observable<any>;
  abstract renderRecords(result: any, append: boolean);
  abstract trackByRecord(index: number, record: RESULT): any;
  abstract getHiddenFacets(): string[];

  protected constructor(localStorageKey?: string, protected quickViews?: QuickView[], private defaultQuickView?: QuickView) {
    this.setLocalStorageKey(localStorageKey);
  }

  protected setLocalStorageKey(localStorageKey: string) {
    this.localStorageKey = localStorageKey;
    this.filters = this.getFiltersFromLocalStorage();
    this.filters.overviewFilters.selectedQuickView = this.defaultQuickView
  }

  ngAfterViewInit(): void {
    this.loadData();
  }

  get isEmpty() {
    return !this.loading && (!this.data || this.data.length === 0);
  }

  protected getFacetFilters() {
    const facetFilters = Object.entries(this.getMergedFacetFilters())
      .map(e => (<FacetMatch>{
        facetName: e[0],
        values: lodash.isArray(e[1]) ? e[1]?.filter(v => v) : [e[1]]
      })).filter(f => f.values?.length > 0);
    return facetFilters.filter(f => this.loadCount === 0 || this.allVisibleFacets.find(fs => f.facetName === fs.name));
  }

  protected getMergedFacetFilters() {
    const mergedFilters = cloneDeep(this.filters.overviewFilters.selectedQuickView?.facets || {});
    const overviewFilters = cloneDeep(this.filters.overviewFilters.facets || {});
    const hiddenFacets = cloneDeep(this.filters.overviewFilters.hiddenFacets || {});
    lodash.mergeWith(mergedFilters, overviewFilters, hiddenFacets, mergeIfNotExists);
    return mergedFilters;
  }

  get allVisibleFacets(): FacetStats[] {
    return (this.facets || []).concat(this.otherFacets || []);
  }

  get allSelectionState(): CheckboxSelectionState {
    const data = this.data || [];
    return data.length
      ? data.every(d => d["selected"])
        ? CheckboxSelectionState.selected : data.some(d => d["selected"])
          ? CheckboxSelectionState.indeterminate : CheckboxSelectionState.unselected : CheckboxSelectionState.unselected;
  }

  set allSelectionState(state: CheckboxSelectionState) {
    (this.data || []).filter(d => this.isSelectable(d)).forEach(d => d["selected"] = state === CheckboxSelectionState.selected
      ? CheckboxSelectionState.selected : state === CheckboxSelectionState.unselected
        ? CheckboxSelectionState.unselected : d["selected"]);
  }

  protected setFacetsFromResult(facets: { [p: string]: FacetValueResult[] }) {
    if (this.from && this.from > 0) {
      return;
    }
    this.allFacets = Object.entries(facets)
      .map(e => ({
        name: e[0],
        values: this.filterFacetValues ? this.filterFacetValues(e[0], e[1]) : e[1],
        nameFormatter: this.nameFormatter,
        valueFormatter: this.getValueFormatter(e[0]),
        valuesSelectedCallback: this.getValuesSelectedCallback ? this.getValuesSelectedCallback(e[0]) : null
      }));
    this.facets = lodash.sortBy(this.allFacets
      .filter(e => !this.hiddenFacets.includes(e.name.toLowerCase()))
      .filter(e => e.values.length > 0)
      .filter(e => !this.getHiddenFacets().includes(e.name)), f => this.facetOrdering.indexOf(f.name));
    this.otherFacets = this.facets.filter(f => this.isOtherFacet(f))
      .filter(f => !FacetUtils.isBooleanFacet(f) || (f.values.find(v => v.value === "true")?.count || 0) > 0);
    this.facets = this.facets.filter(f => !this.isOtherFacet(f));
  }

  private isOtherFacet(f: FacetStats) {
    return FacetUtils.isBooleanFacet(f) || this.otherFacetNames.includes(f.name);
  }

  protected searchTermFunction = (term: string): Observable<any> => {
    this.searchTermChanged(term);
    return this.getData(term);
  }

  protected searchTermChanged = (term: string): void => {
    this.from = 0;
    this.endReached = false;
    this.data = [];
    this.filters.overviewFilters.term = term;
    this.backupFiltersBeforeSearch();
    if (!term?.length && this.overviewFiltersBeforeSearch) {
      this.overviewFiltersBeforeSearch = null;
    }
  }

  protected facetFiltersChanged = (overviewFilters: OverviewFilters<any>) => {
    this.filters.overviewFilters = overviewFilters;
    this.saveFiltersInLocalStorage(this.filters);
    this.loadData();
  }

  protected clearFilters = (overviewFilters: OverviewFilters<any>) => {
    overviewFilters.selectedQuickView = this.filters.overviewFilters.selectedQuickView;
    this.filters.overviewFilters = overviewFilters;
    this.saveFiltersInLocalStorage(this.filters);
    this.loadData();
  }

  protected loadNextPage = () => {
    if (!this.endReached && !this.loading) {
      this.from += this.maxItems;
      this.loadData(true);
    }
  }

  protected loadData = (append: boolean = false) => {
    if (!append) {
      this.from = 0;
      this.endReached = false;
    }
    this.getData(this.filters.overviewFilters.term).subscribe(result => this.renderRecords(result, append));
  }

  private getFiltersFromLocalStorage = (): FILTERS => {
    if (this.localStorageKey) {
      return this.supportBackwardsCompatibility(lodash.merge(<FILTERS>{overviewFilters: FacetUtils.defaultOverviewFilters()},
        JSON.parse(localStorage.getItem(this.localStorageKey))));
    } else {
      return <FILTERS>{overviewFilters: FacetUtils.defaultOverviewFilters()};
    }
  }

  protected saveFiltersInLocalStorage = (filters: FILTERS) => {
    if (this.localStorageKey) {
      localStorage.setItem(this.localStorageKey, JSON.stringify(filters));
    }
  }

  private supportBackwardsCompatibility = (filters: FILTERS): FILTERS => {
    if (!filters) {
      return filters;
    }
    const term = filters.overviewFilters?.term || filters["term"];
    if (filters["facetFilters"]) {
      filters.overviewFilters = filters["facetFilters"];
      delete filters["facetFilters"];
    }
    if (term) {
      filters.overviewFilters.term = '';
      delete filters["term"];
    }
    filters.overviewFilters.filters = filters.overviewFilters.filters || {};
    return filters;
  }

  private backupFiltersBeforeSearch = () => {
    if (!this.overviewFiltersBeforeSearch && this.filters.overviewFilters.term?.length) {
      this.overviewFiltersBeforeSearch = cloneDeep(this.filters);
      this.filters.overviewFilters.facets = {};
      this.filters.overviewFilters.selectedQuickView = this.quickViews[0];
    }
  }
}
