import {Directive, ElementRef, HostListener, Input, OnInit} from '@angular/core';
import {NgModel} from '@angular/forms';

@Directive({
  selector: '[appDecimalNumber]'
})
export class DecimalNumberDirective implements OnInit {
  private regex: RegExp = new RegExp(/^[-]?\d*[,.]?\d*$/g);

  @Input() maxDecimals = 1;

  constructor(private input: ElementRef, private ngModel: NgModel) {
  }

  ngOnInit(): void {
    let element = this.input.nativeElement;
    element.setAttribute("type", "number");
    element.setAttribute("step", this.maxDecimals ? String(1 / Math.pow(10, this.maxDecimals)) : "1");
    if (this.ngModel.viewModel) {
      const subscription = this.ngModel.valueChanges.subscribe(value => {
        this.padValue();
        subscription.unsubscribe();
      });
    }
  }

  @HostListener('blur', ['$event'])
  onBlur(event: UIEvent) {
    this.padValue();
  }

  private padValue = () => {
    const element = this.input.nativeElement;
    const value: string = element.value;
    if (value && value.match(this.regex)) {
      if (!isNaN(this.maxDecimals)) {
        this.ngModel.update.emit(Number(Number.parseFloat(value).toFixed(this.maxDecimals)));
      }
    } else {
      element.value = null;
    }
  }

}
