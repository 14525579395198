import {Injectable} from '@angular/core';
import {WasteMaterial, WasteTypeSsn} from "@portbase/bezoekschip-service-typescriptmodels";
import {sendQuery} from "../common/utils";

@Injectable({providedIn: 'root'})
export class WasteService {
  constructor() {
    sendQuery('com.portbase.bezoekschip.common.api.visit.FindWasteMaterials', {}).subscribe((allMaterials: WasteMaterial[]) => {
      this.wasteMaterials = allMaterials;
    })
  }

  wasteMaterials: WasteMaterial[] = [];

  getWasteMaterials(): WasteMaterial[] {
    return this.wasteMaterials;
  }

  getWasteMaterialsBySsn(ssn: WasteTypeSsn): WasteMaterial[] {
    if (ssn == null) {
      return this.wasteMaterials;
    }
    return this.wasteMaterials.filter(m => m.ssn.code === ssn.code)
      .sort((w1, w2) =>
        w1.nameEN.toLowerCase().includes('refundable') ? 1
          : w2.nameEN.toLowerCase().includes('refundable') ? -1
            : w1.nameEN.toLowerCase().localeCompare(w2.nameEN.toLowerCase()));
  }

}
