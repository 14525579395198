import {Component, Input, OnInit} from '@angular/core';
import {PortvisitUtils} from '../../refdata/portvisit-utils';
import {
  ApiCustomer,
  CustomerReference,
  CustomerReferenceStatus,
  RejectCustomerReference,
  VerifyCustomerReference
} from '@portbase/bezoekschip-service-typescriptmodels';
import {AppContext} from '../../app-context';
import {lodash, openConfirmationModalWithCallback, sendCommand} from "../../common/utils";
import {ModalConfirmAutofocus, ModalConfirmAutofocusData} from "../../common/modal/modal-confirm.component";

@Component({
  selector: 'app-release-to-party',
  templateUrl: './release-to-party.component.html',
  styleUrls: ['./release-to-party.component.css']
})
export class ReleaseToPartyComponent implements OnInit {
  utils = PortvisitUtils;
  appContext = AppContext;
  @Input() model: ApiCustomer;

  savedCustomer: ApiCustomer;

  ngOnInit(): void {
    this.savedCustomer = lodash.cloneDeep(this.model);
  }

  carrierFormatter = (value) => value?.fullName || this.model?.ownerFullName || value;

  customerFormatter = (value) => value?.fullName || this.model?.customerData?.fullName || value;

  verify(value: ApiCustomer, customerReference: CustomerReference) {
    if (!value['new']) {
      sendCommand('com.portbase.bezoekschip.common.api.accountmanagement.VerifyCustomerReference', <VerifyCustomerReference>{
        shortName: AppContext.userProfile.organisation?.shortName,
        customerShortName: value.portbaseId,
        customerReference: customerReference.reference
      }, () => {
        customerReference.status = CustomerReferenceStatus.VERIFIED;
        customerReference.rejectReason = null;
        delete customerReference['undoActive'];
        this.savedCustomer.references[customerReference.reference] = lodash.cloneDeep(customerReference);
        AppContext.registerSuccess("Customer reference was verified successfully");
      });
    }
  }

  reject = (value: ApiCustomer, customerReference: CustomerReference) => {
    if (!value['new']) {
      openConfirmationModalWithCallback((confirmed, reason) => {
        if (confirmed) {
          this.doRejection(value, customerReference, reason);
        }
      }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
        type: "danger",
        title: "Delete customer reference",
        message: "You are about to delete customer reference [" + customerReference.reference + "]",
        question: "Are you sure?",
        confirmText: "Yes",
        cancelText: "No",
        withReason: true,
      }, 'static');
    }
  }

  private doRejection(value: ApiCustomer, customerReference: CustomerReference, rejectReason: string) {
    sendCommand('com.portbase.bezoekschip.common.api.accountmanagement.RejectCustomerReference', <RejectCustomerReference>{
      shortName: AppContext.userProfile.organisation?.shortName,
      customerShortName: value.portbaseId,
      rejectReason: rejectReason,
      customerReference: customerReference.reference
    }, () => {
      customerReference.status = CustomerReferenceStatus.REJECTED;
      customerReference.rejectReason = rejectReason;
      delete customerReference['undoActive'];
      this.savedCustomer.references[customerReference.reference] = lodash.cloneDeep(customerReference);
      AppContext.registerSuccess("Customer reference was rejected successfully");
    });
  }

  reset(value: ApiCustomer, customerReference: CustomerReference) {
    if (!value['new']) {
      customerReference.status = CustomerReferenceStatus.NEW;
      customerReference.rejectReason = null;
      customerReference['undoActive'] = true;
    }
  }

  addCustomerReference($event: any) {
    this.model['additions'] = $event;
  }

  undo(value: ApiCustomer, customerReference: CustomerReference) {
    let savedCustomerReference: CustomerReference = this.savedCustomer.references[customerReference.reference];
    customerReference.status = savedCustomerReference.status;
    customerReference.rejectReason = savedCustomerReference.rejectReason;
    delete customerReference['undoActive'];
  }
}
