import {Component, Input} from '@angular/core';
import {DangerInformation} from '@portbase/bezoekschip-service-typescriptmodels';
import {PortvisitUtils} from '../portvisit-utils';

@Component({
  selector: 'app-edit-danger-information',
  templateUrl: './edit-danger-information.component.html',
  styleUrls: ['./edit-danger-information.component.css']
})
export class EditDangerInformationComponent {
  context = PortvisitUtils;
  @Input() dangerInformation : DangerInformation;

}
