import {Component, ElementRef, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {Carrier, Country, IE3CL707, IE3Communication} from "@portbase/bezoekschip-service-typescriptmodels";
import {PortvisitUtils} from "../../../../../refdata/portvisit-utils";
import {ConsignmentUtils} from "../../../consignment.utils";
import {cloneDeep} from "lodash";
import {checkValidity, clearValidation} from "../../../../../common/utils";

@Component({
  selector: 'app-consignment-carrier-details',
  templateUrl: './consignment-carrier-details.component.html',
  styleUrls: ['./consignment-carrier-details.component.scss']
})
export class ConsignmentCarrierDetailsComponent implements OnInit {
  portVisitUtils = PortvisitUtils;
  utils = ConsignmentUtils;
  elementRef: ElementRef = inject(ElementRef);

  @Input() cargoDeclarantId: string;
  @Input() label: string;
  carrier: Carrier;
  @Input() editMode: boolean;
  @Input() required: boolean;

  carrierEditMode: boolean;
  backupCarrier: Carrier;
  defaultTelephone: IE3Communication = { type: IE3CL707.TE };
  defaultEmail: IE3Communication = { type: IE3CL707.EM };
  countries: Map<string, Country>;

  @Output() carrierChange: EventEmitter<Carrier> = new EventEmitter<Carrier>();

  countryFormatter = (value: Country | string) => typeof value === "string"
    ? this.countries?.get(value) ? this.countryFormatter(this.countries.get(value)) : value
    : ((value.name && value.code) ? `${value.name} - ${value.code}` : '');

  @Input("carrier")
  set setCarrier(carrier: Carrier) {
    this.carrier = this.initialiseCarrier(carrier);
  }

  ngOnInit() {
    ConsignmentUtils.getCountries().subscribe(c => this.countries = c);
  }

  get telephone() {
    return this.carrier.communications.find(c => c.type === IE3CL707.TE) || this.defaultTelephone;
  }

  get email() {
    return this.carrier.communications.find(c => c.type === IE3CL707.EM) || this.defaultEmail;
  }

  onCountryChange(country: Country) {
    this.carrier.address.country = country?.code;
  }

  toggleEditMode = () => {
    this.carrierEditMode = !this.carrierEditMode;
    if (this.carrierEditMode) {
      this.backupCarrier = cloneDeep(this.carrier);
    }
  }

  cancel = () => {
    this.carrier = this.backupCarrier;
    this.toggleEditMode();
    clearValidation(this.elementRef);
  }

  save = () => {
    this.carrier.communications = this.carrier.communications.filter(c => c.identifier);
    if (checkValidity(this.elementRef)) {
      this.processModel();
      this.toggleEditMode();
      this.carrierChange.emit(this.carrier);
    }
  }

  processModel = () => {
    if (!this.carrier.communications?.find(c => c.type === IE3CL707.TE) && this.defaultTelephone.identifier) {
      this.carrier.communications.push(this.defaultTelephone);
    }
    if (!this.carrier.communications?.find(c => c.type === IE3CL707.EM) && this.defaultEmail.identifier) {
      this.carrier.communications.push(this.defaultEmail);
    }
    this.carrier.communications = this.carrier.communications?.filter(c => c.identifier) || [];
  }

  newCarrier = (value: string): Carrier => {
    const carrier: Carrier = this.initialiseCarrier({
      name: value
    });
    carrier["isNewRecord"] = true;
    return carrier;
  }

  onCarrierChange = (value: Carrier) => {
    if (value?.scacCode === "XXXX") {
       value = this.newCarrier(value.name);
    }
    this.carrier = value;
    if (value && value["isNewRecord"]) {
      this.carrierEditMode = true;
    }
    if (!this.carrierEditMode) {
      this.carrierChange.emit(this.carrier);
    }
  }

  private initialiseCarrier(carrier: Carrier) {
    if (carrier == null) {
      return null;
    }
      carrier.address = carrier.address || {};
      carrier.communications = carrier.communications || [];
      carrier.boxOperators = carrier.boxOperators || [];
     return carrier;
  }
}
