import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {AbstractValueAccessorComponent} from '../component/value-accessor.component';
import {Observable} from 'rxjs';
import {extractValue, uuid} from '../utils';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.css'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => RadioComponent), multi: true}
  ],
  host: {'class' : 'd-block'}
})
export class RadioComponent extends AbstractValueAccessorComponent<any> implements OnInit {
  selectedValue;
  @Input() optionsProvider: Observable<any[]>;
  @Input() options: any[];
  @Input() dataKey: string;
  @Input() id = uuid();
  @Input() formatter;
  @Input() inline: boolean;
  @Input() required;
  @Input() disabled: boolean;

  get value(): any {
    return this.selectedValue;
  }

  writeValue(value: any): void {
    this.selectedValue = value === undefined ? null : value;
  }

  ngOnInit(): void {
    if (this.required === "") {
      this.required = true;
    }
    if (this.optionsProvider) {
      this.optionsProvider.subscribe(values => {
        this.options = values;
      });
    }
    if (!this.formatter) {
      this.formatter = value => this.dataKey ? extractValue(value, this.dataKey) : value;
    }
  }
}
