import {Component, Input} from '@angular/core';
import {VisitContext} from '../../visit-context';

@Component({
  selector: 'app-timeline-icon',
  templateUrl: './timeline-icon.component.html',
  styleUrls: ['./timeline-icon.component.scss']
})
export class TimelineIconComponent {

  context = VisitContext;

  @Input() shipStatus: string;
}
