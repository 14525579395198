<ng-container *ngIf="sendOnAtaEnabled">
  <app-form-group [label]="readonly && declaration.status.sent?.date ? 'Sent on ATA berth' : 'Send on ATA berth'">
    <app-yes-no [(ngModel)]="!!declaration.status.sendOnAta"
                (ngModelChange)="onToggleSendOnAta()"
                [disabled]="readonly"></app-yes-no>
  </app-form-group>
  <app-form-group *ngIf="!declaration.status.sendOnAta || declaration.status.sent?.date"
                  [label]="readonly && declaration.status.sent?.date ? 'Date sent' : 'Send date'">
    <app-date-field [minDate]="minDate"
                   [disabled]="readonly"
                   (change)="emitChange()"
                   [calendarStartDate]="today" [dateOnly]="true" [required]="true"
                   [(ngModel)]="declaration.status.sent?.date ? declaration.status.sent?.date : declaration.status.sendDate"></app-date-field>
  </app-form-group>
</ng-container>
<app-form-group *ngIf="!sendOnAtaEnabled"
                [label]="readonly && declaration.status.sent?.date ? 'Date sent' : 'Send date'">
  <app-date-field [minDate]="minDate"
                 [disabled]="readonly"
                 (change)="emitChange()"
                 [calendarStartDate]="today" [dateOnly]="true" [required]="true"
                 [(ngModel)]="declaration.status.sent?.date ? declaration.status.sent?.date : declaration.status.sendDate"></app-date-field>
</app-form-group>
