<div #dropdown class="dropdown">
  <button #button class="btn btn-bg-secondary text-start d-flex justify-content-between align-items-center w-100 p-2" type="button"
          [ngClass]="{'active': hasValue}" data-bs-toggle="dropdown" data-bs-auto-close="outside">
    <div class="d-flex flex-column mw-0 flex-grow-1 me-3">
      <span class="d-inline-block text-truncate">{{getFacetName()}}</span>
      <div *ngIf="hasValue" class="mt-1 text-secondary d-inline-block text-truncate">
        {{selectedValueFormatted}}
      </div>
    </div>
    <i *ngIf="!hasValue" class="fa fa-fw fa-plus text-secondary"></i>
  </button>
  <div class="btn-close-wrapper rounded-circle position-absolute end-0 me-2 mt-2 top-0" [ngClass]="{'d-none': !hasValue}">
    <button type="button" class="btn-close" (click)="removeFilter()" aria-label="Remove filter"></button>
  </div>
  <div class="dropdown-menu min-w-100 overflow-hidden dropdown-menu-flex" [style.max-height.px]="320">
    <div class="d-flex flex-column w-100 mh-100">
      <div class="p-3 border-bottom">
        <app-local-filter [appAutoFocus]="true" [autoFocusInOverlay]="true" placeholder="Search..."
                          [data]="facetValues" [term]="term" class="border-0"
                          (updated)="updateFilteredValues($event)" (termChanged)="term = $event"></app-local-filter>
      </div>
      <app-scroller (endReached)="loadNextPage()" class="overflow-y-auto">
        <ul class="list-group">
          <ng-container *ngFor="let facetValue of filteredValues; index as i; trackBy: trackByFacetValue">
            <ng-container *ngIf="facetValue">
              <li class="list-group-item list-group-item-action border-0 d-flex align-items-stretch p-0">
                <app-facet-filter-value [term]="term" [facet]="facet" [facetValue]="facetValue" [selectedValues]="selectedValues"
                                        (valueSelected)="facetValueSelected(facetValue, $event)"
                                        class="w-100 h-100"></app-facet-filter-value>
              </li>
            </ng-container>
          </ng-container>
        </ul>
      </app-scroller>
    </div>
  </div>
</div>
