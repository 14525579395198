import {Component, Input} from '@angular/core';
import {Port} from "@portbase/bezoekschip-service-typescriptmodels";
import {PortvisitUtils} from '../portvisit-utils';

@Component({
  selector: 'app-edit-port',
  templateUrl: './edit-port.component.html',
  styleUrls: ['./edit-port.component.css']
})
export class EditPortComponent {
  utils = PortvisitUtils;
  @Input() port : Port;
}
