<ng-template #eta>
  <app-date-field
    [ngModel]="berthVisit.eta" (ngModelChange)="onEtaChanged($event)"
    [required]="isZeelandPort() || isPlanningBasedOnFirstBerth() || berthVisit.terminalPlanningEnabled"
    [calendarStartDate]="berthVisit.ata || berthVisit.etd"
    [disabled]="editEtaDisabled"
    id="{{berthVisit.id}}-eta"
    [appCompare]="rtaOrEtaQuarterHour" [compareFormatter]="formatDate" [showComparison]="false">
  </app-date-field>
  <div *ngIf="showUseTerminalRtaRequest()" style="text-align: end;">
    <input type="checkbox" [ngModel]="etaMatchesRta()" (ngModelChange)="useTerminalRta($event)"/>
    &nbsp;Use Requested Arrival
    <app-info>
      <span class="tooltipContent">Use Requested Arrival from terminal, rounded to nearest quarter</span>
    </app-info>
  </div>
  <span *ngIf="previousBerthVisitNotChronological()" class="ng-invalid invalid-feedback mb-2">
    ETA is before the departure of the previous berth
  </span>
</ng-template>

<ng-template #etd>
  <app-tidal-window [status]="getBerthVisitInfo()?.harbourMasterInfo?.tidalWindowStatus"
                    [level]="berthVisit.nextMovement?.order ? 'error' : 'warning'">
    <app-date-field [ngModel]="berthVisit.etd" (ngModelChange)="onEtdChanged($event)"
                    [calendarStartDate]="berthVisit.ata || berthVisit.eta"
                    [required]="berthVisit.nextMovement.order || berthVisit.terminalPlanningEnabled"
                    id="{{berthVisit.id}}-etd" [disabled]="!!berthVisit.atd" class="{{etdMatchesEtc() ? '' : 'has-diff'}}"></app-date-field>
    <div *ngIf="showUseTerminalEtcRequest()" style="text-align: end;">
      <input type="checkbox" [ngModel]="etdMatchesEtc()" (ngModelChange)="useTerminalEtc($event)"/>
      &nbsp;Use Estimated Completion
      <app-info>
        <span class="tooltipContent">Use Estimated Completion from Terminal, rounded to nearest quarter</span>
      </app-info>
    </div>
  </app-tidal-window>
  <span *ngIf="berthVisitNotChronological()" class="ng-invalid invalid-feedback mb-2">
    Departure should be after arrival
  </span>
</ng-template>

<ng-template #lastEtd>
  <app-date-field [(ngModel)]="lastBerthVisitDeparture().etd"
                  [calendarStartDate]="berthVisit.ata || berthVisit.eta" id="lastBerthVisit"></app-date-field>
  <span *ngIf="berthVisitNotChronological()" class="ng-invalid invalid-feedback mb-2">
    Departure should be after arrival
  </span>
</ng-template>
