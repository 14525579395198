import {Component, OnDestroy} from '@angular/core';
import {AppContext} from '../../app-context';
import {VisitContext} from '../visit-context';
import {ActivatedRoute, Router} from '@angular/router';
import {CargoImportModel} from './cargo-import.model';
import {PortvisitUtils} from '../../refdata/portvisit-utils';

@Component({
  selector: 'app-cargo-import',
  templateUrl: './cargo-import.component.html',
  styleUrls: ['./cargo-import.component.scss']
})
export class CargoImportComponent implements OnDestroy {
  appContext = AppContext;
  context = VisitContext;
  utils = PortvisitUtils;
  filterTerm : string;

  constructor(private router: Router, route: ActivatedRoute) {
    route.params.subscribe(params => {
      if (AppContext.isAdminOrCustoms()) {
        const shortName = params['shortName'];
        this.filterTerm = params['filterTerm'];
        if (shortName) {
          CargoImportModel.initializeForCargoAgent(shortName);
        } else {
          AppContext.navigateWithHistory(this.router, '/details/' + VisitContext.savedVisit.crn + '/cargoImportOverview');
        }
      } else if (VisitContext.isUserCargoImportViewer()) {
        CargoImportModel.initializeForCargoAgent(AppContext.userProfile.organisation?.shortName);
      } else {
        AppContext.navigateWithHistory(this.router,'/details/' + VisitContext.savedVisit.crn);
      }
    });
  }

  ngOnDestroy() {
    delete VisitContext.cargoImportModel;
    delete VisitContext.savedImportDeclaration;
  }
}
