<div class="border-bottom container-fluid mw-100 bg-light">
  <div class="row px-3 py-2 gx-4 main-row" *ngIf="container">
    <div class="d-none col-auto d-md-flex align-items-center" [style.width.px]="selectionWidth">
      <ng-container *ngIf="selectable">
        <app-selection-checkbox [(ngModel)]="selectionState"></app-selection-checkbox>
      </ng-container>
    </div>

    <div class="col-auto col-vlg-3 col-xxl-2 d-flex align-items-center" [style.padding-left]="indentionDepth+'px'">
      <button type="button" class="btn text-end p-2 me-2 rounded-circle" (click)="collapsed = !collapsed"
              [ngClass]="collapsible ? '' : 'invisible'"
              data-bs-toggle="collapse" [attr.data-bs-target]="'#'+validSelector(container.containerNumber)"
              [attr.aria-controls]="validSelector(container.containerNumber)" aria-expanded="false">
        <span class="h1 m-0 align-top fa-light fa-fw text-primary"
              [ngClass]="collapsed ? 'fa-chevron-right' : 'fa-chevron-down'"></span>
      </button>
      <span class="sub-text me-2">
        <i class="fa fa-fw fa-light fa-xl fa-container-storage"></i>
      </span>
      <div class="d-flex flex-column align-items-stretch justify-content-center">
        <span class="d-inline-block text-truncate mw-100">{{ container.containerNumber }}</span>
        <span class="sub-text">Container number</span>
      </div>
    </div>

    <div class="d-none col-3 col-vlg-2 col-xxl-2 d-lg-flex flex-column align-items-stretch justify-content-center">
      <a class="d-inline-block text-truncate mw-100 underline-tertiary link-underline-tertiary link-primary text-body"
         [routerLink]="'/details/'+container.crn">
        <app-highlight [term]="term" [result]="container.crn" emptyText="n/a"></app-highlight>
      </a>
      <span class="sub-text">Call reference number</span>
    </div>

    <div class="col col-vlg-2 d-flex flex-column align-items-stretch justify-content-center">
      <span class="text-truncate mw-100">
        <app-highlight [term]="term" [result]="formatDate(container.terminalDataSummary?.actualTimeOfHandling)"
                       emptyText="n/a"></app-highlight>
      </span>
      <span class="sub-text">Discharge time</span>
    </div>

    <div class="d-none col-vlg col-xl-2 d-vlg-flex flex-column align-items-stretch justify-content-center text-truncate">
      <ng-container *ngIf="container.terminalDataSummary?.terminal">
        <span class="d-inline-block text-truncate mw-100">{{ container.terminalDataSummary.terminal }}</span>
        <span class="sub-text">Terminal</span>
      </ng-container>
    </div>

    <div class="d-none d-xl-flex col-vlg-2 col-xxl-3 flex-column align-items-stretch justify-content-center">
      <ng-container *ngIf="container.terminalDataSummary?.containerOperator">
        <span class="d-inline-block text-truncate mw-100">{{ container.terminalDataSummary?.containerOperator }}</span>
        <span class="sub-text">Container operator</span>
      </ng-container>
    </div>

    <div class="col-auto ms-auto p-0"></div>
    <div *ngIf="container.cargoDeclarantId" class="col-auto d-flex flex-row align-items-center dropdown px-2">
      <button *ngIf="appContext.isAdmin()" class="btn rounded-circle p-2 text-primary h1 m-0" type="button"
              data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i class="fa-light fa-fw fa-ellipsis-vertical"></i>
      </button>
      <div class="dropdown-menu position-fixed">
        <a *ngIf="container.crn" class="dropdown-item" [routerLink]="'/details/' + container.crn" target="_blank">
          <i class="fa-light fa-fw fa-right-to-bracket"></i>
          Open visit
        </a>
      </div>
    </div>
  </div>
</div>

<div *ngIf="collapsible" class="collapse" [id]="validSelector(container.containerNumber)">
  <ng-container *ngFor="let consignment of container.consignmentDataSummaries; let i = index;">
    <app-container-consignment-overview-item [consignment]="consignment" [term]="term" [depth]="depth + 1"
                                             [containerNumber]="container.containerNumber" [cargoDeclarantId]="container.cargoDeclarantId"
                                             [selectable]="selectable" [collapsible]="false">
    </app-container-consignment-overview-item>
  </ng-container>
</div>
