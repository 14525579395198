<form (ngSubmit)="addValue(newValue.value); newValue.value = ''">
  <div class="row narrow-gutters">
    <ng-container *ngFor="let v of values">
      <div class="col-auto my-2 values">
        <span class="w-100 d-flex badge text-bg-secondary fw-normal">
          <span class="text-truncate d-flex align-items-center">
            <ng-container *ngIf="itemTemplate; else noTemplate"
                          [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{'item' : v}">
            </ng-container>
          </span>
          <button type="button" (click)="deleteValue(v)" [disabled]="readonly" class="btn-close ms-2"></button>
        </span>
        <ng-template #noTemplate>{{formatter(v)}}</ng-template>
      </div>
    </ng-container>
    <div class="col my-2 input-wrapper" style="min-width: 8rem">
      <input #newValue [placeholder]="placeholder || 'Add value'" class="w-100 border-secondary text-dark"
             style="border-width: 0 0 1px 0; line-height: 1.5rem; outline: 0" [required]="required && values.length==0"
             [readonly]="readonly"
             (input)="onInput.emit(newValue.value)"
             (blur)="addValue($event.target.value); $event.target.value = ''">
    </div>
  </div>
</form>
