<!--  <div>{{context.importDeclaration | json}}</div>-->
<ng-container *ngIf="context.cargoImportModel?.manifests as manifests">

  <app-status-alert [type]="'info'" *ngIf="!context.savedVisit.cargoImportEnabled
  || !context.cargoImportModel?.cargoDeclarant.cargoImportEnabled">
    Cargo import 2.0 not enabled for agent on visit
  </app-status-alert>


  <div class="row narrow-gutters align-items-center mt-4 mb-3">
    <ng-container>
      <div class="col-auto mx-2">
        <app-cargo-filter (changed)="applyImportFilters($event)"></app-cargo-filter>
      </div>
      <div class="col always-enabled">
        <app-local-filter [term]="filterTerm" (termChanged)="onTermChange($event)"
                          (updated)="filteredManifests = $event" [data]="manifests"
                          [filterFunction]="filterManifest"
                          [placeholder]="'Filter by consignment nr, good name, etc.'"></app-local-filter>
      </div>
      <div class="mt-3 mt-sm-0 col-sm-auto ms-2" *ngIf="context.cargoImportModel.isEditor() && context.cargoImportModel.declarations.length === 0">
        <button (click)="saveCargoImport()" style="min-width: 9rem;"
                type="button" class="btn btn-outline-info btn-block me-2" tabindex="-1">Save
        </button>
      </div>
      <div class="mt-3 mt-sm-0 col-sm-auto ms-2" *ngIf="context.cargoImportModel.isEditor()">
        <button (click)="declareCargoImport()" style="min-width: 9rem;"
                type="button" class="btn btn-info btn-block" tabindex="-1">Save and Send
        </button>
      </div>
    </ng-container>
  </div>


  <div class="row mb-2">
    <div class="col-auto me-auto">
      <span class="dropdown mx-1 always-enabled">
        <button class="btn btn-info border-0 bg-transparent text-info dropdown-toggle py-2"
                style="min-width: 8rem" type="button" data-bs-toggle="dropdown"><span
          class="fa fa-file-download me-1"></span> Downloads
        </button>
        <ul class="dropdown-menu dropdown-menu-start" style="cursor: pointer">
          <li (click)="context.cargoImportModel.downloadConsignments(context.cargoImportModel.manifests)"
              class="dropdown-item">
            B/L details
          </li>
          <li (click)="context.cargoImportModel.downloadDischargeList()" class="dropdown-item">
            Container details
          </li>
          <li (click)="context.cargoImportModel.downloadMrns(context.cargoImportModel.manifests)" class="dropdown-item">
            MRNs
          </li>
          <li (click)="context.cargoImportModel.downloadClearanceDifferences(context.cargoImportModel.manifests)" class="dropdown-item">
            Clearance differences
          </li>
        </ul>
      </span>

      <span class="dropdown mx-1" *ngIf="context.cargoImportModel.isEditor()">
        <button class="btn btn-outline-info border-0" type="button" popover
                [popoverContent]="findVisit" popoverCssClass="find-visit-popover"
                popoverPlacement="bottom-start">
          <span class="fa fa-copy"></span> <span class="d-none d-sm-inline ms-1">Copy from Visit</span>
        </button>
        <ng-template #findVisit>
          <app-form-group>
            <app-info class="formGroupLabel">
              <label>Visit to copy</label>
              <div class="tooltipContent">Only the cargo discharged in this port of call will be copied.</div>
            </app-info>
            <app-search [ngModel]="null" (ngModelChange)="$event ? copyCargoOfVisit($event.crn) : null"
                        placeholder="Find other visits" dataKey="crn"
                        [minCharacters]="2" [searchFunction]="utils.findVisitsWithCargoEnabled"></app-search>
          </app-form-group>
        </ng-template>
      </span>


    </div>

    <div class="col-auto ms-auto me-1" *ngIf="context.cargoImportModel.isEditor()">
      <div class="nav-item dropdown">
        <a class="nav-link dropdown-toggle px-0" data-bs-toggle="dropdown" href="#" role="button">
          <span class="pe-2 fa fa-plus-circle"></span><span class="d-none d-sm-inline">Add consignment</span></a>
        <div class="dropdown-menu dropdown-menu-end">
          <a class="dropdown-item" href="#" *ngFor="let previousPort of utils.getPreviousPorts()"
             (click)="addConsignment(previousPort); $event.preventDefault()">
            From: {{previousPort.name | titlecase}}</a>
        </div>
      </div>
    </div>
  </div>

  <div class="accordion" id="manifestAccordion">
    <div *ngFor="let manifest of filteredManifests; trackBy : byManifestId" class="mb-3">
      <div class="row g-0">
        <div class="col ms-1">
          <app-collapse #manifestCollapses [collapse]="manifestTemplate"
                        [showOnLoad]="manifest.declarations.length === 0 && manifest.overlanders.length === 0"
                        [validationModel]="manifest">
            <div class="row pe-3 g-0 align-items-center">
              <ng-container [ngSwitch]="getDeclarationStatus(manifest)">
                <div class="col-auto me-3 p-3 bg-info" *ngSwitchCase="'DECLARED'"
                     title="Declaration has been sent but we haven't received a response">
                  <span class="fa fa-fw fa-paper-plane text-white"></span>
                </div>
                <div class="col-auto me-3 p-3 bg-portbase-green" *ngSwitchCase="'ACCEPTED'"
                     title="ATO has been accepted">
                  <span class="fa fa-fw fa-check text-white"></span>
                </div>
                <div class="col-auto me-3 p-3 bg-portbase-red" *ngSwitchCase="'REJECTED'"
                     title="Declaration has been rejected">
                  <span class="fa fa-fw fa-times text-white"></span>
                </div>
                <div class="col-auto me-3 p-3" *ngSwitchDefault>
                  <span class="fa fa-fw fa-route text-dark"></span>
                </div>
              </ng-container>
              <div class="col fw-bold text-dark">
                {{manifest.portOfLoading?.name | titlecase}} ({{manifest.portOfLoading?.locationUnCode}})
                to {{manifest.portOfDischarge?.name | titlecase}}
              </div>
              <div class="col-auto d-none d-md-block text-dark">
                <app-cargo-summary [manifest]="manifest" [keepTooltipOpen]="manifestCollapses.showing"></app-cargo-summary>
              </div>
            </div>
          </app-collapse>
        </div>
      </div>

      <ng-template #manifestTemplate>
        <div class="collapse" data-bs-parent="#manifestAccordion">
          <div class="mt-3">
            <ng-container *ngIf="getRejectReasons(manifest) as rejectReasons">
              <app-status-alert type="danger" *ngIf="rejectReasons.length > 1">
                <ul class="my-0 py-0">
                  <li *ngFor="let rejectReason of rejectReasons">
                    {{rejectReason}}
                  </li>
                </ul>
              </app-status-alert>
              <app-status-alert type="danger" *ngIf="rejectReasons.length === 1">
                <div>Customs: {{rejectReasons[0]}}</div>
              </app-status-alert>
            </ng-container>

            <ng-container *ngIf="noCargoDeclarationToCustoms(manifest)">
              <app-status-alert type="warning">
                <div>No additional declarations to customs required/possible, since the port of call is no longer the first entry into the EU</div>
              </app-status-alert>
            </ng-container>

            <div class="d-block ms-4 position-relative">
              <app-tab-panel>
                <app-tab-item [templateRef]="consignmentsRef">
                  <span class="tab-item" [ngClass]="{'ng-invalid text-invalid' : utils.isInvalid(manifest.consignments)}">Consignments</span>
                  <ng-template #consignmentsRef>
                    <div class="ms-n4 my-3">
                      <app-consignments [manifest]="manifest"></app-consignments>
                    </div>
                  </ng-template>
                </app-tab-item>
                <app-tab-item [templateRef]="containersRef">
                  <span class="tab-item" [ngClass]="{'ng-invalid text-invalid' : utils.isInvalid(manifest.containers)}">Containers</span>
                  <ng-template #containersRef>
                    <ul class="nav position-absolute" style="top: 0; right: 0">
                      <li class="nav-item">
                        <a href="#" class="nav-link pe-1" (click)="$event.preventDefault(); manifest.addEmptyContainer()">
                          <span class="fa fa-plus-circle"></span><span
                          class="ps-2 d-none d-sm-inline">Add empty</span></a>
                      </li>
                    </ul>
                    <div class="ms-n4 my-3">
                      <div *ngIf="manifest.containers.length === 0" class="text-center text-muted" style="padding: 20px 0">
                        You haven’t added any containers yet.
                      </div>
                      <app-containers [containers]="manifest.containers" [showTerminal]="true"></app-containers>
                    </div>
                  </ng-template>
                </app-tab-item>
                <app-tab-item *ngIf="manifest.overlanders.length > 0" [templateRef]="overlandedRef">
                  <span class="tab-item">Overlanded</span>
                  <ng-template #overlandedRef>
                    <div class="ms-n4 my-3">
                      <app-overlanders [setOverlanders]="manifest.overlanders" [manifest]="manifest"
                                       [showTerminal]="true"></app-overlanders>
                    </div>
                  </ng-template>
                </app-tab-item>
              </app-tab-panel>
            </div>

          </div>
        </div>
      </ng-template>
    </div>
  </div>

  <div *ngIf="filteredManifests.length === 0" class="text-center text-muted py-2">
    <ng-container *ngIf="getConsignmentCount() > 0; else noConsignmentsYet">
      No matching consignments found.
    </ng-container>
    <ng-template #noConsignmentsYet>You haven’t added any consignments yet.</ng-template>
  </div>

  <ng-container *ngIf="context.cargoImportModel.overlandersWithoutPort as overlanders">
    <div *ngIf="visibleItems(overlanders).length > 0" class="mb-3">
      <div class="row g-0">
        <div class="col-auto me-2">
          <div class="form-check form-check-inline m-0 always-enabled" style="height: 3.5rem">
            <input class="form-check-input invisible m-0 always-enabled" type="checkbox"></div>
        </div>
        <div class="col">
          <div class="declaration-block my-4">
            <h2>Overlanded without port of loading</h2>
          </div>
          <app-collapse [collapse]="overlandersTemplate">
            <div class="row pe-3 g-0 align-items-center">
              <div class="col-auto me-3 p-3">
                <span class="fa fa-fw fa-cubes text-dark"></span>
              </div>
              <div class="col fw-bold text-dark">
                ? to {{context.savedVisit.portOfCall.port.name | titlecase}}
              </div>
              <app-cargo-summary [overlanders]="overlanders" [keepTooltipOpen]="true"></app-cargo-summary>
            </div>
          </app-collapse>
        </div>
      </div>

      <ng-template #overlandersTemplate>
        <div class="collapse">
          <div class="my-3">
            <app-overlanders [setOverlanders]="context.cargoImportModel.overlandersWithoutPort"
                             [showTerminal]="true"></app-overlanders>
          </div>
        </div>
      </ng-template>

    </div>
  </ng-container>

  <div *ngIf="filteredManifests.length > 1" class="row pe-3 mb-3 ms-1 g-0 align-items-center"
       style="margin-right: 4rem; padding-right: 2px; padding-left: 2px">
    <div class="col-auto me-3 p-3">
      <span class="fa fa-fw fa-route text-white"></span>
    </div>
    <div class="col fw-bold text-dark">
      Total:
    </div>
    <div class="col-auto d-none d-md-block text-dark">
      <app-cargo-summary [manifests]="filteredManifests" [keepTooltipOpen]="true"></app-cargo-summary>
    </div>
  </div>

  <app-cargo-import-admin></app-cargo-import-admin>

</ng-container>
