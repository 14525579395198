import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IE3Address} from "@portbase/bezoekschip-service-typescriptmodels";
import {add} from "lodash";

@Component({
  selector: 'app-edit-address',
  templateUrl: './edit-address.component.html',
  styleUrls: ['./edit-address.component.scss']
})
export class EditAddressComponent {
  @Input() address: IE3Address;
  @Input() editable: boolean;

  @Output() addressChange: EventEmitter<IE3Address> = new EventEmitter<IE3Address>();
  protected readonly add = add;
}
