<div class="row narrow-gutters">
  <div class="col-md-auto">
    <app-search [(ngModel)]="declaration" [searchFunction]="searchMessages" [resultFormatter]="formatDeclaration"
                       [inputFormatter]="formatDeclaration"
                       (ngModelChange)="openMessages(declaration)" [placeholder]="'Search message'">
    </app-search>
  </div>
  <div class="col-md-auto ms-auto">
    <span class="dropdown mx-1 always-enabled">
        <button class="btn btn-outline-secondary dropdown-toggle" style="min-width: 7rem" type="button"
                data-bs-toggle="dropdown">Outgoing
        <span class="caret"></span></button>
        <ul class="dropdown-menu dropdown-menu-end">
          <ng-container *ngFor="let manifest of manifests">
            <ng-container *ngFor="let declaration of getFirstDeclarations(manifest)">
              <li *ngIf="declaration.type==='SDT' || declaration.type==='ENS'" (click)="openMessages(declaration)"
                  class="dropdown-item">{{declaration.type}} of {{declaration.id}} to Singlewindow</li>
            </ng-container>
          </ng-container>
        </ul>
    </span>
    <button *ngIf="rejectAllowed()" data-bs-toggle="modal" data-bs-target="#rejectImportModal" type="button"
            class="btn-sm btn btn-outline-danger mx-1 py-1" style="min-width: 7rem" tabindex="-1">Reject SDT
    </button>
    <button *ngIf="acceptAllowed()" (click)="accept()" type="button" class="btn-sm btn btn-outline-success mx-1"
            style="min-width: 7rem" tabindex="-1">Accept SDT
    </button>
    <button *ngIf="rejectEnsAllowed()" data-bs-toggle="modal" data-bs-target="#rejectEnsModal" type="button"
            class="btn-sm btn btn-outline-danger mx-1 py-1" style="min-width: 7rem" tabindex="-1">Reject ENS
    </button>
    <button *ngIf="acceptEnsAllowed()" data-bs-toggle="modal" data-bs-target="#acceptEnsModal" type="button"
            class="btn-sm btn btn-outline-danger mx-1 py-1" style="min-width: 7rem" tabindex="-1">Accept ENS
    </button>
  </div>
</div>

<div id="rejectImportModal" class="modal fade" data-bs-backdrop="false" tabindex="-1" role="dialog">
  <div class="modal-dialog shadow" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Reject reason</h5>
      </div>
      <div class="modal-body">
        <input id="rejectImport-reason" type="text" class="form-control">
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Back</button>
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                (click)="reject(appContext.getDocument().getElementById('rejectImport-reason')['value'])">Reject SDT
        </button>
      </div>
    </div>
  </div>
</div>
<div id="rejectEnsModal" class="modal fade" data-bs-backdrop="false" tabindex="-1" role="dialog">
  <div class="modal-dialog shadow" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Reject ens reason</h5>
      </div>
      <div class="modal-body">
        <input id="rejectEns-reason" type="text" class="form-control">
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Back</button>
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                (click)="rejectEns(appContext.getDocument().getElementById('rejectEns-reason')['value'])">Reject ENS
        </button>
      </div>
    </div>
  </div>
</div>
<div id="acceptEnsModal" class="modal fade" data-bs-backdrop="false" tabindex="-1" role="dialog">
  <div class="modal-dialog shadow" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">MRN</h5>
      </div>
      <div class="modal-body">
        <input id="acceptEns-mrn" type="text" class="form-control">
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Back</button>
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                (click)="acceptEns(appContext.getDocument().getElementById('acceptEns-mrn')['value'])">Accept ENS
        </button>
      </div>
    </div>
  </div>
</div>
