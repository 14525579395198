<div [id]="id">
  <ng-container *ngFor="let item of items; let index = index; let last = last">
    <app-search class="mb-2" [ngModel]="item" [placeholder]="placeholder"
                (ngModelChange)="$event ? items[index] = $event : items.splice(index, 1); this.onModelChange()"

                [inputFormatter]="inputFormatter" [resultFormatter]="resultFormatter"
                [required]="required" [dataKey]="dataKey" [disabled]="disabled"
                [newValueFunction]="newValueFunction" [newValueTemplate]="newValueTemplate"
                [newValueModalClass]="newValueModalClass" [valueType]="valueType"
                [styling]="styling" [placement]="placement"
                [minCharacters]="minCharacters" [searchFunction]="searchFunction"
    ></app-search>
    <ng-container *ngIf="last">
      <app-search [ngModel]="null" placeholder="Add other"
                  (ngModelChange)="($event ? items.push($event) : null); this.onModelChange()"

                  [inputFormatter]="inputFormatter" [resultFormatter]="resultFormatter"
                  [dataKey]="dataKey" [disabled]="disabled"
                  [newValueFunction]="newValueFunction" [newValueTemplate]="newValueTemplate"
                  [newValueModalClass]="newValueModalClass" [valueType]="valueType"
                  [styling]="styling" [placement]="placement"
                  [minCharacters]="minCharacters" [searchFunction]="searchFunction"
      ></app-search>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="items.length === 0">
    <app-search [ngModel]="null" [placeholder]="items.length === 0 ? placeholder : 'Add other'"
                (ngModelChange)="($event ? items.push($event) : null); this.onModelChange()"

                [inputFormatter]="inputFormatter" [resultFormatter]="resultFormatter"
                [required]="required" [dataKey]="dataKey" [disabled]="disabled"
                [newValueFunction]="newValueFunction" [newValueTemplate]="newValueTemplate"
                [newValueModalClass]="newValueModalClass" [valueType]="valueType"
                [styling]="styling" [placement]="placement"
                [minCharacters]="minCharacters" [searchFunction]="searchFunction"
    ></app-search>
  </ng-container>
</div>

