import {Component, OnDestroy} from '@angular/core';
import {PortvisitUtils} from '../../../refdata/portvisit-utils';
import {
  Declaration,
  DeclarationStatus,
  DeclareDischargeList,
} from '@portbase/bezoekschip-service-typescriptmodels/common';
import {VisitContext} from '../../visit-context';
import {filterByTerm, sendCommand} from '../../../common/utils';
import {AppContext} from '../../../app-context';
import moment from 'moment';
import {DischargeList} from '../cargo-import.model';

@Component({
  selector: 'app-discharge-list',
  templateUrl: './discharge-list.component.html',
  styleUrls: ['./discharge-list.component.css']
})
export class DischargeListComponent implements OnDestroy {

  context = VisitContext;
  appContext = AppContext;
  utils = PortvisitUtils;

  get dischargeLists(): DischargeList[] {
    return VisitContext.cargoImportModel.dischargeLists;
  }

  filteredLists: DischargeList[] = [];

  allowedTerminals = ["APMII", "RWG", "ECTDELTA", "APMRTM", "RST", "RSTZ", "EUROMAX"];

  filterList(term: string): (value: DischargeList) => boolean {
    return l => {
      const show = filterByTerm(term)(l);
      if (show) {
        l.containers.forEach(c => c['hidden'] = !filterByTerm(term)(c));
        l.overlanders.forEach(c => c['hidden'] = !filterByTerm(term)(c));
      }
      return show;
    }
  }


  listById(index: number, s: DischargeList) {
    return s.terminal.terminalName;
  }

  get selectedLists(): DischargeList[] {
    return this.filteredLists.filter(v => !!v['selected']);
  }

  getDeclarationStatus(list: DischargeList): DeclarationStatus {
    return VisitContext.cargoImportModel.declarations
      .filter(d => d.type === "COPRAR" && d.id === list.terminal.terminalCode).length > 0 ?
      DeclarationStatus.ACCEPTED : null;
  }

  send(lists: DischargeList[]) {
    sendCommand("com.portbase.bezoekschip.common.api.cargo.DeclareDischargeList",
      <DeclareDischargeList>{
        crn: VisitContext.savedVisit.crn,
        cargoDeclarantShortName: VisitContext.cargoImportModel.cargoDeclarant.shortName,
        terminalCodes: lists.map(l => l.terminal.terminalCode)
      }, () => {
        lists.forEach(l => VisitContext.cargoImportModel.declarations.push(
          <Declaration>{type: 'COPRAR', id: l.terminal.terminalCode, timeStamp: moment().toISOString()}
        ));
        VisitContext.replaceVisit(VisitContext.visit);
        AppContext.registerSuccess('Selected discharge lists are sent successfully');
      })
  }

  filterAllowedTerminals(lists: DischargeList[]) {
    return lists.filter(l => this.allowedTerminals.indexOf(l.terminal.organisationShortName) != -1);
  }

  ngOnDestroy(): void {
    this.dischargeLists.forEach(l => {
      delete l['hidden'];
      delete l['selected'];
      l.containers.concat(<any[]>l.overlanders).forEach(c => {
        delete c['hidden'];
        delete c['selected'];
      });
    })
  }
}
