import {Component, OnInit} from '@angular/core';
import {VisitContext} from "../../visit-context";
import moment from "moment";

@Component({
  selector: 'app-port-arrival',
  templateUrl: './port-arrival.component.html',
  styleUrls: ['./port-arrival.component.css']
})
export class PortArrivalComponent implements OnInit {

	context = VisitContext;

	constructor() { }

  ngOnInit() {
  }

	asTime(date: string) {
		if (date) {
			return moment(date).format('HH:mm');
		}
	}

	asDate(date: string, errorText: String) {
		if (date) {
			return date ? moment().isSame(date, 'day') ? 'Today' : moment(date).format('D MMM') : errorText;
		}
	}
}
