import {Component, Input} from '@angular/core';
import {
  CustomsProcess,
  CustomsStatus,
  DischargeResult,
  InspectionType
} from '@portbase/bezoekschip-service-typescriptmodels';
import {lodash} from '../../../../common/utils';
import {VisitContext} from '../../../visit-context';
import {
  CargoImportModel,
  ConsignmentModel,
  DischargeList,
  GoodPlacementModel,
  GoodsItemModel,
  ImportContainerModel,
  Inspection,
  Manifest
} from '../../cargo-import.model';
import {PortvisitUtils} from "../../../../refdata/portvisit-utils";
import {LoadList} from "../../../cargo-loading/cargo-loading.model";

@Component({
  selector: 'app-cargo-summary',
  templateUrl: './cargo-summary.component.html',
  styleUrls: ['./cargo-summary.component.css']
})
export class CargoSummaryComponent {
  utils = PortvisitUtils;
  @Input() keepTooltipOpen: boolean;

  consignmentCount = (): number => 0;
  clearanceDifference = (): boolean => false;
  manifestCleared = (): boolean => false;
  packageCount = (): number => 0;
  containerCount = (): number => 0;
  weight = (): number => 0;
  dangerousCargo = (): boolean => false;
  reefers = (): boolean => false;
  containerDischargeStatus = (): ContainerDischargeStatus => null;
  bulkDischarged = (): boolean => false;
  containerEmpty = (): boolean => false;
  inspections = (): Inspection[] => null;
  customsStatus = (): CustomsStatus[] => null;
  customsProcess = (): CustomsProcess[] => null;
  overlandedCount = (): number => 0;
  shortlandedCount = (): number => 0;

  @Input() set importModel(importModel: CargoImportModel) {
    this.updateManifests(importModel.manifests, importModel, true);
  }

  @Input() set manifest(manifest: Manifest) {
    this.updateManifests([manifest], VisitContext.cargoImportModel);
  }

  @Input() set manifests(manifests: Manifest[]) {
    this.updateManifests(manifests, VisitContext.cargoImportModel, true);
  }

  private updateManifests(manifests: Manifest[], importModel : CargoImportModel, includeOverlandersWithoutPortOfLoading? : boolean) {
    const overlandersWithoutPortOfLoading =
      includeOverlandersWithoutPortOfLoading ?
        importModel.overlandersWithoutPort ? importModel.overlandersWithoutPort.length : 0
        : 0;
    const consignmentsWithDiffs = importModel.consignmentDifferences.map(d => d.consignmentNumber);
    const consignments = lodash.flatMap(manifests, m => m.consignments).filter(v => !v['hidden'] || v['forceVisible']);
    const hasDifferences = consignments.map(c => c.consignmentNumber)
      .some(nr => consignmentsWithDiffs.indexOf(nr) >= 0);
    this.manifestCleared = () => manifests.every(m => m.cleared);
    this.consignmentCount = () => consignments.length;
    this.clearanceDifference = () => hasDifferences;
    this.updateGoods(lodash.flatMap(consignments, c => c.goodsItems));
    this.containerCount = () => lodash.flatMap(manifests, m => m.containers).filter(v => !v['hidden'] || v['forceVisible']).length;
    this.overlandedCount = () => lodash.flatMap(manifests, m => m.overlanders).length + overlandersWithoutPortOfLoading;
    this.shortlandedCount = () => lodash.flatMap(manifests, m => m.containers.filter(c => c.shortlanded)).length;
    this.inspections = () => lodash.flatMap(consignments, c => c.inspections);
    this.customsProcess = () => lodash.uniq(lodash.flatMap(consignments, c => c.customsProcess))
      .filter(status => !!status);
    this.customsStatus = () => lodash.uniq(lodash.flatMap(consignments, c => c.customsStatus))
      .filter(status => !!status);
  }

  @Input() set consignment(consignment: ConsignmentModel) {
    const differences = lodash.keyBy(VisitContext.cargoImportModel.consignmentDifferences, d => d.consignmentNumber);
    this.clearanceDifference = () => !!differences[consignment.consignmentNumber];
    this.updateGoods(consignment.goodsItems);
    this.containerCount = () => lodash.uniq(lodash.flatMap(consignment.goodsItems, g => g.placements).map(p => p.equipmentNumber)).length;
    this.inspections = () => consignment.inspections;
    this.shortlandedCount = () => lodash.flatMap(consignment.goodsItems, i => i.placements).filter(p => p.shortlanded).length;
    this.customsProcess = () => !!consignment.customsProcess ? [consignment.customsProcess] : [];
    this.customsStatus = () => !!consignment.customsStatus ? [consignment.customsStatus] : [];
    this.bulkDischarged = () => consignment.bulkDischarges?.length > 0;
  }

  @Input() set good(good: GoodsItemModel) {
    this.updateGoods([good]);
    const uniqueContainers = lodash.uniqBy(good.placements, p => p.equipmentNumber);
    this.containerCount = () => uniqueContainers.length;
    this.inspections = () => lodash.uniq(lodash.flatMap(good.placements, p => p.inspections));
    this.shortlandedCount = () => uniqueContainers.filter(p => p.shortlanded).length;
  }

  private updateGoods(goods: GoodsItemModel[]) {
    goods = goods.filter(v => !v['hidden'] || v['forceVisible']);
    this.packageCount = () => lodash.sumBy(goods, g => Number(g.numberOfOuterPackages || 0));
    this.weight = () => Math.round(lodash.sumBy(goods, g => Number(g.grossWeight || 0)));
    this.dangerousCargo = () => goods.some(g => !!g.dangerInformation);
    this.reefers = () => goods.some(g => lodash.isNumber(g.minimumTemperature) || lodash.isNumber(g.maximumTemperature));
  }

  @Input() set placement(placement: GoodPlacementModel) {
    this.weight = () => placement.grossWeight || 0;
    this.packageCount = () => placement.numberOfPackages || 0;
    this.inspections = () => placement.inspections;
  }

  @Input() set overlander(overlander: DischargeResult) {
    this.reefers = () => !!overlander.temperature;
    this.weight = () => Number(overlander.grossWeight || 0);
    this.containerDischargeStatus = () => 'overlanded';
    this.containerEmpty = () => overlander.empty;
  }

  @Input() set overlanders(overlanders: DischargeResult[]) {
    overlanders = overlanders.filter(v => !v['hidden'] || v['forceVisible']);
    this.weight = () => Math.round(lodash.sumBy(overlanders, g => Number(g.grossWeight || 0)));
    this.overlandedCount = () => overlanders.length;
    this.containerEmpty = () => overlanders.some(value => value.empty);
  }

  @Input() set container(container: ImportContainerModel) {
    this.updateContainers([<any>container]);
    this.containerCount = () => 0;
    this.containerEmpty = () => container.empty;
  }

  @Input() set dischargeList(list: DischargeList) {
    this.updateContainers(list.containers.concat(<any[]>list.overlanders));
  }

  @Input() set loadList(list: LoadList) {
    this.containerCount = () => list.overlanders.length;
  }

  private updateContainers(containers: ImportContainerModel[]) {
    containers = containers.filter(v => !v['hidden'] || v['forceVisible']);
    this.dangerousCargo = () => false;
    this.reefers = () => false;
    let weight = 0;
    let packageCount = 0;
    const consignments = new Set<string>();
    const result = new Map<string, ContainerDischargeStatus>();
    containers.forEach(container => {
      if (lodash.isNumber(container.temperature)) {
        this.reefers = () => true;
      }
      const dischargeResult = VisitContext.cargoImportModel.discharges[container.number];
      const knownContainer: boolean = lodash.flatMap(VisitContext.cargoImportModel.manifests, m => m.containers)
        .some(c => c.number === container.number);
      lodash.flatMap(VisitContext.cargoImportModel.manifests, m => m.consignments).forEach(
        c => {
          c.goodsItems.forEach(g => g.placements.filter(p => p.equipmentNumber === container.number).forEach(p => {
            consignments.add(c.consignmentNumber);
            weight += Number(p.grossWeight || 0);
            packageCount += Number(p.numberOfPackages || 0);
            if (!!g.dangerInformation) {
              this.dangerousCargo = () => true;
            }
          }));
        });
      if (knownContainer) {
        if (dischargeResult) {
          result.set(container.number, dischargeResult.empty !== container.empty ? 'changed' : 'discharged');
        } else if (container.shortlanded) {
          result.set(container.number, 'shortlanded');
        } else {
          const berthVisit = VisitContext.savedVisit.visitDeclaration.portVisit.berthVisits.find(
            v => v.berth && container.terminal && v.berth.terminalCode === container.terminal.terminalCode);
          if (berthVisit && PortvisitUtils.dischargeResultTerminals.indexOf(berthVisit.berth.organisationShortName) >= 0) {
            if (berthVisit.atd) {
              result.set(container.number, 'shortlanded');
            } else if (berthVisit.ata) {
              result.set(container.number, 'in_progress');
            }
          }
        }
      } else if (dischargeResult) {
        const overlander: DischargeResult = <any>container;
        result.set(container.number, 'overlanded');
        weight += Number(overlander.grossWeight || 0);
        if (!!overlander.temperature) {
          this.reefers = () => true;
        }
      }
    })
    this.consignmentCount = () => consignments.size;
    this.packageCount = () => packageCount;
    this.weight = () => weight;
    this.containerCount = () => containers.length;
    const status = getDischargeStatus(result);
    this.containerDischargeStatus = () => status;
    this.inspections = () => lodash.flatMap(containers, c => c.inspections).filter(c => !!c);

    function getDischargeStatus(statusMap: Map<string, ContainerDischargeStatus>): ContainerDischargeStatus {
      const statuses = Array.from(statusMap.values());
      const filled = statuses.filter(s => !!s);
      if (filled.length === 0) {
        return null;
      }
      if (statuses.length === 1) {
        return statuses[0];
      }
      if (filled.length === statuses.length) {
        return filled.every(s => s === 'discharged') ? 'discharged' : 'changed';
      }
      return 'in_progress';
    }
  }

  quantifiedWeight = (): string => {
    const weight = this.weight();
    return !weight ? null : Math.round(Number(weight)) + " kg";
  };

  getInspectionState(inspections: Inspection[]) {
    if (!inspections || inspections.length == 0) {
      return;
    } else if (inspections.every(i => !i || i.status === 'deleted')) {
      return {deleted: true};
    }
    const inspecting: InspectionType[] = lodash.uniq(lodash.flatMap(lodash.flatMap(
      inspections.filter(i => i && i.status !== 'deleted' && i.status !== 'released'), i => i.inspectionUpdates), u => u.type))
    return {
      inspecting: inspecting,
      released: lodash.uniq(lodash.flatMap(lodash.flatMap(
        inspections.filter(i => i && i.status !== 'deleted' && i.status === 'released'), i => i.inspectionUpdates), u => u.type))
        .filter(t => inspecting.indexOf(t) === -1),
    }
  }
}

export type ContainerDischargeStatus = 'overlanded' | 'shortlanded' | 'changed' | 'in_progress' | 'discharged';
