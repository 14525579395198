import {Component, Input} from '@angular/core';
import {ClearanceService} from "../../visit/clearance/clearance-service";
import {VisitContext} from "../../visit-context";
import {AppContext} from "../../../app-context";
import {AttentionPointContext, DeclarationStatus} from "@portbase/bezoekschip-service-typescriptmodels";

@Component({
  selector: 'app-clearance-status',
  templateUrl: './clearance-status.component.html',
  styleUrls: ['./clearance-status.component.scss']
})
export class ClearanceStatusComponent {
  @Input() tooltipTitle: string;
  @Input() clearanceKey: AttentionPointContext;
  @Input() declarationStatus : DeclarationStatus;

  constructor(private clearanceService: ClearanceService) {
  }

  get status() {
    return this.clearanceService.getStatus(VisitContext.visit, this.clearanceKey, this.declarationStatus);
  }

  isClearancesActive() : boolean {
    return AppContext.isClearancesActive();
  }


}
