<div class="alert {{alertClass}}" role="alert">
  <div class="row">
    <div *ngIf="clearance.status == 'REJECTED'" class="title col-md-4">Movement is not allowed to commence</div>
    <div *ngIf="clearance.status != 'REJECTED'" class="title col-md-4">{{clearance.remarks ? 'Remarks' : ''}}</div>
    <div class="content col-md-8">{{ clearance.remarks }}</div>
  </div>
  <ul *ngFor="let attentionPoint of clearance.attentionPoints" class="no-bullets">
    <li class="row">
      <span class="title col-md-4">{{translate(clearanceKey, attentionPoint.type)}}</span>
      <span class="content col-md-8">{{attentionPoint.remarks}}</span>
    </li>
  </ul>
  <div class="extra">
    <ng-content></ng-content>
  </div>
</div>
