import {Component, Input, OnInit} from '@angular/core';
import {
  IE3GetTranshipmentLog,
  IE3Transhipment,
  TranshipmentLogItem
} from '@portbase/bezoekschip-service-typescriptmodels';
import {ComparatorChain} from "../../common/comparator-chain";
import {sendQuery} from "../../common/utils";

@Component({
  selector: 'app-transhipment-history',
  templateUrl: './transhipment-history.component.html',
  styleUrls: ['./transhipment-history.component.scss']
})
export class TranshipmentHistoryComponent implements OnInit {
  @Input() cargoDeclarantIamConnectedId: string;
  @Input() consignmentNumber: string;
  @Input() transhipment: IE3Transhipment;

  items: TranshipmentLogItem[] = [];
  reverseOrder = true;

  private logComparator = new ComparatorChain("timestamp").compare;

  ngOnInit(): void {
    sendQuery("com.portbase.bezoekschip.common.api.consignments.queries.GetTranshipmentLog", <IE3GetTranshipmentLog>{
        iamConnectedId: this.cargoDeclarantIamConnectedId,
        consignmentNumber: this.consignmentNumber
      },
      {showSpinner: true, caching: false})
      .subscribe((items: TranshipmentLogItem[]) => this.items = items.sort(this.logComparator));
  }

  cast = (item: TranshipmentLogItem): TranshipmentLogItem => item;

  toggleOrder() {
    this.reverseOrder = !this.reverseOrder;
  }
}
