import {Pipe, PipeTransform} from '@angular/core';
import {lodash} from './utils';

@Pipe({
  name: 'join'
})
export class JoinPipe implements PipeTransform {

  transform(value: any[], ...args: string[]): unknown {
    const [separator] = args;
    return value && lodash.join(value, separator || ", ");
  }

}
