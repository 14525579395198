import {
  BezoekschipOrganisation,
  ImportContainer,
  LoadingDeclaration,
  LoadingResult,
  Terminal
} from '@portbase/bezoekschip-service-typescriptmodels';
import {VisitContext} from '../visit-context';
import {cloneObject, lodash, replaceItem} from '../../common/utils';
import {ComparatorChain} from '../../common/comparator-chain';

const compareByTerminal = (m1: any, m2: any) => {
  if (!m1.terminal) {
    return m2.terminal ? -1 : 0;
  }
  if (!m2.terminal) {
    return 1;
  }
  const terminals = VisitContext.savedVisit.visitDeclaration.portVisit.berthVisits.map(p => p.berth && p.berth.terminalCode);
  return terminals.indexOf(m1.terminal.terminalCode) - terminals.indexOf(m2.terminal.terminalCode);
};

const containerComparator = new ComparatorChain(compareByTerminal, '!!loadingResult', 'number').compare;

export class CargoLoadingModel {
  readonly cargoDeclarant: BezoekschipOrganisation;
  readonly loadings: { [index: string]: LoadingResult };
  readonly loadLists: LoadList[];

  constructor(loadingDeclaration: LoadingDeclaration) {
    this.cargoDeclarant = loadingDeclaration.cargoDeclarant;
    this.loadings = lodash.keyBy(loadingDeclaration.loadings, d => d.number);
    this.loadLists = LoadList.asLoadLists(loadingDeclaration);

    //listen for change and button click events
    CargoLoadingModel.listenForChanges();
  }

  static initializeForCargoAgent(organisation: string) {
    if (organisation) {
      let loadingDeclaration = VisitContext.visit.loadingDeclarations.find(d => d.cargoDeclarant.shortName === organisation);
      if (!loadingDeclaration) {
        const cargoAgent = VisitContext.savedVisit.cargoDeclarants.find(c => c.shortName === organisation);
        if (!cargoAgent) {
          delete VisitContext.cargoLoadingModel;
        } else {
          VisitContext.visit.loadingDeclarations.push(loadingDeclaration = <LoadingDeclaration>{
            cargoDeclarant: cargoAgent, loadings: []
          });
        }
      }
      loadingDeclaration.loadings.sort(containerComparator);
      VisitContext.cargoLoadingModel = new CargoLoadingModel(loadingDeclaration);
      if (!VisitContext.savedLoadingDeclaration || VisitContext.savedLoadingDeclaration.cargoDeclarant.shortName !==
        loadingDeclaration.cargoDeclarant.shortName) {
        VisitContext.savedLoadingDeclaration = cloneObject(loadingDeclaration);
      }
    } else {
      delete VisitContext.cargoLoadingModel;
    }
  }


  private static changeHandler;
  private static listenForChanges = () => {
    if (!CargoLoadingModel.changeHandler) {
      $(document.body).on('change', CargoLoadingModel.changeHandler = () => {
        if (VisitContext.cargoLoadingModel) {
          CargoLoadingModel.afterChange();
        }
      });

      $(document.body).on('click', e => {
        if (VisitContext.cargoLoadingModel) {
          let element = e.target;
          while (!!element && element != document.body) {
            switch (lodash.lowerCase(element.tagName)) {
              case 'a':
              case 'button':
                setTimeout(() => CargoLoadingModel.afterChange(true), 0);
                return;
            }
            element = element.parentElement;
          }
        }
      });
    }
  };

  static rerender() {
    VisitContext.cargoLoadingModel = lodash.cloneDeepWith(VisitContext.cargoLoadingModel, v => lodash.isArray(v) ? lodash.assign([], v) : undefined);
    CargoLoadingModel.afterChange(true);
  }

  private static afterChange(force = false) {
    const before = VisitContext.cargoLoadingModel;
    const loadingDeclaration = toLoadingDeclaration();
    const after = new CargoLoadingModel(loadingDeclaration);
    if (force) {
      console.log("carge loading change was forced");
      doUpdate();
    } else {
      const replacer = (key, value) => key === 'ngInvalid' || key === 'selected' || key === 'hidden' || key === 'forceVisible'
        ? undefined : value;
      if (JSON.stringify(before, replacer) !== JSON.stringify(after, replacer)) {
        console.log("carge loading has changed significantly");
        doUpdate();
      }
    }

    function doUpdate() {
      after.loadLists.forEach(list => {
        const previous = before.loadLists.find(m => m.terminal.terminalCode === list.terminal.terminalCode);
        if (previous) {
          list['selected'] = previous['selected'];
          list['hidden'] = previous['hidden'];
          list['forceVisible'] = previous['forceVisible'];
          list['ngInvalid'] = previous['ngInvalid'];
        }
      });
      const oldDeclaration = VisitContext.visit.loadingDeclarations.find(
        d => d.cargoDeclarant.shortName === loadingDeclaration.cargoDeclarant.shortName);
      replaceItem(VisitContext.visit.loadingDeclarations, oldDeclaration, loadingDeclaration);
      VisitContext.cargoLoadingModel = after;
    }

    function toLoadingDeclaration(): LoadingDeclaration {
      return <LoadingDeclaration>{
        cargoDeclarant: before.cargoDeclarant,
        loadings: lodash.values(before.loadings),
        timestamp: null
      }
    }
  }
}

export interface LoadingContainerModel extends ImportContainer {
  selected: boolean;
  ngInvalid: boolean;
  loadingResult: LoadingResult;
  declared: boolean;
}

export class LoadList {
  readonly terminal: Terminal;
  readonly overlanders: LoadingResult[] = [];

  constructor(terminal: Terminal) {
    this.terminal = terminal? terminal : {terminalName: "Unknown terminal",terminalCode:"?",organisationName:"?",organisationShortName:"?"};
  }

  static asLoadLists(loadingDeclaration: LoadingDeclaration): LoadList[] {
    const lists = new Map<string, LoadList>();

    loadingDeclaration.loadings
      .forEach(c => computeLoadList(c.terminal).overlanders.push(c));
    return sortLists(Array.from(lists.values()));

    function computeLoadList(terminal: Terminal) {
      const terminalCode = terminal? terminal.terminalCode:"?";
      let result = lists.get(terminalCode);
      if (!result) {
        lists.set(terminalCode, result = new LoadList(terminal));
      }
      return result;
    }

    function sortLists(loadLists: LoadList[]): LoadList[] {
      const listComparator = new ComparatorChain((i1: LoadList, i2: LoadList) => {
        const terminals = VisitContext.savedVisit.visitDeclaration.portVisit.berthVisits.map(b => b.berth && b.berth.terminalCode);
        return terminals.indexOf(i1.terminal.terminalCode) - terminals.indexOf(i2.terminal.terminalCode);
      }).compare;

      return Array.from(loadLists.values()).sort(listComparator);
    }
  }
}
