import {Component, Input} from '@angular/core';
import {PortvisitUtils} from '../../../../refdata/portvisit-utils';
import {GoodsDocumentType, GoodsItem} from '@portbase/bezoekschip-service-typescriptmodels';
import {removeItem} from '../../../../common/utils';
import {VisitContext} from '../../../visit-context';

@Component({
  selector: 'app-goods-documents',
  templateUrl: './goods-documents.component.html',
  styleUrls: ['./goods-documents.component.css']
})
export class GoodsDocumentsComponent {
  context = VisitContext;
  refData = PortvisitUtils;
  @Input() item: GoodsItem;

  documentTypeFormatter = (value: GoodsDocumentType) => {
    switch (value) {
      case 'N703': return 'N703: House Waybill';
      case 'N705': return 'N705: Bill of Lading';
      case 'Y031': return 'Y031: AEO programma';
      case '_820': return '820: Customs Procedure T';
      case '_821': return '821: Customs Procedure T1';
      case '_822': return '822: Customs Procedure T2';
      default:
        throw Error('Unknown document type: ' + value);
    }
  };

  addItem() {
    this.item.producedDocuments.push(<any>{});
  }

  removeItem(item: any) {
    return () => removeItem(this.item.producedDocuments, item);
  }
}
