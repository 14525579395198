<div class="row">
  <div class="col-md-6">
    <app-form-group>
      <label>Select stowage</label>
      <app-multiselect [(ngModel)]="handling.stowages" [options]="handling.options" dataKey="stowageNumber"
                       [hideSelected]="true" [enableSelectAll]="true" [autoSelectOnlyOption]="true"
                       placement="top-end" title="Select stowage to discharge"></app-multiselect>
    </app-form-group>
  </div>
  <div class="col-md-6" *ngIf="context.dangerousGoodsDeclaration.dangerousGoods.hasTanks">
    <app-form-group>
      <label>Ship to ship</label>
      <app-yes-no required [(ngModel)]="handling.shipToShip"></app-yes-no>
    </app-form-group>
    <app-form-group *ngIf="handling.hasInertTanks">
      <label>Tanks remain inert</label>
      <app-yes-no required [(ngModel)]="handling.keepInert"></app-yes-no>
    </app-form-group>
  </div>
</div>

<div class="row no-gutters mt-4 mb-2">
  <div class="col">
    <ng-container *ngIf="handling.prewashingRequired">
      <div class="alert alert-warning show row align-items-center" role="alert">
        <div class="col-1 ps-2">
          <i class="fa fa-light fa-circle-exclamation" aria-hidden="true" style="font-size: xxx-large"></i>
        </div>
        <div class="col-11">
          Please make sure the handling washing is applied after discharging the load.
        </div>
      </div>
    </ng-container>
  </div>
</div>

<ng-container *ngIf="handling.bulk?.length > 0">
  <div class="row g-0 bg-secondary text-light py-2">
    <div class="col"><span class="ps-2">Bulk</span></div>
    <div class="col-auto ps-2" style="width: 2rem"></div>
  </div>

  <div *ngFor="let stowage of handling.bulk; trackBy: stowageById; let index = index">
    <div class="row g-0 my-2">

      <div class="col">
        <div class="row g-0">
          <div class="col-auto" style="width: 11rem">
            <input [ngModel]="stowage.stowageNumber" class="form-control bg-transparent border-0" [disabled]="true">
          </div>

          <div class="col-auto ps-3 pe-2" *ngIf="stowage.availableBulk">
            <app-yes-no required [(ngModel)]="stowage.emptied" (ngModelChange)="onEmptyChanged(stowage)"
                        [formatter]="emptyFormatter" [id]="'dischargeTillEmpty-' + index" ></app-yes-no>
          </div>

          <div class="col-auto" *ngIf="stowage.availableBulk && stowage.emptied === false" style="width: 11rem">
            <input required [(ngModel)]="stowage.amount" class="form-control" placeholder="Choose amount" appDecimalNumber [maxDecimals]="3">
          </div>

          <div class="col-auto" *ngIf="stowage.availableBulk && stowage.emptied" style="width: 11rem">
            <input [disabled]="true" [ngModel]="stowage.availableBulk"
                   class="form-control" placeholder="Choose amount" appDecimalNumber [maxDecimals]="3">
          </div>

          <div class="col-auto" *ngIf="stowage.availableBulk">
            <div class="form-control border-0 bg-transparent"> TNE</div>
          </div>

          <div class="col-auto" *ngIf="stowage.needsPrewashing && stowage.emptied">
            <div class="form-control border-0 bg-transparent"> (Tank requires Washing, when fully discharged!)</div>
          </div>
        </div>
      </div>

      <div class="col-auto text-end" style="min-width: 2rem">
        <button title="Delete stowage" (click)="deleteStowage(stowage)"
                class="btn" type="button" tabindex="-1"><span class="fa fa-trash text-secondary"></span>
        </button>
      </div>

    </div>
  </div>
</ng-container>

<div class="row g-0 mt-4 mb-2">
  <div class="col">
    <span class="dropdown">
      <button type="button" class="btn btn-info my-2 dropdown-toggle" style="width: 10rem"
              *ngIf="!handling.containersOnly" [disabled]="handling.availableGoods.length===0" data-bs-toggle="dropdown">Discharge good</button>
      <span class="dropdown-menu">
        <button *ngFor="let good of handling.availableGoods" type="button" class="dropdown-item"
                (click)="dischargeGood(good)" data-bs-toggle="collapse">{{good['uniqueGoodName'] | titlecase}}
        </button>
      </span>
    </span>
  </div>
</div>

