import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MergeOrganisationRefdata, WarehouseLicense} from '@portbase/bezoekschip-service-typescriptmodels';
import {PortvisitUtils} from '../../../../refdata/portvisit-utils';
import {lodash, sendCommand, uuid} from '../../../../common/utils';
import {QueryGateway} from '../../../../common/query-gateway';

@Component({
  selector: 'app-warehouse-license',
  templateUrl: './warehouse-license.component.html',
  styleUrls: ['./warehouse-license.component.css']
})
export class WarehouseLicenseComponent implements OnInit, AfterViewInit, OnDestroy {
  refData = PortvisitUtils;

  @Input() model : WarehouseLicense;
  @Input() mergeAlways: boolean;
  mergeRefdata: boolean;
  editMode: boolean;

  listener = () => this.mergeModel();

  @Output() saved = new EventEmitter<WarehouseLicense>();

  constructor(private queryGateway : QueryGateway, public elementRef: ElementRef) {
  }

  ngOnInit(): void {
    this.editMode = !!this.model.id;
  }

  ngAfterViewInit(): void {
    window.addEventListener("submit", this.listener);
  }

  ngOnDestroy(): void {
    setTimeout(() => window.removeEventListener("submit", this.listener), 0);
  }

  private mergeModel() {
    if (this.mergeAlways || this.mergeRefdata) {
      this.model.id = this.model.id || uuid();
      const value = lodash.assign({}, this.model);
      sendCommand('com.portbase.bezoekschip.common.api.refdata.MergeWarehouseLicense', <MergeOrganisationRefdata>{
        value: value,
        id: this.model.id,
        delete: false
      }, () => {
        this.saved.emit(value);
        this.queryGateway.removeFromCache("com.portbase.bezoekschip.common.api.cargo.FindMyWarehouseLicenses");
      })
    }
  }
}
