import {Component, forwardRef, Input} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {AbstractValueAccessorComponent} from '../component/value-accessor.component';
import moment from 'moment';

@Component({
  selector: 'app-days',
  templateUrl: './days.component.html',
  styleUrls: ['./days.component.css'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DaysComponent), multi: true}
  ],
})
export class DaysComponent extends AbstractValueAccessorComponent<string> {
  model: string | number;
  @Input() required;
  @Input() readonly: boolean = false;

  get value(): any {
    return moment.duration(this.model, 'days').toISOString();
  }

  writeValue(value: string | number): void {
    this.model = DaysComponent.getDays(value);
  }

  public static getDays(value: string | number) {
    if (typeof value == 'string') {
      value = moment.duration(value).asSeconds();
    }
    return moment.duration(value * 1000).asDays();
  }
}
