<div #collapseContent class="collapse" data-bs-parent="#releaseAccordion">
  <div class="py-3 ms-2">
    <div class="my-3 ms-4">
      <div *ngIf="release.releaseStatus?.rejectReasons as rejectReason">
        <app-status-alert type="danger">
          <div>Terminal: {{rejectReason | titlecase}}</div>
        </app-status-alert>
      </div>

      <div class="position-relative">

        <div class="pt-3">
          <app-tab-panel>
            <app-tab-item [templateRef]="details">
              <span class="tab-item" [ngClass]="{'ng-invalid text-invalid' : !!release['ngDetailsInvalid']}">
                Details</span>
              <ng-template #details>
                <div class="nav position-absolute" style="top: 0; right: 0">
                  <div class="row narrow-gutters">
                    <div class="col-auto ms-auto">
                      <app-commercial-release-admin *ngIf="appContext.isAdmin()" [declaration]="release">

                      </app-commercial-release-admin>
                    </div>
                    <div class="col-auto me-1" *ngIf="!release.releaseStatus?.cancelled &&
                    (appContext.isCommercialReleaseDeclarant() || appContext.isCommercialReleaseEditor())">
                      <app-confirmation-toggle>
                        <button class="btn btn-outline-danger py-1" tabindex="-1" style="min-width: 9rem">
                          Cancel
                        </button>
                        <div class="modal-content">
                          <div class="modal-header"><h5 class="modal-title">Send cancel request to terminal</h5></div>
                          <div class="modal-body"><p>Are you sure that you want to cancel this release?</p></div>
                          <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="cancel()">
                              Cancel release
                            </button>
                          </div>
                        </div>
                      </app-confirmation-toggle>
                    </div>

                    <div class="col-auto" *ngIf="!release.releaseStatus?.cancelled &&
                    (appContext.isCommercialReleaseDeclarant() || appContext.isCommercialReleaseEditor())">
                      <button (click)="send(collapseContent)" class="btn btn-outline-info py-1" tabindex="-1">
                        Save and send
                      </button>
                    </div>
                  </div>
                </div>
                <div class="p-3 border border-top-0 rounded-bottom" [appValidator]="release"
                     validatorProperty="ngDetailsInvalid">
                  <app-commercial-release-details [declaration]="release"></app-commercial-release-details>
                </div>
              </ng-template>
            </app-tab-item>
            <app-tab-item *ngIf="release.releaseStatus?.status" [templateRef]="history">
              <span class="tab-item" [ngClass]="{'ng-invalid text-invalid' : !!release['ngDetailsInvalid']}">
                History</span>
              <ng-template #history>
                <div class="p-3 border border-top-0 rounded-bottom">
                  <app-commercial-release-history [id]="release.id"></app-commercial-release-history>
                </div>
              </ng-template>
            </app-tab-item>
          </app-tab-panel>
        </div>
      </div>
    </div>
  </div>
</div>
