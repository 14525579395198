import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'middleEllipsis'
})
export class MiddleEllipsisPipe implements PipeTransform {
  transform(value: any, characterCount?: number, ...args: any[]): any {
    return MiddleEllipsisPipe.format(value, characterCount);
  }

  static format(text: string, characterCount: number = 30): string {
    if (!text?.length || text.length <= characterCount + characterCount) {
      return text;
    }
    const start = text.substring(0, characterCount);
    const end = text.substring(text.length - characterCount);
    return `${start.trim()}...${end.trim()}`;
  }
}
