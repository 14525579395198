<app-form-group label="Short code">
  <input class="form-control" [(ngModel)]="model.shortCode">
</app-form-group>
<app-form-group label="EORI number">
  <input class="form-control" [(ngModel)]="model.tinNumber" maxlength="17">
</app-form-group>
<app-form-group label="Name">
  <input class="form-control" required [(ngModel)]="model.name">
</app-form-group>
<app-form-group label="Address">
  <input class="form-control" [(ngModel)]="model.address" required>
</app-form-group>
<app-form-group label="Zip code">
  <input class="form-control" [(ngModel)]="model.zipCode" required maxLength="9">
</app-form-group>
<app-form-group label="City">
  <input class="form-control" required [(ngModel)]="model.city">
</app-form-group>
<app-form-group label="State">
  <input class="form-control" [(ngModel)]="model.state">
</app-form-group>
<app-form-group label="Country">
  <app-search required [ngModel]="model.countryCode" [searchFunction]="utils.findCountries"
              (ngModelChange)="model.countryCode = $event?.code" [resultFormatter]="utils.countryFormatter">
  </app-search>
</app-form-group>
<app-form-group label="Authorised">
  <app-yes-no [(ngModel)]="model.authorised"></app-yes-no>
</app-form-group>
<!--<app-form-group *ngIf="!mergeAlways" label="{{editMode ? 'Update stored contact' : 'Add to contacts'}}">-->
<!--  <app-yes-no [(ngModel)]="mergeRefdata" [appDefaultValue]="editMode"></app-yes-no>-->
<!--</app-form-group>-->
