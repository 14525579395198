import {Component, Input} from '@angular/core';
import {SolidData} from "@portbase/bezoekschip-service-typescriptmodels";

@Component({
  selector: 'app-edit-solid-good',
  templateUrl: './edit-solid-good.component.html',
  styleUrls: ['./edit-solid-good.component.css']
})
export class EditSolidGoodComponent {
  @Input() solid: SolidData;
}
