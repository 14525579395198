import { Component, Input, OnInit } from '@angular/core';
import { BerthVisit } from '@portbase/bezoekschip-service-typescriptmodels';
import { AppContext } from 'src/app/app-context';
import { VisitContext } from '../../visit-context';

@Component({
  selector: 'app-waste-at-berth-icon',
  templateUrl: './waste-at-berth-icon.component.html',
  styleUrls: ['./waste-at-berth-icon.component.css']
})
export class WasteAtBerthIconComponent implements OnInit {

  context = VisitContext;
  appContext = AppContext;

  @Input() berthVisit: BerthVisit;

  constructor() { }

  ngOnInit() {
  }

  isDeliveringWasteAtBerth() {
    let wasteDeclaration = this.context.visit.wasteDeclaration;
    if (wasteDeclaration != null) {
      let berthIds : string[] = wasteDeclaration.wasteItems.map(wasteItem => wasteItem.berthIdForPickup);
      return berthIds.includes(this.berthVisit.id);
    }
    return false;
  }
}
