<div class="row">
  <div class="col-md-6">
    <app-form-group><label>Id</label><input class="form-control" [(ngModel)]="bunkerProductSupplier.id" disabled required>
    </app-form-group>
    <app-form-group><label>Name</label><input class="form-control" [(ngModel)]="bunkerProductSupplier.name" required>
    </app-form-group>
    <app-form-group><label>EORI Number</label><input class="form-control" [(ngModel)]="bunkerProductSupplier.eori" required>
    </app-form-group>
    <app-form-group><label>Contact</label><input class="form-control" [(ngModel)]="bunkerProductSupplier.contact" required>
    </app-form-group>
    <app-form-group><label>Email</label><input class="form-control" [(ngModel)]="bunkerProductSupplier.email" required>
    </app-form-group>
    <app-form-group><label>Phone</label><input class="form-control" [(ngModel)]="bunkerProductSupplier.phone" required>
    </app-form-group>
  </div>
</div>
