<div class="modal-dialog modal-xl" role="document" *ngIf="selectedConsignments.length === 0">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Select bulk consignments to declare</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
      </button>
    </div>
    <div class="modal-body">
      <div class="row narrow-gutters always-enabled mb-3">
        <div class="col">
          <app-filter [(ngModel)]="searchTerm" [minCharacters]="3"
                      [searchFunction]="searchFunction" [getAllFunction]="getAllFunction"
                      (resultsFound)="renderConsignments($event)"
                      placeholder="Search by crn, consignment nr., container nr., etc.."
                      [appAutoFocus]="true" [autoFocusInOverlay]="true"></app-filter>
        </div>
      </div>

      <div *ngIf="bulkEntities.length > 0" class="row px-3 mb-2 bg-light align-items-center cursor-pointer">
        <div class="col-auto me-2 invisible">
          <div class="form-check form-check-inline m-0" style="height: 2rem">
            <input class="form-check-input m-0" type="checkbox"></div>
        </div>
        <div class="col-2">
          Consignment
        </div>
        <div class="col-1">
          Item no.
        </div>
        <div class="col">
          Packages
        </div>
        <div class="col-2">
          Gross weight
        </div>
        <div class="col-2">
          Net weight
        </div>
        <div class="col-2">
          Vesselcall
        </div>
        <div class="col">
          Loaded
        </div>
      </div>

      <app-pagination #pagination [items]="bulkEntities" [maxPerPage]="5">
        <div *ngFor="let entity of pagination.getItemsOnPage(); trackBy : trackByConsignmentNumber; let index = index">
          <div class="row py-1 px-3 align-items-center text-dark border border-light" style="height: 48px;">
            <div class="col-auto me-2">
              <div class="form-check form-check-inline m-0 always-enabled" style="height: 2rem; width: 13px">
                <input *ngIf="isSelectableConsignment(entity)"
                       [(ngModel)]="entity['selected']" class="always-enabled form-check-input m-0" type="checkbox">
              </div>
            </div>
            <div class="col-2">
              {{ entity.consignmentProcessSummary.masterConsignment.consignmentNumber }}
            </div>
            <div class="col-1">
              {{ entity.goodsItem.goodsItemNumber }}
            </div>
            <div class="col">
              {{ entity.goodsItem.numberOfOuterPackages }}
            </div>
            <div class="col-2">
              <ng-container *ngIf="entity['selected']">
                <input class="form-control"
                       [required]=true
                       type="number" [(ngModel)]="entity.goodsItemEdited.grossWeight"
                       [max]="entity.goodsItem.grossWeight"/>
              </ng-container>
              <ng-container *ngIf="!entity['selected']">
                {{ entity.goodsItemEdited.grossWeight }}
              </ng-container>
            </div>
            <div class="col-2">
              <ng-container *ngIf="entity['selected']">
                <input class="form-control"
                       [required]=true
                       type="number" [(ngModel)]="entity.goodsItemEdited.netWeight"
                       [max]="entity.goodsItem.netWeight"/>
              </ng-container>
              <ng-container *ngIf="!entity['selected']">
                {{ entity.goodsItemEdited.netWeight }}
              </ng-container>
            </div>
            <div class="col-2">
              {{ entity.consignmentProcessSummary.crn }}
            </div>
            <div class="col">
              {{ entity.consignmentProcessSummary.masterConsignment.placeOfLoading?.locationUnCode }}
            </div>
          </div>
        </div>
        <div class="row g-0 text-secondary">
          <div class="col-auto" *ngIf="bulkEntities.length > 1">
            <label class="mt-3">
              <input [(ngModel)]="selectableConsignments().length === selected().length"
                     (click)="toggleSelectAll()" class="always-enabled" type="checkbox">
              <span class="ms-2">Select all</span>
            </label>
          </div>
        </div>
      </app-pagination>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary me-auto" (click)="reset()" data-bs-dismiss="modal">Cancel</button>
      <button type="button" class="btn btn-primary" (click)="selectConsignments()">Continue
        with {{ selected().length }} declaration{{ selected().length === 1 ? '' : 's' }}
        <app-tooltip *ngIf="hasSelectedConsignmentWithWarning()">
          <i class="fa-solid fa-triangle-exclamation text-warning"></i>
          <span class="tooltipContent">
            You have selected at least one declaration with customs warnings
          </span>
        </app-tooltip>
      </button>
    </div>
  </div>
</div>

<app-batch-transit-declaration *ngIf="selectedConsignments.length > 0" [items]="selectedConsignments">
</app-batch-transit-declaration>
