<div class="row">
  <div class="col-2">
    <app-select [(ngModel)]="type" [options]="['berth', 'port', 'portofcall', 'location', 'portfacility', 'vessel',
    'pilotexemption', 'dangerousgood', 'dangerinformation', 'wastematerial', 'bunkerproduct', 'bunkerproductsupplier', 'bunkerlicense', 'terminal', 'carrier', 'sizetype',
    'vesselservice', 'terminalemail']"
                [formatter]="formatOption"
                (ngModelChange)="entry = undefined; searchResult = undefined"></app-select>
  </div>
  <div class="col">
    <app-search [(ngModel)]="searchResult" [searchFunction]="search" [inputFormatter]="searchResultFormatter"
                [minCharacters]="3" (ngModelChange)="entry = searchResult" placeholder="Find a {{type}}"></app-search>
  </div>
  <div class="col-auto" *ngIf="type !== 'dangerousgood'">
    <button class="btn btn-info" type="button" (click)="newEntry()">
      <ng-container *ngIf="searchResult">Duplicate</ng-container>
      <ng-container *ngIf="!searchResult">Add new</ng-container>
      {{type}}
    </button>
  </div>
  <div class="col-auto" *ngIf="type === 'dangerousgood'">
    <div class="dropdown">
      <button class="btn btn-info dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
        <ng-container *ngIf="searchResult">Duplicate</ng-container>
        <ng-container *ngIf="!searchResult">Add new</ng-container>
        good
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <a class="dropdown-item" (click)="newDgEntry('chemical')">Chemical</a>
        <a class="dropdown-item" (click)="newDgEntry('containerGood')">Container good</a>
        <a class="dropdown-item" (click)="newDgEntry('gas')">Gas</a>
        <a class="dropdown-item" (click)="newDgEntry('oil')">Oil</a>
        <a class="dropdown-item" (click)="newDgEntry('solid')">Solid good</a>
      </div>
    </div>
  </div>
</div>

<div class="mt-5" [ngSwitch]="type" *ngIf="entry">
  <div class="declaration-block">
    <h2>{{type | titlecase}} details</h2>
  </div>

  <!--Berth-->
  <div *ngSwitchCase="'berth'">
    <app-edit-berth [berth]="entry"></app-edit-berth>
  </div>

  <!--Terminal-->
  <div *ngSwitchCase="'terminal'">
    <app-edit-terminal [terminal]="entry"></app-edit-terminal>
  </div>

  <!--Port-->
  <div *ngSwitchCase="'port'">
    <app-edit-port [port]="entry"></app-edit-port>
  </div>

  <!--Port of Call-->
  <div *ngSwitchCase="'portofcall'">
    <app-edit-port-of-call [portOfCall]="entry"></app-edit-port-of-call>
  </div>

  <!--Location-->
  <div *ngSwitchCase="'location'">
    <app-edit-location [location]="entry"></app-edit-location>
  </div>

  <!--Port Facility-->
  <div *ngSwitchCase="'portfacility'">
    <app-edit-port-facility [portFacility]="entry"></app-edit-port-facility>
  </div>

  <!--Vessel-->
  <div *ngSwitchCase="'vessel'">
    <app-edit-vessel [vessel]="entry"></app-edit-vessel>
  </div>

  <!--Pilot Exemption-->
  <div *ngSwitchCase="'pilotexemption'">
    <app-edit-pilot-exemption [pilotExemption]="entry"></app-edit-pilot-exemption>
  </div>

  <!--Dangerous Good-->
  <div *ngSwitchCase="'dangerousgood'">
    <app-edit-dangerous-good [dangerousGood]="entry"></app-edit-dangerous-good>
  </div>

  <!--Waste Material-->
  <div *ngSwitchCase="'wastematerial'">
    <app-edit-waste-material [wasteMaterial]="entry"></app-edit-waste-material>
  </div>

  <!--Bunker Product-->
  <div *ngSwitchCase="'bunkerproduct'">
    <app-edit-bunker-product [bunkerProduct]="entry"></app-edit-bunker-product>
  </div>

  <!--Bunker Product Supplier-->
  <div *ngSwitchCase="'bunkerproductsupplier'">
    <app-edit-bunker-product-supplier [bunkerProductSupplier]="entry"></app-edit-bunker-product-supplier>
  </div>

  <!--Bunker License -->
  <div *ngSwitchCase="'bunkerlicense'">
    <app-edit-bunker-license [bunkerLicense]="entry"></app-edit-bunker-license>
  </div>

  <!--Danger Info cargo-->
  <div *ngSwitchCase="'dangerinformation'">
    <app-edit-danger-information [dangerInformation]="entry"></app-edit-danger-information>
  </div>

  <!--Vessel Service-->
  <div *ngSwitchCase="'vesselservice'">
    <app-edit-vessel-service [vesselService]="entry"></app-edit-vessel-service>
  </div>

  <!--Terminal Emails-->
  <div *ngSwitchCase="'terminalemail'">
    <app-edit-terminal-email [terminalEmail]="entry"></app-edit-terminal-email>
  </div>

  <!--Refdata hinterland listens to-->

  <!--Carrier-->
  <div *ngSwitchCase="'carrier'">
    <app-edit-carrier [carrier]="entry"></app-edit-carrier>
  </div>

  <!--Size types-->
  <div *ngSwitchCase="'sizetype'">
    <app-edit-sizetype [sizetype]="entry"></app-edit-sizetype>
  </div>

</div>

<div class="my-2 row justify-content-between" *ngIf="entry">
  <div class="col-auto">
    <button class="btn btn-danger" type="button" *ngIf="searchResult" (click)="deleteEntry()" style="width: 10rem">
      Delete {{type}}
    </button>
  </div>
  <div class="col-auto" *ngIf="!searchResult">
    <button class="btn btn-info" type="button" (click)="addEntry()" style="width: 10rem">
      Add {{type}}
    </button>
  </div>
  <div class="col-auto" *ngIf="searchResult">
    <button class="btn btn-info" type="button" (click)="updateEntry()" style="width: 10rem">
      Save {{type}}
    </button>
  </div>
</div>
