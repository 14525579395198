<ng-container *ngIf="true">
  <app-collapse [rounded]="true" [collapse]="readonly ? null : collapseBerthVisitDetails"
                [showOnLoad]="((!context.visit.terminalPlanningEnabled && !context.hasBeenDeclared()) || !berthVisit.berth || (context.visit.orderNauticalServices && berthVisit.ata && !berthVisit.atd && !berthVisit.nextMovement.order)) || (context.visitInTerminalPlanning() && !terminalVisit?.status?.acceptedByAgent)"
                [animateOnLoad]="true"
                [validationModel]="berthVisit" [renderWhenCollapsed]="true">
    <div class="d-flex justify-content-between">
      <!-- Berth summary -->
      <div class="col-auto d-flex align-items-center p-3" *ngIf="!context.berthVisitInTerminalPlanningMode(berthVisit) else terminalPlanning">
        <div class="align-bottom">
          <ng-container *ngIf="getBerthVisitInfo()?.harbourMasterInfo?.tidalWindowStatus as status">
          <span *ngIf="status.tidalShip" class="fa text-info fa-fw me-2"
                [ngClass]="status.tidalWindowType === 'ASTRO' ? 'fa-satellite-dish' : 'fa-water'"
                title="Tidal restricted"></span>
          </ng-container>
          <span
            class="fw-bold text-portbase-dark">{{formatBerth(berthVisit) | titlecase}}{{formatBerthCode(berthVisit.berth)}}</span>
          <span class="text-muted mx-3 d-none d-lg-inline">{{getTimeAtBerth(berthVisit)}}</span>
        </div>
        <ng-container *ngIf="!readonly">
          <div *ngIf="berthVisit.bollardFrom && berthVisit.bollardTo" class="d-none d-md-block">
            <div class="d-flex text-muted ms-2">
              <div class="align-self-start"><span
                class="border rounded py-1 px-2"><small>{{berthVisit.bollardFrom}}</small></span>
              </div>
              <div class="align-self-baseline" style="margin-top: 2px;"><i class="fa fa-fw fa-arrows-alt-h"></i>
              </div>
              <div class="align-self-start"><span
                class="border rounded py-1 px-2"><small>{{berthVisit.bollardTo}}</small></span>
              </div>
            </div>
          </div>
          <div *ngIf="berthVisit.mooring" class="d-none d-md-block ms-2 text-muted">
            {{mooringFormatter(berthVisit.mooring)}}
          </div>
          <div class="ms-auto d-none d-md-block text-dark">
            <!-- Icons/actions for first berth only -->
            <ng-container *ngIf="firstBerth && !isNextBerthVisit">
              <app-entry-dependency-icon></app-entry-dependency-icon>
            </ng-container>
            <!-- Icons/actions for last berth only -->
            <ng-container *ngIf="lastBerth  && !isNextBerthVisit">
              <!-- Transfer to next agent icon-->
              <span *ngIf="this.context.visit.nextOwner" class="mx-2" [title]="this.context.visit.nextOwner.fullName"><span
                class="fa fa-fw fa-people-arrows me-1"></span></span>
              <!-- Storm pilotage icon-->
              <span *ngIf="!isNextBerthVisit && context.isStormPilotageInfoRequiredForPortEntry(context.visit)" class="mx-2"
                    [title]="'Storm pilotage information required'"><span
                class="fa fa-fw fa-wind me-1"></span></span>
            </ng-container>
            <app-waste-at-berth-icon [berthVisit]="berthVisit"></app-waste-at-berth-icon>
          </div>
        </ng-container>
      </div>
      <!-- Terminal planning summary -->
      <ng-template #terminalPlanning>
        <div class="col-auto d-flex align-items-center p-3">
          <span
            class="fw-bold text-portbase-dark">{{formatBerth(berthVisit) | titlecase}}</span>
          <span class="d-none d-md-inline text-muted mx-3">{{getTimeAtTerminal(berthVisit)}}</span>
          <ng-container *ngIf="!readonly">
            <ng-container *ngIf="terminalVisit?.info?.expectedDischarge || berthVisit.discharge">
            <span class="d-none d-md-inline text-muted mx-3" title="Discharging {{terminalVisit?.info?.expectedDischarge ?? berthVisit.discharge}} containers">
              <i class="fa fa-arrow-left" aria-hidden="true"></i>
              <i class="fa fa-ship" aria-hidden="true"></i>
              {{berthVisit.discharge}}
            </span>
            </ng-container>
            <ng-container *ngIf="terminalVisit?.info?.expectedLoad || berthVisit.load">
            <span class="d-none d-md-inline text-muted mx-3" title="Loading {{terminalVisit?.info?.expectedLoad ?? berthVisit.load}} containers">
              <i class="fa fa-arrow-right" aria-hidden="true"></i>
              <i class="fa fa-ship" aria-hidden="true"></i>
              {{berthVisit.load}}
            </span>
            </ng-container>
            <ng-container *ngIf="terminalVisit?.info">
              <div *ngIf="terminalVisit.info.bollardAft && terminalVisit.info.bollardFore" class="d-none d-md-block">
                <div class="d-none d-md-flex text-muted ms-2">
                  <div class="align-self-start"><span
                    class="border rounded py-1 px-2"><small>{{getTerminalVisitBollardFrom}}</small></span>
                  </div>
                  <div class="align-self-baseline" style="margin-top: 2px;"><i class="fa fa-fw fa-arrows-alt-h"></i>
                  </div>
                  <div class="align-self-start"><span
                    class="border rounded py-1 px-2"><small>{{getTerminalVisitBollardTo}}</small></span>
                  </div>
                </div>
              </div>
              <div *ngIf="terminalVisit?.info?.mooringOrientation" class="d-none d-md-block ms-2 text-muted">
                {{terminalMooringFormatter(terminalVisit.info.mooringOrientation)}}
              </div>
            </ng-container>
          </ng-container>
        </div>
      </ng-template>
      <div *ngIf="!readonly" class="col-auto align-self-center">
        <app-message-status *ngIf="context.visit.terminalPlanningEnabled" [messageStatus]="context.getTerminalDeclarationStatus(berthVisit)"
                            label="Terminal" [reason]="getTerminalVisitTooltipMessage" [header]="terminalTooltipHeader"></app-message-status>
      </div>
    </div>
  </app-collapse>
  <ng-template #collapseBerthVisitDetails>
    <div class="collapse">
      <div class="p-3 border rounded-bottom mb-3">
        <app-berth-visit-details [berthVisit]="berthVisit" [firstBerth]="firstBerth" [lastBerth]="lastBerth"
                                 [pilotOnBoard]="getPilotOnBoard" [isBerthOfTransfer]="isBerthOfTransfer"
                                 [isNextBerthVisit]="isNextBerthVisit"></app-berth-visit-details>
      </div>
    </div>
  </ng-template>
</ng-container>

<!-- Pure Bootstrap (i.e. no angular component) -->
<ng-container *ngIf="false">
  <div class="card rounded-0 border-light collapsible">
    <!-- HEADER -->
    <div class="card-header btn btn-link text-decoration-none always-enabled border-bottom-0 bg-white highlight"
         [id]="berthVisit.id"
         [ngClass]="{'validatable ng-invalid' : berthVisit['ngInvalid'] || berthVisitNotChronological() || previousBerthVisitNotChronological()}"
         data-bs-toggle="collapse" [attr.data-bs-target]="'#berth-collapse-' + berthVisit.id + '-' + idx"
         aria-expanded="false"
         [attr.aria-controls]="'berth-collapse-' + berthVisit.id + '-' + idx">
      <div class="d-flex">
        <div class="align-bottom">
          <h5 class="mb-0">
            <span class="h5 text-start text-portbase-blue"><strong>
                {{berthVisit.berth ? berthVisit.berth.name : 'New berth visit'}}
              </strong></span>
          </h5>
        </div>
        <div *ngIf="berthVisit.bollardFrom && berthVisit.bollardTo" class="d-flex text-muted">
          <div class="align-self-start"><span
            class="border rounded py-1 px-2"><small>{{berthVisit.bollardFrom}}</small></span>
          </div>
          <div class="align-self-baseline" style="margin-top: 2px;"><i class="fa fa-fw fa-arrows-alt-h"></i>
          </div>
          <div class="align-self-start"><span
            class="border rounded py-1 px-2"><small>{{berthVisit.bollardTo}}</small></span>
          </div>
        </div>
        <div class="ms-auto d-none d-md-block text-dark">
          <!-- Icons/actions for first berth only -->
          <ng-container *ngIf="firstBerth && !isNextBerthVisit">
            <app-entry-dependency-icon></app-entry-dependency-icon>
          </ng-container>
          <!-- Icons/actions for last berth only -->
          <ng-container *ngIf="lastBerth  && !isNextBerthVisit">
            <!-- Transfer to next agent icon-->
            <span *ngIf="this.context.visit.nextOwner" class="mx-2"
                  [title]="this.context.visit.nextOwner.fullName"><span
              class="fa fa-fw fa-people-arrows me-1"></span></span>
            <!-- Storm pilotage icon-->
            <span *ngIf="!isNextBerthVisit && context.isStormPilotageInfoRequiredForPortEntry(context.visit)"
                  class="mx-2" [title]="'Storm pilotage information required'"><span
              class="fa fa-fw fa-wind me-1"></span></span>
          </ng-container>
        </div>
      </div>
    </div>
    <!-- BODY -->
    <div [id]="'berth-collapse-' + berthVisit.id + '-' + idx" class="collapse" [appValidator]="berthVisit">
      <div class="card-body border-top border-light bg-light">
        <app-berth-visit-details [berthVisit]="berthVisit" [firstBerth]="firstBerth" [lastBerth]="lastBerth"
                                 [pilotOnBoard]="getPilotOnBoard"
                                 [isBerthOfTransfer]="isBerthOfTransfer"></app-berth-visit-details>
      </div>
    </div>
  </div>
</ng-container>
