import {Component, Input, OnInit} from '@angular/core';
import {VisitContext} from '../../../visit-context';
import {BulkDischargeResult, GoodsItem} from '@portbase/bezoekschip-service-typescriptmodels';
import {PortvisitUtils} from '../../../../refdata/portvisit-utils';
import {ConsignmentModel} from '../../cargo-import.model';

@Component({
  selector: 'app-good-details',
  templateUrl: './good-details.component.html',
  styleUrls: ['./good-details.component.css']
})
export class GoodDetailsComponent implements OnInit {
  context = VisitContext;
  refData = PortvisitUtils;

  actualWeight;

  @Input() item : GoodsItem;
  @Input() consignment;

  ngOnInit(): void {
    const d : BulkDischargeResult = this.consignment.bulkDischarges.find(d => d.itemNumber === this.item.itemNumber);
    if (d) {
      this.actualWeight = d.grossWeight;
    }
  }

  onOuterPackageChange() {
    if (this.item.outerPackageType && this.item.outerPackageType.bulk) {
      this.item.numberOfOuterPackages = 1;
      delete this.item.numberOfInnerPackages;
      delete this.item.innerPackageType;
      delete this.item.netWeight;
    }
  }
}
