import {Component, Input, OnInit} from '@angular/core';
import {IE3GoodsItem} from '@portbase/bezoekschip-service-typescriptmodels';
import {PortvisitUtils} from "../../refdata/portvisit-utils";

@Component({
  selector: 'app-transhipment-good-details',
  templateUrl: './transhipment-good-details.component.html',
  styleUrls: ['./transhipment-good-details.component.css']
})
export class TranshipmentGoodDetailsComponent implements OnInit {
  refData = PortvisitUtils;

  // actualWeight;

  @Input() item: IE3GoodsItem;

  // @Input() consignment;

  ngOnInit(): void {
    // const d : BulkDischargeResult = this.consignment.bulkDischarges.find(d => d.itemNumber === this.item.itemNumber);
    // if (d) {
    //   this.actualWeight = d.grossWeight;
    // }
  }

  onOuterPackageChange() {
    if (this.item.outerPackaging.typeOfPackages && this.item.outerPackaging.typeOfPackages.bulk) {
      this.item.outerPackaging.numberOfPackages = 1;
      delete this.item.innerPackagingList;
      delete this.item.netWeight;
    }
  }
}
