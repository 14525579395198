<div class="row">
  <div class="col-md-6" *ngIf="portEntry.origin === 'HINTERLAND'">
    <app-form-group>
      <label for="planningHinterland">Planning based on</label>
      <app-select id="planningHinterland" [(ngModel)]="portEntry.baseForPlanning" [options]="baseForPlanningOptions"
        [disabled]="!firstBerthVisit" [formatter]="baseForPlanningFormatter" required>
      </app-select>
    </app-form-group>
  </div>
  <div class="col-md-6" *ngIf="portEntry.origin === 'SEA'">
    <app-form-group>
      <label for="intention">Intention</label>
      <app-select id="intention" [(ngModel)]="portEntry.intention" [options]="intentionsOptions"
        [disabled]="(this.portEntry.intention === 'REQUEST_FOR_ENTRY') && (context.visit.visitDeclaration.portVisit.firstMovement.order || (!!this.context.visit.incomingTidalWindowStatus && this.context.visit.incomingTidalWindowStatus.tidalWindows.length > 0))" (ngModelChange)="resolveIntention()"
        [formatter]="intentionsFormatter" required placeholder="– Signal intention –">
      </app-select>
    </app-form-group>
  </div>
  <div class="col-md-6">
    <app-form-group *ngIf="portEntry.origin === 'SEA' && portEntry.intention === 'REQUEST_FOR_ENTRY'">
      <label for="etaLocation">Planning based on</label>
      <app-select id="etaLocation" [(ngModel)]="portEntry.baseForPlanning" [options]="baseForPlanningOptions"
        [disabled]="planningBasedOnDisabled()"
        (ngModelChange)="resolveBaseForPlanning($event)" [formatter]="baseForPlanningFormatter" required>
      </app-select>
    </app-form-group>
  </div>
</div>
<div class="row">
  <div class="col-md-6">
    <app-port-passage-planning-area></app-port-passage-planning-area>
    <app-pilot-boarding-place></app-pilot-boarding-place>
    <app-form-group *ngIf="portEntry.origin === 'SEA'">
      <label for="etaSeabuoy">ETA sea buoy</label>
      <app-date-field [(ngModel)]="portEntry.etaSeaBuoy" required id="etaSeabuoy"></app-date-field>
    </app-form-group>
    <ng-container *ngIf="pilotOnBoard">
      <app-form-group class="formGroupLabel">
        <label>Pilot on board</label>
        <app-date-field [(ngModel)]="pilotOnBoard" disabled></app-date-field>
      </app-form-group>
    </ng-container>
    <app-eta-eu-entry [(etaFirstEntryEu)]="context.visit.visitDeclaration.etaFirstEntryEu"></app-eta-eu-entry>
    <app-earliest-time-port-entry></app-earliest-time-port-entry>
  </div>
  <div class="col-md-6">
    <app-entry-point></app-entry-point>
    <app-arrival-voyage [voyage]="context.visit.visitDeclaration.arrivalVoyage"></app-arrival-voyage>
  </div>
</div>
