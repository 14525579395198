<div class="fieldset" [ngClass]="{'disabled' :disabled}">
  <div class="row datetime">
    <div #picker class="col input-group dropdown" [ngClass]="{'dropdown-backdrop': showBackdrop}">
      <ng-content select=".input-group-prepend"></ng-content>
      <ng-container [ngSwitch]="inputMode">
        <div *ngSwitchCase="'button'" class="btn btn-outline-primary" data-bs-toggle="dropdown"
             [ngClass]="!show ? 'invisible p-0' : ''"
             (click)="openCalendar(); $event.stopPropagation();">
          <ng-content></ng-content>
        </div>
        <ng-container *ngSwitchCase="'input'">
          <input [(ngModel)]="formattedDate" (ngModelChange)="onDateChange()" [ngModelOptions]="{updateOn: 'blur'}"
                 [required]="required" (focus)="this.focus.emit($event)" (blur)="this.blur.emit($event)"
                 [disabled]="disabled" [id]="id ? id + '-date' : null" class="form-control validatable"
                 [ngClass]="status === 'warning' ? 'warning' : status === 'error' ? 'error' : null"
                 autocomplete="off" [placeholder]="'mmm dd, yyyy' + separator + 'mmm dd, yyyy'">
          <button #dropdownButton class="btn btn-outline-secondary fa fa-calendar-alt rounded-end"
                  data-bs-toggle="dropdown" (click)="openCalendar(); $event.stopPropagation();"
                  (focus)="this.focus.emit($event)" type="button" tabIndex="-1"></button>
        </ng-container>
      </ng-container>
      <div #dropdownMenu class="dropdown-menu dropdown-menu-end" onclick="event.stopPropagation()">
        <div class="row g-0 flex-md-nowrap">
          <div class="col p-3">
            <span *ngIf="header" class="h5 ms-2">{{header}}</span>
            <app-date-picker *ngIf="showPicker" [date]="_calendarStartDate" [minDate]="_minDate"
                             [range]="true" [numberOfCalendars]="numberOfMonths"
                             (dateChanged)="onDatePickerChanged($event, true)"></app-date-picker>
            <div *ngIf="includeTime" class="row w-100 mx-0 p-3">
              <app-time-picker class="col" [ngModel]="startTime"
                               (ngModelChange)="onTimeChanged($event)"></app-time-picker>
              <app-time-picker class="col" [ngModel]="endTime"
                               (ngModelChange)="onTimeChanged($event)"></app-time-picker>
            </div>
          </div>
          <div *ngIf="ranges" class="border-start-1 col-12 col-md-auto bg-body-tertiary p-3"
               [style.min-width]="'200px'">
            <div class="w-100 position-relative">
              <div class="btn-close-wrapper rounded-circle position-absolute end-0 top-0">
                <button type="button" class="btn-close" aria-label="Close" (click)="closeCalendar()"></button>
              </div>
            </div>
            <div class="d-flex flex-column align-items-stretch" style="margin-top: 62px;">
              <ng-container *ngFor="let range of ranges">
                <button class="btn btn-outline-primary my-1 text-start bg-white" (click)="selectRange(range)"
                        [ngClass]="{'active': utils.rangeSelected(_date, range)}">
                  {{range.label}}
                </button>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="row g-0 border-top p-3">
          <div class="col-auto d-flex align-items-center">
            <input [(ngModel)]="_dateFormatted.start" (blur)="onDateFormattedChange()"
                   autocomplete="off" class="form-control validatable" placeholder="dd-mm-yyyy">
          </div>
          <div class="col-auto d-flex align-items-center px-3">
            –
          </div>
          <div class="col-auto d-flex align-items-center">
            <input [(ngModel)]="_dateFormatted.end" (blur)="onDateFormattedChange()"
                   autocomplete="off" class="form-control validatable" placeholder="dd-mm-yyyy">
          </div>
          <ng-container *ngIf="manual">
            <div class="col">
            </div>
            <div class="col-auto ms-auto d-flex align-items-center px-1">
              <button class="btn btn-secondary" (click)="cancelCalendar()">
                Cancel
              </button>
            </div>
            <div class="col-auto ms-auto d-flex align-items-center ps-3">
              <button class="btn btn-primary" (click)="apply()">
                Apply
              </button>
            </div>
          </ng-container>
        </div>
      </div>
      <ng-content select=".input-group-append"></ng-content>
    </div>
  </div>
</div>
