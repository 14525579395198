<div *ngIf="appContext.isAdmin()">
  <div class="row narrow-gutters align-items-center my-4">
    <div class="col always-enabled">
      <app-local-filter [data]="loadLists" (updated)="filteredLists = $event" [filterFunction]="filterList"
                        [placeholder]="'Filter by consignment nr, good name, etc.'"></app-local-filter>
    </div>
  </div>

  <div *ngFor="let list of filteredLists; let index = index; trackBy : listById" class="mb-3">
    <div class="row g-0">
      <div class="col-auto me-2">
        <div class="form-check form-check-inline m-0 always-enabled" style="height: 3.5rem">
          <input [(ngModel)]="list['selected']" class="always-enabled form-check-input m-0 always-enabled"
                 type="checkbox"></div>
      </div>
      <div class="col">
        <app-collapse #loadListCollapse [collapse]="listTemplate">
          <div class="row pe-3 g-0 align-items-center">
              <div class="col-auto me-3 p-3"
                   title="Your discharge list has not been sent to the terminal">
                <span class="fa fa-fw fa-map-marker-alt text-portbase-blue"></span>
              </div>
            <div class="col fw-bold text-dark">{{list.terminal.terminalName | titlecase}}</div>
            <div class="col-auto d-none d-md-block text-dark">
              <app-cargo-summary [loadList]="list" [keepTooltipOpen]="loadListCollapse.showing"></app-cargo-summary>
            </div>
          </div>
        </app-collapse>
      </div>
    </div>

    <ng-template #listTemplate>
      <div class="collapse">
        <div class="my-3">
          <div class="declaration-block my-4" *ngIf="list.overlanders.length > 0">
            <h2>Overlanded</h2>
          </div>
          <app-loading-overlanders [setOverlanders]="list.overlanders" [readonly]="true"></app-loading-overlanders>
        </div>
      </div>
    </ng-template>
  </div>

  <div *ngIf="filteredLists.length === 0" class="text-center text-muted py-2">
    {{loadLists.length > 0 ? 'No matching items found.' : 'You haven’t declared any containers.'}}
  </div>

  <ng-container *ngIf="selectedLists.length > 0">
    <div class="fixed-bottom row g-0 justify-content-center align-items-center bg-white py-3 border-top">
    </div>
  </ng-container>
</div>
