<!--<div class="rounded-circle text-white" style="min-height: 1rem; min-width: 1rem" [ngClass]="backgroundColorClass">-->
  <ng-container [ngSwitch]="messageStatus">
    <span *ngSwitchCase="'DISABLED'" class="fa fa-light fa-circle-minus ps-2 pe-1 py-2 text-muted"></span>
    <span *ngSwitchCase="'UNKNOWN'" class="fa fa-light fa-circle-question ps-2 pe-1 py-2"></span>
    <span *ngSwitchCase="'PENDING'" class="fa fa-light fa-paper-plane ps-2 pe-1 py-2 text-info"></span>
    <span *ngSwitchCase="'DELIVERED'" class="fa fa-light fa-inbox ps-2 pe-1 py-2 text-portbase-green"></span>
    <span *ngSwitchCase="'ACCEPTED'" class="fa fa-light fa-circle-check ps-2 pe-1 py-2 text-portbase-green"></span>
    <span *ngSwitchCase="'WARNING'" class="fa fa-light fa-comment-exclamation ps-2 pe-1 py-2 text-warning"></span>
    <span *ngSwitchCase="'REJECTED'" class="fa fa-light fa-circle-xmark ps-2 pe-1 py-2 text-portbase-red"></span>
  </ng-container>
<!--</div>-->
