<div class="row g-0 py-1 px-3 align-items-center text-dark">
  <div class="col-2" style="min-width: 12rem" [title]="model.consignmentNumber">
    {{model.consignmentNumber}}
  </div>
  <div class="col-2 small text-secondary cut-text" style="min-width: 12rem" [title]="model.dischargeTerminal ?
        model.dischargeTerminal?.terminalCode + ' - ' + model.dischargeTerminal?.terminalName : ''">
    <span class="fa fa-sign-out-alt"></span>
    {{model.dischargeTerminal ? model.dischargeTerminal?.terminalName : ""}}
  </div>
  <div class="col-3 small text-secondary cut-text" style="min-width: 12rem" [title]="model.placeOfDestination ?
        model.placeOfDestination?.locationUnCode + ' - ' + model.placeOfDestination?.name : ''">
    <span class="fa fa-sign-in-alt"></span>
    {{model.placeOfDestination ? model.placeOfDestination?.name : ""}}
  </div>
  <div class="col-2">
    <ng-container *ngIf="model.etaFirstBerth != null && model.ataFirstBerth == null">
      ETA: {{formatDate(model.etaFirstBerth)}}
    </ng-container>
    <ng-container *ngIf="model.ataFirstBerth">
      ATA: {{formatDate(model.ataFirstBerth)}}
    </ng-container>
  </div>
  <div class="col-auto ms-auto d-flex justify-content-end" *ngIf="!model.cancelled">
    <span *ngIf="midPhaseType() as type" class="mx-1 text-center">
      <span class="badge rounded-pill align-text-bottom" [ngClass]="'text-bg-' + type">{{midPhase()}}</span>
    </span>
    <span *ngIf="renPhaseType() as type" class="mx-1 text-center">
      <span class="badge rounded-pill align-text-bottom" [ngClass]="'text-bg-' + type">{{renPhase()}}</span>
    </span>
  </div>
  <div class="col-auto ms-auto d-flex justify-content-end" *ngIf="model.cancelled" style="min-width: 12rem">
    <span class="badge rounded-pill align-text-bottom" [ngClass]="'text-bg-secondary'">Cancelled</span>
  </div>
</div>
