import {Component, Input, OnInit} from '@angular/core';
import {CollectorSummary, VisitStatus, WasteCollection} from '@portbase/bezoekschip-service-typescriptmodels';
import {AppContext} from '../../../app-context';
import {WasteCollectorUtils} from '../../waste-utils';

@Component({
  selector: 'app-collector-summary-title-bar',
  templateUrl: './collector-summary-title-bar.component.html',
  styleUrls: ['./collector-summary-title-bar.component.css']
})
export class CollectorSummaryTitleBarComponent implements OnInit {

  @Input() summary: CollectorSummary;

  appContext = AppContext;
  utils = WasteCollectorUtils;

  constructor() {
  }

  ngOnInit() {
  }

  getVisitStatusIcon(visitStatus: VisitStatus) {
    switch (visitStatus){
      case "ARRIVED": return [<VisitStatusIcon> {icon: "fa-ship", color: "text-portbase-blue", tooltip: "Arrived" }];
      case "DEPARTED": return [<VisitStatusIcon> {icon: "fa-sign-out-alt", color: "text-portbase-blue", tooltip: "Departed"}];
      case "EXPECTED": return [<VisitStatusIcon> {icon: "fa-sign-in-alt", color: "text-portbase-blue", tooltip: "Expected"}];
    }
  }

  hasOpenCollections(collections: WasteCollection[]) {
    return collections.some(value => !value.endTime);
  }

  hasOpenFinancials(collections: WasteCollection[]) {
    return collections.some(value => value.fixedCosts == null);
  }

  formatBerth(summary: CollectorSummary) {
    let berthVisit;
    if (summary.visitStatus === 'DEPARTED') {
      berthVisit = summary.berthVisits[summary.berthVisits.length -1];
    } else if(summary.visitStatus === 'EXPECTED') {
      berthVisit = summary.berthVisits[0]
    }
    else {
      berthVisit = summary.berthVisits.find(value => !value.ata || !value.atd);
			if(!berthVisit) {
				berthVisit = summary.berthVisits[summary.berthVisits.length -1];
			}
    }

    let status: string = !!berthVisit.atd ? 'Departed: ' + this.utils.formatDate(berthVisit.atd) :
      (berthVisit.ata ? 'Arrived: ' + this.utils.formatDate(berthVisit.ata) : (berthVisit.eta ? 'Expected: ' + this.utils.formatDate(berthVisit.eta) : ''));
    return berthVisit.name + ', ' + status;
  }
}

interface VisitStatusIcon {
  color: string,
  icon: string,
  tooltip: string
}
