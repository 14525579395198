<div class="row sticky-top bg-white" style="top:6.125rem">
  <div class="col-md-12">
    <app-alerting></app-alerting>
  </div>
</div>


<div class="row mt-3 mb-3 justify-content-between align-items-end align-items-center">
  <div class="col" *ngIf="appContext.isAdmin()">
    <div class="row">
      <div class="col-7" style="height: 38px">
        <app-form-group label="Short name">
          <app-search [ngModel]="filterOrganisationShortName" [searchFunction]="searchOrganisation"
                      (ngModelChange)="setOrganisationName($event)" [resultFormatter]="formatter"
                      placeholder="Find by organisation">
          </app-search>
        </app-form-group>
      </div>
    </div>
  </div>

  <div class="col text-end d-flex align-items-center" *ngIf="appContext.isTransitEditor()">
    <a href="#" class="nav-link p-0" (click)="$event.preventDefault(); add()" style="width: 100%">
      <span class="pe-2 fa fa-plus-circle"></span>Add transit party</a>
  </div>
</div>

<app-pagination #pagination [items]="values" class="accordion" id="releaseAccordion">
  <div *ngFor="let value of pagination.getItemsOnPage(); let index = index; trackBy: trackById" class="my-2"
       [ngClass]="{'fieldset disabled' : !appContext.isTransitEditor()}">
    <app-collapse [collapse]="valueTemplate" [showOnLoad]="!value.id" [deleteHandle]="remove(value)">
      <div class="row g-0 align-items-center">
        <div class="col-auto mx-2 p-2">
          <span class="fas fa-copy text-dark"></span>
        </div>
        <div class="col text-dark">
          <span class="d-inline-block align-top text-truncate">{{value.name || 'New transit party'}}</span>
        </div>
        <div class="col-1 d-none d-md-block small text-secondary" style="min-width: 12rem" *ngIf="appContext.isAdmin()">
          <span class="fa fa-fw fa-users"></span>
          <span class="ms-1">{{value.ownerShortName}}</span>
        </div>
        <div class="col-2 d-none d-md-block small text-secondary" style="min-width: 12rem">
          <span class="d-inline-block align-top text-truncate">{{value.shortCode}}</span>
        </div>
        <div class="col-2 d-none d-md-block small text-secondary" style="min-width: 12rem">
          <span class="d-inline-block align-top text-truncate">{{value.tinNumber}}</span>
        </div>
      </div>
    </app-collapse>

    <ng-template #valueTemplate>
      <div class="collapse">
        <div>
          <div class="mx-3 pt-3">
            <form (ngSubmit)="save() ? null : $event.stopPropagation();">
              <div>
                <div class="declaration-block">
                  <h2>
                    <div class="row">
                      <div class="col mt-3">Transit party details</div>
                      <div class="col text-end">
                        <div class="btn-group">
                          <button class="btn btn-outline-info" type="submit" style="min-width: 8rem">Save</button>
                        </div>
                      </div>
                    </div>
                  </h2>
                </div>
              </div>
              <app-transit-party [model]="value" (saved)="onSaved()"></app-transit-party>
            </form>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</app-pagination>

<div *ngIf="values.length === 0" class="text-center text-muted" style="padding: 20px 0">
  You haven’t added any transit parties yet.
</div>
