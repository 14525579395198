<div class="row">
  <div class="col-md-6">
    <app-form-group>
      <label>Select stowage</label>
      <app-multiselect [(ngModel)]="handling.restowedStowage" [options]="handling.options" dataKey="stowageNumber"
                       [hideSelected]="true" [enableSelectAll]="true" [autoSelectOnlyOption]="true"
                       placement="top-end" title="Select stowage to restow"></app-multiselect>
    </app-form-group>
  </div>
</div>

<div class="row g-0 bg-secondary text-light py-2">
  <div class="col-auto" style="width: 11rem"><span class="ps-2">Items</span></div>
  <div class="col-auto" style="width: 11rem">New position *</div>
  <div class="col-auto ps-2" style="width: 2rem"></div>
</div>

<div *ngFor="let stowage of handling.restowedStowage; trackBy: stowageById; let index = index">
  <div class="row g-0 my-2">

    <div class="col">
      <div class="row g-0">
        <div class="col-auto" style="width: 11rem">
          <input [ngModel]="stowage.stowageNumber" class="form-control bg-transparent border-0" [disabled]="true">
        </div>

        <div class="col-auto" style="width: 11rem">
          <input required [(ngModel)]="stowage.newPosition" class="form-control" placeholder="BBBRRTT">
        </div>
      </div>
    </div>

    <div class="col-auto text-end" style="min-width: 2rem">
      <button title="Delete stowage" (click)="deleteStowage(stowage)"
              class="btn" type="button" tabindex="-1"><span class="fa fa-trash text-secondary"></span>
      </button>
    </div>

  </div>
</div>
