import {Component, Input} from '@angular/core';
import {BlendingModel, DangerousGoodsModel} from '../../dangerous-goods.model';
import {VisitContext} from '../../../visit-context';

@Component({
  selector: 'app-edit-blending',
  templateUrl: './edit-blending.component.html',
  styleUrls: ['./edit-blending.component.css']
})
export class EditBlendingComponent {

  get dangerousGoods() : DangerousGoodsModel {
    return <DangerousGoodsModel>VisitContext.dangerousGoodsDeclaration.dangerousGoods;
  }

  @Input() handling: BlendingModel;

}
