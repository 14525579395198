import {AfterViewInit, Component, OnDestroy, QueryList, ViewChildren} from '@angular/core';
import {
  DateTimeRange,
  GetMetrics,
  RefreshTrackerStats,
  RefreshTrackingResultMetrics, TrackerStats,
  TrackingMetric,
  MetricsResult, GetMessagingMetrics, MessageType, RefreshMessagingMetrics, MessagingMetrics
} from "@portbase/bezoekschip-service-typescriptmodels";
import moment, {unitOfTime} from "moment/moment";
import {ServiceMonitoringContext} from "./service-monitoring-context";
import {ChartData, ChartDataset} from "chart.js";
import {takeUntil} from "rxjs/operators";
import {ServiceMonitoringBaseChart} from "./service-monitoring-base-chart.component";
import {stringToRgba} from "./chart-utils";
import lodash from "lodash";
import {sendQuery} from "../../../common/utils";
import {DateRange} from "../../../common/date/date-range/date-range";

type TimeDefinitions = 'relative' | 'absolute';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './service-monitoring.component.html',
  styleUrls: ['./service-monitoring.component.scss']
})
export class ServiceMonitoringComponent implements AfterViewInit, OnDestroy {
  context = ServiceMonitoringContext;
  @ViewChildren(ServiceMonitoringBaseChart) charts: QueryList<ServiceMonitoringBaseChart>;

  zoomed: boolean;
  dateTimeRange: DateTimeRange;
  timeFilter: TimeDefinitions = 'relative';
  timeValue: number = 1;
  measurement: unitOfTime.Base = "days";
  measurements: any[] = Object.values(MeasurementTypes).filter((v) => isNaN(Number(v)));
  private dataDebounceTime = 500;

  private hiddenLabels: string[];

  autoRefreshTimes: number[] = [5, 10, 15, 20, 30, 45, 60];
  currentAutoRefreshInSeconds: number;
  currentRefreshTimeout: number;

  // Datasets
  totalUpdates: ChartData;
  updatesPerUpdateType: { [key: string]: ChartData } = {};
  trackingApiResultData: ChartData;
  totalMessagingMetrics: { [P in MessageType]?: number } = {};
  totalAmountOfUpdatesPerConsumer: ChartData;

  private cachedTrackingUserData: DashboardMetricsResult<TrackerStats> = {data: []};
  private cachedTrackingPollTimeData: DashboardMetricsResult<TrackingMetric> = {data: []};
  private cachedMessagingMetricsResult: DashboardMetricsResult<MessagingMetrics> = {data: []};

  get getCurrentTimeRange(): string {
    return this.timeFilter === 'relative'
      ? `${this.timeValue} ${this.measurement} ago`
      : `${moment(this.dateTimeRange.start).format("DD-MMM-YYYY HH:mm:ss")} → ${moment(this.dateTimeRange.end).format("DD-MMM-YYYY HH:mm:ss")}`;
  }

  createTrackingTimeDataset(result: DashboardMetricsResult<TrackingMetric>, dateTimeRange: DateTimeRange) {
    this.cachedTrackingPollTimeData = result;
    this.trackingApiResultData = {
      datasets: [{
        backgroundColor: "rgb(130,202,221,0.75)",
        borderColor: "rgb(130,202,221,0.75)",
        hoverBackgroundColor: "rgb(130,202,221)",
        data: ServiceMonitoringContext.groupByTimeFrames(result.data.map(t => ({
          x: Date.parse(t.eventTime),
          y: t.differenceMillis
        })), dateTimeRange, lodash.mean)
      }]
    };
  }

  createTrackerDatasets(result: DashboardMetricsResult<TrackerStats>, dateTimeRange: DateTimeRange) {
    this.cachedTrackingUserData = result;

    const totalDataPerUpdateType: { [updateType: string]: TrackerStatsRecord[] } = {};
    const totalDataPerUser: { [user: string]: TrackerStatsRecord[] } = {};

    this.cachedTrackingUserData.data.filter(r => r.countPerUpdateType).forEach(r => {
      Object.entries(r.countPerUpdateType).forEach((e) => {
        const updateType = e[0];
        totalDataPerUpdateType[updateType] = totalDataPerUpdateType[updateType] || [];
        totalDataPerUpdateType[updateType].push({
          user: r.user,
          timestamp: r.timestamp,
          value: e[1]
        });
      });
      totalDataPerUser[r.user] = totalDataPerUser[r.user] || [];
      totalDataPerUser[r.user].push({
        user: r.user,
        timestamp: r.timestamp,
        value: lodash.sum(Object.values(r.countPerUpdateType))
      });
    });

    this.totalUpdates = {
      datasets: lodash.map(Object.entries(totalDataPerUser).map(entry => {
        const user = entry[0];
        return <ChartDataset>{
          label: user,
          backgroundColor: stringToRgba(user),
          borderColor: stringToRgba(user),
          hidden: this.hiddenLabels?.includes(user),
          data: ServiceMonitoringContext.groupByTimeFrames(entry[1].map(t => ({
            x: Date.parse(t.timestamp),
            y: t.value
          })), dateTimeRange, lodash.sum)
        };
      }))
    }

    Object.entries(totalDataPerUpdateType).forEach(e => {
      this.updatesPerUpdateType[e[0]] = {
        datasets: lodash.chain(e[1])
          .groupBy(r => r.user)
          .map((value, user) => (<ChartDataset>{
              label: user,
              backgroundColor: stringToRgba(user),
              borderColor: stringToRgba(user),
              hidden: this.hiddenLabels?.includes(user),
              data: ServiceMonitoringContext.groupByTimeFrames(value
                .map(t => ({
                  x: Date.parse(t.timestamp),
                  y: t.value
                })), dateTimeRange, lodash.sum)
            }
          ))
          .value()
      }
    });

    this.totalAmountOfUpdatesPerConsumer = {
      labels: this.totalUpdates.datasets.map(d => d.label),
      datasets: [<ChartDataset<'doughnut'>>{
        backgroundColor: this.totalUpdates.datasets.map(r => r.backgroundColor),
        hoverBackgroundColor: this.totalUpdates.datasets.map(r => r.backgroundColor),
        hoverBorderColor: "rgb(0,0,0,0)",
        data: this.totalUpdates.datasets.map(d => lodash.sum(d.data.map(r => r["y"]))),
        datalabels: {
          labels: {
            value: {
              align: 'center',
              backgroundColor: 'white',
              borderColor: 'white',
              borderWidth: 2,
              borderRadius: 4,
              padding: 4
            }
          }
        }
      }]
    };
  }

  createMessagingMetricsDataset(result: MetricsResult) {
    this.cachedMessagingMetricsResult = result;
    this.totalMessagingMetrics = {};
    lodash.forEach(lodash.groupBy(result.data, rec => rec.messageType), (rec, messageType) => {
      this.totalMessagingMetrics[messageType] = lodash.sum(rec.map(e => e.amount));
    });
  }

  ngAfterViewInit(): void {
    ServiceMonitoringContext.dateTimeRange.pipe(takeUntil(ServiceMonitoringContext.destroyNotifier))
      .subscribe(d => {
        this.createTrackerDatasets(this.cachedTrackingUserData, d);
        this.createTrackingTimeDataset(this.cachedTrackingPollTimeData, d);
        this.createMessagingMetricsDataset(this.cachedMessagingMetricsResult);
      });
    ServiceMonitoringContext.zoomSubject.pipe(takeUntil(ServiceMonitoringContext.destroyNotifier))
      .subscribe(() => {
        this.zoomed = true;
      });
    setTimeout(() => {
      this.loadFromLocalStorage();
      this.setDateRange();
      this.debouncedGetTrackingMetrics();
    }, 0);
  }

  ngOnDestroy(): void {
    ServiceMonitoringContext.destroyNotifier.next();
    ServiceMonitoringContext.destroyNotifier.complete();
    this.stopRefresh();
  }

  resetZoom() {
    this.zoomed = false;
    this.setDateRange();
    ServiceMonitoringContext.resetZoom.next();
  }

  onRefreshButtonClick = () => {
    if (this.currentRefreshTimeout) {
      this.stopRefresh();
    } else {
      this.refreshTrackingMetrics();
    }
  }

  private getTrackingMetrics = () => {
    sendQuery("com.portbase.bezoekschip.common.api.metrics.GetTrackingResultMetrics", this.getDataPayload(), {
      caching: true
    }).subscribe((result: DashboardMetricsResult<TrackingMetric>) => {
      this.createTrackingTimeDataset(result, this.dateTimeRange);
    });
    sendQuery("com.portbase.bezoekschip.common.api.metrics.GetTrackerStats", this.getDataPayload(), {
      caching: true
    }).subscribe((result: DashboardMetricsResult<TrackerStats>) => {
      this.createTrackerDatasets(result, this.dateTimeRange);
    });
    sendQuery("com.portbase.bezoekschip.common.api.metrics.GetMessagingMetrics", <GetMessagingMetrics>{
      dateRange: this.getDataPayloadDateRange()
    }, {
      caching: true
    }).subscribe((result: DashboardMetricsResult<MessagingMetrics>) => {
      this.createMessagingMetricsDataset(result);
    });
  }

  private refreshTrackingMetrics = (): void => {
    sendQuery("com.portbase.bezoekschip.common.api.metrics.RefreshTrackingResultMetrics", <RefreshTrackingResultMetrics>{
      lastMatch: this.cachedTrackingPollTimeData?.lastMatch
    }).subscribe((result: DashboardMetricsResult<TrackingMetric>) => {
      result = this.mergeWithCachedData(result, this.cachedTrackingPollTimeData, (a => moment(a.eventTime)));
      this.createTrackingTimeDataset(result, this.dateTimeRange);
    });

    sendQuery("com.portbase.bezoekschip.common.api.metrics.RefreshTrackerStats", <RefreshTrackerStats>{
      lastMatch: this.cachedTrackingUserData?.lastMatch
    }).subscribe((result: DashboardMetricsResult<TrackerStats>) => {
      result = this.mergeWithCachedData(result, this.cachedTrackingUserData, (a => moment(a.timestamp)));
      this.createTrackerDatasets(result, this.dateTimeRange);
    });

    sendQuery("com.portbase.bezoekschip.common.api.metrics.RefreshMessagingMetrics", <RefreshMessagingMetrics>{
      lastMatches: this.cachedMessagingMetricsResult?.lastMatch
    }).subscribe((result: DashboardMetricsResult<MessagingMetrics>) => {
      result = this.mergeWithCachedData(result, this.cachedMessagingMetricsResult, (a => moment(a.timestamp)));
      this.createMessagingMetricsDataset(result);
    });
  }

  private debouncedGetTrackingMetrics = lodash.debounce(this.getTrackingMetrics, this.dataDebounceTime);

  hiddenLabelsChanged = (hiddenLabels: string[]) => this.hiddenLabels = hiddenLabels;

  refreshOverTime = (seconds: number): void => {
    this.currentAutoRefreshInSeconds = seconds;
    this.refreshTrackingMetrics();
    this.autoRefreshUsing(seconds);
  }

  onSelectionChange = (): void => {
    this.setLocalStorage();
    this.resetZoom();
    this.debouncedGetTrackingMetrics();
  };

  measurementSelected = (value: unitOfTime.Base) => {
    if (value) {
      this.measurement = value;
      this.timeFilter = 'relative';
      this.onSelectionChange();
    }
  }

  onTimeValueChange = (timeValue: number) => {
    if (timeValue) {
      this.timeValue = timeValue;
      this.timeFilter = 'relative';
      this.onSelectionChange();
    }
  }

  onDateSelection = (dateRange: DateRange): void => {
    if (dateRange) {
      this.dateTimeRange = dateRange;
      this.timeFilter = 'absolute';
      this.stopRefresh();
      this.onSelectionChange();
    }
  }

  private setDateRange() {
    this.dateTimeRange = this.timeFilter === "relative"
      ? {
        start: moment().subtract(this.timeValue, this.measurement).toISOString(),
        end: moment().toISOString()
      } : this.dateTimeRange;
    ServiceMonitoringContext.dateTimeRange.next(this.dateTimeRange);
  };

  private getDataPayload = (): GetMetrics => ({
    dateRange: this.getDataPayloadDateRange()
  });

  private getDataPayloadDateRange = (): DateTimeRange => ({
    start: moment(this.dateTimeRange.start).toISOString(),
    end: moment(this.dateTimeRange.end).toISOString()
  })

  private autoRefreshUsing = (seconds: number): void => {
    if (this.currentRefreshTimeout) {
      clearTimeout(this.currentRefreshTimeout);
      this.currentRefreshTimeout = null;
    }
    this.currentRefreshTimeout = setTimeout(() => {
      if (this.currentRefreshTimeout) {
        this.setDateRange();
        this.refreshTrackingMetrics();
        this.autoRefreshUsing(seconds);
      }
    }, seconds * 1000);
  }

  private stopRefresh() {
    if (this.currentRefreshTimeout) {
      clearTimeout(this.currentRefreshTimeout);
      this.currentRefreshTimeout = null;
    }
    this.currentAutoRefreshInSeconds = null;
  }

  private setLocalStorage() {
    localStorage.setItem('admin-dashboard-days-preference', JSON.stringify(<LocalStorageData>{
      timeValue: this.timeValue,
      measurement: this.measurement,
      timeFilter: this.timeFilter,
      range: this.dateTimeRange
    }));
  }

  private loadFromLocalStorage(): void {
    const item = localStorage.getItem('admin-dashboard-days-preference');
    if (item) {
      const preference: LocalStorageData = JSON.parse(item);
      this.timeValue = preference.timeValue;
      this.measurement = preference.measurement;
      this.timeFilter = preference.timeFilter;
      this.dateTimeRange = preference.range;
    }
  }

  getUpdateTotalCount = (chartData: ChartData, checkHiddenLabels: boolean = true): number => chartData
    ? lodash.sum(lodash.flatMap(chartData.datasets
      .filter(l => !checkHiddenLabels || !this.hiddenLabels?.includes(l.label))
      .map(d => d.data.map(r => r["y"]))))
    : null;

  getAllUpdateTotalCount = (chartData: { [key: string]: ChartData }, checkHiddenLabels: boolean = true): number =>
    chartData
      ? lodash.sum(Object.values(chartData).map(c => this.getUpdateTotalCount(c, checkHiddenLabels)))
      : null;

  get totalAmountOfUpdatesPerConsumerCount(): number {
    if (this.totalAmountOfUpdatesPerConsumer) {
      const viewedIndexes: number[] = this.totalAmountOfUpdatesPerConsumer.labels
        .filter(l => !this.hiddenLabels?.includes(l as string))
        .map(l => this.totalAmountOfUpdatesPerConsumer.labels.indexOf(l));
      return lodash.sum(lodash.flatMap(this.totalAmountOfUpdatesPerConsumer.datasets
        .map(d => d.data.filter((r, i) => viewedIndexes.includes(i)))));
    }
    return null;
  }

  private mergeWithCachedData<T>(result: DashboardMetricsResult<T>, cachedData: DashboardMetricsResult<T>, dateMapper: (value: T) => moment.Moment): DashboardMetricsResult<T> {
    result.data = cachedData.data.concat(result.data).filter(a => dateMapper(a).isBetween(this.dateTimeRange.start, this.dateTimeRange.end));
    result.lastMatch = result.lastMatch || cachedData.lastMatch;
    return result;
  }
}

interface LocalStorageData {
  timeValue: number;
  range: DateTimeRange;
  measurement: unitOfTime.Base;
  timeFilter: TimeDefinitions;
}

enum MeasurementTypes {
  minutes = 60,
  hours = 360,
  days = 86400
}

interface TrackerStatsRecord {
  user: string;
  timestamp: string;
  value: number;
}

interface DashboardMetricsResult<T> {
  data?: T[];
  lastMatch?: any;
}
