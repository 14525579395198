import {Component} from '@angular/core';
import {parseJson, sendCommand, sendQuery} from '../../common/utils';
import {AppContext} from '../../app-context';

@Component({
  selector: 'app-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.css']
})
export class AdminPanelComponent {
  queryResult: any;

  sendCommand(type: string, payload: string) {
    sendCommand(type, parseJson(payload), () => AppContext.registerSuccess("Command was sent successfully"));
  }

  sendQuery(type: string, payload: string) {
    sendQuery(type, parseJson(payload), {caching: false}).subscribe(value => {
      this.queryResult = value || "empty result";
    }, error => AppContext.registerError(error));
  }
}
