import {Component, ElementRef} from '@angular/core';
import {checkValidity, cloneObject, sendCommand, sendQuery} from '../../common/utils';
import {v4 as uuid} from 'uuid';
import {AppContext} from '../../app-context';

@Component({
  selector: 'app-refdata-update',
  templateUrl: './refdata-update.component.html',
  styleUrls: ['./refdata-update.component.css']
})
export class RefdataUpdateComponent {
  entry: any;
  searchResult: any;
  type: 'berth' | 'terminal' | 'port' | 'portofcall' | 'location' | 'portfacility' | 'vessel' | 'pilotexemption' |
    'dangerousgood' | 'wastematerial' | 'bunkerproduct' | 'bunkerproductsupplier' | 'bunkerlicense' |'dangerinformation' | 'carrier' | 'sizetype' | 'vesselservice' | 'terminalemail';
  search = term => {
    switch (this.type) {
      case 'berth': return sendQuery('com.portbase.bezoekschip.common.api.visit.FindBerthsAndBuoys', {term: term, untyped: true}, {caching: false});
      case 'terminal': return sendQuery('com.portbase.bezoekschip.common.api.visit.FindTerminals', {term: term, untyped: true}, {caching: false});
      case 'port': return sendQuery('com.portbase.bezoekschip.common.api.visit.FindPortsOrWayPoints', {term: term, untyped: true}, {caching: false});
      case 'portofcall': return sendQuery('com.portbase.bezoekschip.common.api.visit.FindPortsOfCall', {term: term, untyped: true}, {caching: false});
      case 'location': return sendQuery('com.portbase.bezoekschip.common.api.visit.FindLocations', {term: term, untyped: true}, {caching: false});
      case 'portfacility': return sendQuery('com.portbase.bezoekschip.common.api.visit.FindPortFacilities', {term: term, untyped: true}, {caching: false});
      case 'vessel': return sendQuery('com.portbase.bezoekschip.common.api.visit.FindVessels', {term: term, untyped: true}, {caching: false});
      case 'pilotexemption': return sendQuery('com.portbase.bezoekschip.common.api.visit.FindPilotExemptions', {term: term, untyped: true}, {caching: false});
      case 'dangerousgood': return sendQuery('com.portbase.bezoekschip.common.api.dangerousgoods.FindDangerousGoods', {term: term, untyped: true}, {caching: false});
      case 'wastematerial': return sendQuery('com.portbase.bezoekschip.common.api.visit.FindWasteMaterials', {term: term, untyped: true}, {caching: false});
      case 'bunkerproduct': return sendQuery('com.portbase.bezoekschip.common.api.refdata.GetBunkerProducts', {term: term}, {caching: false});
      case 'bunkerproductsupplier': return sendQuery('com.portbase.bezoekschip.common.api.refdata.GetBunkerProductSuppliers', {term: term}, {caching: false});
      case 'bunkerlicense': return sendQuery('com.portbase.bezoekschip.common.api.refdata.GetBunkerLicences', {term: term}, {caching: false});
      case 'dangerinformation': return sendQuery('com.portbase.bezoekschip.common.api.cargo.FindDangerInformation', {term: term, untyped: true}, {caching: false});
      case 'carrier': return sendQuery('com.portbase.bezoekschip.common.api.visit.FindCarriers', {term: term, untyped: true}, {caching: false});
      case 'sizetype': return sendQuery('com.portbase.bezoekschip.common.api.cargo.FindSizeTypes', {term: term, untyped: true}, {caching: false});
      case 'vesselservice': return sendQuery('com.portbase.bezoekschip.common.api.visit.FindVesselServices', {term: term, untyped: true}, {caching: false});
      case 'terminalemail': return sendQuery('com.portbase.bezoekschip.common.api.refdata.GetTerminalEmails', {term: term, untyped: true}, {caching: false});
    }
  };
  searchResultFormatter = (result: any) => {
    switch (this.type) {
      case 'berth': return result.name + ' – ' + result.code +
        (result.bollards ? ' (' + result.bollards.start + '-' + result.bollards.end	 + ')': '');
      case 'terminal': return result.terminalName + ' - ' + result.terminalCode +
        (result.organisationShortName ? ' - ' + result.organisationShortName : '');
      case 'port': return result.name + ' – ' + result.locationUnCode;
      case 'portofcall': return result.port.name + ' – ' + result.port.locationUnCode;
      case 'location': return result.name + ' – ' + result.locationUnCode;
      case 'portfacility': return result.name + ' – ' + result.code;
      case 'vessel': return result.name + ' – ' + result.imoCode;
      case 'pilotexemption': return result.holderName + ' – ' + result.number;
      case 'dangerousgood': return result.name + (result.unCode ? ' - ' + result.unCode : '') + ' (' +
        result.type + ')';
      case 'dangerinformation': return result.name + ' – ' + result.unCode;
      case 'wastematerial': return result.nameEN + ' - ' + result.typeCode;
      case 'bunkerproduct': return result.type;
      case 'bunkerproductsupplier': return result.name;
      case 'bunkerlicense': return result.organisationShortName +
        (result.bunkerVessel ? ' - ' + result.bunkerVessel.name : '');
      case 'carrier': return result.name + (result.customsId ? ' - ' + result.customsId : '');
      case 'sizetype': return result.code + ' - ' + result.name;
      case 'vesselservice': return result.code + ' - ' + result.name +
        (!result.stevedoreOrganisationShortName ? '' : ' [' + result.stevedoreOrganisationShortName + ']');
      case 'terminalemail': return result.terminalShortName;
    }
  };

  constructor(private element: ElementRef) {
  }

  newEntry = () => {
    if (this.searchResult) {
      this.entry = cloneObject(this.searchResult);
      this.entry.id = uuid();
    } else {
      this.entry = { };
    }
    this.entry.id = uuid();
    this.searchResult = undefined;
    if (this.type === 'berth') {
      this.entry.harbourDuesArea = true;
    }
  };

  newDgEntry = (type : string) => {
    this.newEntry();
    if (!this.searchResult) {
      this.entry.type = type
      this.entry.stowageType = getStowageType(type);
    }

    function getStowageType(type: string) {
      switch (type) {
        case 'containerGood': return 'container';
        case 'solid': return 'hold';
        case 'oil': return 'tank';
        case 'gas': return 'tank';
        case 'chemical': return 'tank';
      }
    }
  };

  addEntry = () => {
    if (checkValidity(this.element)) {
      sendCommand('com.portbase.bezoekschip.common.api.refdata.Add' + this.camelCasedType(), {entity : this.entry}, () => {
        AppContext.registerSuccess(this.camelCasedType() + ' was added successfully.');
        this.entry = undefined;
        this.searchResult = undefined;
      });
    }
  };

  updateEntry = () => {
    if (checkValidity(this.element)) {
      sendCommand('com.portbase.bezoekschip.common.api.refdata.Update' + this.camelCasedType(), {entity : this.entry}, () => {
        AppContext.registerSuccess(this.camelCasedType() + ' was updated successfully.');
        this.clear();
      });
    }
  };

  deleteEntry = () => {
    if (checkValidity(this.element)) {
      sendCommand('com.portbase.bezoekschip.common.api.refdata.Delete' + this.camelCasedType(), {entity : this.entry}, () => {
        AppContext.registerSuccess(this.camelCasedType() + ' was deleted successfully.');
        this.clear();
      });
    }
  };

  private camelCasedType = (): string => {
    switch (this.type) {
      case 'berth': return 'Berth';
      case 'terminal': return 'Terminal';
      case 'port': return 'Port';
      case 'portofcall': return 'PortOfCall';
      case 'location': return 'Location';
      case 'portfacility': return 'PortFacility';
      case 'vessel': return 'Vessel';
      case 'pilotexemption': return 'PilotExemption';
      case 'dangerousgood': return 'GoodData';
      case 'wastematerial': return 'WasteMaterial';
      case 'bunkerproduct': return 'BunkerProduct';
      case 'bunkerproductsupplier': return 'BunkerProductSupplier';
      case 'bunkerlicense': return 'BunkerLicense';
      case 'dangerinformation': return 'DangerInformation';
      case 'carrier': return 'Carrier';
      case 'sizetype': return 'SizeType';
      case 'vesselservice': return 'VesselService';
      case 'terminalemail': return 'TerminalEmail';
    }
  };

  private clear = () => {
    this.entry = undefined;
    this.searchResult = undefined;
  };

  formatOption = (entry) => {
    switch (entry) {
      case 'dangerousgood': return 'dangerousgood (MGS)';
      case 'dangerinformation': return 'dangerinformation (MLI)';
    }
    return entry;
  }
}
