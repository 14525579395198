<!-- Exit point -->
<app-form-group>
  <label for="exitPoint">Exit point</label>
  <app-select [ngModel]="exitPoint" (ngModelChange)="onExitPointChanged($event)" [optionsProvider]="exitPointProvider"
    [formatter]="exitPointFormatter" dataKey="name" required placeholder="– Select exit point –" id="exitPoint">
    <div class="invalid-feedback">Please select an exit point</div>
  </app-select>
</app-form-group>

<!-- ETD -->
<app-form-group>
  <label for="portEtd">ETD port</label>
  <app-date-field [ngModel]="etdPort" (ngModelChange)="onPortEtdChanged($event);" [calendarStartDate]="etaPort"
                 id="portEtd" [required]="!isWaitingForOrders()"></app-date-field>
</app-form-group>

<!-- Error messages -->
<span *ngIf="etdPortIsSameOrAfterNextPort()" class="ng-invalid invalid-feedback mb-2">First next port should be after etd port</span>
<span *ngIf="etdPortIsSameOrBeforeLastBerthWithVisitTime()" class="ng-invalid invalid-feedback mb-2">Etd port should be after last berth with a visit time</span>

<!-- Show ATD port -->
<div *ngIf="showAtdPort() && !isAdmin()">
<app-form-group>
    <label for="portAtd-card">ATD Port</label>
    <app-date-field [ngModel]="atdPort" [disabled]="atdPortReported" (ngModelChange)="onAtdPortChanged($event)"
      id="portAtd-card"></app-date-field>
  </app-form-group>
</div>
<span *ngIf="atdPortInFuture" class="ng-invalid invalid-feedback mb-2">Atd port in the future is not allowed!</span>

<!-- Admin -->
<app-register-atd-port *ngIf="isAdmin()"></app-register-atd-port>
