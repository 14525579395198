import {Component, Input} from '@angular/core';
import {MessageStatus} from "@portbase/bezoekschip-service-typescriptmodels";

@Component({
  selector: 'app-message-status-icon',
  templateUrl: './message-status-icon.component.html',
  styleUrls: ['./message-status-icon.component.scss']
})
export class MessageStatusIconComponent {
  @Input() messageStatus: MessageStatus;

  get backgroundColorClass(): string {
    switch (this.messageStatus) {
      case MessageStatus.UNKNOWN: return "bg-light";
      case MessageStatus.PENDING: return "bg-primary";
      case MessageStatus.WARNING: return "bg-warning";
      case MessageStatus.REJECTED: return "bg-danger";
      case MessageStatus.ACCEPTED: return "bg-success";
    }
    return "";
  }
}
