<app-date-field [(ngModel)]="berthVisit.atd"
                [id]="id + '-atd'" [disabled]="!isAtdModifiable()"
                (focus)="isAtdModifiable() ? withModal('show') : null"></app-date-field>
<div *ngIf="isAtdModifiable()" [id]="id + 'registerAtdModal'" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Register ATD</h5>
      </div>
      <div class="modal-body">
        <app-date-field [(ngModel)]="atd"
                        [required]="modalOpen"
                        [calendarStartDate]="berthVisit.etd"
                        [id]="id + 'register-atd'"></app-date-field>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                (click)="atd = berthVisit.atd; modalOpen = false">Cancel
        </button>
        <button type="button" class="btn btn-danger" (click)="removeAtd()">Remove ATD</button>
        <button type="button" class="btn btn-primary" (click)="registerAtd()">Register ATD</button>
      </div>
    </div>
  </div>
</div>
