import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {AbstractValueAccessorComponent} from '../../component/value-accessor.component';
import {lodash} from '../../utils';
import {debounce} from "lodash";

/**
 * Component used to filter (search in) a list of results
 */
@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FilterComponent), multi: true}
  ],
  host: {'class': 'input-group'}
})
export class FilterComponent extends AbstractValueAccessorComponent<string> implements OnInit {

  filterTerm: string;
  resetInProgress: boolean;

  @Input() searchFunction: (arg: string) => Observable<any[]> | any[];
  @Input() getAllFunction: (arg: void) => Observable<any[]> | any[];

  @Input() placeholder = '';
  @Input() disabled: boolean;
  @Input() minCharacters: number = 1;
  @Input() showTooltip = false;
  @Input() id;
  @Input() debounceTime: number = 0;

  @Output() resultsFound = new EventEmitter<any[]>();
  searching: boolean = false;

  searchDebounced: (oldValue: string) => any;

  ngOnInit() {
    this.searchDebounced = this.debounceTime ? debounce(this.search, this.debounceTime) : this.search;
    if (!this.searchFunction) {
      throw new Error('Attribute searchFunction is required for app-filter component');
    }
    if (!this.getAllFunction) {
      this.getAllFunction = () => this.searchFunction('');
    }
  }

  reset(): void {
    this.resetInProgress = true;
    this.onInput('');
  }

  get value(): string {
    return this.filterTerm;
  }

  writeValue(value: string): void {
    if (this.resetInProgress === false) {
      this.filterTerm = '';
    } else {
      this.filterTerm = value;
    }
  }

  onInput(value: string) {
    const oldValue = this.filterTerm;
    this.filterTerm = value || '';
    this.onModelChange();
    this.searchDebounced(oldValue);
  }

  search = (oldValue: string) => {
    if (!this.searching) {
      this.searching = true;
      this.resetInProgress = false;
      const lastTerm = this.filterTerm;
      let o = lastTerm && lastTerm.length + 2 >= this.minCharacters ? this.searchFunction(lastTerm)
        : oldValue && oldValue.length + 2 >= this.minCharacters ? this.getAllFunction() : null;
      if (o) {
        if (lodash.isArray(o)) {
          this.onResults(<any[]> o);
        } else {
          o.subscribe((results : any[]) => {
            this.onResults(results);
            if (lastTerm !== this.filterTerm) {
              this.searchDebounced(lastTerm);
            }
          })
        }
      } else {
        this.searching = false;
      }
    }
  };

  private onResults(results : any[]) {
    this.resultsFound.emit(results);
    this.searching = false;
  }
}
