import {cloneObject, uuid} from "../common/utils";
import {
  Terminal,
  TransitData,
  TransitDeclaration,
  TransitInspectionStatus,
  TransitPhase,
  TransitStatusUpdate
} from '@portbase/bezoekschip-service-typescriptmodels';
import moment, {now} from "moment";

export const simplifiedScheduled = {
  "@class": "com.fasterxml.jackson.databind.node.ObjectNode",
  "crn": "DANGEROUS_GOODS",
  "declarantShortName": "EIMSKIPBRR",
  "data": {
    "lrn": "dfg4gS4tRsDG33tgdgrdrfgGgdf",
    "containerNumber": "RVWI0000001",
    "goodsItems": [
      {
        "description": "SLAC 274 PC FISHING EQUIPMENT Ships stores in transhipment to final destination F/V Jonas at Mombasa Fisheries, Jetty, Liwatoni Fishing equipment",
        "previousDocument": {
          "type": {
            code: "N705",
            description: 'whatever'
          },
          "documentNumber": "EIMUSKO130VESW009",
          "sequenceNumber": 1
        },
        "packageType": {
          "code": "PK",
          "name": "Package",
          "bulk": false
        },
        "marksAndNumbers": "Package",
        "weight": 15000.0,
        "numberOfPackages": 274,
        "producedDocuments": []
      }
    ],
    "itinerary": [],
    "type": {
      "code": "T1",
      "description": "T1"
    },
    "countryOfExport": {
      "code": "IS",
      "name": "IS"
    },
    "departureOffice": {
      "name": "NL000510",
      "unCode": "NL000510"
    },
    "departureTransport": {
      "modeOfTransport": "ROAD_TRANSPORT",
      "nationality": {
        "code": "NL",
        "name": "NL"
      },
      "reference": "TRUCK"
    },
    "countryOfDestination": {
      "code": "KE",
      "name": "KE"
    },
    "destinationsOffice": {
      "name": "NL000510",
      "unCode": "NL000510"
    },
    "consignee": {
      "eoriNumber": "NL807407343",
      "name": "APM TERMINAL",
      "address": "COLORADOWEG  50",
      "zipCode": "3199 LA",
      "city": "ROTTERDAM",
      "countryCode": "NL"
    },
    "transitOffices": [],
    "guarantees": [
      {
        id: "testA",
        type: {
          code: '1',
          description: 'Iets met een 1 erin'
        },
        customsValue: {
          amount: '1234.55',
          currency: {
            unCode: 'EUR',
            description: 'Euris'
          }
        },
        country: {
          code: "NL",
          name: "Nederland"
        },
        guaranteeReferenceNumber: 'GRUESOME',
        accessCode: '1244'
      }
    ],
    "seals": [
      "939939"
    ],
    "customsSubPlace": "3195 KN",
    "placeOfLoading": "3195 KN 15",
    "authorizedLocation": "3195 KN 15",
    "declarationPlace": "Rotterdam",
    "representative": "Danny van der Walle Goncalves",
    "transportTerm": 777600.000000000
  },
  "status": {
    "sendDate": "2022-07-13T22:00:00Z",
    "cancelInitiatedByCustoms": false,
    "inspectionStatus": {
      "type": "A3",
      "inspectionExpiryDate": null
    },
    "simplifiedProcedure": true,
    "phase": 'declared'
  },
  "timestamp": "2021-08-17T11:21:44.948Z"
};
export const simplifiedOnAta = {
  "@class": "com.fasterxml.jackson.databind.node.ObjectNode",
  "declarantShortName": "EIMSKIPBRR",
  "data": {
    "lrn": "004971140.00/780035724",
    "containerNumber": "RVWI0000002",
    "dischargeTerminal": <Terminal>{
      "terminalName": "Holland Amerikakade",
      "terminalCode": "52090469"
    },
    "emailAddressMrnToTerminal": "bladieblah@blah.nl",
    "emailAddressesMrnToTerminalForCopy": ["typies@blah.nl"],
    "emailAddressContainerInTransit": "hot@hotmail.com",
    "emailAddressesContainerInTransitForCopy": ["bladieblah@blah.nl", "hot@hotmail.com"],
    "emailAddressesForExpiryReminder": ["emailAddressesForExpiryReminder@blah.nl", "emailAddressesForExpiryReminder@hotmail.com"],
    "expiryReminderNumberOfDaysBefore": 2,
    "goodsItems": [
      {
        "description": "SLAC 274 PC FISHING EQUIPMENT Ships stores in transhipment to final destination F/V Jonas at Mombasa Fisheries, Jetty, Liwatoni Fishing equipment",
        "previousDocument": {
          "type": {
            code: "N705",
            description: 'oather'
          },
          "documentNumber": "EIMUSKO130VESW009",
          "sequenceNumber": 1
        },
        "packageType": {
          "code": "PK",
          "name": "Package",
          "bulk": false
        },
        "marksAndNumbers": "Package",
        "weight": 15000.0,
        "numberOfPackages": 274,
        "producedDocuments": []
      }
    ],
    "itinerary": [],
    "type": {
      "code": "T1",
      "description": "T1"
    },
    "countryOfExport": {
      "code": "IS",
      "name": "IS"
    },
    "departureOffice": {
      "name": "NL000510",
      "unCode": "NL000510"
    },
    "departureTransport": {
      "modeOfTransport": "ROAD_TRANSPORT",
      "nationality": {
        "code": "NL",
        "name": "NL"
      },
      "reference": "TRUCK"
    },
    "countryOfDestination": {
      "code": "KE",
      "name": "KE"
    },
    "destinationsOffice": {
      "name": "NL000510",
      "unCode": "NL000510"
    },
    "consignee": {
      "eoriNumber": "NL807407343",
      "name": "APM TERMINAL",
      "address": "COLORADOWEG  50",
      "zipCode": "3199 LA",
      "city": "ROTTERDAM",
      "countryCode": "NL"
    },
    "transitOffices": [{
      "name": "NL000511",
      "unCode": "NL000511"
    }, {
      "name": "NL000512",
      "unCode": "NL000512"
    }, {
      "name": "NL000513",
      "unCode": "NL000513"
    }],
    "guarantees": [{
      id: "testA",
      type: {
        code: '1',
        description: 'Iets met een 1 erin'
      },
      customsValue: {
        amount: '123.45',
        currency: {
          unCode: 'EUR',
          description: 'Euris'
        }
      },
      country: {
        code: "NL",
        name: "Nederland"
      },
      guaranteeReferenceNumber: 'GRUESOME',
      accessCode: '1244'
    }],
    "seals": [
      "dichtjonghu"
    ],
    "customsSubPlace": "3195 KN",
    "placeOfLoading": "3195 KN 15",
    "authorizedLocation": "3195 KN 15",
    "declarationPlace": "Rotterdam",
    "representative": "Danny van der Walle Goncalves",
    "transportTerm": 777600.000000000
  },
  "status": {
    "sendOnAta": true,
    "sendDate": null,
    "cancelInitiatedByCustoms": false,
    "inspectionStatus": {
      "type": "A3",
      "inspectionExpiryDate": null
    },
    "simplifiedProcedure": true,
    "phase": 'declared'
  },
  "crn": "ON_ATA_TEST",
  "timestamp": "2021-08-17T11:21:44.948Z"
};
export const simplifiedDirectly = {
  "@class": "com.fasterxml.jackson.databind.node.ObjectNode",
  "declarantShortName": "EIMSKIPBRR",
  "data": {
    "lrn": "004971140.00/780035724",
    "containerNumber": "RVWI0000003",
    "dischargeTerminal": <Terminal>{
      "terminalName": "Holland Amerikakade",
      "terminalCode": "52090469"
    },
    "emailAddressMrnToTerminal": "bladieblah@blah.nl",
    "emailAddressesMrnToTerminalForCopy": ["typies@blah.nl"],
    "emailAddressContainerInTransit": "hot@hotmail.com",
    "emailAddressesContainerInTransitForCopy": ["bladieblah@blah.nl", "hot@hotmail.com"],
    "emailAddressesForExpiryReminder": ["emailAddressesForExpiryReminder@blah.nl", "emailAddressesForExpiryReminder@hotmail.com"],
    "expiryReminderNumberOfDaysBefore": 2,
    "goodsItems": [
      {
        "description": "SLAC 274 PC FISHING EQUIPMENT Ships stores in transhipment to final destination F/V Jonas at Mombasa Fisheries, Jetty, Liwatoni Fishing equipment",
        "previousDocument": {
          "type": {
            code: "N705",
            description: 'oather'
          },
          "documentNumber": "EIMUSKO130VESW009",
          "sequenceNumber": 1
        },
        "packageType": {
          "code": "PK",
          "name": "Package",
          "bulk": false
        },
        "marksAndNumbers": "Package",
        "weight": 15000.0,
        "numberOfPackages": 274,
        "producedDocuments": []
      }
    ],
    "itinerary": [],
    "type": {
      "code": "T1",
      "description": "T1"
    },
    "countryOfExport": {
      "code": "IS",
      "name": "IS"
    },
    "departureOffice": {
      "name": "NL000510",
      "unCode": "NL000510"
    },
    "departureTransport": {
      "modeOfTransport": "ROAD_TRANSPORT",
      "nationality": {
        "code": "NL",
        "name": "NL"
      },
      "reference": "TRUCK"
    },
    "countryOfDestination": {
      "code": "KE",
      "name": "KE"
    },
    "destinationsOffice": {
      "name": "NL000510",
      "unCode": "NL000510"
    },
    "consignee": {
      "eoriNumber": "NL807407343",
      "name": "APM TERMINAL",
      "address": "COLORADOWEG  50",
      "zipCode": "3199 LA",
      "city": "ROTTERDAM",
      "countryCode": "NL"
    },
    "transitOffices": [{
      "name": "NL000511",
      "unCode": "NL000511"
    }, {
      "name": "NL000512",
      "unCode": "NL000512"
    }, {
      "name": "NL000513",
      "unCode": "NL000513"
    }],
    "guarantees": [{
      id: "testA",
      type: {
        code: '1',
        description: 'Iets met een 1 erin'
      },
      customsValue: {
        amount: '123.45',
        currency: {
          unCode: 'EUR',
          description: 'Euris'
        }
      },
      country: {
        code: "NL",
        name: "Nederland"
      },
      guaranteeReferenceNumber: 'GRUESOME',
      accessCode: '1244'
    }],
    "seals": [
      "dichtjonghu"
    ],
    "customsSubPlace": "3195 KN",
    "placeOfLoading": "3195 KN 15",
    "authorizedLocation": "3195 KN 15",
    "declarationPlace": "Rotterdam",
    "representative": "Danny van der Walle Goncalves",
    "transportTerm": 777600.000000000
  },
  "status": {
    "sendOnAta": false,
    "sendDate": moment(now()).format("YYYY-MM-DD"),
    "cancelInitiatedByCustoms": false,
    "inspectionStatus": {
      "type": "A3",
      "inspectionExpiryDate": null
    },
    "simplifiedProcedure": true,
    "phase": 'declared'
  },
  "crn": "DIRECTTEST",
  "timestamp": "2021-08-17T11:21:44.948Z"
};
export const simplifiedDirectlySensitiveGoods = {
  "@class": "com.fasterxml.jackson.databind.node.ObjectNode",
  "declarantShortName": "EIMSKIPBRR",
  "data": {
    "lrn": "004971140.00/780035724",
    "containerNumber": "RVWI0000003-SENSITIVE",
    "dischargeTerminal": <Terminal>{
      "terminalName": "Holland Amerikakade",
      "terminalCode": "52090469"
    },
    "emailAddressMrnToTerminal": "bladieblah@blah.nl",
    "emailAddressesMrnToTerminalForCopy": ["typies@blah.nl"],
    "emailAddressContainerInTransit": "hot@hotmail.com",
    "emailAddressesContainerInTransitForCopy": ["bladieblah@blah.nl", "hot@hotmail.com"],
    "emailAddressesForExpiryReminder": ["emailAddressesForExpiryReminder@blah.nl", "emailAddressesForExpiryReminder@hotmail.com"],
    "expiryReminderNumberOfDaysBefore": 2,
    "goodsItems": [
      {
        "description": "SLAC 274 PC FISHING EQUIPMENT Ships stores in transhipment to final destination F/V Jonas at Mombasa Fisheries, Jetty, Liwatoni Fishing equipment",
        "previousDocument": {
          "type": {
            code: "N705",
            description: 'oather'
          },
          "documentNumber": "EIMUSKO130VESW009",
          "sequenceNumber": 1
        },
        "packageType": {
          "code": "PK",
          "name": "Package",
          "bulk": false
        },
        "classification": {
          "code": "0100",
          "description": "Live animals",
          "sensitiveGoods": true
        },
        "marksAndNumbers": "Package",
        "weight": 15000.0,
        "numberOfPackages": 274,
        "producedDocuments": []
      }
    ],
    "itinerary": [],
    "type": {
      "code": "T1",
      "description": "T1"
    },
    "countryOfExport": {
      "code": "IS",
      "name": "IS"
    },
    "departureOffice": {
      "name": "NL000510",
      "unCode": "NL000510"
    },
    "departureTransport": {
      "modeOfTransport": "ROAD_TRANSPORT",
      "nationality": {
        "code": "NL",
        "name": "NL"
      },
      "reference": "TRUCK"
    },
    "countryOfDestination": {
      "code": "KE",
      "name": "KE"
    },
    "destinationsOffice": {
      "name": "NL000510",
      "unCode": "NL000510"
    },
    "consignee": {
      "eoriNumber": "NL807407343",
      "name": "APM TERMINAL",
      "address": "COLORADOWEG  50",
      "zipCode": "3199 LA",
      "city": "ROTTERDAM",
      "countryCode": "NL"
    },
    "transitOffices": [{
      "name": "NL000511",
      "unCode": "NL000511"
    }, {
      "name": "NL000512",
      "unCode": "NL000512"
    }, {
      "name": "NL000513",
      "unCode": "NL000513"
    }],
    "guarantees": [{
      id: "testA",
      type: {
        code: '1',
        description: 'Iets met een 1 erin'
      },
      customsValue: {
        amount: '123.45',
        currency: {
          unCode: 'EUR',
          description: 'Euris'
        }
      },
      country: {
        code: "NL",
        name: "Nederland"
      },
      guaranteeReferenceNumber: 'GRUESOME',
      accessCode: '1244'
    }],
    "seals": [
      "dichtjonghu"
    ],
    "customsSubPlace": "3195 KN",
    "placeOfLoading": "3195 KN 15",
    "authorizedLocation": "3195 KN 15",
    "declarationPlace": "Rotterdam",
    "representative": "Danny van der Walle Goncalves",
    "transportTerm": 777600.000000000
  },
  "status": {
    "sendOnAta": false,
    "sendDate": moment(now()).format("YYYY-MM-DD"),
    "cancelInitiatedByCustoms": false,
    "inspectionStatus": {
      "type": "A3",
      "inspectionExpiryDate": null
    },
    "simplifiedProcedure": true,
    "phase": 'declared'
  },
  "crn": "DIRECTTEST",
  "timestamp": "2021-08-17T11:21:44.948Z"
};

export const transitWithCrn =
  {
    "@class": "com.fasterxml.jackson.databind.node.ObjectNode",
    "crn": "DANGEROUS_GOODS",
    "declarantShortName": "EIMSKIPBRR",
    "data": {
      "lrn": "dfg4gS4tRsDG33tgdgrdrfgGgdf",
      "containerNumber": "TEST0608439",
      "goodsItems": [
        {
          "description": "SLAC 274 PC FISHING EQUIPMENT Ships stores in transhipment to final destination F/V Jonas at Mombasa Fisheries, Jetty, Liwatoni Fishing equipment",
          "previousDocument": {
            "type": {
              code: "N705",
              description: 'whatever'
            },
            "documentNumber": "EIMUSKO130VESW009",
            "sequenceNumber": 1
          },
          "packageType": {
            "code": "PK",
            "name": "Package",
            "bulk": false
          },
          "marksAndNumbers": "Package",
          "weight": 15000.0,
          "numberOfPackages": 274,
          "producedDocuments": []
        }
      ],
      "itinerary": [],
      "type": {
        "code": "T1",
        "description": "T1"
      },
      "countryOfExport": {
        "code": "IS",
        "name": "IS"
      },
      "departureOffice": {
        "name": "NL000510",
        "unCode": "NL000510"
      },
      "departureTransport": {
        "modeOfTransport": "ROAD_TRANSPORT",
        "nationality": {
          "code": "NL",
          "name": "NL"
        },
        "reference": "TRUCK"
      },
      "countryOfDestination": {
        "code": "KE",
        "name": "KE"
      },
      "destinationsOffice": {
        "name": "NL000510",
        "unCode": "NL000510"
      },
      "consignee": {
        "eoriNumber": "NL807407343",
        "name": "APM TERMINAL",
        "address": "COLORADOWEG  50",
        "zipCode": "3199 LA",
        "city": "ROTTERDAM",
        "countryCode": "NL"
      },
      "transitOffices": [],
      "guarantees": [
        {
          id: "testA",
          type: {
            code: '1',
            description: 'Iets met een 1 erin'
          },
          customsValue: {
            amount: '1234.55',
            currency: {
              unCode: 'EUR',
              description: 'Euris'
            }
          },
          country: {
            code: "NL",
            name: "Nederland"
          },
          guaranteeReferenceNumber: 'GRUESOME',
          accessCode: '1244'
        }
      ],
      "seals": [
        "939939"
      ],
      "customsSubPlace": "3195 KN",
      "placeOfLoading": "3195 KN 15",
      "authorizedLocation": "3195 KN 15",
      "declarationPlace": "Rotterdam",
      "representative": "Danny van der Walle Goncalves",
      "transportTerm": 777600.000000000
    },
    "status": {
      "sendDate": "2021-08-17",
      "cancelInitiatedByCustoms": false,
      "inspectionStatus": {
        "type": "A3",
        "inspectionExpiryDate": null
      },
      "simplifiedProcedure": true,
      "phase": 'declared'
    },
    "timestamp": "2021-08-17T11:21:44.948Z"
  };
export const transitWithoutCrn = {
  "@class": "com.fasterxml.jackson.databind.node.ObjectNode",
  "declarantShortName": "EIMSKIPBRR",
  "data": {
    "lrn": "004971140.00/780035724",
    "containerNumber": "MSKU0608439",
    "dischargeTerminal": <Terminal>{
      "terminalName": "Holland Amerikakade",
      "terminalCode": "52090469"
    },
    "emailAddressMrnToTerminal": "bladieblah@blah.nl",
    "emailAddressesMrnToTerminalForCopy": ["typies@blah.nl"],
    "emailAddressContainerInTransit": "hot@hotmail.com",
    "emailAddressesContainerInTransitForCopy": ["bladieblah@blah.nl", "hot@hotmail.com"],
    "emailAddressesForExpiryReminder": ["emailAddressesForExpiryReminder@blah.nl", "emailAddressesForExpiryReminder@hotmail.com"],
    "expiryReminderNumberOfDaysBefore": 2,
    "goodsItems": [
      {
        "description": "SLAC 274 PC FISHING EQUIPMENT Ships stores in transhipment to final destination F/V Jonas at Mombasa Fisheries, Jetty, Liwatoni Fishing equipment",
        "previousDocument": {
          "type": {
            code: "N705",
            description: 'oather'
          },
          "documentNumber": "EIMUSKO130VESW009",
          "sequenceNumber": 1
        },
        "packageType": {
          "code": "PK",
          "name": "Package",
          "bulk": false
        },
        "marksAndNumbers": "Package",
        "weight": 15000.0,
        "numberOfPackages": 274,
        "producedDocuments": []
      }
    ],
    "itinerary": [],
    "type": {
      "code": "T1",
      "description": "T1"
    },
    "countryOfExport": {
      "code": "IS",
      "name": "IS"
    },
    "departureOffice": {
      "name": "NL000510",
      "unCode": "NL000510"
    },
    "departureTransport": {
      "modeOfTransport": "ROAD_TRANSPORT",
      "nationality": {
        "code": "NL",
        "name": "NL"
      },
      "reference": "TRUCK"
    },
    "countryOfDestination": {
      "code": "KE",
      "name": "KE"
    },
    "destinationsOffice": {
      "name": "NL000510",
      "unCode": "NL000510"
    },
    "consignee": {
      "eoriNumber": "NL807407343",
      "name": "APM TERMINAL",
      "address": "COLORADOWEG  50",
      "zipCode": "3199 LA",
      "city": "ROTTERDAM",
      "countryCode": "NL"
    },
    "transitOffices": [{
      "name": "NL000511",
      "unCode": "NL000511"
    }, {
      "name": "NL000512",
      "unCode": "NL000512"
    }, {
      "name": "NL000513",
      "unCode": "NL000513"
    }],
    "guarantees": [{
      id: "testA",
      type: {
        code: '1',
        description: 'Iets met een 1 erin'
      },
      customsValue: {
        amount: '123.45',
        currency: {
          unCode: 'EUR',
          description: 'Euris'
        }
      },
      country: {
        code: "NL",
        name: "Nederland"
      },
      guaranteeReferenceNumber: 'GRUESOME',
      accessCode: '1244'
    }],
    "seals": [
      "dichtjonghu"
    ],
    "customsSubPlace": "3195 KN",
    "placeOfLoading": "3195 KN 15",
    "authorizedLocation": "3195 KN 15",
    "declarationPlace": "Rotterdam",
    "representative": "Danny van der Walle Goncalves",
    "transportTerm": 777600.000000000,
    "carrierRemarks": "Dit is een transit aangifte die een enorm lange tekst bevat. Het zou nog steeds goed in de tooltip moeten tonen in het overview scherm"
  },
  "status": {
    "sendDate": "2021-08-17",
    "cancelInitiatedByCustoms": false,
    "inspectionStatus": {
      "type": "A3",
      "inspectionExpiryDate": null
    },
    "simplifiedProcedure": true,
    "phase": 'declared'
  },
  "crn": null,
  "timestamp": "2021-08-17T11:21:44.948Z"
};

export const transitDepartureTransportModeNotSpecified = {
  "@class": "com.fasterxml.jackson.databind.node.ObjectNode",
  "declarantShortName": "EIMSKIPBRR",
  "data": {
    "lrn": "004971140.00/780035724",
    "containerNumber": "MSKU0608449",
    "dischargeTerminal": <Terminal>{
      "terminalName": "Holland Amerikakade",
      "terminalCode": "52090469"
    },
    "emailAddressMrnToTerminal": "bladieblah@blah.nl",
    "emailAddressesMrnToTerminalForCopy": ["typies@blah.nl"],
    "emailAddressContainerInTransit": "hot@hotmail.com",
    "emailAddressesContainerInTransitForCopy": ["bladieblah@blah.nl", "hot@hotmail.com"],
    "emailAddressesForExpiryReminder": ["emailAddressesForExpiryReminder@blah.nl", "emailAddressesForExpiryReminder@hotmail.com"],
    "expiryReminderNumberOfDaysBefore": 2,
    "goodsItems": [
      {
        "description": "SLAC 274 PC FISHING EQUIPMENT Ships stores in transhipment to final destination F/V Jonas at Mombasa Fisheries, Jetty, Liwatoni Fishing equipment",
        "previousDocument": {
          "type": {
            code: "N705",
            description: 'oather'
          },
          "documentNumber": "EIMUSKO130VESW009",
          "sequenceNumber": 1
        },
        "packageType": {
          "code": "PK",
          "name": "Package",
          "bulk": false
        },
        "marksAndNumbers": "Package",
        "weight": 15000.0,
        "numberOfPackages": 274,
        "producedDocuments": []
      }
    ],
    "itinerary": [],
    "type": {
      "code": "T1",
      "description": "T1"
    },
    "countryOfExport": {
      "code": "IS",
      "name": "IS"
    },
    "departureOffice": {
      "name": "NL000510",
      "unCode": "NL000510"
    },
    "departureTransport": {
      "modeOfTransport": "NOT_SPECIFIED",
      "nationality": {
        "code": "NL",
        "name": "NL"
      },
      "reference": "TRUCK"
    },
    "countryOfDestination": {
      "code": "KE",
      "name": "KE"
    },
    "destinationsOffice": {
      "name": "NL000510",
      "unCode": "NL000510"
    },
    "consignee": {
      "eoriNumber": "NL807407343",
      "name": "APM TERMINAL",
      "address": "COLORADOWEG  50",
      "zipCode": "3199 LA",
      "city": "ROTTERDAM",
      "countryCode": "NL"
    },
    "transitOffices": [{
      "name": "NL000511",
      "unCode": "NL000511"
    }, {
      "name": "NL000512",
      "unCode": "NL000512"
    }, {
      "name": "NL000513",
      "unCode": "NL000513"
    }],
    "guarantees": [{
      id: "testA",
      type: {
        code: '1',
        description: 'Iets met een 1 erin'
      },
      customsValue: {
        amount: '123.45',
        currency: {
          unCode: 'EUR',
          description: 'Euris'
        }
      },
      country: {
        code: "NL",
        name: "Nederland"
      },
      guaranteeReferenceNumber: 'GRUESOME',
      accessCode: '1244'
    }],
    "seals": [
      "dichtjonghu"
    ],
    "customsSubPlace": "3195 KN",
    "placeOfLoading": "3195 KN 15",
    "authorizedLocation": "3195 KN 15",
    "declarationPlace": "Rotterdam",
    "representative": "Danny van der Walle Goncalves",
    "transportTerm": 777600.000000000
  },
  "status": {
    "sendDate": "2021-08-17",
    "cancelInitiatedByCustoms": false,
    "inspectionStatus": {
      "type": "A3",
      "inspectionExpiryDate": null
    },
    "simplifiedProcedure": true,
    "phase": 'declared'
  },
  "crn": null,
  "timestamp": "2021-08-17T11:21:44.948Z"
};

export const transitCancelledAcceptedFound = {
  "@class": "com.fasterxml.jackson.databind.node.ObjectNode",
  "declarantShortName": "EIMSKIPBRR",
  "data": {
    "lrn": "004971140.00/780035724",
    "containerNumber": "MSKU0608450",
    "dischargeTerminal": <Terminal>{
      "terminalName": "Holland Amerikakade",
      "terminalCode": "52090469"
    },
    "emailAddressMrnToTerminal": "bladieblah@blah.nl",
    "emailAddressesMrnToTerminalForCopy": ["typies@blah.nl"],
    "emailAddressContainerInTransit": "hot@hotmail.com",
    "emailAddressesContainerInTransitForCopy": ["bladieblah@blah.nl", "hot@hotmail.com"],
    "emailAddressesForExpiryReminder": ["emailAddressesForExpiryReminder@blah.nl", "emailAddressesForExpiryReminder@hotmail.com"],
    "expiryReminderNumberOfDaysBefore": 2,
    "goodsItems": [
      {
        "description": "SLAC 274 PC FISHING EQUIPMENT Ships stores in transhipment to final destination F/V Jonas at Mombasa Fisheries, Jetty, Liwatoni Fishing equipment",
        "previousDocument": {
          "type": {
            code: "N705",
            description: 'oather'
          },
          "documentNumber": "EIMUSKO130VESW009",
          "sequenceNumber": 1
        },
        "packageType": {
          "code": "PK",
          "name": "Package",
          "bulk": false
        },
        "marksAndNumbers": "Package",
        "weight": 15000.0,
        "numberOfPackages": 274,
        "producedDocuments": []
      }
    ],
    "itinerary": [],
    "type": {
      "code": "T1",
      "description": "T1"
    },
    "countryOfExport": {
      "code": "IS",
      "name": "IS"
    },
    "departureOffice": {
      "name": "NL000510",
      "unCode": "NL000510"
    },
    "departureTransport": {
      "modeOfTransport": "NOT_SPECIFIED",
      "nationality": {
        "code": "NL",
        "name": "NL"
      },
      "reference": "TRUCK"
    },
    "countryOfDestination": {
      "code": "KE",
      "name": "KE"
    },
    "destinationsOffice": {
      "name": "NL000510",
      "unCode": "NL000510"
    },
    "consignee": {
      "eoriNumber": "NL807407343",
      "name": "APM TERMINAL",
      "address": "COLORADOWEG  50",
      "zipCode": "3199 LA",
      "city": "ROTTERDAM",
      "countryCode": "NL"
    },
    "transitOffices": [{
      "name": "NL000511",
      "unCode": "NL000511"
    }, {
      "name": "NL000512",
      "unCode": "NL000512"
    }, {
      "name": "NL000513",
      "unCode": "NL000513"
    }],
    "guarantees": [{
      id: "testA",
      type: {
        code: '1',
        description: 'Iets met een 1 erin'
      },
      customsValue: {
        amount: '123.45',
        currency: {
          unCode: 'EUR',
          description: 'Euris'
        }
      },
      country: {
        code: "NL",
        name: "Nederland"
      },
      guaranteeReferenceNumber: 'GRUESOME',
      accessCode: '1244'
    }],
    "seals": [
      "dichtjonghu"
    ],
    "customsSubPlace": "3195 KN",
    "placeOfLoading": "3195 KN 15",
    "authorizedLocation": "3195 KN 15",
    "declarationPlace": "Rotterdam",
    "representative": "Danny van der Walle Goncalves",
    "transportTerm": 777600.000000000
  },
  "status": {
    "sendDate": "2021-08-17",
    "cancelInitiatedByCustoms": false,
    "inspectionStatus": {
      "type": "A3",
      "inspectionExpiryDate": null
    },
    "simplifiedProcedure": true,
    "phase": 'declared'
  },
  "crn": null,
  "timestamp": "2021-08-17T11:21:44.948Z"
};

export const transitSendOnAta = {
  "@class": "com.fasterxml.jackson.databind.node.ObjectNode",
  "declarantShortName": "EIMSKIPBRR",
  "data": {
    "lrn": "004971140.00/780035724",
    "containerNumber": "MSKU0608439-OnATA",
    "dischargeTerminal": <Terminal>{
      "terminalName": "Holland Amerikakade",
      "terminalCode": "52090469"
    },
    "emailAddressMrnToTerminal": "bladieblah@blah.nl",
    "emailAddressesMrnToTerminalForCopy": ["typies@blah.nl"],
    "emailAddressContainerInTransit": "hot@hotmail.com",
    "emailAddressesContainerInTransitForCopy": ["bladieblah@blah.nl", "hot@hotmail.com"],
    "emailAddressesForExpiryReminder": ["emailAddressesForExpiryReminder@blah.nl", "emailAddressesForExpiryReminder@hotmail.com"],
    "expiryReminderNumberOfDaysBefore": 2,
    "goodsItems": [
      {
        "description": "SLAC 274 PC FISHING EQUIPMENT Ships stores in transhipment to final destination F/V Jonas at Mombasa Fisheries, Jetty, Liwatoni Fishing equipment",
        "previousDocument": {
          "type": {
            code: "N705",
            description: 'oather'
          },
          "documentNumber": "EIMUSKO130VESW009",
          "sequenceNumber": 1
        },
        "packageType": {
          "code": "PK",
          "name": "Package",
          "bulk": false
        },
        "marksAndNumbers": "Package",
        "weight": 15000.0,
        "numberOfPackages": 274,
        "producedDocuments": []
      }
    ],
    "itinerary": [],
    "type": {
      "code": "T1",
      "description": "T1"
    },
    "countryOfExport": {
      "code": "IS",
      "name": "IS"
    },
    "departureOffice": {
      "name": "NL000510",
      "unCode": "NL000510"
    },
    "departureTransport": {
      "modeOfTransport": "ROAD_TRANSPORT",
      "nationality": {
        "code": "NL",
        "name": "NL"
      },
      "reference": "TRUCK"
    },
    "countryOfDestination": {
      "code": "KE",
      "name": "KE"
    },
    "destinationsOffice": {
      "name": "NL000510",
      "unCode": "NL000510"
    },
    "consignee": {
      "eoriNumber": "NL807407343",
      "name": "APM TERMINAL",
      "address": "COLORADOWEG  50",
      "zipCode": "3199 LA",
      "city": "ROTTERDAM",
      "countryCode": "NL"
    },
    "transitOffices": [{
      "name": "NL000511",
      "unCode": "NL000511"
    }, {
      "name": "NL000512",
      "unCode": "NL000512"
    }, {
      "name": "NL000513",
      "unCode": "NL000513"
    }],
    "guarantees": [{
      id: "testA",
      type: {
        code: '1',
        description: 'Iets met een 1 erin'
      },
      customsValue: {
        amount: '123.45',
        currency: {
          unCode: 'EUR',
          description: 'Euris'
        }
      },
      country: {
        code: "NL",
        name: "Nederland"
      },
      guaranteeReferenceNumber: 'GRUESOME',
      accessCode: '1244'
    }],
    "seals": [
      "dichtjonghu"
    ],
    "customsSubPlace": "3195 KN",
    "placeOfLoading": "3195 KN 15",
    "authorizedLocation": "3195 KN 15",
    "declarationPlace": "Rotterdam",
    "representative": "Danny van der Walle Goncalves",
    "transportTerm": 777600.000000000
  },
  "status": {
    "sendOnAta": true,
    "sendDate": null,
    "cancelInitiatedByCustoms": false,
    "inspectionStatus": {
      "type": "A3",
      "inspectionExpiryDate": null
    },
    "simplifiedProcedure": true,
    "phase": 'declared'
  },
  "crn": "ON_ATA_TEST",
  "timestamp": "2021-08-17T11:21:44.948Z"
};

const matchingReleaseData = <TransitData>{
  "containerNumber": "MSKU0608439",
  "dischargeTerminal": <Terminal>{
    "terminalName": "Holland Amerikakade",
    "terminalCode": "52090469"
  },
  "goodsItems": [
    {
      "description": "SLAC 274 PC FISHING EQUIPMENT SHIPS STORES IN TRANSSHIPMENT TO FINAL DESTINATION F/V JONAS AT MOMBASA FISHERIES, JETTY, LIWATONI FISHING EQUIPMENT",
      "previousDocument": {
        "type": {
          "code": "N705"
        },
        "documentNumber": "EIMUSKO130VESW009"
      },
      "packageType": {
        "code": "PK",
        "name": "Package",
        "bulk": false
      },
      "marksAndNumbers": "PACKAGE",
      "weight": 15000,
      "numberOfPackages": 274
    }
  ],
  "itinerary": [],
  "type": {
    "code": "T1"
  },
  "countryOfExport": {
    "code": "IS"
  },
  "departureOffice": {
    "unCode": "NL000510"
  },
  "departureTransport": {
    "modeOfTransport": "ROAD_TRANSPORT",
    "nationality": {
      "code": "NL"
    },
    "reference": "TRUCK"
  },
  "countryOfDestination": {
    "code": "KE"
  },
  "destinationsOffice": {
    "unCode": "NL000510"
  },
  "consignee": {
    "tinNumber": "NL807407343",
    "name": "APM TERMINAL",
    "address": "COLORADOWEG 50",
    "zipCode": "3199 LA",
    "city": "ROTTERDAM",
    "countryCode": "NL"
  },
  "transitOffices": [],
  "guarantees": [
    {
      "type": {
        "code": "0"
      },
      "accessCode": "6231",
      "guaranteeReferenceNumber": "12NL5663790002NM3"
    }
  ],
  "seals": [
    "939939"
  ],
  "placeOfLoading": "3195 KN 15",
  "declarationPlace": "ROTTERDAM",
  "representative": "DANNY VAN DER WALLE GONCALVES"
};

const misMatchingReleaseData = <TransitData>{
  "containerNumber": "BEAU4085723",
  "goodsItems": [
    {
      "description": "CIGARETTES CAPITAL CIGARETTES",
      "previousDocument": {
        "type": {
          "code": "Z-ZZZ"
        },
        "documentNumber": "CMDUDXB0603652",
      },
      "packageType": {
        "code": "CS",
        "name": "Case",
        "bulk": false
      },
      "marksAndNumbers": "CASE",
      "weight": 16489,
      "numberOfPackages": 1057,
      "producedDocuments": []
    }
  ],
  "itinerary": [],
  "type": {
    "code": "T2"
  },
  "countryOfExport": {
    "code": "AE"
  },
  "departureOffice": {
    "unCode": "NL000511"
  },
  "departureTransport": {
    "modeOfTransport": "INLAND_WATER_TRANSPORT",
    "nationality": {
      "code": "DE"
    },
    "reference": "BARGE"
  },
  "countryOfDestination": {
    "code": "PA"
  },
  "destinationsOffice": {
    "unCode": "NL000511"
  },
  "consignee": {
    "name": "ECT DELTA TERMINAL",
    "address": "EUROPAWEG 875",
    "zipCode": "3199LD",
    "city": "ROTTERDAM",
    "countryCode": "NL"
  },
  "transitOffices": [{
    "unCode": "NL000510"
  }],
  "guarantees": [
    {
      "type": {
        "code": "6"
      }
    }
  ],
  "seals": ["939939"
  ],
  "customsSubPlace": "3199 KD",
  "placeOfLoading": "3199 KD 50",
  "authorizedLocation": "3199 KD 50",
  "declarationPlace": "AMSTERDAM",
  "representative": "WILLEM T HART"
};

export const invalidGuarantee = {
  "@class": "com.fasterxml.jackson.databind.node.ObjectNode",
  "declarantShortName": "EIMSKIPBRR",
  "data": {
    "containerNumber": "BEAU4085723",
    "goodsItems": [
      {
        "description": "CIGARETTES CAPITAL CIGARETTES",
        "previousDocument": {
          "type": {
            "code": "Z-ZZZ"
          },
          "documentNumber": "CMDUDXB0603652",
        },
        "packageType": {
          "code": "CS",
          "name": "Case",
          "bulk": false
        },
        "marksAndNumbers": "CASE",
        "weight": 16489,
        "numberOfPackages": 1057,
        "producedDocuments": []
      }
    ],
    "itinerary": [],
    "type": {
      "code": "T2"
    },
    "countryOfExport": {
      "code": "AE"
    },
    "departureOffice": {
      "unCode": "NL000511"
    },
    "departureTransport": {
      "modeOfTransport": "INLAND_WATER_TRANSPORT",
      "nationality": {
        "code": "DE"
      },
      "reference": "BARGE"
    },
    "countryOfDestination": {
      "code": "PA"
    },
    "destinationsOffice": {
      "unCode": "NL000511"
    },
    "consignee": {
      "name": "ECT DELTA TERMINAL",
      "address": "EUROPAWEG 875",
      "zipCode": "3199LD",
      "city": "ROTTERDAM",
      "countryCode": "NL"
    },
    "transitOffices": [{
      "unCode": "NL000510"
    }],
    "guarantees": [
      {
        "id": "51272758-d647-47ab-84a2-410395f9621f",
        "type": {
          "code": "6"
        },
        "otherReference": "CODE 6",
        "country": {
          "code": "NL",
          "name": "Netherlands"
        }
      }
    ],
    "seals": ["939939"
    ],
    "customsSubPlace": "3199 KD",
    "placeOfLoading": "3199 KD 50",
    "authorizedLocation": "3199 KD 50",
    "declarationPlace": "AMSTERDAM",
    "representative": "WILLEM T HART"
  },
  "status": {
    "sendOnAta": true,
    "sendDate": null,
    "cancelInitiatedByCustoms": false,
    "inspectionStatus": {
      "type": "A3",
      "inspectionExpiryDate": null
    },
    "simplifiedProcedure": true,
    "phase": 'declared'
  },
  "crn": "INVALID_GUARANTEE",
  "timestamp": "2021-08-17T11:21:44.948Z"
};


function saved(input: TransitDeclaration) {
  let object = cloneDeclaration(input)
  object.status.phase = TransitPhase.undeclared;
  return object;
}

function send(input: TransitDeclaration) {
  let object = cloneDeclaration(input)
  object.status.sent = <TransitStatusUpdate>{processId: "1", date: "2021-08-24"};
  object.status.phase = TransitPhase.sent;
  return object;
}

function accept(input: TransitDeclaration) {
  let object = cloneDeclaration(send(input))
  object.status.accept = <TransitStatusUpdate>{processId: "1", date: "2021-08-24"};
  object.status.mrn = "11NL5665111001F4C1"
  object.status.phase = TransitPhase.accepted;
  return object;
}

function reject(input: TransitDeclaration) {
  let object = cloneDeclaration(send(input))
  object.status.reject = <TransitStatusUpdate>{
    processId: "1",
    date: "2021-08-24",
    reason: "We rejected your transit ha!"
  };
  object.status.phase = TransitPhase.rejected;
  return object;
}

function release(input: TransitDeclaration) {
  let object = cloneDeclaration(accept(input));
  object.status.release = <TransitStatusUpdate>{processId: "1", date: "2021-08-24"};
  object.status.phase = TransitPhase.inTransit;
  object.transportExpiryDate = "2022-02-18";
  object.dataFromCustoms = matchingReleaseData;
  object.status.inspectionStatus = <TransitInspectionStatus>{
    "type": "A3",
    "inspectionDate": "2021-09-27T12:00:00Z",
    "inspectionExpiryDate": "2022-02-18",
    "inspectorName": "NOT CONTROLLED",
    "bindingItinerary": true,
    "authorisationNumber": "NL00740014170"
  }
  return object;
}

function releaseMismatching(input: TransitDeclaration) {
  let object = cloneDeclaration(accept(input));
  object.status.release = <TransitStatusUpdate>{processId: "1", date: "2021-08-24"};
  object.status.phase = TransitPhase.inTransit;
  object.dataFromCustoms = misMatchingReleaseData;
  return object;
}

function arrive(input: TransitDeclaration) {
  let object = cloneDeclaration(release(input));
  object.status.arrival = <TransitStatusUpdate>{processId: "1", date: "2021-08-24"};
  object.status.phase = TransitPhase.arrived;
  object.status.actualDestinationCode = 'BE424000';
  return object;
}

function writeoff(input: TransitDeclaration) {
  let object = cloneDeclaration(arrive(input));
  object.status.writeOff = <TransitStatusUpdate>{processId: "1", date: "2021-08-24"};
  object.status.phase = TransitPhase.writtenOff;
  object.status.clearanceCode = '0';
  return object;
}

function cancelled(input: TransitDeclaration) {
  let object = cancelrequested(input);
  object.status.cancellation = <TransitStatusUpdate>{
    processId: "1",
    date: "2021-08-24",
    reason: "We cancelled your transit ha!"
  };
  object.status.phase = TransitPhase.cancelled;
  return object;
}

function cancelledByCustoms(input: TransitDeclaration) {
  let object = cancelled(input);
  object.status.cancelInitiatedByCustoms = true;
  return object;
}

function notreleased(input: TransitDeclaration) {
  let object = cloneDeclaration(accept(input));
  object.status.releaseRejected = <TransitStatusUpdate>{
    processId: "1", date: "2021-08-24",
    reason: "We rejected the release of your transit ha!"
  };
  object.status.phase = TransitPhase.releaseRejected;
  return object;
}

function releaserequested(input: TransitDeclaration) {
  let object = cloneDeclaration(accept(input));
  object.status.releaseRequest = <TransitStatusUpdate>{processId: "1", date: "2021-08-24"};
  object.status.phase = TransitPhase.releaseRequested;
  return object;
}

function inspection(input: TransitDeclaration) {
  let object = cloneDeclaration(accept(input));
  object.data.containerNumber = object.data.containerNumber + "_A";
  object.status.inspectionStatus.inspectionDate = "2021-08-24";
  object.status.phase = TransitPhase.inspection;
  return object;
}

function insufficientguarantee(input: TransitDeclaration) {
  let object = cloneDeclaration(accept(input));
  object.status.insufficientGuarantee = <TransitStatusUpdate>{
    processId: "1", date: "2021-08-24",
    reason: "Guarantee 12NL5663790002NM3-AA: not enough. Guarantee 12NL5663790002NM3-AB: not enough too"
  };
  object.status.phase = TransitPhase.insufficientGuarantee;
  return object;
}

function cancelrequested(input: TransitDeclaration) {
  let object = cloneDeclaration(accept(input));
  object.status.cancellationRequest = <TransitStatusUpdate>{processId: "1", date: "2021-08-24"};
  object.status.phase = TransitPhase.cancellationRequested;
  return object;
}

function inforequested(input: TransitDeclaration) {
  let object = cloneDeclaration(release(input));
  object.status.infoRequest = <TransitStatusUpdate>{processId: "1", date: "2021-08-24"};
  object.status.phase = TransitPhase.proofOfArrivalRequested;
  object.status.investigatingOffice = 'ABC';
  object.status.infoRequestExpirationDate = '2021-09-27T12:00:00Z';
  return object;
}

function infodeclared(input: TransitDeclaration) {
  let object = cloneDeclaration(inforequested(input));
  object.status.infoDeclared = <TransitStatusUpdate>{processId: "1", date: "2021-08-24"};
  object.status.phase = TransitPhase.proofOfArrivalSent;
  return object;
}

function mrnDeclared(input: TransitDeclaration | any) {
  let object = cloneDeclaration(input)
  object.status.mrnDeclaration = <TransitStatusUpdate>{processId: "1", date: "2021-08-24"};
  return object;
}

function mrnSent(input: TransitDeclaration | any) {
  let object = cloneDeclaration(input)
  object.status.mrnSent = <TransitStatusUpdate>{processId: "1", date: "2021-08-25"};
  return object;
}

function mrnAccepted(input: TransitDeclaration | any) {
  let object = cloneDeclaration(input)
  object.status.mrnAccept = <TransitStatusUpdate>{processId: "1", date: "2021-08-26"};
  return object;
}

function mrnRejected(input: TransitDeclaration | any) {
  let object = cloneDeclaration(input)
  object.status.mrnReject = <TransitStatusUpdate>{processId: "1", date: "2021-08-26", reason: "kannie he!!"};
  return object;
}


export const TransitMock = [
  saved(<any>simplifiedScheduled),
  accept(<any>simplifiedScheduled),
  release(<any>simplifiedScheduled),
  saved(<any>simplifiedOnAta),
  accept(<any>simplifiedOnAta),
  release(<any>simplifiedOnAta),
  saved(<any>simplifiedDirectly),
  accept(<any>simplifiedDirectly),
  release(<any>simplifiedDirectly),
  saved(<any>simplifiedDirectlySensitiveGoods),
  transitWithCrn,
  transitWithoutCrn,
  saved(<any>transitWithoutCrn),
  invalidGuarantee,
  send(<any>transitWithoutCrn),
  transitSendOnAta,
  send(<any>transitSendOnAta),
  accept(<any>transitWithoutCrn),
  accept(<any>transitDepartureTransportModeNotSpecified),
  reject(<any>transitWithoutCrn),
  release(<any>transitWithoutCrn),
  releaseMismatching(<any>transitWithoutCrn),
  arrive(<any>transitWithoutCrn),
  writeoff(<any>transitWithoutCrn),
  cancelled(<any>transitWithoutCrn),
  cancelled(accept(<any>transitCancelledAcceptedFound)),
  cancelled(send(<any>transitWithoutCrn)),
  cancelrequested(<any>transitWithoutCrn),
  cancelledByCustoms(<any>transitWithoutCrn),
  notreleased(<any>transitWithoutCrn),
  releaserequested(<any>transitWithoutCrn),
  inspection(<any>transitWithoutCrn),
  insufficientguarantee(<any>transitWithoutCrn),
  inforequested(<any>transitWithoutCrn),
  infodeclared(<any>transitWithoutCrn),
  mrnDeclared(release(<any>transitWithoutCrn)),
  mrnDeclared(infodeclared(<any>transitWithoutCrn)),
  mrnSent(mrnDeclared(release(<any>transitWithoutCrn))),
  mrnAccepted(mrnSent(mrnDeclared(release(<any>transitWithoutCrn)))),
  mrnRejected(mrnSent(mrnDeclared(release(<any>transitWithoutCrn))))
]

function cloneDeclaration(input: TransitDeclaration): TransitDeclaration {
  const result = cloneObject(input);
  result.lrn = uuid();
  return result;
}

export const midMock = [
  {
    "id": "91f5e283-0cd2-45cf-a206-72ea4987de90",
    "documentNumber": "22NL000855169898D7",
    "documentType": "NT1",
    "shipmentId": null,
    "equipments": [
      {
        "id": "BMOU9255560",
        "type": "CN"
      }
    ],
    "terminal": "ZEELOG",
    "terminalMessageScenarioVersion": "V2",
    "sender": "VAREKAMPCOLD",
    "documentStatus": "SENT"
  },
  {
    "id": "3e9425ef-e2a7-4f9d-99b1-1634f526fa3b",
    "documentNumber": "22NL000510168D6C82",
    "documentType": "MRN",
    "shipmentId": null,
    "equipments": [
      {
        "id": "TLLU2729826",
        "type": "CN"
      }
    ],
    "terminal": "RST",
    "sender": "CMACGMRHN",
    "documentStatus": "ACCEPTED"
  }
]
