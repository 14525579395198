import {Component, ElementRef, Input} from '@angular/core';
import {ChemicalData, DischargedStowage, GoodUnion} from '@portbase/bezoekschip-service-typescriptmodels';
import {dispatchChangeEvent, removeIf, removeItem} from '../../../../common/utils';
import {VisitContext} from '../../../visit-context';
import {DischargedStowageModel, DischargeModel} from '../../dangerous-goods.model';

@Component({
  selector: 'app-edit-discharge',
  templateUrl: './edit-discharge.component.html',
  styleUrls: ['./edit-discharge.component.css']
})
export class EditDischargeComponent {
  context  = VisitContext;

  @Input() handling: DischargeModel;
  emptyFormatter = v => v ? "Fully" : "Partly";

  constructor(private elementRef : ElementRef) {
  }

  stowageById(index: number, s: DischargedStowageModel) {
    return s.type + s.stowageNumber;
  }

  deleteStowage = (stowage: DischargedStowage) => {
    removeItem(this.handling.stowages, stowage);
    dispatchChangeEvent(this.elementRef.nativeElement);
  };

  onEmptyChanged = (stowage: DischargedStowageModel) => {
    stowage.amount = stowage.emptied ? undefined : stowage.availableBulk;
  };

  dischargeGood = (good: GoodUnion) => {
    const stowages = this.handling.stowageBefore.filter(s => s.goodId === good.id && s.weight > 0);
    stowages.forEach(s => {
      const handledStowage = this.handling.stowages.find(e => e.type === s.type && e.stowageNumber === s.stowageNumber);
      if (handledStowage) {
        handledStowage.emptied = true;
        handledStowage.amount = undefined;
      } else {
        this.handling.stowages.push(<DischargedStowageModel>{
          emptied: true,
          stowageNumber: s.stowageNumber,
          needsPrewashing: !!(<ChemicalData>s.good.goodData).prewashRequired,
          amount: s.weight,
          type: s.type
        })
      }
      s = VisitContext.dangerousGoodsDeclaration.dangerousGoods.stowageAtArrival.find(s2 => s2.stowageNumber === s.stowageNumber);
      if (s) {
        s.portOfDischarge = VisitContext.visit.portOfCall.port;
      }
    });
    removeIf(this.handling.stowages, s => !s.stowageNumber);
    dispatchChangeEvent(this.elementRef.nativeElement);
  };
}
