import {Component, Input} from '@angular/core';
import {TransitDeclaration, TransitGoodsItem} from '@portbase/bezoekschip-service-typescriptmodels';
import {PortvisitUtils} from "../../../../refdata/portvisit-utils";
import {findTransitClassifications} from "../../../transit.utils";
import {lodash} from "../../../../common/utils";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";

@Component({
  selector: 'app-transit-good',
  templateUrl: './transit-good.component.html',
  styleUrls: ['./transit-good.component.css']
})
export class TransitGoodComponent {
  refData = PortvisitUtils;
  findClassifications = findTransitClassifications;
  @Input() declaration: TransitDeclaration;
  @Input() item: TransitGoodsItem;
  @Input() itemFromCustoms: TransitGoodsItem;
  @Input() isTemplate: boolean = false;
  @Input() isEditable: boolean = false;

  searchCurrencies(term: string) : Observable<any[]> {
    return PortvisitUtils.getCurrencies
      .pipe(map(all => lodash.filter(all, item => JSON.stringify(item).toLowerCase().includes(term.toLowerCase()))));
  }

  searchPreviousDocumentTypes(term: string) : Observable<any[]> {
    return PortvisitUtils.getTransitPreviousDocumentTypes
      .pipe(map(all => lodash.filter(all, item => JSON.stringify(item).toLowerCase().includes(term.toLowerCase()))));
  }

  searchPackageTypes(term: string) : Observable<any[]> {
    return PortvisitUtils.getPackageTypes
      .pipe(map(all => lodash.filter(all, item => JSON.stringify(item).toLowerCase().includes(term.toLowerCase()))));
  }
}
