<!-- For ports AMS or RTM -->
<ng-container *ngIf="context.visit.orderIncomingMovement">
  <div *ngIf="context.visit.visitDeclaration.portVisit.portEntry as portEntry" class="fieldset"
       [ngClass]="{'disabled' : context.isArrived()}">
    <app-port-entry [portEntry]="portEntry"
                    [firstBerthVisit]="context.visit.visitDeclaration.portVisit.berthVisits[0]"
                    [pilotStation]="context.visit.visitDeclaration.portVisit.pilotStation"
                    [pilotOnBoard]="getPilotOnBoardArrival"
                    [entryDependency]="portEntry.origin === 'SEA' ? portEntry.entryDependency : null">
    </app-port-entry>
  </div>
</ng-container>

<!-- For ports other than AMS or RTM -->
<ng-container *ngIf="!context.visit.orderIncomingMovement">
  <div class="row">
    <div class="col-md-6">
      <app-eta-eu-entry [(etaFirstEntryEu)]="context.visit.visitDeclaration.etaFirstEntryEu"></app-eta-eu-entry>  
      <app-entry-point></app-entry-point>
    </div>
    <div class="col-md-6">
      <app-arrival-voyage [voyage]="context.visit.visitDeclaration.arrivalVoyage"></app-arrival-voyage>
    </div>
  </div>
  <!-- Nautical services -->
  <div class="row" *ngIf="context.visit.orderNauticalServices">
    <div class="col-md-6">
      <app-pilot-station></app-pilot-station>
    </div>
  </div>
</ng-container>




