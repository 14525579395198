import {Component, Input} from '@angular/core';
import {PortvisitUtils} from '../../../refdata/portvisit-utils';
import {
  ApiCustomer,
  CommercialRelease,
  ConsignmentEntity,
  FindCustomers,
  GetMyCarriers,
  ReleaseToParty
} from '@portbase/bezoekschip-service-typescriptmodels';
import {AppContext} from "../../../app-context";
import lodash, {toUpper} from 'lodash';
import {of} from 'rxjs';
import {sendQuery} from '../../../common/utils';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-commercial-release-details',
  templateUrl: './commercial-release-details.component.html',
  styleUrls: ['./commercial-release-details.component.css']
})
export class CommercialReleaseDetailsComponent {
  refData = PortvisitUtils;
  appContext = AppContext;
  @Input() declaration: CommercialRelease;

  terminals: string[] = ['ECTDELTA', 'EUROMAX', 'APMII', 'APMRTM', 'RWG'];
  selectedConsignment: ConsignmentEntity;

  selectConsignment(entity: ConsignmentEntity) {
    delete this.declaration.releaseData.equipmentNumber;
    this.selectedConsignment = entity;
    this.declaration.releaseData.consignmentNumber = toUpper(entity?.consignment?.consignmentNumber);
    this.declaration.releaseData.terminalShortName = entity?.consignment?.terminal?.organisationShortName;
    delete this.declaration.releaseData.containerOperator;
    this.declaration.releaseData.empty = entity?.consignment?.generated
      || (entity && lodash.sumBy(entity?.consignment?.goodsItems, g => g.grossWeight) <= 1);
    this.declaration.crn = entity?.crn;
  }

  containersInConsignment = (consignment: ConsignmentEntity) =>
    of(consignment ? lodash.uniq(lodash.flatMap(consignment.consignment.goodsItems, g => g.placements)
      .map(p => p.equipmentNumber)) : []);

  carriersProvider = (organisationShortName: string) => sendQuery(
    "com.portbase.bezoekschip.common.api.visit.GetMyCarriers", <GetMyCarriers> {
    cargoDeclarantShortName: organisationShortName || AppContext.userProfile.organisation?.shortName});

  findReleaseToParties = term => {
    const ownerShortName = (AppContext.isAdmin() && this.declaration.releaseData?.containerOperator?.cargoDeclarantShortName)
      || AppContext.userProfile.organisation?.shortName;
    return sendQuery(
      "com.portbase.bezoekschip.common.api.commercialrelease.FindCustomers", <FindCustomers>{
        term: term,
        ownerShortName: ownerShortName
      })
      .pipe(map((results: ApiCustomer[]) => {
        return lodash.flatMap(results, r => lodash.values(r.references).map(customerRef => <ReleaseToParty>{
          name: r.customerData?.fullName,
          portbaseId: r.portbaseId,
          customerReference: customerRef.reference
        }));
      }));
  };
}
