import {Component, Input} from '@angular/core';
import {
  CommercialReleaseEmailPreferences,
  Country,
  UserPreferences
} from "@portbase/bezoekschip-service-typescriptmodels";
import {PortvisitUtils} from "../../refdata/portvisit-utils";

@Component({
  selector: 'app-commercial-release-email-preferences',
  templateUrl: './commercial-release-email-preferences.component.html',
  styleUrls: ['./commercial-release-email-preferences.component.css']
})
export class CommercialReleaseEmailPreferencesComponent {
  utils = PortvisitUtils;
  _userPreferences: UserPreferences;
  prefs: EmailPrefs[] = [];

  @Input()
  set userPreferences(preferences: UserPreferences) {
    this._userPreferences = preferences;
    this.prefs = Object.entries(preferences.commercialReleaseCountryPreferences)
      .map(e => {
        const prefs: EmailPrefs = e[1];
        prefs.country = e[0];
        return prefs;
      });
  }

  countryFormatter = (value: Country | string) => typeof value === "string" ? value : value.code;

  addCountrySpecificEmail = () => this.prefs.push({});

  deletePrefs = (prefs: EmailPrefs) => {
    this.prefs = this.prefs.filter(p => p !== prefs);
    this.onChange();
  }

  onChange = () => {
    const prefs: { [index: string]: CommercialReleaseEmailPreferences } = {};
    this.prefs.forEach(p => {
      if (p.country) {
        const country: string = typeof p.country === "string" ? p.country : p.country["code"];
        prefs[country] = p;
      }
    });
    this._userPreferences.commercialReleaseCountryPreferences = prefs;
  }
}

interface EmailPrefs extends CommercialReleaseEmailPreferences {
  country?: string;
}
