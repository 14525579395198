<div class="border-bottom container-fluid mw-100">
  <div class="row px-3 py-2 gx-4 main-row" *ngIf="equipment">
    <div class="col-auto d-none d-md-flex align-items-center" [style.width.px]="selectionWidth">
      <app-selection-checkbox *ngIf="selectable" [(ngModel)]="equipment.equipment['selected']"></app-selection-checkbox>
    </div>
    <div class="col col-sm-4 col-md-3 col-vlg-2 d-flex align-items-center" [style.padding-left]="indentionDepth+'px'">
      <button type="button" class="btn text-end p-2 me-2 rounded-circle" (click)="collapsed = !collapsed"
              [ngClass]="collapsible ? '' : 'invisible'"
              data-bs-toggle="collapse" aria-expanded="false" style="visibility: hidden">
        <span class="h1 m-0 align-top fa-light fa-fw text-primary" [ngClass]="collapsed ? 'fa-chevron-down' : 'fa-chevron-right'"></span>
      </button>
      <span class="sub-text me-2">
        <i class="fa fa-fw fa-light fa-xl" [ngClass]="utils.getEquipmentIconCls(equipment.equipment.sizeType, equipment.equipment.empty)"></i>
      </span>
      <div class="h-100 d-flex flex-column align-items-stretch justify-content-center">
        <span class="d-inline-block text-truncate mw-100 cursor-pointer underline-tertiary link-underline-tertiary link-primary text-body" (click)="editEquipment()">
          <app-highlight [term]="term" [result]="equipment.equipment?.equipmentNumber"></app-highlight>
        </span>
        <span class="sub-text">{{containerType}}</span>
      </div>
    </div>

    <div *ngIf="equipment.houseConsignments.length" class="d-none d-md-flex flex-row" [ngClass]="equipment.equipment.empty ? 'col' : 'col-2'">
      <div class="d-inline-flex me-1 align-items-center">
        <i class="fa fa-kit fa-fw fa-light fa-lg fa-pb-file-line"></i>
      </div>
      <div class="d-flex flex-column align-items-stretch justify-content-center">
        <app-consignment-house-column [houseConsignments]="equipment.houseConsignments" [consignmentProcessId]="consignmentProcessId"
                                      [term]="term"></app-consignment-house-column>
        <span class="sub-text">House B/L</span>
      </div>
    </div>

    <div class="col d-none d-sm-flex flex-column align-items-stretch justify-content-center w-0">
      <ng-container *ngIf="!equipment.equipment.empty">
        <app-consignment-goods-column class="d-block d-xl-none" [goods]="goods" [term]="term" [ellipsisCharacterCount]="15"></app-consignment-goods-column>
        <app-consignment-goods-column class="d-none d-xl-block" [goods]="goods" [term]="term" [ellipsisCharacterCount]="40"></app-consignment-goods-column>
        <span class="sub-text">Good</span>
      </ng-container>
    </div>

    <div class="col-auto d-none d-md-flex flex-column align-items-stretch justify-content-center">
      <span class="d-block text-truncate mw-100 text-end">{{totalPackages}}</span>
      <span class="sub-text text-end">Packages</span>
    </div>

    <div class="col-auto ms-auto d-none d-md-flex flex-column align-items-stretch justify-content-center">
      <span class="d-block text-truncate mw-100 text-end">{{totalWeight}}</span>
      <span class="sub-text text-end">Weight (kg)</span>
    </div>

    <div class="col-auto d-flex flex-row align-items-stretch justify-content-center dropdown px-2">
      <a class="d-none d-md-block btn rounded-circle p-2 text-primary h1 mb-0 action-btn" type="button" (click)="editEquipment()">
        <i class="fa-light fa-angle-right"></i>
      </a>
      <button class="btn rounded-circle p-2 text-primary h1 mb-0 action-btn" type="button"
              data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
              *ngIf="appContext.isAdmin()">
        <i class="fa-light fa-ellipsis-vertical"></i>
      </button>
      <div class="dropdown-menu position-fixed">

      </div>
    </div>
  </div>
</div>
