import {Component, Input} from '@angular/core';
import {ClearanceService} from "../../visit-details/visit/clearance/clearance-service";
import {VisitSummary} from "@portbase/bezoekschip-service-typescriptmodels";

@Component({
  selector: 'app-all-clearances-status',
  templateUrl: './all-clearance-status.component.html',
  styleUrls: ['./all-clearance-status.component.scss']
})
export class AllClearancesStatusComponent {

  constructor(private clearanceService: ClearanceService) {
  }

  @Input()
  summary: VisitSummary;

  get status() {
    return this.clearanceService.getOverviewStatus(this.summary.clearances, this.summary.movementForEkhClearance);
  }

}
