import {
  AttentionPointContext,
  AttentionPointType,
  Clearance,
  ClearanceMovement,
  Clearances,
  ClearanceStatus,
  Declaration,
  DeclarationStatus,
  EkhClearance,
  PortVisit,
  Visit,
} from "@portbase/bezoekschip-service-typescriptmodels";
import {Injectable} from "@angular/core";
import {sendQuery} from "../../../common/utils";

@Injectable({providedIn: 'root'})
export class ClearanceService {

  constructor() {
    sendQuery('com.portbase.bezoekschip.common.api.visit.GetClearanceAttentionPointTypes', {}).subscribe((attentionPoints) => {
      this.attentionPoints = attentionPoints;
    });
  }

  attentionPoints: [] = [];

  getStatus(visit: Visit, clearanceKey: AttentionPointContext, declarationStatus: DeclarationStatus): ClearanceStatus {
    if (visit.portOfCall.portAuthorityClearance) {
      if (clearanceKey != "ekh") {
        let clearance = this.getClearance(visit.clearances, clearanceKey);
        return this.agentNeedsToKnowOfClearanceStatus(clearanceKey, visit.declarations, clearance) ? clearance?.status : null;
      }
      const portVisit = visit.visitDeclaration.portVisit;
      if (this.isPassingThrough(portVisit)) {
        return this.getEkhClearance(visit.clearances, null, null)?.status;
      }
      return visit.movementForEkhClearance
        ? this.getEkhClearance(visit.clearances, visit.movementForEkhClearance?.from, visit.movementForEkhClearance?.to)?.status
        : null;
    } else { // When the port authority does not give explicit clearance, simulate that there is one from the accept/reject of the notification.
      return this.fromDeclarationStatus(declarationStatus);
    }
  }

  // The harbour master starts its clearance process for 'emas', 'emgs', 'sspi' and 'mdoh' on first receipt of 'ekh'.
  // This results in sending 'pending' status for those tasks. From the agents' perspective this is considered noisy
  // for they didn't start the tasks yet. In the case the agent hasn't declared any of those tasks the clearance' status
  // will be ignored, with the exception if the reported clearance status is other than PENDING.
  // NOTICE: In the near future this might be resolved at HAMIS themselves, making this code obsolete!!!
  agentNeedsToKnowOfClearanceStatus(clearanceKey: string, declarations: Declaration[], clearance: Clearance): boolean {
    if (clearance != null && clearance.status != ClearanceStatus.PENDING) {
      return true;
    }
    switch (clearanceKey) {
      case "emas":
        return declarations.filter(value => value.type === "WASTE").length > 0;
      case "emgs":
        return declarations.filter(value => value.type === "DANGEROUS_GOODS").length > 0;
      case "mdoh":
        return declarations.filter(value => value.type === "HEALTH").length > 0;
      case "sspi":
        return declarations.filter(value => value.type === "SECURITY").length > 0;
      default:
        false;
    }
  }

  isPassingThrough(portVisit: PortVisit): boolean {
    return portVisit.berthVisits.length == 0;
  }

  fromDeclarationStatus(dStatus: DeclarationStatus): ClearanceStatus {
    if (!dStatus) {
      return null;
    }
    switch (dStatus) {
      case DeclarationStatus.ACCEPTED:
        return ClearanceStatus.APPROVED;
      case DeclarationStatus.REJECTED:
        return null;
      case DeclarationStatus.DECLARED:
        return null;
      default:
        return null;
    }
  }

  getOverviewStatus(clearances: Clearances, movementForEkhClearance: ClearanceMovement): ClearanceStatus {
    let statuses = [];
    if (clearances?.sspi?.status) {
      statuses.push(clearances?.sspi?.status)
    }
    if (clearances?.emas?.status) {
      statuses.push(clearances?.emas?.status)
    }
    if (clearances?.mdoh?.status) {
      statuses.push(clearances?.mdoh?.status)
    }
    if (clearances?.emgs?.status) {
      statuses.push(clearances?.emgs?.status)
    }
    if (movementForEkhClearance) {
      let ekhClearance = this.getEkhClearance(clearances, movementForEkhClearance.to, movementForEkhClearance.from);
      statuses.push(ekhClearance?.status);
    }
    const initialValue = null;
    return statuses.reduce((accumulator, currentValue) => {
      if (this.statusPrio(accumulator) > this.statusPrio(currentValue)) {
        return accumulator
      } else {
        return currentValue;
      }
    }, initialValue);
  }


  getClearance(clearances: Clearances, clearanceKey: AttentionPointContext, arrivalBerthVisitId?: string, departureBerthVisitId?: string): Clearance {
    switch (clearanceKey) {
      case "sspi" :
      case "emgs" :
      case "emas" :
      case "mdoh" :
        return clearances != null ? clearances[clearanceKey] : null;
      case "ekh" : {
        return this.getEkhClearance(clearances, arrivalBerthVisitId, departureBerthVisitId);
      }
    }
  }

  private getEkhClearances(clearances: Clearances): EkhClearance[] {
    if (!clearances || !clearances.ekhList) {
      return new Array<EkhClearance>();
    }
    return clearances.ekhList;
  }

  getEkhClearance(clearances: Clearances, arrivalBerthVisitId?: string, departureBerthVisitId?: string): Clearance {
    const ekhClearances = this.getEkhClearances(clearances)
    return ekhClearances
      .find(c => {
          return arrivalBerthVisitId == c.arrivalBerthVisitId && departureBerthVisitId == c.departureBerthVisitId
        }
      );
  }

  getAttentionPointsForContext(context: string): AttentionPointType[] {
    return this.attentionPoints[context];
  }

  translate(context: string, attentionPointType: string): string {
    return this.getAttentionPointsForContext(context).find(a => a.type == attentionPointType)?.description;
  }

  private statusPrio(clearanceStatus: ClearanceStatus): Number {
    switch (clearanceStatus) {
      case ClearanceStatus.APPROVED:
        return 0;
      case ClearanceStatus.PENDING:
        return 1;
      case ClearanceStatus.APPROVED_WITH_REMARKS:
        return 2;
      case ClearanceStatus.REJECTED:
        return 3;
      case ClearanceStatus.UNKNOWN:
        return 4;
      default:
        return -1;
    }
  }
}
