<div class="fieldset" [ngClass]="{'disabled' :disabled}" *ngIf="!readonly; else ro;">
  <div class="row g-1 g-md-4 datetime">
    <div #picker class="col input-group dropdown">
      <input [(ngModel)]="formattedDate" (ngModelChange)="onDateChange()" [ngModelOptions]="{updateOn: 'blur'}"
             [required]="required" (focus)="this.focus.emit($event)" (blur)="this.blur.emit($event)" [disabled]="disabled"
             [ngClass]="status === 'warning' ? 'warning' : status === 'error' ? 'error' : null"
             [id]="id ? id + '-date' : null" autocomplete="off" class="form-control validatable" placeholder="dd-mm-yyyy">
      <button class="btn btn-outline-secondary fa fa-calendar-alt rounded-end"
              data-bs-toggle="dropdown" (focus)="this.focus.emit($event)" type="button" tabIndex="-1"></button>
      <div class="dropdown-menu dropdown-menu-end p-3" onclick="event.stopPropagation()">
        <app-date-picker *ngIf="showPicker" [date]="_calendarStartDate" [minDate]="_minDate"
                         (dateChanged)="onDatePickerChanged($event)"></app-date-picker>
      </div>
    </div>
    <div class="col-4 time" *ngIf="!dateOnly">
      <app-search [(ngModel)]="time" [searchFunction]="searchTime" [placeholder]="'hh:mm'" class="h-100"
                  (ngModelChange)="onTimeChange($event)" [id]="id ? id + '-time' : null"
                  [required]="required" (focus)="this.focus.emit($event)" [disabled]="disabled"
                  [ngClass]="status === 'warning' ? 'warning' : status === 'error' ? 'error' : null"></app-search>
    </div>
  </div>
</div>
<ng-template #ro>
  <span class="me-2 fa fa-clock text-secondary"></span> <span
  [ngClass]="{'text-secondary' : !dateOnly}">{{timestamp | timestamp:'DD MMM [’]YY'}}</span> <span
  class="ms-1 text-secondary" *ngIf="!dateOnly">{{timestamp | timestamp:'HH:mm'}}</span>
</ng-template>
