import {Component, Input} from '@angular/core';
import {GoodsItem} from '@portbase/bezoekschip-service-typescriptmodels/common';
import {ConsignmentModel, Manifest} from '../../cargo-import.model';
import {removeItem} from '../../../../common/utils';
import {VisitContext} from '../../../visit-context';

@Component({
  selector: 'app-goods',
  templateUrl: './goods.component.html',
  styleUrls: ['./goods.component.css']
})
export class GoodsComponent {
  context = VisitContext;
  @Input() consignment : ConsignmentModel;
  @Input() manifest: Manifest;

  get hiddenItems() : GoodsItem[] {
    return this.consignment.goodsItems.filter(g => g['hidden']);
  }

  showAll() {
    this.consignment.goodsItems.forEach(g => {
      delete g['hidden'];
      g['forceVisible'] = true;
    });
  }

  removeGoodsItem(item: GoodsItem) {
    return () => removeItem(this.consignment.goodsItems, item)
  }

  getContainerNumbers(item: GoodsItem) : string {
    return item.placements.map(p => p.equipmentNumber).join(", ");
  }
}
