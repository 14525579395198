import {AfterViewInit, Component} from '@angular/core';
import {Port, PortFacility, PortFacilityVisit, PreviousPort} from '@portbase/bezoekschip-service-typescriptmodels';
import {v4 as uuid} from 'uuid';
import {VisitContext} from '../../visit-context';
import {of} from 'rxjs';
import {DragulaService} from 'ng2-dragula';
import {sendQuery} from '../../../common/utils';
import {map} from 'rxjs/operators';
import moment from 'moment';

@Component({
  selector: 'app-previous-ports-and-facilities',
  templateUrl: './previous-ports-and-facilities.component.html',
  styleUrls: ['./previous-ports-and-facilities.component.css']
})
export class PreviousPortsAndFacilitiesComponent implements AfterViewInit {
  context = VisitContext;
  portInputFormatter = (port: Port) => port ? port.name + ' – ' + port.locationUnCode : "";
  searchPort = term => sendQuery("com.portbase.bezoekschip.common.api.visit.FindPorts", {term: term});
  addPreviousPort = () => this.context.visit.visitDeclaration.previousPorts.push(<PreviousPort>{id: uuid(), portFacilityVisits: []});
  facilityFormatter = (facility: PortFacility) => facility ? facility.code + ' – ' + facility.name : '';
  getFacilityProvider = (port: Port) => port ? sendQuery("com.portbase.bezoekschip.common.api.visit.GetPortFacilities", {portUnCode: port.locationUnCode})
    .pipe(map((facilities : PortFacility[]) => {
      facilities.push({
        code: port.locationUnCode + "-9999",
        name: "UNKNOWN"
      });
      return facilities;
    })) : of([]);
  addPortFacility = (portVisit: PreviousPort) => portVisit.portFacilityVisits.push(<PortFacilityVisit>{});
  deletePortFacility = (portIndex: number, facilityIndex: number) => this.context.visit.visitDeclaration.previousPorts[portIndex].portFacilityVisits.splice(facilityIndex, 1);

  constructor(private dragulaService: DragulaService) {
  }

  ngAfterViewInit(): void {
    this.dragulaService.find('previousPortsSecurity').options.invalid = (el, handle) => {
      return !handle.classList.contains('port-drag-handle');
    };
    this.dragulaService.find('portFacilities').options.invalid = (el, handle) => {
      return !handle.classList.contains('facility-drag-handle');
    };
    this.dragulaService.find('previousPortsSecurity').options.moves = () => {
      return !this.context.isVisitReadonly();
    };
    this.dragulaService.find('portFacilities').options.moves = () => {
      return !this.context.isVisitReadonly();
    };
    this.dragulaService.dropModel('previousPortsSecurity').subscribe(value => {
      const previousPort: PreviousPort = value.item;

      if (!!previousPort) {
        previousPort.arrival = null;
        previousPort.departure = null;
        if (previousPort.portFacilityVisits) {
          previousPort.portFacilityVisits.forEach(portFacilityVisit => {
            portFacilityVisit.arrivalDate = null;
            portFacilityVisit.departureDate = null;
          })
        }
      }
    });
    this.dragulaService.dropModel('portFacilities').subscribe(value => {
      const portFacilityVisit: PortFacilityVisit = value.item;
      portFacilityVisit.arrivalDate = null;
      portFacilityVisit.departureDate = null;
    });
  }

  facilityNumber = (facilityVisit: PortFacilityVisit) => {
    facilityVisit['counter'] = 0;
    for (const port of this.context.visit.visitDeclaration.previousPorts) {
      for (const facility of port.portFacilityVisits) {
        facilityVisit['counter']++;
        if (facilityVisit === facility) {
          return facilityVisit['counter'];
        }
      }
    }
  };

  private twoPortsNotChronological(portIndex: number){
    if(portIndex===0){
      return false;
    }
    const port = this.context.visit.visitDeclaration.previousPorts[portIndex];
    const date = port.departure ? port.departure : port.arrival;
    const nextPort = this.context.visit.visitDeclaration.previousPorts[portIndex-1]
    const nextDate = nextPort.arrival ? nextPort.arrival : nextPort.departure;
    return date && nextDate && moment(date).isAfter(moment(nextDate));
  }

  private portNotChronological(port: PreviousPort){
    return port.arrival && port.departure && moment(port.arrival).isAfter(moment(port.departure));
  }

  private twoFacilitiesNotChronological(port: PreviousPort, facIndex: number){
    if(facIndex===0){
      return false;
    }
    const facility = port.portFacilityVisits[facIndex];
    const date = facility.departureDate ? facility.departureDate : facility.arrivalDate;
    const nextFacility =  port.portFacilityVisits[facIndex-1]
    const nextDate = nextFacility.arrivalDate ? nextFacility.arrivalDate : nextFacility.departureDate;
    return date && nextDate && moment(date).isAfter(moment(nextDate));
  }

  private facilityNotChronological(facility: PortFacilityVisit){
    return facility.arrivalDate && facility.departureDate && moment(facility.arrivalDate).isAfter(moment(facility.departureDate));
  }

  private someFacilitiesAreOutsidePortVisit(port: PreviousPort){
    if(port.portFacilityVisits.length===0){
      return false;
    }
    const arrivalMoment = moment(port.arrival);
    const departureMoment = moment(port.departure);
    let result = false;
    port.portFacilityVisits.forEach(facility =>{
      if( (facility.arrivalDate && moment(facility.arrivalDate).isBefore(arrivalMoment, 'day'))
        || (facility.departureDate && departureMoment.isBefore(moment(facility.departureDate), 'day'))){
        result = true;
      }
    });
    return result;
  }

}
