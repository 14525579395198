import {
  BezoekschipOrganisation,
  GuaranteeData,
  TransitData,
  TransitDeclaration,
  TransitGoodsItem,
  TransitStatus
} from '@portbase/bezoekschip-service-typescriptmodels';
import {PortvisitUtils} from '../refdata/portvisit-utils';
import {downloadCsv} from '../common/download/download.utils';
import moment from 'moment';
import lodash from "lodash";
import {forkJoin, Observable} from "rxjs";

function formatGuarantee(guarantees: GuaranteeData[]) {
  return guarantees.length > 1 ? 'multiple guarantees available' :
    (guarantees.length === 1 ? (guarantees[0].type?.code + ' ' + guarantees[0].type?.description + ' ' +
      guarantees[0].guaranteeReferenceNumber + ' ' + guarantees[0].accessCode) : '');
}

function formatDeclaredValue(guarantees: GuaranteeData[]) {
  return guarantees.length > 1 ? 'multiple guarantees available' :
    (guarantees.length === 1 ? (guarantees[0].customsValue?.amount + ' ' + guarantees[0].customsValue?.currency?.unCode) : '');
}

function formatSeals(seals: string[]) {
  return seals.length > 1 ? 'multiple seals available' : (seals.length === 1 ? (seals[0]) : '');
}

function formatBl(goodsItem: TransitGoodsItem) {
  const documentCode = goodsItem?.previousDocument?.type?.code;
  return documentCode === 'N705' || documentCode === 'X-705' ? goodsItem.previousDocument.documentNumber : '';
}

function formatDate(date: string) {
  return date ? moment(date).format("DD-MM-YYYY") : "";
}

function asCsv(lrn: string, crn: string, declarantShortName: string, data: TransitData, status: TransitStatus,
               dataFromCustoms: TransitData, requestedTransportExpiryDate: string, transportExpiryDate: string,
               goodsItem: TransitGoodsItem, consignors: Map<string, any>) {
  const securityDetails = data.securityDetails;
  const sendDate = formatDate(status.sendDate);
  const guarantees = data.guarantees;
  const departureTransport = data.departureTransport;
  const borderTransport = data.borderTransport;
  const consignor = consignors.get(declarantShortName);
  const consignee = data.consignee;
  const securityConsignee = data.securityConsignee;

  return [
    data.type.code,
    !!status.inspectionStatus?.inspectionExpiryDate ? 'Y' : 'N',
    data.securityActivated ? 'Y' : 'N',
    !!securityDetails ? PortvisitUtils.enumFormatter(securityDetails.circumstances) : '',
    !!securityDetails?.commercialReference ? securityDetails.commercialReference : '',
    data.conveyanceReference,
    !!securityDetails?.paymentMethod ? PortvisitUtils.enumFormatter(securityDetails.paymentMethod) : '',
    lrn,
    status.mrn,
    data.containerNumber,
    '',
    formatDate(status.sent?.date),
    data.declarationPlace,
    data.representative,
    formatGuarantee(guarantees),
    formatDeclaredValue(guarantees),
    formatSeals(data.seals),
    formatBl(goodsItem),
    '',
    data.dischargeTerminal?.terminalName,
    data.departureOffice?.unCode,
    data.destinationsOffice?.unCode,
    '',
    data.placeOfLoading,
    '',
    data.authorizedLocation,
    data.countryOfDestination?.code,
    'N',
    data.transitRoute,
    // '', // 'Holder ID TIR', // not present
    crn,
    // '', //'Vessel', // not present
    // '', //'Voyage', // not present
    departureTransport?.modeOfTransport,
    // '', // 'Departure MoT type', not present
    departureTransport?.nationality?.code,
    departureTransport?.reference,
    borderTransport?.modeOfTransport,
    // '', // 'Border MoT type', not present
    borderTransport?.nationality?.code,
    borderTransport?.reference,
    consignor.fullName,
    consignor.address,
    consignor.zipCode,
    consignor.city,
    consignor.countryUnCode,
    consignor.customsEORINumber,
    // '', //'TIN (consignor)',
    consignee?.name,
    consignee?.address,
    consignee?.zipCode,
    consignee?.city,
    consignee?.state,
    consignee?.countryCode,
    consignee?.authorised ? "Y" : "N",
    consignee?.tinNumber,
    securityConsignee?.name,
    securityConsignee?.address,
    securityConsignee?.zipCode,
    securityConsignee?.city,
    securityConsignee?.countryCode,
    securityConsignee?.tinNumber,
    status.phase,
    // '', // 'Printed', not present
    sendDate,
    formatDate(requestedTransportExpiryDate),
    formatDate(status.accept?.date),
    formatDate(status.reject?.date),
    status.reject?.reason,
    formatDate(status.controlNotification?.date),
    status.releaseRequest?.reason,
    formatDate(status.releaseRequest?.date),
    formatDate(status.releaseRejected?.date),
    status.releaseRejected?.reason,
    formatDate(status.release?.date),
    status.cancellationRequest?.reason,
    formatDate(status.cancellationRequest?.date),
    formatDate(status.cancellation?.date),
    status.cancellation?.reason,
    // '', // 'Cancellation justification', not present
    formatDate(status.infoRequest?.date),
    formatDate(status.infoDeclared?.date),
    // '',// 'Info rejection reason', not present
    formatDate(status.writeOff?.date),
    status.clearanceCode,
    formatDate(status.arrival?.date),
    status.inspectionStatus?.bindingItinerary,
    status.inspectionStatus?.type,
    // '',// 'Inspection result description', not present
    // '', // 'Differences', not present
    formatDate(status.inspectionStatus?.inspectionDate),
    status.inspectionStatus?.inspectorName,
    goodsItem.description,
    goodsItem.numberOfPackages,
    goodsItem.weight,
    goodsItem.countryOfExport?.code,
    goodsItem.marksAndNumbers,
    goodsItem.netWeight,
    goodsItem.dangerousGoodsUnCode,
    formatDate(transportExpiryDate)
  ];
};

export function downloadTransitCsv(declarations: TransitDeclaration[]) {
  const data = [['Type', 'Simplified procedure', 'Security', 'Specific circumstance', 'Commercial reference number',
    'Conveyance reference number', 'Method of payment', 'LRN', 'MRN', 'Container', 'Declaration reference',
    'Declaration date', 'Declaration place', 'Representative', 'Guarantee type and reference',
    'Declared value and currency', 'Seal number', 'B/L number', 'MRN notification', 'Terminal', 'Departure office',
    'Destination office', 'Presentation office', 'Place of loading', 'Place of unloading', 'Authorised location',
    'Country of destination', 'Transport without seals', 'Route', 'Call reference number',
    'Departure mode of transport', 'Departure MoT nationality', 'Departure MoT ID', 'Border mode of transport',
    'Border MoT nationality', 'Border MoT ID', 'Consignor name', 'Address (consignor)', 'Zip code (consignor)',
    'City (consignor)', 'Country (consignor)', 'Loading authorisation (consignor)', 'Addressee name',
    'Address (addressee)', 'Zip code (addressee)', 'City (addressee)', 'State (addressee)', 'Country (addressee)',
    'Authorised (addressee)', 'TIN (addressee)', 'Consignee security name', 'Address (consignee security)',
    'Zip code (consignee security)', 'City (consignee security)', 'Country (consignee security)',
    'TIN (consignee security)', 'Status', 'Submission date', 'Transport term limit', 'Acceptance date',
    'Rejection date', 'Rejection reason', 'Control notification date', 'Release request status', 'Release request date',
    'Release rejection date', 'Release rejection reason', 'Release date', 'Cancellation request status',
    'Cancellation request date', 'Cancellation decision date', 'Cancellation decision reason', 'Info request date',
    'Info report date', 'Write-off date', 'Clearance code', 'Arrival date', 'Binding itinerary',
    'Inspection result code', 'Inspection date', 'Inspector', 'Description', 'Number of packages', 'Weight',
    'Country of export', 'Marks and numbers', 'Net weight', 'Dangerous goods UN code', 'Expiry date']];

  let erroredRecords: any[] = [];
  const tasks: Observable<BezoekschipOrganisation>[] = [];

  lodash.uniq(declarations.map(d => d.declarantShortName)).forEach(declarantShortName => {
    tasks.push(PortvisitUtils.getOrganisationByShortName(declarantShortName, {
      caching: true
    }));
  });

  forkJoin(tasks).subscribe(orgs => {
    const consignors: Map<string, BezoekschipOrganisation> = new Map<string, BezoekschipOrganisation>();
    orgs.forEach(o => consignors.set(o.shortName, o));
    declarations.forEach(declaration => declaration.data.goodsItems.forEach(goodsItem => {
      try {
        return data.push(asCsv(declaration.lrn, declaration.crn, declaration.declarantShortName, declaration.data,
          declaration.status, declaration.dataFromCustoms, declaration.requestedTransportExpiryDate,
          declaration.transportExpiryDate, goodsItem, consignors));
      } catch (e) {
        console.warn("Error when exporting declaration with LRN: " + declaration.lrn, e);
        erroredRecords.push(e);
      }
    }));
    if (erroredRecords.length === 0) {
      downloadCsv(data, 'transit.csv');
    }
  });
}
