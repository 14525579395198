import {Component} from '@angular/core';
import {ChartConfiguration} from "chart.js";
import {ServiceMonitoringBaseChart} from "../service-monitoring-base-chart.component";

@Component({
  selector: 'app-dashboard-bar-chart',
  templateUrl: './service-monitoring-bar-chart.component.html',
  styleUrls: ['./service-monitoring-bar-chart.component.scss'],
  providers: [ {provide: ServiceMonitoringBaseChart, useExisting: ServiceMonitoringBarChartComponent }]
})
export class ServiceMonitoringBarChartComponent extends ServiceMonitoringBaseChart {

  getOptions(): ChartConfiguration<'bar'>['options'] {
    return {
      scales: {
        x: {
          position: 'bottom',
          type: 'time',
          time: {
            tooltipFormat: 'DD MMM YYYY HH:mm:ss',
            displayFormats: {
              hour: 'HH:mm',
              minute: 'HH:mm',
              second: 'HH:mm:ss'
            }
          }
        },
        y: {
          min: 0
        }
      },
      plugins: {
        tooltip: this.tooltipOptions,
        legend: {
          display: false
        },
        title: {
          display: true,
          text: this.chartTitle + (this.totalCount ? " (" + this.totalCount + ")" : ""),
          font: {
            size: 16
          }
        },
        zoom: this.zoomOptions
      }
    }
  }
}
