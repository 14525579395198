import {Component, Input} from '@angular/core';
import {AppContext} from '../../../app-context';
import {DeclarationStatus} from '@portbase/bezoekschip-service-typescriptmodels';
import {VisitContext} from "../../visit-context";

@Component({
  selector: 'app-declaration-status',
  templateUrl: './declaration-status.component.html',
  styleUrls: ['./declaration-status.component.scss']
})
export class DeclarationStatusComponent {
  @Input() link : string;
  @Input() icon : string;
  @Input() tooltipTitle : string;
  @Input() status : DeclarationStatus;
  @Input() visitMenuCollapsed : boolean;
  @Input() openInNewTab: boolean;
  appContext = AppContext;
  visitContext = VisitContext;

  getFullMenuTooltip(): string {
    return this.tooltipTitle + this.getTooltipStatusExtension();
  }

  getTooltipStatusExtension(): string {
    switch (this.status) {
      case 'DECLARED':
        return " - Declared"
      case 'REJECTED':
        return " - Rejected"
      case 'ACCEPTED':
        return " - Accepted"
      default:
        return ""
    }
  }
}
