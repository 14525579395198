import {Component, OnInit} from '@angular/core';
import {VisitContext} from '../../visit-context';
import {openConfirmationModal, sendCommand} from "../../../common/utils";
import {
  AcceptSecurity,
  Declaration,
  DeclarationType,
  RejectSecurity
} from "@portbase/bezoekschip-service-typescriptmodels";
import {AppContext} from "../../../app-context";
import {PortvisitUtils} from '../../../refdata/portvisit-utils';

@Component({
  selector: 'app-security-admin',
  templateUrl: './security-admin.component.html',
  styleUrls: ['./security-admin.component.css']
})
export class SecurityAdminComponent implements OnInit {
  protected readonly openConfirmationModal = openConfirmationModal;

  SECURITY = DeclarationType.SECURITY;

  context = VisitContext;
  appContext = AppContext;
  downloadLink = null;
  downloadUri = "/api/uploads/";

  ngOnInit(): void {
    const key: string = VisitContext.visit.uploads.SECURITY;
    if (key) {
      this.downloadLink = this.downloadUri + key;
    }
  }

  openPaMessages() {
    PortvisitUtils.redirectToMessageManagement(VisitContext.visit.crn + '_security2pa')
  }

  openSwMessages() {
    PortvisitUtils.redirectToMessageManagement(VisitContext.visit.crn + '_sec2sw')
  }

  accept() {
    sendCommand('com.portbase.bezoekschip.common.api.security.AcceptSecurity', <AcceptSecurity>{
      crn: VisitContext.visit.crn
    }, () => {
      VisitContext.visit.declarations.push(<Declaration>{
        type: 'SECURITY',
        status: 'ACCEPTED'
      });
      VisitContext.replaceVisit(VisitContext.visit);
      AppContext.registerSuccess('The declaration was set to accepted successfully.');
    });
  }

  reject(reason: string) {
    sendCommand('com.portbase.bezoekschip.common.api.security.RejectSecurity', <RejectSecurity>{
      crn: VisitContext.visit.crn,
      reason: reason
    }, () => {
      VisitContext.visit.declarations.push(<Declaration>{
        type: 'SECURITY',
        status: 'REJECTED'
      });
      VisitContext.replaceVisit(VisitContext.visit);
      AppContext.registerSuccess('The declaration was set to rejected successfully.');
    });
  }
}
