import {Component, OnInit} from '@angular/core';
import {PortvisitUtils} from '../../../refdata/portvisit-utils';
import {MergeOrganisationRefdata, Party} from '@portbase/bezoekschip-service-typescriptmodels';
import {checkValidity, removeItem, sendCommand, sendQuery} from '../../../common/utils';
import {PartyComponent} from '../common/party/party.component';
import {AppContext} from '../../../app-context';
import {QueryGateway} from '../../../common/query-gateway';

@Component({
  selector: 'app-address-book',
  templateUrl: './address-book.component.html',
  styleUrls: ['./address-book.component.css']
})
export class AddressBookComponent implements OnInit {
  values : Party[] = [];

  constructor(private queryGateway : QueryGateway) {
  }

  ngOnInit(): void {
    sendQuery("com.portbase.bezoekschip.common.api.cargo.FindMyParties", {term: '', maxHits: 10000})
      .subscribe(values => this.values = values);
  }

  add() {
    this.values.splice(0, 0, <any>{});
  }

  remove(value) {
    return () => {
      removeItem(this.values, value);

      if (value.id) {
        sendCommand('com.portbase.bezoekschip.common.api.refdata.MergeParty', <MergeOrganisationRefdata>{
          value: value,
          id: value.id,
          delete: true
        }, () => {
          AppContext.registerSuccess("Contact was deleted successfully");
          this.queryGateway.removeFromCache("com.portbase.bezoekschip.common.api.cargo.FindMyParties");
        });
      }
    }
  }

  save(value : PartyComponent) {
    return checkValidity(value.elementRef);
  }

  onSaved() {
    AppContext.registerSuccess("Contact was saved successfully");
  }
}
