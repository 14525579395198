<div class="col-md-12">
  <app-alerting></app-alerting>
</div>
<app-collapse [collapse]="wasteCollectionTemplate" class="card card-body bg-light my-1" [showOnLoad]="newCollector">
  <ng-container *ngIf="summary">
    <app-collector-summary-title-bar [summary]="summary"></app-collector-summary-title-bar>
  </ng-container>
</app-collapse>
<ng-template #wasteCollectionTemplate>
  <div class="collapse">
    <div class="container px-0 mt-4">
      <div class="card">
        <div class="card-body">
          <div class="row" *ngIf="newCollector && !summary">
            <div class="col">
              <app-form-group>
                <label for="newVisit">Search visit</label>
                <app-search [ngModel]="summary" id="newVisit" (ngModelChange)="onVisitSelect($event)"
                            [searchFunction]="searchVisit"
                            [inputFormatter]="visitFormatter"></app-search>
              </app-form-group>
            </div>
            <div class="col">
              <button class="btn btn-secondary" (click)="cancelNewCollection()">Cancel</button>
            </div>
          </div>

          <ng-container *ngIf="summary">
            <ng-container *ngIf="!showRegisterCollection && !newCollector">

              <!-- Overview -->
              <div class="d-none d-md-block">
                <div class="row">
                  <div class="col-4 fw-bold">Type</div>
                  <div class="col-4 text-center fw-bold">To be collected (m3)</div>
                  <div *ngIf="summary.collections.length > 0" class="col-4 text-center fw-bold">Collected
                    (m3)
                  </div>
                </div>
                <hr>
                <div class="row mb-2" *ngFor="let item of allItems | keyvalue">
                  <div class="col-4">{{item.value.ssn.description}}
                    <app-info *ngIf="item.value.specificationFromAgent">
                      <span class="tooltipContent">{{item.value.specificationFromAgent}}</span>
                    </app-info>
                  </div>
                  <div class="col-4 text-center">{{item.value.quantityToBeCollected}}</div>
                  <div *ngIf="summary.collections.length > 0"
                       class="col-4 text-center">{{item.value.quantityCollected}}</div>
                </div>
              </div>

              <!--  Collections-->
              <ng-container *ngIf="summary.collections.length > 0">
                <hr>
                <div class="row mb-2">
                  <div class="col fw-bold">Collections</div>
                </div>
                <ng-container *ngFor="let collection of summary.collections">
                  <app-waste-collection (collectionCompleted)="handleCompletion()" [collection]="collection"
                                        [summary]="summary" [collector]="collector"></app-waste-collection>
                </ng-container>
              </ng-container>
            </ng-container>

            <div #newCollectionEl>
              <!--NEW collection-->
              <ng-container *ngIf="(newCollection && showRegisterCollection) || newCollector">
                <div class="row">
                  <div class="col-3 fw-bold">Type</div>
                  <div class="col-2 fw-bold text-center">To be collected (m3)</div>
                  <div class="col-2 fw-bold">Reception facility</div>
                  <div class="col-4"></div>
                  <div class="col-auto" style="min-width: 2.5em"></div>
                </div>
                <hr>
                <div class="row" *ngFor="let item of this.newCollection.items; let idx = index">
                  <div class="col-3" *ngIf="item.wasteMaterial.ssn">
                    {{item.wasteMaterial.ssn.description}}
                    <app-info *ngIf="getSpecificationFromAgent(item)"><span
                      class="tooltipContent">{{getSpecificationFromAgent(item)}}</span>
                    </app-info>
                  </div>
                  <div class="col-3" *ngIf="!item.wasteMaterial.ssn">
                    <app-select [small]="true" [options]="wasteTypeOptions(this.newCollection)"
                                [(ngModel)]="item.wasteMaterial.ssn"
                                required [formatter]="utils.wasteTypeFormatter"></app-select>
                  </div>
                  <div class="col-2 text-center">{{getToBeCollected(item.wasteMaterial.ssn)}}</div>
                  <div class="col-2 form-group mb-1">
                    <input required class="form-control form-control-sm" id="receptionFacility"
                           (ngModelChange)="setReceptionFacility(idx, $event)"
                           [(ngModel)]="item.receptionFacility"></div>
                  <div class="col-4"></div>
                  <div class="col text-end" style="min-width: 2em">
                    <span class="fa fa-trash text-secondary" (click)="deleteItem(this.newCollection.items, idx)"></span>
                  </div>
                </div>
                <div class="row">
                  <div class="col text-end">
                    <button [disabled]="!newCollection" (click)="addWasteItem(this.newCollection)" class="btn btn-info mt-1 me-1" type="button">Add
                      Waste
                    </button>
                  </div>
                </div>
                <hr>
              </ng-container>

              <!--          register pick up buttons -->
              <ng-container *ngIf="!showRegisterCollection">
                <div class="row mt-2">
                  <div class="col-12 text-end">
                    <button (click)="addNewCollection()" class="btn btn-info me-1" type="button">
                      New Collection
                    </button>
                    <div class="dropdown always-enabled float-end" *ngIf="appContext.isAdmin()">
                      <button class="btn btn-secondary" type="button" id="adminHamburgerMenu" data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false">
                        <!-- Hamburger menu icon -->
                        <i class="fa fa-user-shield" aria-hidden="true"></i>
                      </button>
                      <div class="dropdown-menu dropdown-menu-end" aria-labelledby="adminHamburgerMenu">
                        <h6 class="dropdown-header" style="font-size: 18px;">Messages</h6>
                        <a href="#" (click)="$event.preventDefault(); openSwMessages()" class="dropdown-item">
                          WAR to Singlewindow
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="showRegisterCollection || newCollector">
                <div class="row">
                  <div class="col-6">
                    <app-form-group>
                      <label for="berthVisitId">Berth</label>
                      <app-select id="berthVisitId" [(ngModel)]="newCollection.berthVisitId" [options]="getBerthIds()"
                                  [formatter]="berthFormatter"></app-select>
                    </app-form-group>
                  </div>
                  <div class="col-6">
                    <app-form-group>
                      <label for="startTime">Start time</label>
                      <app-date-field required id="startTime" [(ngModel)]="newCollection.startTime"></app-date-field>
                    </app-form-group>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 text-end">
                    <button (click)="cancelNewCollection()" class="btn btn-secondary me-1" type="button">
                      Cancel
                    </button>
                    <button (click)="registerCollection()" class="btn btn-info me-1" type="button">
                      Register Collection
                    </button>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="card d-none d-md-block mt-2" *ngIf="summary">
        <app-itinerary [berthVisits]="summary.berthVisits"></app-itinerary>
      </div>
    </div>
  </div>
</ng-template>

