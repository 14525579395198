import {Component, ElementRef, forwardRef, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {AbstractValueAccessorComponent} from '../component/value-accessor.component';
import {Observable} from 'rxjs';
import {dispatchChangeEvent, extractValue, uuid} from '../utils';
import {Placement} from "@popperjs/core/lib/enums";

@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.css'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => MultiselectComponent), multi: true}
  ],
  encapsulation: ViewEncapsulation.None,
})
export class MultiselectComponent extends AbstractValueAccessorComponent<any[]> implements OnInit {

  selectedValues = [];
  @Input() optionsProvider: Observable<any[]>;
  @Input() options: any[];
  @Input() dataKey: string;
  @Input() formatter;
  @Input() equalsFunction;
  @Input() required;
  @Input() id = uuid();
  @Input() title: string = "Select any number of values";
  @Input() placement: Placement = "top-end";
  @Input() disabled: boolean;
  @Input() hideSelected: boolean;
  @Input() enableSelectAll: boolean;
  @Input() autoSelectOnlyOption: boolean;
  @Input() hideButton: boolean;
  @Input() horizontal: boolean;

  constructor(private elementRef: ElementRef) {
    super();
  }

  toggleOption(option: any) {
    const existingOption = this.selectedValues.find(o => this.equalsFunction(option, o));
    if (existingOption) {
      this.selectedValues.splice(this.selectedValues.indexOf(existingOption), 1);
    } else {
      this.selectedValues.push(option);
    }
    this.onModelChange();
    dispatchChangeEvent(this.elementRef.nativeElement);
  }

  toggleSelectAll() {
    if (this.selectedValues.length < this.options.length) {
      this.selectedValues = [].concat(this.options);
    } else {
      this.selectedValues = [];
    }
    this.onModelChange();
    dispatchChangeEvent(this.elementRef.nativeElement);
  }

  isSelected(option: any): boolean {
    return this.selectedValues.some(o => this.equalsFunction(option, o));
  }

  get value(): any[] {
    return this.selectedValues;
  }

  writeValue(value: any[]): void {
    this.selectedValues = value ? value : [];
  }

  ngOnInit(): void {
    if (!this.optionsProvider && !this.options) {
      throw new Error('Attribute optionsProvider or options is required for app-multiselect component');
    }
    if (this.required === "") {
      this.required = true;
    }
    if (this.optionsProvider) {
      this.optionsProvider.subscribe(values => {
        this.options = values;
        this.onOptions();
      });
    } else if (this.options) {
      this.onOptions();
    }
    if (!this.formatter) {
      this.formatter = value => this.dataKey ? extractValue(value, this.dataKey) : value;
    }
    if (!this.equalsFunction) {
      this.equalsFunction = (option1, option2) => {
        if (!option1 && !option2) {
          return true;
        }
        if (!option1 || !option2) {
          return false;
        }
        return this.dataKey ? extractValue(option1, this.dataKey) === extractValue(option2, this.dataKey) : option1 === option2;
      }
    }
  }

  private onOptions = () => {
    setTimeout(() => {
      if (this.autoSelectOnlyOption && this.options.length == 1 && this.selectedValues.length === 0) {
        this.selectedValues.push(this.options[0]);
        this.onModelChange();
        dispatchChangeEvent(this.elementRef.nativeElement);
      }
    }, 0);
  };
}
