<!--table header-->
<div class="row g-0 bg-secondary text-light py-2">
  <div class="col required">
    <app-info style="padding-left: 2.2rem">
      <span>Location</span>
      <span class="tooltipContent">Specify the location or the longitude and latitude where the ship-to-ship activity is executed.</span>
    </app-info>
  </div>
  <div class="col-2 required ps-2 d-none d-sm-block" style="min-width: 12.5rem">Activity</div>
  <div class="col-1 ps-2 d-none d-xl-block" style="min-width: 10rem">Start date</div>
  <div class="col-1 ps-2 d-none d-xl-block" style="min-width: 10rem">End date</div>
  <div class="col-1 ps-2 d-none d-sm-block" style="min-width: 7.5rem">
    <app-info>
      <span class="required">Compliant</span>
      <span class="tooltipContent">Has the ship-to-ship activity been executed in compliance with the SSP?</span>
    </app-info>
  </div>
  <div class="col-2 ps-2 d-none d-xl-block" style="min-width: 14rem">
    <app-info>
      <span>Security measures</span>
      <span class="tooltipContent">Security measures applied in lieu.</span>
    </app-info>
  </div>
  <div class="col-auto ps-2 d-none d-xl-block" style="min-width: 1.5rem"></div>
  <div class="col-auto ps-2 text-end d-xl-none" style="min-width: 5rem"></div>
</div>

<!--loop over all activities-->
<div dragula="shipToShipActivities" [(dragulaModel)]="context.visit.securityDeclaration.shipToShipActivities">
  <div *ngFor="let activity of context.visit.securityDeclaration.shipToShipActivities; let idx = index;">
    <div class="row g-0 my-2 validatable">
      <div class="col">
        <span class="input-group">
          <span class="drag-handle grabbable input-group-text bg-light border-0 drag-handle"><span
            class="fa fa-map-marker-alt text-portbase-blue drag-handle"></span></span>
          <input required type="text" [(ngModel)]="activity.location" maxlength="256"
                 [id]="'activity-location-' + idx" maxlength="256" class="form-control">
        </span>
      </div>
      <div class="col-2 ps-2 d-none d-sm-block" style="min-width: 12.5rem">
        <app-select required [(ngModel)]="activity.type" [options]="types" [formatter]="typeFormatter"
                    [id]="'activity-type-' + idx" class="input-group">
        </app-select>
      </div>
      <div class="col-1 ps-2 d-none d-xl-block" style="min-width: 10rem">
        <app-date-field [(ngModel)]="activity.startDate"
                       [calendarStartDate]="activity.endDate"
                       [dateOnly]="true" [id]="'activity-start-' + idx"></app-date-field>
      </div>
      <div class="col-1 ps-2 d-none d-xl-block" style="min-width: 10rem">
        <app-date-field [(ngModel)]="activity.endDate"
                       [calendarStartDate]="activity.startDate"
                       [dateOnly]="true" [id]="'activity-end-' + idx"></app-date-field>
      </div>
      <div class="col-1 ps-2 d-none d-sm-block text-center" style="min-width: 7.5rem">
        <app-yes-no required [(ngModel)]="activity.compliantWithSsp" [id]="'activity-compliant-' + idx"></app-yes-no>
      </div>
      <div class="col-2 ps-2 d-none d-xl-block" style="min-width: 14rem">
        <input type="text" [(ngModel)]="activity.securityMeasures" maxlength="512"
               [id]="'activity-measures-' + idx" class="form-control">
      </div>
      <div class="col-auto ps-2 text-end d-none d-xl-block">
        <button class="btn px-0" type="button" title="Delete activity" (click)="deleteActivity(idx)"
                [id]="'activity-delete-' + idx" tabindex="-1"><span class="fa fa-trash text-secondary"></span></button>

      </div>
      <div class="col-auto ps-2 text-end d-xl-none" style="min-width: 5rem">
        <label class="btn btn-info" onclick="" [id]="'activity-details-' + idx"
                [ngClass]="{'btn-invalid' : activity.ngInvalid}"
                [attr.data-bs-target]="'#activity-collapse-' + idx"
                data-bs-toggle="collapse">Details</label>
      </div>

      <!--Activity details (only for small screens)-->
      <div class="col-12 my-2 collapse" [id]="'activity-collapse-' + idx" [appValidator]="activity">
        <div class="card card-body bg-light">
          <div class="card-title row">
            <h5 class="col">{{(activity.location ? activity.location + ' - ' : '') + typeFormatter(activity.type)}}</h5>
            <button (click)="deleteActivity(idx)" [id]="'activity-delete-' + idx"
                    class="btn mt-n2 col-auto" type="button" title="Delete activity" tabindex="-1"><span class="fa fa-trash text-secondary"></span></button>
          </div>
          <div class="row">
            <div class="col-md-6 d-sm-none">
              <app-form-group>
                <label [attr.for]="'activity-type-' + idx">Type</label>
                <app-select required [(ngModel)]="activity.type" [options]="types" [formatter]="typeFormatter"
                            [id]="'activity-type-' + idx" class="input-group">
                </app-select>
              </app-form-group>
            </div>
            <div class="col-md-6 d-sm-none">
              <app-form-group class="bg-transparent">
                <label [attr.for]="'activity-compliant-' + idx">Compliant with SSP</label>
                <app-yes-no required [(ngModel)]="activity.compliantWithSsp" [id]="'activity-compliant-' + idx"></app-yes-no>
              </app-form-group>
            </div>
            <div class="col-md-6">
              <app-form-group>
                <label [attr.for]="'activity-start-' + idx">Start date</label>
                <app-date-field [(ngModel)]="activity.startDate" [dateOnly]="true"
                               [calendarStartDate]="activity.endDate"
                               [id]="'activity-start-' + idx"></app-date-field>
              </app-form-group>
            </div>
            <div class="col-md-6">
              <app-form-group>
                <label [attr.for]="'activity-end-' + idx">End date</label>
                <app-date-field [(ngModel)]="activity.endDate" [dateOnly]="true"
                               [calendarStartDate]="activity.startDate"
                               [id]="'activity-end-' + idx"></app-date-field>
              </app-form-group>
            </div>
            <div class="col-md-6">
              <app-form-group>
                <label [attr.for]="'activity-measures-' + idx">Security measures applied in lieu</label>
                <textarea [(ngModel)]="activity.securityMeasures"
                          [id]="'activity-measures-' + idx" maxlength="512" class="form-control"></textarea>
              </app-form-group>
            </div>
          </div>
        </div>
      </div>
    </div>
    <span *ngIf="activityNotChronological(activity)" class="ng-invalid invalid-feedback mb-2" style="margin-left: 2.25rem">End date should be after start date</span>
    <span *ngIf="nextActivityNotChronological(idx)" class="ng-invalid invalid-feedback mb-2" style="margin-left: 2.25rem">This activity should be in chronological order with the activity above</span>
  </div>
</div>

<!--add port-->
<div class="row justify-content-between my-3">
  <div class="col-auto"></div>
  <div class="col-auto">
    <button (click)="addActivity()" id="addActivity" type="button" class="btn btn-info"
            style="width: 8rem">Add activity</button>
  </div>
</div>
