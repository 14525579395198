<ng-container [ngSwitch]="orderStatus">


  <ng-container *ngSwitchCase="'ORDERED'">
    <span class="fa-stack text-portbase-light-blue">
      <i class="fas fa-circle fa-stack-2x fa-inverse"></i>
      <i class="fas fa-fw fa-lg fa-paper-plane fa-stack-1x" title="Movement ordered"></i>
    </span>
  </ng-container>
  <ng-container *ngSwitchCase="'REJECTED'">
    <span class="fa-stack text-portbase-red">
      <i class="fas fa-circle fa-stack-2x"></i>
      <i class="fas fa-fw fa-lg fa-times fa-stack-1x fa-inverse" title="Order rejected"></i>
    </span>
  </ng-container>
  <ng-container *ngSwitchCase="'CONFIRMED'">
    <span class="fa-stack text-portbase-green">
      <i class="fas fa-circle fa-stack-2x"></i>
      <i class="fas fa-fw fa-lg fa-check fa-stack-1x fa-inverse" title="Order confirmed"></i>
    </span>
  </ng-container>
  <ng-container *ngSwitchCase="'CONFIRMED_NEW_PROPOSAL'">
    <span class="fa-stack text-portbase-orange">
      <i class="fas fa-circle fa-stack-2x"></i>
      <i class="fas fa-fw fa-lg fa-check fa-stack-1x fa-inverse" title="Order confirmed with new time proposal"></i>
    </span>
  </ng-container>
  <ng-container *ngSwitchCase="null">
    <span class="fa-stack light-grey">
      <i class="fas fa-circle fa-stack-2x fa-inverse"></i>
      <i class="fas fa-fw fa-lg fa-route fa-stack-1x" title="Movement information"></i>
    </span>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <span class="fa-stack light-grey">
      <i class="fas fa-circle fa-stack-2x fa-inverse"></i>
      <i class="fas fa-fw fa-lg fa-route fa-stack-1x" title="Movement information"></i>
    </span>
  </ng-container>
</ng-container>
