import {Component, ElementRef, Input} from '@angular/core';
import {DeclareProofOfArrival, TransitDeclaration} from '@portbase/bezoekschip-service-typescriptmodels';
import {VisitContext} from "../../../visit-details/visit-context";
import {checkValidity, sendCommand} from "../../../common/utils";
import {PortvisitUtils} from "../../../refdata/portvisit-utils";
import {AppContext} from "../../../app-context";

@Component({
  selector: 'app-transit-proof-of-arrival',
  templateUrl: './transit-proof-of-arrival.component.html',
  styleUrls: ['./transit-proof-of-arrival.component.scss']
})
export class TransitProofOfArrivalComponent {

  context = VisitContext;
  refData = PortvisitUtils;
  @Input() declaration: TransitDeclaration;

  constructor(private element: ElementRef) {
  }

  send() {
    if (checkValidity(this.element)) {
      sendCommand('com.portbase.bezoekschip.common.api.transit.DeclareProofOfArrival', <DeclareProofOfArrival>{
        lrn: this.declaration.lrn,
        crn: this.declaration.crn,
        info: this.declaration.status.info
      }, () => {
        AppContext.registerSuccess('The proof of arrival was sent successfully.');
      });
    }
  }
}
