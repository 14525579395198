<div class="row">
  <div class="col-md">
    <app-form-group label="Transport mode">
      <app-select [(ngModel)]="model.modeOfTransport"
                  [options]="utils.modesOfTransport" [formatter]="utils.enumFormatter"></app-select>
    </app-form-group>
    <app-form-group label="Vessel">
      <input class="form-control" [(ngModel)]="model.vessel">
    </app-form-group>
  </div>
  <div class="col-md">
    <app-form-group label="Voyage number">
      <input class="form-control" [(ngModel)]="model.voyageNumber">
    </app-form-group>
    <app-form-group label="Planned departure">
      <app-date-field [(ngModel)]="model.plannedDeparture" defaultTime="12:00"></app-date-field>
    </app-form-group>
  </div>
</div>
