<span class="d-block fw-regular mb-3">{{label}}</span>
<ng-container *ngIf="supplyChainActor else noSupplyChainActor">
  <div *ngIf="supplyChainActor" class="card form-group">
    <div class="card-body p-4">
      <div class="row mb-3">
        <div class="col d-flex flex-column align-items-stretch justify-content-center">
          <app-form-field label="Identification number" [includeMargin]="false"
                          [(value)]="supplyChainActor.identificationNumber" [editMode]="editMode && supplyChainActorEditMode">
            <input class="input-group rounded border form-control" [(ngModel)]="supplyChainActor.identificationNumber" required/>
          </app-form-field>
        </div>
        <div class="col d-flex flex-column align-items-stretch justify-content-center">
          <app-form-field label="Type" [includeMargin]="false" [(value)]="supplyChainActor.type"
                          [editMode]="editMode && supplyChainActorEditMode" [formatter]="utils.supplyChainTypeFormatter">
            <app-select [(ngModel)]="supplyChainActor.type" [options]="utils.additionalSupplyChainTypes"
                        [formatter]="utils.supplyChainTypeFormatter" required></app-select>
          </app-form-field>
        </div>
      </div>
      <div *ngIf="editMode && supplyChainActorEditMode" class="row mt-3">
        <div class="col-auto ms-auto">
          <button type="button" class="btn btn-outline-secondary" aria-label="Cancel" (click)="cancel()">Cancel</button>
        </div>
        <div class="col-auto">
          <button type="button" class="btn btn-primary" (click)="save()">Save</button>
        </div>
      </div>
      <div *ngIf="editMode && !supplyChainActorEditMode" class="position-absolute end-0 top-0 me-2 mt-2">
        <button type="button" class="btn btn-tertiary p-1" (click)="deleteActor()">
          <i class="text-danger fa fa-fw fa-light fa-trash-can"></i>
        </button>
        <button type="button" class="btn btn-tertiary p-1" (click)="toggleEditMode()">
          <i class="fa fa-fw fa-light fa-pencil"></i>
        </button>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #noSupplyChainActor>
  <span class="d-block mb-3">N/A</span>
</ng-template>
