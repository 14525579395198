<div class="row"  *ngIf="dangerousGoods.hasTanks">
  <div class="col-6">
    <app-form-group>
      <label>Added good</label>
      <input required [(ngModel)]="handling.addedGoodName" class="form-control">
    </app-form-group>
  </div>
  <div class="col-6">
    <app-form-group>
      <label>Ship to ship</label>
      <app-yes-no required [(ngModel)]="handling.shipToShip"></app-yes-no>
    </app-form-group>
    <app-form-group *ngIf="handling.hasInertTanks">
      <label>Tanks remain inert</label>
      <app-yes-no required [(ngModel)]="handling.keepInert"></app-yes-no>
    </app-form-group>
  </div>
</div>

<app-stowages [stowageArray]="handling.stowages" [loading]="handling"></app-stowages>
