<div class="row">
  <div class="col-md-6">
    <app-form-group><label>Id</label><input class="form-control" [(ngModel)]="portFacility['id']" disabled required>
    </app-form-group>
    <app-form-group><label>Port code</label>
      <app-search [ngModel]="portFacility['portCode']" [searchFunction]="searchPort"
                  (ngModelChange)="portFacility['portCode'] = $event['locationUnCode']"
                  required id="port" placeholder="Find port" [inputFormatter]="portFormatter">
      </app-search>
    </app-form-group>
  </div>
  <div class="col-md-6">
    <app-form-group><label>Code</label><input class="form-control" [(ngModel)]="portFacility.code" required>
    </app-form-group>
    <app-form-group><label>Name</label><input class="form-control" [(ngModel)]="portFacility.name" required>
    </app-form-group>
  </div>
</div>
