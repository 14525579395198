import {Component, Input, OnInit} from '@angular/core';
import {BerthVisitSummary, VisitStatus} from '@portbase/bezoekschip-service-typescriptmodels';
import {WasteCollectorUtils} from '../../waste-utils';

@Component({
  selector: 'app-itinerary',
  templateUrl: './itinerary.component.html',
  styleUrls: ['./itinerary.component.css']
})
export class ItineraryComponent implements OnInit {

  @Input() berthVisits: BerthVisitSummary[];

  utils = WasteCollectorUtils;

  constructor() {
  }

  ngOnInit() {
  }

  getStatus(berthVisit: BerthVisitSummary): VisitStatus {
    return berthVisit.atd ? VisitStatus.DEPARTED : (berthVisit.ata ? VisitStatus.ARRIVED : VisitStatus.EXPECTED);
  }
}
