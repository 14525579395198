<div class="row medium-14-16">
  <div class="col">Vessel name</div>
  <div class="col">ENI number</div>
  <div class="col">IMO number</div>
</div>
<div class="row regular-14-16">
  <div class="col">{{selection.bunkerVessel.name}}</div>
  <div class="col">{{selection.bunkerVessel.eni}}</div>
  <div class="col">{{selection.bunkerVessel.imo}}</div>
</div>
