<div class="row">
  <div class="col-md-6">
    <app-form-group><label>Type</label><input class="form-control" [(ngModel)]="chemical.type" disabled required></app-form-group>
    <app-form-group><label>Id</label><input class="form-control" [(ngModel)]="chemical['id']" disabled required></app-form-group>
    <app-form-group><label>Name</label><input class="form-control" [(ngModel)]="chemical.name" required></app-form-group>
    <app-form-group><label>Good code</label><input class="form-control" [(ngModel)]="chemical.goodCode"></app-form-group>
  </div>
  <div class="col-md-6">
    <app-form-group><label>Pollution category</label><input class="form-control" [(ngModel)]="chemical.pollutionCategory"></app-form-group>
    <app-form-group><label>Hazard class</label><input class="form-control" [(ngModel)]="chemical.hazardClass"></app-form-group>
    <app-form-group><label>Spec op requirements</label><input class="form-control" [(ngModel)]="chemical.specOpRequirements"></app-form-group>
    <app-form-group><label>Pre-wash required</label><app-yes-no [(ngModel)]="chemical.prewashRequired"
                                                                id="pre-wash-req"></app-yes-no></app-form-group>
  </div>
</div>
