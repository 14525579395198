import {Component, Input, TemplateRef} from '@angular/core';

@Component({
  selector: 'modal-confirm-autofocus',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss'],
})
export class ModalConfirmAutofocus {
  @Input() data: ModalConfirmAutofocusData;
  callback: (boolean, string?) => any;

  get title() {
    return this.data?.title || "Danger";
  }

  get points() {
    return this.data?.points || [];
  }

  get confirmText() {
    return this.data?.confirmText || "Ok";
  }

  get cancelText() {
    return this.data?.cancelText || "Cancel";
  }
}

export interface ModalConfirmAutofocusData {
  type: string;
  title: string;
  modalSize: null | 'sm' | 'lg' | 'xl';
  message: string;
  beforePointsMessage: string;
  points: string[];
  afterPointsMessage: string;
  question: string;
  confirmText: string;
  cancelText: string;
  withReason?: boolean;
  withChosableReason?: boolean;
  chosableMessage: string;
  chosableReasons: string[];
  chosableReasonFormatter: any;
  reason?: string;
  body: TemplateRef<any>;
}
