import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {lodash, removeItem} from "../../common/utils";
import {DeclarationStatus, IE3TranshipmentSummary} from '@portbase/bezoekschip-service-typescriptmodels';

@Component({
  selector: 'app-transhipment-filter',
  templateUrl: './transhipment-filter.component.html',
  styleUrls: ['./transhipment-filter.component.css']
})
export class TranshipmentFilterComponent implements TranshipmentFilter, OnInit {

  options: FilterableItems[] = [
    "declared", "senttocustoms", "rejectedbycustoms", "acceptedbycustoms", "senttoterminal", "acceptedbyterminal", "rejectedbyterminal"];
  selectedOptions: FilterableItems[] = [
    "declared", "senttocustoms", "rejectedbycustoms", "acceptedbycustoms", "senttoterminal", "acceptedbyterminal", "rejectedbyterminal"];
  deselectedOptions: FilterableItems[] = [];

  @Input() transhipments: IE3TranshipmentSummary[];
  @Output() changed = new EventEmitter<TranshipmentFilter>();

  ngOnInit(): void {
    const storedOptions: { selectedOptions, deselectedOptions }
      = JSON.parse(localStorage.getItem('transhipment-filter'));
    if (storedOptions) {
      this.selectedOptions = storedOptions.selectedOptions || [];
      this.deselectedOptions = storedOptions.deselectedOptions || [];
    }
    this.onChanged();
  }

  private onChanged() {
    this.changed.emit(this);
    localStorage.setItem('transhipment-filter', JSON.stringify({
      selectedOptions: this.selectedOptions,
      deselectedOptions: this.deselectedOptions
    }));
  }

  phaseDisplayName(option: FilterableItems) {
    switch (option) {
      case 'declared':
        return 'Scheduled';
      case 'senttocustoms':
        return 'REN sent to customs';
      case 'rejectedbycustoms':
        return 'REN rejected by customs';
      case 'acceptedbycustoms':
        return 'REN accepted by customs';
      case 'senttoterminal':
        return 'MID sent to terminal';
      case 'acceptedbyterminal':
        return 'MID accepted by terminal';
      case 'rejectedbyterminal':
        return 'MID rejected by terminal';
    }
    return lodash.startCase(option);
  }

  count(option: FilterableItems): number {
    return this.transhipments.filter(t => this.checkCondition(option, t)).length;
  }

  isOn(option: FilterableItems): boolean {
    return this.selectedOptions.some(o => option === o);
  }

  isOff(option: FilterableItems): boolean {
    return this.deselectedOptions.some(o => option === o);
  }

  isToggled(option: FilterableItems): boolean {
    return this.isOn(option) || this.isOff(option);
  }

  toggleOn(option: FilterableItems) {
    removeItem(this.deselectedOptions, option);
    const existingOption = this.selectedOptions.find(o => option === o);
    if (existingOption) {
      removeItem(this.selectedOptions, option);
    } else {
      this.selectedOptions.push(option);
    }
    this.onChanged();
  }

  toggleOff(option: FilterableItems) {
    removeItem(this.selectedOptions, option);
    const existingOption = this.deselectedOptions.find(o => option === o);
    if (existingOption) {
      removeItem(this.deselectedOptions, option);
    } else {
      this.deselectedOptions.push(option);
    }
    this.onChanged();
  }

  reset(option: FilterableItems) {
    removeItem(this.selectedOptions, option);
    removeItem(this.deselectedOptions, option);
    this.onChanged();
  }

  resetAll() {
    this.selectedOptions = [];
    this.deselectedOptions = [];
    this.onChanged();
  }

  checkCondition = (option: FilterableItems, s: IE3TranshipmentSummary) => {
    switch (option) {
      case "declared":
        return !s.renStatus && !s.midStatus;
      case "senttocustoms":
        return DeclarationStatus.DECLARED == s.renStatus?.status;
      case "rejectedbycustoms":
        return DeclarationStatus.REJECTED == s.renStatus?.status;
      case "acceptedbycustoms":
        return DeclarationStatus.ACCEPTED == s.renStatus?.status;
      case "senttoterminal":
        return s.midStatus?.sent && !(DeclarationStatus.ACCEPTED == s.midStatus?.status || DeclarationStatus.REJECTED == s.midStatus?.status);
      case "acceptedbyterminal":
        return DeclarationStatus.ACCEPTED == s.midStatus?.status;
      case "rejectedbyterminal":
        return DeclarationStatus.REJECTED == s.midStatus?.status;
      default:
        return false;
    }
  };

}

export interface TranshipmentFilter {
  selectedOptions: FilterableItems[];
  deselectedOptions: FilterableItems[];
  checkCondition: (option: FilterableItems, t: IE3TranshipmentSummary) => {};
}

export type FilterableItems = "declared" | "senttocustoms" | "rejectedbycustoms" | "acceptedbycustoms"
  | "senttoterminal" | "acceptedbyterminal" | "rejectedbyterminal";

