<div class="row">
  <div class="col-md">
    <app-form-group label="Item number">
      <input required class="form-control" placeholder="Enter item number" [disabled]="true"
             appDecimalNumber [maxDecimals]="0" [(ngModel)]="item.goodsItemNumber">
    </app-form-group>
    <app-form-group label="Description">
      <input class="form-control" placeholder="Enter goods description" [(ngModel)]="item.commodity.descriptionOfGoods"
             [disabled]="true">
    </app-form-group>
    <!--    <app-form-group label="Commodity code">-->
    <!--      <app-search [(ngModel)]="item.classification" [inputFormatter]="refData.goodsClassificationFormatter"-->
    <!--                  [searchFunction]="refData.findClassifications"-->
    <!--                  dataKey="code" placeholder="Select a classification" [disabled]="true"></app-search>-->
    <!--    </app-form-group>-->
    <!--    <app-form-group label="Marks & numbers">-->
    <!--      <app-tags [(ngModel)]="item.marksAndNumbers" placeholder="Add entry" [disabled]="true"></app-tags>-->
    <!--    </app-form-group>-->
    <app-form-group label="Temperature range">
      <div class="input-group">
        <input [(ngModel)]="item.minimumTemperature" placeholder="Minimum" appDecimalNumber maxlength="100"
               class="form-control" [disabled]="true">
        <input [(ngModel)]="item.maximumTemperature" placeholder="maximum" appDecimalNumber maxlength="100"
               class="form-control" [disabled]="true">
        <span class="input-group-text">&deg;C</span>
      </div>
    </app-form-group>

    <app-form-group label="Danger information">
      <app-search [(ngModel)]="item.commodity.dangerousGoods" dataKey="name"
                  [inputFormatter]="refData.dangerInformationFormatter"
                  [searchFunction]="refData.findDangerInformation"
                  [resultFormatter]="refData.dangerInformationFormatter"
                  placeholder="Find by name or un code" [disabled]="true"></app-search>
    </app-form-group>
  </div>

  <div class="col-md">
    <app-form-group label="Gross weight">
      <div class="input-group">
        <input required [(ngModel)]="item.grossWeight" comparePreamble="Terminal reported: "
               appDecimalNumber [maxDecimals]="3" class="form-control" [disabled]="true">
        <span class="input-group-text">kg</span>
      </div>
    </app-form-group>
    <app-form-group label="Outer packaging">
      <app-select [(ngModel)]="item.outerPackaging.typeOfPackages" dataKey="code" placeholder="– Select packaging –"
                  (ngModelChange)="onOuterPackageChange()" [optionsProvider]="refData.getCargoPackageTypes"
                  [formatter]="refData.packageTypeFormatter" [disabled]="true"></app-select>
    </app-form-group>
    <ng-container *ngIf="!item.outerPackaging?.typeOfPackages?.bulk">
      <app-form-group label="Outer packages">
        <div class="input-group">
          <input [(ngModel)]="item.outerPackaging.numberOfPackages" appDecimalNumber [maxDecimals]="0" [min]="1"
                 class="form-control" required [disabled]="true">
          <span class="input-group-text">#</span>
        </div>
      </app-form-group>
    </ng-container>
    <ng-container *ngIf="!item.outerPackaging.typeOfPackages?.bulk">
      <app-form-group label="Net weight">
        <div class="input-group">
          <input [(ngModel)]="item.netWeight" appDecimalNumber [maxDecimals]="3" class="form-control" [disabled]="true">
          <span class="input-group-text">kg</span>
        </div>
      </app-form-group>
      <ng-container *ngFor="let packaging of item.innerPackagingList">
        <app-form-group label="Inner packages">
          <div class="input-group">
            <input [(ngModel)]="packaging.numberOfPackages" appDecimalNumber [maxDecimals]="0" [min]="1"
                   class="form-control" [disabled]="true">
            <span class="input-group-text">#</span>
          </div>
        </app-form-group>
        <app-form-group *ngIf="packaging.numberOfPackages > 0" label="Inner packaging">
          <app-select [(ngModel)]="packaging.typeOfPackages" dataKey="code"
                      [optionsProvider]="refData.getCargoPackageTypes"
                      [formatter]="refData.packageTypeFormatter"
                      required [disabled]="true"></app-select>
        </app-form-group>
      </ng-container>
    </ng-container>
  </div>
</div>
