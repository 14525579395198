import {Chart} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import chartDataLabels from "chartjs-plugin-datalabels";

function setChartDefaults() {

  Chart.defaults.responsive = true;
  Chart.defaults.maintainAspectRatio = false;
  Chart.defaults.animation = false;
  Chart.defaults.parsing = false;
  Chart.defaults.normalized = true;

  // Datalabels
  Chart.defaults.plugins.datalabels.display = false;

  // Decimation
  Chart.defaults.plugins.decimation.enabled = true;
  Chart.defaults.plugins.decimation.algorithm = "lttb";

  // Default
  Chart.defaults.font.size = 13;

  // Layout
  Chart.defaults.layout.padding = 0;

  // Legend
  Chart.defaults.plugins.legend.display = false;

  // Point
  Chart.defaults.elements.point.radius = 0;
  Chart.defaults.elements.point.backgroundColor = "#2c7be5";
  Chart.defaults.elements.point.hoverRadius = 0;
  Chart.defaults.elements.point.borderWidth = 0;

  // Line
  Chart.defaults.elements.line.tension = 0.4;
  Chart.defaults.elements.line.borderWidth = 3;
  Chart.defaults.elements.line.borderColor = "#2c7be5";
  Chart.defaults.elements.line.backgroundColor = "rgba(255,255,255,0)";
  Chart.defaults.elements.line.borderCapStyle = 'round';
  Chart.defaults.elements.line.spanGaps = true;
  Chart.defaults.datasets.line.pointBorderWidth = 0;
  Chart.defaults.datasets.line.pointHoverRadius = 0;

  // Bar
  Chart.defaults.elements.bar.backgroundColor = "#2c7be5";
  Chart.defaults.elements.bar.borderWidth = 0;
  Chart.defaults.elements.bar.borderSkipped = 'middle';
  Chart.defaults.datasets.bar.maxBarThickness = 10;
  Chart.defaults.datasets.bar.barPercentage = 0.5;
  Chart.defaults.datasets.bar["pointBorderColor"] = "rgb(0,0,0,0)";

  // Arc
  Chart.defaults.elements.arc.backgroundColor = "#2c7be5";
  Chart.defaults.elements.arc.borderColor = "#152e4d";
  Chart.defaults.elements.arc.hoverBorderColor = "#0b1828";

  // Doughnut
  Chart.defaults.datasets.doughnut["border"] = '50%';
  Chart.defaults.datasets.doughnut.rotation = 90;

  // yAxis
  Chart.defaults.scales.linear["border"] = { ...Chart.defaults.scales.linear["border"], ...{ display: false, dash: [2], dashOffset: [2] } };
  Chart.defaults.scales.linear.grid = {
    ...Chart.defaults.scales.linear.grid,
    ...{ color: "#e3e3e3", drawTicks: false },
  };

  Chart.defaults.scales.linear.ticks.padding = 10;

  // xAxis
  Chart.defaults.scales.category["border"] = { ...Chart.defaults.scales.category["border"], ...{ display: false } };
  Chart.defaults.scales.category.grid = { ...Chart.defaults.scales.category.grid, ...{ display: false, drawTicks: false, drawOnChartArea: false } };
  Chart.defaults.scales.category.ticks.padding = 20;
}

function registerPlugins() {
  Chart.register(zoomPlugin);
  Chart.register(chartDataLabels);
}

function initialiseChartJs() {
  registerPlugins();
  setChartDefaults();
}

function stringToRgba(str: string, alpha = 1): string {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  let rgb = [0, 0, 0];
  for (let i = 0; i < rgb.length; i++) {
    rgb[i] = (hash >> (i * 8)) & 255;
  }
  return `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${alpha})`;
}

export { initialiseChartJs, stringToRgba }
