import {Component, Input} from '@angular/core';
import {PreviousDocument, TransitDeclaration, TransitGoodsItem} from '@portbase/bezoekschip-service-typescriptmodels';
import {removeItem} from '../../../common/utils';
import {AppContext} from "../../../app-context";

@Component({
  selector: 'app-transit-goods',
  templateUrl: './transit-goods.component.html',
  styleUrls: ['./transit-goods.component.css']
})
export class TransitGoodsComponent {
  appContext = AppContext;
  @Input() declaration: TransitDeclaration;
  @Input() isTemplate: boolean = false;

  removeItem(item: TransitGoodsItem) {
    return () => {
      removeItem(this.declaration.data.goodsItems, item);
    }
  }

  emptyGoodsItem() {
    return <TransitGoodsItem> { previousDocument: <PreviousDocument>{} };
  };

  isEditable = (): boolean => {
    switch (this.declaration.status.phase) {
      case 'undeclared':
      case 'rejected':
      case 'declared':
        return this.appContext.isTransitEditor();
    }
    return !this.declaration.status.phase;
  };
}
