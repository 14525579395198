import {Component, Input} from '@angular/core';
import {Berth, FindTerminals, Terminal} from '@portbase/bezoekschip-service-typescriptmodels';
import {sendQuery} from '../../common/utils';
import {map} from "rxjs/operators";

@Component({
  selector: 'app-edit-berth',
  templateUrl: './edit-berth.component.html',
  styleUrls: ['./edit-berth.component.css']
})
export class EditBerthComponent {
  @Input() berth: Berth;
  portsOfCall = sendQuery('com.portbase.bezoekschip.common.api.visit.GetPcsPorts', {}).pipe(map(ports => ports.map(p => p.port.locationUnCode)));

  searchTerminal = (term, portUnCode) => sendQuery('com.portbase.bezoekschip.common.api.visit.FindTerminals',
    <FindTerminals>{term: term, portUnCode: portUnCode});
  terminalFormatter = (terminal: Terminal) => terminal && terminal.terminalCode ? terminal.terminalCode + ' - ' + terminal.terminalName : "";

  setTerminal(terminal: Terminal) {
    if (!terminal) {
      this.berth.terminalCode = null;
      this.berth.terminalName = null;
      this.berth.organisationName = null;
      this.berth.organisationShortName = null;
    } else {
      this.berth.terminalCode = terminal.terminalCode;
      this.berth.terminalName = terminal.terminalName;
      this.berth.organisationName = terminal.organisationName;
      this.berth.organisationShortName = terminal.organisationShortName;
    }
  }

  setBollards($event, start) {
    if (!$event) {
      this.berth.bollards = null;
    } else {
      if (!this.berth.bollards) {
        this.berth.bollards = <any>{};
      }
      start ? this.berth.bollards.start = $event : this.berth.bollards.end = $event;
    }
  }
}
