import {Component, ElementRef} from '@angular/core';
import {
  BezoekschipOrganisation,
  CreateVisit,
  EntryPoint,
  EtaLocations,
  GetCurrentUserPreferences,
  HinterlandEntry,
  PilotStation,
  PortOfCall,
  SeaEntry,
  UserPreferences,
  Vessel,
  Visit,
} from '@portbase/bezoekschip-service-typescriptmodels';
import {combineLatest, Observable, of} from 'rxjs';
import {v4 as uuid} from 'uuid';
import {Router} from '@angular/router';
import {AppContext} from '../app-context';
import {checkValidity, cloneObject, sendCommand, sendQuery} from '../common/utils';
import {HttpErrorResponse} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-new-visit',
  templateUrl: './new-visit.component.html',
  styleUrls: ['./new-visit.component.css']
})
export class NewVisitComponent {
  appContext = AppContext;
  searchVessel = term => sendQuery('com.portbase.bezoekschip.common.api.visit.FindVessels', {term: term});
  vesselFormatter = (vessel: Vessel) => vessel.name + ' – ' + vessel.imoCode;
  portsOfCall = sendQuery('com.portbase.bezoekschip.common.api.visit.GetPcsPorts', {});
  shipOperators: BezoekschipOrganisation[];
  model: CreateVisit = newCommand();
  isBackOffice: boolean;
  pilotStations: Observable<PilotStation[]>;
  entryPoints: Observable<EntryPoint[]>;
  locations: Observable<PilotStation[]>;
  pilotBoardingPlace: PilotStation;
  emailForOrders: string;
  smsForOrders: string;
  manualCrn: boolean;
  optionallyGenerateCrn: boolean;
  useTerminalPlanning: boolean;
  feeder: boolean;

  constructor(private router: Router, private element: ElementRef) {
    this.initialize();
  }

  private initialize() {
    this.pilotStations = of([]);
    this.locations = of([]);
    this.entryPoints = undefined;
    this.shipOperators = [];
    this.manualCrn = undefined;
    this.optionallyGenerateCrn = undefined;

    sendQuery("com.portbase.bezoekschip.common.api.authorisation.GetCurrentUserPreferences", <GetCurrentUserPreferences>{})
      .subscribe(result => {
        this.emailForOrders = (<UserPreferences>result).orderEmail;
        this.smsForOrders = (<UserPreferences>result).orderSms;
      });


    delete this.model.owner;
    delete this.model.declarant;
    delete this.model.visitDeclaration.portVisit.pilotStation;
    delete this.model.visitDeclaration.portVisit.entryPoint;
  }

  onPortOfCall(portOfCall: PortOfCall) {
    this.initialize();
    this.model.portOfCall = portOfCall;
    this.model.orderNauticalServices = portOfCall ? portOfCall.orderNauticalServicesEnabled : false;
    this.model.orderIncomingMovement = portOfCall ? portOfCall.orderNauticalServicesEnabled : false;
    this.model.crn = null;

    if (!portOfCall) {
      this.optionallyGenerateCrn = undefined;
      this.manualCrn = undefined;
    } else if (portOfCall.paDeclarationRequired || portOfCall.swDeclarationRequired) {
      this.optionallyGenerateCrn = false;
      this.manualCrn = false;
    } else {
      if (['NLVLI', 'NLTNZ'].includes(portOfCall.port.locationUnCode)) {
        this.optionallyGenerateCrn = true;
        this.manualCrn = !this.optionallyGenerateCrn;
      } else {
        this.manualCrn = true;
        this.optionallyGenerateCrn = false;
      }
    }

    if (portOfCall) {
      sendQuery('com.portbase.bezoekschip.common.api.visit.GetEtaLocations',
        {
          portUnCode: portOfCall.port.locationUnCode,
          isOrderIncomingMovement: this.model.orderIncomingMovement
        }).subscribe((result: EtaLocations) => {
        this.pilotStations = result.pilotStations && result.pilotStations.length > 0 ? of(result.pilotStations) : undefined;
        this.entryPoints = result.pilotStations && result.pilotStations.length > 0 ? undefined : of(result.entryPoints);
        if (this.pilotStations) {
          this.locations = this.pilotStations.pipe(map((pilotBoardingPlaces: PilotStation[]) => {
            var allLocations = pilotBoardingPlaces;
            if (this.model.orderIncomingMovement) {
              allLocations = pilotBoardingPlaces.filter(ps => !ps.atSea && ps.name !== "ACHTERLAND");
              if (portOfCall.port.locationUnCode === "NLRTM") {
                allLocations.unshift({
                  "code": null,
                  "name": "Sea buoy (Maascenter)",
                  "atSea": true
                });
              } else if (portOfCall.port.locationUnCode === "NLAMS") {
                allLocations.unshift({
                  "code": null,
                  "name": "Sea buoy (Kruispost)",
                  "atSea": true
                });
              }
            }
            return allLocations;
          }));
        }
      });

      sendQuery('com.portbase.bezoekschip.common.api.authorisation.GetShipOperatorsForCurrentUser',
        {portUnCode: portOfCall.port.locationUnCode, authorityShortName: portOfCall.portAuthority.shortName}, {
          caching: false,
          showSpinner: true
        })
        .subscribe((result: BezoekschipOrganisation[]) => {
          this.shipOperators = result;
          this.isBackOffice = result.length > 1;
          this.model.owner = result.length > 1 ? undefined : result[0];
          this.model.declarant = result.length > 1 ? result.filter(organisation =>
            this.appContext.userProfile.organisation?.shortName === organisation.shortName)[0] : result[0];
        });
    }
  }

  createVisit() {
    if (checkValidity(this.element)) {
      const crn = this.model.crn ? of(this.model.crn) : sendQuery('com.portbase.bezoekschip.common.api.visit.GetNextCrn', {portUnCode: this.model.portOfCall.port.locationUnCode}, {
        caching: false,
        showSpinner: true
      });
      const owner = this.model.owner;
      this.model.declarantResponsibleForCustoms = owner != null &&
        this.appContext.userProfile.customsResponsibleForOrganisations.indexOf(owner.shortName) != -1;
      if (this.model.copyOfVisit) {
        combineLatest(crn, sendQuery('com.portbase.bezoekschip.common.api.visit.GetCopyVisit', {crn: this.model.copyOfVisit}, {
          caching: false,
          showSpinner: true
        }))
          .subscribe(([crn, copiedVisit]) => {
            this.model = commandForCopiedVisit(this.model, copiedVisit);
            this.doCreateVisit(crn);
          });
      } else {
        crn.subscribe(crn => {
          if (AppContext.isCargoDeclarant()) {
            this.model.cargoDeclarants.push(this.model.declarant);
          }
          this.doCreateVisit(crn);
        });
      }
    }
  }

  private doCreateVisit(crn: string) {
    this.model.crn = crn;
    this.model.cargoImportEnabled = true;
    this.model.dangerousGoodsEnabled = this.model.portOfCall.dangerousGoodsEnabled;
    this.model.orderNauticalServices = this.model.portOfCall.orderNauticalServicesEnabled;
    this.model.orderIncomingMovement = this.model.portOfCall.orderNauticalServicesEnabled;
    this.model.visitDeclaration.vesselEmailAddress = this.model.vessel.emailAddress;
    this.model.visitDeclaration.portVisit.firstMovement.orderEmail = this.emailForOrders;
    this.model.visitDeclaration.portVisit.firstMovement.orderSms = this.smsForOrders;
    this.model.terminalPlanningEnabled = this.useTerminalPlanning && AppContext.isTerminalPlanner();

    this.model.visitDeclaration.portVisit.berthVisits.forEach(bv => {
      bv.terminalPlanningEnabled = this.model.terminalPlanningEnabled;
      bv.nextMovement.orderEmail = this.emailForOrders;
      bv.nextMovement.orderSms = this.smsForOrders;
      return bv;
    });

    if (this.model.terminalPlanningEnabled) {
      this.model.visitDeclaration.portVisit.berthVisits[0].eta = this.model.visitDeclaration.portVisit.etaPort;
    }

    if (!this.model.orderIncomingMovement) {
      this.model.visitDeclaration.portVisit.portEntry = null;
      this.model.visitDeclaration.portVisit.pilotStation = this.pilotStations ? this.pilotBoardingPlace : null;
    }
    if (this.pilotStations && this.model.orderIncomingMovement) {
      this.resolvePortEntry();
    }
    sendCommand('com.portbase.bezoekschip.common.api.visit.CreateVisit', this.model,
      () => window.setTimeout(() => this.router.navigate(['/details/' + this.model.crn]), 100),
      (error) => this.handleError(error));
  }

  private resolvePortEntry() {
    if (this.model.orderIncomingMovement) {
      this.model.visitDeclaration.portVisit.firstMovement.order = false;
      if (this.pilotBoardingPlace.atSea) {
        this.model.visitDeclaration.portVisit.portEntry = <SeaEntry>{
          entryDependency: null,
          earliestTimeOfPortEntry: null,
          etaPilotBoardingPlace: this.model.visitDeclaration.portVisit.pilotStation && this.model.visitDeclaration.portVisit.pilotStation.code === "MC"
            ? this.model.visitDeclaration.portVisit.etaPort : null,
          etaSeaBuoy: this.model.visitDeclaration.portVisit.etaPort,
          requestedEtaPilotBoardingPlace: null,
          intention: "REQUEST_FOR_ENTRY",
          origin: "SEA",
          baseForPlanning: this.useTerminalPlanning ? "FIRST_BERTH" :
            (this.model.visitDeclaration.portVisit.berthVisits.length > 0 ? null : "PILOT_BOARDING_PLACE")
        };
      } else {
        this.model.visitDeclaration.portVisit.portEntry = <HinterlandEntry>{
          earliestTimeOfPortEntry: null,
          etaPilotBoardingPlace: this.model.visitDeclaration.portVisit.etaPort,
          requestedEtaPilotBoardingPlace: null,
          baseForPlanning: this.useTerminalPlanning ? "FIRST_BERTH" : "PILOT_BOARDING_PLACE",
          intention: "REQUEST_FOR_ENTRY",
          origin: "HINTERLAND"
        };
        this.model.visitDeclaration.portVisit.pilotStation = this.pilotBoardingPlace;
      }
    } else {
      this.model.visitDeclaration.portVisit.portEntry = null;
      this.model.visitDeclaration.portVisit.firstMovement.order = null;
    }
  }

  private handleError = (error: any): void => {
    if (error instanceof HttpErrorResponse && String(error.status).startsWith('4') && error.error.error) {
      error = error.error.error;
      if (error.indexOf('Vessel') >= 0) {
        error = (<string>error).split('\n').join(", ");
        error = error + '. Please contact '
          + (this.model.portOfCall.port.locationUnCode === 'NLAMS'
            ? 'HOC at email: HOC@portofamsterdam.com; tel: +31-205234600'
            : 'HCC at email: HCC@portofrotterdam.com; tel: +31-102521000') + ' to register missing vessel details.';
        AppContext.registerError(error);
        return;
      }
    }
    AppContext.registerError(error);
  };

  get terminalPlanningEnabled() {
    return AppContext.isTerminalPlanner() && this.model.portOfCall?.port.locationUnCode === 'NLRTM';
  }
}

function newCommand(): CreateVisit {
  return <CreateVisit>{
    crn: null,
    cargoDeclarants: [],
    declarant: null,
    owner: null,
    portOfCall: null,
    vessel: null,
    copyOfVisit: null,
    visitDeclaration: {
      previousPorts: [{id: uuid(), portFacilityVisits: []}],
      nextPorts: [{id: uuid()}],
      arrivalVoyage: {},
      departureVoyage: {},
      portVisit: {
        firstMovement: {},
        berthVisits: [
          {
            id: uuid(),
            nextMovement: {},
            visitPurposes: []
          }
        ],
        etaPort: null,
        entryPoint: null,
        pilotStation: null,
        defectTypes: []
      }
    },
    sendToSwEnabled: true
  };
}

function commandForCopiedVisit(model: CreateVisit, copiedVisit: Visit): CreateVisit {
  if (model.portOfCall.port.locationUnCode !== copiedVisit.portOfCall.port.locationUnCode) {
    AppContext.registerError('Copied visit contains a different port');
    throw 'Copied visit contains a different port';
  }
  copiedVisit = removeTimesAndOrders(cloneObject(copiedVisit));
  copiedVisit.visitDeclaration.portVisit.defectTypes = [];
  copiedVisit.visitDeclaration.portVisit.defectTypeRemarks = null;

  copiedVisit.visitDeclaration.portVisit.etaPort = model.visitDeclaration.portVisit.etaPort;
  if (model.visitDeclaration.portVisit.pilotStation) {
    copiedVisit.visitDeclaration.portVisit.pilotStation = model.visitDeclaration.portVisit.pilotStation;
  }
  if (model.visitDeclaration.portVisit.entryPoint) {
    copiedVisit.visitDeclaration.portVisit.entryPoint = model.visitDeclaration.portVisit.entryPoint;
  }

  model.orderNauticalServices = model.portOfCall.orderNauticalServicesEnabled;
  model.orderIncomingMovement = model.portOfCall.orderNauticalServicesEnabled;
  model.copyOfVisit = copiedVisit.crn;
  model.cargoDeclarants = copiedVisit.cargoDeclarants;
  model.visitDeclaration = copiedVisit.visitDeclaration;
  model.securityDeclaration = copiedVisit.securityDeclaration;
  return model;

  function removeTimesAndOrders(copiedVisit: Visit): Visit {
    const visitDeclaration = copiedVisit.visitDeclaration;

    copiedVisit.berthVisitInfos = {};
    copiedVisit.incomingMovementHarbourMasterInfo = null;

    visitDeclaration.portVisit.etaPort = null;
    visitDeclaration.portVisit.etdPort = null;
    visitDeclaration.portVisit.ataPort = null;
    visitDeclaration.portVisit.atdPort = null;
    visitDeclaration.portVisit.firstMovement.stormPilotageInformation = null;
    visitDeclaration.etaFirstEntryEu = null;
    visitDeclaration.portVisit.firstMovement.order = null;
    visitDeclaration.portVisit.firstMovement.orderCancellationReason = null;

    visitDeclaration.portVisit.berthVisits.forEach(v => {
      v.id = uuid();
      v.eta = null;
      v.etd = null;
      v.ata = null;
      v.atd = null;
      v.requestedEtd = null;
      v.nextMovement.order = null;
      v.nextMovement.orderCancellationReason = null;
      v.nextMovement.stormPilotageInformation = null;
      v.bollardTo = null;
      v.bollardFrom = null;
    });

    visitDeclaration.previousPorts.forEach(v => {
      v.id = uuid();
      v.arrival = null;
      v.departure = null;
      v.portFacilityVisits.forEach(pv => {
        pv.arrivalDate = null;
        pv.departureDate = null;
      });
    });
    visitDeclaration.nextPorts.forEach(v => {
      v.id = uuid();
      v.arrival = null;
      v.departure = null;
    });

    if (copiedVisit.securityDeclaration) {
      copiedVisit.securityDeclaration.shipToShipActivities.forEach(a => {
        a.startDate = null;
        a.endDate = null;
      });
    }
    return copiedVisit;
  }
}
