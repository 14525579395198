import {Component} from '@angular/core';
import {AppContext} from '../app-context';
import {sendCommand} from '../common/utils';
import {ReportIssue} from '@portbase/bezoekschip-service-typescriptmodels';
import {VisitContext} from '../visit-details/visit-context';
import * as html2canvas from 'html2canvas';

@Component({
  selector: 'app-feedback',
  templateUrl: './report-issue.component.html',
  styleUrls: ['./report-issue.component.css']
})
export class ReportIssueComponent {
  sentFeedback = [];
  email = AppContext.userProfile.organisation?.emailAddress;
  feedback;

  reset() {
    this.email = AppContext.userProfile.organisation?.emailAddress;
    this.feedback = "";
  }

  isValid(): boolean {
    return this.email && this.feedback;
  }

  sendFeedback() {
    const sentFeedback = this.sentFeedback;
    const email = this.email;
    const feedback = this.feedback;
    const contact = AppContext.userProfile.organisation?.contact;

    if (!sentFeedback.includes(feedback)) {
      sentFeedback.push(feedback);

      // @ts-ignore
      html2canvas(document.querySelector("#screen"), {scale: 2}).then(function (result) {
        //scale the screenshot down
        const width = result.width >= 1600 ? 1600 : result.width;
        const height = result.width >= 1600 ? result.height * (1600 / result.width) : result.height;
        const extra_canvas = document.createElement("canvas");

        extra_canvas.setAttribute('width', width + '');
        extra_canvas.setAttribute('height', height + '');
        extra_canvas.getContext('2d').drawImage(result, 0, 0, result.width, result.height, 0, 0, width, height);

        const dataURL = extra_canvas.toDataURL();
        const command = {
          '@class': 'io.fluxcapacitor.javaclient.common.Message',
          payload: <ReportIssue>{
            'crn': VisitContext.visit ? VisitContext.visit.crn : null,
            'email': email,
            'contact': contact,
            'issue': feedback,
            'url': window.location.href,
            'version': AppContext.version
          },
          metadata: {
            'upload': dataURL
          }
        };

        sendCommand('com.portbase.bezoekschip.common.api.ui.ReportIssue', command, () => {
          AppContext.registerSuccess("Your feedback was sent successfully");
        }, () => {
          sentFeedback.pop();
        });
      });
    }
  }
}
