import {Component, Input} from '@angular/core';
import {Location} from '@portbase/bezoekschip-service-typescriptmodels';
import {PortvisitUtils} from '../portvisit-utils';

@Component({
  selector: 'app-edit-location',
  templateUrl: './edit-location.component.html',
  styleUrls: ['./edit-location.component.css']
})
export class EditLocationComponent {
  utils = PortvisitUtils;
  @Input() location : Location;
}
