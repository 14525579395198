import {Component, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {GoodDataUnion} from '@portbase/bezoekschip-service-typescriptmodels';
import {VisitContext} from '../../../visit-context';
import {StowageModelUnion} from '../../dangerous-goods.model';
import {dispatchChangeEvent} from "../../../../common/utils";
import {PortvisitUtils} from '../../../../refdata/portvisit-utils';

@Component({
  selector: 'app-edit-stowage',
  templateUrl: './edit-stowage.component.html',
  styleUrls: ['./edit-stowage.component.css']
})
export class EditStowageComponent {
  context = VisitContext;
  refData = PortvisitUtils;

  @Input() stowage: StowageModelUnion;
  @Input() disableGoodEdit: boolean;
  @Input() toggleTankContainer;
  @Input() isContainerItem:boolean;
  @Output() goodDataChanged = new EventEmitter<GoodDataUnion>();

  constructor(private elementRef : ElementRef) {
  }

  toggleOption = (stowage) => {
    this.toggleTankContainer(stowage);
  };

  ngAfterViewInit(): void {
    dispatchChangeEvent(this.elementRef.nativeElement);
  }
}
