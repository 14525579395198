<div id="sendEmailTokenModal" class="modal fade" tabindex="-1" role="dialog">
  <app-alerting></app-alerting>

  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Email login token</h5>
      </div>

      <ng-container *ngIf="!emailSent; else emailSentContent">
        <div class="modal-body">
          <p>Due to technical reasons we could not authenticate you. However, we can send you an email with login token.</p>
          <p>Please submit your Portbase user and organisation name to receive the login token.</p>

          <div class="my-2">
            <div class="row">
              <div class="col-12">
                <app-form-group>
                  <label>User name</label>
                  <input class="form-control" [(ngModel)]="user" id="user-email-token">
                </app-form-group>
              </div>
              <div class="col-12">
                <app-form-group>
                  <label>Organisation</label>
                  <input class="form-control" [(ngModel)]="organisation" id="organisation-email-token">
                </app-form-group>
              </div>
            </div>
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="sendLoginToken()">Send token</button>
        </div>
      </ng-container>

      <ng-template #emailSentContent>
        <div class="modal-body">
          <p>The email with login token is on its way. Please check inbox and spam folder.</p>
          <p>By clicking on the button in the email you will be automatically signed in.</p>
          <p>If you haven't received an email after 5 minutes, please contact Portbase Service Desk.</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="sendLoginToken()">Send email again</button>
        </div>
      </ng-template>

    </div>
  </div>
</div>
