<span *ngIf="manifestCleared()" class="fa fa-lock mx-2 text-secondary" title="Manifest has been closed by customs"></span>

<span *ngIf="clearanceDifference()" class="fa fa-lock-open mx-2 text-danger" title="Contains clearance differences"></span>

<ng-container *ngIf="inspections() as inspections">
  <ng-container *ngIf="getInspectionState(inspections) as inspectionState">
    <app-tooltip class="formGroupLabel" [wide]="true" placement="left" [keepOpen]="keepTooltipOpen">
      <ng-container *ngIf="!inspectionState.deleted">
        <span *ngFor="let type of inspectionState.inspecting" [ngSwitch]="type">
          <span *ngSwitchCase="'physical'" class="fas fa-eye mx-2"></span>
          <span *ngSwitchCase="'scan'" class="fas fa-camera mx-2"></span>
          <span *ngSwitchCase="'block'" class="fas fa-hand-paper mx-2"></span>
        </span>
        <span *ngFor="let type of inspectionState.released" [ngSwitch]="type">
          <span *ngSwitchCase="'physical'" class="fas fa-eye text-success mx-2"></span>
          <span *ngSwitchCase="'scan'" class="fas fa-camera text-success mx-2"></span>
          <span *ngSwitchCase="'block'" class="fas fa-thumbs-up text-success mx-2"></span>
        </span>
      </ng-container>
      <ng-container *ngIf="inspectionState.deleted">
        <span><span class="fas fa-trash-alt text-success mx-2" title="Inspection deleted"></span></span>
      </ng-container>
      <div class="tooltipContent mt-n2 text-start ">
        <div *ngFor="let inspection of inspections">
          <div class="row narrow-gutters mt-2">
            <div class="col-auto" style="color:#C0C0C0;" *ngIf="inspection.containerNumber">
              Inspection status for <span class="text-white">{{inspection.consignmentNumber}}</span> - <span class="text-white">{{inspection.containerNumber}}</span></div>
            <div class="col-auto" style="color:#C0C0C0;" *ngIf="!inspection.containerNumber">
              Bulk inspection status for <span class="text-white">{{inspection.consignmentNumber}}</span></div>
          </div>
          <ng-container *ngFor="let update of inspection.inspectionUpdates.slice().reverse()">
            <div class="row narrow-gutters">
              <div class="col-auto" style="color:#C0C0C0;">{{update.timestamp | date:"MMM d HH:mm"}}</div>
              <div class="col-auto" *ngIf="update.status">{{update.status}}{{update.remark? ', remark: ' + update.remark : ''}}</div>
              <div class="col-auto" *ngIf="!update.status">{{update.type}}{{update.physicalInspectionLocation === 'inspection_outlet'? ' at inspection outlet' :
                update.physicalInspectionLocation ==='customs_warehouse'? ' at customs warehouse' : ''}}</div>
            </div>
          </ng-container>
        </div>
      </div>
    </app-tooltip>
  </ng-container>
</ng-container>

<ng-container *ngIf="containerDischargeStatus() as status">
  <span class="mx-2" [ngSwitch]="status">
    <span *ngSwitchCase="'in_progress'" class="fas fa-cog fa-spin text-secondary"
          title="Currently being discharged"></span>
    <span *ngSwitchCase="'discharged'" class="fa fa-flag-checkered text-dark" title="Discharged"></span>
    <span *ngSwitchCase="'changed'" class="fa fa-flag-checkered text-danger"
          title="Discharged but different than requested"></span>
    <span *ngSwitchCase="'overlanded'" class="badge text-bg-info align-text-bottom"
          title="Discharged but not declared">Overlanded</span>
    <span *ngSwitchCase="'shortlanded'" class="badge text-bg-danger align-text-bottom"
          title="Declared but not discharged">Shortlanded</span>
  </span>
</ng-container>

<span *ngIf="bulkDischarged()" class="mx-2 fa fa-flag-checkered text-dark" title="Discharged"></span>

<ng-container *ngIf="containerEmpty()">
  <span class="mx-2">
    <span class="badge text-bg-secondary align-text-bottom" title="Empty container">Empty</span>
  </span>
</ng-container>

<ng-container *ngFor="let process of customsProcess()">
  <span *ngIf="'SEA_IN_SEA_OUT' === process" class="fa fa-forward mx-2 text-info" title="Sea in sea out (Transhipment)"></span>
  <span *ngIf="'SEA_IN_SEA_OUT' !== process" class="mx-1 text-center">
    <span class="badge rounded-pill text-bg-info align-text-bottom " [title]="utils.enumFormatter(process)">{{utils.getCustomsProcessCode(process)}}</span>
  </span>
</ng-container>

<ng-container *ngFor="let status of customsStatus()">
  <span class="mx-1 text-center">
    <span class="badge rounded-pill text-bg-dark align-text-bottom " [title]="utils.enumFormatter(status)">{{utils.getCustomsStatusCode(status)}}</span>
  </span>
</ng-container>

<span *ngIf="dangerousCargo()" class="fa fa-burn mx-2 text-danger" title="Cargo contains dangerous goods"></span>

<span *ngIf="reefers()" class="fa fa-thermometer-half mx-2 text-danger" title="Cargo is temperature controlled"></span>

<span *ngIf="shortlandedCount() as count" class="mx-2 text-danger" title="{{count}} shortlanded container{{count > 1 ? 's' : ''}}"><span class="fa fa-balance-scale-left me-1"></span>{{count}}</span>

<span *ngIf="overlandedCount() as count" class="mx-2 text-danger" title="{{count}} overlanded container{{count > 1 ? 's' : ''}}"><span class="fa fa-balance-scale-right me-1"></span>{{count}}</span>

<span *ngIf="consignmentCount() as count" class="mx-2" title="{{count}} consignment{{count > 1 ? 's' : ''}}"><span class="fa fa-file-invoice me-1"></span>{{count}}</span>

<span *ngIf="packageCount() as count" class="mx-2" title="{{count}} package{{count > 1 ? 's' : ''}}"><span class="fa fa-box-open me-1"></span>{{count}}</span>

<span *ngIf="quantifiedWeight() as weight" class="mx-2" title="{{weight}} gross weight"><span class="fa fa-weight-hanging me-1"></span>{{weight}}</span>

<span *ngIf="containerCount() as count" class="mx-2" title="{{count}} container{{count > 1 ? 's' : ''}}"><span class="fa fa-cube me-1"></span><span class="text-start d-inline-block" style="min-width: 1.5rem">{{count}}</span></span>
