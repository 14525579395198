import {Component} from '@angular/core';
import {AppContext} from "../app-context";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-system-link',
  templateUrl: './system-link.component.html',
  styleUrls: ['./system-link.component.scss']
})
export class SystemLinkComponent {
  appContext = AppContext;
  env = environment;

}
