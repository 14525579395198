<div *ngIf="this.context.findLatestDeclaration(PAX) || this.context.findLatestDeclaration(MSV)">
  <div class="d-none">
    <div *ngIf="this.visit">
      <div>
        <div id="toPrint" class="page">
          <header class="d-flex justify-content-between">
            <div class="logo ms-5">
              <img alt="Portbase logo" src="assets/Portbase_WB_RGB.png" class="img-resize"/>
            </div>
            <!-- contact info-->
            <div id="contact-information" style="margin-right: 130px;" class="mt-5">
              <!-- segment -->
              <div>
                <ul>
                  <li>Blaak 16</li>
                  <li>3011 TA ROTTERDAM</li>
                  <li>The Netherlands</li>
                  <li>T +31(0)88 625 25 00</li>
                  <li>info@portbase.com</li>
                  <li><b>www.portbase.com</b></li>
                </ul>
              </div>
              <div>
                <ul>
                  <li>VAT-number: NL8112.03.992.B.01</li>
                  <li>CoC-number: 24338021</li>
                </ul>
              </div>
            </div>
          </header>
          <section>
            <h2 class="title ms-5">Confirmation of Purchase</h2>
            <div class="d-flex justify-content-between">
              <div id="ship-agent-box" class="info-box border border-dark ms-3">
                <ul class="ms-n3 mt-3 px-4">
                  <li><b>To:</b></li>
                  <li>{{visit.declarant.fullName}}</li>
                  <li>{{visit.declarant.address}}</li>
                  <li>{{visit.declarant.zipCode}} {{visit.declarant.city}}</li>
                </ul>
              </div>
              <div id="ship-details-box" class="info-box border border-dark me-3">
                <div>
                  <div class="ms-3"><b>Ship details:</b></div>
                  <div class="d-flex justify-content-between">
                    <div class="col-4">CRN-nr:</div>
                    <div class="col">{{visit.crn}}</div>
                  </div>
                  <div class="d-flex justify-content-between">
                    <div class="col-4">Ship name:</div>
                    <div class="col">{{visit.vessel.name}}</div>
                  </div>
                  <div class="d-flex justify-content-between">
                    <div class="col-4">Imo:</div>
                    <div class="col">{{visit.vessel.imoCode}}</div>
                  </div>
                  <div class="d-flex justify-content-between">
                    <div class="col-4">Client ref nr:</div>
                    <div class="col" *ngIf="this.visit.visitDeclaration.clientReferenceNumber;else other_content">
                      <div class="inline">{{visit.visitDeclaration.clientReferenceNumber}}</div>
                    </div>
                    <ng-template #other_content class="col">
                      <div>-</div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
            <div id="declarations-matrix" class="col-12 mt-1">
              <div class="matrix-body border border-dark">
                <div class="matrix-header border-bottom border-dark d-flex justify-content-between">
                  <div class="col-2 my-auto">Quantity</div>
                  <div class="col-5  my-auto">Service</div>
                  <div class="col-2 my-auto">Unit</div>
                  <div class="col-2 my-auto">Price</div>
                </div>
                <div class="d-flex justify-content-between mt-2 bold" *ngIf="this.context.findLatestDeclaration(PAX)">
                  <div class="col-2 my-auto">1</div>
                  <div class="col-5 my-auto">Notification Crew and Passengers</div>
                  <div class="col-2 my-auto">Ship visit</div>
                  <div class="col-2 my-auto">{{getCrewPrice()}}</div>
                </div>
                <div class="d-flex justify-content-between mt-2 bold" *ngIf="this.context.findLatestDeclaration(MSV)">
                  <div class="col-2 my-auto">1</div>
                  <div class="col-5 my-auto">Notification Ship's stores</div>
                  <div class="col-2 my-auto">Ship visit</div>
                  <div class="col-2 my-auto">{{getShipStoresPrice()}}</div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <div class="total-price-box col-6 d-flex justify-content-between me-3">
                <div class="col-6 ms-n3 my-auto">Total in €:</div>
                <div class="col-1 my-auto me-2">{{calculateTotalPrice()}}</div>
              </div>
            </div>
          </section>
          <footer class="footer-container ms-3">
            <div>
              This confirmation of purchase is subject to the general terms and conditions of Portbase.<br>
              This document was generated by Portbase on: <b>{{getCurrentDate()}}</b>.
            </div>
          </footer>
        </div>
      </div>
    </div>
  </div>

  <a class="row g-0" (click)="downloadConfirmation()">
    <span class="col text-dark justify-content-start">Confirmation of Purchase</span>
  </a>

</div>
