export class ContainerUtils {

  static constructContainerProcessId = (crn: string, equipmentNumber: string) =>
    !crn || !equipmentNumber ? null : `${crn}_${equipmentNumber}`;

  static statusFormatter = (value: String) => {
    switch (value) {
      case "EXPECTED":
        return "Expected";
       case "Dischared":
        return "Discharged";
      case null:
        return "";
    }
  }

}
