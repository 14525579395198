import {Component, OnInit} from '@angular/core';
import {VisitContext} from "../../visit-context";
import {AppContext} from "../../../app-context";
import {scrollToTop, sendCommand} from "../../../common/utils";
import {RegisterAtdPortUsingId, RemoveAtdPort} from "@portbase/bezoekschip-service-typescriptmodels";

@Component({
  selector: 'app-register-atd-port',
  templateUrl: './register-atd-port.component.html',
  styleUrls: ['./register-atd-port.component.css']
})
export class RegisterAtdPortComponent implements OnInit {
  context = VisitContext;
  atdPort: string;
  modalOpen: boolean;

  constructor() { }

  ngOnInit() {
  }

  withModal = (action: "toggle" | "show" | "hide" | "handleUpdate" | "dispose") => {
    this.modalOpen = action === 'show';
    $('#registerAtdPortModal').modal(action);
  };

  isAtdModifiable() {
    return AppContext.isAdmin()
  }

  registerAtdPort() {
    sendCommand('com.portbase.bezoekschip.common.api.visit.RegisterAtdPortUsingId', <RegisterAtdPortUsingId> {
      crn: this.context.visit.crn,
      atdPort: this.atdPort
    }, () => {
      this.context.visit.visitDeclaration.portVisit.atdPort = this.atdPort;
      this.context.replaceVisit(this.context.visit);
      AppContext.registerSuccess('ATD Port registered successfully.');
    });
    scrollToTop();
    this.withModal('hide');
  }

  removeAtdPort() {
    sendCommand('com.portbase.bezoekschip.common.api.visit.RemoveAtdPort', <RemoveAtdPort> {
      crn: this.context.visit.crn,
    }, () => {
      this.context.visit.visitDeclaration.portVisit.atdPort = null;
      this.context.replaceVisit(this.context.visit);
      AppContext.registerSuccess('ATD Port successfully removed.');
    });
    scrollToTop();
    this.withModal('hide');
  }

}
