import {Clearance, ClearanceStatus} from "@portbase/bezoekschip-service-typescriptmodels";

export class ClearanceUtil {
  static shouldShow(clearance: Clearance): boolean {
    return clearance != null && (
      (clearance.status != ClearanceStatus.APPROVED && clearance.status != ClearanceStatus.PENDING)
      || clearance.remarks != null
      || (clearance.attentionPoints != null && clearance.attentionPoints.length > 0)
    )
  }
}
