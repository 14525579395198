import {
  CommercialRelease,
  CommercialReleaseData,
  CommercialReleaseStatus,
} from "@portbase/bezoekschip-service-typescriptmodels";
import {ComparatorChain} from "../common/comparator-chain";

const commercialReleasePhases: ReleasePhase[] = ["declared", "accepted", "rejected", "cancel declared", "cancel accepted", "cancel rejected"];

export const commercialReleaseStatusComparator: ComparatorChain = new ComparatorChain(
  'releaseData.releaseDeadline', '!releaseStatus.cancelled',
  (a: CommercialRelease, b: CommercialRelease): number =>
    commercialReleasePhases.indexOf(getPhase(a)) - commercialReleasePhases.indexOf(getPhase(b)),
  'releaseData.equipmentNumber');

export const commercialReleaseItemComparator: ComparatorChain = commercialReleaseStatusComparator.reverseProperties();

export const commercialReleaseDateComparator: ComparatorChain = new ComparatorChain(
  '-releaseStatus.declarationDate', 'releaseData.releaseDeadline', '!releaseStatus.cancelled',
  (a: CommercialRelease, b: CommercialRelease): number =>
    commercialReleasePhases.indexOf(getPhase(a)) - commercialReleasePhases.indexOf(getPhase(b)),
  'releaseData.equipmentNumber');

export function initializeCommercialRelease(o: CommercialRelease = <CommercialRelease>{}): CommercialRelease {
  const result = o;
  result.id = o.id;
  result.releaseData = initializeCommercialReleaseData(o.releaseData)
  result.releaseStatus = o.releaseStatus || <CommercialReleaseStatus>{};
  return result;
}

export function initializeCommercialReleaseData(o: CommercialReleaseData = <CommercialReleaseData>{}): CommercialReleaseData {
  return o || <CommercialReleaseData>{};
}

export function getPhase(o: CommercialRelease): ReleasePhase {
  return <ReleasePhase>((o.releaseStatus.cancelled ? "cancel " : "") + (!!o.releaseStatus.status ? o.releaseStatus.status.toLowerCase() : 'new'));
}

export type ReleasePhase = "declared" | "accepted" | "rejected" | "cancel declared" | "cancel accepted" | "cancel rejected";
