<div class="dropdown">
  <button class="btn btn-bg-secondary text-start d-flex justify-content-between align-items-center w-100 p-2" type="button"
          [ngClass]="{'active': hasValue}" data-bs-toggle="dropdown">
    <div class="d-flex flex-column mw-0 flex-grow-1 me-3">
      <span class="d-inline-block text-truncate">{{filterName}}</span>
      <div *ngIf="hasValue" class="mt-1 text-secondary d-inline-block text-truncate">
        {{dateLabel}}
      </div>
    </div>
    <i *ngIf="!hasValue" class="fa fa-fw fa-plus text-secondary"></i>
  </button>
  <div class="btn-close-wrapper rounded-circle position-absolute end-0 me-2 mt-2 top-0" [ngClass]="{'d-none': !hasValue}">
    <button type="button" class="btn-close"  (click)="removeFilter()" aria-label="Remove filter"></button>
  </div>
  <div class="dropdown-menu w-100">
    <div class="list-group">
      <ng-container *ngFor="let range of ranges">
        <button type="button" class="list-group-item list-group-item-action border-0" aria-current="true" (click)="selectRange(range)">
          {{range.label}}
        </button>
      </ng-container>
      <button type="button" class="list-group-item list-group-item-action border-0" aria-current="true" (click)="openDatePicker();">
        Custom range
      </button>
    </div>
  </div>
</div>
<app-date-range #dateRangeComponent [(ngModel)]="_dateRange" class="btn-group position-absolute" [inputMode]="'button'" [ranges]="ranges"
                (ngModelChange)="dateRangeChange($event)" [show]="false" header="Arrival date" [manual]="true"
                [showBackdrop]="true"></app-date-range>
