import {Component, Input} from '@angular/core';
import {Port, PortFacility} from '@portbase/bezoekschip-service-typescriptmodels';
import {sendQuery} from '../../common/utils';

@Component({
  selector: 'app-edit-port-facility',
  templateUrl: './edit-port-facility.component.html',
  styleUrls: ['./edit-port-facility.component.css']
})
export class EditPortFacilityComponent {
  @Input() portFacility : PortFacility;
  searchPort = term => sendQuery("com.portbase.bezoekschip.common.api.visit.FindPorts", {term: term});
  portFormatter = (port: Port) => port && port.name ? port.name + ' – ' + port.locationUnCode : port ? port : "";
}
