<app-date-field [(ngModel)]="berthVisit.ata"
                [id]="id + '-ata'" [disabled]="!isAtaModifiable()"
                (focus)="isAtaModifiable() ? withModal('show') : null"></app-date-field>
<div *ngIf="isAtaModifiable()" [id]="id + 'registerAtaModal'" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Register ATA</h5>
      </div>
      <div class="modal-body">
        <app-date-field [(ngModel)]="ata"
                        [required]="modalOpen"
                        [calendarStartDate]="berthVisit.ata || berthVisit.eta"
                        [id]="id + 'register-ata'"></app-date-field>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                (click)="ata = berthVisit.ata; modalOpen = false">Cancel
        </button>
        <button type="button" class="btn btn-danger" (click)="removeAta()">Remove ATA</button>
        <button type="button" class="btn btn-primary" (click)="registerAta()">Register ATA</button>
      </div>
    </div>
  </div>
</div>
