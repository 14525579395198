import {Component, Input, OnInit} from '@angular/core';
import {VentilationModel} from '../../dangerous-goods.model';
import {VisitContext} from "../../../visit-context";
import moment from "moment/moment";
import {BerthVisit} from "@portbase/bezoekschip-service-typescriptmodels";

@Component({
  selector: 'app-edit-ventilation',
  templateUrl: './edit-ventilation.component.html',
  styleUrls: ['./edit-ventilation.component.css']
})
export class EditVentilationComponent implements OnInit {
  context=VisitContext;

  @Input() handling: VentilationModel;

  startDateEntered: boolean = false;
  berthVisit: BerthVisit;

  ngOnInit(): void {
    this.berthVisit = VisitContext.berthVisitById(this.handling.berthVisitId);
  }
  blurStartDate() {
    this.startDateEntered = true;
  }
  beforeEtaBerth() {
    return moment(this.handling.timestamp).isBefore(this.berthVisit.eta);
  }

  afterEtdBerth() {
    return this.berthVisit.etd && moment(this.handling.timestamp).isAfter(this.berthVisit.etd);
  }
}
