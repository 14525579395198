import {Component, Input} from '@angular/core';
import {ChemicalData} from "@portbase/bezoekschip-service-typescriptmodels";

@Component({
  selector: 'app-edit-chemical',
  templateUrl: './edit-chemical.component.html',
  styleUrls: ['./edit-chemical.component.css']
})
export class EditChemicalComponent {
  @Input() chemical: ChemicalData;
}
