<div class="modal-dialog modal-xl" role="document" style="width:90%">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Adding {{items.length}} transit
        declaration{{items.length === 1 ? '' : 's'}}</h5>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md">
          <app-form-group label="Use template">
            <app-search [ngModel]="null" [minCharacters]="0"
                        (ngModelChange)="($event ? useTemplate($event) : null);"
                        [searchFunction]="findTransitTemplates"
                        [inputFormatter]="refData.transitTemplateFormatter">
            </app-search>
          </app-form-group>
        </div>

        <app-transit-declaration-send-trigger-info class="col-md" [declaration]="templateData"
                                                   sendOnAtaEnabled="true"></app-transit-declaration-send-trigger-info>
      </div>
      <app-collapse #transitAccordion [collapse]="collapz" [renderWhenCollapsed]="true">
        Show details
      </app-collapse>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary me-auto" data-bs-dismiss="modal" (click)="reset()">Cancel</button>
      <div class="btn-group">
        <button (click)="send()" class="btn btn-outline-info" tabindex="-1">Save and send</button>
        <button type="button" class="btn btn-outline-info dropdown-toggle dropdown-toggle-split"
                data-bs-toggle="dropdown"></button>
        <div class="dropdown-menu dropdown-menu-end">
          <button class="dropdown-item" (click)="save()">Save</button>
        </div>
      </div>
    </div>
    <ng-template #collapz>
      <div class="collapse" data-bs-parent="#transitAccordion">
        <div class="py-3 ms-2">
          <app-transit-details class="m-3" [declaration]="templateData" [isGeneric]="true" [displaySendDate]="false"></app-transit-details>
        </div>
      </div>
    </ng-template>
  </div>
</div>
