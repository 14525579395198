<app-form-group label="Eori number">
  <input class="form-control" [(ngModel)]="model.eoriNumber">
</app-form-group>
<app-form-group label="Name">
  <input class="form-control" required [(ngModel)]="model.name">
</app-form-group>
<app-form-group label="Address">
  <input class="form-control" required [(ngModel)]="model.address">
</app-form-group>
<app-form-group label="Zip code">
  <input class="form-control" required [(ngModel)]="model.zipCode" [maxLength]="9">
</app-form-group>
<app-form-group label="City">
  <input class="form-control" required [(ngModel)]="model.city">
</app-form-group>
<app-form-group label="Country">
  <app-search required [ngModel]="model.countryCode" [searchFunction]="utils.findCountries"
              (ngModelChange)="model.countryCode = $event && $event.code ? $event.code : model.countryCode"
              [resultFormatter]="utils.countryFormatter">
  </app-search>
</app-form-group>
<app-form-group *ngIf="!mergeAlways" label="{{editMode ? 'Update stored contact' : 'Add to contacts'}}">
  <app-yes-no [(ngModel)]="mergeRefdata" [appDefaultValue]="editMode"></app-yes-no>
</app-form-group>
