<div class="always-enabled">
  <app-form-group *ngIf="showEtaEuEntry()">
    <app-info class="formGroupLabel">
      <label>ETA EU entry</label>
      <span class="tooltipContent">Use original ETA reported on ENS so Customs can match CRN. Mandatory when last
        previous
        port is outside the EU. Otherwise leave empty.</span>
    </app-info>
    <app-date-field [(ngModel)]="etaFirstEntryEu" (ngModelChange)="onEtaFirstEntryEuChanged($event)" required
      id="etaFirstEuEntry"></app-date-field>
  </app-form-group>
</div>
