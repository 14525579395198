import {Component} from '@angular/core';
import {AttentionPointContext} from "@portbase/bezoekschip-service-typescriptmodels";
import {ClearanceService} from "./clearance-service";
import {VisitContext} from "../../visit-context";
import {ClearanceUtil} from "./clearance-util";

@Component({
  selector: 'app-emgs-clearance',
  templateUrl: './emgs.clearance.component.html',
  styleUrls: ['./emgs.clearance.component.css']
})
export class EmgsClearanceComponent {

  constructor(private clearanceService: ClearanceService) {
  }

  get clearance() {
    return this.clearanceService.getClearance(VisitContext.visit?.clearances, AttentionPointContext.emgs);
  }

  get shouldShow(): Boolean {
    return ClearanceUtil.shouldShow(this.clearance)
  }
}
