<div class="my-3"><i>Terminals are synced to WPCS refdata</i></div>
<div class="row">
  <div class="col-md-6">
    <app-form-group><label>Id</label><input class="form-control" [(ngModel)]="terminal['id']" disabled required></app-form-group>
    <app-form-group><label>Port code</label>
      <app-select id="port" [(ngModel)]="terminal['portCode']" [optionsProvider]="portsOfCall"
                  [addSelectedIfNotExists]="true" required placeholder="– Select a port –">
      </app-select>
    </app-form-group>
    <app-form-group><label>Name</label><input class="form-control" [(ngModel)]="terminal.terminalName" required></app-form-group>
  </div>
  <div class="col-md-6">
    <app-form-group><label>Code</label><input class="form-control" [(ngModel)]="terminal.terminalCode" required></app-form-group>
    <app-form-group label="Organisation">
      <app-search [ngModel]="terminal.organisationShortName" [searchFunction]="searchOrganisation"
                  (ngModelChange)="setOrganisation($event)" [resultFormatter]="utils.agentFormatter"
                  placeholder="Find organisation by short name">
      </app-search>
    </app-form-group>
    <app-form-group><label>Deprecated in Single Window</label>
      <app-yes-no [(ngModel)]="terminal.deprecatedSwLocationCode" required id="deprecatedSwLocationCode"></app-yes-no>
    </app-form-group>
    <app-form-group><label>Roll-On/Roll-Off</label>
      <app-yes-no [(ngModel)]="terminal.rollOnRollOff" required id="rollOnRollOff"></app-yes-no>
    </app-form-group>  </div>
</div>
