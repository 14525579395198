import {Component} from '@angular/core';
import {ServiceMonitoringBaseChart} from "../service-monitoring-base-chart.component";
import {ChartConfiguration} from "chart.js";

@Component({
  selector: 'app-dashboard-doughnut-chart',
  templateUrl: './service-monitoring-doughnut-chart.component.html',
  styleUrls: ['./service-monitoring-doughnut-chart.component.scss'],
  providers: [ {provide: ServiceMonitoringBaseChart, useExisting: ServiceMonitoringDoughnutChartComponent }]
})
export class ServiceMonitoringDoughnutChartComponent extends ServiceMonitoringBaseChart {

  getOptions(): ChartConfiguration<'doughnut'>['options'] {
    return {
      plugins: {
        tooltip: this.tooltipOptions,
        legend: {
          display: false,
          position: "top"
        },
        title: {
          display: true,
          text: this.chartTitle + (this.totalCount ? " (" + this.totalCount + ")" : ""),
          font: {
            size: 16
          }
        },
        datalabels: {
          display: true
        }
      }
    }
  }
}
