<div class="row">

  <div class="col-md">
    <app-form-group label="Number">
      <input class="form-control" required [ngModel]="model.number"
             [appCompare]="dischargeResult.number" comparePreamble="Terminal reported: "
             [disabled]="model.declared || !!model.dischargeResult"
             (change)="changeContainerNumber($event)">
    </app-form-group>
    <app-form-group label="Shipper‘s owned" *ngIf="model.shippersOwned">
      <app-yes-no disabled [ngModel]="true"></app-yes-no>
    </app-form-group>
    <app-form-group label="Type">
      <app-select required [(ngModel)]="model.type" (ngModelChange)="model.sizeType = getSizeType($event)"
                  placeholder="– Select a type –"
                  [appCompare]="dischargeResult.type" [compareFormatter]="utils.enumFormatter" comparePreamble="Terminal reported: "
                  [options]="utils.containerTypeOptions" [formatter]="utils.enumFormatter"></app-select>
    </app-form-group>
    <app-form-group label="Size/type">
      <app-search [(ngModel)]="model.sizeType" [searchFunction]="utils.findSizeTypes"
                  [inputFormatter]="utils.sizeTypeFormatter"
                  [appCompare]="dischargeResult.sizeType" [compareFormatter]="utils.sizeTypeFormatter" comparePreamble="Terminal reported: "
                  placeholder="Size or type, e.g. 22G1" dataKey="name" required>
      </app-search>
    </app-form-group>
    <app-form-group label="Container operator">
      <app-search [(ngModel)]="model.containerOperator" dataKey="name" [searchFunction]="utils.findContainerOperators"
                  [appCompare]="dischargeResult.containerOperator" [compareFormatter]="utils.containerOperatorFormatter" comparePreamble="Terminal reported: "
                  required [inputFormatter]="utils.containerOperatorFormatter"></app-search>
    </app-form-group>
    <app-form-group label="Terminal"
                    *ngIf="((model.empty && !goodsItem)
                    || !!dischargeResult.terminal && model.terminal?.terminalCode !== dischargeResult.terminal?.terminalCode)
                    && context.savedVisit.portOfCall.port.locationUnCode === model.portOfDischarge?.locationUnCode
                    && context.savedVisit.visitDeclaration.portVisit.berthVisits.length!==0">
      <app-select [disabled]="!model.empty"
                  required [(ngModel)]="model.terminal" [formatter]="utils.terminalFormatter"
                  [appCompare]="dischargeResult.terminal" [compareFormatter]="utils.terminalFormatter" comparePreamble="Terminal reported: "
                  [optionsProvider]="utils.getVisitTerminals()" placeholder="– Select a terminal –" dataKey="terminalCode"></app-select>
    </app-form-group>
    <app-form-group label="Booking reference">
      <input [(ngModel)]="model.bookingReference" [appCompare]="dischargeResult.bookingReference" comparePreamble="Terminal reported: "
             class="form-control">
    </app-form-group>
    <app-form-group label="Empty">
      <app-yes-no [(ngModel)]="model.empty" [appCompare]="dischargeResult.empty" comparePreamble="Terminal reported: "
                  [disabled]="!goodsItem"
                  [appDefaultValue]="goodsItem?.grossWeight === 0"></app-yes-no>
    </app-form-group>
    <app-form-group label="Tare weight">
      <div class="input-group">
        <input [(ngModel)]="model.tareWeight" [appCompare]="dischargeResult.tareWeight" comparePreamble="Terminal reported: "
               appDecimalNumber [maxDecimals]="3" class="form-control">
        <span class="input-group-text">kg</span>
      </div>
    </app-form-group>
    <app-form-group label="Port of loading">
      <app-select disabled
                  [ngModel]="model.portOfLoading" [options]="getPreviousPorts()" [formatter]="utils.portFormatter"
                  dataKey="name" [appCompare]="dischargeResult.portOfLoading" [compareFormatter]="utils.portFormatter" comparePreamble="Terminal reported: ">
      </app-select>
    </app-form-group>
    <app-form-group *ngIf="manifest && !goodsItem">
      <app-info class="formGroupLabel">
        <label>Departure</label>
        <div class="tooltipContent">The actual departure time from the port of loading</div>
      </app-info>
      <app-date-field required [(ngModel)]="model.actualDeparture"
                     (ngModelChange)="manifest.setActualDeparture($event)"></app-date-field>
    </app-form-group>
    <app-form-group label="Cargo agent"
                    *ngIf="!!dischargeResult.cargoAgentShortName
                    && dischargeResult.cargoAgentShortName !== context.cargoImportModel.cargoDeclarant.shortName">
      <input disabled [ngModel]="context.cargoImportModel.cargoDeclarant.shortName"
             [appCompare]="dischargeResult.cargoAgentShortName" comparePreamble="Terminal reported: " class="form-control">
    </app-form-group>
  </div>

  <div class="col-md">
    <app-form-group *ngIf="!!dischargeResult.actualTimeOfHandling">
      <label>Discharge time</label>
      <app-date-field disabled [(ngModel)]="dischargeResult.actualTimeOfHandling"></app-date-field>
    </app-form-group>
    <app-form-group label="Shipper seal">
      <input [(ngModel)]="model.shipperSealNumber" [appCompare]="dischargeResult.shipperSealNumber" comparePreamble="Terminal reported: "
             class="form-control">
    </app-form-group>
    <app-form-group label="Carrier seal">
      <input [(ngModel)]="model.carrierSealNumber" [appCompare]="dischargeResult.carrierSealNumber" comparePreamble="Terminal reported: "
             class="form-control">
    </app-form-group>
    <app-form-group label="Customs seal">
      <input [(ngModel)]="model.customsSealNumber" [appCompare]="dischargeResult.customsSealNumber" comparePreamble="Terminal reported: "
             class="form-control">
    </app-form-group>
    <app-form-group label="Temperature setting">
      <div class="input-group">
        <input [(ngModel)]="model.temperature" [appCompare]="dischargeResult.temperature" comparePreamble="Terminal reported: "
               appDecimalNumber maxlength="100" class="form-control">
        <span class="input-group-text">&deg;C</span>
      </div>
    </app-form-group>
    <app-form-group label="Overlength">
      <div class="input-group">
        <input [(ngModel)]="model.overlength" [appCompare]="dischargeResult.overlength" comparePreamble="Terminal reported: "
               appDecimalNumber [maxDecimals]="3" class="form-control">
        <span class="input-group-text">cm</span>
      </div>
    </app-form-group>
    <app-form-group label="Overheight">
      <div class="input-group">
        <input [(ngModel)]="model.overheight" [appCompare]="dischargeResult.overheight" comparePreamble="Terminal reported: "
               appDecimalNumber [maxDecimals]="3" class="form-control">
        <span class="input-group-text">cm</span>
      </div>
    </app-form-group>
    <app-form-group label="Overwidth">
      <div class="input-group">
        <input [(ngModel)]="model.overwidth" [appCompare]="dischargeResult.overwidth" comparePreamble="Terminal reported: "
               appDecimalNumber [maxDecimals]="3" class="form-control">
        <span class="input-group-text">cm</span>
      </div>
    </app-form-group>
    <app-form-group label="Oversize remarks">
      <input [(ngModel)]="model.oversizeRemarks" [appCompare]="dischargeResult.oversizeRemarks" comparePreamble="Terminal reported: "
             class="form-control">
    </app-form-group>
  </div>

</div>

<div class="declaration-block my-4">
  <h2>On carriage details</h2>
</div>
<app-on-carriage-details [(ngModel)]="model.onCarriageDetails"></app-on-carriage-details>

<div class="row">
  <div class="col-md">
    <app-form-group label="Initial demurrage">
      <app-date-field [(ngModel)]="model.initialDemurrage"></app-date-field>
    </app-form-group>
  </div>
  <div class="col-md">
    <app-form-group label="Redelivery address">
      <textarea [(ngModel)]="model.redeliveryAddress" class="form-control" style="min-height: 5.75rem;"></textarea>
    </app-form-group>
  </div>
</div>
