<div class="container-fluid p-0">
  <div class="d-flex flex-row mw-100">
    <div class="col-auto px-0 d-flex align-content-center">
      <button class="btn btn-outline text-primary h1 m-0" (click)="previous('month')"><i class="fa-light fa-angle-left"></i></button>
    </div>
    <div class="row m-0 col flex-md-nowrap header-select align-items-stretch">
      <div *ngFor="let c of calendar | keyvalue : sortCalendar"
           class="col p-1 text-center d-flex flex-row align-items-center">
        <span class="w-100">{{c.value.month}} {{c.value.year}}</span>
      </div>
    </div>
    <div class="col-auto px-0 d-flex align-content-center">
      <button class="btn btn-outline text-primary h1 m-0" (click)="next('month')"><i class="fa-light fa-angle-right"></i></button>
    </div>
  </div>
  <div class="row m-0 flex-nowrap">
    <div *ngFor="let c of calendar | keyvalue : sortCalendar" style="min-width: 224px;" class="col px-0">
      <div class="row g-0 fw-bolder mb-2" style="height: 2rem">
        <div *ngFor="let day of days" class="col d-flex align-items-center justify-content-center">
          <span>{{day}}</span>
        </div>
      </div>
      <div>
        <div *ngFor="let w of c.value.weeks | keyvalue" class="row g-0 align-items-center">
          <button *ngFor="let d of w.value.days" class="col day-cell btn btn-light p-0 m-0 border-0" [disabled]="d.disabled"
                  [ngClass]="d.cssClassesButton" (click)="changeDate(d.date)" [appAutoFocus]="isSelectedDate(d.date)" (mouseenter)="cellMouseEnter(d)"
                  (keydown.arrowLeft)="previous('day'); $event.preventDefault()" (keydown.arrowRight)="next('day'); $event.preventDefault()"
                  (keydown.arrowUp)="previous('week'); $event.preventDefault();" (keydown.arrowDown)="next('week'); $event.preventDefault()"
                  (keydown.enter)="updateDate(); $event.preventDefault()"
                  style="width: 2rem; height: 2rem">
            <div [ngClass]="d.cssClassesBackground" class="d-flex justify-content-center" [style]="d.styles">
              <span class="d-inline-flex align-self-center">{{d.date.date()}}</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
