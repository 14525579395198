import {Component, Input, OnInit} from '@angular/core';
import {BerthVisit, BerthVisitInfo} from '@portbase/bezoekschip-service-typescriptmodels';

@Component({
  selector: 'app-movement-summary',
  templateUrl: './movement-summary.component.html',
  styleUrls: ['./movement-summary.component.css']
})
export class MovementSummaryComponent implements OnInit {

  @Input() berthVisit: BerthVisit;
  @Input() nextBerthVisit: BerthVisit;
  @Input() berthVisitInfo: BerthVisitInfo;

  constructor() { }

  ngOnInit() {
  }

	get differentTugs() {
		return this.nextBerthVisit && JSON.stringify(this.berthVisit.tugboatAtDeparture) !== JSON.stringify(this.nextBerthVisit.tugboatAtArrival);
	}

	get differentBoatmen() {
		return this.nextBerthVisit && JSON.stringify(this.berthVisit.boatmenAtDeparture) !== JSON.stringify(this.nextBerthVisit.boatmenAtArrival);
	}

}
