import {Component, Input} from '@angular/core';
import {Guarantee} from "@portbase/bezoekschip-service-typescriptmodels";
import {AppContext} from "../../../../app-context";
import {PortvisitUtils} from "../../../../refdata/portvisit-utils";

@Component({
  selector: 'app-guarantee-admin',
  templateUrl: './guarantee-admin.component.html',
  styleUrls: ['./guarantee-admin.component.css']
})
export class GuaranteeAdminComponent {
  refData = PortvisitUtils;
  appContext = AppContext;

  @Input() guarantee: Guarantee;

  openMessages(processId: string) {
    PortvisitUtils.redirectToMessageManagement(processId);
  }

  getOutgoingMessages() {
    let statusList = [];
    if (this.guarantee.status) {
      statusList.push({name: "Request balance", id: this.guarantee.id, status: this.guarantee.status});
    }
    return statusList.length === 0 ? null : statusList;
  }
}
