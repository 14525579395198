<ng-container *ngIf="(appContext.isAdmin() || context.isOrganisationPortAuthority()) && !context.eventId">
  <div *ngIf="context.isOrganisationPortAuthority()" class="always-enabled">
    <button *ngIf="'DECLARED' === context.declarationStatusOf(SECURITY)" (click)="openConfirmationModal(rejectModal)"
            id="rejectSecurity" type="button" class="btn btn-secondary me-2" style="min-width: 6rem" tabindex="-1">Reject
    </button>
    <button *ngIf="'DECLARED' === context.declarationStatusOf(SECURITY)
    || 'REJECTED' === context.declarationStatusOf(SECURITY)" (click)="accept()" id="acceptSecurity"
            type="button" class="btn btn-secondary me-2" style="min-width: 6rem" tabindex="-1">Accept
    </button>
  </div>

  <div class="dropdown always-enabled" *ngIf="!context.isOrganisationPortAuthority()">
    <button class="btn btn-secondary" type="button" id="adminHamburgerMenu" data-bs-toggle="dropdown" aria-haspopup="true"
      aria-expanded="false">
      <!-- Hamburger menu icon -->
      <i class="fa fa-user-shield" aria-hidden="true"></i>
    </button>
    <div class="dropdown-menu dropdown-menu-end" aria-labelledby="adminHamburgerMenu">
      <!-- Reject declaration -->
      <a *ngIf="'DECLARED' === context.declarationStatusOf(SECURITY)"  (click)="openConfirmationModal(rejectModal)"
         id="rejectSecurity" class="dropdown-item text-danger" tabindex="-1" role="button">
        Reject
      </a>
      <!-- Accept declaration -->
      <a *ngIf="'DECLARED' === context.declarationStatusOf(SECURITY)
      || 'REJECTED' === context.declarationStatusOf(SECURITY)"
        (click)="accept()" id="acceptSecurity" class="dropdown-item text-success" tabindex="-1" role="button">
        Accept
      </a>
      <!-- Upload declaration -->
      <a download="{{context.visit.crn}} + '_security.xls'" *ngIf="downloadLink" href="{{downloadLink}}" class="dropdown-item" tabindex="-1">
        <i class="fas fa-file-excel"></i> Uploaded declaration
      </a>
      <!-- Messages -->
      <h6 class="dropdown-header" style="font-size: 18px;">Messages</h6>
      <a href="#" (click)="$event.preventDefault(); openPaMessages()" class="dropdown-item">
        SECURITY to Port Authority
      </a>
      <a href="#" (click)="$event.preventDefault(); openSwMessages()" class="dropdown-item">
        SEC to Singlewindow
      </a>
    </div>
  </div>
</ng-container>

<ng-template #rejectModal>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Reject reason</h5>
    </div>
    <div class="modal-body">
      <input id="rejectSecurity-reason" type="text" class="form-control">
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Back</button>
      <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
        (click)="reject(appContext.getDocument().getElementById('rejectSecurity-reason')['value'])">Send</button>
    </div>
  </div>
</ng-template>
