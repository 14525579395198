import {Component, Input} from '@angular/core';
import {DangerousGoodsModel, LoadingModel} from '../../dangerous-goods.model';
import {VisitContext} from '../../../visit-context';

@Component({
  selector: 'app-edit-loading',
  templateUrl: './edit-loading.component.html',
  styleUrls: ['./edit-loading.component.css']
})
export class EditLoadingComponent {
  get dangerousGoods() : DangerousGoodsModel {
    return <DangerousGoodsModel>VisitContext.dangerousGoodsDeclaration.dangerousGoods;
  }

  @Input() handling: LoadingModel;
}
