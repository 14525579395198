import {Observable} from 'rxjs';
import {WasteDeclaration, WasteItem} from '@portbase/bezoekschip-service-typescriptmodels';
import {
  ArrayTemplate,
  Cell,
  DateField,
  MappedField,
  NonNegativeQuantityField,
  parseExcel,
  RequiredField,
  ValidatedField,
  WorkBookTemplate
} from '../../common/upload/excel.utils';
import {VisitContext} from '../visit-context';
import {sendQuery} from '../../common/utils';
import {wasteDisplayItems} from './waste.categories';
import {tap} from 'rxjs/operators';
import {getWasteTypeSsn} from "./waste.type-ssn";

export function uploadWaste(file: File): Observable<WasteDeclaration> {
  return parseExcel(file, wasteTemplate);
}

const expectedItemCount = 25;

const wasteTemplate: WorkBookTemplate = {
  sheets: [
    {
      name: 'General',
      template: {
        version: new ValidatedField(new RequiredField('B3'),
          value => {
            if (value !== '2.0') {
              throw 'The version of your Excel file is not supported. Please download the latest template and try again.';
            }
          })
      }
    },
    {
      name: 'Verificatie',
      template: {
        verificationUuid: new ValidatedField(new RequiredField('A1'),
          value => {
            if (value !== '3fcdcb3d0e685f2886c129d8e7bb3a4c8cd8bd25') {
              throw 'Your Excel file could not be verified. Please download the latest template and try again.';
            }
          })
      }
    },
    {
      name: 'Waste',
      template: {
        imoNumber: new ValidatedField(new RequiredField('I2'),
          value => {
            const expectedImo = VisitContext.visit.vessel.imoCode;
            if (String(value) !== expectedImo) {
              throw 'The IMO code in the Excel file (' + value + ') does not match IMO code of the vessel (' + expectedImo + ').';
            }
          }),
        portOfLastDelivery: new MappedField('B4', (portUnCode, cell) => {
          return portUnCode ? sendQuery('com.portbase.bezoekschip.common.api.visit.GetPort',
            {portUnCode: portUnCode}).pipe(tap(v => {
            if (!v) {
              throw 'Cell ' + cell.cell + ' in sheet "' + cell.sheetName + '" contains an unknown port: ' + portUnCode;
            }
          })) : null;
        }),
        portOfNextDelivery: new MappedField('G4', (portUnCode, cell) => {
          return portUnCode ? sendQuery('com.portbase.bezoekschip.common.api.visit.GetPort',
            {portUnCode: portUnCode}).pipe(tap(v => {
            if (!v) {
              throw 'Cell ' + cell.cell + ' in sheet "' + cell.sheetName + '" contains an unknown port: ' + portUnCode;
            }
          })) : null;
        }),
        lastDeliveryDate: new DateField('B5'),
        wasteItems: new MappedField(new ArrayTemplate({
          ssn: new MappedField('B$', (ssnCode, cell) => {
            let ssn = getWasteTypeSsn(ssnCode);
            if (!ssn) {
              throw 'Cell ' + cell.cell + ' in sheet "' + cell.sheetName + '" contains an unknown ssnCode: ' + ssnCode
            }
            return ssn;
          }),
          specification: new MappedField('L$', (spec, cell) => [10, 28, 29, 30, 31, 33, 34].indexOf(cell.address.r) >= 0 ? spec : undefined),
          quantityToBeDelivered: new NonNegativeQuantityField('C$', 0),
          quantityToBeRetained: new NonNegativeQuantityField('E$', 0),
          maxDedicatedCapacity: new NonNegativeQuantityField('I$', 0),
          quantityToBeGenerated: new NonNegativeQuantityField('G$', 0),
          portForRetainedWaste: new MappedField('K$', (portUnCode, cell) => {
            return portUnCode ? sendQuery('com.portbase.bezoekschip.common.api.visit.GetPortOrWayPoint',
              {portUnCode: portUnCode}).pipe(tap(v => {
              if (!v) {
                throw 'Cell ' + cell.cell + ' in sheet "' + cell.sheetName + '\" contains an unknown port: ' + portUnCode;
              }
            })) : null;
          }),
        }, [9, 11], [13, 21], [23, 23], [25, 27], [29, 32], [34, 35], [37, 38], [40, 40]), (items: WasteItem[]) => {
          if (items.length !== expectedItemCount) {
            throw 'Incorrect number of waste items found on sheet: ' + items.length
            + ' (expected ' + expectedItemCount + '). Please redownload the template and fill out correctly!';
          }
          return items;
        })
      }
    }
  ]
};
