import {Component, Input} from '@angular/core';
import {GoodData} from "@portbase/bezoekschip-service-typescriptmodels";

@Component({
  selector: 'app-edit-dangerous-good',
  templateUrl: './edit-dangerous-good.component.html',
  styleUrls: ['./edit-dangerous-good.component.css']
})
export class EditDangerousGoodComponent {
  @Input() dangerousGood: GoodData;
  possibleTypes = ["IBC", "IMDG", "IGC", "MARPOLANNEX1", "IMSBC"];

}
