<ng-container [ngSwitch]="dependency">
  <!-- Show icon if ship's entry to first berth is dependant in some way on another ship -->
  <span *ngSwitchCase="'EXCHANGE'" class="mx-2"
    [title]="thisShipName + ' exchanges with ' + (otherShipName ? otherShipName : '...')">
    <span class="fa fa-fw fa-ship me-1"></span>
    <span class="fa fa-fw fa-exchange-alt me-1"></span>
    <span class="fa fa-fw fa-ship me-1"></span>
  </span>
  <span *ngSwitchCase="'ENTRY_AFTER'" class="mx-2"
    [title]="thisShipName + ' enters after ' + (otherShipName ? otherShipName : '...')">
    <span class="fa fa-fw fa-ship me-1"></span>
    <span class="fa fa-fw fa-long-arrow-alt-right me-1"></span>
    <span class="fa fa-fw fa-ship me-1"></span>
  </span>
</ng-container>
