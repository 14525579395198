import {Component, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {VisitContext} from './visit-context';
import {lodash, sendQuery, toWebsocketUrl} from '../common/utils';
import {AppContext} from '../app-context';
import {environment} from '../../environments/environment';
import {UiVisitUpdate, Visit, WasteDeclaration} from '@portbase/bezoekschip-service-typescriptmodels';
import {Alert} from "../common/status-alert/alert";

@Component({
  selector: 'app-visit-wrapper',
  templateUrl: './visit-details.component.html',
  styleUrls: ['./visit-details.component.css']
})
export class VisitDetailsComponent implements OnDestroy {
  crn: string;
  appContext = AppContext;
  context = VisitContext;
  socket: WebSocket;
  eventId: string;
  path: string;

  downAlert: Alert = {
    content: "The connection with the port authorities of Rotterdam and Amsterdam is down (possibly due to maintenance on their system). " +
      "Please be aware that movement orders will not be processed by the Port Authority. Please contact the Port" +
      " Authority.",
    level: "danger",
    type: "hamis"
  };
  upAlert: Alert = {
    content: "The connection with the Port Authority have been restored. ", level: "success",
    type: "hamis"
  };

  lowFreeboardPilotageAlert: Alert = {
    content: "Storm pilotage is active for incoming/departing vessels with low freeboard. " +
      "Extra pilotage information may be required. ", level: "info",
    type: "stormPilotage"
  };
  smallVesselsPilotageAlert: Alert = {
    content: "Storm pilotage is active for small incoming/departing vessels. " +
      "Extra pilotage information may be required. ", level: "info",
    type: "stormPilotage"
  };
  allVesselsPilotageAlert: Alert = {
    content: "Storm pilotage is active for all incoming/departing vessels." +
      " Extra pilotage information may be required. ", level: "info",
    type: "stormPilotage"
  };

  constructor(route: ActivatedRoute, router: Router) {
    this.path = router.url;
    router.events.subscribe((event) => {
      this.path = router.url;
    });

    route.params.subscribe(params => {
      route.queryParams.subscribe(queryParams => {
        this.crn = params['crn'];
        this.eventId = queryParams['eventId'];
        document.title = 'Port visit – ' + this.crn;
        if (this.eventId) {
          sendQuery("com.portbase.bezoekschip.common.api.history.GetHistoricalVisit", {
            crn: this.crn, eventId: this.eventId
          }, {caching: false, showSpinner: true})
            .subscribe((visit: Visit) => {
              if (visit) {
                visit.wasteDeclaration = this.prunedWasteDeclaration(visit.wasteDeclaration)
                VisitContext.replaceVisit(visit, this.eventId);
              } else {
                router.navigateByUrl("/");
              }
            });
        } else {
          sendQuery("com.portbase.bezoekschip.common.api.visit.GetVisit", {crn: params['crn']},
            {caching: false, showSpinner: true})
            .subscribe((visit: Visit) => {
              visit.wasteDeclaration = this.prunedWasteDeclaration(visit.wasteDeclaration)
              VisitContext.replaceVisit(visit);
              this.subscribeToVisitUpdates();
              if (AppContext.isCustoms()) {
                if (AppContext.getUrlPath() === "/details/" + this.crn + "/visit") {
                  router.navigateByUrl('/details/' + this.crn + '/cargoImportOverview');
                }
              } else if (VisitContext.isOrganisationOnlyCargoDeclarant() || VisitContext.isUserOnlyCargoDeclarant()) {
                if (visit.cargoImportEnabled && VisitContext.isUserCargoImportViewer()) {
                  router.navigateByUrl('/details/' + this.crn + '/cargoImport');
                } else if (visit.dangerousGoodsEnabled) {
                  router.navigateByUrl('/details/' + this.crn + '/dangerousGoods/' +
                    AppContext.userProfile.organisation?.shortName);
                }
              }
            });
        }
      });
    });
  }

  isWasteDeclarationValid(wasteDeclaration: WasteDeclaration): Boolean {
    return !wasteDeclaration.wasteItems.some(i => {
      return i.ssn == null;
    });
  }

  prunedWasteDeclaration(wasteDeclaration: WasteDeclaration): WasteDeclaration {
    if (!wasteDeclaration) {
      return null;
    }
    if (!this.isWasteDeclarationValid(wasteDeclaration)) {
      return null;
    }
    if (wasteDeclaration.wasteItems) {
      wasteDeclaration.wasteItems = wasteDeclaration.wasteItems
        .filter((v, i, a) => a.findIndex(v2 => (v2.ssn.code === v.ssn.code)) === i);
    }
    return wasteDeclaration;
  }

  get visit(): Visit {
    return this.context.savedVisit;
  }

  private subscribeToVisitUpdates = () => {
    if (environment.production || environment.docker) {
      try {
        this.socket = new WebSocket(toWebsocketUrl("/api/ui/" + this.crn));
      } catch (e) {
        console.info("Could not open websocket. Retrying every minute...", e);
        setTimeout(this.subscribeToVisitUpdates, 60_000);
        return;
      }
      this.socket.onmessage = (message: MessageEvent) => {
        if (typeof message.data === 'string') {
          const uiUpdate = JSON.parse(message.data);
          if (!!uiUpdate.crn) {
            const visitUpdate: UiVisitUpdate = uiUpdate;
            addDeclarationStatuses(visitUpdate);
            VisitContext.visit.terminalPlanningApproved = uiUpdate.terminalPlanningApproved;
            VisitContext.visit.berthVisitInfos = visitUpdate.berthVisitUpdates || {};
            VisitContext.visit.terminalVisits = visitUpdate.terminalVisits || {};
            VisitContext.visit.visitDeclaration.portVisit.atdPort = visitUpdate.atdPort;
            VisitContext.visit.visitDeclaration.portVisit.berthVisits.forEach(v => {
              const update = visitUpdate.berthVisitUpdates[v.id];
              if (update) {
                v.eta = update.eta;
                v.ata = update.ata;
                v.atd = update.atd;
                v.etd = update.etd;
                v.load = update.load;
                v.discharge = update.discharge;
                v.terminal = update.terminal;
                v.stevedore = update.stevedore;
                v.berth = update.berth;
                v.bollardFrom = update.bollardFrom;
                v.bollardTo = update.bollardTo;
                v.vesselServiceCode = update.vesselServiceCode;
                v.nextMovement.order = update.ordered;
                v.tugboatAtArrival = update.tugboatAtArrival;
                v.tugboatAtDeparture = update.tugboatAtDeparture;
              }
            });
            VisitContext.visit.visitDeclaration.portVisit.passages = visitUpdate.passages || [];
            if (visitUpdate.incomingMovementUpdate) {
              const update = visitUpdate.incomingMovementUpdate;
              VisitContext.visit.visitDeclaration.portVisit.pilotStation = update.pilotStation;
              VisitContext.visit.visitDeclaration.portVisit.portEntry = update.portEntry;
              VisitContext.visit.additionalIncomingMovementInfo = update.additionalIncomingMovementInfo;
              VisitContext.visit.incomingMovementHarbourMasterInfo = update.harbourMasterInfo;
              if (VisitContext.visit.visitDeclaration.portVisit.berthVisits.length > 0) {
                VisitContext.visit.visitDeclaration.portVisit.berthVisits[0].eta = update.etaBerth;
                VisitContext.visit.visitDeclaration.portVisit.berthVisits[0].requestedEta = update.requestedEtaBerth;
                VisitContext.visit.visitDeclaration.portVisit.berthVisits[0].tugboatAtArrival = update.tugboatAtArrival;
              } else {
                VisitContext.visit.visitDeclaration.portVisit.passingThroughTugboats = update.passingThroughTugboats;
              }
              VisitContext.visit.visitDeclaration.portVisit.firstMovement.order = update.ordered;
            }
            VisitContext.visit.incomingTidalWindowStatus = visitUpdate.incomingTidalWindowStatus;
            if (VisitContext.visit.healthDeclarationSummary) {
              VisitContext.visit.healthDeclarationSummary.healthDeclarationStatus = visitUpdate.healthDeclarationStatus;
              VisitContext.visit.healthDeclarationSummary.healthDeclarationRemarks = visitUpdate.healthDeclarationRemarks;
            }
            VisitContext.visit.clearances = visitUpdate.clearances;
            VisitContext.replaceVisit(VisitContext.visit);
          } else {
            if (VisitContext.savedVisit && VisitContext.savedVisit.orderNauticalServices) {
              if (uiUpdate.stormPilotage) {
                if (uiUpdate.portUnCode === "NLRTM" && VisitContext.visit.crn.startsWith("NLRTM")) {
                  AppContext.stormPilotageRotterdam = uiUpdate.stormPilotage;
                  AppContext.closeAlerts(this.smallVesselsPilotageAlert, this.allVesselsPilotageAlert);
                  if (uiUpdate.stormPilotage === "HALTED_FOR_SMALL" && this.context.visit.vessel.fullLength < 125) {
                    AppContext.addAlert(this.smallVesselsPilotageAlert);
                  } else if (uiUpdate.stormPilotage === "HALTED_FOR_ALL") {
                    AppContext.addAlert(this.allVesselsPilotageAlert);
                  }
                } else if (uiUpdate.portUnCode === "NLAMS" && VisitContext.visit.crn.startsWith("NLAMS")) {
                  AppContext.stormPilotageAmsterdam = uiUpdate.stormPilotage;
                  AppContext.closeAlerts(this.lowFreeboardPilotageAlert, this.smallVesselsPilotageAlert, this.allVesselsPilotageAlert);
                  if (uiUpdate.stormPilotage === "HALTED_FOR_LOW_FREEBOARD" && this.context.visit.vessel.fullLength < 145) {
                    AppContext.addAlert(this.lowFreeboardPilotageAlert);
                  } else if (uiUpdate.stormPilotage === "HALTED_FOR_SMALL" && this.context.visit.vessel.fullLength < 145) {
                    AppContext.addAlert(this.smallVesselsPilotageAlert);
                  } else if (uiUpdate.stormPilotage === "HALTED_FOR_ALL") {
                    AppContext.addAlert(this.allVesselsPilotageAlert);
                  }
                }
              } else if (AppContext.environment === 'pcs') {
                if (uiUpdate.hamisServiceDown) {
                  AppContext.addAlert(this.downAlert);
                  AppContext.closeAlerts(this.upAlert);
                } else {
                  AppContext.addAlert(this.upAlert);
                  AppContext.closeAlerts(this.downAlert);
                }
              }
            }
          }
        }
      };
      this.socket.onclose = (event: CloseEvent) => {
        if (!event.wasClean) {
          console.warn("Websocket closed with reason: " + event.reason + " (" + event.code + "). Trying to reconnect...");
        }
        setTimeout(() => this.subscribeToVisitUpdates(), 5_000);
      }
    }

    function addDeclarationStatuses(visitUpdate: UiVisitUpdate) {
      VisitContext.visit.declarations = visitUpdate.declarations;
      if (!lodash.isEmpty(visitUpdate.importDeclarations)) {
        lodash.entries(visitUpdate.importDeclarations).forEach(
          v => {
            const importDeclaration = VisitContext.visit.importDeclarations.find(d => d.cargoDeclarant.shortName === v[0]);
            if (importDeclaration) {
              importDeclaration.declarations = v[1];
            }
          });
      }
      if (!lodash.isEmpty(visitUpdate.movementReferenceNumbers)) {
        const consignments = lodash.keyBy(lodash.flatMap(VisitContext.visit.importDeclarations, d => d.consignments),
          c => c.consignmentNumber);
        lodash.entries(visitUpdate.movementReferenceNumbers).forEach(
          v => {
            const consignment = consignments[v[0]];
            if (consignment && !lodash.isEqual(consignment.movementReferenceNumbers, v[1])) {
              consignment.movementReferenceNumbers = v[1];
            }
          });
      }
    }
  };

  get isReadOnlyForUser(): boolean {
    return (VisitContext.isVisitReadonly() || (VisitContext.isOrganisationNextDeclarant() && !AppContext.dangerousGoodsContextActive()));
  }

  ngOnDestroy(): void {
    VisitContext.replaceVisit(null);
    document.title = 'Port visit';
    try {
      this.socket.onclose = () => {
      };
      this.socket.close();
      this.socket = null;
    } catch (ignored) {
    }
  }

}
