import {Component, Input, OnInit} from '@angular/core';
import {CollectorSummary, WasteCollection, WasteCollector} from '@portbase/bezoekschip-service-typescriptmodels';
import * as html2pdf from 'html2pdf.js';
import moment from 'moment';
import {AppContext} from "../../../app-context";
import {WasteCollectorUtils} from "../../waste-utils";

@Component({
  selector: 'app-waste-collection-pdf',
  templateUrl: './waste-collection-pdf.component.html',
  styleUrls: ['./waste-collection-pdf.component.css']
})
export class WasteCollectionPdfComponent implements OnInit {
  appContext = AppContext;
  utils = WasteCollectorUtils;
  @Input() collector: WasteCollector;
  @Input() summary: CollectorSummary;
  @Input() collection: WasteCollection;

  showIltCodes:boolean = false;

  ngOnInit() {
    this.showIltCodes = moment("2023-06-01T09:00:00Z").isBefore(moment(this.collection.startTime));
  }

  download() {
    let element = document.getElementById('printPdf');
    let opt = {
      image: {type: 'jpeg', quality: 0.98},
    };
    html2pdf().set(opt).from(element).save('waste_pickup_pdf_' + this.summary.crn + '.pdf');
  }

  toDate = (dateString: string): string => !!dateString && moment(dateString).format('DD/MM/YYYY');
  toTime = (dateString: string): string => !!dateString && moment(dateString).format('HH:mm');

  getRemarks() {
    return this.collection.remarks;
  }

  formatBerth = (id) => {
    const berthVisit = this.summary.berthVisits.filter(v => v.id === id)[0];
    return berthVisit && berthVisit.code + '-' + berthVisit.name;
  };

  formatSformNumber = (collectorNumber: string, fileNumber: string): string =>
    WasteCollectorUtils.formatSformNumber(collectorNumber, fileNumber)

}
