import {Component, Input} from '@angular/core';
import {
  IE3GetIsoAndUneceSizeType,
  IE3IsoAndUneceSizeType,
  SizeType
} from "@portbase/bezoekschip-service-typescriptmodels";
import {sendQuery} from "../../common/utils";

@Component({
  selector: 'app-edit-sizetype',
  templateUrl: './edit-sizetype.component.html',
  styleUrls: ['./edit-sizetype.component.css']
})
export class EditSizeTypeComponent {
  @Input() sizetype : SizeType;

  updateIsoCode = (code: string) => {
    if (code) {
      if (code.length === 4) {
        sendQuery("com.portbase.bezoekschip.common.api.consignments.queries.GetIsoAndUneceSizeType", <IE3GetIsoAndUneceSizeType>{
          sizeTypeCode: code
        }).subscribe((result: IE3IsoAndUneceSizeType) => {
          this.sizetype.isoCode = result.isoCode;
          this.sizetype.uneceSizeType = result.uneceSizeType;
        });
      }
    } else {
      this.sizetype.isoCode = null;
      this.sizetype.uneceSizeType = null;
    }
  };
}
