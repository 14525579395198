import {Component, Input} from '@angular/core';
import {BezoekschipOrganisation, FindOrganisations, Terminal} from '@portbase/bezoekschip-service-typescriptmodels';
import {sendQuery} from '../../common/utils';
import {map} from "rxjs/operators";
import {PortvisitUtils} from '../portvisit-utils';

@Component({
  selector: 'app-edit-terminal',
  templateUrl: './edit-terminal.component.html',
  styleUrls: ['./edit-terminal.component.css']
})
export class EditTerminalComponent {
  utils = PortvisitUtils;
  @Input() terminal: Terminal;
  portsOfCall = sendQuery('com.portbase.bezoekschip.common.api.visit.GetPcsPorts', {}).pipe(map(ports => ports.map(p => p.port.locationUnCode)));

  searchOrganisation = term => sendQuery('com.portbase.bezoekschip.common.api.authorisation.FindOrganisations',
    <FindOrganisations>{term: term});

  setOrganisation = (organisation: BezoekschipOrganisation) => {
    if (!organisation) {
      this.terminal.organisationName = null;
      this.terminal.organisationShortName = null;
    } else {
      this.terminal.organisationName = organisation.fullName;
      this.terminal.organisationShortName = organisation.shortName;
    }
  };
}
