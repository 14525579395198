import {Component, Input} from '@angular/core';
import {BunkerLicense} from "@portbase/bezoekschip-service-typescriptmodels";

@Component({
  selector: 'app-edit-bunker-license',
  templateUrl: './edit-bunker-license.component.html',
  styleUrls: ['./edit-bunker-license.component.css']
})
export class EditBunkerLicenseComponent {
  @Input() bunkerLicense: BunkerLicense;

  get license() {
    if (!this.bunkerLicense.bunkerVessel) {
      this.bunkerLicense.bunkerVessel = {};
    }
    return this.bunkerLicense;
  }

}
