<div class="d-block mb-3">
  <span class="fw-regular" [ngClass]="required && editMode ? 'required' : ''">{{label}}</span>
  <span *ngIf="!required && editMode" class="form-field-optional text-secondary ms-1">(optional)</span>
</div>
<ng-container *ngIf="carrier || editMode else noParty">
  <div *ngIf="editMode" class="row mb-3">
    <div class="col-6">
      <app-search required [(ngModel)]="carrier" (ngModelChange)="onCarrierChange($event)"
                  [searchFunction]="utils.findCarriers" [resultFormatter]="utils.carrierFormatter"
                  [inputFormatter]="utils.carrierFormatter">
      </app-search>
    </div>
  </div>
  <div *ngIf="carrier" class="card form-group">
    <div class="card-body p-4">
      <div class="row gy-3 mb-3">
        <div class="col-12 col-lg-4 d-flex flex-column align-items-stretch justify-content-center">
          <app-form-field label="Name" [includeMargin]="false" [(value)]="carrier.name" [editMode]="editMode && carrierEditMode">
            <input class="input-group rounded form-control" [(ngModel)]="carrier.name" required/>
          </app-form-field>
        </div>
        <div class="col-12 col-lg-4 d-flex flex-column align-items-stretch justify-content-center">
          <app-form-field label="Country" [includeMargin]="false" [(value)]="carrier.address.country"
                          [editMode]="editMode && carrierEditMode" [formatter]="countryFormatter">
            <app-search required [(ngModel)]="carrier.address.country" [searchFunction]="portVisitUtils.findCountries"
                        [resultFormatter]="portVisitUtils.countryFormatter" [inputFormatter]="countryFormatter"
                        (ngModelChange)="onCountryChange($event)">
            </app-search>
          </app-form-field>
        </div>
      </div>
      <div class="row gy-3 mb-3">
        <div class="col-12 col-lg-4 d-flex flex-column align-items-stretch justify-content-center">
          <app-form-field label="Street" [includeMargin]="false" [(value)]="carrier.address.street" [editMode]="editMode && carrierEditMode">
            <input class="input-group rounded form-control" [(ngModel)]="carrier.address.street"/>
          </app-form-field>
        </div>
        <div class="col-12 col-lg-4 d-flex flex-column align-items-stretch justify-content-center">
          <app-form-field label="Number" [includeMargin]="false" [(value)]="carrier.address.number" [editMode]="editMode && carrierEditMode">
            <input class="input-group rounded form-control" [(ngModel)]="carrier.address.number"/>
          </app-form-field>
        </div>
        <div class="col-12 col-lg-4 d-flex flex-column align-items-stretch justify-content-center">
          <app-form-field label="Post office box" [includeMargin]="false" [(value)]="carrier.address.poBox" [editMode]="editMode && carrierEditMode">
            <input class="input-group rounded form-control" [(ngModel)]="carrier.address.poBox"/>
          </app-form-field>
        </div>
      </div>
      <div class="row gy-3 mb-3">
        <div class="col-12 col-lg-4 d-flex flex-column align-items-stretch justify-content-center">
          <app-form-field label="City" [includeMargin]="false" [(value)]="carrier.address.city" [editMode]="editMode && carrierEditMode">
            <input class="input-group rounded form-control" [(ngModel)]="carrier.address.city" required/>
          </app-form-field>
        </div>
        <div class="col-12 col-lg-4 d-flex flex-column align-items-stretch justify-content-center">
          <app-form-field label="Postal code" [includeMargin]="false" [(value)]="carrier.address.postCode" [editMode]="editMode && carrierEditMode">
            <input class="input-group rounded form-control" [(ngModel)]="carrier.address.postCode"/>
          </app-form-field>
        </div>
        <div class="col-12 col-lg-4 d-flex flex-column align-items-stretch justify-content-center">
          <app-form-field label="Telephone" [includeMargin]="false" [(value)]="telephone.identifier" [editMode]="editMode && carrierEditMode">
            <input class="input-group rounded form-control" [(ngModel)]="telephone.identifier"/>
          </app-form-field>
        </div>
      </div>
      <div class="row gy-3">
        <div class="col-12 col-lg-6 d-flex flex-column align-items-stretch justify-content-center">
          <app-form-field label="E-mail address" [includeMargin]="false" [(value)]="email.identifier" [editMode]="editMode && carrierEditMode">
            <input class="input-group rounded form-control" [(ngModel)]="email.identifier"/>
          </app-form-field>
        </div>
        <div class="col-12 col-lg-6 d-flex flex-column align-items-stretch justify-content-center">
          <app-form-field label="Identification number" [includeMargin]="false" [(value)]="carrier.customsId"
                          [editMode]="editMode && carrierEditMode" tooltip="Fill with EORI number of the party">
            <input class="input-group rounded form-control" [(ngModel)]="carrier.customsId"/>
          </app-form-field>
        </div>
      </div>
      <div *ngIf="editMode && carrierEditMode" class="row mt-3">
        <div class="col-auto ms-auto">
          <button type="button" class="btn btn-outline-secondary" aria-label="Cancel" (click)="cancel()">Cancel</button>
        </div>
        <div class="col-auto">
          <button type="button" class="btn btn-primary" (click)="save()">Save</button>
        </div>
      </div>
      <button *ngIf="editMode && !carrierEditMode" type="button" class="btn btn-tertiary position-absolute end-0 top-0 me-2 mt-2 p-1"
              (click)="toggleEditMode()">
        <i class="fa fa-fw fa-light fa-pencil"></i>
      </button>
    </div>
  </div>
</ng-container>
<ng-template #noParty>
  <span class="d-block mb-3">N/A</span>
</ng-template>
