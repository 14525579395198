import {AfterViewInit, Component, ElementRef, Input, OnDestroy} from '@angular/core';

@Component({
  selector: 'app-form-group',
  templateUrl: './form-group.component.html',
  styleUrls: ['./form-group.component.css']
})
export class FormGroupComponent implements AfterViewInit, OnDestroy {

  @Input() omitVerticalPadding: boolean;
  @Input() label: String;
  @Input() disableInvalidFeedback: boolean = false;
  private observer: MutationObserver;
  private el: Element;
  private labelElement: Element;

  constructor(public element: ElementRef) {
    this.observer = new MutationObserver(this.requiredChangedObserver);
  }

  ngAfterViewInit(): void {
    this.el = this.element.nativeElement.querySelector('textarea, app-date-field, app-radio, app-multiselect, input, select');
    if (this.el) {
      this.labelElement = this.element.nativeElement.querySelector('label');
      if (this.el.hasAttribute('required')) {
        this.labelElement.classList.add('required');
      }
      if (!this.disableInvalidFeedback && !this.element.nativeElement.querySelector('.invalid-feedback')){
				const feedbackElement = document.createElement('div');
        this.el.parentElement.appendChild(feedbackElement);
				feedbackElement.outerHTML = '<div class="invalid-feedback">Please select ' + (this.labelElement ? renderLabel(this.labelElement.innerHTML) : 'a value') + '</div>';
			}
      this.observer.observe(this.el, { attributes: true });
    }
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
  }

  requiredChangedObserver = (mutationsList) => {
    for (const mutation of mutationsList) {
      if (mutation.type !== "attributes" || mutation.attributeName !== "required") {
        continue;
      }
      const required = mutation.target.getAttribute("required") != null;
      if (this.labelElement) {
        if (required) {
          this.labelElement.classList.add('required');
        } else {
          this.labelElement.classList.remove('required');
        }
      }
    }
  }
}

function renderLabel(name: string): string {
  name = name.toLowerCase();
  switch (name.charAt(0)) {
    case 'a':
    case 'e':
    case 'i':
    case 'o':
    case 'u':
      return 'an ' + name;
  }
  return 'a ' + name;
}
