<div class="modal-dialog modal-xl" role="document" *ngIf="selectedContainers.length === 0">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Select containers to declare</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
      </button>
    </div>
    <div class="modal-body">
      <div class="row narrow-gutters always-enabled mb-3">
        <div class="col">
          <app-tags #searchField [(ngModel)]="searchTags"
                    (ngModelChange)="searchFunction()"
                    (onInput)="onInputSearchTerm($event)"
                    (paste)="onPasteSearchTerm($event)"
                    placeholder="Paste your excel container numbers..."
                    style="max-height: 200px; overflow-y: auto; overflow-x: hidden; display: block;"></app-tags>
        </div>
      </div>

      <div *ngIf="containers.length < searchTags.length" class="row narrow-gutters mb-3">
        <div class="col-12">
          {{ searchTags.length - containers.length }} container
          <ng-container *ngIf="searchTags.length - containers.length > 1">s</ng-container>
          couldn't be found
        </div>
      </div>

      <div *ngIf="containers.length > 0" class="row px-3 mb-2 bg-light align-items-center cursor-pointer">
        <div class="col-auto me-2 invisible">
          <div class="form-check form-check-inline m-0" style="height: 2rem">
            <input class="form-check-input m-0" type="checkbox"></div>
        </div>
        <div class="col-2">
          Consignment
        </div>
        <div class="col-2">
          Container
        </div>
        <div class="col-2">
          Vesselcall
        </div>
        <div class="col">
          Loaded
        </div>
        <div class="col">
          Terminal
        </div>
        <div class="col">
          Destination
        </div>
        <div class="col-1">
          Delivery
        </div>
        <div class="col">
          Customs
        </div>
      </div>

      <app-pagination #pagination [items]="containers" [maxPerPage]="5">
        <div *ngFor="let entity of pagination.getItemsOnPage(); trackBy : trackByContainerNumber; let index = index">
          <div class="row py-1 px-3 align-items-center text-dark border border-light">
            <div class="col-auto me-2">
              <div class="form-check form-check-inline m-0 always-enabled" style="height: 2rem; width: 13px">
                <input *ngIf="isSelectableContainer(entity)"
                       [(ngModel)]="entity['selected']" class="always-enabled form-check-input m-0" type="checkbox">
              </div>
            </div>
            <div class="col-2">
              {{ entity.consignmentNumber }}
            </div>
            <div class="col-2">
              {{ entity.containerNumber }}
            </div>
            <div class="col-2">
              {{ entity.crn }}
            </div>
            <div class="col">
              {{ entity.consignmentProcessSummaries[0]?.masterConsignment.placeOfLoading?.locationUnCode }}
            </div>
            <div class="col">
              {{ entity.consignmentProcessSummaries[0]?.masterConsignment.dischargeTerminal?.organisationShortName }}
            </div>
            <div class="col">
              {{ entity.consignmentProcessSummaries[0]?.masterConsignment.placeOfDelivery?.locationUnCode }}
            </div>
            <div class="col-1">
              {{ entity.consignmentProcessSummaries[0]?.masterConsignment.placeOfDelivery?.name | titlecase }}
            </div>
            <div class="col text-center">
              <ng-container *ngIf="entity.consignmentProcessSummaries[0]?.masterConsignment.customsProcess
              || entity.consignmentProcessSummaries[0]?.masterConsignment.customsStatus">
                <app-tooltip>
                  <i class="fa-solid fa-triangle-exclamation text-warning"></i>
                  <div class="d-flex flex-column flex-fixed-width-item">
                    <span *ngIf="entity.consignmentProcessSummaries[0]?.masterConsignment.customsProcess"
                          class="text-nowrap">{{
                        refData.customsProcessFormatter(
                          entity.consignmentProcessSummaries[0]?.masterConsignment.customsProcess)
                      }}</span>
                    <span *ngIf="entity.consignmentProcessSummaries[0]?.masterConsignment.customsStatus"
                          class="text-nowrap">Customs status: {{
                        refData.customsStatusFormatter(
                          entity.consignmentProcessSummaries[0]?.masterConsignment.customsStatus)
                      }}</span>
                  </div>
                </app-tooltip>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="row g-0 text-secondary">
          <div class="col-auto" *ngIf="selectableContainers().length > 1">
            <label class="mt-3">
              <input [(ngModel)]="selectableContainers().length === selected().length"
                     (click)="toggleSelectAll()" class="always-enabled" type="checkbox">
              <span class="ms-2">Select all</span>
            </label>
          </div>
        </div>
      </app-pagination>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary me-auto" (click)="reset()" data-bs-dismiss="modal">Cancel</button>
      <button type="button" class="btn btn-primary" *ngIf="selected().length > 0" (click)="selectContainers()">Continue
        with {{ selected().length }} declaration{{ selected().length === 1 ? '' : 's' }}
        <app-tooltip *ngIf="hasSelectedConsignmentWithWarning()">
          <i class="fa-solid fa-triangle-exclamation text-warning"></i>
          <span class="tooltipContent">
            You have selected at least one declaration with customs warnings
          </span>
        </app-tooltip>
      </button>
    </div>
  </div>
</div>

<app-batch-transit-declaration *ngIf="selectedContainers.length > 0" [items]="selectedContainers">
</app-batch-transit-declaration>
