import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {VisitContext} from "../../visit-context";
import {Carrier, Voyage} from "@portbase/bezoekschip-service-typescriptmodels";
import {sendQuery} from "../../../common/utils";

@Component({
  selector: 'app-departure-voyage',
  templateUrl: './departure-voyage.component.html',
  styleUrls: ['./departure-voyage.component.css']
})
export class DepartureVoyageComponent implements OnInit {
  context = VisitContext;

  @Input() voyage: Voyage;
  @Input() showCarrier: boolean = true;

  @Output('voyageChange')
  voyageUpdate = new EventEmitter();


  ngOnInit(): void {
    this.voyage = this.voyage === null ? <Voyage>{voyageNumber:''} : this.voyage;
  }

  carrierFormatter = (carrier: Carrier) => carrier && carrier.name
    ? carrier.name + (carrier.customsId ? ' - ' + carrier.customsId : '') : '';

  searchCarrier = term => sendQuery("com.portbase.bezoekschip.common.api.visit.FindCarriers",
    {withCustomsId: true, term: term});

  outboundVoyageNumberRequired() {
    const multiBerthVisitIds = Array.from(this.context.multiBerthLoadDischargeInfo.keys());
    return this.context.visit.visitDeclaration.portVisit.berthVisits
        .filter(bv => !multiBerthVisitIds.includes(bv.id)).some(bv => bv.load > 0)
      || Array.from(this.context.multiBerthLoadDischargeInfo.values())
        .some(l => l.some(i => i.load > 0));
  }

  departureCarrierRequired(): boolean {
  	if (this.context.isOrganisationNextDeclarant()) {
  		return false;
		}
    const berthVisits = VisitContext.visit.visitDeclaration.portVisit.berthVisits;
    return berthVisits.length > 0 ? !!berthVisits[berthVisits.length - 1].etd : false;
  }

  onVoyageNumberChange(event: any) {
    this.voyage.voyageNumber = event;
    this.voyageUpdate.emit(this.voyage);
  }

  onVoyageCarrierChange(event: any) {
    this.voyage.carrier = event;
    this.voyageUpdate.emit(this.voyage);
  }

  onCarrierCustomsIdChanged(event: any) {
    this.voyage.carrier = event ? <Carrier>{customsId : event} : null;
    this.voyageUpdate.emit(this.voyage);
  }
}
