<div [ngClass]="{'always-enabled': isAtdModifiable()}">
  <app-form-group *ngIf="isAtdModifiable()">
    <label for="atdPort">ATD Port</label>
    <app-date-field [(ngModel)]="context.visit.visitDeclaration.portVisit.atdPort"
                   [disabled]="!isAtdModifiable()"
                   id="atdPort"
                   (focus)="isAtdModifiable() ? withModal('show') : null"></app-date-field>
  </app-form-group>
  <div *ngIf="isAtdModifiable()" id="registerAtdPortModal" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Register ATD Port</h5>
        </div>
        <div class="modal-body">
          <app-date-field [(ngModel)]="atdPort"
                         [required]="modalOpen"
                         [calendarStartDate]="context.visit.visitDeclaration.portVisit.etdPort"
                         id="register-atd-port"></app-date-field>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                  (click)="atdPort = context.visit.visitDeclaration.portVisit.ataPort; modalOpen = false">Cancel
          </button>
          <button type="button" class="btn btn-danger" (click)="removeAtdPort()">Remove ATD Port</button>
          <button type="button" class="btn btn-primary" (click)="registerAtdPort()">Register ATD Port</button>

        </div>
      </div>
    </div>
  </div>
</div>

