<div class="fieldset" [ngClass]="{'disabled' : context.isUserOnlyCargoDeclarant()}">

  <div class="row sticky-top bg-white" style="top:6.125rem">
    <div class="col-md-12">
      <app-alerting></app-alerting>
    </div>
    <div class="col-md-12">
      <app-action-nav-bar></app-action-nav-bar>
    </div>
  </div>

  <div class="row">
    <!--Cargo agents-->
    <div class="declaration-block col-md-12" [ngClass]="{'always-enabled': isAllowedToAddCargoAgents}">
      <h2>Cargo handling agents</h2>
      <div class="row g-0 bg-secondary text-light py-2">
        <div class="col required"><span class="ps-2">Name</span></div>
        <div class="col-3 d-none d-xl-block">Address</div>
        <div class="col-3 d-none d-lg-block">City</div>
        <div class="col-2 d-none d-md-block">Country</div>
        <div class="col-auto" style="min-width: 16px"></div>
      </div>

      <div class="row g-0 my-2 mx-0"
           *ngFor="let cargoDeclarant of context.visit.cargoDeclarants; trackBy : trackByIndex; let idx = index;">
        <div class="col pe-3">
          <app-search [(ngModel)]="context.visit.cargoDeclarants[idx]" [searchFunction]="searchCargoDeclarant"
                      placeholder="Find organisation" dataKey="fullName">
          </app-search>
        </div>
        <div class="col-3 d-none d-xl-block">
          <div class="form-control border-0">{{cargoDeclarant?.address}}</div>
        </div>
        <div class="col-3 d-none d-lg-block">
          <div class="form-control border-0">{{cargoDeclarant?.city}}</div>
        </div>
        <div class="col-2 d-none d-md-block">
          <div class="form-control border-0">{{cargoDeclarant?.countryName}}</div>
        </div>
        <div class="col-auto text-end px-0" style="min-width: 16px">
          <button class="btn px-0" type="button" title="Delete cargo declarant"
                  (click)="deleteCargoDeclarant(cargoDeclarant)"
                  tabindex="-1"><span class="fa fa-trash text-secondary"></span>
          </button>
        </div>
      </div>

      <div class="row justify-content-between my-3">
        <div class="col-auto">
          <button (click)="saveCargoDeclarants()" [disabled]="disableUpdateCargoDeclarants"
                  id="updateCargoDeclarants" type="button" class="btn btn-info"
                  style="width: 8rem">Save
          </button>
        </div>
        <div class="col-auto" [ngClass]="{'always-enabled': isAllowedToAddCargoAgents}">
          <button (click)="addCargoDeclarant()" [disabled]="!isAllowedToAddCargoAgents" type="button"
                  class="btn btn-info"
                  style="width: 8rem">Add agent
          </button>
        </div>
      </div>


    </div>

    <!-- Only show current ship operator to admin users -->
    <div class="declaration-block col-md-12">
      <h2>Ship operator</h2>

      <div class="row g-0 bg-secondary text-light py-2">
        <div class="col"><span class="ps-2">Name</span></div>
        <div class="col-3 d-none d-xl-block">Address</div>
        <div class="col-3 d-none d-lg-block">City</div>
        <div class="col-2 d-none d-md-block">Country</div>
        <div class="col-auto" style="min-width: 16px"></div>
      </div>

      <div class="row g-0 my-2 mx-0">
        <div class="col pe-3 border-0 d-none d-xl-block ms-2">{{context.visit.owner?.fullName}}</div>
        <div class="col-3 border-0 d-none d-xl-block">{{context.visit.owner?.address}}</div>
        <div class="col-3 border-0 d-none d-lg-block">{{context.visit.owner?.city}}</div>
        <div class="col-2 border-0 d-none d-md-block">{{context.visit.owner?.countryName}}</div>
        <div class="col-auto text-end px-0" style="min-width: 16px"></div>
      </div>
    </div>

    <!-- Back office -->
    <div *ngIf="context.hasBackOffice()" class="declaration-block col-md-12">
      <h2>BackOffice</h2>
      <div class="row g-0 bg-secondary text-light py-2">
        <div class="col required"><span class="ps-2">Name</span></div>
        <div class="col-3 d-none d-xl-block">Address</div>
        <div class="col-3 d-none d-lg-block">City</div>
        <div class="col-2 d-none d-md-block">Country</div>
        <div class="col-auto" style="min-width: 16px"></div>
      </div>
      <div class="row g-0 my-2 mx-0">
        <div class="col pe-3 d-none d-xl-block ms-2">
          <div class="form-control border-0">{{context.visit.declarant?.fullName}}</div>
        </div>
        <div class="col-3 d-none d-xl-block ms-2">
          <div class="form-control border-0">{{context.visit.declarant?.address}}</div>
        </div>
        <div class="col-3 d-none d-lg-block ms-2">
          <div class="form-control border-0">{{context.visit.declarant?.city}}</div>
        </div>
        <div class="col-2 d-none d-md-block ms-2">
          <div class="form-control border-0">{{context.visit.declarant?.countryName}}</div>
        </div>
        <div class="col-auto text-end px-0" style="min-width: 16px"></div>
      </div>
    </div>

    <!-- Next Back office -->
    <div *ngIf="context.hasNextBackOffice()" class="declaration-block col-md-12">
      <h2>Next BackOffice</h2>
      <div class="row g-0 bg-secondary text-light py-2">
        <div class="col required"><span class="ps-2">Name</span></div>
        <div class="col-3 d-none d-xl-block">Address</div>
        <div class="col-3 d-none d-lg-block">City</div>
        <div class="col-2 d-none d-md-block">Country</div>
        <div class="col-auto" style="min-width: 16px"></div>
      </div>
      <div class="row g-0 my-2 mx-0">
        <div class="col pe-3 d-none d-xl-block ms-2">
          <div class="form-control border-0">{{context.visit.nextDeclarant?.fullName}}</div>
        </div>
        <div class="col-3 d-none d-xl-block">
          <div class="form-control border-0">{{context.visit.nextDeclarant?.address}}</div>
        </div>
        <div class="col-3 d-none d-lg-block">
          <div class="form-control border-0">{{context.visit.nextDeclarant?.city}}</div>
        </div>
        <div class="col-2 d-none d-md-block">
          <div class="form-control border-0">{{context.visit.nextDeclarant?.countryName}}</div>
        </div>
        <div class="col-auto text-end px-0" style="min-width: 16px"></div>
      </div>
    </div>

    <!--Additional viewers-->
    <div class="declaration-block col-md-12"
         [ngClass]="{'always-enabled': context.organisationMayUpdateVisit() && !context.isUserOnlyCargoDeclarant() && !context.savedVisit.cancelled}">
      <h2>Additional visit viewers</h2>
      <div class="row g-0 bg-secondary text-light py-2">
        <div class="col required"><span class="ps-2">Name</span></div>
        <div class="col-3 d-none d-xl-block">Address</div>
        <div class="col-3 d-none d-lg-block">City</div>
        <div class="col-2 d-none d-md-block">Country</div>
        <div class="col-auto" style="min-width: 16px"></div>
      </div>
      <div class="row g-0 my-2 mx-0"
           *ngFor="let organisation of context.visit.additionalViewers; trackBy : trackByIndex; let idx = index;">
        <div class="col pe-3">
          <app-search [(ngModel)]="context.visit.additionalViewers[idx]" [searchFunction]="searchCargoDeclarant"
                      placeholder="Find organisation" dataKey="fullName">
          </app-search>
        </div>
        <div class="col-3 d-none d-xl-block">
          <div class="form-control border-0">{{organisation?.address}}</div>
        </div>
        <div class="col-3 d-none d-lg-block">
          <div class="form-control border-0">{{organisation?.city}}</div>
        </div>
        <div class="col-2 d-none d-md-block">
          <div class="form-control border-0">{{organisation?.countryName}}</div>
        </div>
        <div class="col-auto text-end px-0" style="min-width: 16px">
          <button class="btn px-0" type="button" title="Delete additional viewer"
                  (click)="deleteAdditionalViewer(organisation)"
                  tabindex="-1"><span class="fa fa-trash text-secondary"></span>
          </button>
        </div>
      </div>
      <div class="row justify-content-between my-3">
        <div class="col-auto">
          <button (click)="updateAdditionalViewers()" [disabled]="disableUpdateAdditionalViewers"
                  type="button" class="btn btn-info" style="width: 8rem">Save
          </button>
        </div>
        <div class="col-auto">
          <button (click)="addAdditionalViewer()" type="button" class="btn btn-info" style="width: 8rem">Add viewer
          </button>
        </div>
      </div>
    </div>

  </div>
</div>
