<div>
  <div class="row narrow-gutters align-items-center">
    <div class="col">
      <div class="collapsible border border-light" style="border-width: 2px!important; margin-top: -2px"
           [ngClass]="{'ng-invalid border-invalid' : validationModel && validationModel['ngInvalid'], 'rounded' : rounded}">
        <div class="row g-0 align-items-center">
          <div class="col">
            <ng-content></ng-content>
          </div>
          <div class="col-auto" *ngIf="!hideCollapseIcon && collapse">
            <div class="text-end pe-2 d-none d-md-block" [ngStyle]="{'width.rem' : deleteHandle && showing && !collapsing ? 2 : 4}">
              <h4 class="m-0 align-top fa fa-chevron-right text-secondary"></h4>
            </div>
            <div class="text-end pe-2 d-md-none" [ngStyle]="{'width.rem' : deleteHandle && showing && !collapsing ? 2 : 2}">
              <h4 class="m-0 align-top fa fa-chevron-right text-secondary"></h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-auto" style="width: 2rem" *ngIf="(deleteHandle || deleteButton) && showing && !collapsing">
      <ng-container *ngIf="deleteButton; else simpleButton">
        <ng-container *ngTemplateOutlet="deleteButton"></ng-container>
      </ng-container>
      <ng-template #simpleButton>
        <button class="mx-2 btn p-0" type="button" title="Delete item" (click)="deleteCollapse()" tabindex="-1">
          <span class="fa fa-trash text-secondary"></span>
        </button>
      </ng-template>
    </div>
  </div>

  <!--if rendering is slow make sure you set the collapse input-->
  <div *ngIf="(renderWhenCollapsed || showing) && collapse" [appValidator]="validationModel">
    <ng-container *ngTemplateOutlet="collapse"></ng-container>
  </div>
</div>
