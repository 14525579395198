import {Component, Input} from '@angular/core';
import {ContainerGoodData} from "@portbase/bezoekschip-service-typescriptmodels";
import {PortvisitUtils} from "../../portvisit-utils";

@Component({
  selector: 'app-edit-container-good',
  templateUrl: './edit-container-good.component.html',
  styleUrls: ['./edit-container-good.component.css']
})
export class EditContainerGoodComponent {
  context = PortvisitUtils;

  @Input() containerGood: ContainerGoodData;
}
