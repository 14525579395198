import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-estimated-actual-field',
  templateUrl: './estimated-actual-field.component.html',
  styleUrls: ['./estimated-actual-field.component.scss']
})
export class EstimatedActualFieldComponent implements OnInit {
  @Input() label: string;
  @Input() estimate: string;
  @Input() actual: string;
  @Input() editMode: boolean;
  selectedOption: Options;
  allOptions: Options[] = [Options.Estimated, Options.Actual];

  @Output() estimateChange: EventEmitter<string> = new EventEmitter();
  @Output() actualChange: EventEmitter<string> = new EventEmitter();

  ngOnInit() {
    this.selectedOption = this.actual ? Options.Actual : Options.Estimated;
  }

  get value() {
    return this.selectedOption === Options.Actual ? this.actual : this.estimate;
  }

  set value(value: string) {
    if (this.selectedOption === Options.Actual) {
      this.actual = value;
      this.actualChange.emit(this.actual);
    } else {
      this.estimate = value;
      this.estimateChange.emit(this.estimate);
    }
  }
}

enum Options {
  Estimated = "Estimated",
  Actual = "Actual"
}
