import {Component, ElementRef, Input, NgZone, QueryList, ViewChildren} from '@angular/core';
import {VisitContext} from '../../visit-context';
import {AppContext} from '../../../app-context';
import {
  Consignment,
  DeclarationStatus,
  DeclarationType,
  DischargeResult,
  ImportContainer,
  Port,
  Visit
} from '@portbase/bezoekschip-service-typescriptmodels';
import {Router} from '@angular/router';
import {
  checkValidity,
  cloneObject,
  filterByTerm,
  isEqualJson,
  lodash,
  removeItem,
  sendCommand,
  sendQuery
} from '../../../common/utils';
import {PortvisitUtils} from '../../../refdata/portvisit-utils';
import {CollapseComponent} from '../../../common/collapse/collapse.component';
import {
  CargoImportModel,
  ConsignmentModel,
  GoodsItemModel,
  ImportContainerModel,
  Manifest
} from '../cargo-import.model';
import {DeclarationUtils} from "../../../refdata/declaration-utils";
import {CargoFilter, CargoSelection} from './import-filter/cargo-filter.component';

@Component({
  selector: 'app-import-declaration',
  templateUrl: './import-declaration.component.html',
  styleUrls: ['./import-declaration.component.css']
})
export class ImportDeclarationComponent {
  context = VisitContext;
  appContext = AppContext;
  removeItem = removeItem;
  utils = PortvisitUtils;
  keysToIgnore = ['selected', 'declared', 'hidden', 'ngInvalid', 'forceVisible', 'ngDetailsInvalid', 'inspections'];

  @ViewChildren('manifestCollapses', {read: CollapseComponent}) manifestElements!: QueryList<CollapseComponent>;

  filteredManifests: Manifest[] = [];
  @Input() filterTerm: string;
  private cargoFilter: CargoFilter = {deselectedOptions: [], selectedOptions: []};

  constructor(private router: Router, private elementRef: ElementRef, private zone: NgZone) {
  }

  saveCargoImport() {
    this.doDeclareCargoImport("com.portbase.bezoekschip.common.api.cargo.SaveCargoImport");
  }

  declareCargoImport() {
    this.doDeclareCargoImport("com.portbase.bezoekschip.common.api.cargo.DeclareCargoImport");
  }

  private doDeclareCargoImport(command: string) {
    if (checkValidity(this.elementRef)) {
      const consignments: Consignment[] = lodash.flatMap(VisitContext.cargoImportModel.manifests, m => m.consignments)
      const containers: ImportContainer[] = lodash.flatMap(VisitContext.cargoImportModel.manifests, m => m.containers)

      let payload = {
        crn: VisitContext.savedVisit.crn,
        cargoDeclarantShortName: VisitContext.cargoImportModel.cargoDeclarant.shortName,
        consignments: consignments.filter(c => !isEqualJson(c, this.context.savedImportDeclaration.consignments
          .find(or => or.consignmentNumber === c.consignmentNumber), this.keysToIgnore)),
        containers: containers.filter(c => !isEqualJson(c, this.context.savedImportDeclaration.containers
          .find(or => or.number === c.number), this.keysToIgnore)),
        deletedConsignments: this.context.savedImportDeclaration.consignments.map(c => c.consignmentNumber)
          .filter(nr => consignments.findIndex(c => c.consignmentNumber === nr) === -1),
        deletedContainers: this.context.savedImportDeclaration.containers.map(c => c.number)
          .filter(nr => containers.findIndex(c => c.number === nr) === -1),
        delta: true
      };
      if (!VisitContext.eventId) {
        sendCommand(command,
          payload, () => {
            const manifests = VisitContext?.cargoImportModel?.manifests;

            if (!!manifests) {
              manifests.forEach(m => {
                m.consignments.forEach(c => c.declared = true);
                m.containers.forEach(c => c.declared = true);
              });
            }

            this.context.replaceVisit(this.context.visit);
            this.context.savedImportDeclaration = cloneObject(this.context.visit.importDeclarations
              .find(d => d.cargoDeclarant.shortName === this.context.cargoImportModel.cargoDeclarant.shortName));
            AppContext.registerSuccess('Your import declaration was sent successfully');
          });
      }
    }
  }


  getDeclarationStatus(manifest: Manifest): DeclarationStatus {
    const declarations =
      DeclarationUtils.getLastDeclarations(manifest.declarations, [DeclarationType.SDT, DeclarationType.ENS]);
    if (declarations.length === 0) {
      return null;
    } else if (declarations.findIndex(d => d.status === 'REJECTED') > -1) {
      return DeclarationStatus.REJECTED;
    } else if (declarations.findIndex(d => d.status === 'DECLARED') > -1) {
      return DeclarationStatus.DECLARED;
    } else {
      return DeclarationStatus.ACCEPTED;
    }
  }

  getRejectReasons(manifest: Manifest): string[] {
    return lodash.flatMap(
      DeclarationUtils.getLastDeclarations(manifest.declarations, [DeclarationType.SDT, DeclarationType.ENS])
        .filter(d => d.status === 'REJECTED' && d.rejectReasons).map(d => d && d.rejectReasons), r => r.split(";"))
      .map(s => s.trim()).filter(s => s.length > 0);
  }

  noCargoDeclarationToCustoms(manifest: Manifest) {
    return !VisitContext.savedVisit.visitDeclaration.etaFirstEntryEu && manifest.portOfDischarge &&
      manifest.portOfDischarge.locationUnCode !== VisitContext.savedVisit.portOfCall.port.locationUnCode
  }


  byManifestId(index: number, s: Manifest) {
    return s.id;
  }

  addConsignment(port: Port) {
    let manifest = VisitContext.cargoImportModel.addConsignment(port);
    const collapseComponent = this.manifestElements.find((collapse, index) =>
      index === this.filteredManifests.findIndex(m => m.id === manifest.id));
    if (collapseComponent) {
      collapseComponent.toggle(true, true);
    }
  }

  getConsignmentCount(): number {
    return lodash.sumBy(VisitContext.cargoImportModel.manifests, m => m.consignments.length);
  }

  deleteManifest(m: Manifest) {
    return () => {
      m.consignments.splice(0, m.consignments.length);
      m.containers.splice(0, m.containers.length);
    }
  }

  applyImportFilters(cargoFilter: CargoFilter) {
    this.zone.runOutsideAngular(() => {
      this.resetFilters();
      this.cargoFilter = cargoFilter;
      this.filteredManifests = VisitContext.cargoImportModel.manifests.filter(this.filterManifest(this.filterTerm));
    });
    CargoImportModel.rerender();
  }

  resetFilters() {
    VisitContext.cargoImportModel.manifests.forEach(m => {
      m.containers.forEach(e => {
        delete e['forceVisible'];
        delete e['hidden'];
      });
      m.overlanders.forEach(e => {
        delete e['forceVisible'];
        delete e['hidden'];
      });
      m.consignments.forEach(c => {
        delete c['forceVisible'];
        delete c['hidden'];
        c.goodsItems.forEach(g => {
          delete g['forceVisible'];
          delete g['hidden'];
        });
      });
    });
    VisitContext.cargoImportModel.overlandersWithoutPort.forEach(e => {
      delete e['forceVisible'];
      delete e['hidden'];
    });
  }

  filterManifest = (term: string): (value: Manifest) => boolean => m => {
    if (m.containers.length === 0 && m.consignments.length === 0 && m.overlanders.length === 0
      && (m.declarations.length === 0
        || lodash.last(m.declarations.filter(d => d.type === 'SDT').map(d => d.status)) === 'ACCEPTED'
        || m.declarations.every(d => d.status === 'REJECTED'))) {
      return false;
    }
    return usingSelections(this.cargoFilter.selectedOptions)
      && usingSelections(this.cargoFilter.deselectedOptions, true)
      && usingTerm();

    function optionallyHideElement(element, hide: boolean): boolean {
      if (!element['forceVisible']) {
        return element['hidden'] = element['hidden'] || hide;
      }
      return false;
    }

    function usingTerm(): boolean {
      const byTerm = filterByTerm(term);
      if (byTerm(m)) {
        m.containers.forEach(e => optionallyHideElement(e, !byTerm(e)));
        m.overlanders.forEach(e => optionallyHideElement(e, !byTerm(e)));
        m.consignments.forEach(c => {
          const consignmentHidden = optionallyHideElement(c, !byTerm(c));
          c.goodsItems.forEach(g => optionallyHideElement(g, !byTerm(g)));
          if (!consignmentHidden && c.goodsItems.every(g => g['hidden'])) {
            c.goodsItems.forEach(g => delete g['hidden']);
          }
        });
        return true;
      } else {
        m.containers.forEach(e => optionallyHideElement(e, true));
        m.overlanders.forEach(e => optionallyHideElement(e, true));
        m.consignments.forEach(c => {
          const consignmentHidden = optionallyHideElement(c, true);
          c.goodsItems.forEach(g => optionallyHideElement(g, true));
          if (!consignmentHidden && c.goodsItems.every(g => g['hidden'])) {
            c.goodsItems.forEach(g => delete g['hidden']);
          }
        });
        return false;
      }
    }

    function usingSelections(filters: CargoSelection[], deselect: boolean = false): boolean {
      if (filters.length === 0) {
        return true;
      }
      const containersHidden = m.containers.map(c => optionallyHideElement(c, hideContainer(c, deselect))).every(c => !!c);
      const overlandersHidden = m.overlanders.map(c => optionallyHideElement(c, hideContainer(c, deselect))).every(c => !!c);
      const consignmentsHidden = m.consignments.map(c => optionallyHideElement(c, hideConsignment(c, deselect))).every(c => !!c);
      return !(containersHidden && overlandersHidden && consignmentsHidden);

      function hideContainer(v: ImportContainerModel | DischargeResult, invert: boolean): boolean {
        const good = lodash.flatMap(m.consignments, c => c.goodsItems)
          .find(g => g.placements.find(p => p.equipmentNumber === v.number));
        return filters.some(f => {
          function dischargedWithDifferences(v: ImportContainerModel | DischargeResult) {
            return !!v['dischargeResult'] && (<DischargeResult>v['dischargeResult']).empty !== v.empty;
          }

          switch (f) {
            case 'Empty':
              return test(!v.empty, invert);
            case 'Reefer':
              return test(!lodash.isNumber(v.temperature) && (!good || (!lodash.isNumber(good.minimumTemperature)
                && !lodash.isNumber(good.maximumTemperature))), invert);
            case 'Transhipment':
              const consignment = m.consignments.find(c => c.goodsItems
                .find(g => g.placements.find(p => p.equipmentNumber === v.number)));
              return test(!consignment || consignment.customsProcess !== 'SEA_IN_SEA_OUT', invert);
            case 'Dangerous':
              return test(!good || !good.dangerInformation, invert);
            case 'Overlanded':
              return test(!m.overlanders.find(c => c.number === v.number), invert);
            case 'Shortlanded':
              const berthVisit = VisitContext.savedVisit.visitDeclaration.portVisit.berthVisits.find(
                b => b.berth && v.terminal && b.berth.terminalCode === v.terminal.terminalCode);
              return test(!(((berthVisit && berthVisit.atd) || VisitContext.savedVisit.visitDeclaration.portVisit.atdPort)
                && !m.overlanders.find(c => c.number === v.number)
                && !v['dischargeResult']), invert);
            case 'Inspection':
              return test(!v['inspections'] || v['inspections'].length === 0, invert);
            case 'Discharged':
              return test(!m.overlanders.find(value => value.number === v.number) && !v['dischargeResult'], invert);
            case 'Differences':
              return test(!dischargedWithDifferences(v), invert);
          }
        });
      }

      function hideConsignment(v: ConsignmentModel, invert: boolean): boolean {
        if (filters.some(f => {
          switch (f) {
            case 'Transhipment':
              return test(v.customsProcess !== 'SEA_IN_SEA_OUT', invert);
            case 'Inspection':
              return test(!v.inspections || v.inspections.length === 0, invert);
          }
        })) {
          return true;
        }
        return v.goodsItems.map(g => optionallyHideElement(g, hideGood(g, invert))).every(g => !!g);
      }

      function hideGood(v: GoodsItemModel, invert: boolean): boolean {
        if (filters.some(f => {
          switch (f) {
            case 'Dangerous':
              return test(!v.dangerInformation, invert);
            case 'Reefer':
              return test(!lodash.isNumber(v.maximumTemperature) && !lodash.isNumber(v.minimumTemperature), invert);
            case 'Inspection':
              return test(v.placements.every(p => !p.inspections || p.inspections.length === 0), invert);
          }
        })) {
          return true;
        }
        if (filters.some(f => {
          switch (f) {
            case 'Empty':
              return true;
            case 'Overlanded':
            case 'Shortlanded':
              return !invert;
          }
        })) {
          return v.placements
            .map(p => m.containers.find(c => c.number === p.equipmentNumber))
            .every(c => !c || c['hidden']);
        }
        return false;
      }
    }

    function test(condition: boolean, invert: boolean) {
      return invert ? !condition : condition;
    }


  };

  onTermChange(term: string) {
    this.filterTerm = term;
    this.resetFilters();
    const byTerm = filterByTerm(this.filterTerm);
    VisitContext.cargoImportModel.overlandersWithoutPort.forEach(o => o['hidden'] = !byTerm(o));
    CargoImportModel.rerender();
  }

  copyCargoOfVisit(crn: string) {
    sendQuery("com.portbase.bezoekschip.common.api.visit.GetVisit", {crn: crn},
      {caching: false, showSpinner: true})
      .subscribe((visit: Visit) => {
        const importDeclaration = visit.importDeclarations.find(d => d.cargoDeclarant.shortName === VisitContext.cargoImportModel.cargoDeclarant.shortName);
        if (importDeclaration) {
          const containers = importDeclaration.containers.filter(
            c => c.portOfDischarge.locationUnCode === VisitContext.savedVisit.portOfCall.port.locationUnCode)
            .map(c => {
              c.terminal = null;
              return c;
            });
          const consignments = importDeclaration.consignments.filter(
            c => c.portOfDischarge.locationUnCode === VisitContext.savedVisit.portOfCall.port.locationUnCode)
            .map(c => {
              c.consignmentNumber = null;
              c.terminal = null;
              return c;
            });
          if (containers.length > 0 || consignments.length > 0) {
            VisitContext.cargoImportModel.addCargo(containers, consignments);
            AppContext.registerSuccess("Import cargo discharged in this visit's port of call was copied successfully");
            return;
          }
        }
        AppContext.registerError("Selected visit does not have any cargo that will be discharged in this visit's port of call", "warning");
      });
  }

  visibleItems(items: any[]) {
    return items.filter(i => !i.hidden);
  }
}
