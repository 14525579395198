<div class="col-md-12">
  <app-alerting></app-alerting>
</div>
<div class="row mx-n1">
  <div class="col-md-6">
    <div class="declaration-block">
      <h2>Details</h2>
    </div>
    <app-form-group>
      <label for="vesselName">Vessel</label>
      <app-search [(ngModel)]="model.vessel" [searchFunction]="searchVessel" [inputFormatter]="vesselFormatter"
                  [minCharacters]="3" placeholder="Name or IMO number" dataKey="imoCode" id="vesselName" required>
      </app-search>
    </app-form-group>
    <app-form-group>
      <label for="portOfCall">Port of Call</label>
      <app-select id="portOfCall" [ngModel]="model.portOfCall" (ngModelChange)="onPortOfCall($event)"
                  [optionsProvider]="portsOfCall" required
                  dataKey="port.name" placeholder="– Select a port –">
      </app-select>
    </app-form-group>
    <div *ngIf="optionallyGenerateCrn">
      <app-form-group label="Reported in Enigma+">
        <app-yes-no [(ngModel)]="manualCrn" required></app-yes-no>
      </app-form-group>
    </div>
    <div *ngIf="manualCrn">
      <app-form-group>
        <label for="crn">CRN</label>
        <input class="input-group rounded border h-100 form-control" id="crn" [(ngModel)]="model.crn" required/>
      </app-form-group>
    </div>
    <app-form-group *ngIf="isBackOffice">
      <label for="shipOperator">Ship Operator</label>
      <app-select id="shipOperator" [(ngModel)]="model.owner" [options]="shipOperators" required
                  placeholder="– Select ship operator –" dataKey="fullName">
      </app-select>
    </app-form-group>
    <app-form-group *ngIf="!pilotStations">
      <label for="entryPoints">Entry point</label>
      <app-select id="entryPoints" [(ngModel)]="model.visitDeclaration.portVisit.entryPoint"
                  [optionsProvider]="entryPoints" dataKey="name" required
                  [refreshWatch]="model.portOfCall" placeholder="– Select entry point –">
      </app-select>
    </app-form-group>
    <app-form-group *ngIf="pilotStations">
      <label for="pilotBoardingPlaces">Location</label>
      <app-select id="pilotBoardingPlaces" [(ngModel)]="pilotBoardingPlace"
                  [optionsProvider]="locations" dataKey="name" required
                  [refreshWatch]="model.portOfCall" placeholder="– Select location –">
      </app-select>
    </app-form-group>
    <app-form-group>
      <label for="eta">ETA</label>
      <app-date-field [(ngModel)]="model.visitDeclaration.portVisit.etaPort" id="eta" required></app-date-field>
    </app-form-group>
    <app-form-group *ngIf="terminalPlanningEnabled">
      <app-info class="formGroupLabel">
        <label for="isFeeder">Feeder vessel service</label>
        <div class="tooltipContent">Terminal planning is only enabled for Feeder vessel services.<br/>
          Select 'Yes' if you want to communicate plannings with terminals directly using Portbase<br/>
          Select 'No' to start declaring to the Port Authority
        </div>
      </app-info>
      <app-yes-no [(ngModel)]="feeder" id="isFeeder"></app-yes-no>
    </app-form-group>
    <app-form-group *ngIf="terminalPlanningEnabled && feeder">
      <app-info class="formGroupLabel">
        <label for="terminalPlanning">Terminal planning?</label>
        <div class="tooltipContent">Select 'Yes' to coordinate the planning with the terminal before declaring the
          visit to the Port Authority.<br/>
        Select 'No' to start declaring to the Port Authority</div>
      </app-info>
      <app-yes-no [(ngModel)]="useTerminalPlanning" id="terminalPlanning" required="true"></app-yes-no>
    </app-form-group>
  </div>
  <div class="declaration-block col-md-6">
    <h2>Copy</h2>
    <app-form-group>
      <label for="crnToCopy">Copy from visit with CRN</label>
      <input id="crnToCopy" class="form-control" [(ngModel)]="model.copyOfVisit"
             oninput="this.value = this.value.toUpperCase()">
    </app-form-group>
  </div>
</div>

<div class="col-auto">
  <button (click)="createVisit()" id="save"
          type="button" class="btn btn-info" style="min-width: 5rem; float:right">Create Visit
  </button>
</div>

<div *ngIf="model.portOfCall?.port?.locationUnCode == 'NLRTM'" class="mt-4">More information on the data sharing policy of the harbourmaster of Rotterdam (scroll down) <a class="ms-2" href="https://www.portofrotterdam.com/nl/zeevaart" title="Hergebruik van gegevens van de Havenmeester ontvangen van externe partijen" target="_blank"><i class="fa-solid fa-arrow-up-right-from-square"></i></a></div>
