<div class="col-md-12">
  <app-action-nav-bar></app-action-nav-bar>
</div>
<div class="row align-items-center mx-1 mb-2 py-2 bg-light">
  <div class="col-auto" style="width: 4rem"><span class="fa fa-2x" (click)="toggleOrder()" [ngClass]="reverseOrder ? 'fa-caret-up' : 'fa-caret-down'"></span></div>
  <div class="col" style="max-width: 12rem">Timestamp</div>
  <div class="col">Action</div>
  <div class="col-auto" style="width: 16rem">User</div>
  <div class="col-auto" style="width: 16rem">Organisation</div>
  <div class="col-auto" style="min-width: 4rem"></div>
</div>


  <ng-container *ngFor="let item of reverseOrder ? items.slice().reverse() : items; let index = index">
    <ng-container [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{ item : item, index: reverseOrder ? items.length - index - 1 : index }"></ng-container>
  </ng-container>


<ng-template #itemTemplate let-logItem="item" let-index="index">
  <div class="always-enabled">
  <a [href]="['/details/' + item.crn + '?eventId=' + item.eventId]" class="text-dark always-enabled"
     target="_blank" title="View visit at this point in time" *ngIf="cast(logItem) as item">
    <div class="row align-items-start item mx-1 my-2 py-2 border border-light">
      <div class="col-auto" style="width: 4rem">
        {{index + 1}}.
      </div>
      <div class="col" style="max-width: 12rem">
        <app-date-field [readonly]="true" [ngModel]="item.timestamp"></app-date-field>
      </div>
      <div class="col angular-with-newlines">{{item.action}}</div>
      <div class="col-auto" style="width: 16rem">
        {{item.user}}
      </div>
      <div class="col-auto" style="width: 16rem">
        {{!item.fullName ? item.shortName: item.fullName}}
      </div>
      <div class="col-auto text-center" style="min-width: 4rem">
        <h5 class="m-0 fa fa-external-link-alt text-white"></h5>
      </div>
    </div>
  </a>
  </div>
</ng-template>
