<div class="container py-3">
  <div class="card">
    <div class="card-body p-5">
      <ng-container *ngFor="let historyItem of historyItemsQuery | async; trackBy: trackByHistoryItem; let first = first;">
        <div class="row mb-3">
          <div class="col-auto text-end" [style.width.px]="150">
            <ng-container *ngIf="splitTimeAndDate(historyItem.timestamp | calendar) as timestampFormatted">
              <span class="d-block">{{timestampFormatted[0]}}</span>
              <span class="d-block">{{timestampFormatted[1]}}</span>
            </ng-container>
          </div>
          <div class="d-flex col-auto">
            <div class="d-flex align-items-center border border-info rounded-circle justify-content-center"
                 style="width: 1.5rem; height: 1.5rem;" [ngClass]="first ? 'border-2' : ''">
              <div *ngIf="first" class="d-flex bg-info rounded-circle" style="width: 0.9rem; height: 0.9rem;">
              </div>
            </div>
          </div>
          <div class="col">
            <ng-container *ngIf="appContext.isAdmin() else normalUser">
              <ng-container *ngIf="openInEmhEnabled(historyItem) else openInKibana">
                <div class="d-block mb-1" (click)="portVisitUtils.redirectToMessageManagement(historyItem.emhProcessId)">
                  <span class="h2 m-0 position-relative">
                    <span class="underline-tertiary link-underline-tertiary link-primary text-body cursor-pointer">{{historyItem.action}}</span>
                    <div class="position-absolute top-0" style="right: -30px">
                      <i class="fa fa-arrow-up fa-rotate-by" style="--fa-rotate-angle: 45deg; font-size: 0.75rem" aria-hidden="true"></i>
                      <i class="fa fa-inbox ps-1" aria-hidden="true"></i>
                    </div>
                  </span>
                </div>
              </ng-container>
              <ng-template #openInKibana>
                <a class="d-block mb-1 underline-tertiary link-underline-tertiary link-primary text-body" target="_blank" [href]="portVisitUtils.getKibanaLink(historyItem.traceId, historyItem.timestamp)">
                  <span class="h2 m-0 position-relative">
                    <span>{{historyItem.action}}</span>
                    <i class="position-absolute top-0 fa fa-arrow-up fa-rotate-by" style="--fa-rotate-angle: 45deg; font-size: 0.75rem; right: -20px" aria-hidden="true"></i>
                  </span>
                </a>
              </ng-template>
            </ng-container>
            <ng-template #normalUser>
              <div class="d-block mb-1">
                <span class="h2 m-0 position-relative">
                  <span>{{historyItem.action}}</span>
                </span>
              </div>
            </ng-template>
            <span class="d-block text-secondary mb-1">{{formatUser(historyItem)}}</span>
            <span class="d-block">{{historyItem.messages | join}}</span>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
