<ng-container *ngIf="appContext.isAdmin() && !context.eventId">
  <div class="dropdown always-enabled">
    <button class="btn btn-secondary" type="button" id="adminHamburgerMenu" data-bs-toggle="dropdown" aria-haspopup="true"
      aria-expanded="false">
      <!-- Hamburger menu icon -->
      <i class="fa fa-user-shield" aria-hidden="true"></i>
    </button>
    <div class="dropdown-menu dropdown-menu-end" aria-labelledby="adminHamburgerMenu">
      <!-- Reject declaration -->
      <a *ngIf="'DECLARED' === context.declarationStatusOf(PAX)" (click)="openConfirmationModal(rejectModal)"
        class="dropdown-item text-danger" tabindex="-1" role="button">
        Reject
      </a>
      <!-- Accept declaration -->
      <a *ngIf="'DECLARED' === context.declarationStatusOf(PAX)" (click)="accept()" id="acceptSecurity"
        class="dropdown-item text-success" tabindex="-1" role="button">
        Accept
      </a>
      <!-- Messages -->
      <h6 class="dropdown-header" style="font-size: 18px;">Messages</h6>
      <a href="#" (click)="$event.preventDefault(); openSwMessages()" class="dropdown-item">
        PAX to Singlewindow
      </a>
    </div>
  </div>
</ng-container>

<ng-template #rejectModal>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Reject reason</h5>
    </div>
    <div class="modal-body">
      <input id="rejectPax-reason" type="text" class="form-control">
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Back</button>
      <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
        (click)="reject(appContext.getDocument().getElementById('rejectPax-reason')['value'])">Send</button>
    </div>
  </div>
</ng-template>
