<div class="row">
  <div class="col">
    <app-form-group label="Carrier organisation" *ngIf="appContext.isAdmin()">
      <app-search [disabled]="!model['new']" required [ngModel]="model.ownerPortbaseId" [minCharacters]="3"
                  [searchFunction]="utils.findOrganisations"
                  [inputFormatter]="carrierFormatter"
                  (ngModelChange)="model.ownerPortbaseId = $event?.shortName; model.ownerFullName = $event?.fullName"
                  [resultFormatter]="utils.agentFormatter">
      </app-search>
    </app-form-group>
    <app-form-group label="Customer organisation">
      <app-search [disabled]="!model['new']" required [ngModel]="model.portbaseId" [minCharacters]="3"
                  [searchFunction]="utils.findOrganisations" [inputFormatter]="customerFormatter"
                  (ngModelChange)="model.portbaseId = $event?.shortName; model.customerData = $event"
                  [resultFormatter]="utils.agentFormatter">
      </app-search>
    </app-form-group>
    <app-form-group label="Address">
      <input class="form-control" readonly [ngModel]="model.customerData?.address">
    </app-form-group>
    <app-form-group label="Zip code">
      <input class="form-control" readonly [ngModel]="model.customerData?.zipCode">
    </app-form-group>
    <app-form-group label="City">
      <input class="form-control" readonly [ngModel]="model.customerData?.city">
    </app-form-group>
    <app-form-group label="Country">
      <input class="form-control" readonly [ngModel]="model.customerData?.countryName">
    </app-form-group>
  </div>
  <div class="col-auto me-3">
    <label>Customer references</label>
  </div>
  <div class="col" *ngIf="!model['new']">
    <ng-container *ngFor="let customerReferenceEntry of model.references | keyvalue">
      <div class="row mb-3">
        <label *ngIf="!customerReferenceEntry.value.status || customerReferenceEntry.value.status === 'NEW'"
               class="col">
          {{customerReferenceEntry.value.reference}}
        </label>
        <label *ngIf="customerReferenceEntry.value.status === 'REJECTED'" class="col text-decoration-line-through">
          {{customerReferenceEntry.value.reference}}
          <label>(<i class="fa fa-remove ms-1 text-portbase-red"></i> Rejected,
            <app-tooltip>
              <span><u>show reason</u></span>
              <span class="tooltipContent">{{customerReferenceEntry.value.rejectReason}}</span>
            </app-tooltip>
            )
          </label>
        </label>
        <label *ngIf="customerReferenceEntry.value.status === 'VERIFIED'" class="col">
          {{customerReferenceEntry.value.reference}} (<i class="fa fa-check ms-1 text-success"></i> Verified)
        </label>
        <div class="col-auto">
          <ng-container *ngIf="customerReferenceEntry.value.status === 'VERIFIED' else notVerified">
            <label></label>
          </ng-container>
          <ng-template #notVerified>
            <ng-container *ngIf="customerReferenceEntry.value.status === 'REJECTED' else notRejected">
              <button class="btn btn-outline-info" (click)="reset(model, customerReferenceEntry.value)"
                      type="button"
                      *ngIf="appContext.isCommercialReleaseDeclarant() || appContext.isCommercialReleaseEditor()">
                <i class="fa fa-pencil me-2"></i>
                Edit
              </button>
            </ng-container>
          </ng-template>
          <ng-template #notRejected>
            <ng-container>
              <button class="btn btn-outline-primary" (click)="verify(model, customerReferenceEntry.value)"
                      type="button"
                      *ngIf="appContext.isCommercialReleaseDeclarant() || appContext.isCommercialReleaseEditor()">
                <i class="fa fa-check me-2"></i>
                Verify
              </button>
              <button class="btn btn-outline-danger ms-2" (click)="reject(model, customerReferenceEntry.value)"
                      type="button"
                      *ngIf="appContext.isCommercialReleaseDeclarant() || appContext.isCommercialReleaseEditor()">
                <i class="fa fa-remove me-2"></i>
                Reject
              </button>
              <button *ngIf="customerReferenceEntry.value['undoActive']" class="btn btn-secondary ms-2"
                      (click)="undo(model, customerReferenceEntry.value)"><span
                      class="fa fa-fw fa-undo-alt"></span>
              </button>
              <label class="text-success"
                     *ngIf="!appContext.isCommercialReleaseDeclarant() && !appContext.isCommercialReleaseEditor()">
                Not verified
              </label>
            </ng-container>
          </ng-template>
        </div>
      </div>
    </ng-container>
  </div>
  <div *ngIf="!!model['new']" class="col">
    <div class="row">
      <app-tags (ngModelChange)="addCustomerReference($event)"
                [(ngModel)]="model['newReferences']"></app-tags>
    </div>
  </div>
</div>
