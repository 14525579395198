<ng-container *ngIf="showTooltip else content">
  <app-tooltip class="formGroupLabel" placement="top">
    <app-message-status-icon [messageStatus]="messageStatus"></app-message-status-icon>
    <span class="d-none d-md-inline-block" [class]="messageStatus === 'DISABLED' ? 'text-muted' : ''">{{label}}</span>
    <div class="tooltipContent text-start">
      <span class="d-block fw-bold">{{header}}</span>
      <span class="d-block">{{reason}}</span>
    </div>
  </app-tooltip>
</ng-container>

<ng-template #content>
  <app-message-status-icon [messageStatus]="messageStatus"></app-message-status-icon>
  <span class="d-none d-md-inline-block" [class]="messageStatus === 'DISABLED' ? 'text-muted' : ''">{{label}}</span>
</ng-template>
