<div class="fieldset"
  [ngClass]="context.findLatestDeclaration(PAX)?.status === 'DECLARED' ? 'disabled'
                      : context.visitIsDeparted() && !context.isVisitReadonlyIgnoringDeparture()? 'always-enabled' : ''">

  <div class="row sticky-top bg-white" style="top:6.125rem">
    <div class="col-md-12">
      <app-alerting></app-alerting>
    </div>
    <div class="col-md-12 mt-2" *ngIf="getRejectReason() as rejectReason">
      <app-status-alert type="danger">{{rejectReason}}</app-status-alert>
    </div>
    <div class="col-md-12">
      <app-action-nav-bar>
        <!-- RIGHT: Action buttons -->
          <ng-container>
            <div class="d-flex flex-row-reverse">
              <!-- Admin actions -->
              <div class="ms-2">
                <app-passengers-admin></app-passengers-admin>
              </div>
              <!-- User actions -->
              <div class="btn-group ms-2">
                <button (click)="sendCrewAndPassengers()" id="send" type="button" class="btn btn-info" [disabled]="!paxDeclarationXls">
                  Send
                </button>
                <!-- Dropdown button -->
                <button type="button"
                  class="btn btn-info dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-haspopup="true"
                  aria-expanded="false">
                  <span class="visually-hidden">Toggle Dropdown</span>
                </button>
                <div class="dropdown-menu dropdown-menu-end">
                  <ng-container *ngIf="paxDeclarationXls">
                    <a (click)="removeUpload()" *ngIf="paxDeclarationXls" id="cancel" role="button"
                      class="dropdown-item text-danger">
                      Remove
                    </a>
                  </ng-container>
                  <ng-container *ngIf="!paxDeclarationXls">
                    <a class="dropdown-item" role="button" id="cancelAll" (click)="openConfirmationModal(cancelModal)">
                      <span class="fa fa-ban" aria-hidden="true"></span> Cancel
                    </a>
                  </ng-container>
                </div>
              </div>
              <!-- Upload button -->
              <a *ngIf="!paxDeclarationXls" role="button" class="btn btn-secondary" (click)="uploadInput.click()" title="Upload crew and passengers declaration">
                <span class="fa fa-file-upload"></span><input type="file" hidden
                  (change)="upload($event.target.files[0]); $event.target.value = '';" #uploadInput>
              </a>
            </div>
          </ng-container>
      </app-action-nav-bar>
    </div>
  </div>

  <div class="row">

    <!--Summary-->
    <div class="declaration-block col-12" *ngIf="paxDeclarationXls as pax">
      <h2>Summary</h2>
      <div class="row justify-content-between g-0 bg-secondary text-light py-2">
        <div class="col-5 col-md-4 ps-2">Type</div>
        <div class="col-3 col-md-6">Number</div>
        <div class="col-auto text-end" style="min-width: 5.5rem"></div>
      </div>
      <app-passenger-summary [persons]="paxDeclarationXls.crewMembers" id="crew">Crew</app-passenger-summary>
      <app-passenger-summary [persons]="paxDeclarationXls.passengers" id="passengers">Passengers</app-passenger-summary>
      <app-passenger-summary [persons]="paxDeclarationXls.crewMembersOtherShip" id="crewOther">Crew other ship
      </app-passenger-summary>
      <app-passenger-summary [persons]="paxDeclarationXls.stowaways" id="stowaways">Stowaways</app-passenger-summary>
    </div>

    <!--Crew and Passengers-->
    <div class="declaration-block col-12">
      <h2>Declarations</h2>
      <div class="row g-0 bg-secondary text-light py-2">
        <div class="col-4 col-md-2 ps-2">Sent</div>
        <div class="col-4 col-md-2">Crew</div>
        <div class="col-md-2 d-none d-md-block">Passengers</div>
        <div class="col-md-2 d-none d-md-block">Crew other ship</div>
        <div class="col-md-2 d-none d-md-block">Stowaways</div>
        <div class="col-md-1 d-none d-md-block" *ngIf="hasSearchAndRescue()">SAR</div>
        <div class="col-4 col-md-1">Status</div>
      </div>
      <div class="row g-0 my-2 mx-0"
        *ngFor="let paxDeclarationSummary of context.visit.paxDeclarationSummaries; let i = index">
        <div class="col-4 col-md-2 ps-2">{{context.paxDeclarations[i]?.timeStamp | date: 'dd-MM-yyyy' }}</div>
        <div class="col-4 col-md-2">{{paxDeclarationSummary.numberOfCrew}}</div>
        <div class="col-md-2 d-none d-md-block">{{paxDeclarationSummary.numberOfPassengers}}</div>
        <div class="col-md-2 d-none d-md-block">{{paxDeclarationSummary.numberOfCrewOtherShip}}</div>
        <div class="col-md-2 d-none d-md-block">{{paxDeclarationSummary.numberOfStowaways}}</div>
        <div class="col-md-1 d-none d-md-block" *ngIf="hasSearchAndRescue()">
          {{paxDeclarationSummary.searchAndRescue != null ? paxDeclarationSummary.searchAndRescue ? 'Yes' : 'No' : ''}}
        </div>
        <div class="col-4 col-md-1">{{context.paxDeclarations[i]?.status}}</div>
      </div>
    </div>


    <div class="disclaimer-box mx-3">
      <div class="disclaimer-label">Disclaimer</div>
      <div>
        The shipping agent guarantees Portbase that express and advance permission has been obtained from the parties
        involved with regard to the processing of these individuals’ personal data for the purposes of providing the
        Portbase services. Portbase accepts no liability in the event the shipping agent has submitted inaccurate or
        incomplete personal data; Portbase also rejects any liability in the event the agent has failed to obtain
        appropriate permission for processing the data. In both instances, the shipping agent declares Portbase free
        from
        liability, of any kind whatsoever, that may arise as a result of the instances described.
      </div>
    </div>

  </div>

  <ng-template #cancelModal>
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Cancel all previous declarations</h5>
      </div>
      <div class="modal-body">
        <p>Are you sure that you want to cancel?</p>
        <p>All previous declarations of the crew and passenger list will be cancelled.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">No
        </button>
        <button type="button" class="btn btn-primary" (click)="cancelAll()" data-bs-dismiss="modal">Yes
        </button>
      </div>
    </div>
  </ng-template>
</div>

<!-- MOBILE (buttons bottom right) -->
<div
  [ngClass]="{'always-enabled' : appContext.hasRole('VisitDeclarant') && !context.visit.cancelled &&
  !(context.findLatestDeclaration(PAX)?.status === 'DECLARED')}">
  <div class="fixed-bottom mb-3 pe-2 btn-group d-block d-lg-none" style="max-width: 100vw;">
    <div class="row justify-content-end g-2">
      <div class="col-auto">
        <button type="button" class="btn btn-info dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
          Actions
        </button>
        <div class="dropdown-menu dropdown-menu-end">
          <ng-container>
            <div>
              <button (click)="sendCrewAndPassengers()" type="button" class="dropdown-item" [disabled]="!paxDeclarationXls">
                Send
              </button>
            </div>
          </ng-container>
          <ng-container *ngIf="paxDeclarationXls">
            <div>
              <a (click)="removeUpload()" *ngIf="paxDeclarationXls" role="button"
                 class="dropdown-item text-danger">
                Remove
              </a>
            </div>
          </ng-container>
          <ng-container *ngIf="!paxDeclarationXls">
            <div>
              <a class="dropdown-item" role="button" (click)="openConfirmationModal(cancelModal)">
                <span class="fa fa-ban" aria-hidden="true"></span> Cancel
              </a>
            </div>
          </ng-container>
          <ng-container>
            <div>
              <a *ngIf="!paxDeclarationXls" role="button" class="dropdown-item" (click)="uploadInput.click()" title="Upload crew and passengers declaration">
                Upload<input type="file" hidden (change)="upload($event.target.files[0]); $event.target.value = '';" #uploadInput>
              </a>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="col-auto">
        <app-passengers-admin></app-passengers-admin>
      </div>
    </div>
  </div>
</div>
