<div class="row narrow-gutters">
  <div *ngIf="rejectAllowed()" class="col-md-auto ms-auto">
    <button data-bs-toggle="modal" data-bs-target="#rejectConsignmentModal" type="button"
            class="btn btn-outline-danger mx-1" style="min-width:10rem" tabindex="-1">Reject ENS
    </button>
  </div>
  <div *ngIf="consignments.length===1 && getUniqueIftmcs().length!==0" class="col-md-auto ms-auto">
    <span class="dropdown mx-1 always-enabled">
        <button class="btn btn-outline-secondary dropdown-toggle" style="min-width: 10rem" type="button"
                data-bs-toggle="dropdown">Incoming
        <span class="caret"></span></button>
        <ul class="dropdown-menu dropdown-menu-end">
          <ng-container *ngFor="let incomingMessage of getUniqueIftmcs()">
            <li (click)="openMessages(incomingMessage.processId)"
                class="dropdown-item">IFTMCS at {{incomingMessage.timeStamp | timestamp}}</li>
          </ng-container>
        </ul>
    </span>
  </div>
</div>

<div id="rejectConsignmentModal" class="modal fade" data-bs-backdrop="false" tabindex="-1" role="dialog">
  <div class="modal-dialog shadow" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Reject reason</h5>
      </div>
      <div class="modal-body">
        <input id="rejectImport-reason" type="text" class="form-control">
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Back</button>
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                (click)="reject(appContext.getDocument().getElementById('rejectImport-reason')['value'])">Reject ENS
        </button>
      </div>
    </div>
  </div>
</div>
