<div class="mx-auto mb-3" style="max-width: 1150px">
  <div class="row my-3">
    <div class="col-auto px-0 align-self-center" style="height: 1.5rem">
      <input type="checkbox" class="form-check-input" (click)="allEntriesChecked() ? deselectAll() : selectAll()"
             [ngModel]="allEntriesChecked()">
    </div>
    <div class="col-md">
        <app-select [(ngModel)]="filter.declarationType" [options]="declarationTypes" class="input-group"></app-select>
    </div>
    <div class="col-md">
      <app-select [(ngModel)]="filter.harbour" [options]="harbours" class="input-group"></app-select>
    </div>
    <div class="col-md">
      <input [(ngModel)]="filter.minimumAge" appDecimalNumber [maxDecimals]="0" class="form-control">
    </div>
    <div class="col-md">
        <app-select [(ngModel)]="filter.minimumAgeUnit" [options]="['minutes', 'hours', 'days']"
                    class="input-group"></app-select>
    </div>
    <div class="col-auto">
      <button type="button" [disabled]="noEntriesChecked() || cannotBeResubmitted()" class="btn btn-info" style="min-width: 5rem" (click)="resubmit()">Resubmit</button>
    </div>
    <div class="col-auto">
      <button type="button" [disabled]="noEntriesChecked()" class="btn btn-info" style="min-width: 5rem" (click)="remove()">Remove</button>
    </div>
  </div>

  <!--Messages -->
  <div class="mx-sm-0 visit-container" *ngIf="entries">
    <div class="bg-portbase-blue ms-3 text-white">
      <h5 class="p-2 my-0">Pending declarations</h5>
    </div>
    <div>
      <div *ngFor="let entry of getPendingDeclarations()" class="row">
        <div class="col-auto px-0 d-flex align-items-center">
          <input type="checkbox" class="form-check-input align-self-center" [(ngModel)]="entry['selected']">
        </div>
        <a class="col text-decoration-none" [routerLink]="'/details/' + entry.crn">
          <div class="row g-0 text-dark border border-top-0 p-3 link">
            <div class="col-sm-2"><h5 class="text-portbase-blue m-0">{{entry.crn}}</h5></div>
            <div class="col-sm-2"><h5 class="text-portbase-blue m-0">{{entry.type}}</h5></div>
            <div class="col-sm-6"><h5 class="text-portbase-blue m-0">{{!!entry.ids? entry.ids.join(','):''}}</h5></div>
            <div class="col-sm-2"><h5 class="text-portbase-blue m-0">{{entry.timestamp | timestamp}}</h5></div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
