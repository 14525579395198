<div class="my-3"><i>Updates the e-mail addresses of a terminal for notifications</i></div>
<div class="row">
  <div class="col-md-6">
    <app-form-group><label>Id</label><input class="form-control" [ngModel]="terminalEmail['id']" disabled required></app-form-group>
    <app-form-group><label>Notifications e-mail</label><input class="form-control" [(ngModel)]="terminalEmail.bunkeringMayCauseDelayEmail" required></app-form-group>
  </div>
  <div class="col-md-6">
    <app-form-group><label>Terminal shortName</label><input class="form-control" [(ngModel)]="terminalEmail.terminalShortName" ></app-form-group>
  </div>
</div>
