import {Component, Input} from '@angular/core';
import {AppContext} from "../../../../../app-context";
import {IE3ConsignmentDataSummary} from "@portbase/bezoekschip-service-typescriptmodels";
import {openEditModal} from "../../../../../common/utils";
import {
  MasterConsignmentDetailsComponent,
  MasterConsignmentDetailsComponentData
} from "../../../../consignments/details/master-consignment-details/master-consignment-details.component";
import {ConsignmentUtils} from "../../../../consignments/consignment.utils";

@Component({
  selector: 'app-container-consignment-overview-item',
  templateUrl: './container-consignment-overview-item.component.html',
  styleUrls: ['./container-consignment-overview-item.component.scss']
})
export class ContainerConsignmentOverviewItemComponent {
  appContext = AppContext;
  @Input() cargoDeclarantId: string;
  @Input() containerNumber: string;
  @Input() consignment:IE3ConsignmentDataSummary;
  @Input() term: string;
  @Input() depth: number;
  @Input() selectable: boolean;
  @Input() collapsible: boolean;

  selectionWidth: number = 40;
  paddingPerStep: number = 20;

  collapsed: boolean;

  get indentionDepth() {
    return this.depth * this.paddingPerStep;
  }

  editConsignment = () => {
    openEditModal(MasterConsignmentDetailsComponent, <MasterConsignmentDetailsComponentData>{
      consignmentProcessId: ConsignmentUtils.constructConsignmentProcessId(this.cargoDeclarantId, this.consignment.consignmentNumber)
    }, {
      warnOnClose: true,
      currentToStack: true
    });
  };
}
