import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {AbstractValueAccessorComponent} from '../../../../../common/component/value-accessor.component';
import {BerthVisit, NauticalService, RequiredQuantity} from '@portbase/bezoekschip-service-typescriptmodels';
import {Observable} from 'rxjs';
import {VisitContext} from "../../../../visit-context";
import {uuid} from "../../../../../common/utils";

export type ServiceType = "Pilot" | "Tugboat" | "Boatmen";

@Component({
  selector: 'app-edit-nautical-service',
  templateUrl: './edit-nautical-service.component.html',
  styleUrls: ['./edit-nautical-service.component.css'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => EditNauticalServiceComponent), multi: true}
  ],
})
export class EditNauticalServiceComponent extends AbstractValueAccessorComponent<NauticalService> implements OnInit {
  service : NauticalService = <NauticalService>{ required : null };
  tugboatQuantities: RequiredQuantity[] = [RequiredQuantity.PILOT_DETERMINES, RequiredQuantity.ONE,
    RequiredQuantity.TWO, RequiredQuantity.THREE, RequiredQuantity.FOUR, RequiredQuantity.FIVE, RequiredQuantity.SIX,
    RequiredQuantity.SEVEN, RequiredQuantity.EIGHT, RequiredQuantity.NINE];
  context = VisitContext;
  tugsRequiredForPortOfCall: boolean;

  quantityFormatter = (value : RequiredQuantity) => {
    switch (value) {
      case "PILOT_DETERMINES": return "L.A.B.";
      case "ONE": return "1";
      case "TWO": return "2";
      case "THREE": return "3";
      case "FOUR": return "4";
      case "FIVE": return "5";
      case "SIX": return "6";
      case "SEVEN": return "7";
      case "EIGHT": return "8";
      case "NINE": return "9";
      default: throw Error('Unknown RequiredQuantity value: ' + value);
    }
  };
  @Input() type : ServiceType;
  @Input() serviceProviders: Observable<any[]>;
  @Input() required;
  id = uuid();
  @Input() tugBoatsOnEntry;
  @Input() berthVisit : BerthVisit;
  @Input() isFirstMovement : boolean;
  @Input() isLastMovement : boolean;

  get value(): NauticalService {
    return !this.service || this.service.required === null ? null : this.service;
  }

  writeValue(value: NauticalService): void {
    this.service = value ? value : <NauticalService>{ required : null };
  }

  ngOnInit() {
    if (!this.id) {
      throw new Error('Attribute "id" is required for app-edit-nautical-service component');
    }
    if (!this.type) {
      throw new Error('Attribute "type" is required for app-edit-nautical-service component');
    }
    if (!this.serviceProviders) {
      throw new Error('Attribute "serviceProviders" is required for app-edit-nautical-service component');
    }
    this.tugsRequiredForPortOfCall = this.context.visit.portOfCall.tugBoatsRequired;
  }

  sanitize = () => {
    if (this.service && this.service.required === false) {
      this.service = <NauticalService> {
        required: false
      }
    }
  };

  show : boolean = false;
  showRemarks() {
    this.show = true;
  }

  isLabTheOnlyOption() {
    return this.isLongContainerShip() ||
      (this.context.isVisitingRotterdam() && this.context.visit.vessel?.lngShip);
  }

  isLongContainerShip() {
    const vessel = this.context.visit.vessel;
    return vessel && vessel.containerShip && vessel.fullLength >= 350;
  }
}
