<div class="row">
  <div class="col-md">
    <app-form-group label="Template name">
      <input class="form-control" required [disabled]="!!model.id" placeholder="Enter template name" [(ngModel)]="model.name">
    </app-form-group>
  </div>
  <div class="col-md">
  </div>
</div>
<app-transit-details [declaration]="declarationTemplate" isTemplate="true" [isSimplifiedProcedure]="declarationTemplate.data.transportTerm!=null"></app-transit-details>
