import {DateTimeRange} from "@portbase/bezoekschip-service-typescriptmodels";
import moment, {unitOfTime} from "moment";
import lodash from "lodash";
import {EventEmitter} from "@angular/core";
import {Subject} from "rxjs";

declare var require: any;

const timeslots: TimeSlotDefinition[] = require('./timeslot-definitions.json');

export class ServiceMonitoringContext {

  static updatesPerType: string[] = ["visit", "accountManagement", "commercialRelease", "terminalUpdates", "refData", "pilotUpdates"];
  private static dateRangesLongToSmaller: unitOfTime.Base[] = ["years", "months", "days", "hours", "minutes", "seconds", "milliseconds"];
  static dateTimeRange = new EventEmitter<DateTimeRange>();
  static zoomSubject = new EventEmitter<number>();
  static resetZoom = new EventEmitter();
  static destroyNotifier = new Subject();

  static groupByTimeFrames(data: ChartDataSimple[], dateRange: DateTimeRange, aggregationMethod: (arr: any[]) => number): ChartDataSimple[] {
    const startDate = moment(dateRange.start);
    const endDate = moment(dateRange.end);
    const duration = moment.duration(endDate.diff(startDate));
    const foundTimeSlot: TimeSlotDefinition = ServiceMonitoringContext.findTimeSlotDefinition(duration);
    const startDiffSinceStartInUnit = startDate.diff(moment(0), foundTimeSlot.result.unit);
    const startDateTimeInUnits = moment(0).add(startDiffSinceStartInUnit, foundTimeSlot.result.unit);
    const durationOfTimeUnit = moment.duration(startDateTimeInUnits.diff(moment(0))).as(foundTimeSlot.result.unit);
    const durationOfTimeUnitRounded = Math.floor(durationOfTimeUnit / foundTimeSlot.result.amount) * foundTimeSlot.result.amount;
    const startTimeRoundedByParent = moment(0).add(durationOfTimeUnitRounded, foundTimeSlot.result.unit);

    const slots: Slot[] = lodash.range(0, duration.as(foundTimeSlot.result.unit) + 1, foundTimeSlot.result.amount).map(n => {
      return <Slot>{
        startTime: startTimeRoundedByParent.valueOf(),
        endTime: startTimeRoundedByParent.add(foundTimeSlot.result.amount, foundTimeSlot.result.unit).valueOf(),
        values: []
      }
    });

    const slotData = this.slotsToData(data, slots, aggregationMethod);
    return slotData.every(r => r.y === 0) ? [] : slotData;
  }

  private static findTimeSlotDefinition(difference: moment.Duration): TimeSlotDefinition {
    const foundUnit = this.dateRangesLongToSmaller.find(d => difference.get(d) >= 1);
    const differenceInUnit = difference.as(foundUnit);

    let timeSlotDefinition = timeslots.find(t => t.difference.unit === foundUnit &&
      differenceInUnit >= t.difference.start && differenceInUnit <= t.difference.end);

    if (!timeSlotDefinition) {
      timeSlotDefinition = this.scaleTimeSlotDown(difference, this.dateRangesLongToSmaller[0]);
    }
    return timeSlotDefinition || timeslots[timeslots.length - 1];
  }

  private static scaleTimeSlotDown(difference: moment.Duration, foundUnit: unitOfTime.Base): TimeSlotDefinition {
    let indexOfUnit = this.dateRangesLongToSmaller.indexOf(foundUnit);
    if (indexOfUnit == this.dateRangesLongToSmaller.length - 1) {
      return timeslots[timeslots.length - 1];
    }
    const scaledDownTimeSlot: unitOfTime.Base = this.dateRangesLongToSmaller[indexOfUnit + 1];
    return this.findTimeSlotForUnit(difference, scaledDownTimeSlot);
  }

  private static findTimeSlotForUnit(difference: moment.Duration, scaledDownTimeSlot: unitOfTime.Base) {
    const differenceInUnit = difference.as(scaledDownTimeSlot);

    let timeSlotDefinition = timeslots.find(t => t.difference.unit === scaledDownTimeSlot &&
      lodash.inRange(differenceInUnit, t.difference.start, t.difference.end));
    if (!timeSlotDefinition) {
      timeSlotDefinition = ServiceMonitoringContext.scaleTimeSlotDown(difference, scaledDownTimeSlot);
    }
    return timeSlotDefinition;
  }

  private static slotsToData(data: ChartDataSimple[], slots: Slot[], aggregationMethod: (arr: any[]) => number) {
    data.forEach(r => {
      const index = slots.findIndex(s => lodash.inRange(r.x, s.startTime, s.endTime));
      if (index > -1) {
        slots[index].values.push(r.y);
      }
    });

    return slots
      .map(e => {
        return <ChartDataSimple>{
          x: e.startTime,
          y: aggregationMethod(e.values)
        };
      });
  }
}

export interface ChartDataSimple {
  x: number;
  y: number;
}

interface Slot {
  startTime: number;
  endTime: number;
  values: number[];
}

interface TimeSlotDefinition {
  difference: {
    unit: unitOfTime.Base;
    start: number;
    end: number;
  }
  result: {
    unit: unitOfTime.Base;
    amount: number;
  }
}
