<div class="row sticky-top navbar-dark" style="background-color: #004462">
  <div class="page-section navbar-brand col">
    <img class="ms-5 mt-1 mb-1" alt="Portbase logo" src="assets/logo-portbase.png" style="height: 30px"/>
    <span class="d-none d-sm-inline ms-3 ps-3 border-start">Port Visit</span>
  </div>
</div>
<div class="container mt-3">
  <div class="row page-section d-flex justify-content-center">
    <div class="col-md-8">
      <div class="error-template">
        <h1>Maintenance</h1>
        <p>Portvisit is currently unavailable because of planned maintenance. Please check the status page using button below for more information.</p>
        <div class="error-actions">
          <a href="https://status.portbase.com/" style="margin-top: 10px;" class="btn btn-info btn-lg"><span class="glyphicon glyphicon-home"></span>Take Me To Status page </a>
        </div>
      </div>
    </div>
  </div>
</div>
