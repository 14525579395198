import {Component, EventEmitter, Output} from '@angular/core';
import {lodash, removeItem} from "../../common/utils";

@Component({
  selector: 'app-visit-filter',
  templateUrl: './visit-filter.component.html',
  styleUrls: ['./visit-filter.component.css']
})
export class VisitFilterComponent implements VisitFilter {
  options: VisitSelection[] = ['Expected', 'Arrived', 'Rejections', 'Inspection', 'Shortlanded', 'Overlanded', 'Tidal',
    'ServicesOrdered', 'HarbourMasterRemarks'];
  selectedOptions: VisitSelection[] = [];
  deselectedOptions: VisitSelection[] = [];

  @Output() changed = new EventEmitter<VisitFilter>();

  isOn(option: any): boolean {
    return this.selectedOptions.some(o => option === o);
  }

  isOff(option: any): boolean {
    return this.deselectedOptions.some(o => option === o);
  }

  isToggled(option: any): boolean {
    return this.isOn(option) || this.isOff(option);
  }

  toggleOn(option: VisitSelection) {
    removeItem(this.deselectedOptions, option);
    const existingOption = this.selectedOptions.find(o => option === o);
    if (existingOption) {
      removeItem(this.selectedOptions, option);
    } else {
      this.selectedOptions.push(option);
    }
    this.changed.emit(this);
  }

  toggleOff(option: VisitSelection) {
    removeItem(this.selectedOptions, option);
    const existingOption = this.deselectedOptions.find(o => option === o);
    if (existingOption) {
      removeItem(this.deselectedOptions, option);
    } else {
      this.deselectedOptions.push(option);
    }
    this.changed.emit(this);
  }

  reset(option: VisitSelection) {
    removeItem(this.selectedOptions, option);
    removeItem(this.deselectedOptions, option);
    this.changed.emit(this);
  }

  resetAll() {
    this.selectedOptions = [];
    this.deselectedOptions = [];
    this.changed.emit(this);
  }

  display(option: VisitSelection) {
    switch (option) {
      case 'Tidal':
        return 'Tidal restricted';
      case 'ServicesOrdered':
        return 'Services ordered';
      case 'HarbourMasterRemarks':
        return 'Harbour master remarks';
    }
    return lodash.startCase(option);

  }
}

export type VisitSelection = 'Expected' | 'Arrived' | 'Rejections' | 'Overlanded' | 'Shortlanded' | 'Inspection'
  | 'Tidal' | 'ServicesOrdered' | 'HarbourMasterRemarks';

export interface VisitFilter {
  selectedOptions: VisitSelection[];
  deselectedOptions: VisitSelection[];
}
