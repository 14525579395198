<div *ngIf="appContext.isAdmin() || appContext.isDischargeListSender()">
  <div class="row narrow-gutters align-items-center my-4">
    <div class="col always-enabled">
      <app-local-filter [data]="dischargeLists" (updated)="filteredLists = $event" [filterFunction]="filterList"
        [placeholder]="'Filter by consignment nr, good name, etc.'"></app-local-filter>
    </div>
  </div>

  <div *ngFor="let list of filteredLists; let index = index; trackBy : listById" class="mb-3">
    <div class="row g-0">
      <div class="col-auto me-2">
        <div class="form-check form-check-inline m-0 always-enabled" style="height: 3.5rem">
          <input [(ngModel)]="list['selected']" class="always-enabled form-check-input m-0 always-enabled"
            type="checkbox">
        </div>
      </div>
      <div class="col">
        <app-collapse #dischargeListCollapse [collapse]="listTemplate">
          <div class="row pe-3 g-0 align-items-center">
            <ng-container [ngSwitch]="getDeclarationStatus(list)">
              <div class="col-auto me-3 p-3 bg-portbase-green" *ngSwitchCase="'ACCEPTED'"
                title="Your discharge list has been sent to the terminal">
                <span class="fa fa-fw fa-check text-white"></span>
              </div>
              <div class="col-auto me-3 p-3" *ngSwitchDefault
                title="Your discharge list has not been sent to the terminal">
                <span class="fa fa-fw fa-map-marker-alt text-portbase-blue"></span>
              </div>
            </ng-container>
            <div class="col fw-bold text-dark">{{list.terminal.terminalName | titlecase}}</div>
            <div class="col-auto d-none d-md-block text-dark">
              <app-cargo-summary [dischargeList]="list" [keepTooltipOpen]="dischargeListCollapse.showing">
              </app-cargo-summary>
            </div>
          </div>
        </app-collapse>
      </div>
    </div>

    <ng-template #listTemplate>
      <div class="collapse">
        <div class="my-3">
          <div class="declaration-block my-4" *ngIf="list.overlanders.length > 0">
            <h2>Overlanded</h2>
          </div>
          <app-overlanders [setOverlanders]="list.overlanders"></app-overlanders>

          <div class="declaration-block my-4" *ngIf="list.overlanders.length > 0 && list.containers.length > 0">
            <h2>Declared</h2>
          </div>
          <app-containers [containers]="list.containers"></app-containers>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<div *ngIf="filteredLists.length === 0" class="text-center text-muted py-2">
  {{dischargeLists.length > 0 ? 'No matching items found.' : 'You haven’t declared any containers.'}}
</div>

<ng-container *ngIf="selectedLists.length > 0">
  <div class="fixed-bottom row g-0 justify-content-center align-items-center bg-white py-3 border-top">
    <div class="col-auto" *ngIf="appContext.isAdmin()">
      <app-discharge-list-admin [lists]="selectedLists"></app-discharge-list-admin>
    </div>
    <div class="col-auto always-enabled" *ngIf="selectedLists.length === 1">
      <button class="btn btn-outline-secondary mx-1" style="min-width: 10rem" type="button"
        (click)="context.cargoImportModel.downloadDischargeList(selectedLists[0].terminal.terminalCode)">Download
      </button>
    </div>
    <div class="col-auto" *ngIf="filterAllowedTerminals(selectedLists).length > 0">
      <button class="btn btn-outline-info mx-1" style="min-width: 10rem" type="button"
        (click)="send(filterAllowedTerminals(selectedLists))">Send to terminal
      </button>
    </div>
    <div *ngIf="filterAllowedTerminals(selectedLists).length===0" class="col-auto hint-box text-end ms-1">Terminal
      does not support sending discharge list
    </div>
  </div>
</ng-container>


<div *ngIf="!appContext.isAdmin() && !appContext.isDischargeListSender()" class="text-muted text-center"
  style="margin-top: 50px">
  No discharge list subscription detected for your organisation. <a
    href="https://www.portbase.com/en/services/discharge-list/">Get your subscription here</a>
</div>
