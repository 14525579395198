<div class="d-none" *ngIf="this.summary && this.collector">
  <div id="printPdf" class="page">
    <h2 class="title d-flex justify-content-center mt-4 mb-3">S-formulier</h2>
    <div class="ms-5 me-5 mt-3">
      <div class="row border">
        <div class="col-7">
          <div class="row border-bottom border-end">
            <div class="col-7 bg-light border-end">
              <div><b>Naam van ontvangende organisatie</b></div>
              <div class="mb-1">Name of receiving company</div>
            </div>
            <div class="col-5">
              <div class="mt-2">{{collector.organisation.fullName}}</div>
            </div>
          </div>
          <div class="row border-bottom border-end">
            <div class="col-7 bg-light border-end">
              <div class="mt-1"><b>VIHB nummer</b></div>
              <div class="mb-1">VIHB number</div>
            </div>
            <div class="col-5">
              <div class="mt-2">{{collector.vihbNumber}}</div>
            </div>
          </div>
          <div class="row   border-end">
            <div class="col-7 bg-light border-end">
              <div class="mt-1"><b>Postcode en adres</b></div>
              <div class="mb-1">Area code and address</div>
            </div>
            <div class="col-5">
              <div class="mt-2">{{collector.organisation.zipCode}} {{collector.organisation.city}}, {{collector.organisation.address}}</div>
            </div>
          </div>
        </div>
        <div class="col-5">
          <div><b>Formulier voor de overdracht van scheepsafvalstoffen</b><br>Ship waste transfer form<br>
            <b>Formulier S1 Bewijs van afgifte</b>/Form S1 Proof of waste disposal<br>
            <b>Bestemd voor het afgevende schip</b>/For discharging vessel<br>
            <b>Het ingevulde formulier 5 jaar aan boord bewaren</b><br>Filled and signed form must be kept on
            board for 5 years
          </div>
        </div>
      </div>
    </div>
    <div class="ms-5 me-5 mt-3">
      <div class="row border">
        <div class="col-12">
          <div class="row border-bottom">
            <div class="col-4 bg-light border-end">
              <div class=" mt-1 mx-n2">1.<b>S-nummer</b></div>
              <div class=" mb-1">File number</div>
            </div>
            <div class="col-8">
              <div class="mt-2">{{formatSformNumber(collector.collectorNumber, collection.fileNumber)}}</div>
            </div>
          </div>
          <div class="row border-bottom">
            <div class="col-4 bg-light border-end">
              <div class="mx-n2">2.<b>Naam afgevend schip</b></div>
              <div class="mb-1">Name of discharging vessel</div>
            </div>
            <div class="col-4 border-end">
              <div class="mt-2">{{summary.vesselName}}</div>
            </div>
            <div class="col-2 bg-light border-end">
              <div class="mt-1"><b>Vlag</b></div>
              <div class="mb-1">Flag</div>
            </div>
            <div class="col-2">
              <div class="mt-2">{{summary.vesselFlagCode}}</div>
            </div>
          </div>
          <div class="row border-bottom">
            <div class="col-4 bg-light border-end">
              <div class="mt-1"><b>Naam scheepsagent/reder</b></div>
              <div class="mb-1">Name shipping agent/owner</div>
            </div>
            <div class="col-8">
              <div class="mt-2">{{summary.shipOperatorName}}</div>
            </div>
          </div>
          <div class="row border-bottom">
            <div class="col-4 bg-light border-end">
              <div class=" mt-1"><b>Postcode en adres</b></div>
              <div class=" mb-1">Area code and address</div>
            </div>
            <div class="col-8">
              <div class="mt-2">{{summary.shipOperatorZipCode}}</div>
              <div class="mb-1">{{summary.shipOperatorAddress}}</div>
            </div>
          </div>
          <div class="row border-bottom">
            <div class="col-4 bg-light border-end">
              <div class=" mt-1"><input type="checkbox" checked="true">&nbsp;<b>Zeeschip* IMO-nummer</b></div>
              <div class=" mb-1">Seagoing vessel IMO-number</div>
            </div>
            <div class="col-4 border-end">
              <div class="mt-2">{{summary.vesselImoCode}}</div>
            </div>
            <div class="col-2 bg-light border-end">
              <div class="mt-1"><b>Roepletters</b></div>
              <div class="mb-1">Call sign</div>
            </div>
            <div class="col-2">
              <div class="mt-2">{{summary.vesselCallSign}}</div>
            </div>
          </div>
          <div class="row border-bottom">
            <div class="col-4 bg-light border-end">
              <div class="mt-1"><input type="checkbox">&nbsp;<b>Binnenschip* scheepsnummer</b></div>
              <div class="mb-1">Inland craft Vesselregistration no.</div>
            </div>
            <div class="col-8">
              <div></div>
            </div>
          </div>
          <div class="row border-bottom">
            <div class="col-4 bg-light border-end">
              <div class="mt-1"><input type="checkbox">&nbsp;<b>Recreatievaart*</b></div>
              <div class="mb-1">Recreational craft</div>
            </div>
            <div class="col-8">
              <div></div>
            </div>
          </div>
          <div class="row border-bottom">
            <div class="col-2 bg-light border-end">
              <div class="mx-n2">3.<b>Eural-code</b></div>
            </div>
            <div class="col-2 bg-light border-end">
              <div><b>Afvalcode</b></div>
              <div>Waste code</div>
            </div>
            <div class="col-4 bg-light border-end">
              <div><b>Naam van het afval</b></div>
              <div>Common name of waste</div>
            </div>
            <div class="col-1 bg-light border-end">
              <div><b>Aantal</b></div>
              <div>Quantity</div>
            </div>
            <div class="col-3 bg-light ">
              <div><b>Naam, nr of kenteken van ontvangstmiddel</b></div>
              <div>Name, no. or registration no. of the receptionvessel</div>
            </div>
          </div>
          <div class="row border-bottom" *ngFor="let item of collection.items">
            <div class="col-2 border-end"><!-- Bewust niet gevuld met item.wasteMaterial.euralCode omdat deze
            momenteel niet juist is --></div>
            <div class="col-2 border-end">{{showIltCodes ? item.wasteMaterial.iltCode : item.wasteMaterial.typeCode}}</div>
            <div class="col-4 border-end">{{item.wasteMaterial.nameEN}}</div>
            <div class="col-1 px-1 text-end border-end">{{item.amount}} {{item.wasteMaterial.unitOfMeasure}}</div>
            <div class="col-3">{{item.receptionFacility}}</div>
          </div>
          <div class="row border-bottom" *ngFor="let number of [0,1]">
            <div class="col-2 border-end">&nbsp;</div>
            <div class="col-2 border-end">&nbsp;</div>
            <div class="col-4 border-end">&nbsp;</div>
            <div class="col-1 border-end">&nbsp;</div>
            <div class="col-3">&nbsp;</div>
          </div>
          <div class="row border-bottom">
            <div class="col-4 border-end">
              <div class="row">
                <div class="col-8  bg-light">
                  <div class="mx-n2">4.<b>Datum van afgifte (d/m/j)</b></div>
                  <div>Date of discharge (d/m/y)</div>
                </div>
                <div class="col-4">
                  <div class="mt-2">{{toDate(collection.startTime)}}</div>
                </div>
              </div>
            </div>
            <div class="col-4 border-end">
              <div class="row">
                <div class="col-9 bg-light">
                  <div><b>Tijdstipaanvang afgifte (uu:mm)</b></div>
                  <div>Time start of discharge (hh:mm)</div>
                </div>
                <div class="col-3">
                  <div class="mt-2">{{toTime(collection.startTime)}}</div>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="row">
                <div class="col-9 bg-light">
                  <div><b>Tijdstipgereed afgifte (uu:mm)</b></div>
                  <div>Time completion of discharge (hh:mm)</div>
                </div>
                <div class="col-3">
                  <div class="mt-2">{{toTime(collection.endTime)}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row border-bottom">
            <div class="col-4 bg-light border-end">
              <div class="mx-n2">5.<b>Vaarwegnummer</b></div>
              <div>Waterway number</div>
            </div>
            <div class="col-4 bg-light">
              <div><b>Gemeente, haven of havennummer</b></div>
              <div>Municipality, port or port number</div>
            </div>
            <div class="col-4">
              <div class="mt-2">{{formatBerth(collection.berthVisitId)}}</div>
            </div>
          </div>
          <div class="row border-bottom">
            <div class="col-12 bg-light">
              <div class="mx-n2">6.<b>Ruimte voor aanvullende gegevens</b></div>
              <div>Supplementary remarks</div>
            </div>
          </div>
          <div class="row border-bottom">
            <div class="col-12">{{getRemarks()}}</div>
          </div>
          <div class="row border-bottom" *ngFor="let number of [0,1]">
            <div class="col-12">&nbsp;</div>
          </div>
          <div class="row border-bottom">
            <div class="col-3 bg-light border-end">
              <div class="mx-n2">7.<b>Handtekening ontvanger</b></div>
              <div>Signature of receiver</div>
              <div>&nbsp;</div>
              <div>&nbsp;</div>
            </div>
            <div class="col-3">
              <div></div>
              <div></div>
            </div>
            <div class="col-3 bg-light border-start">
              <div><b>Handtekening ontdoener</b></div>
              <div>Signature of discharger</div>
            </div>
            <div class="col-3 border-start">
              <div></div>
              <div></div>
            </div>
          </div>
          <div class="row border-bottom">
            <div class="col-3 bg-light border-end">
              <div><b>Naam</b></div>
              <div>Name</div>
            </div>
            <div class="col-3">
              <div>{{appContext.userProfile.organisation?.contact}}</div>
              <div></div>
            </div>
            <div class="col-3 bg-light border-start">
              <div><b>Naam</b></div>
              <div>Name</div>
            </div>
            <div class="col-3 border-start">
              <div></div>
              <div></div>
            </div>
          </div>
          <div class="row border-bottom">
            <div class="col-3 bg-light border-end">
              <div><b>Functie</b></div>
              <div>Function</div>
            </div>
            <div class="col-3">
              <div></div>
              <div></div>
            </div>
            <div class="col-3 bg-light border-start">
              <div><b>Functie</b></div>
              <div>Function</div>
            </div>
            <div class="col-3 border-start">
              <div></div>
              <div></div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 bg-light">
              <div><b>* Aankruisen wat van toepassing is</b></div>
              <div>Tick what is applicable</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<button class="btn btn-info" (click)="download()">Download form <span class="fas fa-download"></span></button>
