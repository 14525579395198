import {Component, ElementRef, forwardRef, Input, OnInit} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {AbstractValueAccessorComponent} from '../../../../../common/component/value-accessor.component';
import {BerthVisit, RegisterAtaUsingId, RemoveAta} from '@portbase/bezoekschip-service-typescriptmodels';
import {VisitContext} from '../../../../visit-context';
import {AppContext} from '../../../../../app-context';
import {checkValidity, scrollToTop, sendCommand} from '../../../../../common/utils';

@Component({
  selector: 'app-register-ata',
  templateUrl: './register-ata.component.html',
  styleUrls: ['./register-ata.component.css'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => RegisterAtaComponent), multi: true}
  ],
})
export class RegisterAtaComponent extends AbstractValueAccessorComponent<BerthVisit> implements OnInit {
  context = VisitContext;
  @Input() berthVisit: BerthVisit;
  ata: string;
  modalOpen: boolean;

  @Input() id;

  constructor(private elementRef: ElementRef) {
    super();
  }

  get value(): BerthVisit {
    return this.berthVisit;
  }

  writeValue(value: BerthVisit): void {
    this.berthVisit = value;
  }

  ngOnInit(): void {
    if (!this.id) {
      throw new Error('Attribute "id" is required for register-ata-component');
    }
  }

  isAtaModifiable = () => {
    return VisitContext.isAtaAtdModifiable();
  };

  withModal = (action: "toggle" | "show" | "hide" | "handleUpdate" | "dispose") => {
    this.modalOpen = action === 'show';
    $('#' + this.id + 'registerAtaModal').modal(action);
  };

  removeAta() {
    sendCommand('com.portbase.bezoekschip.common.api.visit.RemoveAta', <RemoveAta> {
      crn: this.context.visit.crn,
      berthVisitId: this.berthVisit.id
    }, () => {
      this.berthVisit.ata = null;
      this.context.replaceVisit(this.context.visit);
      AppContext.registerSuccess('ATA successfully removed.');
    });
    scrollToTop();
    this.withModal('hide');
  }

  registerAta() {
    const result = checkValidity(this.elementRef);
    if (!result) {
      return;
    }
    sendCommand('com.portbase.bezoekschip.common.api.visit.RegisterAtaUsingId', <RegisterAtaUsingId> {
      crn: this.context.visit.crn,
      berthVisitId: this.berthVisit.id,
      ata: this.ata
    }, () => {
      this.berthVisit.ata = this.ata;
      this.context.replaceVisit(this.context.visit);
      AppContext.registerSuccess('ATA registered successfully.');
    });
    scrollToTop();
    this.withModal('hide');
  }
}
