<div class="row sticky-top bg-white" style="top:6.125rem">
  <div class="col-md-12">
    <app-alerting></app-alerting>
  </div>
  <div class="col-md-12" *ngIf="getRejectReason() as rejectReason">
    <app-status-alert type="danger">Customs: {{rejectReason}}</app-status-alert>
  </div>
  <div class="col-md-12">
    <app-action-nav-bar>
      <div class="d-flex flex-row-reverse">
        <!-- Admin features -->
        <div class="ms-2">
          <app-ship-stores-admin></app-ship-stores-admin>
        </div>
          <ng-container *ngIf="!isDeclaredOrAccepted() && !this.showSendConfirmation">
            <div class="btn-group fieldset" [ngClass]="isDeclaredOrAccepted() ? 'disabled' :
  context.visitIsDeparted() || !context.isVisitReadonlyIgnoringDeparture() ? 'always-enabled' : ''">
              <button (click)="checkSaveAndSend()" id="checkSendShipStores" type="button" class="btn btn-info ms-2" [disabled]="!isSaveAndSendAllowed()"
                      title="{{(!isSaveAndSendAllowed()?' First save and send the visit' : '')}}" style="min-width: 6rem" tabindex="-1">Save and send
              </button>
            </div>
            <div class="fieldset" [ngClass]="isDeclaredOrAccepted() ? 'disabled' :
  context.visitIsDeparted() || !context.isVisitReadonlyIgnoringDeparture() ? 'always-enabled' : ''">
              <a class="btn btn-secondary" role="button" (click)="uploadInput.click()" title="Upload ship stores declaration">
                <span class="fa fa-file-upload"></span><input type="file" hidden
                                                              (change)="upload($event.target.files[0]); $event.target.value = '';" #uploadInput>
              </a>
            </div>
          </ng-container>
      </div>
    </app-action-nav-bar>
  </div>
</div>

<div class="fieldset" [ngClass]="isDeclaredOrAccepted() ? 'disabled' :
  context.visitIsDeparted() || !context.isVisitReadonlyIgnoringDeparture() ? 'always-enabled' : ''">

  <div class="row">

    <!-- Send warnings -->
    <div class="col-md-12" *ngIf="this.showSendConfirmation">
      <div class="alert alert-warning fade show mb-0" role="alert">
        <div class="row justify-content-between">
          <div class="me-3 mb-2 mt-2 ms-2 d-inline-block">
            <span>The declaration is empty. Are you sure you want to send the declaration?</span>
          </div>
          <div class="d-inline-block text-nowrap me-2">
            <button (click)="this.showSendConfirmation = false" id="cancelSendShipStores" data-bs-dismiss="alert"
              type="button" class="btn btn-secondary me-2" style="min-width: 6rem" tabindex="-1">Cancel
            </button>
            <button (click)="saveAndSend()" id="sendShipStores" data-bs-dismiss="alert" type="button"
              class="btn btn-warning" style="min-width: 6rem" tabindex="-1">Send
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Ship Stores Declaration-->
    <div class="declaration-block container col-md-12">
      <h2>Ship Stores Declaration</h2>
    </div>
    <div class="col-md-6">
      <app-form-group>
        <label>Spirits (ltr)</label>
        <input required [(ngModel)]="context.visit.shipStoresDeclaration.items.SPIRITS" id="spirits" type="number"
          class="form-control">
      </app-form-group>
      <app-form-group>
        <label>
          <span>Beer (ltr)</span>
        </label>
        <input required [(ngModel)]="context.visit.shipStoresDeclaration.items.BEER" id="beer" type="number"
          class="form-control">
      </app-form-group>
      <app-form-group>
        <label>
          <span>Wine (ltr)</span>
        </label>
        <input required [(ngModel)]="context.visit.shipStoresDeclaration.items.WINE" id="wine" type="number"
          class="form-control">
      </app-form-group>
      <app-form-group>
        <label>
          <span>Other alcoholic liquids (ltr)</span>
        </label>
        <input required [(ngModel)]="context.visit.shipStoresDeclaration.items.OTHER" id="other" type="number"
          class="form-control">
      </app-form-group>
      <app-form-group>
        <app-info class="formGroupLabel">
          <label>Cigarettes (pcs)</label>
          <span class="tooltipContent">Number of individual cigarettes (not the amount in cartons or packages).</span>
        </app-info>
        <input required [(ngModel)]="context.visit.shipStoresDeclaration.items.CIGARETTES" id="cigarettes" type="number"
          class="form-control">
      </app-form-group>
      <app-form-group>
        <app-info class="formGroupLabel">
          <label>Cigars (pcs)</label>
          <span class="tooltipContent">Number of individual cigars (not the amount in cartons or packages).</span>
        </app-info>
        <input required [(ngModel)]="context.visit.shipStoresDeclaration.items.CIGARS" id="cigars" type="number"
          class="form-control">
      </app-form-group>
    </div>
    <div class="col-md-6">
      <app-form-group>
        <label>
          <span>Tobacco (kgm)</span>
        </label>
        <input required [(ngModel)]="context.visit.shipStoresDeclaration.items.TOBACCO" id="tobacco" type="number"
          class="form-control">
      </app-form-group>
      <app-form-group>
        <label>
          <span>Fuel oil (ltr)</span>
        </label>
        <input required [(ngModel)]="context.visit.shipStoresDeclaration.items.FUEL_OIL" id="fuelOil" type="number"
          class="form-control">
      </app-form-group>
      <app-form-group>
        <label>
          <span>Lubricating oil (ltr)</span>
        </label>
        <input required [(ngModel)]="context.visit.shipStoresDeclaration.items.LUBRICATING_OIL" id="lubricatingOil"
          type="number" class="form-control">
      </app-form-group>
      <app-form-group>
        <label>
          <span>Meat and meat products (kgm)</span>
        </label>
        <input required [(ngModel)]="context.visit.shipStoresDeclaration.items.MEAT" id="meat" type="number"
          class="form-control">
      </app-form-group>
      <app-form-group>
        <label>
          <span>Narcotics (kgm)</span>
        </label>
        <input required [(ngModel)]="context.visit.shipStoresDeclaration.items.NARCOTICS" id="narcotics" type="number"
          class="form-control">
      </app-form-group>
      <app-form-group>
        <label>
          <span>Fire arms and ammunition (pcs)</span>
        </label>
        <input required [(ngModel)]="context.visit.shipStoresDeclaration.items.FIRE_ARMS" id="fireArms" type="number"
          class="form-control">
      </app-form-group>
    </div>
  </div>
</div>

<!-- MOBILE (buttons bottom right) -->
<div
  [ngClass]="{'always-enabled' : appContext.hasRole('VisitDeclarant') && !context.visit.cancelled
  && !(context.findLatestDeclaration(VISIT)?.status === 'DECLARED')}">
  <div class="fixed-bottom mb-3 pe-2 btn-group d-block d-lg-none" style="max-width: 100vw;">
    <div class="row justify-content-end g-2">
      <div class="col-auto">
        <button type="button" class="btn btn-info dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
          Actions
        </button>
        <div class="dropdown-menu dropdown-menu-end">
          <ng-container *ngIf="!isDeclaredOrAccepted() && !this.showSendConfirmation">
            <div class="btn-group fieldset" [ngClass]="isDeclaredOrAccepted() ? 'disabled' :
  context.visitIsDeparted() || !context.isVisitReadonlyIgnoringDeparture() ? 'always-enabled' : ''">
              <button (click)="checkSaveAndSend()" type="button" class="dropdown-item" [disabled]="!isSaveAndSendAllowed()"
                      style="min-width: 6rem" tabindex="-1">Save and send
              </button>
            </div>
          </ng-container>
          <ng-container>
            <div class="fieldset" [ngClass]="isDeclaredOrAccepted() ? 'disabled' :
  context.visitIsDeparted() || !context.isVisitReadonlyIgnoringDeparture() ? 'always-enabled' : ''">
              <a class="dropdown-item" role="button" (click)="uploadInput.click()" title="Upload ship stores declaration">
                Upload<input type="file" hidden (change)="upload($event.target.files[0]); $event.target.value = '';" #uploadInput>
              </a>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="col-auto">
        <app-ship-stores-admin></app-ship-stores-admin>
      </div>
    </div>
  </div>
</div>
