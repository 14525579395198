<!-- STICKY ROW -->
<div class="row sticky-top bg-white" style="top: 5.5rem">

  <!-- Reject reasons-->
  <div class="col-md-12" *ngIf="getRejectReason() as rejectReason">
    <app-status-alert type="danger">{{rejectReason}}</app-status-alert>
  </div>
  <div class="col-md-12">
    <app-alerting></app-alerting>
  </div>
  <!--Send warning-->
  <div class="col-md-12" *ngIf="warnings.length > 0">
    <div class="alert alert-warning fade show mb-0" role="alert">
      <div class="row justify-content-between">
        <div class="me-3 mb-2 mt-2 ms-2 d-inline-block">
          <ul class="ps-0" style="list-style: inside">
            <li *ngFor="let warning of warnings">
              <span [innerHTML]="warning"></span>
            </li>
          </ul>
          <div>Are you sure that you want to send the declaration?</div>
        </div>
        <div class="d-inline-block text-nowrap me-2 align-self-center">
          <button (click)="warnings = []" id="cancelSend" data-bs-dismiss="alert" type="button"
                  class="btn btn-secondary me-2" style="min-width: 6rem" tabindex="-1">Cancel
          </button>
          <button (click)="sendHealthDeclaration()" id="doSend" data-bs-dismiss="alert" type="button"
                  class="btn btn-warning" style="min-width: 6rem" tabindex="-1">Send
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-12">
    <app-action-nav-bar>
      <!-- RIGHT: Action buttons -->
      <ng-container>
        <div class="d-flex flex-row-reverse">
          <!-- Admin actions -->
          <div class="ms-2">
            <app-health-declaration-admin></app-health-declaration-admin>
          </div>
          <!-- User actions -->
          <div class="fieldset btn-group ms-2" [ngClass]="{'disabled' : !healthDeclaration
          || !this.context.hasBeenAcceptedAtLeastOnce(VISIT)}">
            <button (click)="trySendHealthDeclaration()" id="send" type="button" class="btn btn-info">Send</button>
            <button type="button" class="btn btn-info dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
              <span class="visually-hidden">Toggle Dropdown</span>
            </button>
            <div class="dropdown-menu dropdown-menu-end">
              <a *ngIf="healthDeclaration" id="remove" class="dropdown-item text-danger" href="#" role="button"
                 (click)="$event.preventDefault(); removeUpload()">
                <i class="fa fa-ban" aria-hidden="true"></i> Remove
              </a>
            </div>
          </div>
          <!-- Upload health declaration button -->
          <div>
            <button *ngIf="!healthDeclaration" class="btn btn-secondary" role="button" (click)="uploadInput.click()"
                    title="Upload medical declaration of health">
              <span class="fa fa-file-upload"></span><input type="file" hidden
                                                            (change)="upload($event.target.files[0]); $event.target.value = '';" #uploadInput>
            </button>
          </div>
        </div>
      </ng-container>
    </app-action-nav-bar>
  </div>
</div>

<!-- Hint -->
<div *ngIf="healthDeclaration && !this.context.hasBeenAcceptedAtLeastOnce(VISIT)"
     class="row justify-content-end hint-box">
  <div>Health declaration can be sent once the visit declaration has been accepted.</div>
</div>
<div class="row">
  <app-mdoh-clearance class="col-md-12"></app-mdoh-clearance>
  <!--PA Health declaration response-->
  <div *ngIf="healthDeclaration || this.context.visit.healthDeclarationSummary" class="declaration-block col-12">
    <h2>Recovered, ill or dead persons summary</h2>
    <div *ngIf="noRecoveredIllOrDeadPeople()" class="row justify-content-between g-0 py-2">
      <div class="col-5 col-md-4 ps-2"><b>No recovered, ill or dead people reported</b></div>
      <div class="col-auto text-end" style="min-width: 5.5rem"></div>
    </div>
    <div *ngIf="!noRecoveredIllOrDeadPeople()" class="row justify-content-between g-0 bg-secondary text-light py-2">
      <div class="col-5 col-md-4 ps-2">Disposal of case</div>
      <div class="col-3 col-md-6">Amount</div>
      <div class="col-auto text-end" style="min-width: 5.5rem"></div>
    </div>
    <div *ngIf="amountRecoveredAndStillOnBoard() > 0" class="row justify-content-between g-0 py-2">
      <div class="col-5 col-md-4 ps-2">Recovered and still on board</div>
      <div class="col-3 col-md-6 ms-5">{{amountRecoveredAndStillOnBoard()}}</div>
      <div class="col-auto text-end" style="min-width: 5.5rem"></div>
    </div>
    <div *ngIf="amountRecoveredAndEvacuated() > 0" class="row justify-content-between g-0 py-2">
      <div class="col-5 col-md-4 ps-2">Recovered and evacuated</div>
      <div class="col-3 col-md-6 ms-5">{{amountRecoveredAndEvacuated()}}</div>
      <div class="col-auto text-end" style="min-width: 5.5rem"></div>
    </div>
    <div *ngIf="amountIllAndStillOnBoard() > 0" class="row justify-content-between g-0 py-2">
      <div class="col-5 col-md-4 ps-2">Ill and still on board</div>
      <div class="col-3 col-md-6 ms-5">{{amountIllAndStillOnBoard()}}</div>
      <div class="col-auto text-end" style="min-width: 5.5rem"></div>
    </div>
    <div *ngIf="amountIllAndEvacuated() > 0" class="row justify-content-between g-0 py-2">
      <div class="col-5 col-md-4 ps-2">Ill and evacuated</div>
      <div class="col-3 col-md-6 ms-5">{{amountIllAndEvacuated()}}</div>
      <div class="col-auto text-end" style="min-width: 5.5rem"></div>
    </div>
    <div *ngIf="amountDiedAndStillOnBoard() > 0" class="row justify-content-between g-0 py-2">
      <div class="col-5 col-md-4 ps-2">Died and still on board</div>
      <div class="col-3 col-md-6 ms-5">{{amountDiedAndStillOnBoard()}}</div>
      <div class="col-auto text-end" style="min-width: 5.5rem"></div>
    </div>
    <div *ngIf="amountDiedAndEvacuated() > 0" class="row justify-content-between g-0 py-2">
      <div class="col-5 col-md-4 ps-2">Died and evacuated</div>
      <div class="col-3 col-md-6 ms-5">{{amountDiedAndEvacuated()}}</div>
      <div class="col-auto text-end" style="min-width: 5.5rem"></div>
    </div>
    <div *ngIf="amountDiedAndBuriedAtSea() > 0" class="row justify-content-between g-0 py-2">
      <div class="col-5 col-md-4 ps-2">Died and buried at sea</div>
      <div class="col-3 col-md-6 ms-5">{{amountDiedAndBuriedAtSea()}}</div>
      <div class="col-auto text-end" style="min-width: 5.5rem"></div>
    </div>
  </div>

  <!--PA Health declaration response-->
  <div *ngIf="!this.appContext.isClearancesActive() && !healthDeclaration" class="declaration-block col-12 mb-2">
    <h2>Port authority health declaration response</h2>
    <div class="row justify-content-between g-0 bg-secondary text-light py-2">
      <div class="col-5 col-md-4 ps-2">Status</div>
      <div class="col-3 col-md-6">Remarks</div>
      <div class="col-auto text-end" style="min-width: 5.5rem"></div>
    </div>
    <div *ngIf="this.context.visit.healthDeclarationSummary &&
    this.context.visit.healthDeclarationSummary.healthDeclarationStatus"
         class="row justify-content-between g-0 py-2">
      <div class="col-5 col-md-4 ps-2">{{formatHamisStatus()}}</div>
      <div class="col-3 col-md-6 ps-1">{{this.context.visit.healthDeclarationSummary.healthDeclarationRemarks}}
      </div>
      <div class="col-auto text-end" style="min-width: 5.5rem"></div>
    </div>
  </div>
</div>

<div class="disclaimer-box mx-2">
  <div class="disclaimer-label">Note regarding processing of personal data</div>
  <div>
    For processing of personal data we would like to refer to the privacy declaration of the of the <a title="" rel="noopener noreferrer" target="_blank"
                                                                                                       href="https://www.portofrotterdam.com/sites/default/files/2021-06/privacyverklaring-divisie-havenmeester.pdf">Port of Rotterdam privacyverklaring
    (DHMR)</a>
    and to the <a title="" rel="noopener noreferrer" target="_blank" href="https://www.portofamsterdam.com/nl/privacyverklaring-divisie-havenmeester">Port of Amsterdam privacyverklaring.</a>
  </div>
</div>

<!-- MOBILE (buttons bottom right) -->
<div>
  <div class="fixed-bottom mb-3 pe-2 btn-group d-block d-lg-none" style="max-width: 100vw;">
    <div class="row justify-content-end g-2">
      <div class="col-auto">
        <button type="button" class="btn btn-info dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
          Actions
        </button>
        <div class="dropdown-menu dropdown-menu-end">
          <ng-container>
            <!-- User actions -->
            <div class="fieldset btn-group ms-2" [ngClass]="{'disabled' : !healthDeclaration
            || !this.context.hasBeenAcceptedAtLeastOnce(VISIT)}">
              <button (click)="trySendHealthDeclaration()" type="button" class="dropdown-item">Send</button>
            </div>
          </ng-container>
          <ng-container>
            <div>
              <a *ngIf="healthDeclaration" class="dropdown-item text-danger" href="#" role="button"
                 (click)="$event.preventDefault(); removeUpload()">
                <i class="fa fa-ban" aria-hidden="true"></i> Remove
              </a>
            </div>
          </ng-container>
          <ng-container>
            <!-- Upload health declaration button -->
            <div>
              <button *ngIf="!healthDeclaration" class="dropdown-item" role="button" (click)="uploadInput.click()"
                      title="Upload medical declaration of health">
                Upload
                <input type="file" hidden (change)="upload($event.target.files[0]); $event.target.value = '';" #uploadInput>
              </button>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="col-auto">
        <app-passengers-admin></app-passengers-admin>
      </div>
    </div>
  </div>
</div>
