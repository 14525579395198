import {Component, Input} from '@angular/core';
import {WasteMaterial, WasteTypeSsn} from "@portbase/bezoekschip-service-typescriptmodels";
import {PortvisitUtils} from '../portvisit-utils';
import {Observable, of} from "rxjs";
import {sendQuery} from "../../common/utils";

@Component({
  selector: 'app-edit-waste-material',
  templateUrl: './edit-waste-material.component.html',
  styleUrls: ['./edit-waste-material.component.css']
})
export class EditWasteMaterialComponent {
  utils = PortvisitUtils;
  @Input() wasteMaterial: WasteMaterial;
  unitsOfMeasure: Observable<string[]> = of(["kg", "m3", "units"]);

  ssnFormatter = (value: WasteTypeSsn) => value ? value.code : null;

  getAllWasteTypes = () => sendQuery('com.portbase.bezoekschip.common.api.visit.GetWasteTypesSsn', {});


}
