<div class="dropdown always-enabled">
  <button class="btn" [ngClass]="selectedOptions.length > 0 || deselectedOptions.length > 0 ? 'btn-danger' : 'btn-outline-info'" type="button"
          id="dropdownMenuButton" data-bs-toggle="dropdown" data-bs-reference="parent" aria-haspopup="true" aria-expanded="false">
    <span class="fa fa-filter m-0"></span>
  </button>
  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" (click)="$event.stopPropagation()">
    <div class="p-3" style="min-width: 550px">
      <div class="row g-0">
        <div *ngFor="let option of options; let index = index" class="col-sm-6 col-xl-4">

          <div class="row narrow-gutters align-items-center">
            <div class="col text-end">
              <span style="cursor: pointer"
                    (click)="isToggled(option) ? reset(option) : toggleOn(option)">{{option}}</span>
            </div>
            <div class="col-auto" style="min-width: 4rem">
              <div class="btn-group">
                <button type="button" class="btn px-2 py-1" style="font-size: 0.5rem;"
                        (click)="toggleOn(option)"
                        [ngClass]="isOn(option) ? 'btn-success' : 'btn-outline-success'">
                  <span class="fa fa-plus"></span>
                </button>
                <button type="button" class="btn px-2 py-1" style="font-size: 0.5rem;"
                        (click)="toggleOff(option)"
                        [ngClass]="isOff(option) ? 'btn-danger' : 'btn-outline-danger'">
                  <span class="fa fa-minus"></span>
                </button>
              </div>
            </div>
          </div>

        </div>

        <div class="col-auto ms-auto mt-2" *ngIf="selectedOptions.length > 0 || deselectedOptions.length > 0">
          <button class="me-1 p-0 btn btn-secondary border-0 bg-transparent text-secondary" (click)="resetAll()" style="font-size: 0.8rem;">
            <span class="fa fa-ban me-1"></span> Clear all
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
