import {Component, OnInit} from '@angular/core';
import {VisitContext} from '../../visit-context';
import {openConfirmationModal, sendCommand} from "../../../common/utils";
import {AcceptShipStores, Declaration, RejectShipStores} from "@portbase/bezoekschip-service-typescriptmodels";
import {AppContext} from "../../../app-context";
import {PortvisitUtils} from '../../../refdata/portvisit-utils';

@Component({
  selector: 'app-ship-stores-admin',
  templateUrl: './ship-stores-admin.component.html',
  styleUrls: ['./ship-stores-admin.component.css']
})
export class ShipStoresAdminComponent implements OnInit {
  protected readonly openConfirmationModal = openConfirmationModal;
  context = VisitContext;
  appContext = AppContext;
  downloadLink = null;
  downloadUri = "/api/uploads/";

  ngOnInit(): void {
    const key: string = VisitContext.visit.uploads.MSV;
    if (key) {
      this.downloadLink = this.downloadUri + key;
    }
  }

  openSwMessages() {
    PortvisitUtils.redirectToMessageManagement(VisitContext.visit.crn + '_sto2sw')
  }

  accept() {
    sendCommand('com.portbase.bezoekschip.common.api.shipstores.AcceptShipStores', <AcceptShipStores>{
      crn: VisitContext.visit.crn
    }, () => {
      VisitContext.visit.declarations.push(<Declaration>{
        type: 'MSV',
        status: 'ACCEPTED'
      });
      VisitContext.replaceVisit(VisitContext.visit);
      AppContext.registerSuccess('The declaration was set to accepted successfully.');
    });
  }

  reject(reason:string) {
    sendCommand('com.portbase.bezoekschip.common.api.shipstores.RejectShipStores', <RejectShipStores>{
      crn: VisitContext.visit.crn,
      reason: reason
    }, () => {
      VisitContext.visit.declarations.push(<Declaration>{
        type: 'MSV',
        status: 'REJECTED'
      });
      VisitContext.replaceVisit(VisitContext.visit);
      AppContext.registerSuccess('The declaration was set to rejected successfully.');
    });
  }
}
