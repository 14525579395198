import {Component, Input, OnInit} from '@angular/core';
import {BerthVisit} from '@portbase/bezoekschip-service-typescriptmodels';
import moment from 'moment';
import {VisitContext} from '../../visit-context';
import {ShipMovementService} from '../ship-movement.service';

@Component({
	selector: 'app-movement-duration-summary',
	templateUrl: './movement-duration-summary.component.html',
	styleUrls: ['./movement-duration-summary.component.css']
})
export class MovementDurationSummaryComponent implements OnInit {

	context = VisitContext;

	@Input() currentBerthVisit: BerthVisit;

	constructor(private shipMovementService: ShipMovementService) { }

	ngOnInit() {
	}

	getMovementDuration() : string{
		var duration : moment.Duration;
		let visit = this.context.visit;
		if (this.currentBerthVisit) {
			// Movement is from a berth
			let nextBerthVisit = this.shipMovementService.nextBerth(this.currentBerthVisit);
			if (nextBerthVisit && (this.currentBerthVisit.atd || this.currentBerthVisit.etd) && (nextBerthVisit.eta || nextBerthVisit.ata)) {
				duration = moment.duration(moment(nextBerthVisit.ata ? nextBerthVisit.ata : nextBerthVisit.eta)
					.diff(moment(this.currentBerthVisit.atd ? this.currentBerthVisit.atd : this.currentBerthVisit.etd)));
			}
			else if (this.currentBerthVisit.etd && visit.visitDeclaration.portVisit.etdPort){
				duration = moment.duration(moment(visit.visitDeclaration.portVisit.atdPort ?
					visit.visitDeclaration.portVisit.atdPort : visit.visitDeclaration.portVisit.etdPort)
					.diff(moment(this.currentBerthVisit.atd ? this.currentBerthVisit.atd : this.currentBerthVisit.etd)));
			}
		} else {
			// No berth provided so assume movement is from point of port entry to:
			// - First berth or
			// - Exit (if passing through)
			let portVisit = visit.visitDeclaration.portVisit;
			let etaEntry = portVisit.portEntry && portVisit.portEntry.etaPilotBoardingPlace ?
				(visit.incomingMovementHarbourMasterInfo.pilotOnBoard ? visit.incomingMovementHarbourMasterInfo.pilotOnBoard : portVisit.portEntry.etaPilotBoardingPlace) : portVisit.etaPort;
			let etaFirstBerthOrPortDeparture = portVisit.berthVisits[0] ? (portVisit.berthVisits[0].ata ? portVisit.berthVisits[0].ata : portVisit.berthVisits[0].eta) :
				(portVisit.atdPort ? portVisit.atdPort : portVisit.etdPort);
			if (etaEntry && etaFirstBerthOrPortDeparture) {
				duration = moment.duration(moment(etaFirstBerthOrPortDeparture).diff(moment(etaEntry)));
			}
		}

		if (duration && duration.asMilliseconds() > 0) {
			var days = Math.floor(duration.asDays());
			var hours = Math.floor(duration.asHours()); //hours instead of asHours
			var minutes = duration.minutes(); //minutes instead of asMinutes
			return (days ? (days + ' days') : (hours ? hours + 'h' : '') + (minutes ? minutes + 'm' : ''));
		}
		else return null;
	}

}
