import {Component, Input, OnDestroy} from '@angular/core';
import {Port} from '@portbase/bezoekschip-service-typescriptmodels';
import {checkValidity, cloneObject, dispatchChangeEvent, removeIf, removeItem} from '../../../../common/utils';
import {AppContext} from '../../../../app-context';
import {VisitContext} from '../../../visit-context';
import {PortvisitUtils} from '../../../../refdata/portvisit-utils';
import {ImportContainerModel} from '../../cargo-import.model';
import {DateFieldComponent} from "../../../../common/date/date-field/date-field.component";

@Component({
  selector: 'app-containers',
  templateUrl: './containers.component.html',
  styleUrls: ['./containers.component.css']
})
export class ContainersComponent implements OnDestroy {
  context = VisitContext;
  appContext = AppContext;
  utils = PortvisitUtils;
  @Input() containers: ImportContainerModel[] = [];
  @Input() showTerminal: boolean;
  @Input() readonly: boolean;

  get hiddenItems(): ImportContainerModel[] {
    return this.containers.filter(g => g['hidden']);
  }

  get visibleItems(): ImportContainerModel[] {
    return this.containers.filter(s => !s['hidden']);
  }

  showAll() {
    this.containers.forEach(g => {
      delete g['hidden'];
      g['forceVisible'] = true;
    });
  }

  removeContainer(container: ImportContainerModel) {
    return () => {
      const manifest = VisitContext.cargoImportModel.manifests.find(
        m => m.containers.some(c => c.number === container.number));

      if (!!manifest) {
        removeItem(manifest.containers, container);
        removeIf(manifest.consignments, c => {
          const removedGoods = removeIf(c.goodsItems, g => {
            const removedPlacements = removeIf(g.placements, p => p.equipmentNumber === container.number);
            return removedPlacements && g.placements.length == 0;
          })
          return removedGoods && c.goodsItems.length == 0;
        })
      }
    }
  }

  duplicateContainer(container: ImportContainerModel) {
    const manifest = VisitContext.cargoImportModel.manifests.find(
      m => m.containers.some(c => c.number === container.number));
    const newContainer = cloneObject(container);
    newContainer.selected = false;
    newContainer['duplicate'] = true;
    newContainer.ngInvalid = true;
    newContainer.number = null;
    newContainer.dischargeResult = null;
    newContainer.declared = false;
    manifest.containers.push(newContainer);
  }

  /*
    Bulk actions
   */

  get selectedItems(): ImportContainerModel[] {
    return this.containers.filter(s => !!s['selected'] && !s['hidden']);
  }

  removeSelected() {
    this.selectedItems.forEach(c => this.removeContainer(c)());
  }

  duplicatedSelected() {
    this.selectedItems.forEach(c => this.duplicateContainer(c));
  }

  get currentDemurrage() {
    const values = new Set<any>(this.selectedItems.map(c => c.initialDemurrage));
    return values.size === 1 ? values.values().next().value : null;
  }

  bulkUpdateDemurrage(modal: HTMLDivElement, demurrage: DateFieldComponent) {
    if (checkValidity(modal)) {
      this.selectedItems.forEach(c => c.initialDemurrage = demurrage.value);
      $(modal).modal('hide');
    }
    AppContext.clearAlerts();
  }

  get currentRedelivery() {
    const values = new Set<any>(this.selectedItems.map(c => c.redeliveryAddress));
    return values.size === 1 ? values.values().next().value : null;
  }

  get currentContainer() {
    const values = new Set<any>(this.selectedItems);
    return values.size === 1 ? values.values().next().value : null;
  }

  bulkUpdateRedelivery(modal: HTMLDivElement, redelivery: HTMLTextAreaElement) {
    if (checkValidity(modal)) {
      this.selectedItems.forEach(c => c.redeliveryAddress = redelivery.value);
      $(modal).modal('hide');
    }
    AppContext.clearAlerts();
  }

  ngOnDestroy(): void {
    this.containers.forEach(c => {
      delete c['hidden'];
      delete c['selected'];
    });
  }

  moveSelectedContainers(portOfLoading: Port) {
    const placements = VisitContext.cargoImportModel.getAllPlacements();
    this.selectedItems.forEach(c => {
      if (!c.portOfLoading || portOfLoading.locationUnCode !== c.portOfLoading.locationUnCode) {
        c.portOfLoading = portOfLoading;
        let manifest = VisitContext.cargoImportModel.manifests.find(value => value.portOfLoading.locationUnCode === portOfLoading.locationUnCode);
        let previousPort = VisitContext.visit.visitDeclaration.previousPorts.find(v => portOfLoading.locationUnCode === v.port.locationUnCode);
        c.actualDeparture = manifest ? manifest.getActualDeparture() : (previousPort ? previousPort.departure : null);
        placements.filter(p => p.equipmentNumber === c.number).forEach(p => delete p.equipmentNumber);
      }
    });
    dispatchChangeEvent(document.body);
  }

  mayBeDuplicated(containers: ImportContainerModel[]): boolean {
    return containers.every(c => c.empty);
  }

  toggleSelectAll() {
    if (this.selectedItems.length === this.visibleItems.length) {
      this.visibleItems.forEach(c => c['selected'] = false);
    } else {
      this.visibleItems.forEach(c => c['selected'] = true);
    }
  }
}
