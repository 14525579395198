<ng-container *ngIf="(appContext.isAdmin() || context.isOrganisationPortAuthority()) && !context.eventId">

  <div *ngIf="context.isOrganisationPortAuthority()" class="always-enabled">
    <button data-bs-toggle="modal" data-bs-target="#rejectDgModal" id="rejectDg"
            type="button" class="btn btn-secondary me-2" style="min-width: 5rem" tabindex="-1">Reject
    </button>
    <button (click)="acceptDg()" id="acceptDg"
            type="button" class="btn btn-secondary me-2" style="min-width: 5rem" tabindex="-1">Accept
    </button>
  </div>

  <div class="dropdown always-enabled" *ngIf="!context.isOrganisationPortAuthority()">
    <button class="btn btn-secondary" type="button" id="adminHamburgerMenu" data-bs-toggle="dropdown" aria-haspopup="true"
      aria-expanded="false">
      <!-- Hamburger menu icon -->
      <i class="fa fa-user-shield" aria-hidden="true"></i>
    </button>
    <div class="dropdown-menu dropdown-menu-end" aria-labelledby="adminHamburgerMenu">
      <!-- Accept declaration -->
      <a (click)="acceptDg()" id="acceptDg" class="dropdown-item text-success" tabindex="-1" role="button">
        <i class="far fa-check-circle" aria-hidden="true"></i> Accept
      </a>
      <!-- Reject declaration -->
      <a (click)="openConfirmationModal(rejectModal)" id="rejectDg" class="dropdown-item text-danger" tabindex="-1"
        role="button">
        <i class="far fa-times-circle" aria-hidden="true"></i> Reject
      </a>
      <ng-container *ngIf="appContext.isAdmin()">
        <!-- Download a previously uploaded declaration-->
        <ng-container *ngIf="context.dangerousGoodsDeclaration?.upload">
          <h6 class="dropdown-header" style="font-size: 18px;">Previous upload</h6>
          <a download="{{context.visit.crn}} + '_dg.xls'"
            href="/api/uploads/{{context.dangerousGoodsDeclaration.upload}}" class="dropdown-item">
            <i class="far fa-file-excel me-1" aria-hidden="true"></i> {{context.visit.crn}}_dg.xls
          </a>
        </ng-container>
        <!-- Messages -->
        <h6 class="dropdown-header" style="font-size: 18px;">Messages</h6>
        <a href="#" (click)="$event.preventDefault(); openMessages('dg2pa')" class="dropdown-item">
          DG to Port Authority
        </a>
        <a href="#" (click)="$event.preventDefault(); openMessages('dg2otherpa')" class="dropdown-item">
          DG to Moerdijk
        </a>
        <a href="#" (click)="$event.preventDefault(); openMessages('hza2sw')" class="dropdown-item">
          HZA to Singlewindow
        </a>
        <a href="#" (click)="$event.preventDefault(); openMessages('hzd2sw')" class="dropdown-item">
          HZD to Singlewindow
        </a>
        <ng-container *ngFor="let goods of context.visit.dangerousGoodsDeclarations">
          <ng-container *ngFor="let processId of goods.processIds">
            <a href="#" (click)="$event.preventDefault(); openIftdgn(processId)" class="dropdown-item">
              IFTDGN from {{goods.cargoDeclarant.shortName}}
            </a>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-template #rejectModal>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Reject reason</h5>
    </div>
    <div class="modal-body">
      <input id="rejectDg-reason" type="text" class="form-control">
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Back</button>
      <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
        (click)="rejectDg(appContext.getDocument().getElementById('rejectDg-reason')['value'])">Send</button>
    </div>
  </div>
</ng-template>
