import {Component, Input} from '@angular/core';
import {IE3GoodsItem} from '@portbase/bezoekschip-service-typescriptmodels';
import {PortvisitUtils} from "../../refdata/portvisit-utils";

@Component({
  selector: 'app-transhipment-goods-placement',
  templateUrl: './transhipment-goods-placement.component.html',
  styleUrls: ['./transhipment-goods-placement.component.css']
})
export class TranshipmentGoodsPlacementComponent {
  refData = PortvisitUtils;
  @Input() item: IE3GoodsItem;
}
