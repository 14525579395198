<div class="border-bottom container-fluid mw-100">
  <div class="row px-3 py-2 gx-4 main-row" *ngIf="consignment">
    <div class="d-none col-auto d-md-flex align-items-center" [style.width.px]="selectionWidth"></div>
    <div class="col-3 d-flex align-items-center" [style.padding-left]="indentionDepth+'px'">
      <button type="button" class="btn btn-plain text-end p-2 me-2 rounded-circle" (click)="collapsed = !collapsed"
              [ngClass]="collapsible ? '' : 'invisible'"
              data-bs-toggle="collapse" aria-expanded="false" style="visibility: hidden">
        <span class="h1 m-0 align-top fa-light fa-fw text-primary" [ngClass]="collapsed ? 'fa-chevron-down' : 'fa-chevron-right'"></span>
      </button>
      <span class="sub-text me-2">
        <i class="fa fa-fw fa-light fa-xl fa-file-lines"></i>
      </span>
      <div class="h-100 d-flex flex-column align-items-stretch justify-content-center">
        <span class="d-inline-block text-truncate mw-100 cursor-pointer underline-tertiary link-underline-tertiary link-primary text-body"
              (click)="editConsignment()">
          <app-highlight [term]="term" [result]="consignment.consignmentNumber"></app-highlight>
        </span>
        <span class="sub-text">Consignment number</span>
      </div>
    </div>
    <div class="col-auto ms-auto"></div>
    <div *ngIf="cargoDeclarantId" class="col-auto d-flex flex-column align-items-stretch justify-content-center dropdown px-2">
      <a class="btn rounded-circle p-2 text-primary h1 mb-0 action-btn" type="button" (click)="editConsignment()">
        <i class="fa-light fa-fw fa-angle-right"></i>
      </a>
    </div>
  </div>
</div>
