<div class="form-group row" [class]="customClass" [ngClass]="includeMargin ? 'mb-3' : ''">
  <div class="col-12 col-form-label">
    <ng-container *ngIf="tooltip || tooltipTemplateRef; else labelText">
      <span class="form-field-label">
        <ng-container *ngTemplateOutlet="labelText"></ng-container>
      </span>
      <app-tooltip [placement]="'top'">
        <i class="fa-light fa-circle-info info-icon text-black"></i>
        <div class="tooltipContent">
          <div class="tooltip-card">
            <div class="tooltip-card-body">
              <ng-container *ngIf="tooltip else tooltipAsRef">
                {{tooltip}}
              </ng-container>
              <ng-template #tooltipAsRef>
                <ng-container *ngTemplateOutlet="tooltipTemplateRef"></ng-container>
              </ng-template>
            </div>
          </div>
        </div>
      </app-tooltip>
    </ng-container>
  </div>
  <div class="col-12">
    <ng-container *ngIf="_editMode else displayMode">
      <ng-content></ng-content>
    </ng-container>
    <ng-template #displayMode>
      <span class="col-12" [ngClass]="(hasValue ? '' : ' text-muted ') + (truncateReadOnly ? ' d-inline-block text-truncate ' : '') + (!hasValue && required ? ' ng-invalid ' : ' ')">{{readOnlyValue}}</span>
    </ng-template>
  </div>
</div>

<ng-template #labelText>
  <ng-container *ngIf="compareDifferent else normalLabel">
    <button onPopover class="has-diff btn btn-plain d-inline-block p-0"
            data-bs-toggle="popover" [popoverContent]="comparePopover" [placement]="'top'"
            aria-describedby="tooltip" popoverClass="pop-auto-container position-fixed">
      <ng-container *ngIf="label; else fromTemplate"><label class="fw-regular">{{label}}</label></ng-container>
      <span *ngIf="!required && renderOptional && _editMode" class="form-field-optional text-secondary">(optional)</span>
      <span class="badge rounded-pill text-bg-primary ms-1 text-uppercase">New</span>
    </button>
  </ng-container>
</ng-template>

<ng-template #normalLabel>
  <ng-container *ngIf="label; else fromTemplate"><label class="fw-regular">{{label}}</label></ng-container>
  <span *ngIf="!required && renderOptional && _editMode" class="form-field-optional text-secondary">(optional)</span>
</ng-template>

<ng-template #fromTemplate>
  <ng-content select="label"></ng-content>
  <ng-content select=".formGroupLabel"></ng-content>
</ng-template>

<ng-template #comparePopover>
  <div class="w-100 h-100 text-start p-1 pe-4" [style.max-width.px]="320">
    <div class="btn-close-wrapper rounded-circle position-absolute end-0 top-0 mt-2 me-2">
      <button type="button" class="btn-close" data-bs-toggle="popover" aria-label="Close"></button>
    </div>
    <div>
      <span class="d-block fw-medium">{{compareHeader}}</span>
      <span class="d-block my-2" style="line-height: 1.25rem">{{compareValue}}</span>
    </div>
    <div *ngIf="_editMode" class="btn btn-plain text-primary p-0" (click)="useCompareValue()">
      <i class="fa fa-light fa-fw fa-arrow-down-to-line me-2"></i>
      <span>Insert</span>
    </div>
  </div>
</ng-template>
