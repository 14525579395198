<div class="my-3"><i>Updates the sizetypes in both bezoekschip and hinterland</i></div>
<div class="row">
  <div class="col-md-6">
    <app-form-group><label>Id</label><input class="form-control" [(ngModel)]="sizetype['id']" disabled required></app-form-group>
    <app-form-group><label>Code</label><input class="form-control" [(ngModel)]="sizetype.code" (ngModelChange)="updateIsoCode($event)" required></app-form-group>
  </div>
  <div class="col-md-6">
    <app-form-group><label>Name</label><input class="form-control" [(ngModel)]="sizetype.name" ></app-form-group>
    <app-form-group><label>Code (ISO 2022)</label><input class="form-control" [(ngModel)]="sizetype.isoCode" disabled></app-form-group>
    <app-form-group><label>UNECE Code (CL710 in ICS2)</label><input class="form-control" [(ngModel)]="sizetype.uneceSizeType" disabled></app-form-group>
  </div>
</div>
