<app-form-group label="Discharge">
  <input [(ngModel)]="berthVisit.discharge"
         required
         id="{{berthVisit.id}}-discharge" appDecimalNumber [maxDecimals]="0" class="form-control"
         [appCompare]="terminalVisit?.info?.expectedDischarge"
         [compareInline]="true"
         comparePreamble="Terminal reported: ">
</app-form-group>
<app-form-group label="Load">
  <input [(ngModel)]="berthVisit.load"
         required
         id="{{berthVisit.id}}-load" appDecimalNumber [maxDecimals]="0" class="form-control"
         [appCompare]="terminalVisit?.info?.expectedLoad"
         [compareInline]="true"
         comparePreamble="Terminal reported: ">
</app-form-group>
<app-form-group label="Restow">
  <input [(ngModel)]="berthVisit.restow"
         id="{{berthVisit.id}}-restow" appDecimalNumber [maxDecimals]="0" class="form-control"
         [appCompare]="terminalVisit?.info?.expectedRestow"
         [compareInline]="true"
         comparePreamble="Terminal reported: ">
</app-form-group>
