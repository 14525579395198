import {
  ArrayTemplate,
  DateField,
  parseExcel,
  RequiredField,
  RequiredIfField,
  ValidatedField,
  WorkBookTemplate
} from '../../common/upload/excel.utils';

import {
  CrewMember,
  Gender,
  PaxDeclaration,
  PersonOnBoard,
  TransitIndicator,
  TravelDocumentType
} from '@portbase/bezoekschip-service-typescriptmodels';
import {VisitContext} from '../visit-context';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {cloneObject} from '../../common/utils';
import {AppContext} from '../../app-context';
import moment from "moment";

export function uploadPax(excelFile: File): Observable<PaxDeclaration[]> {
  return parseExcel(excelFile, crewAndPassengerListTemplate).pipe(map(model => {
    const result = [
      model,
      <PaxDeclaration>{
        crewMembers: model.crewMembers.map(p => convertCrew(p, model)),
        passengers: model.passengers.map(p => convertPerson(p, model)),
        crewMembersOtherShip: model.crewMembersOtherShip.map(p => convertPerson(p, model)),
        stowaways: model.stowaways.map(p => convertPerson(p, model))
      }
    ];
    return AppContext.hasErrors() ? [null, null] : result;
  }));
}

const codeTemplate: any = {
  description: new RequiredField('A$'),
  code: new RequiredField('B$')
};

const crewAndPassengerListTemplate: WorkBookTemplate = {
  sheets: [
    {
      name: 'General',
      template: {
        nameOfShip: 'A4',
        imoNumber: new ValidatedField(new RequiredField('B4'),
          value => {
            const expectedImo = VisitContext.visit.vessel.imoCode;
            if (String(value) !== expectedImo) {
              throw 'The IMO code in the Excel file (' + value + ') does not match IMO code of the vessel (' + expectedImo + ').';
            }
          }),
        portOfCall: 'D4',

        version: new ValidatedField(new RequiredField('B6'),
          value => {
            if (value !== '1.5') {
              throw 'The version of your Excel file is not supported. Please download the latest template and try again.';
            }
          })
      }
    },
    {
      name: 'Crew',
      template: {
        crewMembers: new ArrayTemplate(computePersonTemplate(template => {
          template.jobTitle = new RequiredField('V$');
          return template;
        }), [5, 4004])
      }
    },
    {
      name: 'Passengers',
      template: {
        passengers: new ArrayTemplate(computePersonTemplate(template => {
          template.specialCareHandlingInformation = 'V$';
          return template;
        }), [5, 10004])
      }
    },
    {
      name: 'Crew other ship',
      template: {
        crewMembersOtherShip: new ArrayTemplate(computePersonTemplate(template => {
          template.familyName = 'A$';
          template.givenNames = 'B$';
          template.nationality = 'D$';
          template.dateOfBirth = new DateField('E$');
          return template;
        }), [5, 204])
      }
    },
    {
      name: 'Stowaways',
      template: {
        stowaways: new ArrayTemplate(computePersonTemplate(template => {
          template.familyName = "A$";
          template.givenNames = "B$";
          template.nationality = "D$";
          template.dateOfBirth = new DateField("E$");
          template.travelDocuments[0] = {
            type: new RequiredIfField('G$', ['H$', 'I$', 'J$']),
            number: new RequiredIfField('H$', ['G$', 'I$', 'J$']),
            expiryDate: new RequiredIfField(new DateField('I$'), ['G$', 'H$', 'J$']),
            issuingCountry: new RequiredIfField('J$', ['G$', 'H$', 'I$'])
          };
          return template;
        }), [5, 204])
      }
    },
    {
      name: 'Job title',
      template: {jobTitles: new ArrayTemplate(codeTemplate, [2, 106])}
    },
    {
      name: 'Gender',
      template: {genderCodes: new ArrayTemplate(codeTemplate, [2, 12])}
    },
    {
      name: 'Country',
      template: {countries: new ArrayTemplate(codeTemplate, [2, 499])}
    },
    {
      name: 'Nationality',
      template: {nationalities: new ArrayTemplate(codeTemplate, [2, 507])}
    },
    {
      name: 'Travel doc other',
      template: {travelDocsOther: new ArrayTemplate(codeTemplate, [2, 12])}
    },
    {
      name: 'Travel doc crew',
      template: {travelDocsCrew: new ArrayTemplate(codeTemplate, [2, 12])}
    },
    {
      name: 'Transit',
      template: {transitCodes: new ArrayTemplate(codeTemplate, [2, 12])}
    },
    {
      name: '(Dis)embarkation',
      template: {portOfCallCodes: new ArrayTemplate(codeTemplate, [2, 12])}
    },
    {
      name: 'Verificatie',
      template: {
        verificationHash: new ValidatedField(new RequiredField('A1'),
          value => {
            if (value !== '842d09dce8a21e90645c8e69d598db4df781e0d7') {
              throw 'Your Excel file could not be verified. Please download the latest template and try again.';
            }
          })
      }
    }
  ]
};

function computePersonTemplate(mapper: (template) => any) {
  const personTemplate: any = {
    familyName: new ValidatedField(new RequiredField('A$'), sizeValidator(0, 70)),
    givenNames: new ValidatedField(new RequiredField('B$'), sizeValidator(0, 70)),
    gender: 'C$',
    nationality: new RequiredField('D$'),
    dateOfBirth: new RequiredField(new DateField('E$')),
    placeOfBirth: new ValidatedField('F$', sizeValidator(0, 35)),
    travelDocuments: [
      {
        type: new RequiredField('G$'),
        number: new ValidatedField(new RequiredField('H$'), sizeValidator(0, 35)),
        expiryDate: new RequiredField(new DateField('I$')),
        issuingCountry: new RequiredField('J$'),
      },
      {
        type: new RequiredIfField('K$', ['L$', 'M$', 'N$']),
        number: new ValidatedField(new RequiredIfField('L$', ['K$', 'M$', 'N$']), sizeValidator(0, 35)),
        expiryDate: new RequiredIfField(new DateField('M$'), ['K$', 'L$', 'N$']),
        issuingCountry: new RequiredIfField('N$', ['K$', 'L$', 'M$'])
      },
      {
        type: new RequiredIfField('O$', ['P$', 'Q$', 'R$']),
        number: new ValidatedField(new RequiredIfField('P$', ['O$', 'Q$', 'R$']), sizeValidator(0, 35)),
        expiryDate: new RequiredIfField(new DateField('Q$'), ['O$', 'P$', 'R$']),
        issuingCountry: new RequiredIfField('R$', ['O$', 'P$', 'Q$'])
      }],
    embarkingAtPortOfCall: new ValidatedField(new RequiredField('S$'), yesNoValidator),
    disembarkingAtPortOfCall: new ValidatedField(new RequiredField('T$'), yesNoValidator),
    transitIndicator: 'U$'
  };

  return mapper(cloneObject(personTemplate));
}

function yesNoValidator(value: any) {
  if (!value) {
    return;
  }
  if (!(value.toUpperCase() === 'YES' || value.toUpperCase() === 'NO')) {
    throw 'Possible values (Yes/No)';
  }
}

function sizeValidator(min, max) {
  return (value: any) => {
    if (!value) {
      return;
    }
    if (value.length < min || value.length > max) {
      throw 'Value must be between ' + min + ' and ' + max + ' characters long';
    }
  }
}

function convertCrew(person: any, referenceData: any): CrewMember {
  const result: CrewMember = <CrewMember>convertPerson(person, referenceData);
  result.jobTitle = findJobTitle(person, person.jobTitle, referenceData.jobTitles);
  return result;

}

function convertPerson(person: any, referenceData: any): PersonOnBoard {
  const result: PersonOnBoard = cloneObject(person);
  result.gender = convertGender(person, person.gender);
  result.transitIndicator = convertTransitIndicator(person, person.transitIndicator);
  result.nationality = findNationality(person, person.nationality, referenceData.nationalities);
  result.embarkingAtPortOfCall = person.embarkingAtPortOfCall && person.embarkingAtPortOfCall.toUpperCase() === 'YES';
  result.disembarkingAtPortOfCall = person.disembarkingAtPortOfCall &&
    person.disembarkingAtPortOfCall.toUpperCase() === 'YES';
  result.travelDocuments.forEach(traveldocument => {
    if (moment(traveldocument.expiryDate).isBefore(moment("01-01-2000"))) {
      AppContext.registerError('Document ' + traveldocument.number + ' of ' + person.familyName + ' has expired on ' +
        traveldocument.expiryDate)
    }
    traveldocument.type = convertDocumentType(person, traveldocument.type);
    traveldocument.issuingCountry = findCountry(person, traveldocument.issuingCountry, referenceData.countries);
  });
  return result;
}

function convertGender(person: PersonOnBoard, gender: string): Gender {
  if (gender) {
    switch (String(gender).toUpperCase()) {
      case 'MALE':
        return Gender.MALE;
      case 'FEMALE':
        return Gender.FEMALE;
      case 'NOT KNOWN':
        return Gender.NOT_KNOWN;
      default:
        AppContext.registerError('Unrecognized gender for (' + person.familyName + ', ' + person.givenNames + ') gender: ' + gender);
    }
  }
}

function convertDocumentType(person: PersonOnBoard, documentType: string): TravelDocumentType {
  if (documentType) {
    switch (String(documentType).toUpperCase()) {
      case 'IDENTITY CARD':
        return TravelDocumentType.IDENTITY_CARD;
      case 'PASSPORT':
        return TravelDocumentType.PASSPORT;
      case 'REGISTRATION DOCUMENT':
        return TravelDocumentType.REGISTRATION_DOCUMENT;
      case 'VISA':
        return TravelDocumentType.VISA;
      case 'SEAMAN’S BOOK':
        return TravelDocumentType.SEAMANS_BOOK;
      case 'RESIDENCE PERMIT':
        return TravelDocumentType.RESIDENCE_PERMIT;
      default:
        AppContext.registerError('Unrecognized document type for (' + person.familyName + ', ' + person.givenNames + ') document type: ' + documentType);
    }
  }
}

function convertTransitIndicator(person: PersonOnBoard, transitIndicator: string): TransitIndicator {
  if (transitIndicator) {
    switch (String(transitIndicator).toUpperCase()) {
      case 'PERSON WILL NOT LEAVE THE VESSEL (NO)':
        return TransitIndicator.PERSON_WILL_NOT_LEAVE_THE_VESSEL;
      case 'PERSON WILL LEAVE THE VESSEL TEMPORARILY DURING VISIT (OFF)':
        return TransitIndicator.PERSON_WILL_LEAVE_THE_VESSEL_TEMPORARILY_DURING_VISIT;
      case 'PERSON HAS LEFT THE VESSEL BUT IS ON BOARD AGAIN (ON)':
        return TransitIndicator.PERSON_HAS_LEFT_THE_VESSEL_BUT_IS_ON_BOARD_AGAIN;
      default:
        AppContext.registerError('Unrecognized transitIndicator for (' + person.familyName + ', ' + person.givenNames + ') transitIndicator: ' + transitIndicator);
    }
  }
}

function findJobTitle(person: PersonOnBoard, value, list) {
  return tryFinding(() => findCode(value, list), 'Unrecognized job title for (' + person.familyName + ', ' + person.givenNames + ') job title: ' + value);
}

function findCountry(person: PersonOnBoard, value, list) {
  return tryFinding(() => findCode(value, list), 'Unrecognized country for (' + person.familyName + ', ' + person.givenNames + ') country: ' + value);
}

function findNationality(person: PersonOnBoard, value, list) {
  return tryFinding(() => findCode(value, list), 'Unrecognized nationality for (' + person.familyName + ', ' + person.givenNames + ') nationality: ' + value);
}

function findCode(value, list) {
  if (!value) {
    return null;
  }

  if (typeof value !== "string") {
    throw new Error(value + ' not a string');
  }

  const found = value && list.find(v => v.description.toUpperCase() === value.toUpperCase());
  if (!found) {
    throw new Error(value + ' not found.');
  }
  return found.code;
}

function tryFinding(f: Function, onError: string) {
  try {
    return f();
  } catch (e) {
    AppContext.registerError(onError);
  }
}
