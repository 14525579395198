import {Component, Input, OnInit} from '@angular/core';
import {VisitContext} from '../../../visit-details/visit-context';
import {CommercialReleaseLogItem, GetCommercialReleaseHistoryLog} from '@portbase/bezoekschip-service-typescriptmodels';
import {ComparatorChain} from '../../../common/comparator-chain';
import {sendQuery} from '../../../common/utils';

@Component({
  selector: 'app-commercial-release-history',
  templateUrl: './commercial-release-history.component.html',
  styleUrls: ['./commercial-release-history.component.scss']
})
export class CommercialReleaseHistoryComponent implements OnInit {

  context = VisitContext;
  items: CommercialReleaseLogItem[] = [];
  reverseOrder = true;
  @Input() id: string;

  private logComparator = new ComparatorChain("timestamp").compare;

  ngOnInit(): void {
    sendQuery("com.portbase.bezoekschip.common.api.commercialrelease.GetCommercialReleaseHistoryLog", <GetCommercialReleaseHistoryLog>{id: this.id},
      {showSpinner: true, caching: false})
      .subscribe((items: CommercialReleaseLogItem[]) => this.items = items.sort(this.logComparator));
  }

  cast = (item: CommercialReleaseLogItem): CommercialReleaseLogItem => item;

  toggleOrder() {
    this.reverseOrder = !this.reverseOrder;
  }
}
