import {Component, ElementRef, forwardRef, ViewChild} from '@angular/core';
import {CheckboxSelectionState} from "../utils";
import {AbstractValueAccessorComponent} from "../component/value-accessor.component";
import {NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'app-selection-checkbox',
  templateUrl: './selection-checkbox.component.html',
  styleUrls: ['./selection-checkbox.component.scss'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SelectionCheckboxComponent), multi: true}
  ],
})
export class SelectionCheckboxComponent extends AbstractValueAccessorComponent<CheckboxSelectionState> {
  inputValue: CheckboxSelectionState;

  @ViewChild("inputEl") inputEl: ElementRef;

  writeValue(value: CheckboxSelectionState): void {
    this.inputValue = value;
    if (this.inputEl) {
      this.inputEl.nativeElement.indeterminate = value === CheckboxSelectionState.indeterminate;
    }
    this.onModelChange();
  }

  get value(): CheckboxSelectionState {
    return this.inputValue;
  }

  get selected(): boolean {
    return this.inputValue === CheckboxSelectionState.selected;
  }

  set selected(selected: boolean) {
    this.writeValue(selected ? CheckboxSelectionState.selected : CheckboxSelectionState.unselected);
  }
}
