import {AfterContentInit, Component, ContentChildren, EventEmitter, Input, Output, QueryList} from '@angular/core';
import {TabItemComponent} from "./tab-item/tab-item.component";

@Component({
  selector: 'app-tab-panel',
  templateUrl: './tab-panel.component.html',
  styleUrls: ['./tab-panel.component.scss']
})
export class TabPanelComponent implements AfterContentInit {
  @ContentChildren(TabItemComponent) tabs!: QueryList<TabItemComponent>;
  activeComponent!: TabItemComponent;
  @Input() activeIndex: number = 0;
  @Input() sticky: boolean;
  @Input() alwaysRender: boolean;
  @Output() tabChange: EventEmitter<TabInfo> = new EventEmitter<TabInfo>();

  ngAfterContentInit() {
    this.activeComponent = this.tabs.toArray()[this.activeIndex];
  }

  activateTab(tab: TabItemComponent, index: number) {
    this.activeComponent = tab;
    this.tabChange.emit({
      index: index,
      tab: tab
    });
  }

  trackByIndex = (i: number, tab: TabItemComponent) => i;
}

export interface TabInfo {
  index: number;
  tab: TabItemComponent;
}
