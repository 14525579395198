<ng-container [ngSwitch]="shipStatus">
  <ng-container *ngSwitchCase="'EXPECTED'">
    <span class="fa-stack">
      <i class="fas fa-circle fa-stack-2x fa-inverse"></i>
      <i class="fas fa-lg fa-map-marker-alt fa-stack-1x"></i>
    </span>
  </ng-container>
  <ng-container *ngSwitchCase="'ARRIVED'">
    <span class="fa-stack" title="{{context.visit.vessel.name}}">
      <i class="fas fa-circle fa-stack-2x"></i>
      <i class="fas fa-lg fa-ship fa-stack-1x fa-inverse"></i>
    </span>
  </ng-container>
  <ng-container *ngSwitchCase="'DEPARTED'">
    <span class="fa-stack">
      <i class="fas fa-circle fa-stack-2x fa-inverse"></i>
      <i class="fas fa-lg fa-map-marker-alt fa-stack-1x"></i>
    </span>
  </ng-container>
  <ng-container *ngSwitchCase="'MOVING'">
    <span class="fa-stack">
      <i class="fas fa-circle fa-stack-2x fa-inverse"></i>
      <i class="fas fa-lg fa-map-marker-alt fa-stack-1x"></i>
    </span>
  </ng-container>
</ng-container>
