<nav class="navbar navbar-expand-xl navbar-light px-0 bg-white border-bottom">
  <div class="container-fluid px-0">
    <div class="d-flex align-items-center align-content-between flex-fill flex-wrap justify-content-sm-between">
      <!-- LEFT: Ship icon and name -->
      <div class="d-none d-sm-block col-lg-3 px-0">
        <div class="navbar-brand">
          <app-vessel-details></app-vessel-details>
        </div>
      </div>
      <!-- CENTER: Navbar -->
      <div class="d-none d-sm-block">
        <app-visit-status></app-visit-status>
      </div>
      <!-- RIGHT: Action buttons -->
      <div class="text-end pe-0 col-lg-3 d-none d-lg-block">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</nav>
