<div class="mx-auto mb-5" style="max-width: 1150px">

  <!-- Filters -->
  <div class="row mt-2 px-1 d-flex justify-content-between">

    <div class="col-md-4 col-xs-12 pe-3" style="min-width: 19rem">
      <app-date-range [ngModel]="overviewFilter.dateRange" (ngModelChange)="onDateSelection($event)"
                      [disabled]="!!overviewFilter.searchTerm"></app-date-range>
    </div>
    <div class="col-md-6 mb-3">
      <app-filter [(ngModel)]="overviewFilter.searchTerm" (ngModelChange)="from = 0; endReached = false"
                  [minCharacters]="3" [searchFunction]="searchFunction" (resultsFound)="renderWasteCollections($event)"
                  placeholder="Search by CRN, vessel, berth, waste type"></app-filter>
    </div>
    <div class="col-md-2 text-end">
      <button class="btn btn-info" (click)="addNewCollection()" [disabled]="appContext.isAdmin()">Add Collection</button>
    </div>
  </div>

  <div *ngIf="summaries">
    <div *ngFor="let summary of summaries">
      <app-collector-summary [collector]="collector" [newCollector]="summary === null" [summary]="summary" (onCancel)="cancelNewCollection()"></app-collector-summary>
    </div>
  </div>

</div>
