import {VisitContext} from "./visit-context";
import moment from "moment";
import {SeaEntry} from "@portbase/bezoekschip-service-typescriptmodels";

export class VisitValidator {

  static etaPortIsSameOrBeforePreviousPort() {
    if (VisitContext.visit.visitDeclaration.previousPorts.length === 0) {
      return false;
    }
    const portVisit = VisitContext.visit.visitDeclaration.portVisit;
    const lastPreviousPort = VisitContext.visit.visitDeclaration.previousPorts[0];
    return !portVisit.ataPort && portVisit.etaPort && lastPreviousPort.departure && moment(portVisit.etaPort)
      .isSameOrBefore(moment(lastPreviousPort.departure))
  }
  static etaPortIsSameOrAfterFirstBerth() {
    if (VisitContext.visit.visitDeclaration.portVisit.berthVisits.length === 0) {
      return false;
    }
    const portVisit = VisitContext.visit.visitDeclaration.portVisit;
    const firstBerthDate = portVisit.berthVisits[0].eta ? portVisit.berthVisits[0].eta : portVisit.berthVisits[0].etd;
    return !portVisit.ataPort && portVisit.etaPort && firstBerthDate && moment(firstBerthDate).isSameOrBefore(moment(portVisit.etaPort));
  }

  static etaPortIsSameOrAfterEtdPort() {
    if (VisitContext.visit.visitDeclaration.portVisit.berthVisits.length !== 0) {
      return false;
    }
    const portVisit = VisitContext.visit.visitDeclaration.portVisit;
    return !portVisit.ataPort && !portVisit.atdPort && portVisit.etaPort && portVisit.etdPort && moment(portVisit.etaPort).isSameOrAfter(moment(portVisit.etdPort));
  }

  static etaPBPIsSameOrAfterFirstBerth() {
    if (!VisitContext.visit.orderIncomingMovement) {
      return false;
    }
    if (VisitContext.visit.visitDeclaration.portVisit.berthVisits.length === 0) {
      return false;
    }
    const portVisit = VisitContext.visit.visitDeclaration.portVisit;
    const portEntry = portVisit.portEntry;
    const firstBerthDate = portVisit.berthVisits[0].eta ? portVisit.berthVisits[0].eta : portVisit.berthVisits[0].etd;
    return !portVisit.ataPort && portEntry && portEntry.etaPilotBoardingPlace && firstBerthDate && moment(firstBerthDate).isSameOrBefore(moment(portEntry.etaPilotBoardingPlace));
  }

  static etaEarliestPBPIsAfterPBP() {
    const portEntry = VisitContext.visit.visitDeclaration.portVisit.portEntry;
    return !VisitContext.visit.visitDeclaration.portVisit.ataPort && portEntry && portEntry.etaPilotBoardingPlace
      && portEntry.earliestTimeOfPortEntry && moment(portEntry.earliestTimeOfPortEntry).isAfter(moment(portEntry.etaPilotBoardingPlace));
  }

  static etaPBPIsBeforeEtdOfOtherVessel() {
    if (!VisitContext.visit.orderIncomingMovement || !VisitContext.isExpected()) {
      return false;
    }
    let portEntry = (<SeaEntry> VisitContext.visit.visitDeclaration.portVisit.portEntry);
    if (portEntry.entryDependency == null) {
      return false;
    }
    if (portEntry && portEntry.entryDependency && !portEntry.entryDependency.autoOrder) {
      return false;
    }

    let orderStatus = portEntry.entryDependency.orderStatus;
    if (orderStatus === null || orderStatus === "CANCELLED" || orderStatus === "REEVALUATED" || orderStatus === "REJECTED") {
      return false;
    }

    if (portEntry && portEntry.entryDependency && portEntry.entryDependency.dependencyType === "EXCHANGE") {
      const etdBerth = portEntry.entryDependency.estimatedTimeBerth;
      const smallVessel = VisitContext.visit.vessel.fullLength < 150;
      const etdDepartingVessel = smallVessel ? moment(etdBerth).subtract(30, 'minutes') : moment(etdBerth);
      const etdPilotBoardingPlaceSecondVessel = portEntry.etaPilotBoardingPlace;

      if (etdDepartingVessel && etdPilotBoardingPlaceSecondVessel) {
        return moment(etdPilotBoardingPlaceSecondVessel).isBefore(moment(etdDepartingVessel));
      }
    }
    return false;
  }

  static isEtaPbpInvalid() {
    return this.etaEarliestPBPIsAfterPBP() || this.etaPBPIsSameOrAfterFirstBerth()
    || this.etaPortIsSameOrAfterEtdPort() || this.etaPortIsSameOrBeforePreviousPort()
  }

  static isEtaPortInvalid() {
    return this.etaPortIsSameOrAfterEtdPort() || this.etaPortIsSameOrAfterFirstBerth()
      || this.etaPortIsSameOrBeforePreviousPort();
  }
}
