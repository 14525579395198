<div class="my-3"><i>Vessels are synced from Ares and to WPCS refdata</i></div>
<div class="row">
  <div class="col-md-6">
    <app-form-group><label>Id</label><input class="form-control" [(ngModel)]="vessel['id']" disabled required></app-form-group>
    <app-form-group><label>IMO number</label><input class="form-control" [(ngModel)]="vessel.imoCode" required></app-form-group>
    <app-form-group><label>Name</label><input class="form-control" [(ngModel)]="vessel.name" required></app-form-group>
    <app-form-group><label>Radio call sign</label><input class="form-control" [(ngModel)]="vessel.radioCallSign" required></app-form-group>
    <app-form-group><label>Max width</label><input class="form-control" [(ngModel)]="vessel.maxWidth" appDecimalNumber [maxDecimals]="2"></app-form-group>
      <app-form-group><label>Max draught</label><input class="form-control" [(ngModel)]="vessel.maxDraught"
                                                       appDecimalNumber [maxDecimals]="2"></app-form-group>
    <app-form-group><label>Gross tonnage</label><input class="form-control" appDecimalNumber [maxDecimals]="2" [(ngModel)]="vessel.grossTonnage" required></app-form-group>
    <app-form-group><label>Net tonnage</label><input class="form-control" appDecimalNumber [maxDecimals]="2" [(ngModel)]="vessel.netTonnage" required></app-form-group>
    <app-form-group><label>MMSI number</label><input class="form-control" [(ngModel)]="vessel.mmsiNumber"></app-form-group>
    <app-form-group><label>Summer dead weight</label><input class="form-control" appDecimalNumber [maxDecimals]="2" [(ngModel)]="vessel.summerDeadWeight"></app-form-group>
    <app-form-group><label>Stat code level 5</label><input class="form-control" [(ngModel)]="vessel.statCode5"></app-form-group>
  </div>
  <div class="col-md-6">
    <app-form-group><label>MOT code</label><input class="form-control" [(ngModel)]="vessel.motUnCode" required></app-form-group>
    <app-form-group><label>MOT Name</label><input class="form-control" [(ngModel)]="vessel.motName"></app-form-group>
    <app-form-group><label>Flag code</label><input class="form-control" [(ngModel)]="vessel.flagCode" required></app-form-group>
    <app-form-group><label>Flag country unCode</label><input class="form-control" [(ngModel)]="vessel.flagCountryUnCode" required></app-form-group>
    <app-form-group><label>Registration place unloCode </label><input class="form-control" [(ngModel)]="vessel.registrationPlaceUnloCode" required></app-form-group>
    <app-form-group><label>Registration place name</label><input class="form-control" [(ngModel)]="vessel.registrationPlaceName" required></app-form-group>
    <app-form-group><label>Registration date</label><app-date-field [(ngModel)]="vessel.registrationDate" [dateOnly]="true" [yearSpan]="1000" id="registrationDate" required></app-date-field></app-form-group>
    <app-form-group><label>Full length</label><input class="form-control" appDecimalNumber [maxDecimals]="2" [(ngModel)]="vessel.fullLength" required></app-form-group>
    <app-form-group><label>Vessel's email address</label><input class="form-control" [(ngModel)]="vessel.emailAddress"></app-form-group>
  </div>
</div>
