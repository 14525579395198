import {Component} from '@angular/core';
import {AppContext} from '../../app-context';
import {sendCommand} from '../../common/utils';
import {Router} from '@angular/router';
import {mockProfiles} from '../../../../utils/jwt-generator/mocks/user-profiles.mock';
import {UserProfile} from "@portbase/bezoekschip-service-typescriptmodels";

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.css']
})
export class UserMenuComponent {

  appContext = AppContext;
  mockProfiles = mockProfiles;

  constructor(private router: Router ) {
  }

  onUserChanged = (userProfile: UserProfile) => {
    if (userProfile) {
      sendCommand("updateUserProfile", userProfile);
      this.router.navigate(['/']);
    }
  };
}
