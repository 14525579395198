<ul class="nav position-absolute" style="top: 0; right: 0">
  <li class="nav-item d-lg-block d-none">
    <span class="dropdown mx-1 always-enabled">
      <button class="btn btn-info border-0 bg-transparent text-info dropdown-toggle py-2"
              style="min-width: 8rem" type="button" data-bs-toggle="dropdown"><span class="fa fa-file-download me-1"></span> Downloads
      <span class="caret"></span></button>
      <ul class="dropdown-menu dropdown-menu-start" style="cursor: pointer">
        <li (click)="context.cargoImportModel.downloadConsignments([manifest])" class="dropdown-item">
          {{selectedItems.length > 0 ? 'Selected ' : ''}}B/L details
        </li>
        <li (click)="context.cargoImportModel.downloadMrns([manifest])" class="dropdown-item">
          {{selectedItems.length > 0 ? 'Selected ' : ''}}MRNs
        </li>
        <li *ngIf="context.savedVisit.portOfCall.port.locationUnCode === manifest.portOfDischarge?.locationUnCode"
            (click)="context.cargoImportModel.downloadClearanceDifferences([manifest])" class="dropdown-item">
          Clearance differences
        </li>
      </ul>
    </span>
  </li>
  <li class="nav-item" *ngIf="context.cargoImportModel.isEditor()">
    <a href="#" class="nav-link pe-1" (click)="$event.preventDefault(); context.cargoImportModel.addConsignment(manifest.portOfLoading)">
      <span class="pe-2 fa fa-plus-circle"></span><span class="d-none d-sm-inline">Add consignment</span></a>
  </li>
</ul>

<div *ngIf="manifest.consignments.length === 0" class="text-center text-muted" style="padding: 20px 0">
  You haven’t added any consignments yet.
</div>

<ng-container *ngIf="visibleItems as items">
  <app-pagination #pagination [items]="items">
    <ng-container *ngFor="let consignment of pagination.getItemsOnPage(); let index = index;">

      <!-- render terminal -->
      <ng-container *ngIf="consignment.terminal && (index === 0
        || consignment.terminal?.terminalCode !== items[index - 1].terminal?.terminalCode)">
        <div class="mt-3 row g-0 align-items-center">
          <div class="col-auto me-2 invisible">
            <div class="form-check form-check-inline m-0" style="height: 2.5rem">
              <input class="form-check-input m-0" type="checkbox"></div>
          </div>
          <div class="col py-1 ps-3 border border-white" style="border-width: 2px!important;">
            <span class="fa fa-fw fa-map-marker-alt text-portbase-blue pe-2 me-2"></span>
            <span class="ms-1 fw-bold text-dark">{{utils.terminalFormatter(consignment.terminal)}}</span>
          </div>
        </div>
      </ng-container>

      <div class="row g-0">
        <div class="col-auto me-2">
          <div class="form-check form-check-inline m-0 always-enabled" style="height: 2rem">
            <input [(ngModel)]="consignment['selected']" class="always-enabled form-check-input m-0" type="checkbox"></div>
        </div>
        <div class="col">
          <app-collapse #consignmentCollapse [collapse]="consignmentTemplate" [showOnLoad]="!consignment.consignmentNumber"
                        [animateOnLoad]="true"
                        [deleteHandle]="context.cargoImportModel.isEditor() && removeConsignment(consignment)" [validationModel]="consignment">
            <div class="row g-0 py-1 px-3 align-items-center text-dark">
              <div class="col">
                <ng-container [ngSwitch]="getDeclarationStatus(consignment)">
                <span class="fa fa-fw fa-paper-plane pe-2 me-2 text-info" *ngSwitchCase="'DECLARED'"
                      title="ENS has been declared but we haven't received a response"></span>
                  <span class="fa fa-fw fa-check pe-2 me-2 text-portbase-green" *ngSwitchCase="'ACCEPTED'"
                        title="ENS has been accepted"></span>
                  <span class="fa fa-fw fa-times pe-2 me-2 text-portbase-red" *ngSwitchCase="'REJECTED'"
                        title="ENS has been rejected"></span>
                  <span class="fa fa-fw fa-file-invoice pe-2 me-2" [title]="consignment.declared ? 'Consignment without ENS declaration' : ''" *ngSwitchDefault></span>
                </ng-container>
                {{consignment.consignmentNumber || 'New consignment'}}
              </div>
              <div class="col-auto d-none d-md-block text-dark">
                <app-cargo-summary [consignment]="consignment" [keepTooltipOpen]="consignmentCollapse.showing"></app-cargo-summary>
              </div>
            </div>
          </app-collapse>
        </div>
      </div>

      <ng-template #consignmentTemplate>
        <div class="collapse">
          <div class="my-3">
            <ng-container *ngIf="getRejectReasons(consignment) as rejectReasons">
              <app-status-alert type="danger" *ngIf="rejectReasons.length > 1">
                <ul class="my-0 py-0">
                  <li *ngFor="let rejectReason of rejectReasons">
                    {{rejectReason}}
                  </li>
                </ul>
              </app-status-alert>
              <app-status-alert type="danger" *ngIf="rejectReasons.length === 1">
                <div>Customs: {{rejectReasons[0]}}</div>
              </app-status-alert>
            </ng-container>
            <div class="row g-0">
              <div class="col position-relative">
                <app-tab-panel>
                  <app-tab-item [templateRef]="detailsRef">
                    <span class="tab-item" [ngClass]="{'ng-invalid text-invalid' : !!consignment['ngDetailsInvalid']}">Details</span>
                    <ng-template #detailsRef>
                      <div class="nav position-absolute" style="top: 0; right: 0" *ngIf="context.cargoImportModel.isEditor()">
                        <ng-container *ngIf="!consignment.consignmentNumber; else duplicateConsignment">
                          <button class="btn btn-outline-info border-0" type="button" onPopover
                                  [popoverContent]="findConsignment" popoverClass="find-consignment-popover"
                                  placement="bottom-start">
                            <span class="fa fa-copy"></span> <span class="d-none d-sm-inline ms-1">Copy from B/L</span>
                          </button>
                          <ng-template #findConsignment>
                            <app-form-group label="Copy of B/L">
                              <app-search [ngModel]="null" (ngModelChange)="$event ? copyConsignmentDetails(consignment, $event) : null"
                                          placeholder="Find other consignment" dataKey="consignmentNumber"
                                          [minCharacters]="2" [searchFunction]="utils.findConsignments(consignment)"></app-search>
                            </app-form-group>
                          </ng-template>
                        </ng-container>
                        <ng-template #duplicateConsignment>
                          <button (click)="duplicate(consignment, consignmentCollapse)" class="btn btn-outline-info border-0" type="button">
                            <span class="fa fa-copy"></span> <span class="d-none d-sm-inline ms-1">Duplicate B/L</span>
                          </button>
                        </ng-template>
                      </div>
                      <div class="p-3 border border-top-0 rounded-bottom" [appValidator]="consignment"
                           validatorProperty="ngDetailsInvalid">
                        <app-consignment-details
                          [manifest]="manifest" [consignment]="consignment"></app-consignment-details>
                      </div>
                    </ng-template>
                  </app-tab-item>
                  <app-tab-item [templateRef]="goodsRef">
                    <span class="tab-item" [ngClass]="{'ng-invalid text-invalid' : utils.isInvalid(consignment.goodsItems)}">Goods</span>
                    <ng-template #goodsRef>
                      <ul class="nav position-absolute" style="top: 0; right: 0">
                        <li class="nav-item">
                          <a href="#" class="nav-link pe-1" (click)="$event.preventDefault(); manifest.addGoodsItem(consignment)">
                            <span class="fa fa-plus-circle"></span><span class="ps-2 d-none d-sm-inline">Add good</span></a>
                        </li>
                      </ul>
                      <app-goods [consignment]="consignment" [manifest]="manifest"></app-goods>
                    </ng-template>
                  </app-tab-item>
                  <ng-container *ngIf="getContainers(consignment) as containers">
                    <app-tab-item *ngIf="containers.length > 0" [templateRef]="containersRef">
                      <span class="tab-item" [ngClass]="{'ng-invalid text-invalid' : utils.isInvalid(containers)}">Containers</span>
                      <ng-template #containersRef>
                        <div class="p-3 border border-top-0 rounded-bottom">
                          <app-containers [containers]="containers" [showTerminal]="false"></app-containers>
                        </div>
                      </ng-template>
                    </app-tab-item>
                  </ng-container>
                </app-tab-panel>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>

    <div class="row g-0 text-secondary">
      <div class="col-auto" *ngIf="items.length > 1">
        <label class="mt-3">
          <input [(ngModel)]="items.length === selectedItems.length"
                 (click)="toggleSelectAll()" class="always-enabled" type="checkbox">
          <span class="ms-2">Select all</span>
        </label>
      </div>
      <div *ngIf="appContext.isAdmin()" class="col-auto ms-auto mt-3 me-n1">
        <app-import-declaration-admin [setManifests]="[manifest]"></app-import-declaration-admin>
      </div>
    </div>

    <div *ngIf="hiddenItems.length as hiddenCount" class="text-center text-muted" style="padding: 20px 0">
      Hiding {{hiddenCount}} filtered consignment{{hiddenCount == 1 ? '' : 's'}}
      <button type="button" class="btn btn-outline-info btn-sm ms-2" (click)="showAll()">Show all</button>
    </div>
  </app-pagination>
</ng-container>

<ng-container *ngIf="selectedItems.length > 0">
  <div class="fixed-bottom row g-0 justify-content-center bg-white py-3 border-top">
    <div class="col-auto mx-1">
      <button class="btn btn-outline-danger" (click)="deleteSelected()" style="min-width: 10rem" type="button">Delete</button>
    </div>

    <div class="col-auto mx-1">
      <button class="btn btn-outline-secondary dropdown-toggle" popoverClass="find-authorisation-popover"
              onPopover [popoverContent]="findBulkAuthorisation"
              style="min-width: 10rem" type="button" data-bs-toggle="dropdown">Authorise</button>
      <ng-template #findBulkAuthorisation>
        <app-form-group label="Organisation">
          <app-search [ngModel]="null" placeholder="Find party" placement="top"
                      (ngModelChange)="$event ? addBulkAuthorisation($event) : null"
                      [searchFunction]="utils.findBulkAuthorisations" [inputFormatter]="utils.bulkAuthorisationFormatter">
          </app-search>
        </app-form-group>
      </ng-template>
    </div>

    <div class="col-auto mx-1">
      <div class="dropdown">
        <button class="btn btn-outline-secondary dropdown-toggle" style="min-width: 10rem" type="button" data-bs-toggle="dropdown">
          Change terminal
          <span class="caret"></span></button>
        <ul class="dropdown-menu dropdown-menu-start">
          <li class="dropdown-item" *ngFor="let terminal of visitTerminals" (click)="changeTerminal(terminal)">
            {{terminal.terminalName}}
          </li>
        </ul>
      </div>
    </div>
    <div class="col-auto" *ngIf="appContext.isAdmin()">
      <app-consignments-admin [setConsignments]="selectedItems"></app-consignments-admin>
    </div>
  </div>
</ng-container>
