<!--PILOT BOARDING PLACE - INKOMENDE REIZEN-->
<div class="d-flex flex-column" *ngIf="context.visit.orderIncomingMovement">
  <div class="fieldset" *ngIf="context.visit.orderIncomingMovement">
    <app-form-group><label>Pilot boarding place</label>
      <app-select [(ngModel)]="context.visit.visitDeclaration.portVisit.pilotStation"
                  (ngModelChange)="setEtaIfPilotBoardingPlaceIsMaascenter($event)" [optionsProvider]="pilotStations"
                  dataKey="name" [formatter]="pilotBoardingPlaceFormatter"
                  placeholder="– Select a pilot boarding place –"
                  required [disabled]="context.isArrived()" id="pilotBoardingPlace">
        <div class="invalid-feedback">Please select a pilot boarding place</div>
      </app-select>
    </app-form-group>
  </div>
  <div>
    <app-form-group label="ETA pilot boarding place">
      <app-tidal-window [status]="context.visit.incomingTidalWindowStatus"
                        [level]="context.visit.visitDeclaration.portVisit.firstMovement.order ? 'error' : 'warning'">
        <app-date-field [(ngModel)]="context.visit.visitDeclaration.portVisit.portEntry.etaPilotBoardingPlace"
                       [required]="!context.isWaitingForOrders()" (ngModelChange)="onEtaPbpChanged($event)"
                       [calendarStartDate]="context.visit.visitDeclaration.portVisit.etdPort" [disabled]="context.isArrived() || (context.visit.visitDeclaration.portVisit.portEntry != null &&
                           context.visit.visitDeclaration.portVisit.portEntry.baseForPlanning == 'FIRST_BERTH') ||
                           context.isWaitingForOrders()" id="etaPBP"></app-date-field>
      </app-tidal-window>
    </app-form-group>
  </div>
  <span *ngIf="validator.etaPBPIsBeforeEtdOfOtherVessel()" class="ng-invalid invalid-feedback mb-2">Eta should be the same or after ETD of other vessel</span>
  <span *ngIf="validator.etaPBPIsSameOrAfterFirstBerth() " class="ng-invalid invalid-feedback mb-2">First berth visit should be after eta pilot boarding place</span>
  <span *ngIf="validator.etaPortIsSameOrBeforePreviousPort()" class="ng-invalid invalid-feedback mb-2">Last previous port should be before eta pilot boarding place</span>
  <span *ngIf="validator.etaPortIsSameOrAfterEtdPort()" class="ng-invalid invalid-feedback mb-2">Eta port should be before etd port</span>
</div>

