import {Component, Input, OnInit} from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-timeline-date-time',
  templateUrl: './timeline-date-time.component.html',
  styleUrls: ['./timeline-date-time.component.scss']
})
export class TimelineDateTimeComponent implements OnInit {

  @Input() dateTime: string;
  @Input() postfix: string;

  constructor() {
  }

  ngOnInit() {
  }

  asTime(date: string) {
    return moment(date).format('HH:mm');
  }

  asDate(date: string, errorText: String) {
    return date ? moment().isSame(date, 'day') ? 'Today' : moment(date).format('D MMM') : errorText;
  }
}
