<div class="row narrow-gutters always-enabled my-4">
  <div class="col-md-4" style="min-width: 19rem; height: 35px;">
    <app-date-range (ngModelChange)="onDateSelection($event)" [ngModel]="dateRange" [disabled]="!!filterTerm"></app-date-range>
  </div>
  <div class="col">
    <app-filter (resultsFound)="renderFilteredItems()" [(ngModel)]="filterTerm" [searchFunction]="reload"
                placeholder="Filter by mrn, container nr, etc..." [appAutoFocus]="true"></app-filter>
  </div>
</div>

<app-pagination #pagination [items]="entries" class="accordion" id="midAccordion">
  <ng-container *ngFor="let declaration of pagination.getItemsOnPage(); let index = index;">

    <div class="row g-0">
      <div class="col">
        <app-collapse [collapse]="collapseTemplate">
          <div class="row g-0 py-1 px-3 align-items-center text-dark">
            <div class="col">
              <span class="fa fa-fw fa-file-invoice pe-2 me-2" title="Document number"></span>
              {{declaration.documentNumber}}
            </div>
            <div class="col" *ngIf="declaration.equipments.length > 0">
              <span class="fa fa-fw fa-cube pe-2 me-2"></span>
              <span class="d-inline-block align-top text-truncate">{{declaration.equipments[0].id}}</span>
            </div>
          </div>
        </app-collapse>
      </div>
    </div>

    <ng-template #collapseTemplate>
      <div class="collapse" data-bs-parent="#midAccordion">
        <div class="py-3 ms-2">
          <pre>{{declaration | json}}</pre>
        </div>
      </div>
    </ng-template>

  </ng-container>
</app-pagination>
