import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Country, IE3CustomsOffice, IE3Place, Terminal} from "@portbase/bezoekschip-service-typescriptmodels";
import moment from "moment/moment";
import {PortvisitUtils} from "../../../../../refdata/portvisit-utils";
import {removeItem} from "../../../../../common/utils";
import {ConsignmentUtils} from "../../../consignment.utils";

@Component({
  selector: 'app-master-consignment-route',
  templateUrl: './master-consignment-route.component.html',
  styleUrls: ['./master-consignment-route.component.scss']
})
export class MasterConsignmentRouteComponent {
  portVisitUtils = PortvisitUtils;
  @Input() routeInfo: ConsignmentRouteInfo;
  @Input() index: number;
  @Input() allRoutes: ConsignmentRouteInfo[];
  @Input() editMode: boolean;
  @Output() allRoutesChange: EventEmitter<ConsignmentRouteInfo[]> = new EventEmitter<ConsignmentRouteInfo[]>();

  countryFormatter = (value: Country | string) => typeof value === "string" ? value : ((value.name && value.code) ? `${value.name} - ${value.code}` : '');

  formatDate = (dateString: string): string => !!dateString ? moment(dateString).format("DD MMM YYYY, HH:mm") : "n/a";

  protected getPlaceLabel = (place: IE3Place) => place ? `${place.name} (${place.locationUnCode})` : '';

  moveUp = () => this.allRoutesChange.emit(this.moveItem(this.allRoutes, this.index, this.index - 1));

  moveDown = () => this.allRoutesChange.emit(this.moveItem(this.allRoutes, this.index, this.index + 1));

  routeDeleted = () => this.allRoutesChange.emit(removeItem(this.allRoutes, this.allRoutes[this.index]));

  refreshRoutes = () => this.allRoutesChange.emit(this.allRoutes);

  moveItem = (routes: ConsignmentRouteInfo[], from: number, to: number) => {
    routes.splice(to,0, routes.splice(from,1)[0]);
    return routes;
  }
  protected readonly utils = ConsignmentUtils;
}

export interface ConsignmentRouteInfo {
  country: Country;
  etd?: string;
  eta?: string;
  placeOfAcceptance?: IE3Place;
  placeOfLoading?: IE3Place;
  placeOfUnloading?: IE3Place;
  placeOfDelivery?: IE3Place;
  dischargeTerminal?: Terminal;
  terminalLicense?: string;
  customsOfficeOfFirstEntry?: IE3CustomsOffice;
}
