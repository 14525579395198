import {Component} from '@angular/core';
import {VisitContext} from '../../visit-context';
import {openConfirmationModal, sendCommand} from '../../../common/utils';
import {AcceptPax, Declaration, DeclarationType, RejectPax} from '@portbase/bezoekschip-service-typescriptmodels';
import {AppContext} from '../../../app-context';
import {PortvisitUtils} from '../../../refdata/portvisit-utils';

@Component({
  selector: 'app-passengers-admin',
  templateUrl: './passengers-admin.component.html',
  styleUrls: ['./passengers-admin.component.css']
})
export class PassengersAdminComponent {
  protected readonly openConfirmationModal = openConfirmationModal;
  PAX = DeclarationType.PAX;

  context = VisitContext;
  appContext = AppContext;


  openSwMessages() {
    PortvisitUtils.redirectToMessageManagement(VisitContext.visit.crn + '_pax2sw')
  }

  accept() {
    sendCommand('com.portbase.bezoekschip.common.api.pax.AcceptPax', <AcceptPax>{
      crn: VisitContext.visit.crn
    }, () => {
      VisitContext.visit.declarations.push(<Declaration>{
        type: 'PAX',
        status: 'ACCEPTED'
      });
      VisitContext.replaceVisit(VisitContext.visit);
      AppContext.registerSuccess('Crew and passenger list was set to accepted successfully.');
    });
  }

  reject(reason: string) {
    sendCommand('com.portbase.bezoekschip.common.api.pax.RejectPax', <RejectPax>{
      crn: VisitContext.visit.crn,
      reason: reason
    }, () => {
      VisitContext.visit.declarations.push(<Declaration>{
        type: 'PAX',
        status: 'REJECTED'
      });
      VisitContext.replaceVisit(VisitContext.visit);
      AppContext.registerSuccess('Crew and passenger list was set to rejected successfully.');
    });
  }
}
