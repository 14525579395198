import {Component, Input} from '@angular/core';
import {VisitContext} from "../visit-context";
import {Visit} from "@portbase/bezoekschip-service-typescriptmodels";
import {AppContext} from "../../app-context";


@Component({
  selector: 'app-vessel-details',
  templateUrl: './vessel-details.component.html',
  styleUrls: ['./vessel-details.component.scss']
})
export class VesselDetailsComponent  {
  context = VisitContext;
  appContext = AppContext;
  @Input() mobile?:boolean;

  get visit(): Visit {
    return this.context.visit;
  }

}
