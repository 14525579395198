<div>
  <div class="news-item mb-3">
    <h2>
      <div style="display: inline-block;">Onderhoud 6 augustus systeem havenmeester Rotterdam en Amsterdam (HAMIS)</div>
      <div style="display: inline-block; float: right">5 augustus 2022</div>
    </h2>
    <div>
      <p>
        Op zaterdag 6 augustus van 08.00 – 11.30 uur zal er onderhoud plaatsvinden aan het systeem van de havenmeesters van Rotterdam en Amsterdam (HAMIS).
        Er worden dan geen meldingen verwerkt en er wordt ook geen informatie teruggekoppeld vanuit HAMIS (denk aan ATA’s, ATD’s, bestellingen etc.).
      </p>
      <p>
        U kunt uw meldingen wel aanbieden in Portbase. Deze worden gedurende het onderhoud (tussen 08:00 en 11:30 uur) niet direct verwerkt maar gebufferd. Houdt u rekening met een vertraging in de retourberichten.
        Na afloop van het onderhoud, worden de gebufferde berichten alsnog verwerkt en volgen eventuele retourberichten. Wij verzoeken u met klem zoveel mogelijk alle meldingen vóór het geplande onderhoud in te sturen.
      </p>
      <p>
        Tijdens het onderhoud wordt gewerkt met noodprocedures. Dit betekent dat u reizen ook per mail kan melden aan het HCC. Uiteraard kunnen ad hoc zaken ook telefonisch met HCC en HOC worden afgestemd.
      </p>
      <p>
        Kijk hier voor de back-up procedure voor Melding Schip voor
        <a target="_blank" href="https://support.portbase.com/kennis/back-up-procedure-melding-schip-rotterdam-en-moerdijk/">Rotterdam</a> of
        <a target="_blank" href="https://support.portbase.com/kennis/back-up-procedure-melding-schip-haven-van-amsterdam/">Amsterdam</a>.
      </p>
    </div>
  </div>
  <hr>
  <div class="news-item mb-3">
    <h2>
      <div style="display: inline-block;">Update over de herbouw van de service Transitaangifte</div>
      <div style="display: inline-block; float: right">19 juli 2022</div>
    </h2>
    <div>
      <p>
        In ons eerdere bericht over <a class="fw-bold" target="_blank" href="https://www.portbase.com/belangrijke-upgrade-voor-de-service-transitaangifte/">de herbouw van
        de service Transitaangifte</a>, heeft u kunnen lezen dat de service
        een belangrijke upgrade krijgt. Wij zijn verheugd te melden, dat vanaf 5 september alle klanten worden overgezet op deze herbouwde service.
        Dit betekent dat u nieuwe aangiften aanmaakt in de nieuwe service en bestaande aangiften kunt afmaken en inzien in de oude service.
        Na verloop van tijd zal de oude service worden uitgezet.
      </p>
      <p>
        Sinds maart zijn we bezig met een pilot die succesvol verloopt. Op basis van de ontvangen feedback zijn bugs opgelost en extra functionaliteiten toegevoegd.
        Zo is het bijvoorbeeld mogelijk om de aangifte op ATA van het schip te versturen en kunt u sneller alle containers vinden waarvoor u de aangifte wil doen.
        Hieronder nogmaals de belangrijkste voordelen van de herbouwde service:
      </p>

      <ul>
        <li>Een triggerdatum maakt het mogelijk om aangiftes automatisch op een later moment te verzenden.</li>
        <li>Documenten kunnen automatisch worden gemaild naar klanten en vervoerders wanneer de Douane de container ‘in transit’ meldt.</li>
        <li>Klanten en vervoerders kunnen via automatische mail geïnformeerd worden als een vervoerstermijn gaat verlopen.</li>
        <li>Maximaal hergebruik van data uit de ATO-melding, maar ook blanco aangiftes blijven mogelijk.</li>
        <li>Transitaangifte is te gebruiken binnen Cargo Controller.</li>
        <li>Nieuwe look en feel: voor rederijen dezelfde look en feel van de service Melding Schip en Melding Lading Import.</li>
        <li>Inzien van gebeurtenishistorie met betrekking tot de aangifte: wie heeft wat wanneer gedaan?</li>
        <li>Door nieuwe en up-to-date technische basis is het makkelijker om functionele wensen te realiseren. Later zal bijvoorbeeld ook een bijbehorende MED-melding gedaan kunnen worden vanuit de service Transitaangifte.</li>
        <li>Betrokken partijen bij de transitaangifte kunnen updates ook via een API-koppeling ontvangen.</li>
      </ul>
      <p>
        Alle templates en guarantees worden gekopieerd naar de nieuwe service, om de overgang zo soepel mogelijk te laten verlopen.
      </p>
      <p>
        Daarnaast worden instructievideo’s op de <a class="fw-bold" target="_blank" href="https://support.portbase.com/services/transitaangifte/">supportpagina</a> geplaatst.
      </p>
    </div>
  </div>
  <hr>
  <div class="news-item mb-3">
    <h2>
      <div style="display: inline-block;">Terugkoppelen actuele diepgang én PBP</div>
      <div style="display: inline-block; float: right">8 juli 2022</div>
    </h2>
    <div>
      <p>
        Als een schip operationeel contact krijgt bij Maasaanloop komt het voor dat de diepgang niet correct is gemeld.
        Stel er is een <b>verwachte</b> diepgang gemeld van 13,90 meter en Maasaanloop hoort bij operationeel contact dat dit 14,40 meter is.
        Op dat moment registreert de havenmeester een <b>actuele</b> diepgang van 14,40 meter én wijzigt de Pilot Boarding Place i.v.m. een diepgang van > 14,30 meter van Maascenter naar Euro13.
        Het PCS kent alleen een <b>verwachte</b> diepgang. Deze wijzigt niet en de PBP blijft op Maascenter staan.
        Dit is nu opgelost door ook de <b>actuele</b> diepgang terug te koppelen naar het PCS én de PBP wijziging te synchroniseren naar het PCS.
        De agent kan het schip nu beter informeren m.b.t. de locatie waar de loods aan boord komt (Euro13).
        Idealiter meldt de agent een correcte diepgang (first time right) zodat het Loodswezen niet verrast wordt bij het plannen van een (juiste) loods
      </p>
    </div>
  </div>
  <hr>
  <div class="news-item mb-3">
    <h2>
      <div style="display: inline-block;">Onderhoud 3 juli systeem havenmeester Rotterdam en Amsterdam (HAMIS)</div>
      <div style="display: inline-block; float: right">1 juli 2022</div>
    </h2>
    <div>
      <p>
        Op zondag 3 juli tussen 09.00 – 13.00 zal er onderhoud plaatsvinden aan het systeem van de havenmeesters van Rotterdam en Amsterdam (HAMIS). Er worden dan geen meldingen verwerkt en er wordt ook geen informatie teruggekoppeld vanuit HAMIS (denk aan ATA’s, ATD’s, bestellingen etc.).
      </p>
      <p>
        Kijk hier voor de back-up procedure voor Melding Schip voor
        <a target="_blank" href="https://support.portbase.com/kennis/back-up-procedure-melding-schip-rotterdam-en-moerdijk/">Rotterdam</a> of
        <a target="_blank" href="https://support.portbase.com/kennis/back-up-procedure-melding-schip-haven-van-amsterdam/">Amsterdam</a>.
      </p>
    </div>
  </div>
  <hr>
  <div class="news-item mb-3">
    <h2>
      <div style="display: inline-block;">Terugkoppelen LOA geaccepteerd</div>
      <div style="display: inline-block; float: right">20 juni 2022</div>
    </h2>
    <div>
      <p>
        Tijdens een aangepaste loodsdienst (voor kleine of voor alle schepen), waarbij de loods niet aan boord kan door slecht weer, komt het schip in sommige gevallen in aanmerking voor een ‘Loods op afstand’ (LOA). Als de betreffende velden correct in het PCS zijn ingevuld, wordt er door het Loodswezen en de havenmeester beoordeeld of het schip met een loods op afstand naar binnen/buiten mag. Als hier toestemming voor wordt gegeven (‘LOA geaccepteerd’), is dat direct zichtbaar bij de betreffende reis in het PCS.
      </p>
    </div>
  </div>
  <hr>
  <div class="news-item mb-3">
    <h2>
      <div style="display: inline-block;">Onderhoud systeem havenmeester Rotterdam en Amsterdam (HAMIS)</div>
      <div style="display: inline-block; float: right">12 juni 2022</div>
    </h2>
    <div>
      <p>
        Op zondag 12 juni tussen 09.00 – 12.30 zal er onderhoud plaatsvinden aan het systeem van de havenmeesters van Rotterdam en Amsterdam (HAMIS). Er worden dan geen meldingen verwerkt en er wordt ook geen informatie teruggekoppeld vanuit HAMIS (denk aan ATA’s, ATD’s, bestellingen etc.)
      </p>
      <p>
        Via <a class="fw-bold" target="_blank" href="https://support.portbase.com/services/melding-schip/">Portbase Support</a> kunt u de back-up procedure vinden.
      </p>
    </div>
  </div>
  <hr>
  <div class="news-item mb-3">
    <h2>
      <div style="display: inline-block;">Jouw mening telt!</div>
      <div style="display: inline-block; float: right">11 mei 2022</div>
    </h2>
    <div>
      <p>
        Doe mee aan ons jaarlijkse klanttevredenheidsonderzoek, we willen juist van jou als gebruiker horen wat we als Portbase kunnen verbeteren.<br>
        De link naar de vragenlijst vind je in je mailbox. Kan je hem niet vinden? De afzender is Expoints, onze partner voor dit onderzoek, en het onderwerp is “uw mening is belangrijk voor Portbase”.
      </p>
      <p>
        Bedankt voor je hulp!
      </p>
    </div>
  </div>
  <hr>
  <div class="news-item mb-3">
    <h2>
      <div style="display: inline-block;">Eerste stap in koppeling Portbase en Enigma+ zorgt voor efficiëntie voor scheepsagenten</div>
      <div style="display: inline-block; float: right">18 maart 2022</div>
    </h2>
    <div>
      <p>
        Groot nieuws; na een succesvolle pilot is de basiskoppeling tussen Portbase en Enigma+ actief! Samen met North Sea Port hebben wij gekeken naar de wensen van de scheepsagenten en is de koppeling voor de melding aan de havenmeester gerealiseerd tussen Portbase en Enigma+. Vanaf 7 maart kunnen alle scheepsagenten gebruik maken van deze nieuwe functionaliteit.
      </p>
      <p>
        Scheepsagenten kunnen het scheepsbezoek aanmaken in Melding Schip van Portbase en van daaruit de gegevens eenvoudig naar Enigma+ sturen. Gegevens hoeven niet meer dubbel ingevoerd worden. Naast deze koppeling is er eveneens een automatische koppeling tussen Enigma+ en Portbase voor de ATA en ATD-meldingen. Deze ATA en ATD worden vanaf nu dus ook door North Sea Port doorgestuurd naar Portbase. Deze worden hergebruikt in o.a. de service Cargo Controller. Gebruikers van deze service worden vanaf nu dus ook voor Vlissingen en Terneuzen geïnformeerd over het moment waarop de invoeraangifte gedaan kan worden (CVB).
      </p>
      <p>
        Portbase zal samen met North Sea Port de koppeling verder uitbreiden, waarbij de eerste prioriteit gegeven wordt aan agentenoverdracht, ISPS en de afvalstoffen melding. Hier zal te zijner tijd over gecommuniceerd worden.
      </p>
        Voor meer info, bekijk de pagina op de <a class="fw-bold" target="_blank" href="https://campaign.portbase.com/ODQzLUlUVy0zMTIAAAGDMmPk4ZATSdSAQO8Fz3cNE-6OiiU7Ae9DgfaH72zUEfIkM1rxG6-Z8sBFtqyAcRTsw7J5F6Y=">North Sea Port website</a>.
    </div>
  </div>
  <hr>
  <div class="news-item mb-3">
    <h2>
      <div style="display: inline-block;">Incident: Messaging between Portbase and Customs</div>
      <div style="display: inline-block; float: right">4 February 2022</div>
    </h2>
    <div>
      <p>Please check our <a href="https://status.portbase.com/">status</a> page for updates.</p>
    </div>
  </div>
  <hr>
  <div class="news-item mb-3">
    <h2>
      <div style="display: inline-block;">Tijpoorten in Melding Schip</div>
      <div style="display: inline-block; float: right">1 Februari 2022</div>
    </h2>
    <div>
      <p>Vanaf 2 februari krijgt u voor een tij-gebonden schip een tijpoort van de havenmeester te zien in Melding Schip. Voorheen belde u als agent hiervoor met het HCC. Dat is dan niet meer nodig.<br/><i>Let op, dit geldt alleen voor Rotterdam</i></p>
      <p>Voor meer informatie: <a target="_blank" href="/assets/files/20220128-Melding-Schip-Tijpoorten-in-Melding-Schip.pdf" class="fw-bold">klik hier!</a></p>
    </div>
  </div>
  <hr>
  <div class="news-item mb-3">
    <h2>
      <div style="display: inline-block;">Emails to group mailbox</div>
      <div style="display: inline-block; float: right">7 Januari 2022</div>
    </h2>
    <div>
      Due to a change in PCS you might experience that emails from the Port Authority, Customs and Border Control are send to your user mailbox instead of a group mailbox.
      Please contact our Servicedesk (088-625 25 25, servicedesk@portbase.com) if you want to receive the emails from these parties in your group mailbox.
      Aside from the emails from these parties we also have emails that are send by the service. For these emails you can register the email address yourself.
    </div>
    <br>
    <p>
      <img src="assets/img/news/register-email-address.jpg">
    <p>
  </div>
  <hr>
  <div class="news-item mb-3">
    <h2>
      <div style="display: inline-block;">Verbeteringen in Melding Schip</div>
      <div style="display: inline-block; float: right">23 December 2021</div>
    </h2>
    <div>
      <p>
        In Melding Schip zijn voor u een volgende reeks aan verbeteringen per direct beschikbaar. Dit zijn praktische verbeteringen die wij in nauwe samenspraak hebben doorgevoerd met Havenbedrijf Rotterdam en Havenbedrijf Amsterdam.
        Bekijk <a target="_blank" href="https://www.portbase.com/services/melding-schip/?utm_medium=email" class="fw-bold">deze pagina</a> om deze in te zien.
      </p>
      <p>
        Met vriendelijke groet,
      </p>
      <p>
        Havenbedrijf Rotterdam, Havenbedrijf Amsterdam, Portbase
      </p>
    </div>
  </div>
  <hr>
  <div class="news-item mb-3">
    <h2>
      <div style="display: inline-block;">De bolder ranges zijn toegevoegd aan de ligplaatsen van Rotterdam</div>
      <div style="display: inline-block; float: right">8 Oktober 2021</div>
    </h2>
    <div>
      <p>
        De bolder ranges zijn toegevoegd aan de ligplaatsen van Rotterdam.
        Dit kunt u gebruiken voor het selecteren van de juiste ligplaats.
        Het is uiteraard belangrijk dat de juiste ligplaats wordt geselecteerd.
        Voor de bezoeken aan Rotterdam geldt dat alleen een ATA wordt afgegeven door de havenmeester als de ligplaats overeenkomt.
        <br>
        Voor meer informatie hierover, klik <a target="_blank" href="https://www.portbase.com/wp-content/uploads/2021/03/Communicatie-aan-agenten-v1-0-1.pdf" class="fw-bold">hier!</a>
      </p>
      <p>
        Een juiste ATA registratie is vanaf 4 oktober nóg belangrijker geworden.
        Een vervolgaangifte van de lading kan pas gedaan worden als de ATA is geregistreerd.
        <br>
        Voor meer informatie hierover, klik <a target="_blank" href=" https://www.voorbereidophetcvb.nl/" class="fw-bold">hier!</a>
      </p>
      <p>Vertrouwende hiermee voldoende te hebben geïnformeerd.</p>
    </div>
  </div>
  <hr>

  <div class="news-item mb-3">
    <h2>
      <div style="display: inline-block;">Herinnering: Vanaf 24 augustus MDoH digitaal uploaden in PCS en versturen naar de havenmeester</div>
      <div style="display: inline-block; float: right">24 Augustus 2021</div>
    </h2>
    <div>
      <p>
        Vanaf 1 september 2021 dient u de Maritime Declaration of Health (MDoH) voor elk schip digitaal aan te leveren aan de havenmeester via Melding Schip in het Port Community Systeem (PCS) van Portbase. Het versturen van de MDoH mag pas binnen 24 uur vóór aankomst op het gekozen Pilot Boarding Place (PBP) maar niet later dan 6 uur voor ETA PBP.      </p>
      <p>
        Op 24 augustus sluiten we alle agenten aan op deze nieuwe service zodat u in die week nog gelegenheid heeft om vragen te stellen. Vanaf 1 september accepteren we geen oude formulieren meer.
        <a target="_blank" href="https://www.portbase.com/wp-content/uploads/2021/07/Declaration-of-Health-nu-digitaal.pdf?utm_medium=email" class="fw-bold">Hier</a> vindt u uitgebreide uitleg over de nieuwe werkwijze en met dit document in het <a target="_blank" href="https://c.spotler.com/ct/m7/k1/-TC5TrTjKMueSZpf4L3K8OzzNaYNO82Q_i99HbXoDMOVd3cahFEzBKQ-v8SJudvYZa4rT0Vj8eeYSIK9NLcaHA/UAYbxCB2jzKtsfD" class="fw-bold">Nederlands</a> of <a target="_blank" href="https://c.spotler.com/ct/m7/k1/tHpEt6azpPI2f5UyLT43EPj68zIrbIn9bHgMqYEd16B43urJoi00coLSs9KF1zgC9ByzF51Yqga4A0cTiQ8vqg/hSJfLt8gWTkIGCN" class="fw-bold">Engels</a> kunt u kapiteins informeren.
      </p>
      <p>
        Met vriendelijke groet, <br>
        Havenbedrijf Rotterdam, Havenbedrijf Amsterdam, Portbase
      </p>
    </div>
  </div>
  <hr>

  <div class="news-item mb-3">
    <h2>
      <div style="display: inline-block;">Aanscherping beleid opvang bemanningen aan de wal</div>
      <div style="display: inline-block; float: right">31 Maart 2021</div>
    </h2>
    <div>
      <p>
        De afgelopen maand zijn er tientallen bemanningsleden die met corona besmet waren, ernstig ziek geworden. Zij zijn ondergebracht in een zorginstelling en een tiental zelfs in het ziekenhuis en IC.
      </p>
      <p>
        Het verloop van het ziektebeeld kan erg grillig zijn en een besmet persoon kan in zeer korte tijd van klachtenvrij naar zeer ernstige klachten gaan en medische zorg nodig hebben. Voor deze mensen is het van levensbelang dat er snel een arts geraadpleegd kan worden.
      </p>
      <p>
        Om deze reden gaan we voortaan werken met een incheckformulier dat elk bemanningslid dat in Wings Hotel wordt opgevangen, bij zich heeft. De scheepsagent speelt een belangrijke rol bij het invullen van het incheckformulier.
      </p>
      <p>
        Alle belangrijke aanscherpingen op een rij;
      </p>
      <ul>
        <li>De scheepsagent geeft elke wijziging in de gezondheidssituatie aan boord, terwijl het schip al in de haven is, door aan het HCC; 010-2521100 / <span><a href="mailto:DHMR_OM@portofrotterdam.com" rel="noreferrer">DHMR_OM@portofrotterdam.com</a></span></li>
        <li>Alleen bemanningsleden met een medische beoordeling door GGD of huisarts kunnen aan wal worden opgevangen. Deze  beoordeling betreft ook de plek van opvang; Wings Hotel, zorglocatie of ziekenhuis (triage). Na de triage kan de scheepsagent het hotel contacteren en transport regelen.</li>
        <li>De scheepsagent draagt zorg voor het invullen van het incheckformulier “Check-in form quarantine/isolation ashore, Port of Rotterdam”.</li>
        <li>De agent draagt zorg dat elke zeevarende die vanwege corona aan wal wordt opgevangen, het ingevulde incheckformulier bij zich draagt.</li>
        <li>De agent stuurt tevens het ingevulde formulier aan <span><a href="mailto:m.vanBallegooijen@wingshotel.nl" rel="noreferrer">m.vanBallegooijen@wingshotel.nl</a></span></li>
        <li>Het formulier is geen vervanging van de toestemming om in te reizen. Deze toestemming van de immigratiedienst is nog steeds benodigd.</li>
        <li>De agent wordt door Wings Hotel op de hoogte gesteld als er sprake is van overplaatsing naar zorglocatie of ziekenhuis, waarna de agent deze overplaatsing doorgeeft aan de ZHP.</li>
        <li>De scheepsagent draagt er zorg voor dat de zeevarende die aan wal wordt opgevangen, de beschikking heeft over een mobiele telefoon.</li>
      </ul>
      <p>
        Port Health Authority Rotterdam
      </p>
      <p>
        <img src="assets/img/news/port-health-authority-rotterdam.jpg" width="370px">
        <img src="assets/img/news/port-of-rotterdam.png" width="140px">
      <p>
    </div>
  </div>
  <hr>

  <div class="news-item mb-3">
    <h2>
      <div style="display: inline-block;">Ondersteuning pandemie door scheepsagenten</div>
      <div style="display: inline-block; float: right">8 Februari 2021</div>
    </h2>
    <div>
      <p>
        Het afgelopen jaar zijn er meer dan 750 zeevarenden in Wings Hotel opgevangen voor Covid-19 quarantaine of isolatie. Dit is allemaal mogelijk dankzij een zeer goede samenwerking tussen scheepsagenten, (haven)artsen, GGD, Port Health Authority Rotterdam, en Wings Hotel!
      </p>
      <p>
        Iedereen heeft hierin zijn eigen rol; Wings Hotel draagt zorg voor goed gereinigde kamers en wasgoed, 3x per dag een maaltijd volgens een vast protocol en geregeld een praatje met de gasten die immers niet naar buiten mogen, zelfs de gang niet op! Artsen nemen de temperatuur op, beslissen over de gezondheidssituatie van mensen en medicatie, en of extra zorg nodig is. Scheepsagenten faciliteren -namens de werkgever- belangrijke zaken zoals taxivervoer, het melden van mensen die besmet zijn geraakt, het contacteren van artsen en het bezorgen van medicijnen;
      </p>
      <ul>
        <li>De scheepsagent regelt het taxivervoer volgens de RIVM richtlijnen en laat het hotel via 06 19857800 de aankomsttijd van de taxi weten.</li>
        <li>De scheepsagent informeert het betreffende bemanningslid over de locatie waar hij naartoe vervoerd wordt, de reden hiervan en de mondkapjes-plicht.</li>
        <li>De scheepsagent meldt aan de GGD de persoon die voor quarantaine of isolatie vervoerd is, plus het schip en werkgever.</li>
        <li>De agent of de gezagvoerder nemen dagelijks meerdere malen contact op met het bemanningslid, bij voorkeur op de vaste lijn van de hotelkamer.</li>
        <li>Wanneer zo’n contact de indruk geeft dat de persoon medische zorg nodig heeft, of wanneer een bemanningslid bij Wings Hotel aangeeft zich niet lekker te voelen, waarschuwt de scheepsagent een (huis)arts of Port Health Center (haven)arts.</li>
        <li>Wanneer er behoefte aan medicijnen is, laat de scheepsagent dat bij het hotel bezorgen.</li>
      </ul>
      <p>
        We hopen er dit jaar weer een fijne samenwerking van te maken! Dank voor jullie medewerking!
      </p>
      <p>
        Port Health Authority Rotterdam
      </p>
      <p>
        <img src="assets/img/news/port-health-authority-rotterdam.jpg" width="370px">
        <img src="assets/img/news/port-of-rotterdam.png" width="140px">
      <p>
    </div>
  </div>
  <hr>

  <div class="news-item mb-3">
    <h2>
      <div style="display: inline-block;">As from today, report your import cargo via Cargo Declaration Import 2.0.</div>
      <div style="display: inline-block; float: right">1 February 2021</div>
    </h2>
    <div>
      <p>
        The renewed service Cargo Declaration Import 2.0 has been added to your account. For all vessel calls that are created from now on, you report the import cargo in the renewed service. The service appears as a separate tab next to he tabs for Vessel Notification and Notification Danger Goods.
        Click <a href="https://support.portbase.com/services/melding-lading-import/" class="fw-bold">here</a> for the service’s support page. Here you can quickly find instructions video’s and FAQ.
      </p>
    </div>
  </div>
  <hr>

  <div class="news-item mb-3">
    <h2>
      <div style="display: inline-block;">Onderhoud systeem havenmeester Rotterdam en Amsterdam (HAMIS)</div>
      <div style="display: inline-block; float: right">14 January 2021</div>
    </h2>
    <div>
      <p>
        Op zondag 17 januari tussen 09.00 – 15.00 zal er onderhoud plaatsvinden aan het systeem van de havenmeesters van Rotterdam en Amsterdam (HAMIS).
        Er worden dan geen meldingen verwerkt en er wordt ook geen informatie teruggekoppeld vanuit HAMIS (denk aan ATA’s, ATD’s, bestellingen etc.)
      </p>
    </div>
  </div>
  <hr>

  <div class="news-item mb-4">
    <h2>
      <div style="display: inline-block;">Brexit related changes for Vessel Notification</div>
      <div style="display: inline-block; float: right">1 Januari 2021</div>
    </h2>
    <div>
      <ul>
        <li>
          If the previous port is outside EU, the ETA of first entry into the EU is required. This is now also the case for GB ports.
        </li>
        <li>
          The GB Eori numbers are not valid anymore. For carriers we need the EU EORI number. <br>
          You can either inform our servicedesk and we will add the correct EORI number or manually report the correct EORI in the 'Carrier customs ID'
        </li>
      </ul>
    </div>
  </div>
  <hr>

  <div class="news-item mb-3">
    <h2>
      <div style="display: inline-block;">Onderhoud systeem havenmeester Rotterdam en Amsterdam (HAMIS)</div>
      <div style="display: inline-block; float: right">11 December 2020</div>
    </h2>
    <div>
      <p>
        Op zaterdag 12 december tussen 09.00 – 12.00 zal er onderhoud plaatsvinden aan het systeem van de havenmeesters van Rotterdam en Amsterdam (HAMIS).
        Er worden dan geen meldingen verwerkt en er wordt ook geen informatie teruggekoppeld vanuit HAMIS (denk aan ATA’s, ATD’s, bestellingen etc.)
      </p>
      <b>Support</b>
      <br>
      <p>
        Via <a title="" rel="noopener noreferrer" target="_blank" href="https://support.portbase.com/services/melding-schip/">Portbase Support</a> is gedetailleerde gebruiksinformatie beschikbaar.
        Hier vindt u onder meer 2 instructiefilmpjes over bovenstaande werkwijze.
      </p>
    </div>
  </div>
  <hr>

  <div class="news-item mb-3">
    <h2>
      <div style="display: inline-block;">Nieuwe versie Melding Lading Import binnenkort beschikbaar</div>
      <div style="display: inline-block; float: right">09 November 2020</div>
    </h2>
    <div>
      <p>
        Portbase introduceert binnenkort een vernieuwde service Melding Lading Import. De bestaande dienstverlening is
        overgebouwd en daarmee klaar voor de toekomst. Een wens vanuit het Portbase klantpanel
        <span><a title="" rel="noopener noreferrer" target="_blank"
                 href="https://www.portbase.com/over-ons/klantpanels/">Rederijen & Cargadoors</a></span> is hiermee ingevuld.
        Nu volgt een pilot met een aantal containercargadoors en bulkagenten. Als na een maand iedereen
        tevreden is met de werking, worden ook alle andere cargadoors en agenten overgezet. U wordt tijdig geïnformeerd
        als de pilot afgelopen is en wij u gaan overzetten.</p>
      <br>
      <p><b>Belangrijkste voordelen van de vernieuwde service</b></p>
      <ul>
        <li>De service komt als apart tabblad beschikbaar naast de tabbladen van Melding Schip en Melding Gevaarlijke Stoffen.
          Op één scherm heeft u zo het totale overzicht van uw meldingen voor schip en lading.
        </li>
        <li>U kunt heel eenvoudig terugzoeken wie, wat, wanneer gemeld heeft en wanneer de meldingen zijn geaccepteerd door de Douane.
          Dit inzicht in de historie was een grote wens vanuit het klantpanel.
        </li>
        <li>De service bepaalt <b>automatisch</b> of er een ENS en/of ATO aan de Douane moet worden gemeld. Als eerst een ENS nodig is,
          dan wordt na het akkoord van de Douane hierop, vervolgens <b>automatisch</b> ook een ATO verstuurd naar de Douane.
        </li>
        <li>Op <b>elk moment</b> kunt u de Douane een update sturen. Het is niet meer nodig te wachten totdat een eerdere melding akkoord is bevonden.
      </ul>
      <br>
      <p>De services Losbevestigingsrapportage, Afschrijvingsrapportage en Loslijst zijn meegenomen in de vernieuwing.
        De voordelen van deze services werken ook in combinatie met het vernieuwde Melding Lading Import.</p>
      <p><b>Meldt u de importlading via de systeemkoppeling?</b></p>
      <p>U hoeft <b>niets te wijzigen</b> aan de wijze waarop het bericht (IFTMCS) wordt ingestuurd. Ook aan de inhoud van het bericht verandert niets.
        Het is tevens mogelijk om via API te koppelen, zowel als alternatief voor de berichten als voor de mailnotificaties.</p>
      <p><b>Support</b></p>
      <p>Bij livegang komt via <a title="" rel="noopener noreferrer" target="_blank" href="https://support.portbase.com/services/melding-lading-import/">Portbase Support</a> gedetailleerde gebruiksinformatie beschikbaar.
        Hier vindt u dan onder meer een Quick Start Guide en de antwoorden op veelgestelde vragen.</p>
    </div>
  </div>
  <hr>

  <div class="news-item mb-3">
    <h2>
      <div style="display: inline-block;">Meer tijd voor uw melding bij overdracht scheepsbezoek</div>
      <div style="display: inline-block; float: right">04 November 2020</div>
    </h2>
    <div>
      <p>
        Vanaf vandaag is het voorbereiden op de overdracht van het scheepsbezoek een stuk makkelijker geworden.
        U kunt namelijk de volgende agent eerder toegang geven tot uw scheepsbezoek. De volgende agent kan zodoende reeds zijn meldingen voorbereiden,
        met name de benodigde informatie voor de bestelling van de reis en de gevaarlijke stoffen. Op het overdrachtsmoment (ATA op de ligplaats) kunnen deze meldingen vervolgens
        direct naar de havenmeester.
      </p>
      <b>Support</b>
      <br>
      <p>
        Via <a title="" rel="noopener noreferrer" target="_blank" href="https://support.portbase.com/services/melding-schip/">Portbase Support</a> is gedetailleerde gebruiksinformatie beschikbaar.
        Hier vindt u onder meer 2 instructiefilmpjes over bovenstaande werkwijze.
      </p>
    </div>
  </div>
  <hr>

  <div class="news-item mb-3">
    <h2>
      <div style="display: inline-block;">Nieuwe introductiesessie Melding Schip</div>
      <div style="display: inline-block; float: right">02 November 2020</div>
    </h2>
    <div>
      <p>
      Onlangs zijn er meerdere online instructiesessies voor Melding Schip geweest over de werkwijze m.b.t. het bestellen van inkomende schepen (incl. uitwisselen).
       Een van deze sessies is opgenomen. Wilt u deze (nog eens) bekijken? Bezoek dan onze
       <a title="" rel="noopener noreferrer" target="_blank" href="https://support.portbase.com/services/melding-schip/">supportpagina</a>.
      </p>
    </div>
  </div>
  <hr>

  <div class="news-item mb-3">
    <h2>
      <div style="display: inline-block;">Onderhoud werkzaamheden HaMIS; uitwijktest 18-10</div>
      <div style="display: inline-block; float: right">13 oktober 2020</div>
    </h2>
    <div>
      <p>
      <b>Onderhoud werkzaamheden HaMIS; uitwijktest</b>
      <br>
      Onder voorbehoud van omstandigheden zijn we voornemens om zondag 18 oktober onderhoud te doen aan de HaMIS systemen.
      HaMIS zal een uitwijktest van datacentrum doen met onder andere de UH2 server.
      </p>
      <p>
      Tijdens deze uitwijk komen de Gevaarlijke stoffen meldingen bij HaMIS binnen maar deze raken wij kwijt als wij terug gaan naar het primaire datacentrum.
      Kort voor het eind van de uitwijktest zullen wij u vragen om de Gevaarlijke stoffen meldingen vanaf 08:55 uur (voor aanvang uitwijktest), welke via WMQ gaan, opnieuw aan te bieden.
      </p>
      <p>
      Aanvulling: Gevaarlijke stoffen meldingen voor Rotterdam én Amsterdam.
      </p>
        <b>Wanneer</b>
      <div>
        <label>Dag:</label>
        <span>zondag</span>
      </div>
      <div>
        <label>Datum:</label>
        <span>18 oktober</span>
      </div>
      <div>
        <label>Tijdstip van/tot:</label>
        <span>09:00 / 15:00</span>
      </div>
      <br/>
      Voorafgaand op de werkzaamheden zal contact opgenomen worden met de DO HCC.
      <br/><br/>
      <p>
        <b>Vragen</b>
        <br>
        Voor vragen en/of informatie over dit onderhoud kunt u contact op nemen met Functioneel Beheer HaMIS, Henk Vane of Richard Beekmans.
        Dit kan telefonisch (tijdens kantooruren) of per e-mail. Zie afzender.
      </p>
      <p>Vertrouwende hiermee voldoende te hebben geïnformeerd.</p>
    </div>
  </div>
  <hr>

  <div class="news-item mb-3">
    <h2>
      <div style="display: inline-block;">Gebruik geen auto-order bij tij-gebonden schip dat moet uitwisselen</div>
      <div style="display: inline-block; float: right">6 oktober 2020</div>
    </h2>
    <div>
      <br>
      Voor een tij-gebonden schip dat moet uitwisselen, gebruikt u (voorlopig nog) AUTO-ORDER = NO.
      Hiermee voorkomt u dat een wijziging op de ETD van het vertrekkende schip ervoor zorgt dat uw
      inkomende schip buiten de geldende tij-poort wordt besteld. U krijgt bij de keuze NO alerteringen
      bij wijziging op de ETD zodat u evt. handmatig uw ETA hierop kunt aanpassen.
    </div>
  </div>
  <hr>

  <div class="news-item mb-3">
    <h2>
      <div style="display: inline-block;">Wijziging van het proces voor het melden én bestellen van inkomende en doorgaande reizen</div>
      <div style="display: inline-block; float: right">8 September 2020</div>
    </h2>
    <div>
      <b>Live op 8 september</b>
      <br>
      Portbase zet u per 8 september 09:00 uur over op de nieuwe werkwijze. Dit betekent dat u voor nieuwe scheepsbezoeken
      die vanaf dat tijdstip zijn aangemaakt via de nieuwe werkwijze werkt.
      Voor bezoeken die vóór dat tijdstip zijn aangemaakt, werkt u nog met de huidige werkwijze.
      <br/><br/>
      Heeft u vragen over de functionaliteit van ‘Melding schip’, neem dan contact op met de <span><a
      href="mailto:servicedesk@portbase.com?subject=Ordering%20incoming%20and%20onward journeys"
      rel="noreferrer">Servicedesk</a></span> van Portbase.
      <br/>
      Heeft u vragen over het nieuwe proces voor I/D-reizen, neem dan contact op met <span><a
      href="mailto:L.van.Waasdijk@portofrotterdam.com?subject=Ordering%20incoming%20and%20onward journeys"
      rel="noreferrer">L.van Waasdijk</a></span>
    </div>
  </div>
  <hr>

  <div class="news-item mb-3">
    <h2>
      <div style="display: inline-block;">Reminder: Wijziging van het proces voor het melden én bestellen van inkomende en doorgaande reizen</div>
      <div style="display: inline-block; float: right">2 September 2020</div>
    </h2>
    <div>
      <br>Begin juli hebben wij u op de hoogte gebracht dat op dinsdag 8 september het proces voor het melden én
      bestellen van inkomende en doorgaande reizen wijzigt.
      <br>
      <br>
      <b>Live op 8 september</b>
      <br>
      Op 25 augustus is een 2 weken durende pilotfase gestart waarin we met een beperkt aantal agentschappen alvast
      zijn gaan proefdraaien in de productie omgeving. Hiermee kunnen we in de praktijk toetsen of alles naar behoren
      werkt voordat we met alle agentschappen live gaan.
      Tot nu toe zijn er geen blokkerende problemen ontdekt en gaan we er vooralsnog vanuit dat we op 8 september live gaan.
      <br>
      <br>
      <b>Informatiesessies voor agenten</b>
      <br>
      Er zijn inmiddels 6 online informatiesessies geweest waarin wij het vernieuwde proces en de nieuwe functionaliteit
      in het PCS via MS Teams hebben gedemonstreerd. Er worden nog 2 sessies gegeven, nl. op 9 en 16 september vanaf 13:00 uur.
      De sessie duurt ongeveer 1,5 uur.
      <br><br>
      Voor meer informatie kunt u kijken naar de
      <a title="" rel="noopener noreferrer" target="_blank" href="https://www.portbase.com/wp-content/uploads/2020/07/Nieuwe-werkwijze-inkomende-en-doorgaande-reizen-Juli-2020.pdf">eerdere mailing van de havenmeester</a>.
      Ook op de <a title="" rel="noopener noreferrer" target="_blank" href="https://support.portbase.com/en/services/vessel-notification/">Portbase support pagina</a> kunt u de Quick Start Guide erop naslaan.
      <br><br>
      Heeft u vragen over de functionaliteit van ‘Melding schip’, neem dan contact op met de <span><a
      href="mailto:servicedesk@portbase.com?subject=Ordering%20incoming%20and%20onward journeys"
      rel="noreferrer">Servicedesk</a></span> van Portbase.
      <br>
      Heeft u vragen over het nieuwe proces voor I/D-reizen, neem dan contact op met <span><a
      href="mailto:L.van.Waasdijk@portofrotterdam.com?subject=Ordering%20incoming%20and%20onward journeys"
      rel="noreferrer">L.van Waasdijk</a></span>
    </div>
  </div>
  <hr>
  <div class="news-item mb-3">
    <h2>
      <div style="display: inline-block;">Process for reporting and ordering journeys will change</div>
      <div style="display: inline-block; float: right">5 August 2020</div>
    </h2>
    <div>
      <br>On behalf of the Harbour Masters and Loodswezen (Dutch Maritime Pilots’ Organisation), we would like
      to inform you that as of Tuesday the 8th of September, the process for reporting and ordering incoming and onward
      journeys will change.
      <br>
      The main change is that incoming and onward journeys will now also be explicitly ordered.
      This is done electronically via the PCS, as is currently already the case for outbound and shifting trips.
      <br><br>
      A lot of information about the changes is available in the
      <a title="" rel="noopener noreferrer" target="_blank" href="https://www.portbase.com/wp-content/uploads/2020/07/Nieuwe-werkwijze-inkomende-en-doorgaande-reizen-Juli-2020.pdf">mailing of the Harbour Master (Dutch)</a>.
      The <a title="" rel="noopener noreferrer" target="_blank" href="https://support.portbase.com/en/services/vessel-notification/">Portbase Support page</a> features a Quick Start Guide as well as frequently asked questions, which are regularly updated.
      <br><br>
      Do you have any questions? Then ask them by e-mail: <span><a
      href="mailto:L.van.Waasdijk@portofrotterdam.com?subject=Ordering%20incoming%20and%20onward journeys"
      rel="noreferrer">L.van.Waasdijk@portofrotterdam.com</a></span>
    </div>
  </div>
  <hr>
  <div class="news-item mb-3">
    <h2>
      <div style="display: inline-block;">Reminder meldingen gezondheidssituatie aan boord</div>
      <div style="display: inline-block; float: right">30 July 2020</div>
    </h2>
    <div>
      <p><b>Alle zeeschepen die Rotterdam aandoen dienen een Maritime Declaration of Health (MDoH) in bij
        het Haven Coördinatie Centrum (HCC) van het Havenbedrijf Rotterdam; via <span><a
          href="mailto:PHAR@portofrotterdam.com?subject=Meldingen%20gezondheidssituatie%20aan%20boord"
          rel="noreferrer">PHAR@portofrotterdam.com</a></span>.
        De beoordeling van de MDoH moet gedaan zijn voordat een schip toestemming krijgt Rotterdam binnen te komen.<br>
        Een MDoH die onjuist wordt ingestuurd kan tot vertraging leiden.</b></p>

      <br> Bij verhaalreizen en uitgaande reizen wordt er geen MDoH ingediend. Hier geldt wél de verplichting,
      vanuit de Wet Publieke Gezondheid, dat elke verandering in de gezondheidssituatie aan boord door het schip,
      of diens agent, bij het HCC gemeld wordt via <span><a
      href="mailto:PHAR@portofrotterdam.com?subject=Meldingen%20gezondheidssituatie%20aan%20boord"
      rel="noreferrer">PHAR@portofrotterdam.com</a></span>.
      Het achterwege laten van deze melding kan leiden tot strafrechtelijke vervolging.
      <br>
      <br>
      Het HCC draagt zorg voor het informeren van de nautische dienstverleners en de agent van het betreffende schip,
      indien er bijzonderheden rond de gezondheid van bemanningsleden door de GGD geconstateerd zijn.
      De scheepsagent kan daarop de terminal en eventueel andere dienstverleners op de hoogte brengen, zodat zij
      passende hygiënemaatregelen kunnen treffen bij het aan boord gaan.
      <br>
      <br>
      Nu het aantal Covid-19 besmettingen in de regio Rotterdam weer oploopt, is het belangrijk dat eenieder in
      de haven verantwoordelijk blijft omgaan zowel met de RIVM hygiënemaatregelen als met de verplichte meldingen
      en het inzenden van MDoH’s. Alleen samen krijgen we corona onder controle.
      <br>
      <br>
      Bedankt voor de medewerking!
      <br>
      <br>
      Port Health Authority Rotterdam
      <br><br>
      <p>
        <img src="assets/img/news/port-of-rotterdam.png" width="140px">
        <img src="assets/img/news/port-health-authority-rotterdam.jpg" width="370px">
      <p>
    </div>
  </div>
  <hr>
</div>
