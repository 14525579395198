import {Component, Input, OnInit} from '@angular/core';
import {
  IE3ConsignmentProcess,
  IE3ConsignmentProcessLogItem,
  IE3GetConsignmentHistoryLog
} from "@portbase/bezoekschip-service-typescriptmodels";
import {Observable} from "rxjs";
import {sendQuery} from "../../../../common/utils";
import {map} from "rxjs/operators";
import lodash from "lodash";
import {PortvisitUtils} from "../../../../refdata/portvisit-utils";
import {AppContext} from "../../../../app-context";

@Component({
  selector: 'app-consignment-history-details',
  templateUrl: './consignment-history-details.component.html',
  styleUrls: ['./consignment-history-details.component.scss']
})
export class ConsignmentHistoryDetailsComponent implements OnInit {
  appContext = AppContext;
  portVisitUtils = PortvisitUtils;
  @Input() consignmentProcess: IE3ConsignmentProcess;
  historyItemsQuery: Observable<IE3ConsignmentProcessLogItem[]>;

  ngOnInit() {
    this.historyItemsQuery = sendQuery("com.portbase.bezoekschip.common.api.consignments.queries.GetConsignmentHistoryLog", <IE3GetConsignmentHistoryLog>{
      consignmentProcessId: this.consignmentProcess.consignmentProcessId
    }, { caching: false }).pipe(map((h: IE3ConsignmentProcessLogItem[]) => lodash.assign([], h).reverse()));
  }

  trackByHistoryItem = (index: number, record: IE3ConsignmentProcessLogItem) => record.eventId;

  splitTimeAndDate = (dateAndTime: string) => dateAndTime.split(" at ");

  formatUser = (historyItem: IE3ConsignmentProcessLogItem) => [historyItem.user, historyItem.fullName].filter(a => a).join(" – ");

  openInEmhEnabled = (historyItem: IE3ConsignmentProcessLogItem) => historyItem.emhProcessId;
}
