import {Component, ElementRef, OnInit} from '@angular/core';
import {VisitContext} from '../visit-context';
import {
  DeclarationType,
  DeclareShipStores,
  ShipStoresDeclaration
} from '@portbase/bezoekschip-service-typescriptmodels';
import {uploadShipsStoresForm} from './ship-stores.upload';
import emptyDeclaration from './empty-declaration.json';
import {$try, checkValidity, cloneObject, sendCommand, wrapCommand} from '../../common/utils';
import {AppContext} from '../../app-context';

@Component({
  selector: 'app-ship-stores',
  templateUrl: './ship-stores.component.html',
  styleUrls: ['./ship-stores.component.css']
})
export class ShipStoresComponent implements OnInit {
  VISIT = DeclarationType.VISIT;

  context = VisitContext;
  appContext = AppContext;
  upload = uploadShipsStoresForm;
  disabled: boolean;
  showSendConfirmation = false;
  uploadedXls: string;

  constructor(private element: ElementRef) {
  }

  ngOnInit() {
    this.context.visit.shipStoresDeclaration = this.context.visit.shipStoresDeclaration ||
      <ShipStoresDeclaration>{items: cloneObject(emptyDeclaration)};
  }

  isDeclaredOrAccepted() {
    const declaration = this.context.findLatestDeclaration(DeclarationType.MSV);
    return declaration ? (declaration.status === "DECLARED" || declaration.status === "ACCEPTED") : false;
  }

  isSaveAndSendAllowed() {
    if (this.context.visit.portOfCall.paDeclarationRequired) {
      return this.context.visit.visitKnownAtPa;
    }
    return true;
  }

  isEmptyDeclaration() {
    return Object.keys(this.context.visit.shipStoresDeclaration.items)
      .filter(item => this.context.visit.shipStoresDeclaration.items[item] != 0).length == 0;
  }

  checkSaveAndSend = () => {
    if (checkValidity(this.element)) {
      if (this.isEmptyDeclaration()) {
        this.showSendConfirmation = true;
      } else {
        this.saveAndSend();
      }
    }
  };

  saveAndSend = () => {
    this.showSendConfirmation = false;
    if (checkValidity(this.element)) {
      const command = <DeclareShipStores>{
        crn: this.context.visit.crn,
        shipStoresDeclaration: this.context.visit.shipStoresDeclaration
      };

      sendCommand('com.portbase.bezoekschip.common.api.shipstores.DeclareShipStores',
        this.uploadedXls ? wrapCommand(command, this.uploadedXls) : command, () => {
          this.context.replaceVisit(this.context.visit);
          AppContext.registerSuccess('The declaration was sent successfully.')
        });
    }
  };

  getRejectReason() {
    return $try(() => this.context.findLatestDeclaration(DeclarationType.MSV).rejectReasons);
  }
}
