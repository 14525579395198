import {Component} from '@angular/core';
import {ChartConfiguration} from "chart.js";
import {ServiceMonitoringBaseChart} from "../service-monitoring-base-chart.component";

@Component({
  selector: 'app-dashboard-line-chart',
  templateUrl: './service-monitoring-line-chart.component.html',
  styleUrls: ['./service-monitoring-line-chart.component.scss'],
  providers: [ {provide: ServiceMonitoringBaseChart, useExisting: ServiceMonitoringLineChartComponent }]
})
export class ServiceMonitoringLineChartComponent extends ServiceMonitoringBaseChart {

  getOptions(): ChartConfiguration<'line'>['options'] {
    return {
      scales: {
        x: {
          position: 'bottom',
          type: 'time',
          time: {
            tooltipFormat: 'DD MMM YYYY HH:mm:ss',
            displayFormats: {
              hour: 'HH:mm',
              minute: 'HH:mm',
              second: 'HH:mm:ss'
            }
          }
        },
        y: {
          min: 0
        }
      },
      datasets: {
        line: {
          pointRadius: 0.5,
          tension: 0,
          borderWidth: 2
        }
      },
      plugins: {
        tooltip: this.tooltipOptions,
        legend: {
          display: false,
          position: "top"
        },
        title: {
          display: true,
          text: this.chartTitle + (this.totalCount ? " (" + this.totalCount + ")" : ""),
          font: {
            size: 16
          }
        },
        zoom: this.zoomOptions
      }
    }
  }
}
