import {cloneObject, uuid} from "../common/utils";
import {CommercialRelease, DeclarationStatus, Modality} from '@portbase/bezoekschip-service-typescriptmodels';

export const releaseWithCrn: CommercialRelease =
  {
    "id": "4d02a9a47ec24b39a0b784a17ebb8b4a",
    "crn": "NLRTM_DANGEROUS_GOODS",
    "releaseData": {
      "equipmentNumber": "TEST2301222",
      "terminalShortName": "RWG",
      "containerOperator": {
        "name": "Eimskip",
        "cargoDeclarantShortName": "EIMSKIPT",
        "smdgCode": "CA1"
      },
      "consignmentNumber": "CONSIGNMENTNR",
      "releaseDeadline": "2022-01-13T22:59:00Z",
      "releaseToParty": {"portbaseId": "VRV1", name: 'VRV1', customerReference: 'VRV1'}
    },
    "releaseNumber": "###########",
    "releaseStatus": {
      "status": DeclarationStatus.DECLARED,
      "declarationDate": "2022-01-12T22:59:00Z",
      "rejectReasons": null,
      "cancelled": false
    }
  };


export const releaseWithoutCrn: CommercialRelease = {
  "id": "890ba8dcc0854aa2a9c215d6a94f901b",
  "crn": null,
  "releaseData": {
    "equipmentNumber": "TEST2301223",
    "terminalShortName": "RWG",
    "containerOperator": {
      "name": "Eimskip",
      "cargoDeclarantShortName": "ONE",
      "smdgCode": "CA2"
    },
    "consignmentNumber": "CONSIGNMENTNR",
    "releaseDeadline": "2022-01-13T22:59:00Z",
    "releaseToParty": {"portbaseId": "VRV1", name: 'VRV1', customerReference: 'VRV1'},
    "releaseModality": Modality.barge,
    "remarks": "call to extend"
  },
  "releaseNumber": "###########",
  "releaseStatus": {
    "status": DeclarationStatus.DECLARED,
    "declarationDate": "2022-01-14T22:59:00Z",
    "rejectReasons": null,
    "cancelled": false
  }
};


export const releaseWithoutDeclarationDate: CommercialRelease = {
  "id": "890ba8dcc0854aa2a9c215d6a94f901b",
  "crn": null,
  "releaseData": {
    "equipmentNumber": "TEST2301223",
    "terminalShortName": "RWG",
    "containerOperator": {
      "name": "Eimskip",
      "cargoDeclarantShortName": "ONE",
      "smdgCode": "CA2"
    },
    "consignmentNumber": "CONSIGNMENTNR",
    "releaseDeadline": "2022-01-13T22:59:00Z",
    "releaseToParty": {"portbaseId": "VRV1", name: 'VRV1', customerReference: 'VRV1'},
    "releaseModality": Modality.barge,
    "remarks": "call to extend"
  },
  "releaseNumber": "###########",
  "releaseStatus": {
    "status": DeclarationStatus.DECLARED,
    "rejectReasons": null,
    "cancelled": false
  }
};

function accept(input: CommercialRelease) {
  let object = cloneDeclaration(input)
  object.releaseStatus.status = DeclarationStatus.ACCEPTED
  return object;
}

function reject(input: CommercialRelease) {
  let object = cloneDeclaration(input)
  object.releaseStatus.status = DeclarationStatus.REJECTED
  object.releaseStatus.rejectReasons = 'Some reason yes'
  return object;
}

function cancel(input: CommercialRelease) {
  let object = cloneDeclaration(input)
  object.releaseStatus.cancelled = true
  return object;
}

export const CommercialReleaseMock: CommercialRelease[] = [
  releaseWithCrn,
  releaseWithoutCrn,
  releaseWithoutDeclarationDate,
  accept(<any>releaseWithCrn),
  reject(<any>releaseWithCrn),
  cancel(<any>releaseWithCrn),
  cancel(accept(<any>releaseWithCrn)),
  cancel(reject(<any>releaseWithCrn))
]

function cloneDeclaration(input: CommercialRelease): CommercialRelease {
  const result = cloneObject(input);
  result.id = uuid();
  return result;
}
