import {
  Carrier,
  Country,
  CustomsOffice,
  DocumentType,
  FindDocumentTypes,
  FindGoodsClassifications,
  GoodsClassification,
  IE3AddressBook,
  IE3CL116,
  IE3CL214,
  IE3CL704,
  IE3CL710,
  IE3CL711,
  IE3CL745,
  IE3Commodity,
  IE3CommodityCode,
  IE3ConsignmentHouseLevel,
  IE3ConsignmentMasterLevel,
  IE3ConsignmentProcess,
  IE3ConsignmentProcessSummary,
  IE3CustomsProcess,
  IE3CustomsStatus,
  IE3EquipmentSummary,
  IE3FilingType,
  IE3FindParties,
  IE3GoodsItem,
  IE3GoodsItemSummary,
  IE3GoodsPlacement,
  IE3GoodsPlacementSummary,
  IE3MasterConsignment,
  IE3Party,
  IE3PartyPersonType,
  IE3Place,
  IE3SizeType,
  IE3TransportConditionCode,
  IE3TransportEquipment
} from "@portbase/bezoekschip-service-typescriptmodels";
import {PortvisitUtils} from "../../refdata/portvisit-utils";
import {Observable, of} from "rxjs";
import {publishEvent, sendQuery} from "../../common/utils";
import {map} from "rxjs/operators";
import lodash from "lodash";
import {AppContext} from "../../app-context";
import {TemplateRef} from "@angular/core";
import {MiddleEllipsisPipe} from "../../common/middle-ellipsis.pipe";
import {
  ConsignmentRouteInfo
} from "./details/master-consignment-details/master-consignment-route/master-consignment-route.component";

export class ConsignmentUtils {

  private static euCountries: string[] = ["GF", "NL", "FR", "RE", "SE", "LU", "MT", "DK", "SK", "BE", "EE", "SI", "YT",
    "FI", "CY", "PT", "MC", "HR", "IE", "CZ", "MQ", "ES", "LT", "BG", "LV", "IT", "AX", "PL", "GR", "RO", "HU", "DE"];

  private static allCountries: Map<string, Country>;

  static isDutchPort = (place: IE3Place) => place?.countryUnCode === "NL";
  static isEuPort = (place: IE3Place) => place?.euLocation;
  static isEuCountry = (country: Country) => country ? this.euCountries.includes(country.code) : false;
  static ensFilingRequired = (filingType: IE3FilingType) => [IE3FilingType.F10, IE3FilingType.F11, IE3FilingType.F12,
    IE3FilingType.F13, IE3FilingType.F50].includes(filingType);

  static constructConsignmentProcessId = (cargoDeclarantId: string, consignmentNumber: string) =>
    !cargoDeclarantId || !consignmentNumber ? null : `${cargoDeclarantId}_${consignmentNumber}`;

  static splitCommodityCode = (code: string): IE3CommodityCode => {
    let codeTrimmed: string = code.trim();
    return {
      harmonizedSystemSubHeadingCode: codeTrimmed.length <= 6 ? codeTrimmed : codeTrimmed.substring(0, 6),
      combinedNomenclatureCode: codeTrimmed.length > 6 ? codeTrimmed.substring(6) : null
    }
  }
  static findCommodities = term => sendQuery("com.portbase.bezoekschip.common.api.cargo.FindGoodsClassifications", <FindGoodsClassifications>{
    term: term,
    minimumCodeSize: 6
  }).pipe(map((g: GoodsClassification[]) => g.map(s => (<IE3Commodity>{
    descriptionOfGoods: s.description,
    commodityCode: ConsignmentUtils.splitCommodityCode(s.code),
    sensitiveGoods: s.sensitiveGoods
  }))));
  static commodityFormatter = (commodity: IE3Commodity) => commodity ?
    MiddleEllipsisPipe.format(`${commodity.commodityCode ? (commodity.commodityCode?.harmonizedSystemSubHeadingCode || '') + (commodity.commodityCode?.combinedNomenclatureCode || '') : ''} – ${commodity.descriptionOfGoods}`, 50) : '';

  static findDocumentTypes = term => sendQuery("com.portbase.bezoekschip.common.api.cargo.FindDocumentTypes", <FindDocumentTypes>{term: term});
  static documentTypeFormatter = (document: DocumentType) => document ?
    `${document.code} – ${document.name}` : '';

  static getCountries = (): Observable<Map<string, Country>> => this.allCountries ? of(this.allCountries)
    : sendQuery("com.portbase.bezoekschip.common.api.visit.GetCountries", {})
      .pipe(map((c: Country[]) => {
        this.allCountries = c.reduce((map, country) => {
          if (country.code) {
            map.set(country.code, country);
          }
          return map;
        }, new Map<string, Country>());
        return this.allCountries;
      }));

  static transportConditionCodes: IE3TransportConditionCode[] = [null, IE3TransportConditionCode.DOOR_TO_DOOR,
    IE3TransportConditionCode.DOOR_TO_PIER, IE3TransportConditionCode.PIER_TO_DOOR,
    IE3TransportConditionCode.PIER_TO_PIER];
  static customsProcesses: IE3CustomsProcess[] = [null, IE3CustomsProcess.SEA_IN_SEA_OUT, IE3CustomsProcess.MILITARY,
    IE3CustomsProcess.EMPTY_RETURN_PACKAGING];
  static customsStatuses: IE3CustomsStatus[] = [null, IE3CustomsStatus.EU_COMMUNITY_GOODS,
    IE3CustomsStatus.EU_COMMUNITY_GOODS_IN_TRANSHIPMENT, IE3CustomsStatus.EU_PROCEDURE_T, IE3CustomsStatus.EU_PROCEDURE_T1,
    IE3CustomsStatus.EU_PROCEDURE_T2, IE3CustomsStatus.EU_PROCEDURE_T2F, IE3CustomsStatus.GOODS_FROM_EVA_COUNTRIES];
  static typeOfPartyPersons: IE3PartyPersonType[] = [null, IE3PartyPersonType.NATURAL_PERSON,
    IE3PartyPersonType.LEGAL_PERSON, IE3PartyPersonType.ASSOCIATIONS_OF_PERSONS];
  static methodsOfPayment: IE3CL116[] = [null, IE3CL116.A, IE3CL116.B, IE3CL116.C, IE3CL116.D, IE3CL116.H, IE3CL116.Y,
    IE3CL116.Z];
  static filingTypes: IE3FilingType[] = [IE3FilingType.F10, IE3FilingType.F11, IE3FilingType.F12, IE3FilingType.F13, IE3FilingType.F50];
  static supplementaryFilingTypes: IE3CL745[] = [null, IE3CL745.N1, IE3CL745.N2];
  static documentTypes: IE3CL214[] = [null, IE3CL214.C612, IE3CL214.C620, IE3CL214.N355, IE3CL214.N820, IE3CL214.N821,
    IE3CL214.N822, IE3CL214.N825, IE3CL214.N952, IE3CL214.N955];
  static additionalSupplyChainTypes: IE3CL704[] = [IE3CL704.CS, IE3CL704.FW, IE3CL704.WH];

  static filingTypeFormatter = (value: IE3FilingType) => {
    switch (value) {
      case IE3FilingType.F10:
        return "Straight B/L single"; // "Single filing straight B/L"
      case IE3FilingType.F11:
        return "Master B/L single"; // "Single filing master B/L + house B/L"
      case IE3FilingType.F12:
        return "Master B/L multiple"; // "Multiple filing master B/L only"
      case IE3FilingType.F13:
        return "Straight B/L multiple"; // "Multiple filing straight B/L only"
      case IE3FilingType.F50:
        return "Road B/L"; // "Road B/L"
      case IE3FilingType.TSR:
        return "Temporary Storage (reuse)"; // "Temporary Storage Reuse"
      case IE3FilingType.TSD:
        return "Temporary Storage"; // "Temporary Storage Declaration"
      case null:
        return "Pre ICS2 B/L"; // No filing means cargo from old Visit migrated to the new consignments
    }
  }

  static methodOfPaymentFormatter = (value: IE3CL116) => {
    switch (value) {
      case IE3CL116.A:
        return "Payment in cash";
      case IE3CL116.B:
        return "Payment by credit card";
      case IE3CL116.C:
        return "Payment by cheque";
      case IE3CL116.D:
        return "Other (e.g. direct debit to cash account)";
      case IE3CL116.H:
        return "Electronic funds transfer";
      case IE3CL116.Y:
        return "Account holder with carrier";
      case IE3CL116.Z:
        return "Not pre-paid";
    }
  }

  static findSizeTypes = term => sendQuery("com.portbase.bezoekschip.common.api.cargo.FindSizeTypes", {term: term});

  static sizeTypeFormatter = (sizeType: IE3SizeType) => {
    if (!sizeType) {
      return "";
    }
    switch (sizeType.code) {
      case IE3CL710.N1:
        return "Dime coated tank";
      case IE3CL710.N2:
        return "Epoxy coated tank";
      case IE3CL710.N6:
        return "Pressurised tank";
      case IE3CL710.N7:
        return "Refrigerated tank";
      case IE3CL710.N9:
        return "Stainless steel tank";
      case IE3CL710.N10:
        return "40 feet - Non-working reefer container";
      case IE3CL710.N12:
        return "80x120cm - Europallet";
      case IE3CL710.N13:
        return "100x120cm - Scandinavian pallet";
      case IE3CL710.N14:
        return "Trailer";
      case IE3CL710.N15:
        return "20 feet - Non-working reefer container";
      case IE3CL710.N16:
        return "Exchangeable pallet";
      case IE3CL710.N17:
        return "Semi-trailer";
      case IE3CL710.N18:
        return "20 feet - Tank container";
      case IE3CL710.N19:
        return "30 feet - Tank container";
      case IE3CL710.N20:
        return "40 feet - Tank container";
      case IE3CL710.N21:
        return "20 feet - Container IC, owned by InterContainer, a European railway subsidiary";
      case IE3CL710.N22:
        return "30 feet - Container IC, owned by InterContainer, a European railway subsidiary";
      case IE3CL710.N23:
        return "40 feet - Container IC, owned by InterContainer, a European railway subsidiary";
      case IE3CL710.N24:
        return "20 feet - Refrigerated tank";
      case IE3CL710.N25:
        return "30 feet - Refrigerated tank";
      case IE3CL710.N26:
        return "40 feet - Refrigerated tank";
      case IE3CL710.N27:
        return "20 feet - Tank container IC, owned by InterContainer, a European railway subsidiary";
      case IE3CL710.N28:
        return "30 feet - Tank container IC, owned by InterContainer, a European railway subsidiary";
      case IE3CL710.N29:
        return "40 feet - Tank container IC, owned by InterContainer, a European railway subsidiary";
      case IE3CL710.N30:
        return "20 feet - Refrigerated tank IC, owned by InterContainer, a European railway subsidiary";
      case IE3CL710.N31:
        return "30 feet - Temperature controlled container";
      case IE3CL710.N32:
        return "40 feet - Refrigerated tank IC, owned by InterContainer, a European railway subsidiary";
      case IE3CL710.N33:
        return "6,15 metres - A movable case with a length less than";
      case IE3CL710.N34:
        return "6,15 metres and 7,82 metres - A movable case with a length between";
      case IE3CL710.N35:
        return "7,82 metres and 9,15 metres - A movable case with a length between";
      case IE3CL710.N36:
        return "9,15 metres and 10,90 metres - A movable case with a length between";
      case IE3CL710.N37:
        return "10,90 metres and 13,75 metres - A movable case with a length between";
      case IE3CL710.N38:
        return "Totebin";
      case IE3CL710.N39:
        return "20 feet - Temperature controlled container";
      case IE3CL710.N40:
        return "40 feet - Temperature controlled container";
      case IE3CL710.N41:
        return "30 feet - Non working refrigerated (reefer) container";
      case IE3CL710.N42:
        return "Dual trailers";
      case IE3CL710.N43:
        return "20 feet - IL container (open top)";
      case IE3CL710.N44:
        return "20 feet - IL container (closed top)";
      case IE3CL710.N45:
        return "40 feet - IL container (closed top)";
      default:
        return sizeType.name;
    }
  }

  static supportingDocumentTypeFormatter = (type: IE3CL214): string => {
    let name;
    switch (type) {
      case IE3CL214.C612:
        name = "T2F";
        break;
      case IE3CL214.C620:
        name = "T2LF";
        break;
      case IE3CL214.N355:
        name = "ENS";
        break
      case IE3CL214.N820:
        name = "AANG.DOUANEVERVOER,T";
        break;
      case IE3CL214.N821:
        name = "T1";
        break;
      case IE3CL214.N822:
        name = "T2";
        break;
      case IE3CL214.N825:
        name = "T2L";
        break;
      case IE3CL214.N952:
        name = "TIR";
        break;
      case IE3CL214.N955:
        name = "ATA";
        break;
      case null:
      case undefined:
        return "";
    }
    return `${type} – ${name}`;
  }

  static getCustomsStatusCode = (status: IE3CustomsStatus) => {
    switch (status) {
      case 'EU_COMMUNITY_GOODS':
        return 'C';
      case 'EU_COMMUNITY_GOODS_IN_TRANSHIPMENT':
        return 'N27';
      case 'EU_EMPTY_RETURN_PACKAGING':
        return 'NP';
      case 'EU_PROCEDURE_T':
        return 'T';
      case 'EU_PROCEDURE_T1':
        return 'T1';
      case 'EU_PROCEDURE_T2':
        return 'T2';
      case 'EU_PROCEDURE_T2F':
        return 'T2F';
      case 'GOODS_FROM_EVA_COUNTRIES':
        return 'TV';
    }
  }

  static getCustomsProcessCode = (process: IE3CustomsProcess) => {
    switch (process) {
      case 'SEA_IN_SEA_OUT':
        return 'Sea in sea out';
      case 'MILITARY':
        return 'Military';
      case 'EMPTY_RETURN_PACKAGING':
        return 'Empty return packaging';
    }
  }

  static supplierTypes: IE3CL711[] = [null, IE3CL711.N1, IE3CL711.N2];

  static supplierTypeFormatter = (value: IE3CL711) => {
    switch (value) {
      case IE3CL711.N1:
        return "Shipper supplied";
      case IE3CL711.N2:
        return "Carrier supplied";
    }
  }

  static findParties = (term: string, cargoDeclarantId: string, party?: IE3Party): Observable<IE3AddressBook[]> =>
    sendQuery("com.portbase.bezoekschip.common.api.consignments.addressbook.FindParties", <IE3FindParties>{
      term: term,
      cargoDeclarantId: cargoDeclarantId,
      party: party
    }, {caching: false});

  static addressBookFormatter = (addressBook: IE3AddressBook): string => addressBook?.party
    ? this.partyFormatter(addressBook.party) : '';

  static partyFormatter = (party: IE3Party) => `${party.name}${party.address?.city ? ' (' + party.address.city + ')' : ''}`;

  static findCarriers = (term: string): Observable<Carrier[]> => PortvisitUtils.findContainerOperators(term);

  static carrierFormatter = (carrier: Carrier) => `${carrier?.name || ''}`;

  static customProcessNameFormatter = (process: IE3CustomsProcess): string => {
    switch (process) {
      case IE3CustomsProcess.SEA_IN_SEA_OUT:
        return "27 (Sea in sea out)"
      case IE3CustomsProcess.MILITARY:
        return "302 (Military)"
      case IE3CustomsProcess.EMPTY_RETURN_PACKAGING:
        return "P (Empty returning packaging)"
    }
  }

  static customsOfficeFormatter = (customsOffice: CustomsOffice) => customsOffice ? `${customsOffice.name} – ${customsOffice.unCode}` : "";

  static supplyChainTypeFormatter = (value: IE3CL704): string => {
    switch (value) {
      case IE3CL704.CS:
        return "Consolidator";
      case IE3CL704.FW:
        return "Freight forwarder";
      case IE3CL704.WH:
        return "Warehouse keeper";
      default:
        return "N/A";
    }
  }

  static getEquipmentNumbersOfHouseConsignment = (masterLevel: IE3ConsignmentMasterLevel, houseLevel: IE3ConsignmentHouseLevel): string[] => {
    return Object.keys(masterLevel.transportEquipmentMap).filter(containerIdentificationNumber =>
      lodash.flatMap(houseLevel.goodsItems, g => g.goodsPlacements.map(p => p.containerIdentificationNumber))
        .includes(containerIdentificationNumber));
  }

  static toConsignmentGoodsItemsSummaries = (goodsItems: IE3GoodsItem[]): IE3GoodsItemSummary[] =>
    goodsItems.map(g => ({
      goodsItemNumber: g.goodsItemNumber,
      commodity: g.commodity,
      netWeight: g.netWeight,
      grossWeight: g.grossWeight,
      placements: g.goodsPlacements.map(p => ({
        containerIdentificationNumber: p.containerIdentificationNumber,
        numberOfPackages: p.numberOfPackages,
        grossWeight: p.grossWeight
      }))
    }));

  static placeFormatter = (place: IE3Place) => place ? `${place.name} (${place.locationUnCode})` : '';

  static hasBeenDeclared = (consignmentProcess: IE3ConsignmentProcess): boolean => consignmentProcess.declared;

  static trackByGoodsItemAndEquipment = (index: number, item: EquipmentSummaryItem) => `${item.equipment.equipmentNumber}`;

  static trackByGoodsItemWithHouseConsignments = (index: number, item: GoodsItemWithHouseConsignments) => `${item.goodsItem.goodsItemNumber}-${item.houseConsignments.join(',')}`

  static goodsItemsWithContainerForSummary = (masterConsignment: IE3MasterConsignment, equipment: IE3EquipmentSummary[]): EquipmentSummaryItem[] =>
    lodash.map(equipment, e => ({
      equipment: e,
      goodsItems: this.getGoodsItemInContainer(e, masterConsignment.houseConsignments?.length
        ? lodash.flatMap(masterConsignment.houseConsignments, (h => h.goodsItems)) : masterConsignment.goodsItems),
      houseConsignments: masterConsignment.houseConsignments
        .filter(h => h.equipments.some(eq => eq.equipmentNumber === e.equipmentNumber))
        .map(h => h.consignmentNumber)
    }));

  static bulkGoodsItemsForSummary = (masterConsignment: IE3MasterConsignment): GoodsItemWithHouseConsignments[] => {
    return masterConsignment.goodsItems.map(g => (<GoodsItemWithHouseConsignments>{
      goodsItem: g,
      houseConsignments: []
    })).concat(lodash.flatMap(masterConsignment.houseConsignments, h => h.goodsItems.map(g => (<GoodsItemWithHouseConsignments>{
      goodsItem: g,
      houseConsignments: [h.consignmentNumber]
    })))).filter(g => !g.goodsItem.placements.length);
  }

  private static getGoodsItemInContainer = (c: IE3EquipmentSummary, goodsItems: IE3GoodsItemSummary[]): GoodsItemWithEquipment[] => {
    return goodsItems
      .filter(g => g.placements
        .find(p => p.containerIdentificationNumber === c.equipmentNumber))
      .map(g => (<GoodsItemWithEquipment>{
        goodsItem: g,
        placement: g.placements.find(p => p.containerIdentificationNumber === c.equipmentNumber)
      }));
  }

  static getGoodsItem = (consignmentProcess: IE3ConsignmentProcess, goodsItemNumber: number, houseConsignmentNumber?: string): IE3GoodsItem => {
    return (houseConsignmentNumber ? lodash.flatMap(consignmentProcess.consignmentMasterLevel.consignmentsHouseLevel,
        h => h.goodsItems)
      : consignmentProcess.consignmentMasterLevel.goodsItems).find(g => g.goodsItemNumber === goodsItemNumber);
  }

  static getGoodsOfEquipment = (consignmentProcess: IE3ConsignmentProcess, equipmentNumber: string, houseConsignmentNumber?: string): IE3GoodsItem[] => {
    return houseConsignmentNumber
      ? consignmentProcess.consignmentMasterLevel.consignmentsHouseLevel
        .find(h => h.consignmentNumber === houseConsignmentNumber)
        .goodsItems.filter(g => g.goodsPlacements.some(
          p => p.containerIdentificationNumber === equipmentNumber))
      : consignmentProcess.consignmentMasterLevel.goodsItems.filter(g => g.goodsPlacements
        .some(p => p.containerIdentificationNumber === equipmentNumber))
        .concat(lodash.flatMap(consignmentProcess.consignmentMasterLevel.consignmentsHouseLevel,
          h => h.goodsItems.filter(g => g.goodsPlacements
            .some(p => p.containerIdentificationNumber === equipmentNumber))));
  }

  static getContainerWithPlacement = (c: IE3TransportEquipment, goodsItem: IE3GoodsItem): EquipmentWithPlacement => {
    return {
      equipment: c,
      placement: goodsItem.goodsPlacements.find(g => g.containerIdentificationNumber === c.containerIdentificationNumber)
    }
  }

  static allGoodsItems = (c: IE3ConsignmentProcess): IE3GoodsItem[] => c.consignmentMasterLevel.goodsItems
    .concat(lodash.flatMap(c.consignmentMasterLevel.consignmentsHouseLevel, h => h.goodsItems));

  static getEquipmentIconCls = (sizeType: IE3SizeType, empty: boolean): string => {
    if (empty) {
      return "fa-kit fa-pb-container-empty";
    }
    switch (sizeType?.code as IE3CL710) {
      case IE3CL710.N1:
      case IE3CL710.N2:
      case IE3CL710.N6:
      case IE3CL710.N7:
      case IE3CL710.N9:
      case IE3CL710.N18:
      case IE3CL710.N19:
      case IE3CL710.N20:
      case IE3CL710.N27:
      case IE3CL710.N28:
      case IE3CL710.N29:
        return "fa-kit fa-pb-container-tank";
      case IE3CL710.N15:
      case IE3CL710.N24:
      case IE3CL710.N25:
      case IE3CL710.N26:
      case IE3CL710.N30:
      case IE3CL710.N31:
      case IE3CL710.N32:
      case IE3CL710.N39:
      case IE3CL710.N40:
      case IE3CL710.N41:
        return "fa-kit fa-pb-container-refrigerated";
      case IE3CL710.N43:
        return "fa-kit fa-pb-container-open-top";
      case IE3CL710.N12:
      case IE3CL710.N13:
      case IE3CL710.N14:
      case IE3CL710.N16:
      case IE3CL710.N17:
      case IE3CL710.N21:
      case IE3CL710.N22:
      case IE3CL710.N23:
      case IE3CL710.N33:
      case IE3CL710.N34:
      case IE3CL710.N35:
      case IE3CL710.N36:
      case IE3CL710.N37:
      case IE3CL710.N38:
      case IE3CL710.N42:
      case IE3CL710.N44:
      case IE3CL710.N45:
        return "fa-container-storage";
      default:
        return "fa-container-storage";
    }
  }

  static allowedToEdit = (): boolean => AppContext.isAdmin() || AppContext.isCargoImportEditor();

  static isEditable = (consignment: IE3ConsignmentProcess): boolean => this.allowedToEdit() && !consignment.fromVisit;

  static isSummaryAllowedToDeclare = (consignment: IE3ConsignmentProcessSummary): boolean => !consignment.fromVisit;

  static isAllowedToDeclare = (consignment: IE3ConsignmentProcess): boolean => !consignment.fromVisit;

  static isMasterConsignment = (consignment: IE3ConsignmentProcess): boolean => [IE3FilingType.F11, IE3FilingType.F12]
    .includes(consignment.filing.filingType);

  static isStraightConsignment = (consignment: IE3ConsignmentProcess): boolean => consignment.filing.straight ||
    [IE3FilingType.F10, IE3FilingType.F13].includes(consignment.filing.filingType);

  static isRoadConsignment = (consignment: IE3ConsignmentProcess): boolean => [IE3FilingType.F50]
    .includes(consignment.filing.filingType);

  static isMultipleFilingConsignment = (consignment: IE3ConsignmentProcess): boolean => [IE3FilingType.F12, IE3FilingType.F13]
    .includes(consignment.filing.filingType);

  static isSingleFilingConsignment = (consignment: IE3ConsignmentProcess): boolean => [IE3FilingType.F10, IE3FilingType.F11]
    .includes(consignment.filing.filingType);

  static isTemporaryStorageOnlyConsignment = (consignment: IE3ConsignmentProcess): boolean => [IE3FilingType.TSD, IE3FilingType.TSR]
    .includes(consignment.filing.filingType);

  static houseConsignmentSupported = (consignment: IE3ConsignmentProcess): boolean =>
    this.isStraightConsignment(consignment) || this.isSingleFilingConsignment(consignment) || consignment.filing.filingType === IE3FilingType.F50;

  static hasHouseLevelConsignments = (consignment: IE3ConsignmentProcess): boolean =>
    [IE3FilingType.F10, IE3FilingType.F11, IE3FilingType.F13].includes(consignment.filing.filingType);

  static openSubModal = (payload: ConsignmentSubModalEvent) => publishEvent("openConsignmentSubModal", payload);

  static closeSubModal = () => publishEvent("closeConsignmentSubModal");

  static createRoutesInfo = (consignmentProcess: IE3ConsignmentProcess, countries: Map<string, Country>, itinerary: string[], placeOfAcceptance: IE3Place, placeOfDelivery: IE3Place): ConsignmentRouteInfo[] => {
    const activeBorderTransportMeans = consignmentProcess.consignmentMasterLevel.activeBorderTransportMeans;
    const placeOfLoading = consignmentProcess.consignmentMasterLevel.placeOfLoading;
    const placeOfUnloading = consignmentProcess.consignmentMasterLevel.placeOfUnloading;
    const customsOfficeOfFirstEntry = consignmentProcess.filing.customsOfficeOfFirstEntry;
    const routes: ConsignmentRouteInfo[] = itinerary?.map(c => ({
      country: countries.get(c)
    })) || [];
    let loadingRoute: ConsignmentRouteInfo, acceptanceRoute: ConsignmentRouteInfo, discharge: ConsignmentRouteInfo,
      firstEntryOffice: ConsignmentRouteInfo;
    if (placeOfLoading) {
      loadingRoute = routes.find(v => v.country.code === placeOfLoading.countryUnCode);
      if (loadingRoute) {
        loadingRoute.placeOfLoading = placeOfLoading;
        loadingRoute.etd = activeBorderTransportMeans.actualDateAndTimeOfDeparture || activeBorderTransportMeans.estimatedDateAndTimeOfDeparture;
      }
    }
    if (placeOfAcceptance) {
      acceptanceRoute = routes.find(v => v.country.code === placeOfAcceptance.countryUnCode);
      if (acceptanceRoute) {
        acceptanceRoute.placeOfAcceptance = placeOfAcceptance;
      }
    }
    if (customsOfficeOfFirstEntry) {
      firstEntryOffice = lodash.find(routes, v => v.country.code === customsOfficeOfFirstEntry.unCode.substring(0, 2),
        loadingRoute ? routes.indexOf(loadingRoute) : acceptanceRoute ? routes.indexOf(acceptanceRoute) : 0);
      if (firstEntryOffice) {
        firstEntryOffice.customsOfficeOfFirstEntry = customsOfficeOfFirstEntry;
      }
    }
    if (placeOfUnloading) {
      discharge = lodash.findLast(routes, v => v.country.code === placeOfUnloading.countryUnCode);
      if (discharge) {
        discharge.placeOfUnloading = placeOfUnloading;
        discharge.eta = activeBorderTransportMeans.estimatedDateAndTimeOfArrival;
        discharge.dischargeTerminal = consignmentProcess.consignmentMasterLevel.dischargeTerminal;
        discharge.terminalLicense = consignmentProcess.consignmentMasterLevel.terminalLicense;
      }
    }
    if (placeOfDelivery) {
      const delivery = lodash.find(routes, v => v.country.code === placeOfDelivery.countryUnCode,
        discharge ? routes.indexOf(discharge) : firstEntryOffice ? routes.indexOf(firstEntryOffice) : 0);
      if (delivery) {
        delivery.placeOfDelivery = placeOfDelivery;
      }
    }
    return routes;
  }
}

export interface EquipmentSummaryItem {
  goodsItems: GoodsItemWithEquipment[];
  equipment: IE3EquipmentSummary;
  houseConsignments: string[];
}

export interface GoodsItemWithEquipment {
  goodsItem: IE3GoodsItemSummary;
  placement: IE3GoodsPlacementSummary;
}

export interface GoodsItemWithHouseConsignments {
  goodsItem: IE3GoodsItemSummary;
  houseConsignments?: string[];
}

export interface EquipmentWithPlacement {
  equipment: IE3TransportEquipment;
  placement?: IE3GoodsPlacement;
  goodsItems?: IE3GoodsItemSummary[];
  houseConsignments?: string[];
}

export interface ConsignmentSubModalEvent {
  modalContent: TemplateRef<any>;
}
