import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {MergeOrganisationRefdata, TransitParty} from "@portbase/bezoekschip-service-typescriptmodels";
import {PortvisitUtils} from "../../../refdata/portvisit-utils";
import {QueryGateway} from "../../../common/query-gateway";
import {lodash, sendCommand} from "../../../common/utils";

@Component({
  selector: 'app-transit-party',
  templateUrl: './transit-party.component.html',
  styleUrls: ['./transit-party.component.css']
})

export class TransitPartyComponent implements AfterViewInit, OnDestroy {
  utils = PortvisitUtils;
  @Input() model: TransitParty;
  listener = () => this.mergeModel();

  @Output() saved = new EventEmitter<TransitParty>();

  constructor(private queryGateway: QueryGateway, public elementRef: ElementRef) {
  }

  ngAfterViewInit(): void {
    window.addEventListener("submit", this.listener);
  }

  ngOnDestroy(): void {
    setTimeout(() => window.removeEventListener("submit", this.listener), 0);
  }

  private mergeModel() {
    const value = lodash.assign({}, this.model);
    sendCommand('com.portbase.bezoekschip.common.api.refdata.MergeTransitParty', <MergeOrganisationRefdata>{
      id: this.model.name,
      value: value,
      delete: false
    }, () => {
      this.saved.emit(value);
      this.queryGateway.removeFromCache("com.portbase.bezoekschip.common.api.cargo.FindMyTransitParties");
    });
  }
}
