import { Input } from '@angular/core';
import {Component} from '@angular/core';
import {Berth, BerthVisit, LocalMovement, Visit} from '@portbase/bezoekschip-service-typescriptmodels';
import * as html2pdf from 'html2pdf.js';
import moment from 'moment';
import {VisitContext} from '../visit-context';

@Component({
  selector: 'app-notification-of-departure',
  templateUrl: './notification-of-departure.component.html',
  styleUrls: ['./notification-of-departure.component.css']
})
export class NotificationOfDepartureComponent {
  context = VisitContext;

  get visit(): Visit {
    return this.context.visit;
  }

  downloadNotification() {
    let element = document.getElementById('notificationPDF');
    let opt = {
			image: {type: 'jpeg', quality: 0.98},
    };
    html2pdf().set(opt).from(element).save('notification_of_departure_' + this.visit.crn + '.pdf');
  }

  getCurrentDateTime() {
    return moment().format('DD-MM-YYYY HH:mm');
  }

  displayNotificationOfDeparture() {
    if (this.visit) {
      const berthVisits = this.visit.visitDeclaration.portVisit.berthVisits;
      return berthVisits.length >= 1 && !!berthVisits[berthVisits.length - 1].atd;
    }
  }

  getLastBerthVisit(): BerthVisit {
    if(this.visit){
      const berthVisits = <BerthVisit[]>this.visit.visitDeclaration.portVisit.berthVisits;
      if (berthVisits.length > 0) {
        return berthVisits[berthVisits.length - 1];
      }
      return undefined;
    }
  }

  getLastBerth(): Berth {
    const lastBerthVisit = this.getLastBerthVisit();
    return this.getLastBerthVisit() ? lastBerthVisit.berth : undefined;
  }

  getLastMovement(): LocalMovement {
    if (this.visit) {
      const lastBerthVisit = this.getLastBerthVisit();
      return lastBerthVisit? lastBerthVisit.nextMovement : this.visit.visitDeclaration.portVisit.firstMovement;
    }
    return undefined;
  }

  getNextPortsAsString() {
    if (this.visit) {
      let result = '';
      for (let port of this.visit.visitDeclaration.nextPorts) {
        if (port.port) {
          if (result === '') {
            result = port.port.name;
          } else {
            result = result + ', ' + port.port.name;
          }
        }
      }
      return result;
    }
  }

  getLastPreviousPortName() {
    if (this.visit && this.visit.visitDeclaration.previousPorts.length > 0 && this.visit.visitDeclaration.previousPorts[0].port) {
      return this.visit.visitDeclaration.previousPorts[0].port.name;
    }
    return '';
  }

  getformatedCargo() {
    if (this.visit) {
      let cargo = '';
      if (this.getLastBerthVisit() && this.getLastBerthVisit().nextMovement.cargo) {
        cargo = <string>this.getLastBerthVisit().nextMovement.cargo;
        cargo = cargo.replace('_', ' ');
        cargo = cargo.toLowerCase();
        cargo = cargo.charAt(0).toUpperCase() + cargo.slice(1);
      }
      return cargo;
    }
  }

}
