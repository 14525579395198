import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {
  DateTimeRange,
  DeclarationStatus,
  IE3CancelTranshipment,
  IE3SearchTranshipments,
  IE3TranshipmentSummary,
  InitiateTransitForForwarder,
  Item,
} from '@portbase/bezoekschip-service-typescriptmodels';
import {AppContext} from '../app-context';
import {
  lodash,
  openConfirmationModalWithCallback,
  removeItem,
  sendCommand,
  sendQuery,
  toWebsocketUrl
} from '../common/utils';
import {AbstractOverviewComponent} from '../common/abstract-overview.component';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {TranshipmentFilter} from './transhipment-filter/transhipment-filter.component';
import {PortvisitUtils} from '../refdata/portvisit-utils';
import {ActivatedRoute} from '@angular/router';
import {ModalConfirmAutofocus, ModalConfirmAutofocusData} from "../common/modal/modal-confirm.component";

@Component({
  selector: 'app-transhipment-overview',
  templateUrl: './transhipment-overview.component.html',
  styleUrls: ['./transhipment-overview.component.css']
})
export class TranshipmentOverviewComponent extends AbstractOverviewComponent<IE3TranshipmentSummary> implements OnInit, OnDestroy {
  appContext = AppContext;
  refData = PortvisitUtils;
  transhipments: IE3TranshipmentSummary[] = [];
  socket: WebSocket;
  transhipmentFilter: TranshipmentFilter = {deselectedOptions: [], selectedOptions: [], checkCondition: null};
  showFilter: boolean = true;
  transitDateFilterType: string = "TRANSPORT_TERM";
  preselectedItems: Item[] = [];

  localStorageKey(): string {
    return "transhipment-overview";
  }

  constructor(private zone: NgZone, route: ActivatedRoute) {
    super();
    route.queryParams.subscribe(queryParams => {
      const crn = queryParams['crn'];
      const consignmentNumber = queryParams['consignmentNumber'];
      const containerNumber = queryParams['containerNumber'];
      if (crn && consignmentNumber) {
        this.preselectedItems = [{
          crn: crn,
          consignmentNumber: consignmentNumber,
          containerNumber: containerNumber
        }];
      } else if (queryParams['items']) {
        const itemsJson = atob(queryParams['items']);
        const initiateTransit: InitiateTransitForForwarder = JSON.parse(itemsJson);
        this.preselectedItems = initiateTransit.items;
      }
      if (this.preselectedItems.length > 0) {
        setTimeout(() => $('#preselectedDeclaration').modal('show'), 0);
      }
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscribeToTranshipmentUpdates();
  }

  get visibleTranshipments(): IE3TranshipmentSummary[] {
    return this.transhipments.filter(s => !s['hidden']);
  }

  toggleFilter() {
    this.showFilter = !this.showFilter;
  }

  doLoad(dateTimeRange: DateTimeRange): Observable<(IE3TranshipmentSummary)[]> {
    this.transhipments = [];
    return sendQuery('com.portbase.bezoekschip.common.api.consignments.queries.SearchTranshipments',
      <IE3SearchTranshipments>{
        dateTimeRange: !!this.filterTerm ? null : dateTimeRange,
        term: this.filterTerm,
        dateFilterType: this.transitDateFilterType,
        untyped: true
      }, {caching: false, showSpinner: true})
      .pipe(map((d: IE3TranshipmentSummary[]) => {
        return this.zone.runOutsideAngular(() =>
          this.items = d.map(dd => this.transformSummary(dd))
        );
      }));

  }

  doRender = (transhipments: IE3TranshipmentSummary[]) => {
    this.zone.runOutsideAngular(() => {
      if (this.transhipmentFilter.selectedOptions.length !== 0) {
        transhipments = transhipments.filter(t => this.transhipmentFilter.selectedOptions
          .some(option => this.transhipmentFilter.checkCondition(option, t)
          ));
      }
      if (this.transhipmentFilter.deselectedOptions.length !== 0) {
        transhipments = transhipments.filter(t => !this.transhipmentFilter.deselectedOptions
          .some(option => this.transhipmentFilter.checkCondition(option, t)
          ));
      }
    });
    this.transhipments = transhipments;
  };

  addDeclaration = (declaration: IE3TranshipmentSummary) => {
    this.items.splice(0, 0, declaration);
    this.transhipments.splice(0, 0, declaration);
  };

  handleStatusUpdate = (declaration: IE3TranshipmentSummary) => {
    let existing = this.items.find(i =>
      i.crn === declaration.crn && i.consignmentNumber === declaration.consignmentNumber);
    if (!existing) {
      this.items.push(this.transformSummary(declaration));
    } else {
      lodash.assign(existing, this.transformSummary(declaration));
    }
  };

  applyTranshipmentFilters(transhipmentFilter: TranshipmentFilter) {
    this.transhipmentFilter = transhipmentFilter;
    this.renderFilteredItems();
  }

  private subscribeToTranshipmentUpdates = () => {
    if (environment.production || environment.docker) {
      try {
        this.socket = new WebSocket(toWebsocketUrl('/api/ui/transhipments'));
      } catch (e) {
        console.info('Could not open websocket. Retrying every minute...', e);
        setTimeout(this.subscribeToTranshipmentUpdates, 60_000);
        return;
      }
      this.socket.onmessage = (message: MessageEvent) => {
        if (typeof message.data === 'string') {
          this.handleStatusUpdate(JSON.parse(message.data));
        }
      };
      this.socket.onclose = (event: CloseEvent) => {
        if (!event.wasClean) {
          console.warn('Websocket closed with reason: ' + event.reason + ' (' + event.code + '). Trying to reconnect...');
        }
        setTimeout(() => this.subscribeToTranshipmentUpdates(), 5_000);
      };
    }
  };

  onDateTypeChanged(option): void {
    this.loadAndRender();
  }

  trackByCrnAndConsignmentNumber(index: number, obj: IE3TranshipmentSummary): any {
    return obj.crn + "-" + obj.consignmentNumber;
  }

  removeNewDeclaration(item: IE3TranshipmentSummary) {
    return () => {
      removeItem(this.items, item);
      this.renderFilteredItems();
    };
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.transhipments.forEach(c => {
      delete c['selected'];
    });
    try {
      this.socket.onclose = () => {
      };
      this.socket.close();
      this.socket = null;
    } catch (ignored) {
    }
  }

  // This mimics the code in transit.utils
  transformSummary(summary: IE3TranshipmentSummary): IE3TranshipmentSummary {
    if (summary.midStatus?.sent && !(summary.midStatus?.status == DeclarationStatus.REJECTED) &&
      ["APMRTM", "APMII", "BCW", "RWG", "UNIP"].some(t => t === summary.dischargeTerminal?.organisationShortName)) {
      //we don't get accepts from these terminals (they're still using the legacy MID 1.0)
      summary.midStatus.status = DeclarationStatus.ACCEPTED;
    }
    return summary;
  }

  /*
  Bulk actions
 */
  get selectedItems(): IE3TranshipmentSummary[] {
    return this.transhipments.filter(s => !!s['selected']);
  }

  toggleSelectAll = () => {
    if (this.selectedItems.length === this.transhipments.length) {
      this.transhipments.forEach(c => c['selected'] = false);
    } else {
      this.transhipments.forEach(c => c['selected'] = true);
    }
  };

  cancelSelected = () => {
    openConfirmationModalWithCallback((confirmed) => {
      if (confirmed) {
        this.doCancelSelected();
      }
    }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
      type: "danger",
      title: "Cancel transhipments",
      message: "You are about to cancel " + this.selectedItems.length + " transhipments",
      question: "Are you sure that you want to cancel these transhipments?",
      confirmText: "Yes",
      cancelText: "No"
    }, 'static');
  };

  private doCancelSelected() {
    this.selectedItems.forEach(item => {
      sendCommand('com.portbase.bezoekschip.common.api.consignments.commands.CancelTranshipment',
        <IE3CancelTranshipment>{
          cargoDeclarantId: item.cargoDeclarantIamConnectedId,
          consignmentNumber: item.consignmentNumber
        },
        () => AppContext.registerSuccess('The declaration for ' + item.consignmentNumber + 'was cancelled successfully.')
      );
    });
  }
}
