import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {VisitContext} from "../../visit-context";
import {
  BaseForPlanning,
  BerthVisit,
  DeclarationType,
  DependencyType,
  EntryDependency,
  PlanningVisit,
  PortEntryUnion,
  SeaEntry,
  UserPreferences
} from "@portbase/bezoekschip-service-typescriptmodels";
import {openConfirmationModalWithCallback, sendQuery} from "../../../common/utils";
import moment from "moment";
import {AppContext} from "../../../app-context";
import {ModalConfirmAutofocus, ModalConfirmAutofocusData} from 'src/app/common/modal/modal-confirm.component';
import {map} from "rxjs/operators";

@Component({
  selector: 'app-exchange-at-berth',
  templateUrl: './exchange-at-berth.component.html',
  styleUrls: ['./exchange-at-berth.component.css']
})
export class ExchangeAtBerthComponent implements OnInit, OnChanges {
  VISIT = DeclarationType.VISIT;

  appContext = AppContext;
  context = VisitContext;
  dependentVisit;

  dependencyType: DependencyType;

  @Input() portEntry: PortEntryUnion;
  @Input() firstBerthVisit: BerthVisit;
  @Input() entryDependency;

  userPreferences: UserPreferences;

  isAutomaticOrderApplicableForRotterdam: boolean = false;

  constructor() {
    this.portEntry = this.context.visit.visitDeclaration.portVisit.portEntry;
  }

  ngOnInit() {
    this.dependencyType = this.setDependencyType();
    if (this.hasFirstBerth()) {
      sendQuery('com.portbase.bezoekschip.common.api.visit.IsBerthInEuroPoort', {
        berthCode: this.firstBerthVisit.berth.code
      }).subscribe(result => {
        this.isAutomaticOrderApplicableForRotterdam = result;
        console.log('Automatic order applicable for Rotterdam: ' + this.isAutomaticOrderApplicableForRotterdam);
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.dependencyType = this.setDependencyType();
  }

  private setDependencyType() : DependencyType {
    return this.portEntry.origin === "SEA" && (<SeaEntry>this.portEntry).entryDependency != null ?
      (<SeaEntry>this.portEntry).entryDependency.dependencyType : null;
  }

  entryAfterFormatter = (planningVisit: PlanningVisit) => planningVisit.vesselName
    + ' - ' + this.formatBerth(planningVisit.berthCode, planningVisit.berthName)
    + ' (ETA: ' + this.formatDate(planningVisit.eta) + ')';

  exchangeFormatter = (planningVisit: PlanningVisit) => planningVisit.vesselName
    + ' - ' + this.formatBerth(planningVisit.berthCode, planningVisit.berthName)
    + ' (ETD: ' + this.formatDate(planningVisit.etd) + ')';

  styleExchange = (planningVisit: PlanningVisit) => planningVisit.crnExchange ? {
    'cursor': 'not-allowed',
    'color': 'lightgray',
    'font-style': 'italic'
  } : {};
  styleEntryAfter = (planningVisit: PlanningVisit) => planningVisit.crnEntryAfter ? {
    'cursor': 'not-allowed',
    'color': 'lightgray',
    'font-style': 'italic'
  } : {};


  formatBerth = (berthCode: string, berthName: string) => {
    return '' + (berthName ? berthName + ' - ' : '') + berthCode;
  }

  refreshVisitsForExchange = (term) => {
    return sendQuery('com.portbase.bezoekschip.common.api.visit.GetPlanningVisitsAtBerthGroup',
    {
      berthGroupCode: this.firstBerthVisit.berth.berthGroupCode,
      berthCode: this.firstBerthVisit.berth.code
    },
      {caching: false})
      .pipe(map(values => {
        let planningVisits = this.filterAndSortForExchange(values);
        return planningVisits.filter(planningVisit => (planningVisit.vesselName + planningVisit.vesselImoCode).toUpperCase().indexOf(term.toUpperCase()) !== -1);
      }));
  };

  private filterAndSortForExchange(arrivedVisits: PlanningVisit[]) {
    let etaPbp = this.context.visit.visitDeclaration.portVisit.portEntry.intention === 'WAITING_FOR_ORDERS' ?
			moment.now() :
			(this.context.visit.visitDeclaration.portVisit.portEntry.etaPilotBoardingPlace || this.context.visit.visitDeclaration.portVisit.etaPort);
    let maximumEtaPbp = moment(etaPbp).add(48, "hour");
    //if vessel has not arrived then the ETA PBP may not be more than 48 hours in the future
    return arrivedVisits
      .filter(planningVisit => (planningVisit.vesselImoCode !== this.context.visit.vessel.imoCode) && (planningVisit.etaPilotBoardingPlace
        && moment(planningVisit.etaPilotBoardingPlace).isBefore(maximumEtaPbp) || planningVisit.ata))
      .sort((a, b) => !a.etd ? 1 : (moment(a.etd)).isAfter(b.etd) ? 1 : -1);
  }

  refreshVisitsForEntryAfter = (term) => {
    return sendQuery('com.portbase.bezoekschip.common.api.visit.GetPlanningVisitsAtBerthGroup',
    {
      berthGroupCode: this.firstBerthVisit.berth.berthGroupCode,
      berthCode: this.firstBerthVisit.berth.code
    },
      {caching: false})
      .pipe(map(values => {
        let planningVisits = this.filterAndSortForArriveAfter(values);
        return planningVisits.filter(planningVisit => (planningVisit.vesselName + planningVisit.vesselImoCode).toUpperCase().indexOf(term.toUpperCase()) !== -1);
      }));
  };

  private filterAndSortForArriveAfter(planningVisits: PlanningVisit[]) {
    let etaPbp = this.context.visit.visitDeclaration.portVisit.portEntry.etaPilotBoardingPlace || this.context.visit.visitDeclaration.portVisit.etaPort;
    let maximumEtaPbp = moment(etaPbp).add(48, "hour");
    return planningVisits
      .filter(planningVisit => (planningVisit.vesselImoCode !== this.context.visit.vessel.imoCode) && !planningVisit.ata && planningVisit.etaPilotBoardingPlace
        && moment(planningVisit.etaPilotBoardingPlace).isBefore(maximumEtaPbp))
      .sort((a, b) => !a.eta ? 1 : (moment(a.eta)).isAfter(b.eta) ? 1 : -1);
  }

  dependencyOptions: DependencyType[] = [null, DependencyType.ENTRY_AFTER, DependencyType.EXCHANGE];
  dependencyFormatter = (arrivalDependency: DependencyType) => {
    switch (arrivalDependency) {
      case null:
        return 'None';
      case "EXCHANGE":
        return 'Exchange with other vessel';
      case "ENTRY_AFTER":
        return 'Entry after other vessel';
    }
  };

  setEntryDependency($event: PlanningVisit) {

    if (!$event) return;
    let entryDependency = (<SeaEntry>this.portEntry).entryDependency;

    if (entryDependency.dependencyType === 'EXCHANGE' && $event.crnExchange != null) {
      AppContext.addAlert({
        content: "The vessel you have selected to exchange with has already been selected by another vessel. Please contact the terminal.",
        level: "warning",
        type: "visit"
      });
      this.dependentVisit = null;
      (<SeaEntry>this.portEntry).entryDependency = null;
      return;
    }
    if (entryDependency.dependencyType === 'ENTRY_AFTER' && $event.crnEntryAfter != null) {
      AppContext.addAlert({
        content: "The vessel you have selected to enter after has already been selected by another vessel.",
        level: "warning",
        type: "visit"
      });
      this.dependentVisit = null;
      (<SeaEntry>this.portEntry).entryDependency = null;
      return;
    }

    entryDependency.vesselName = $event.vesselName;
    entryDependency.vesselImoCode = $event.vesselImoCode;
    entryDependency.berthVisitId = $event.berthVisitId;
    entryDependency.movementOnHold = $event.movementOnHold;
    entryDependency.crn = $event.crn;
    entryDependency.berthName = $event.berthName ? $event.berthName : $event.berthCode;
    entryDependency.berthCode = $event.berthCode;
    if (entryDependency.dependencyType === 'ENTRY_AFTER') {
      entryDependency.actualTimeBerth = $event.ata;
      entryDependency.estimatedTimeBerth = $event.eta;
      entryDependency.orderStatus = $event.orderStatusArrival;
    } else if (entryDependency.dependencyType === 'EXCHANGE') {
      entryDependency.estimatedTimeBerth = $event.etd;
      entryDependency.actualTimeBerth = null;
      entryDependency.orderStatus = $event.orderStatusDeparture;
    }
    (<SeaEntry>this.portEntry).entryDependency = entryDependency;
  }

  resolveIntention() {
    if (this.portEntry.intention === 'WAITING_FOR_ORDERS') {
      this.portEntry.etaPilotBoardingPlace = null;
      this.portEntry.requestedEtaPilotBoardingPlace = null;
      this.portEntry.baseForPlanning = null;
      this.portEntry.earliestTimeOfPortEntry = null;

      if (this.hasFirstBerth()) {
        this.firstBerthVisit.eta = null;
        this.firstBerthVisit.requestedEta = null;
      }
    } else if (!this.hasFirstBerth()) {
      this.portEntry.baseForPlanning = BaseForPlanning.PILOT_BOARDING_PLACE;
    }
  }

  resolveDependency($event: any) {
    if ($event == 'EXCHANGE' || $event == 'ENTRY_AFTER') {
      (<SeaEntry>VisitContext.visit.visitDeclaration.portVisit.portEntry).entryDependency = <EntryDependency>{
        berthVisitId: this.firstBerthVisit.id,
        actualTimeBerth: null,
        orderStatus: null,
        estimatedTimeBerth: null,
        crn: null,
        dependencyType: ($event == 'EXCHANGE' ? "EXCHANGE" : "ENTRY_AFTER"),
        movementOnHold: false,
        autoOrder: false,
        vesselImoCode: null,
        vesselName: null,
        phoneNumber: null,
        email: AppContext.userProfile.organisation.emailAddress
      };
    } else {
      (<SeaEntry>VisitContext.visit.visitDeclaration.portVisit.portEntry).entryDependency = null;
    }
  }

  hasFirstBerth() {
    return this.firstBerthVisit && this.firstBerthVisit.berth != null;
  }

  formatDate(date: string) {
    return date ? moment().isSame(date, 'day')
      ? 'Today, ' + moment(date).format('HH:mm') : moment(date).format('ddd D MMM, HH:mm') : "unknown";
  }

  get showAutomaticOrder(): boolean {
    if (this.context.visit.crn.startsWith("NLAMS")) {
      return true;
    }
    if (this.context.visit.vessel.fullLength >= 200) {
      return true;
    }
    return this.isAutomaticOrderApplicableForRotterdam;
  }

  orderIncomingMovementIfRequired(autoOrderEnabled: boolean) {
    let orderStatusDependentVessel = (<SeaEntry>this.portEntry).entryDependency.orderStatus;
    if (autoOrderEnabled) {
      let dependentVesselIsOrdered = orderStatusDependentVessel === "CONFIRMED" || orderStatusDependentVessel === "REEVALUATED" || orderStatusDependentVessel === "ORDERED"
        || orderStatusDependentVessel === "ETD_CHANGED" || orderStatusDependentVessel === "ETA_CHANGED";
      if (dependentVesselIsOrdered) {
        this.context.visit.visitDeclaration.portVisit.firstMovement.order = true;
        this.context.shiftEtas();
      } else if (this.context.visit.visitDeclaration.portVisit.firstMovement.order) {
        openConfirmationModalWithCallback((confirmed) => {
          if (confirmed) {
            this.context.visit.visitDeclaration.portVisit.firstMovement.order = false;
          }
        }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
          title: "Cancel order",
          message: "The dependent vessel's movement has not been ordered yet. Your order of the incoming movement will be cancelled. Are you sure?",
        }, 'static');
      }
    }
  }

  formatTime = (entryDependency: EntryDependency) => {
    const formatDate = (date: string) => date ? moment().isSame(date, 'day')
      ? 'Today, ' + moment(date).format('HH:mm') : moment(date).format('ddd D MMM, HH:mm') : "unknown";
    const dependencyTypeIsExchange = entryDependency.dependencyType === 'EXCHANGE';

    if (!entryDependency.actualTimeBerth) {
      return (dependencyTypeIsExchange ? 'ETD: ' : 'ETA: ') + formatDate(entryDependency.estimatedTimeBerth);
    } else {
      return (dependencyTypeIsExchange ? 'Departed: ' : 'Arrived: ') + formatDate(entryDependency.actualTimeBerth);
    }
  };

  removeDependencyOnVessel() {
    this.dependencyType = null;
    this.dependentVisit = null;
    (<SeaEntry>this.portEntry).entryDependency = null;
  }
}
