import {AfterViewInit, Component} from '@angular/core';
import {VisitContext} from '../../visit-context';
import {ShipToShipActivity, ShipToShipActivityType} from '@portbase/bezoekschip-service-typescriptmodels';
import {DragulaService} from 'ng2-dragula';
import moment from 'moment';

@Component({
  selector: 'app-ship-to-ship-activities',
  templateUrl: './ship-to-ship-activities.component.html',
  styleUrls: ['./ship-to-ship-activities.component.css']
})
export class ShipToShipActivitiesComponent implements AfterViewInit {
  context = VisitContext;
  addActivity = () => this.context.visit.securityDeclaration.shipToShipActivities.push(<ShipToShipActivity>{});
  deleteActivity = (activityIdx: number) => this.context.visit.securityDeclaration.shipToShipActivities.splice(activityIdx, 1);
  types: ShipToShipActivityType[] = [ShipToShipActivityType.MISCELLANEOUS, ShipToShipActivityType.CREW_MOVEMENT,
    ShipToShipActivityType.QUARANTINE_INSPECTION, ShipToShipActivityType.UNLOADING_CARGO,
    ShipToShipActivityType.LOADING_CARGO, ShipToShipActivityType.PASSENGER_MOVEMENT,
    ShipToShipActivityType.CARGO_TANK_CLEANING, ShipToShipActivityType.DE_GASSING,
    ShipToShipActivityType.WASTE_DISPOSAL, ShipToShipActivityType.TAKING_BUNKERS, ShipToShipActivityType.CHANGING_CREW,
    ShipToShipActivityType.TAKING_SUPPLIES, ShipToShipActivityType.REPAIR];
  typeFormatter = (type: ShipToShipActivityType) => {
    return type ? formatType(type): null;
  };

  constructor(private dragulaService: DragulaService) {
  }

  ngAfterViewInit(): void {
    this.dragulaService.find('shipToShipActivities').options.invalid = (el, handle) => {
      return !handle.classList.contains('drag-handle');
    };
    this.dragulaService.find('shipToShipActivities').options.moves = () => {
      return !this.context.isVisitReadonly();
    };
    this.dragulaService.dropModel('shipToShipActivities').subscribe(value => {
      const activity: ShipToShipActivity = value.item;
      activity.startDate = null;
      activity.endDate = null;
    });
  }

  private activityNotChronological(activity: ShipToShipActivity){
    return activity.startDate && activity.endDate && moment(activity.startDate).isAfter(moment(activity.endDate));
  }

  private nextActivityNotChronological(index: number){
    if(index===0){
      return false;
    }
    const activity = this.context.visit.securityDeclaration.shipToShipActivities[index];
    const date = activity.endDate ? activity.endDate : activity.startDate;
    const nextActivity = this.context.visit.securityDeclaration.shipToShipActivities[index-1]
    const nextDate = nextActivity.startDate ? nextActivity.startDate : nextActivity.endDate;
    return date && nextDate && moment(date).isAfter(moment(nextDate))
  }
}

function formatType(type) {
  switch (type) {
    case 'MISCELLANEOUS': return "Miscellaneous";
    case 'CREW_MOVEMENT': return "Crew movement";
    case 'QUARANTINE_INSPECTION': return "Quarantine inspection";
    case 'UNLOADING_CARGO': return "Unloading cargo";
    case 'LOADING_CARGO': return "Loading cargo";
    case 'PASSENGER_MOVEMENT': return "Passenger movement";
    case 'CARGO_TANK_CLEANING': return "Cargo tank cleaning";
    case 'DE_GASSING': return "De-gassing";
    case 'WASTE_DISPOSAL': return "Waste disposal";
    case 'TAKING_BUNKERS': return "Taking bunkers";
    case 'CHANGING_CREW': return "Changing crew";
    case 'TAKING_SUPPLIES': return "Taking supplies";
    case 'REPAIR': return "Repair";
    default: throw Error('Unknown ship to ship activity: ' + type);
  }
}
