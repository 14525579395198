import {Injectable} from "@angular/core";
import {QueryHandler} from "../common/query-handler";
import {GetCommercialReleaseStats, GetReleaseToPartyStats} from "@portbase/bezoekschip-service-typescriptmodels";
import {Observable, of} from "rxjs";

declare var require: any;

const commercialReleasesPerOrganisation: QueryResult = require('./commercial-release-dashboard/commercial-releases-per-organisation.json');
const releaseToPartiesPerOrganisation: QueryResult = require('./commercial-release-dashboard/release-to-parties-per-organisation.json');

@Injectable()
export class CommercialReleaseDashboardQueryHandlerMock extends QueryHandler {
  'com.portbase.bezoekschip.common.api.commercialrelease.stats.GetCommercialReleaseStats' = (query: GetCommercialReleaseStats): Observable<QueryResult> => {
    return of(commercialReleasesPerOrganisation);
  }

  'com.portbase.bezoekschip.common.api.commercialrelease.stats.GetReleaseToPartyStats' = (query: GetReleaseToPartyStats): Observable<QueryResult> => {
    return of(releaseToPartiesPerOrganisation);
  }
}

interface QueryResult {
  [organisation: string]: number;
}
