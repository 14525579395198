import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {
  BerthVisit,
  Bollards,
  RegisterTerminalStatus,
  TerminalInfo,
  TerminalMooring,
  TerminalVisit
} from "@portbase/bezoekschip-service-typescriptmodels";
import {VisitContext} from "../../../../../visit-context";
import {AppContext} from "../../../../../../app-context";
import {berthInputFormatter, checkValidity, sendCommand} from "../../../../../../common/utils";

@Component({
  selector: 'app-register-terminal-status',
  templateUrl: './register-terminal-status.component.html',
  styleUrls: ['./register-terminal-status.component.css']
})
export class RegisterTerminalStatusComponent implements OnInit {
  context = VisitContext;
  appContext = AppContext;

  @Input() berthVisit: BerthVisit;
  data: RegisterTerminalStatus;

  rta: string;
  etc: string;
  mooring: TerminalMooring;
  bollardsInfo: Bollards;

  constructor(private element: ElementRef) {
  }

  ngOnInit(): void {
    const terminalVisit: TerminalVisit = this.context.getTerminalVisit(this.berthVisit);
    this.data = <RegisterTerminalStatus>{
      berthVisitId: this.berthVisit.id,
      terminal: this.berthVisit.terminal,
      info: terminalVisit?.info ?? <TerminalInfo>{}
    }
    this.rta = this.data.info.rta ?? this.berthVisit.eta;
    this.etc = this.data.info.etc ?? this.berthVisit.etd;
  }

  moorings: TerminalMooring[] = [TerminalMooring.PORTSIDE, TerminalMooring.STARBOARD];
  mooringFormatter = (value: TerminalMooring) => {
    switch (value) {
      case TerminalMooring.PORTSIDE:
        return 'Port side';
      case TerminalMooring.STARBOARD:
        return 'Starboard side';
      default:
        throw Error('Unknown mooring value: ' + value);
    }
  };

  sendMockStatus() {
    if (checkValidity(this.element)) {
      this.data.info.rta = this.rta;
      this.data.info.etc = this.etc;
      sendCommand('com.portbase.bezoekschip.common.api.visit.terminal.MockRegisterTerminalStatus',
        <RegisterTerminalStatus>{
          crn: this.context.visit.crn,
          terminalOrganisationShortName: this.berthVisit.berth.organisationShortName,
          visitId: "TERMID-" + this.context.visit.crn + "-" + this.berthVisit.berth.organisationShortName,  // pretend some terminal visit ID
          info: this.data.info
        }, () => {
          VisitContext.replaceVisit(VisitContext.visit);
          AppContext.registerSuccess('The terminal status was successfully registered!');
          $('#' + this.berthVisit.id + '-acceptStatusModal').modal("hide");
        });
    }
  }

  updateBollardsAft($event: any) {
    this.data.info.bollardAft = this.roundBollard($event.target.value);
  }

  updateBollardsFore($event: any) {
    this.data.info.bollardFore = this.roundBollard($event.target.value);
  }

  roundBollard = (value: number): number => {
    if (value) {
      value = Math.round(2 * value) / 2;
    }
    return value;
  }

  modalOpen(): boolean {
    return $('#' + this.berthVisit.id + '-acceptStatusModal').is(":visible");
  }

  protected readonly berthInputFormatter = berthInputFormatter;
}
