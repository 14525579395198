import {Component, Input} from '@angular/core';
import {VisitContext} from "../../visit-context";
import {AppContext} from "../../../app-context";
import {BerthVisit} from "@portbase/bezoekschip-service-typescriptmodels";
import {cloneObject, sendQuery} from "../../../common/utils";
import {OrderMovementService} from './order-movement.service';
import {PortvisitUtils} from "../../../refdata/portvisit-utils";


@Component({
	selector: 'app-nautical-services',
	templateUrl: './nautical-services.component.html',
	styleUrls: ['./nautical-services.component.css']
})
export class NauticalServicesComponent {
	areNauticalServicesApplicable = PortvisitUtils.areNauticalServicesApplicable;
	context = VisitContext;
	appContext = AppContext;

	@Input() berthVisit: BerthVisit;
	@Input() nextBerthVisit: BerthVisit;
	@Input() disabled: boolean;
	@Input() firstBerth: boolean;
	@Input() lastBerth: boolean;
	@Input() isLastBerthVisitDeparture: boolean;
	@Input() isNextBerthVisits: boolean;
	showNauticalServicesAtArrival = false;

	pilotProvider = sendQuery('com.portbase.bezoekschip.common.api.visit.GetPilots', {portUnCode: this.context.visit.portOfCall.port.locationUnCode});
	boatmenProvider = sendQuery('com.portbase.bezoekschip.common.api.visit.GetBoatmen', {portUnCode: this.context.visit.portOfCall.port.locationUnCode});

	tugboatProvider = sendQuery('com.portbase.bezoekschip.common.api.visit.GetTugboats', {portUnCode: this.context.visit.portOfCall.port.locationUnCode});

	constructor(private orderMovementService: OrderMovementService) {
	}

	orderMovement = () => {
		this.orderMovementService.orderMovement(this.berthVisit);
    this.berthVisit.nextMovement.orderCancellationReason = null;
  };
	cancelMovement = () => {
		this.orderMovementService.cancelMovement(this.berthVisit);
	};

	copyNauticalServices() {
		this.nextBerthVisit.tugboatAtDeparture = cloneObject(this.berthVisit.tugboatAtDeparture);
		this.nextBerthVisit.boatmenAtDeparture = cloneObject(this.berthVisit.boatmenAtDeparture);
		this.nextBerthVisit.nextMovement.pilotService = cloneObject(this.berthVisit.nextMovement.pilotService);
		// als niet naar de laatste ligplaats wordt gekopieerd moet dan de arrival erna die krijgen van de nextBerthVisit


		let berthVisits = this.context.visit.visitDeclaration.portVisit.berthVisits;
		for (let [index, berthVisit] of berthVisits.entries()) {
				if (berthVisit == this.nextBerthVisit && berthVisits.length > index + 1) {
					berthVisits[index + 1].tugboatAtArrival = cloneObject(this.nextBerthVisit.tugboatAtArrival);
					berthVisits[index + 1].boatmenAtArrival = cloneObject(this.nextBerthVisit.boatmenAtArrival);
				}
			}

		// TODO works different now for next declarant

		// if (this.firstBerth && this.isNextBerthVisits) {
		// 	currentMovement = this.context.visit.nextVisitDeclaration.lastBerthVisitDeparture.nextMovement;
		// }
	}

	copyMovement() {
		let pilotService = this.berthVisit.nextMovement.pilotService;
		let clone = cloneObject(this.berthVisit.nextMovement);
		// TODO works different now for next declarant
		// if (this.firstBerth && this.isNextBerthVisits) {
		// 	pilotService = this.context.visit.nextVisitDeclaration.lastBerthVisitDeparture.nextMovement.pilotService;
		// 	clone = cloneObject(this.context.visit.nextVisitDeclaration.lastBerthVisitDeparture.nextMovement);
		// }
		if (clone) {
			clone.pilotService = pilotService ? cloneObject(pilotService) : null;
			clone.order = this.berthVisit.nextMovement.order;
			this.nextBerthVisit.nextMovement = clone;
		}
	};

	toggleNauticalServicesAtArrival(){
		this.showNauticalServicesAtArrival = ! this.showNauticalServicesAtArrival;
		if(this.showNauticalServicesAtArrival) {
			this.nextBerthVisit.tugboatAtArrival = null;
			this.nextBerthVisit.boatmenAtArrival = null;
		}
		else {
			this.nextBerthVisit.tugboatAtArrival = cloneObject(this.berthVisit.tugboatAtDeparture);
			this.nextBerthVisit.boatmenAtArrival = cloneObject(this.berthVisit.boatmenAtDeparture);
		}

	}

	get differentNauticalServices() {
		let differentTugs = JSON.stringify(this.berthVisit.tugboatAtDeparture) !== JSON.stringify(this.nextBerthVisit.tugboatAtArrival);
		let differentBoatmen = JSON.stringify(this.berthVisit.boatmenAtDeparture) !== JSON.stringify(this.nextBerthVisit.boatmenAtArrival);
		return differentTugs || differentBoatmen;
	}

	changeTugsAtArrival($event) {
		if(this.nextBerthVisit && !this.showNauticalServicesAtArrival) {
			this.nextBerthVisit.tugboatAtArrival = cloneObject($event);
		}
	}

	changeBoatmenAtArrival($event: any) {
		if(this.nextBerthVisit && !this.showNauticalServicesAtArrival) {
			this.nextBerthVisit.boatmenAtArrival = cloneObject($event);
		}
	}
}
