import {Injectable} from "@angular/core";
import {QueryHandler} from "../common/query-handler";
import {AppContext} from "../app-context";
import vaartijden from "../mocking/vaartijden.json";
import {sendQuery} from "../common/utils";
import {GetTravelTimeToFirstBerth, Mooring} from "@portbase/bezoekschip-service-typescriptmodels";
import {of} from "rxjs";

import berthMappings from '@portbase/bezoekschip-service-typescriptmodels/refdata/berthMappings.json';


@Injectable()
export class TravelTimesQueryHandler extends QueryHandler {

	euroPoortBerths: string [];
	berthTravelTimes: { [index: string]: Criterium[] };

	constructor() {
		super();
		this.loadBerthTravelTimes();
	}

	private loadBerthTravelTimes() {
		this.euroPoortBerths = [];
		this.berthTravelTimes = {};

		if (AppContext.environment === 'localhost') {
			this.sortTravelTimes(vaartijden);
		} else {
			sendQuery('com.portbase.bezoekschip.common.api.visit.GetSailingTimesTables', {}).subscribe(
				v => {
					this.sortTravelTimes(v);
				}
			);
		}
	}

	private sortTravelTimes(v) {
		v['NLRTM'].forEach(v => {
			if (v.harbourArea && v.harbourArea === "Europoort") {
				this.euroPoortBerths.push(...v.berths);
			}
			this.sortTravelTimesPerBerth(v);
		});
		v['NLAMS'].forEach(v => {
			this.sortTravelTimesPerBerth(v);
		})
	}

	private sortTravelTimesPerBerth(v) {
		if (v.durations.length == 1) {
			v.berths.forEach(b => {
				this.berthTravelTimes[b] = [new Criterium(null, null, null, v.durations[0].minutesFrom)];
			});
		} else {
			v.berths.forEach(b => {
				var criteriums: Criterium [] = [];
				v.durations.forEach((d: Duration) => {
					if (d.criteria) {
						criteriums.push(new Criterium(d.criteria.lengthInCm, d.criteria.draughtInCm, d.criteria.mooringSide, d.minutesFrom));
					}
				});
				this.berthTravelTimes[b] = criteriums;
			});
		}
	}

	'com.portbase.bezoekschip.common.api.visit.GetTravelTimeToFirstBerth' = (query: GetTravelTimeToFirstBerth) => {
		let berthCode = berthMappings[query.berthCode] ? berthMappings[query.berthCode] : query.berthCode;
		let criteria: Criterium[] = this.berthTravelTimes[berthCode];
		let pilotBoardingPlaceCode = query.pilotBoardingPlaceCode.toLowerCase();
		if (!criteria) {
			AppContext.registerError("No travel time can be calculated to first berth: berth code does not exist in travel time table", 'warning');
			return of(null);
		} else if (criteria.length == 1) {
			return of(criteria[0].durations[pilotBoardingPlaceCode]);
		} else {
			for (let i = 0; i < criteria.length; i++) {
				if (criteria[i].matchesQuery(query.vesselLength, query.vesselDraught, this.mapMooring(query.mooring))) {
					return of(criteria[i].durations[pilotBoardingPlaceCode]);
				}
			}
		}
		return of(null);
	};

	'com.portbase.bezoekschip.common.api.visit.IsBerthInEuroPoort' = (query) => {
		return of(this.euroPoortBerths.indexOf(this.getBerthMapping(query)) >= 0);
	};


	private getBerthMapping(query) {
		let berthMapping = berthMappings[query.berthCode];
		return berthMapping !== undefined ? berthMapping : query.berthCode;
	}



	private mapMooring(mooring: Mooring) {
		switch (mooring) {
			case "PORT_SIDE":
				return "BB";
			case "STARBOARD_SIDE":
				return "SB";
			case "NO_PREFERENCE":
				return "NoPreference";
			case "ALONGSIDE_OTHER_SHIP":
				return "Alongside";
		}
		return null;
	}

}

class Criterium {
	constructor(lengthExpression: string, draughtExpression: string, mooringSide: string, durations: { [index: string]: number }) {
		this.lengthExpression = lengthExpression;
		this.draughtExpression = draughtExpression;
		this.mooringSide = mooringSide;
		this.durations = durations;
	}

	lengthExpression: string;
	draughtExpression: string;
	mooringSide: string;
	durations: { [index: string]: number };

	matchesQuery(length: number, draught: number, mooringSide: string): boolean {
		let matchesLengthCriterium = this.lengthExpression ? this.matchesCriterium(this.lengthExpression, length) : true;
		let matchesDraughtCriterium = this.draughtExpression ? this.matchesCriterium(this.draughtExpression, draught) : true;
		let matchesMooringSide = this.mooringSide ? this.mooringSide === mooringSide : true;
		return matchesLengthCriterium && matchesDraughtCriterium && matchesMooringSide;
	}

	private matchesCriterium(expression: string, value: number) {
		let operation: string = expression.replace(new RegExp("\\d+"), "");
		let threshold = parseInt(expression.replace(new RegExp("\\D+"), "")) / 100;
		switch (operation) {
			case "<":
				return value < threshold;
			case ">":
				return value > threshold;
			case ">=":
				return value >= threshold;
			case "<=":
				return value <= threshold;
		}
		return true;
	}
}

class Duration {
	criteria: any;
	minutesFrom: any;
}