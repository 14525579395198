import {Pipe, PipeTransform} from '@angular/core';
import {lodash} from './utils';

@Pipe({
  name: 'round'
})
export class RoundPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (!lodash.isNumber(value)) {
      return "";
    }
    return lodash.round(value, 0);
  }
}
