<div *ngIf="true" class="fieldset" [ngClass]="{'disabled' : isDisabled()}">
  <!-- STICKY ROW -->
  <div class="row sticky-top bg-white" style="top: 5.5rem">

    <!-- info/warning/alert bars -->
    <ng-container *ngIf="sendWarnings.length === 0">
      <div class="col-md-12">
        <app-alerting></app-alerting>
      </div>

      <div class="col-md-12">
        <div class="row">
          <!--Reject reasons-->
          <div class="col-md-12"
               *ngFor="let reason of latestVisitDeclarationsRejectReasons(); let index = index; trackBy: trackByString">
            <app-status-alert type="danger">{{reason}}</app-status-alert>
          </div>

          <!--Visit warnings-->
          <div class="col-md-12" *ngFor="let berthVisitInfo of context.visit.berthVisitInfos | keyvalue">
            <div *ngIf="getBerthCode(berthVisitInfo.key)!=null && !hasAtd(berthVisitInfo.key)">
              <div *ngIf="berthVisitInfo.value.harbourMasterInfo?.rejectionReason">
                <app-status-alert type="danger">
                  Your order of movement from berth {{getBerthCode(berthVisitInfo.key)}} has been rejected:
                  <i>{{berthVisitInfo.value.harbourMasterInfo.rejectionReason}}.</i>
                </app-status-alert>
              </div>
              <div *ngIf="berthVisitInfo.value.harbourMasterInfo?.movementOnHold">
                <app-status-alert type="warning">
                  Your departure from berth {{getBerthCode(berthVisitInfo.key)}} has been put on hold by the port
                  authorities and may proceed as conditions allow.
                </app-status-alert>
              </div>
              <div *ngIf="berthVisitInfo.value.harbourMasterInfo?.remarks &&
              (berthVisitInfo.value.harbourMasterInfo.etd || berthVisitInfo.value.harbourMasterInfo.rejectionReason)">
                <app-status-alert type="info">
                  Harbour master remarks on your movement order from berth {{getBerthCode(berthVisitInfo.key)}}:
                  <i>{{berthVisitInfo.value.harbourMasterInfo.remarks.text}}</i>
                </app-status-alert>
              </div>
            </div>
          </div>

          <div class="col-md-12" *ngFor="let berthVisit of getShiftedOrders()">
            <div *ngIf="!hasAtd(berthVisit.id)">
              <app-status-alert type="warning">Your order of movement from berth {{berthVisit.berth.code}} has been
                confirmed but the departure time was changed.
              </app-status-alert>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <!--ETA pilot station estimate message-->
    <app-eta-port-ais class="col-md-12"></app-eta-port-ais>

    <!-- DESKTOP / TABLET -->
    <div class="col-md-12 d-none d-sm-block">
      <app-action-nav-bar>
        <ng-container>
          <div class="d-flex flex-row-reverse">
            <!-- Admin features -->
            <div class="d-none d-lg-block ms-2">
              <app-visit-admin></app-visit-admin>
            </div>
            <!-- Save and send (plus other actions) -->
            <div *ngIf="!appContext.isPortAuthority()"
                 [ngClass]="{'always-enabled' : appContext.hasRole('VisitDeclarant') && !context.visit.cancelled
              && !(findLatestDeclaration()?.status === 'DECLARED')}">
              <div class="btn-group d-none d-lg-block">
                <ng-container *ngIf="context.visitInTerminalPlanning() else plannedDesktopHeader">
                  <button (click)="declareTerminalPlanning()" id="declareTerminalPlanning" type="button"
                          class="btn btn-info" tabindex="-1">Save and send
                  </button>
                </ng-container>
                <ng-template #plannedDesktopHeader>
                  <button (click)="trySendVisit()" *ngIf="userIsAllowedToDeclareVisit()" id="sendVisit" type="button"
                          class="btn btn-info" tabindex="-1">Save and send
                  </button>
                  <button (click)="syncToWpcs()" *ngIf="userIsAllowedToSyncToWpcs()" id="syncToWpcs" type="button"
                          class="btn btn-info" tabindex="-1">Save and send
                  </button>
                  <button (click)="saveForNextDeclarant()" *ngIf="userIsNextDeclarantOrNextOwner"
                          id="saveForNextDeclarant" type="button" class="btn btn-info" tabindex="-1">Save Changes
                  </button>
                </ng-template>
                <ng-container *ngIf="userIsDeclarantOrOwner() && context.isExpected()">
                  <button type="button" class="btn btn-info dropdown-toggle dropdown-toggle-split"
                          data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span class="visually-hidden">Toggle Dropdown</span>
                  </button>
                  <div class="dropdown-menu dropdown-menu-end">
                    <a class="dropdown-item" href="#" (click)="$event.preventDefault(); saveVisit()"
                       *ngIf="userIsAllowedToSave()" id="saveVisit" role="button">Save</a>
                    <a *ngIf="context.visitInTerminalPlanning()" class="dropdown-item" href="#"
                       (click)="$event.preventDefault(); finishTerminalPlanning()"
                       id="finishTerminalPlanning" role="button">Finish planning
                    </a>
                    <div *ngIf="userIsAllowedToSave() && !context.visitInTerminalPlanning()"
                         class="dropdown-divider"></div>
                    <a id="cancelVisit" class="dropdown-item text-danger" href="#" role="button"
                       (click)="$event.preventDefault(); cancelVisit()">
                      <i class="fa fa-ban" aria-hidden="true"></i> Cancel visit
                    </a>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
      </app-action-nav-bar>
    </div>
  </div>

  <!-- MOBILE (buttons bottom right) -->
  <div
    [ngClass]="{'always-enabled' : appContext.hasRole('VisitDeclarant') && !context.visit.cancelled
    && !(findLatestDeclaration()?.status === 'DECLARED')}">
    <div class="fixed-bottom mb-3 pe-2 btn-group d-block d-lg-none" style="max-width: 100vw;">
      <div class="row justify-content-end g-2">
        <div class="col-auto">
          <button type="button" class="btn btn-info dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true"
                  aria-expanded="false">
            Actions
          </button>
          <div class="dropdown-menu dropdown-menu-end">
            <ng-container *ngIf="!userIsNextDeclarantOrNextOwner">
              <ng-container *ngIf="context.visitInTerminalPlanning() else plannedMobileHeader">
                <button (click)="declareTerminalPlanning()" id="declareTerminalPlanning" type="button"
                        class="dropdown-item" tabindex="-1">Save and send
                </button>
                <button (click)="finishTerminalPlanning()" id="finishTerminalPlanning" type="button"
                        class="dropdown-item" tabindex="-1">Finish planning
                </button>
              </ng-container>
              <ng-template #plannedMobileHeader>
                <button (click)="trySendVisit()" *ngIf="userIsAllowedToDeclareVisit()" id="sendVisit" type="button"
                        class="dropdown-item" tabindex="-1">Save and send
                </button>
                <button (click)="syncToWpcs()" *ngIf="userIsAllowedToSyncToWpcs()" id="syncToWpcs" type="button"
                        class="dropdown-item" tabindex="-1">Save and send
                </button>
              </ng-template>
              <button (click)="saveVisit()" *ngIf="userIsAllowedToSave()" id="saveVisit" type="button"
                      class="dropdown-item" tabindex="-1">Save
              </button>
              <button [disabled]="!context.isExpected()" (click)="cancelVisit()" id="cancelVisit" class="dropdown-item"
                      type="button">Cancel
                visit
              </button>
            </ng-container>
            <ng-container *ngIf="userIsNextDeclarantOrNextOwner">
              <button (click)="saveForNextDeclarant()" id="saveForNextDeclarant" type="button" class="dropdown-item"
                      tabindex="-1">Save Changes
              </button>
            </ng-container>
          </div>
        </div>
        <div class="col-auto">
          <app-visit-admin></app-visit-admin>
        </div>
      </div>
    </div>
  </div>

  <!-- TIMELINE -->
  <div class="row mb-4">
    <div class="col">
      <app-timeline [(berthVisits)]="context.visit.visitDeclaration.portVisit.berthVisits"></app-timeline>
    </div>
  </div>

  <!-- MODALS -->
  <div id="shiftVisitTimesModal" class="modal fade always-enabled" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Adjust other expected times?</h5>
        </div>
        <div class="modal-body">
          <p>Do you want to adjust all subsequent ETA/ETDs with the same time difference?</p>
          <p>The chosen option will apply to all changes while editing this visit.</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="context.shiftTimes = false">
            No
          </button>
          <button type="button" class="btn btn-primary" (click)="context.shiftTimes = true" data-bs-dismiss="modal">Yes
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #berthVisitChronologicalOrder>
  <p>The berth visits are not in chronological order. Below is the suggested order. Do you want to change the berth
    visits to this order?</p>
  <app-timeline [(berthVisits)]="berthVisitsInChronologicalOrder" [readonly]="true"></app-timeline>
</ng-template>
