import {
  BerthVisitSelection,
  VisitForBunkering, Bunkering,
  BunkerLicense,
  BunkerProduct,
  BunkerProductSupplier, SearchVisitsForBunkering
} from "@portbase/bezoekschip-service-typescriptmodels";
import {sendQuery} from "../common/utils";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {Injectable} from "@angular/core";

@Injectable({providedIn: 'root'})
export class BunkeringService {

  products: Observable<BunkerProduct[]>

  static licenses: (term: string) => Observable<BunkerLicense[]> =
    (term: string) => <Observable<BunkerLicense[]>>sendQuery('com.portbase.bezoekschip.common.api.refdata.GetBunkerLicences', {term: term});

  static suppliers: (term: string) => Observable<BunkerProductSupplier[]> =
    (term: string) => <Observable<BunkerProductSupplier[]>>sendQuery('com.portbase.bezoekschip.common.api.refdata.GetBunkerProductSuppliers', {term: term})

  static products: (term: string) => Observable<BunkerProduct[]> =
    (term: string) => <Observable<BunkerProduct[]>>sendQuery('com.portbase.bezoekschip.common.api.refdata.GetBunkerProducts', {term: term})

  static berthVisits: (term: string, licenseId: string) => Observable<BerthVisitSelection[]> =
    (term: string, licenseId: string) => {
      let visits = <Observable<VisitForBunkering[]>>sendQuery('com.portbase.bezoekschip.common.api.bunkering.SearchVisitsForBunkering',
        <SearchVisitsForBunkering>{term: term, licenseId: licenseId});
      return visits.pipe(
        map(v => v.map(s => {
            return s.berthVisits.map(bv => <BerthVisitSelection>{
              crn: s.crn,
              imo: s.imo,
              vesselName: s.vesselName,
              startTime: bv.eta,
              endTime: bv.etd,
              berthName: bv.berthName,
              berthVisitId: bv.berthVisitId
            })
          }).reduce((acc, val) => acc.concat(val), [])
        ))
    }

  static myBunkering: (crn: string, berthVisitId: string, bunkeringId: string) => Observable<Bunkering> =
    (crn: string, berthVisitId: string, bunkeringId: string) => {
      return sendQuery('com.portbase.bezoekschip.common.api.bunkering.GetBunkering', {
        crn: crn,
        berthVisitId: berthVisitId,
        bunkeringId: bunkeringId
      });
    }

}
