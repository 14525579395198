import {Component, OnInit} from '@angular/core';
import {
  AuthorisationsForDeclarant,
  AuthoriseNextDeclarant,
  BezoekschipOrganisation,
  FindShipOperators,
  GetAuthorisationsForDeclarant,
  TransferVisitAtBerth,
  UnAuthoriseNextDeclarant
} from '@portbase/bezoekschip-service-typescriptmodels';
import {Observable, of} from 'rxjs';
import {AppContext} from 'src/app/app-context';
import {ModalConfirmAutofocus, ModalConfirmAutofocusData} from 'src/app/common/modal/modal-confirm.component';
import {
  openConfirmationModalWithCallback,
  scrollToTop,
  sendCommand,
  sendCommandAndWait,
  sendQuery
} from 'src/app/common/utils';
import {VisitContext} from '../../visit-context';

@Component({
  selector: 'app-agent-transfer',
  templateUrl: './agent-transfer.component.html',
  styleUrls: ['./agent-transfer.component.css']
})
export class AgentTransferComponent implements OnInit {
  context = VisitContext;
  appContext = AppContext;
  nextOwner: BezoekschipOrganisation;

  ngOnInit() {
    this.nextOwner = this.context.visit.nextOwner;
  }

  toggleTransferDetails() {
    this._showTransferDetails = !this._showTransferDetails;
  }

  private _showTransferDetails: boolean = false;
  get showTransferDetails(): boolean {
    return this._showTransferDetails;
  }

  get showTransferOnLastBerth(): boolean {
    return this.context.visit.portOfCall.paDeclarationRequired && (this.isOwnerOrDeclarant || this.appContext.isAdmin());
  }

  get enabled(): boolean {
    let lastBerthVisit = this.context.visit.visitDeclaration.portVisit.berthVisits[this.context.visit.visitDeclaration.portVisit.berthVisits.length - 1];
    return !lastBerthVisit.atd;
  }

  private getAuthorisationsForDeclarant(): Observable<AuthorisationsForDeclarant> {
    const nextOwner = this.nextOwner;

    return nextOwner ? sendQuery('com.portbase.bezoekschip.common.api.authorisation.GetAuthorisationsForDeclarant',
      <GetAuthorisationsForDeclarant>{
        organisationShortName: nextOwner.shortName,
        authorityShortName: this.context.visit.portOfCall.portAuthority.shortName,
      }, {showSpinner: true}) : of(<AuthorisationsForDeclarant>{});
  }

  searchShipOperator = (term: String) => sendQuery('com.portbase.bezoekschip.common.api.authorisation.FindShipOperators', <FindShipOperators>{
    authorityShortName: this.context.visit.portOfCall.portAuthority.shortName,
    term: term
  });

  authorise() {
    if (this.context.savedVisit.visitDeclaration.portVisit.berthVisits.length !== this.context.visit.visitDeclaration.portVisit.berthVisits.length) {
      AppContext.registerError("Save and send changes you made before authorising next declarant");
      this.resetNextOwner();
      return;
    }

    if (this.nextOwner.shortName &&
      (this.nextOwner.shortName === this.context.visit.owner.shortName || this.nextOwner.shortName === this.context.visit.declarant.shortName)) {
      AppContext.registerError("Not allowed to transfer to yourself");
      this.resetNextOwner();
    }

    this.getAuthorisationsForDeclarant().subscribe((authorisations: AuthorisationsForDeclarant) => {
      const nextDeclarant = authorisations.backOffice || this.nextOwner;
      const nextOwnerIsCargoAgent = authorisations.cargoAgent;
      const nextDeclarantIsCargoAgent = authorisations.backOfficeCargoAgent;

      if (nextDeclarant) {
        this.authoriseNextDeclarant(nextDeclarant, nextOwnerIsCargoAgent, nextDeclarantIsCargoAgent);
      }
    });
  }

  undoAuthorise() {
    this.resetNextOwner();
  }

  private resetNextOwner() {
    this.nextOwner = null;
    this.toggleTransferDetails();
  }

  private authoriseNextDeclarant(nextDeclarant: BezoekschipOrganisation, nextOwnerIsCargoAgent: boolean,
                                 nextDeclarantIsCargoAgent: boolean) {
    sendCommandAndWait('com.portbase.bezoekschip.common.api.authorisation.AuthoriseNextDeclarant', <AuthoriseNextDeclarant>{
      crn: this.context.visit.crn,
      nextDeclarant: nextDeclarant,
      nextOwner: this.nextOwner,
      nextOwnerIsCargoAgent: nextOwnerIsCargoAgent,
      nextDeclarantIsCargoAgent: nextDeclarantIsCargoAgent,
    }).subscribe(() => {
      this.context.visit.nextOwner = this.nextOwner;
      this.context.visit.nextDeclarant = nextDeclarant;
      if (nextOwnerIsCargoAgent) {
        if (!this.context.visit.cargoDeclarants) {
          this.context.visit.cargoDeclarants = [this.context.visit.nextOwner];
        } else if (!this.context.visit.cargoDeclarants.some(dec => dec && dec.shortName === this.context.visit.nextOwner.shortName)) {
          this.context.visit.cargoDeclarants.push(this.context.visit.nextOwner);
        }
      }
      if (nextDeclarantIsCargoAgent) {
        if (!this.context.visit.cargoDeclarants) {
          this.context.visit.cargoDeclarants = [nextDeclarant];
        } else if (!this.context.visit.cargoDeclarants.some(dec => dec.shortName === nextDeclarant.shortName)) {
          this.context.visit.cargoDeclarants.push(nextDeclarant);
        }
      }
      this.removeOrderFromLastBerthVisit();
      VisitContext.replaceVisit(this.context.visit);
      AppContext.registerSuccess(this.context.visit.nextOwner.fullName + ' has been authorised as next operator');
      scrollToTop();
    });
  }

  private removeOrderFromLastBerthVisit() {
    let lastBerthVisit = this.context.visit.visitDeclaration.portVisit.berthVisits[this.context.visit.visitDeclaration.portVisit.berthVisits.length - 1];
    lastBerthVisit.nextMovement.order = false;
  }

  get isOwnerOrDeclarant() {
    return this.appContext.userProfile.organisation?.shortName === this.context.visit.owner.shortName
      || this.appContext.userProfile.organisation?.shortName === this.context.visit.declarant.shortName;
  }

  transferVisit = () => {
    this.showConfirmModalAndTransfer();
  };

  private showConfirmModalAndTransfer() {
    let fullName = this.context.visit.nextOwner.fullName;

    openConfirmationModalWithCallback((confirmed) => {
      if (confirmed) {
        this.transferVisitAtBerth();
      }
    }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
      title: "Transfer visit",
      message: "Visit will be transferred to " + fullName + ". Please take notice: Changes have effect on dangerous goods declaration of next declarant or owner.",
    }, 'static');
  }

  private transferVisitAtBerth() {
    sendCommand('com.portbase.bezoekschip.common.api.authorisation.TransferVisitAtBerth', <TransferVisitAtBerth>{
      crn: this.context.visit.crn,
      newDeclarant: this.context.visit.nextDeclarant,
      newOwner: this.context.visit.nextOwner,
    }, () => {
      this.context.visit.owner = this.context.visit.nextOwner;
      this.context.visit.declarant = this.context.visit.nextDeclarant;

      this.resetNextOrganisations();

      VisitContext.replaceVisit(this.context.visit);
      AppContext.registerSuccess('The visit has been transferred successfully.');
      scrollToTop();
    }, error => {
      AppContext.registerError(error.error.error);
      scrollToTop();
    });
  }

  unAuthorise() {
    this.showConfirmModalAndRemove();
  }

  private showConfirmModalAndRemove() {
    let fullName = this.context.visit.nextOwner.fullName;

    openConfirmationModalWithCallback((confirmed) => {
      if (confirmed) {
        this.unAuthoriseNextDeclarant(fullName);
      }
    }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
      title: "Unauthorise " + fullName,
      message: "Are you sure you want ot unauthorise " + fullName +
        ". All information associated to this ship operator will be permanently deleted. This operation cannot be undone.",
    }, 'static');
  }

  private unAuthoriseNextDeclarant(fullName: string) {
    sendCommand('com.portbase.bezoekschip.common.api.authorisation.UnAuthoriseNextDeclarant', <UnAuthoriseNextDeclarant>{
      crn: this.context.visit.crn,
    }, () => {
      this.resetNextOrganisations();
      VisitContext.replaceVisit(this.context.visit);
      AppContext.registerSuccess('Unauthorised ' + fullName);
      scrollToTop();
    });
  }

  private resetNextOrganisations() {
    this.context.visit.nextDeclarant = null;
    this.context.visit.nextOwner = null
    this.nextOwner = null;
  }
}
