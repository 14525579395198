<div [id]="berthVisit.id + '-acceptPlanningModal'" class="modal fade always-enabled" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Respond to planning</h5>
      </div>
      <div class="modal-body">
        <app-form-group label="Berth">
          <app-select [(ngModel)]="data.terminal"
                      [refreshProvider]="getTerminalsProvider"
                      [refreshWatch]="berthVisit.stevedore?.shortName"
                      (ngModelChange)="onTerminalSelected()"
                      [formatter]="terminalNameFormatter"
                      [required]="modalOpen()"
                      placeholder="Select berth" dataKey="terminalCode">
          </app-select>
        </app-form-group>
        <app-form-group label="Mooring">
          <app-select [(ngModel)]="data.terminalInfo.mooringOrientation" [required]="modalOpen()" [options]="moorings"
                      [formatter]="mooringFormatter">
          </app-select>
        </app-form-group>
        <app-form-group [disableInvalidFeedback]="true" class="was-validated">
          <!-- force  display of validation errors -->
          <label for="{{data.berthVisitId}}-bollardAft">Bollards</label>
          <div class="d-flex">
            <div class="input-group me-3">
              <span class="input-group-text" id="bollard-from">aft</span>
              <input [(ngModel)]="data.terminalInfo.bollardAft" (blur)="updateBollardsAft($event)"
                     [required]="!!data.terminalInfo.bollardFore && modalOpen()"
                     [disabled]="!data.terminal?.terminalCode"
                     [min]="bollardsInfo?.start" [max]="bollardsInfo?.end"
                     id="{{data.berthVisitId}}-bollardAft" appDecimalNumber class="form-control">
            </div>
            <div class="input-group">
              <span class="input-group-text" id="bollard-to">fore</span>
              <input [(ngModel)]="data.terminalInfo.bollardFore" (blur)="updateBollardsFore($event)"
                     [required]="!!data.terminalInfo.bollardAft && modalOpen()"
                     [disabled]="!data.terminal?.terminalCode"
                     [min]="bollardsInfo?.start" [max]="bollardsInfo?.end"
                     id="{{data.berthVisitId}}-bollardFore" appDecimalNumber class="form-control">
            </div>
          </div>
        </app-form-group>
        <app-form-group label="ETA Berth">
          <app-date-field [(ngModel)]="eta" [required]="modalOpen()"></app-date-field>
        </app-form-group>
        <app-form-group label="ETD Berth">
          <app-date-field [(ngModel)]="etd" [required]="modalOpen()"></app-date-field>
        </app-form-group>
        <app-form-group label="Discharge"><input [(ngModel)]="discharge"
                                                 [required]="modalOpen()" appDecimalNumber
                                                 [maxDecimals]="0" min="0" class="form-control"></app-form-group>
        <app-form-group label="Load"><input [(ngModel)]="load" [required]="modalOpen()"
                                            appDecimalNumber
                                            [maxDecimals]="0" min="0" class="form-control"></app-form-group>
        <app-form-group label="Restow"><input [(ngModel)]="restow"
                                            appDecimalNumber
                                            [maxDecimals]="0" min="0" class="form-control"></app-form-group>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="acceptPlanning()">Send</button>
      </div>
    </div>
  </div>
</div>
