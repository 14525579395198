<div *ngIf="visibleItems as items">
  <app-pagination #pagination [items]="items">
    <ng-container *ngFor="let overlander of pagination.getItemsOnPage(); let index = index">

      <!-- render terminal -->
      <ng-container *ngIf="showTerminal && overlander.terminal && (index === 0
        || overlander.terminal?.terminalCode !== items[index - 1].terminal?.terminalCode)">
        <div class="mt-3 row g-0 align-items-center">
          <div class="col-auto me-2 invisible" *ngIf="!readonly">
            <div class="form-check form-check-inline m-0" style="height: 2.5rem">
              <input class="form-check-input m-0" type="checkbox"></div>
          </div>
          <div class="col py-1 ps-3 border border-white" style="border-width: 2px!important;">
            <span class="fa fa-fw fa-map-marker-alt text-portbase-blue pe-2 me-2"></span>
            <span class="ms-1 fw-bold text-dark">{{utils.terminalFormatter(overlander.terminal)}}</span>
          </div>
        </div>
      </ng-container>

      <div class="row g-0">
        <div class="col-auto me-2" *ngIf="!readonly">
          <div class="form-check form-check-inline m-0 always-enabled" style="height: 2rem">
            <input [(ngModel)]="overlander['selected']" class="always-enabled form-check-input m-0" type="checkbox"></div>
        </div>
        <div class="col">
          <app-collapse #overlanderCollapse [collapse]="overlanderTemplate" [animateOnLoad]="true">
            <div class="row g-0 py-1 px-3 align-items-center text-dark">
              <div class="col">
                <span class="fa fa-fw fa-cube pe-2 me-2"></span>
                <span class="d-inline-block align-top text-truncate">{{overlander.number}}</span>
              </div>
              <div class="col-auto me-3 d-none d-md-block text-dark">
                <app-cargo-summary [overlander]="overlander" [keepTooltipOpen]="overlanderCollapse.showing"></app-cargo-summary>
              </div>
            </div>
          </app-collapse>
        </div>
      </div>

      <ng-template #overlanderTemplate>
        <div class="collapse">
          <div class="mx-3 pt-3 fieldset disabled">
            <app-load-container [container]="$any(overlander)"></app-load-container>
          </div>
        </div>
      </ng-template>
    </ng-container>

    <div class="row g-0 text-secondary" *ngIf="!readonly && items.length > 1">
      <label class="py-2">
        <input [(ngModel)]="items.length === selectedItems.length"
               (click)="toggleSelectAll()" class="always-enabled" type="checkbox">
        <span class="ms-2">Select all</span>
      </label>
    </div>
  </app-pagination>
</div>

<div *ngIf="hiddenItems.length as hiddenCount" class="text-center text-muted" style="padding: 20px 0">
  Hiding {{hiddenCount}} filtered overlander{{hiddenCount == 1 ? '' : 's'}}
  <button type="button" class="btn btn-outline-info btn-sm ms-2" (click)="showAll()">Show all</button>
</div>

<ng-container *ngIf="selectedItems.length > 0">
  <div class="fixed-bottom row g-0 justify-content-center bg-white py-3 border-top">
  </div>
</ng-container>
