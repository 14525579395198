import {Component} from '@angular/core';
import {VisitContext} from '../../visit-context';
import {openConfirmationModal, sendCommand} from '../../../common/utils';
import {
  AcceptHealth,
  Declaration,
  DeclarationType,
  RegisterHamisHealthStatus,
  RejectHealth
} from '@portbase/bezoekschip-service-typescriptmodels';
import {AppContext} from '../../../app-context';

@Component({
  selector: 'app-health-declaration-admin',
  templateUrl: './health-declaration-admin.component.html',
  styleUrls: ['./health-declaration-admin.component.css']
})
export class HealthDeclarationAdminComponent {
  protected readonly openConfirmationModal = openConfirmationModal;
  HEALTH = DeclarationType.HEALTH;

  context = VisitContext;
  appContext = AppContext;

  accept() {
    sendCommand('com.portbase.bezoekschip.common.api.healthdeclaration.AcceptHealth', <AcceptHealth>{
      crn: this.context.visit.crn
    }, () => {
      this.context.visit.declarations.push(<Declaration>{
        type: 'HEALTH',
        status: 'ACCEPTED'
      });
      this.context.replaceVisit(this.context.visit);
      AppContext.registerSuccess('Health declaration technically accepted.');
    });
  }

  reject() {
    sendCommand('com.portbase.bezoekschip.common.api.healthdeclaration.RejectHealth', <RejectHealth>{
      crn: this.context.visit.crn
    }, () => {
      this.context.visit.declarations.push(<Declaration>{
        type: 'HEALTH',
        status: 'REJECTED'
      });
      this.context.replaceVisit(this.context.visit);
      AppContext.registerSuccess('Health declaration technically rejected.');
    });
  }

  hamisReject(remarks: string) {
    sendCommand('com.portbase.bezoekschip.common.api.visit.pa.RegisterHamisHealthStatus', <RegisterHamisHealthStatus>{
      crn: this.context.visit.crn,
      status: 'REJECTED',
      remarks: remarks
    }, () => {
      this.context.replaceVisit(this.context.visit);
      AppContext.registerSuccess('Health declaration functionally rejected by HAMIS.');
    });
  }

  hamisApprove() {
    sendCommand('com.portbase.bezoekschip.common.api.visit.pa.RegisterHamisHealthStatus', <RegisterHamisHealthStatus>{
      crn: this.context.visit.crn,
      status: 'APPROVED'
    }, () => {
      this.context.replaceVisit(this.context.visit);
      AppContext.registerSuccess('Health declaration accepted rejected by HAMIS');
    });
  }

  hamisApproveMeasuresImposed(remarks: string) {
    sendCommand('com.portbase.bezoekschip.common.api.visit.pa.RegisterHamisHealthStatus', <RegisterHamisHealthStatus>{
      crn: this.context.visit.crn,
      status: 'APPROVED',
      healthMeasuresImposed: true,
      remarks: remarks
    }, () => {
      this.context.replaceVisit(this.context.visit);
      AppContext.registerSuccess('Health declaration functionally accepted by HAMIS with measures imposed');
    });
  }
}
