<div class="text-end mt-4 mb-3 me-3">
  <a href="#" class="nav-link p-0" (click)="$event.preventDefault(); add()">
    <span class="pe-2 fa fa-plus-circle"></span>Add contact</a>
</div>

<div *ngFor="let value of values" class="my-2">
  <app-collapse [collapse]="valueTemplate" [showOnLoad]="!value.id" [deleteHandle]="remove(value)">
    <div class="row g-0 align-items-center">
      <div class="col-auto mx-2 p-2">
        <span class="fa fa-fw fa-warehouse text-dark"></span>
      </div>
      <div class="col text-dark">
        <span class="d-inline-block align-top text-truncate">{{utils.warehouseLicenseFormatter(value) || 'New license'}}</span>
      </div>
    </div>
  </app-collapse>

  <ng-template #valueTemplate>
    <div class="collapse">
      <div>
        <div class="mx-3 pt-3">
          <form (ngSubmit)="save(licenseComponent) ? null : $event.stopPropagation();">
            <app-warehouse-license #licenseComponent [model]="value" [mergeAlways]="true" (saved)="onSaved()"></app-warehouse-license>
            <div class="mb-3 text-end">
              <button class="btn btn-info" type="submit" style="min-width: 12rem">Save contact</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </ng-template>
</div>

<div *ngIf="values.length === 0" class="text-center text-muted" style="padding: 20px 0">
  You haven’t added any contacts yet.
</div>

