import {Component, Input} from '@angular/core';
import {DeclarationStatus, MessageStatus, Visit} from "@portbase/bezoekschip-service-typescriptmodels";
import {VisitContext} from "../../visit-context";
import {AppContext} from "../../../app-context";

@Component({
  selector: 'app-visit-declaration-status',
  templateUrl: './visit-declaration-status.component.html',
  styleUrls: ['./visit-declaration-status.component.css']
})
export class VisitDeclarationStatusComponent {
  context = VisitContext;
  appContext = AppContext;

  @Input() visitDeclarationStatus: DeclarationStatus;
  @Input() terminalMessageStatus: MessageStatus;

  get visit(): Visit {
    return this.context.savedVisit;
  }

  get link(): string {
    return '/details/' + this.visit.crn + '/visit';
  }

  get visitStatusHasPriority() {
    return [DeclarationStatus.DECLARED, DeclarationStatus.REJECTED].includes(this.visitDeclarationStatus) ||
      VisitContext.visitIsDeparted();
  }

  getCssClasses = () => {
    let classes: string[] = [];
    if (AppContext.getUrlPath().indexOf(this.link) >= 0) {
      classes.push('active');
    }
    switch (this.terminalMessageStatus) {
      case MessageStatus.DISABLED:
      case MessageStatus.UNKNOWN:
        classes.push('bg-light text-dark');
        break;
      case MessageStatus.ACCEPTED:
      case MessageStatus.DELIVERED:
        classes.push('bg-portbase-green');
        break;
      case MessageStatus.PENDING:
        classes.push('bg-portbase-light-blue');
        break;
      case MessageStatus.WARNING:
        classes.push('bg-warning');
        break;
      case MessageStatus.REJECTED:
        classes.push('bg-portbase-red');
        break;
    }
    return classes;
  }
}
