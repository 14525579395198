<div class="row medium-14-16">
  <div class="col">Vessel name</div>
  <div class="col">IMO number</div>
  <div class="col">CRN</div>
</div>
<div class="row regular-14-16">
  <div class="col">{{selection.vesselName}}</div>
  <div class="col">{{selection.imo}}</div>
  <div class="col">{{selection.crn}}</div>
</div>
<div class="row" style="height: 1rem">
</div>
<div class="row medium-14-16">
  <div class="col">Berth</div>
  <div class="col">Arrival</div>
  <div class="col">Departure</div>
</div>
<div class="row regular-14-16">
  <div class="col">{{selection.berthName}}</div>
  <div class="col">{{selection.startTime | timestamp}}</div>
  <div class="col">{{selection.endTime | timestamp}}</div>
</div>
