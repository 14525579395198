import {AfterViewInit, Component, forwardRef} from '@angular/core';
import {VisitContext} from '../../visit-context';
import {GetCustomsOffices, NextPort, Port} from '@portbase/bezoekschip-service-typescriptmodels/common';

import {v4 as uuid} from 'uuid';
import {of} from 'rxjs';
import {DragulaService} from 'ng2-dragula';
import {sendQuery} from '../../../common/utils';
import {AppContext} from '../../../app-context';
import moment from 'moment';
import {AbstractValueAccessorComponent} from "../../../common/component/value-accessor.component";
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {DeclarationType} from "@portbase/bezoekschip-service-typescriptmodels";

export abstract class BaseNextPortsComponent extends AbstractValueAccessorComponent<NextPort[]> {
  VISIT = DeclarationType.VISIT;

  context = VisitContext;
  appContext = AppContext;

  nextPorts: NextPort[];

  dragulaService: DragulaService;

  writeValue(value: NextPort[]): void {
    this.nextPorts = value;
  }

  get value(): NextPort[] {
    return this.nextPorts;
  }

  portInputFormatter = (port: Port) => port ? port.name + ' – ' + port.locationUnCode : '';
  searchPort = term => sendQuery("com.portbase.bezoekschip.common.api.visit.FindPortsOrWayPoints", {term: term});
  addNextPort = () => {
    if (!this.nextPorts) {
      this.nextPorts = [];
    }
    this.nextPorts.push(<NextPort>{id: uuid()});
  };
  getCustomsOfficeProvider = (port: Port) => port ? sendQuery('com.portbase.bezoekschip.common.api.visit.GetCustomsOffices', <GetCustomsOffices>{countryCode: port.countryUnCode}) : of([]);

  public abstract deleteNextPort(nextPort: NextPort): void;

  public abstract selectNextPort(nextPort: NextPort, port: Port): void;

  public abstract twoPortsNotChronological(index: number): boolean;

  public abstract portNotChronological(port: NextPort): boolean;

  public abstract onDragulaModelChanges($event: any[]): void;

}

@Component({
  selector: 'app-next-next-ports',
  templateUrl: './next-ports.component.html',
  styleUrls: ['./next-ports.component.css'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => NextNextPortsComponent), multi: true}
  ],
})
export class NextNextPortsComponent extends BaseNextPortsComponent implements AfterViewInit {

  constructor(dragulaService: DragulaService) {
    super();
    if (!this.nextPorts) {
      this.nextPorts = [];
    }
    this.dragulaService = dragulaService;
  }

  ngAfterViewInit(): void {
    this.dragulaService.find('nextPorts').options.invalid = (el, handle) => {
      return !handle.classList.contains('drag-handle');
    };
    this.dragulaService.find('nextPorts').options.moves = () => {
      return !this.context.isVisitReadonly();
    };
    this.dragulaService.dropModel('nextPorts').subscribe(value => {
      const nextPort: NextPort = value.item;
      nextPort.arrival = null;
      nextPort.departure = null;
    });
  }

  deleteNextPort = (nextPort: NextPort) => {
    this.nextPorts.splice(this.nextPorts.indexOf(nextPort), 1);
  };

  selectNextPort = (nextPort: NextPort, port: Port) => {
    this.nextPorts[this.nextPorts.indexOf(nextPort)] =
      {
        id: uuid(),
        port: port,
        arrival: nextPort.arrival,
        departure: nextPort.departure,
        customsOffice: null
      };
  };

  twoPortsNotChronological(index: number) {
    return false;
  };

  portNotChronological(port: NextPort) {
    return false;
  }

  onDragulaModelChanges($event: any[]) {
    // this.context.visit.visitDeclaration.nextPorts = $event;
  }

}

@Component({
  selector: 'app-next-ports',
  templateUrl: './next-ports.component.html',
  styleUrls: ['./next-ports.component.css'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => NextPortsComponent), multi: true}
  ],
})
export class NextPortsComponent extends BaseNextPortsComponent implements AfterViewInit {

  constructor(dragulaService: DragulaService) {
    super();
    this.dragulaService = dragulaService;
  }

  ngAfterViewInit(): void {
    this.dragulaService.find('nextPorts').options.invalid = (el, handle) => {
      return !handle.classList.contains('drag-handle');
    };
    this.dragulaService.find('nextPorts').options.moves = () => {
      return !this.context.isVisitReadonly();
    };
    this.dragulaService.dropModel('nextPorts').subscribe(value => {
      const nextPort: NextPort = value.item;
      nextPort.arrival = null;
      nextPort.departure = null;
    });
  }

  deleteNextPort = (nextPort: NextPort) => {
    VisitContext.deleteNextPort(nextPort);
  }

  selectNextPort = (nextPort: NextPort, port: Port) => {
    VisitContext.selectNextPort(nextPort, port);
  }

  twoPortsNotChronological(index: number) {
    if (index === 0) {
      return false;
    }
    const port = this.context.visit.visitDeclaration.nextPorts[index];
    const date = port.arrival ? port.arrival : port.departure;
    const previousPort = this.context.visit.visitDeclaration.nextPorts[index - 1]
    const previousDate = previousPort.departure ? previousPort.departure : previousPort.arrival;
    return date && previousDate && moment(date).isBefore(moment(previousDate));
  }

  portNotChronological(port: NextPort) {
    return port.arrival && port.departure && moment(port.arrival).isAfter(moment(port.departure));
  }

  onDragulaModelChanges($event: any[]) {
    this.context.visit.visitDeclaration.nextPorts = $event;
  }
}
