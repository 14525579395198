<div *ngIf="visit" class="d-flex align-items-center">
  <div class="ms-2">
    <div class="flex-fixed-width-timeline-column text-center"><img width="30" height="30"
                                                                   src="../../../assets/img/icons8-shippie-30.png"/>
    </div>
  </div>
  <div class="ms-2" style="margin: 0; padding: 0; line-height: 1em;">
    <span class="fw-bold text-truncate" style="max-width: 220px;">{{visit.vessel.name | titlecase}}</span>
    <div style="font-size: 0.8rem">{{visit.crn}}</div>
  </div>
  <div class="flex-column m-0 p-0 ms-1 d-none d-sm-block" style="line-height: 1em;">
    <app-info [wide]="!mobile" [placement]="mobile? 'bottom': 'auto'">
      <div class="tooltipContent">
        <div class="text-start">
          <span class="row g-0" style="color:#C0C0C0;">Vessel name: </span>
          <span class="row g-0">{{visit.vessel.name}}</span>
          <span class="row g-0" style="color:#C0C0C0;">Imo code: </span>
          <span class="row g-0">{{visit.vessel.imoCode}}</span>
          <span class="row g-0" style="color:#C0C0C0;">Summer dead weight: </span>
          <span class="row g-0">{{visit.vessel.summerDeadWeight != 0 ? visit.vessel.summerDeadWeight + " kg" :
            "-"}}</span>
          <span class="row g-0" style="color:#C0C0C0;">Maximum width: </span>
          <span class="row g-0">{{visit.vessel.maxWidth != 0 ? visit.vessel.maxWidth + " meter" : "-"}}</span>
          <span class="row g-0" style="color:#C0C0C0;">Full length: </span>
          <span class="row g-0">{{visit.vessel.fullLength}} meter</span>
          <span class="row g-0" style="color:#C0C0C0;">Gross tonnage: </span>
          <span class="row g-0">{{visit.vessel.grossTonnage}}</span>
          <span class="row g-0" style="color:#C0C0C0;">Net tonnage: </span>
          <span class="row g-0">{{visit.vessel.netTonnage}}</span>
          <span class="row g-0" style="color:#C0C0C0; font-size: small">
            <br/>Includes content supplied by IHS Global Ltd;
            <br/>Copyright © IHS Global Ltd, [2023], All rights reserved.
            <br/>All liability for errors and omissions is hereby excluded by IHS and its sources.
            <br/>No representations or warranties are made by IHS or its sources concerning the Information or any conclusions to be drawn from it.</span>
        </div>
      </div>
    </app-info>
  </div>
</div>
