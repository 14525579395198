import {Component, Input} from '@angular/core';
import {MessageStatus} from "@portbase/bezoekschip-service-typescriptmodels";

@Component({
  selector: 'app-message-status',
  templateUrl: './message-status.component.html',
  styleUrls: ['./message-status.component.scss']
})
export class MessageStatusComponent {
  @Input() messageStatus: MessageStatus;
  @Input() label: string;
  @Input() reason: string;
  @Input() header: string;
  @Input() showTooltip: boolean = true;
}
