import { Component, OnInit } from '@angular/core';
import {VisitContext} from "../../visit-context";
import {AppContext} from "../../../app-context";

@Component({
  selector: 'app-port-entry-tab',
  templateUrl: './port-entry-tab.component.html',
  styleUrls: ['./port-entry-tab.component.css']
})
export class PortEntryTabComponent implements OnInit {


	context = VisitContext;
	appContext = AppContext;


	get getPilotOnBoardArrival(): string {
		return this.context.visit.incomingMovementHarbourMasterInfo && this.context.visit.incomingMovementHarbourMasterInfo.pilotOnBoard;
	}

  constructor() { }

  ngOnInit() {
  }

}
