<div [ngSwitch]="status">
  <span *ngIf="status == 'REJECTED'">
    <i class="fa fa-solid fa-circle fa-stack-1x background"></i>
    <i class="fa fa-circle-xmark fa-stack-1x rejected" aria-hidden="true" data-bs-toggle="tooltip"
       title="Rejected"></i>
  </span>
  <span *ngIf="status == 'APPROVED_WITH_REMARKS'">
    <i class="fa fa-solid fa-circle fa-stack-1x background"></i>
    <i class="fa fa-circle-exclamation fa-stack-1x remarks" aria-hidden="true" data-bs-toggle="tooltip"
       title="Actions required"></i>
  </span>
</div>
