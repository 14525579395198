import moment from 'moment';
import {Observable} from 'rxjs';
import {Directive, OnDestroy, OnInit} from '@angular/core';
import {EventGateway} from './event-gateway';
import {filterByTerm} from './utils';
import {map} from 'rxjs/operators';
import {DateTimeRange} from '@portbase/bezoekschip-service-typescriptmodels';
import {InjectorProvider} from './injector-provider';
import {ActivatedRoute} from '@angular/router';
import {AppContext} from "../app-context";
import {DateRange} from "./date/date-range/date-range";

@Directive()
export abstract class AbstractOverviewComponent<T> implements OnInit, OnDestroy {
  localStorageKey(): string {
    return "overview";
  }

  // Configuration
  maxItems: number = 100;
  excludedFilterFields: string[] = [];
  pagingSupported: boolean = false;

  // State
  items: T[] = [];
  from: number = 0;
  endReached: boolean;
  loading: boolean;
  dateRange = this.getDateRange();
  filterTerm = '';
  unsubscribeHandle;

  ngOnInit(): void {
    InjectorProvider.injector.get(ActivatedRoute).queryParams.subscribe(queryParams => {
      this.filterTerm = this.filterTerm === '' ? queryParams['filterTerm'] || '' : this.filterTerm;
      this.loadAndRender();
    });
  }

  ngOnDestroy(): void {
    if (this.unsubscribeHandle) {
      this.unsubscribeHandle();
    }
  }

  /*
    Loading and rendering
   */

  abstract doLoad(dateTimeRange: DateTimeRange): Observable<T[]>;

  abstract doRender(entries: T[]);

  renderFilteredItems = () => this.doRender(this.getFilteredItems());

  loadAndRender = (reset = false, showSpinner = false): void => {
    var observable = (reset ? this.reload() : this.load()).pipe(map((entries: T[]) => {
      this.renderFilteredItems();
      if (!this.unsubscribeHandle) {
        this.unsubscribeHandle = InjectorProvider.injector.get(EventGateway).registerLocalHandler(this);
      }
    }));
    if (showSpinner) {
      AppContext.waitForProcess(observable);
    }
    observable.subscribe(a=>a);
  };

  loadAndRenderNextPage = () => {
    if (this.pagingSupported && !this.endReached && !this.loading) {
      this.from += this.maxItems;
      this.loadAndRender();
    }
  };

  reload = (): Observable<T[]> => {
    this.from = 0;
    this.endReached = false;
    return this.load();
  };

  private load = (): Observable<T[]> => {
    this.loading = true;
    return this.doLoad(<DateTimeRange>{
      start: moment(this.dateRange.start).toISOString(),
      end: moment(this.dateRange.end).add(1, 'days').toISOString()
    }).pipe(map(entries => {
      if (entries.length < this.maxItems) {
        this.endReached = true;
      }
      if (this.from > 0) {
        entries = this.items.concat(entries);
      }
      this.loading = false;

      this.items = entries;
      return entries;
    }));
  };

  /*
    Filtering
   */

  getFilteredItems = (): T[] => this.filterTerm ? this.filterItems() : this.items;

  public filterItems() {
    return this.items.filter(filterByTerm(this.filterTerm, this.excludedFilterFields));
  }

  onDateSelection = (dateRange: DateRange): void => {
    if (dateRange) {
      const now = moment().startOf('day');
      let daysBefore = now.diff(moment(dateRange.start), 'days')
      let daysAfter = moment(dateRange.end).diff(now, 'days');
      this.dateRange = dateRange;
      this.from = 0;
      this.endReached = false;
      this.loadAndRender();

      if (this.localStorageKey()) {
        localStorage.setItem(this.localStorageKey(), JSON.stringify({
          daysBefore: daysBefore,
          daysAfter: daysAfter
        }));
      }
    }
  };

  private getDateRange(): DateRange {
    const preference: OverviewPreference = this.localStorageKey() && JSON.parse(localStorage.getItem(this.localStorageKey()));
    return preference ? {
      start: moment().startOf('day').subtract(preference.daysBefore, 'd').format('YYYY-MM-DD'),
      end: moment().startOf('day').add(preference.daysAfter, 'd').format('YYYY-MM-DD')
    } : {
      start: moment().startOf('day').subtract(7, 'd').format('YYYY-MM-DD'),
      end: moment().startOf('day').add(7, 'd').format('YYYY-MM-DD')
    };
  }
}

export interface OverviewPreference {
  daysBefore: number;
  daysAfter: number;
}
