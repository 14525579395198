import {Component, OnInit} from '@angular/core';
import {VisitContext} from '../../visit-context';
import {sendCommand} from "../../../common/utils";
import {AcceptWaste, Declaration, DeclarationType, RejectWaste} from "@portbase/bezoekschip-service-typescriptmodels";
import {AppContext} from "../../../app-context";
import {PortvisitUtils} from '../../../refdata/portvisit-utils';

@Component({
  selector: 'app-waste-admin',
  templateUrl: './waste-admin.component.html',
  styleUrls: ['./waste-admin.component.css']
})
export class WasteAdminComponent implements OnInit {
  WASTE = DeclarationType.WASTE;

  context = VisitContext;
  appContext = AppContext;
  downloadLink = null;
  downloadUri = "/api/uploads/";

  ngOnInit(): void {
    const key: string = VisitContext.visit.uploads.WASTE;
    if (key) {
      this.downloadLink = this.downloadUri + key;
    }
  }

  openPaMessages() {
    PortvisitUtils.redirectToMessageManagement(VisitContext.visit.crn + '_wastedeclaration2')
  }

  openSwMessages() {
    PortvisitUtils.redirectToMessageManagement(VisitContext.visit.crn + '_was2sw')
  }

  accept() {
    sendCommand('com.portbase.bezoekschip.common.api.waste.AcceptWaste', <AcceptWaste>{
      crn: VisitContext.visit.crn
    }, () => {
      VisitContext.visit.declarations.push(<Declaration>{
        type: 'WASTE',
        status: 'ACCEPTED'
      });
      VisitContext.replaceVisit(VisitContext.visit);
      AppContext.registerSuccess('Waste declaration was set to accepted successfully.');
    });
  }

  reject(reason: string) {
    sendCommand('com.portbase.bezoekschip.common.api.waste.RejectWaste', <RejectWaste>{
      crn: VisitContext.visit.crn,
      reason: reason
    }, () => {
      VisitContext.visit.declarations.push(<Declaration>{
        type: 'WASTE',
        status: 'REJECTED'
      });
      VisitContext.replaceVisit(VisitContext.visit);
      AppContext.registerSuccess('Waste declaration was set to rejected successfully.');
    });
  }
}
