import {Component} from '@angular/core';
import {VisitContext} from "../../../visit-context";
import {sendQuery} from "../../../../common/utils";
import {EntryPoint} from "@portbase/bezoekschip-service-typescriptmodels";
import {VisitValidator} from "../../../visit-validator";

@Component({
  selector: 'app-entry-point',
  templateUrl: './entry-point.component.html',
  styleUrls: ['./entry-point.component.css']
})
export class EntryPointComponent {

  context = VisitContext;
  validator = VisitValidator;

  constructor() { }

  private _showDetails:boolean = false;
  get showDetails(): boolean { return this._showDetails; }
  toggleDetails() {
    this._showDetails = !this._showDetails;
  }

  entryPointFormatter = (entryPoint: EntryPoint) => entryPoint ? entryPoint.name : '';

  entryPointProvider = sendQuery('com.portbase.bezoekschip.common.api.visit.GetAccessPoints',
    {
      portUnCode: VisitContext.visit.portOfCall.port.locationUnCode,
      atSea: VisitContext.visit.visitDeclaration.portVisit.portEntry ? VisitContext.visit.visitDeclaration.portVisit.portEntry.origin === 'SEA' : null
    });

  showEtaPort() {
    // show ETA Port only for ports without pilot station
    return !(VisitContext.visit.orderIncomingMovement || VisitContext.visit.visitDeclaration.portVisit.pilotStation);
  }

  isArrived() {
    return VisitContext.isArrived();
  }

  isWaitingForOrders() {
    return VisitContext.isWaitingForOrders();
  }

  onEtaPortChanged($event: any) {
    VisitContext.shiftTime(VisitContext.visit.visitDeclaration.portVisit, 'etaPort', $event)
  }
}
