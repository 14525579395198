import {Component} from '@angular/core';

import {
  BezoekschipOrganisation,
  FindFinancialDeclarants,
  NominateFinancialDeclarant,
  UpdateCreditNumberHolder
} from '@portbase/bezoekschip-service-typescriptmodels';
import {VisitContext} from '../visit-context';
import {sendCommand, sendQuery} from '../../common/utils';
import {AppContext} from '../../app-context';

@Component({
  selector: 'app-financial-declarant',
  templateUrl: './financial-declarant.component.html',
  styleUrls: ['./financial-declarant.component.css']
})
export class FinancialDeclarantComponent {
  context = VisitContext;
  searchFinancialDeclarant = term => sendQuery('com.portbase.bezoekschip.common.api.authorisation.FindFinancialDeclarants', <FindFinancialDeclarants>{
    portUnCode: this.context.visit.portOfCall.port.locationUnCode,
    authorityShortName: this.context.visit.portOfCall.portAuthority.shortName,
    term: term
  });
  _financialDeclarant: BezoekschipOrganisation;
  creditNumberFormatter = value => value ? "Use own number" : "Use number of ship operator";

  set financialDeclarant(value: BezoekschipOrganisation) {
    this._financialDeclarant = value;
  }

  get financialDeclarant(): BezoekschipOrganisation {
    if (this._financialDeclarant === undefined) {
      this._financialDeclarant = this.context.visit.nominatedFinancialDeclarant || this.context.visit.financialDeclarant;
    }
    return this._financialDeclarant;
  }

  get disableNominate(): boolean {
    if (!this._financialDeclarant) {
      return true;
    }
    const saved = this.context.savedVisit.nominatedFinancialDeclarant || this.context.visit.financialDeclarant;
    return saved.shortName === this._financialDeclarant.shortName;
  }

  nominate = () => {
    const savedDeclarant = this._financialDeclarant;
    sendCommand('com.portbase.bezoekschip.common.api.authorisation.NominateFinancialDeclarant', <NominateFinancialDeclarant>{
        crn: this.context.visit.crn,
        nominatedFinancialDeclarant: this._financialDeclarant,
      },
      () => {
        this.context.visit.nominatedFinancialDeclarant
          = savedDeclarant.shortName === this.context.visit.financialDeclarant.shortName ? null : savedDeclarant;
        VisitContext.replaceVisit(this.context.visit);
        AppContext.registerSuccess('Financial declarant was updated successfully.');
      });
  };

  get disableUpdateCreditNumber(): boolean {
    return this.context.visit.creditNumberHolderSameAsDeclarant === this.context.savedVisit.creditNumberHolderSameAsDeclarant;
  }

  updateCreditNumber = () => {
    sendCommand('com.portbase.bezoekschip.common.api.authorisation.UpdateCreditNumberHolder', <UpdateCreditNumberHolder> {
      crn: this.context.visit.crn,
      creditNumberHolderSameAsDeclarant: this.context.visit.creditNumberHolderSameAsDeclarant
    }, () => {
      VisitContext.replaceVisit(this.context.visit);
      AppContext.registerSuccess('Credit number was updated successfully.');
    });
  };
}
