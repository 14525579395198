<div class="row" (change)="addressChange.emit(address)">
  <div class="col-10 col-md-6">
    <app-form-field label="Street" [renderOptional]="false" [editMode]="editable" [value]="address.street">
      <input class="input-group rounded border form-control" [(ngModel)]="address.street"/>
    </app-form-field>
  </div>
  <div class="col-2 col-md-6">
    <app-form-field label="Number" [renderOptional]="false" [editMode]="editable" [value]="address.number">
      <input class="input-group rounded border form-control" [(ngModel)]="address.number"/>
    </app-form-field>
  </div>
  <div class="col-12 col-md-6">
    <app-form-field label="Postal code" [renderOptional]="false" [editMode]="editable" [value]="address.postCode">
      <input class="input-group rounded border form-control" [(ngModel)]="address.postCode"/>
    </app-form-field>
  </div>
  <div class="col-12 col-md-6">
    <app-form-field label="City" [renderOptional]="false" [editMode]="editable" [value]="address.city">
      <input class="input-group rounded border form-control" [(ngModel)]="address.city"/>
    </app-form-field>
  </div>
  <div class="col-12 col-md-6">
    <app-form-field label="Country" [renderOptional]="false" [editMode]="editable" [value]="address.country">
      <input class="input-group rounded border form-control" [(ngModel)]="address.country"/>
    </app-form-field>
  </div>
</div>
