<ng-container *ngIf="voyage">

  <app-form-group *ngIf="(!voyage.carrier || voyage.carrier.name) && showCarrier && !context.visitInTerminalPlanning()">
    <label for="departureVoyage-carrierName" [ngClass]="{'required': departureCarrierRequired()}">Carrier</label>
    <app-search [(ngModel)]="voyage.carrier" dataKey="name" (ngModelChange)="onVoyageCarrierChange($event)"
                [searchFunction]="searchCarrier" [required]="departureCarrierRequired()"
                id="departureVoyage-carrierName"
                [inputFormatter]="carrierFormatter"></app-search>
  </app-form-group>

  <app-form-group *ngIf="(!voyage.carrier || !voyage.carrier.name) && showCarrier && !context.visitInTerminalPlanning()">
    <label for="departureVoyage-carrierCustomsId" [ngClass]="{'required': departureCarrierRequired()}">Carrier
      customs id</label>
    <input [ngModel]="voyage.carrier && voyage.carrier.customsId" (ngModelChange)="onCarrierCustomsIdChanged($event)"
           [required]="departureCarrierRequired()" id="departureVoyage-carrierCustomsId" type="search"
           class="form-control">
  </app-form-group>

  <app-form-group>
    <label for="departureVoyage-number">Voyage number</label>
    <input [(ngModel)]="voyage.voyageNumber" (ngModelChange)="onVoyageNumberChange($event)" id="departureVoyage-number"
      type="text" maxlength="17" class="form-control" [required]="outboundVoyageNumberRequired()">
  </app-form-group>

</ng-container>
