import {Component} from '@angular/core';
import {VisitContext} from '../../visit-context';
import moment from 'moment';
import {roundToQuarterHour} from '../../../common/date/time-utils';
import {sendCommand} from '../../../common/utils';
import {IgnoreAisEta} from '@portbase/bezoekschip-service-typescriptmodels';

@Component({
  selector: 'app-eta-port-ais',
  templateUrl: './eta-port-ais.component.html',
  styleUrls: ['./eta-port-ais.component.css']
})
export class EtaPortAisComponent {
  context = VisitContext;

  showEtaPortAis = () => {
    const visit = VisitContext.visit;
    if (!visit.etaPortAis || visit.ignoreEtaPortAis || !visit.visitDeclaration.portVisit.etaPort) {
      return false;
    }
    const etaPortAis = moment(visit.etaPortAis);
    if (etaPortAis.isBefore(moment())) {
      return false;
    }
    return Math.abs(etaPortAis.diff(moment(visit.visitDeclaration.portVisit.etaPort), 'minutes')) > 30;
  };

  acceptEtaPortAis = () => {
    if (this.context.visit.orderIncomingMovement) {
      this.context.shiftTime(this.context.visit.visitDeclaration.portVisit.portEntry, 'etaPilotBoardingPlace',
        roundToQuarterHour(VisitContext.visit.etaPortAis));
    } else {
      this.context.shiftTime(this.context.visit.visitDeclaration.portVisit, 'etaPort',
        roundToQuarterHour(VisitContext.visit.etaPortAis));
    }
  };

  ignoreEtaPortAis = () => {
    sendCommand('com.portbase.bezoekschip.common.api.visit.IgnoreAisEta', <IgnoreAisEta>{crn: VisitContext.visit.crn},
      () => {
        VisitContext.visit.ignoreEtaPortAis = true;
        VisitContext.replaceVisit(VisitContext.visit);
      });
  };

}
