<div class="card mt-2 mt-md-0">
  <div class="card-body p-0 pt-3 px-3 m-0">
    <ng-container [ngSwitch]="type">
      <!-- REQUIRED YES/NO -->
      <ng-container *ngSwitchCase="'Pilot'">
        <app-form-group>
          <label [attr.for]="id + '-required'" [ngClass]="{'required': required}">Pilot</label>
          <app-yes-no [(ngModel)]="service.required" (ngModelChange)="sanitize(); onModelChange()" [required]="required"
                      [id]="id + '-required'"></app-yes-no>
        </app-form-group>
      </ng-container>
      <ng-container *ngSwitchCase="'Tugboat'">
        <app-form-group>
          <app-info class="formGroupLabel">
            <label [attr.for]="id + '-required'" [ngClass]="{'required': required}">Tugboat</label>
            <span class="tooltipContent">
              <p>Note: If you select 'Tugboat = No', the ship will NOT be included in the schedule of the tug company!
                There will be a significant risk of delay.
              <p>If you don't know the number of tugboats required, choose LAB.
                The number of tugs is then determined by the pilot.</p>
              <p>For container ships that are 350 m long or longer and LNG ships, choosing LAB is mandatory.</p>
          </span>
          </app-info>
          <app-yes-no [(ngModel)]="service.required" (ngModelChange)="sanitize(); onModelChange()" [required]="required"
                      [id]="id + '-required'"></app-yes-no>
        </app-form-group>
      </ng-container>
      <ng-container *ngSwitchCase="'Boatmen'">
        <app-form-group>
          <label [attr.for]="id + '-required'" [ngClass]="{'required': required}">Boatmen</label>
          <app-yes-no [(ngModel)]="service.required" (ngModelChange)="sanitize(); onModelChange()" [required]="required"
                      [id]="id + '-required'"></app-yes-no>
        </app-form-group>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="service.required ||
    (type === 'Tugboat' && service.required !== null && tugsRequiredForPortOfCall)">
      <app-form-group>
        <label [attr.for]="id + '-organisation'">Organisation</label>
        <app-select [(ngModel)]="service.serviceProvider" (ngModelChange)="onModelChange()" required
                    [optionsProvider]="serviceProviders" optionLabel="name" [addSelectedIfNotExists]="berthVisit?.ata"
                    dataKey="portAuthorityId" [id]="id + '-organisation'"></app-select>
      </app-form-group>
    </ng-container>
    <ng-container *ngIf="service.required">
      <!-- TUGBOAT QUANTITY (only applicable to tugboats) -->
      <app-form-group *ngIf="type === 'Tugboat'">
        <label [attr.for]="id + '-requiredQuantity'">Quantity</label>
        <app-select *ngIf="!isLabTheOnlyOption()" [(ngModel)]="service.requiredQuantity"
                    (ngModelChange)="onModelChange()" [options]="tugboatQuantities" [formatter]="quantityFormatter"
                    required [id]="id + '-requiredQuantity'">
        </app-select>
        <app-select *ngIf="isLabTheOnlyOption()" [(ngModel)]="service.requiredQuantity"
                    addSelectedIfNotExists [options]="[tugboatQuantities[0]]" [formatter]="quantityFormatter"
                    required [id]="id + '-requiredQuantity'">
        </app-select>
      </app-form-group>
      <app-form-group *ngIf="type === 'Tugboat' && context.visit.additionalIncomingMovementInfo &&
      context.visit.additionalIncomingMovementInfo.plannedNumberOfTugboats">
        <label [attr.for]="id + '-plannedQuantity'">Planned quantity</label>
        <app-select [(ngModel)]="context.visit.additionalIncomingMovementInfo.plannedNumberOfTugboats"
                    [options]="tugboatQuantities" [formatter]="quantityFormatter" disabled
                    [id]="id + '-plannedQuantity'">
        </app-select>
      </app-form-group>

      <!-- STORM PILOTAGE (only applicable to type 'Pilot' and Port of Rotterdam) -->
      <ng-container *ngIf="type === 'Pilot' && context.visit.portOfCall.port.locationUnCode === 'NLRTM'">
        <app-storm-pilotage *ngIf="isFirstMovement" [firstMovement]="true"
                            [ngModel]="context.visit.visitDeclaration.portVisit.firstMovement"
                            [localMovement]="context.visit.visitDeclaration.portVisit.firstMovement"
                            [isTanker]="!context.visit.vessel.statCode5 ||
                            context.visit.vessel.statCode5.startsWith('A1')"
                            [vesselLength]="context.visit.vessel.fullLength" [id]="'first-berth-incoming'"
                            [required]="context.isStormPilotageInfoRequiredForPortEntry(context.visit)">
        </app-storm-pilotage>
        <app-storm-pilotage *ngIf="isLastMovement" [firstMovement]="false" [ngModel]="berthVisit.nextMovement"
                            [localMovement]="berthVisit.nextMovement" [isTanker]="!context.visit.vessel.statCode5 ||
                            context.visit.vessel.statCode5.startsWith('A1')"
                            [vesselLength]="context.visit.vessel.fullLength" [id]="berthVisit.id + '-outgoing'"
                            [required]="context.isStormPilotageInfoRequiredForPortDeparture(context.visit)">
        </app-storm-pilotage>
      </ng-container>

      <!-- REMARKS -->
      <div class="d-flex">
        <div class="ms-auto mb-2">
          <a *ngIf="!service.remarks && !show" role="button" href="#" (click)="$event.preventDefault(); showRemarks()">
            Add remarks</a>
        </div>
      </div>
      <app-form-group *ngIf="service.remarks || (!service.remarks && show)">
        <label [attr.for]="id + '-remarks'">Remarks</label>
        <textarea [(ngModel)]="service.remarks" (ngModelChange)="onModelChange()" [id]="id + '-remarks'" maxlength="70"
                  class="form-control"></textarea>
      </app-form-group>
    </ng-container>
  </div>
</div>
