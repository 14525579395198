<div class="row narrow-gutters">
  <div class="col-md-auto ms-auto">
    <span class="dropdown mx-1">
        <button class="btn btn-outline-secondary dropdown-toggle" style="min-width: 10rem" type="button" data-bs-toggle="dropdown">Messages
        <span class="caret"></span></button>
        <ul class="dropdown-menu dropdown-menu-start">
          <ng-container *ngFor="let list of lists">
            <ng-container *ngFor="let declaration of getDeclarations(list)">
              <li *ngIf="declaration.type==='COPRAR'" (click)="openMessages(declaration)"
                  class="dropdown-item">{{declaration.type}} to terminal code {{declaration.id}}
                at {{declaration.timeStamp | date: "dd-MM-yyyy HH:mm"}}</li>
            </ng-container>
          </ng-container>
        </ul>
    </span>
  </div>
</div>
