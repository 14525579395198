import {WasteItem, WasteType, WasteTypeSsn} from '@portbase/bezoekschip-service-typescriptmodels';
import {VisitContext} from '../visit-context';
import {getWasteTypeSsn} from "./waste.type-ssn";

export type VesselType = 'TANKERS' | 'NON-TANKERS' | 'ANY';

export class WasteCategory {
  name: string;
  annex: number;
  vesselType: VesselType;
  items: WasteDisplayItem[];

  constructor(name: string, annex: number, vesselType: VesselType, items: WasteDisplayItem[]) {
    this.name = name;
    this.annex = annex;
    this.vesselType = vesselType;
    this.items = items;
  }
}

export class WasteDisplayItem {
  ssn: WasteTypeSsn;
  type: WasteType;
  index: number;
  warnings: string[];
  errors: string[];

  constructor(ssn: WasteTypeSsn, type: WasteType, index: number) {
    this.ssn = ssn
    this.type = type;
  }

  get wasteItem(): WasteItem {
    return VisitContext.visit.wasteDeclaration.wasteItems.find(wi => wi.ssn.code == this.ssn.code);
  }
}

export const wasteCategories: WasteCategory[] = createCategories();

export const wasteDisplayItems: WasteDisplayItem[] = getAllDisplayItems();

function createCategories(): WasteCategory[] {
  let index = 0;
  return [
    new WasteCategory('Oily waste (Annex 1)', 1, 'ANY', [
      new WasteDisplayItem(getWasteTypeSsn("101"), WasteType.BILGE_WATER, index++),
      new WasteDisplayItem(getWasteTypeSsn("102"), WasteType.SLUDGE, index++),
      new WasteDisplayItem(getWasteTypeSsn("999"), WasteType.OTHER_OILY_WASTE, index++),
    ]),
    new WasteCategory('Garbage (Annex 5)', 5, 'ANY', [
      new WasteDisplayItem(getWasteTypeSsn("501"), WasteType.PLASTIC_ANY, index++),
      new WasteDisplayItem(getWasteTypeSsn("502"), WasteType.FOOD_WASTE, index++),
      new WasteDisplayItem(getWasteTypeSsn("503"), WasteType.DOMESTIC_WASTES, index++),
      new WasteDisplayItem(getWasteTypeSsn("504"), WasteType.COOKING_OIL, index++),
      new WasteDisplayItem(getWasteTypeSsn("505"), WasteType.INCINERATOR_ASHES, index++),
      new WasteDisplayItem(getWasteTypeSsn("506"), WasteType.OPERATIONAL_WASTE, index++),
      new WasteDisplayItem(getWasteTypeSsn("507"), WasteType.ANIMAL_CARCASSES, index++),
      new WasteDisplayItem(getWasteTypeSsn("508"), WasteType.FISHING_GEAR, index++),
      new WasteDisplayItem(getWasteTypeSsn("509"), WasteType.EWASTE_DEVICES, index++),
    ]),
    new WasteCategory('Sewage (Annex 4)', 4, 'ANY', [
      new WasteDisplayItem(getWasteTypeSsn("401"), WasteType.SEWAGE_WASTE_BLACK, index++),
    ]),
    new WasteCategory('Cargo residue (Annex 1) (Tankers only)', 1, 'TANKERS', [
      new WasteDisplayItem(getWasteTypeSsn("103"), WasteType.WASHWATER_OIL, index++),
      new WasteDisplayItem(getWasteTypeSsn("104"), WasteType.DIRTY_BALLASTWATER, index++),
      new WasteDisplayItem(getWasteTypeSsn("105"), WasteType.OILY_WASTE_FROM_CLEANING, index++),
    ]),
    new WasteCategory('Cargo residues chemicals (Annex 2) (Tankers only)', 2, 'TANKERS', [
      new WasteDisplayItem(getWasteTypeSsn("201"), WasteType.CARGO_RESIDUES_OR_WASHWATER, index++),
      new WasteDisplayItem(getWasteTypeSsn("202"), WasteType.CARGO_RESIDUES_OR_WASHWATER, index++),
      new WasteDisplayItem(getWasteTypeSsn("203"), WasteType.CARGO_RESIDUES_OR_WASHWATER, index++),
      new WasteDisplayItem(getWasteTypeSsn("204"), WasteType.CARGO_RESIDUES_OR_WASHWATER, index++),
    ]),
    new WasteCategory('Cargo residue (Annex 5) (Non Tankers only)', 5, 'NON-TANKERS', [
      new WasteDisplayItem(getWasteTypeSsn("510"), WasteType.DRY_CARGO_RESIDUES_NON_HME, index++),
      new WasteDisplayItem(getWasteTypeSsn("511"), WasteType.DRY_CARGO_RESIDUES_HME, index++),
    ]),
    new WasteCategory('Ozone substances residue (Annex 6)', 6, 'ANY', [
      new WasteDisplayItem(getWasteTypeSsn("601"), WasteType.OZONE_DEPLETION, index++),
      new WasteDisplayItem(getWasteTypeSsn("602"), WasteType.EXHAUST_GAS_CLEANING, index++),
    ]),
    new WasteCategory('Other, Not covered by MARPOL', 1, 'ANY', [
      new WasteDisplayItem(getWasteTypeSsn("991"), WasteType.PASSIVELY_FISHED_WASTE, index++),
    ]),
  ]
}

function getAllDisplayItems(): WasteDisplayItem[] {
  const result: WasteDisplayItem[] = [];
  createCategories().forEach(c => c.items.forEach(i => result.push(i)));
  return result;
}
