import {of} from 'rxjs';
import {
  DeclarationStatus,
  Guarantee,
  RequestTransitGuaranteeBalance,
  SaveTransitGuarantee
} from '@portbase/bezoekschip-service-typescriptmodels';
import {Injectable} from "@angular/core";
import {CommandHandler} from "../common/command-handler";
import {replaceItem, uuid} from '../common/utils';
import {InjectorProvider} from '../common/injector-provider';
import {EventGateway} from '../common/event-gateway';
import {adminGuarantees, myTransitGuarantees} from './refdata-query-handler.mock';
import lodash from 'lodash';
import moment from 'moment';

@Injectable()
export class GuaranteeCommandHandlerMock extends CommandHandler {

  'com.portbase.bezoekschip.common.api.transit.SaveTransitGuarantee' = (command: SaveTransitGuarantee) => {
    this.updateGuarantee(command.id, r => {
      r.guaranteeData = command.guaranteeData;
      return r;
    });
    return of(null);
  };

  'com.portbase.bezoekschip.common.api.transit.RequestTransitGuaranteeBalance' = (command: RequestTransitGuaranteeBalance) => {
    this.updateGuarantee(command.id, r => {
      r.status = DeclarationStatus.ACCEPTED;
      r.balance = {
        amount: 1234.50,
        currency: {
          unCode: 'EUR',
          description: 'EURO'
        }
      };
      if (r.timestamp && moment(r.timestamp).isSame(moment(), 'days')) {
        r.balanceQueryCount += 1;
      } else {
        r.balanceQueryCount = 1;
      }
      r.processId = uuid();
      r.timestamp = moment().toISOString();
      r.customsReference = r.id
      return r;
    });
    return of(null);
  };

  private updateGuarantee(id, patch: (r: Guarantee) => Guarantee) {
    let guarantee = lodash.concat(myTransitGuarantees, adminGuarantees).find(i => i.id === id);
    let newValue = patch(guarantee);
    replaceItem(myTransitGuarantees, guarantee, newValue);
    replaceItem(adminGuarantees, guarantee, newValue);
    InjectorProvider.injector.get(EventGateway).publish('handleGuaranteeUpdate', newValue);
  }
}
