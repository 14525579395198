<div class="fieldset" [ngClass]="context.findLatestDeclaration(DANGEROUS_GOODS)?.status === 'DECLARED'
                                          || isNotOwnDangerousGoodsDeclaration() && !appContext.isAdmin()
                                              ? 'disabled'
                                              : context.visitIsDeparted() ? 'enabled' : ''">


  <div class="row sticky-top bg-white" style="top:6.125rem">
    <div class="col-md-12">
      <app-alerting></app-alerting>
    </div>

    <div class="col-md-12">
      <app-action-nav-bar>
        <!--Buttons-->
        <div class="row mb-1">
          <div class="col text-end">
            <ng-container *ngIf="!context.isOrganisationNextDeclarant()">
              <button data-bs-toggle="modal" data-bs-target="#cancelDGModal" id="cancelDG"
                      *ngIf="context.dangerousGoodsDeclaration?.completed" type="button" class="btn btn-secondary"
                      tabindex="-1">Cancel
              </button>
            </ng-container>
            <ng-container *ngIf="context.isOrganisationNextDeclarant()">
              <button (click)="saveNextDangerousGoodsDeclaration()" type="button" class="btn btn-info" tabindex="-1">Save
              </button>
            </ng-container>
            <ng-container *ngIf="!context.isOrganisationNextDeclarant()">
              <button (click)="save()" *ngIf="!context.dangerousGoodsDeclaration?.completed" type="button"
                      class="btn btn-info" tabindex="-1">Save
              </button>
              <button (click)="complete()" type="button" class="btn btn-info ms-1" tabindex="-1">Complete
              </button>
            </ng-container>
          </div>
        </div>
      </app-action-nav-bar>
    </div>
  </div>

  <div #outerDiv *ngIf="context.dangerousGoodsDeclaration" (change)="onChange()">


    <!-- Stowage at arrival -->
    <div class="row" *ngIf="!(context.isOrganisationNextDeclarant()) && showStowageAtArrival()">
      <div class="col-12">
        <app-stowages [stowageArray]="context.dangerousGoodsDeclaration.dangerousGoods?.stowageAtArrival"
                      (upload)="upload($event)"></app-stowages>
      </div>
    </div>

    <!-- Handlings -->
    <div class="row">
      <div class="declaration-block col-12">
        <h2>Handlings</h2>
      </div>
      <div class="col-12">
        <app-handlings></app-handlings>
      </div>
    </div>
  </div>

  <div id="cancelDGModal" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Are you sure that you want to cancel this dangerous goods declaration?</h5>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">No</button>
          <button type="button" class="btn btn-primary" (click)="cancel()" data-bs-dismiss="modal">Yes</button>
        </div>
      </div>
    </div>
  </div>

</div>
