<div id="alerts">
  <div *ngFor="let alert of appContext.alerts.slice(0, maxAlertCount)" class="mt-2">
    <app-status-alert [type]="alert.level" (close)="appContext.closeAlerts(alert)" [msShowTime]="alert.msShowTime">
      {{alert.content}}
    </app-status-alert>
  </div>
  <div *ngIf="appContext.alerts.length > maxAlertCount" class="mt-2">
    <div class="collapse" id="alertsColapse">
      <div *ngFor="let alert of appContext.alerts.slice(maxAlertCount)">
        <app-status-alert [type]="alert.level" (close)="appContext.closeAlerts(alert)" [msShowTime]="alert.msShowTime">
          {{alert.content}}
        </app-status-alert>
      </div>
    </div>
    <button class="btn btn-outline-danger float-end collapsed mb-2" type="button" data-bs-toggle="collapse"
      data-bs-target="#alertsColapse">
      <span class="more-alerts-button">Show more errors</span>
      <span class="fewer-alerts-button">Show fewer errors</span>
    </button>
  </div>
</div>
