import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-flux-search-export',
  templateUrl: './flux-search-export.component.html',
  styleUrls: ['./flux-search-export.component.css']
})
export class FluxSearchExportComponent {
  type: string;

  constructor() { }
}
