import {Component, TemplateRef, ViewChild} from '@angular/core';
import {VisitContext} from '../../visit-context';
import {openConfirmationModal, sendCommand, sendQuery} from '../../../common/utils';
import {
  AcceptVisit,
  Declaration,
  DeclarationType,
  RejectVisit,
  RemoveAtdPort,
  Visit
} from '@portbase/bezoekschip-service-typescriptmodels';
import {AppContext} from '../../../app-context';
import {PortvisitUtils} from '../../../refdata/portvisit-utils';

@Component({
  selector: 'app-visit-admin',
  templateUrl: './visit-admin.component.html',
  styleUrls: ['./visit-admin.component.css']
})
export class VisitAdminComponent {
  protected readonly openConfirmationModal = openConfirmationModal;

  VISIT = DeclarationType.VISIT;

  @ViewChild("rejectModal") rejectModal: TemplateRef<any>;

  context = VisitContext;
  appContext = AppContext;

  openMessages(type: string) {
    PortvisitUtils.redirectToMessageManagement(VisitContext.visit.crn + '_' + type);
  }

  acceptVisit() {
    sendCommand('com.portbase.bezoekschip.common.api.visit.AcceptVisit', <AcceptVisit>{
      crn: VisitContext.visit.crn
    }, () => {
      VisitContext.visit.declarations.push(<Declaration>{
        type: 'VISIT',
        status: 'ACCEPTED'
      });
      VisitContext.replaceVisit(VisitContext.visit);
      AppContext.registerSuccess('The visit was set to accepted successfully.');
    });
  }

  rejectVisit(reason: string) {
    sendCommand('com.portbase.bezoekschip.common.api.visit.RejectVisit', <RejectVisit>{
      crn: VisitContext.visit.crn,
      reason: reason
    }, () => {
      VisitContext.visit.declarations.push(<Declaration>{
        type: 'VISIT',
        status: 'REJECTED'
      });
      VisitContext.replaceVisit(VisitContext.visit);
      AppContext.registerSuccess('The visit was set to rejected successfully.');
    });
  }

  retrySendToWpcs() {
    sendCommand('com.portbase.bezoekschip.common.api.messaging.RetrySendToWpcs', {
      crn: VisitContext.visit.crn
    }, () => {
      AppContext.registerSuccess('A new wpcs-sync has been triggered successfully.');
    });
  }


  tryRefreshRefData() {
    sendCommand('com.portbase.bezoekschip.common.api.messaging.TryRefreshRefData', {
      crn: VisitContext.visit.crn
    }, () => {
      sendQuery("com.portbase.bezoekschip.common.api.visit.GetVisit", {crn: VisitContext.visit.crn},
        {caching: false, showSpinner: true})
        .subscribe((visit: Visit) => {
          VisitContext.replaceVisit(visit);
          AppContext.registerSuccess('Terminals and berths have been refreshed in this visit. ' +
            'Changes are saved, not sent. Contact team if you need other ref data to refresh.', 10000);
        })
    });
  }

  tryRefreshVisitSummary() {
    sendCommand('com.portbase.bezoekschip.common.api.visit.RefreshVisitSummary', {
      crn: VisitContext.visit.crn
    }, () => {
      AppContext.registerSuccess('Visit summary has been rebuild successfully.');
    });
  }

  removeAtdPort() {
    sendCommand('com.portbase.bezoekschip.common.api.visit.RemoveAtdPort', <RemoveAtdPort>{
      crn: VisitContext.visit.crn
    }, () => {
      VisitContext.visit.visitDeclaration.portVisit.atdPort = null;
      VisitContext.replaceVisit(VisitContext.visit);
      AppContext.registerSuccess('ATD Port successfully removed.');
    })
  }
}
