import {Component, Input, OnInit} from '@angular/core';
import {BerthVisit, RequiredQuantity, TugboatService} from '@portbase/bezoekschip-service-typescriptmodels';
import {VisitContext} from '../../visit-context';
import {ShipMovementService} from '../ship-movement.service';

@Component({
  selector: 'app-tugboats-summary',
  templateUrl: './tugboats-summary.component.html',
  styleUrls: ['./tugboats-summary.component.css']
})
export class TugboatsSummaryComponent implements OnInit {

  context = VisitContext;

  /**
   * Optional field. Not applicable for first movement (i.e. inbound movement to the first berth).
   */
  @Input() currentBerth: BerthVisit;

  /**
   * True if tugboats should be displayed for the next berth, false for the current berth (departure tugboats).
   */
  @Input() atArrival: boolean;

  @Input() title: string;

  constructor(private shipMovementService: ShipMovementService) { }

  ngOnInit() {
  }

  private nextBerth(): BerthVisit {
    if (this.currentBerth) {
      return this.shipMovementService.nextBerth(this.currentBerth);
    } else {
      return this.context.visit.visitDeclaration.portVisit.berthVisits[0];
    }
  }

  get service(): TugboatService {
    if (this.atArrival) {
      let nextBerth = this.nextBerth();
      if (nextBerth) {
        return nextBerth.tugboatAtArrival;
      }
    } else {
      if (this.currentBerth) {
        return this.currentBerth.tugboatAtDeparture;
      }
    }
  }

  get serviceRequired(): boolean {
    if (this.atArrival) {
      let nextBerth = this.nextBerth();
      return nextBerth && nextBerth.tugboatAtArrival ? nextBerth.tugboatAtArrival.required : false;
    } else {
      return this.currentBerth && this.currentBerth.tugboatAtDeparture ? this.currentBerth.tugboatAtDeparture.required : false;
    }
  }

  formatQuantity(value: RequiredQuantity): string {
    if (value) {
      switch (value) {
        case "PILOT_DETERMINES":
          return "L.A.B.";
        case "ONE":
          return "1";
        case "TWO":
          return "2";
        case "THREE":
          return "3";
        case "FOUR":
          return "4";
        case "FIVE":
          return "5";
        case "SIX":
          return "6";
        case "SEVEN":
          return "7";
        case "EIGHT":
          return "8";
        case "NINE":
          return "9";
        default:
          throw Error('Unknown RequiredQuantity value: ' + value);
      }
    }
  }
}
