import {Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  CancelWasteCollection,
  CollectedItem,
  CollectorSummary,
  InputCompleteWasteCollection,
  InputSubmitFinancials,
  WasteCollection,
  WasteCollector,
  WasteMaterial,
  WasteTypeSsn
} from '@portbase/bezoekschip-service-typescriptmodels';
import {WasteCollectorUtils} from "../waste-utils";
import {WasteService} from "../waste.service";
import {checkValidity, cloneObject, sendCommand} from "../../common/utils";
import {AppContext} from "../../app-context";

@Component({
  selector: 'app-waste-collection',
  templateUrl: './waste-collection.component.html',
  styleUrls: ['./waste-collection.component.css']
})
export class WasteCollectionComponent implements OnInit {
  appContext = AppContext;

  @Input() collection: WasteCollection;
  @Input() summary: CollectorSummary;
  @Input() collector: WasteCollector;

  utils = WasteCollectorUtils;

  startTime: string;
  endTime: string;
  fixedCosts: number;
  savedCollection: WasteCollection;
  allowRollback: boolean;

  @Output('collectionCompleted')
  collectionCompleted = new EventEmitter();

  constructor(private element: ElementRef, private wasteService: WasteService) {
  }

  ngOnInit() {
    this.prefillInputfields();
  }

  prefillInputfields() {
    this.resetStatusFields();
    this.collection.items.forEach(item => {
      item.specification = item.specification ? item.specification : this.getSpecificationFromAgent(item)
    });
  }

  private resetStatusFields() {
    this.savedCollection = cloneObject(this.collection);
    this.startTime = this.collection.startTime;
    this.endTime = this.collection.endTime;
    this.fixedCosts = this.collection.fixedCosts;
    this.allowRollback = false;
  }

  materialEquals(material: WasteMaterial, other: WasteMaterial): boolean {
    return material != null && other != null && material.typeCode === other.typeCode;
  }

  ssnEquals(ssn: WasteTypeSsn, other: WasteTypeSsn): boolean {
    return ssn != null && other != null && ssn.code === other.code;
  }

  addWasteItem(collection: WasteCollection) {
    collection.items.push(<CollectedItem>{wasteMaterial: {}});
  }

  deleteItem(list: any[], idx: number) {
    list.splice(idx, 1);
  }

  wasteOptions(ssn: WasteTypeSsn): WasteMaterial[] {
    return this.wasteService.getWasteMaterialsBySsn(ssn);
  }

  wasteTypeOptions() :WasteTypeSsn[] {
    return this.utils.wasteDisplayItems.map(value => value.ssn);
  }

  formatBerth = (id) => {
    const berthVisit = this.summary.berthVisits.filter(v => v.id === id)[0];
    return berthVisit && berthVisit.name;
  };

  getSpecificationFromAgent(item: CollectedItem) {
    let expectedItem = this.summary.expectedItems.filter(value => value.ssn != null && value.ssn.code === item.wasteMaterial.ssn?.code)[0];
    return expectedItem && expectedItem.specification;
  }

  invalidTimes(collection: WasteCollection): boolean {
    return !this.utils.isBefore(collection.startTime, collection.endTime ? collection.endTime : this.endTime);
  }

  alreadyUsed(fileNumber: string): boolean {
    return this.summary.collections.filter(value => value.fileNumber === fileNumber).length !== 1;
  }

  cancelCollection(id: string) {
    this.allowRollback = false;
    sendCommand('com.portbase.bezoekschip.common.api.waste.collector.CancelWasteCollection', <CancelWasteCollection>{
      crn: this.summary.crn,
      collectorShortName: this.collection.collectorShortName,
      collectionId: id
    }, () => {
      this.summary.collections.splice(this.summary.collections.indexOf(this.summary.collections.filter(value => value.id === id)[0]), 1);
      AppContext.registerSuccess('Cancelled waste Collection.');
    });
  }

  completeCollection(collection: WasteCollection) {
    const result = checkValidity(this.element);
    if (!result) {
      return;
    }
    collection.endTime = this.endTime;
    collection.startTime = this.startTime;
    collection.fixedCosts = null;
    sendCommand('com.portbase.bezoekschip.common.api.waste.collector.input.InputCompleteWasteCollection', <InputCompleteWasteCollection>{
      crn: this.summary.crn,
      imo: this.summary.vesselImoCode,
      timestamp: new Date().toISOString(),
      collection: WasteCollectorUtils.mapWasteCollection(this.collection, this.collector),
    }, () => {
      let indexOfCollection = this.summary.collections.findIndex(value => value.id === collection.id);
      this.summary.collections[indexOfCollection] = collection;
      AppContext.registerSuccess('Completed waste collection.');
      this.resetStatusFields();
      this.collectionCompleted.emit();
    });
  }

  submitFinancials(collection: WasteCollection) {
    const result = checkValidity(this.element);
    if (!result) {
      return;
    }
    collection.startTime = this.startTime;
    collection.endTime = this.endTime;
    collection.fixedCosts = this.fixedCosts;
    sendCommand('com.portbase.bezoekschip.common.api.waste.collector.input.InputSubmitFinancials', <InputSubmitFinancials>{
      crn: this.summary.crn,
      imo: this.summary.vesselImoCode,
      // collectorShortName: this.collector.organisation.shortName,
      timestamp: new Date().toISOString(),
      collection: WasteCollectorUtils.mapWasteCollection(this.collection, this.collector),
    }, () => {
      let indexOfCollection = this.summary.collections.findIndex(value => value.id === collection.id);
      this.summary.collections[indexOfCollection] = collection;
      AppContext.registerSuccess('Submitted financials');
      this.resetStatusFields();
    });

  }

  getToBeCollectedAmount(item: CollectedItem) {
    const expected = this.summary.expectedItems.filter(expectedItem => {
      if (expectedItem == null || item.wasteMaterial.ssn == null) {
        return false;
      }
      return expectedItem.ssn.code == item.wasteMaterial.ssn.code
    });
    return (Array.isArray(expected) && expected.length) ? expected[0].quantityToBeCollected : 0;
  }

  registrationPhase = () => !this.collection.endTime;
  completionPhase = () => this.collection.endTime && !this.collection.fixedCosts;
  wasteRegistered = () => !!this.collection.startTime;
  wasteCompleted = () => !!this.collection.endTime;
  wasteFinancialsSubmitted = () => !!this.collection.endTime && !!this.collection.fixedCosts;

  revertFinancials = (id: string) => {
    this.fixedCosts = this.collection.fixedCosts;
    this.collection.fixedCosts = null;
    this.allowRollback = true;
  };

  revertCompletion = (id: string) => {
    this.endTime = this.collection.endTime;
    this.collection.endTime = null;
    this.collection.fixedCosts = null;
    this.allowRollback = true;
  };

  undo = () => {
    this.collection = cloneObject(this.savedCollection);
    this.fixedCosts = this.savedCollection.fixedCosts;
    this.endTime = this.savedCollection.endTime;
    this.allowRollback = false;
  };
}
