import {Component, Input} from '@angular/core';
import {Port, Terminal} from '@portbase/bezoekschip-service-typescriptmodels';
import {PortvisitUtils} from '../../../../refdata/portvisit-utils';
import {VisitContext} from '../../../visit-context';
import {lodash} from '../../../../common/utils';
import {ConsignmentModel, Manifest} from '../../cargo-import.model';
import {AppContext} from '../../../../app-context';

@Component({
  selector: 'app-consignment-details',
  templateUrl: './consignment-details.component.html',
  styleUrls: ['./consignment-details.component.css']
})
export class ConsignmentDetailsComponent {
  context = VisitContext;
  refData = PortvisitUtils;
  appContext = AppContext;

  @Input() manifest: Manifest;
  @Input() consignment: ConsignmentModel;

  getPreviousPorts() : Port[] {
    return lodash.uniqBy(VisitContext.savedVisit.visitDeclaration.previousPorts.map(p => p.port), p => p.locationUnCode);
  }

  getDischargePorts() : Port[] {
    return lodash.uniqBy([VisitContext.savedVisit.portOfCall.port].concat(VisitContext.savedVisit.visitDeclaration.nextPorts.map(p => p.port)), p => p.locationUnCode);
  }

  setTerminal(terminal: Terminal) {
    const containers = lodash.flatMap(this.consignment.goodsItems, g => g.placements)
      .map(p => p.equipmentNumber).filter(p => !!p);
    this.manifest.containers
      .filter(c => containers.indexOf(c.number) >= 0)
      .forEach(c => c.terminal = terminal);
    this.manifest.consignments
      .filter(c => c.goodsItems.find(g => g.placements.some(p => containers.indexOf(p.equipmentNumber) >= 0)))
      .forEach(c => c.terminal = terminal);
  }
}
