import {DeclarationStatus, TaskMessageStatus} from "@portbase/bezoekschip-service-typescriptmodels";

export class NewPortvisitUtils {

  static declarationStatusToTaskMessageStatus = (status: DeclarationStatus): TaskMessageStatus => {
    switch (status) {
      case DeclarationStatus.DECLARED:
        return TaskMessageStatus.PENDING;
      case DeclarationStatus.ACCEPTED:
        return TaskMessageStatus.ACCEPTED;
      case DeclarationStatus.REJECTED:
        return TaskMessageStatus.REJECTED;
    }
  }
}
