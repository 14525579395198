import {Component, Input} from '@angular/core';
import {CommercialRelease, ResubmitCommercialRelease} from "@portbase/bezoekschip-service-typescriptmodels";
import {PortvisitUtils} from "../../../refdata/portvisit-utils";
import {sendCommand} from "../../../common/utils";
import {AppContext} from "../../../app-context";

@Component({
  selector: 'app-commercial-release-admin',
  templateUrl: './commercial-release-admin.component.html',
  styleUrls: ['./commercial-release-admin.component.css']
})
export class CommercialReleaseAdminComponent {
  @Input() declaration: CommercialRelease;
  utils = PortvisitUtils;
  appContext = AppContext;

  acceptRelease() {
    sendCommand('com.portbase.bezoekschip.common.api.commercialrelease.AcceptCommercialRelease',
      {id: this.declaration.id},
      () => AppContext.registerSuccess('The admin command was sent successfully.'));
  }

  resubmit() {
    sendCommand("com.portbase.bezoekschip.common.api.commercialrelease.ResubmitCommercialRelease", <ResubmitCommercialRelease>{id: this.declaration.id},
      () => AppContext.registerSuccess('The release has been resubmitted.'))
  }
}
