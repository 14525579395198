<div class="mt-5" [ngSwitch]="dangerousGood.type" *ngIf="dangerousGood">
  <!--Container-->
  <div *ngSwitchCase="'containerGood'">
    <app-edit-container-good [containerGood]="dangerousGood"></app-edit-container-good>
  </div>

  <!--SolidData-->
  <div *ngSwitchCase="'solid'">
    <app-edit-solid-good [solid]="dangerousGood"></app-edit-solid-good>
  </div>

  <!--Gas-->
  <div *ngSwitchCase="'gas'">
    <app-edit-gas [gas]="dangerousGood"></app-edit-gas>
  </div>

  <!--Oil-->
  <div *ngSwitchCase="'oil'">
    <app-edit-oil [oil]="dangerousGood"></app-edit-oil>
  </div>

  <!--Chemical-->
  <div *ngSwitchCase="'chemical'">
    <app-edit-chemical [chemical]="dangerousGood"></app-edit-chemical>
  </div>
</div>
