import {Component, Input} from '@angular/core';
import {GasData} from "@portbase/bezoekschip-service-typescriptmodels";

@Component({
  selector: 'app-edit-gas',
  templateUrl: './edit-gas.component.html',
  styleUrls: ['./edit-gas.component.css']
})
export class EditGasComponent {
  @Input() gas: GasData;
}
