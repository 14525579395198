import {ZoomPluginOptions} from "chartjs-plugin-zoom/types/options";
import {Chart, ChartData, TooltipOptions} from "chart.js";
import {DateTimeRange} from "@portbase/bezoekschip-service-typescriptmodels";
import moment from "moment/moment";
import {ServiceMonitoringContext} from "./service-monitoring-context";
import {AfterViewInit, Component, Input, OnChanges, SimpleChange, SimpleChanges, ViewChild} from "@angular/core";
import {BaseChartDirective} from "ng2-charts";
import {takeUntil} from "rxjs/operators";
import lodash, {isArray} from "lodash";

@Component({
  template: ''
})
export abstract class ServiceMonitoringBaseChart implements AfterViewInit, OnChanges {
  @ViewChild(BaseChartDirective) chartDirective: BaseChartDirective;
  @Input() data: ChartData;
  @Input() chartTitle: string = "";
  @Input() totalCount: number;

  private currentDateTimeRange: DateTimeRange;

  updateChart() {
    this.chartDirective.data = this.data;
    this.chartDirective.chart.update();
  }

  ngAfterViewInit(): void {
    ServiceMonitoringContext.resetZoom.pipe(takeUntil(ServiceMonitoringContext.destroyNotifier))
      .subscribe(() => this.chartDirective.chart.resetZoom("reset"));
    ServiceMonitoringContext.zoomSubject.pipe(takeUntil(ServiceMonitoringContext.destroyNotifier))
      .subscribe(zoom => this.chartDirective.chart.zoom(zoom, "reset"));
    ServiceMonitoringContext.dateTimeRange.pipe(takeUntil(ServiceMonitoringContext.destroyNotifier))
      .subscribe(d => this.currentDateTimeRange = d);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const data = changes["data"];
    const dateRange = changes["dateRange"];
    if (this.chartDirective && (this.hasChanged(data) || this.hasChanged(dateRange))) {
      this.updateChart();
    }
  }

  private hasChanged(changes: SimpleChange): boolean {
    return changes && !lodash.isEqual(changes.previousValue, changes.currentValue);
  }

  get isZoomed(): boolean {
    return this.chartDirective?.chart?.isZoomedOrPanned();
  }

  zoomOptions: ZoomPluginOptions = {
    zoom: {
      wheel: {
        enabled: false
      },
      drag: {
        enabled: true
      },
      mode: 'x',
      onZoomComplete: (context: { chart: Chart }) => {
        let dateRange: DateTimeRange = this.currentDateTimeRange;
        if (this.isZoomed) {
          const ticks = context.chart.scales["x"].ticks;
          dateRange = {
            start: moment(ticks[0].value).toISOString(),
            end: moment(ticks[ticks.length - 1].value).toISOString()
          }
          ServiceMonitoringContext.zoomSubject.next(context.chart.getZoomLevel());
        }
        ServiceMonitoringContext.dateTimeRange.next(dateRange);
      }
    }
  };

  tooltipOptions = {
    mode: "index",
    intersect: false,
    enabled: true,
    multiKeyBackground: "rgb(0,0,0,0)",
    callbacks: {
      labelColor(t) {
        const color: string = isArray(t.dataset.backgroundColor)
          ? t.dataset.backgroundColor[t.dataIndex]
          : t.dataset.backgroundColor;
        return {
          borderColor: "rgb(0,0,0,0)",
          backgroundColor: color
        };
      }
    }
  } as TooltipOptions;
}
