import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Container, GoodUnion, StowageUnion, Tank, TankStatus} from '@portbase/bezoekschip-service-typescriptmodels';
import {VisitContext} from '../../visit-context';
import {
	cloneObject,
	dispatchChangeEvent,
	lodash,
	removeIf,
	removeItem,
	scrollToTop,
	uniqueItems
} from '../../../common/utils';
import {v4 as uuid} from 'uuid';
import {
	BlendingModel,
	ContainerModel,
	goodComparator,
	LoadingModel,
	sortStowage,
	StowageModelUnion,
	TankModel
} from '../dangerous-goods.model';
import {AppContext} from '../../../app-context';
import {uploadDangerousGoods} from '../dangerous-goods.upload';
import {PortvisitUtils} from '../../../refdata/portvisit-utils';

@Component({
  selector: 'app-stowages',
  templateUrl: './stowages.component.html',
  styleUrls: ['./stowages.component.scss'],
})
export class StowagesComponent implements AfterViewInit, OnInit {

  types: ('tank' | 'hold' | 'container' | 'breakBulk' | 'trailer')[] = ['tank', 'hold', 'container', 'trailer', 'breakBulk'];

  removeItem = removeItem;
  refData = PortvisitUtils;
  context = VisitContext;

  dangerousGoods = VisitContext.dangerousGoodsDeclaration.dangerousGoods;

  id = uuid();
  @Input() loading?: LoadingModel | BlendingModel;

  @Input() readonly;
  stowages: StowageModelUnion[];
  page: number = 1;
  maxPerPage: number = 20;

  lastAddedStowage: StowageModelUnion;

  @Input() set stowageArray(value: StowageModelUnion[]) {
    this.stowages = value;
    if (!this.lastAddedStowage && value.length > 0) {
      this.lastAddedStowage = value[value.length - 1];
    }
  }

  @Output() upload = new EventEmitter<File>();

  constructor(private elementRef: ElementRef) {
  }

  ngOnInit(): void {
    if (this.readonly === '') {
      this.readonly = true;
    }
    if (this.loading) {
      removeIf(this.types, type =>
        type === 'tank' && !this.dangerousGoods.hasTanks && VisitContext.canEditFirstBerthVisit() && !VisitContext.isOrganisationNextDeclarant());
    }
  }

  //if this only contains one stowage with unknown good data then automatically start editing the stowage
  ngAfterViewInit(): void {
    if (this.stowages.length === 1 && VisitContext.dangerousGoodsDeclaration.dangerousGoods.goods.find(
      g => g.id === this.stowages[0].goodId && !g.goodData)) {
      $('#stowage-collapse' + this.id + 0).collapse('show');
    }
  }

  stowageById(index: number, s: StowageModelUnion) {
    return index;
  }

  getGoodIds = (stowage: StowageModelUnion): string[] => {
    let goods : GoodUnion[] = (stowage.type === 'container') ? StowagesComponent.stowageItem(stowage)
      : uniqueItems(this.stowages.filter(s => s.type === stowage.type && s.goodId).map(s => s.good));
    goods = goods.filter(value => value && value.goodData);
    if (goods.length === 0) {
      stowage['newGood'] = true;
      return [];
    }
    goods.sort(goodComparator);
    const result = goods.map(g => g.id);
    result.splice(0, 0, '');
    return result;
  };

  private static stowageItem(stowage: StowageModelUnion) {
    return !!stowage.goodId ? [stowage.good] : [];
  }

  goodFormatter = id => {
    const good = VisitContext.dangerousGoodsDeclaration.dangerousGoods.goods.find(g => id === g.id);
    if (!good) {
      return 'New good';
    }
    const name = good['uniqueGoodName'];
    return name || 'Select good';
  };

  positionFormatter = (stowageNumber: string) => {
    if (!stowageNumber) {
      return 'New stowage';
    }
    return stowageNumber || 'Select stowage';
  };

  addContainerItem = (currentContainer: ContainerModel) => {
    this.addStowage('container', currentContainer);
  };

  addStowage = (type: 'tank' | 'hold' | 'container' | 'breakBulk' | 'trailer', previous?: StowageModelUnion) => {
    if (!previous && type !== 'container') {
      previous = this.stowages.slice().reverse().find(s => s.type === type);
    }
    let stowage: StowageModelUnion;
    if (previous) {
      stowage = cloneObject(previous);
      stowage.weight = stowage.emptyTank ? 0 : undefined;
      if (stowage.type) {
        switch (stowage.type) {
          case 'hold':
          case 'tank':
            stowage.stowageNumber = undefined;
            break;
          case 'breakBulk':
            stowage.stowageNumber = undefined;
            stowage.goodId = undefined;
            stowage['newGood'] = true;
            break;
          case 'container':
            stowage.goodId = undefined;
            stowage['newGood'] = true;
            this.wipeOutContainerDetails(stowage, false);
            break;
        }
      }
    } else {
      let isTrailer = type === 'trailer';
      if (type === 'trailer') {
        type = 'container';
      }
      stowage = <StowageModelUnion>{type: type};
      if (stowage.type === 'container') {
        stowage['newContainerNumber'] = true;
        stowage.trailer = isTrailer;
        stowage.uncleanTankContainer = false;
      }
      if (stowage.type === 'hold') {
        stowage.fumigated = false;
      }
      if (stowage.type === 'container' || stowage.type === 'breakBulk') {
        stowage.transportInLimitedQuantity = false;
      }
      if (this.loading) {
        stowage.portOfLoading = VisitContext.visit.portOfCall.port;
      }
    }

    this.stowages.push(stowage);
    sortStowage(this.stowages);

    const itemIndex = this.stowages.indexOf(stowage);
    this.page = 1 + (itemIndex / this.maxPerPage);

    this.lastAddedStowage = stowage;
    dispatchChangeEvent(this.elementRef.nativeElement);

    //focus on stowage number
    setTimeout(() => {
      if (!this.loading) {
        const elements = this.elementRef.nativeElement.querySelectorAll(".stowageRow .stowageNumber");
        if (elements && elements.length > itemIndex) {
          elements[itemIndex].focus(<FocusOptions>{preventScroll: false})
        }
      }
    }, 0);
  };

  deleteStowage = (stowage: StowageModelUnion) => {
    removeItem(this.stowages, stowage);
    dispatchChangeEvent(this.elementRef.nativeElement);
  };

  onNewGoodFound = (stowage: StowageModelUnion) => {
    const goodId = stowage.goodId || uuid();
    let selectedGood = stowage['goodData'];
    if (!selectedGood) {
      return;
    }
    let type;
    switch (stowage.type) {
      case 'container':
      case 'breakBulk':
        type = 'containerGood';
        break;
      case 'hold':
        type = 'solid';
        break;
      case 'tank':
        type = selectedGood['type'];
        break;
    }
    const isNew = !stowage.good;
    let newGood = stowage.good || <GoodUnion>{id: goodId, type: type};
    newGood.type = type;
    if (newGood.type === 'containerGood') {
      newGood.radioactive = selectedGood.hazardClass === '7';
      if (newGood.radioactive) {
        newGood.radioactivity = <any>{};
      }
    }
    newGood.goodData = selectedGood;
    if (isNew) {
      VisitContext.dangerousGoodsDeclaration.dangerousGoods.goods.push(newGood)
    }
    stowage.goodId = goodId;
    delete stowage['goodData'];
    delete stowage['newGood'];
    setTimeout(() => dispatchChangeEvent(this.elementRef.nativeElement), 0);
  };

  onGoodSelected = (stowage: StowageModelUnion, row: HTMLElement) => {
    if (stowage['newGood']) {
      //set focus on input element for new good search
      setTimeout(() => {
        (<HTMLInputElement>row.querySelector('.find-new-good input')).focus()
      }, 0);
    } else {
      const existing = this.stowages.find(s => s.goodId === stowage.goodId && s !== stowage);
      if (existing) {
        stowage.type = existing.type;
        stowage.portOfLoading = existing.portOfLoading;
        stowage.portOfDischarge = existing.portOfDischarge;
        if (stowage.type === "hold" && existing.type === "hold") {
          stowage.fumigated = existing.fumigated;
          stowage.fumigation = existing.fumigation;
        }
        return;
      }
    }
    if (stowage.type === "hold") {
      stowage.fumigated = false;
      stowage.fumigation = undefined;
    }
  };

  //Container stuff

  getContainerNumbers = (trailer: boolean): string[] => {
    const result = uniqueItems(this.stowages.filter(s => s.type === 'container' && !s.uncleanTankContainer && s.trailer === trailer && s.stowageNumber)
      .map(s => (<Container>s).stowageNumber)).sort();
    result.splice(0, 0, '');
    return result;
  };

  containerNumberFormatter = containerNumber => containerNumber === '' ? 'New container' : containerNumber;
  trailerNumberFormatter = containerNumber => containerNumber === '' ? 'New trailer' : containerNumber;

  onContainerNumberSelected = (container: Container, stowageRow: HTMLDivElement) => {
    if (container.stowageNumber === null) {
      container.position = undefined;
      //set focus on input element for new container number
      setTimeout(() => (<HTMLInputElement>stowageRow.querySelector('.new-container')).focus(), 0);
    } else {
      //copy container details over to stowage
      const stowageWithSameNumber = <Container>this.stowages.find(s => s !== container && (<Container>s).stowageNumber === container.stowageNumber);
      container.position = stowageWithSameNumber.position;
      container.portOfLoading = stowageWithSameNumber.portOfLoading;
      container.portOfDischarge = stowageWithSameNumber.portOfDischarge;
      sortStowage(this.stowages);
    }
  };

  //Tank stuff

  onTankStatusSelected = (tank: Tank) => {
    switch (tank.tankStatus) {
      case 'EMPTY':
      case 'EMPTY_NOT_GAS_FREE':
      case 'EMPTY_INERT':
        tank.goodId = undefined;
        tank.weight = 0;
        tank.portOfLoading = undefined;
        tank.portOfDischarge = undefined;
        break;
      case 'RESIDUE_INERT':
      case 'RESIDUE':
        tank.weight = 0;
        tank.portOfLoading = undefined;
        tank.portOfDischarge = undefined;
    }
    tank.previousGood = undefined;
  };

  onStowageSelected = (stowage: StowageModelUnion) => {
    if (stowage && this.loading) {
      const beforeHandling = this.loading.stowageBefore.find(s => s.stowageNumber === stowage.stowageNumber && s.type === stowage.type);
      if (beforeHandling && !beforeHandling.emptyTank) {
        if (stowage.type === 'tank') {
          stowage.tankStatus = TankStatus.NOT_EMPTY;
        }
        if (this.loading.type !== 'blending') {
          stowage.goodId = beforeHandling.goodId;
          delete stowage['newGood'];
        }
        stowage.portOfLoading = beforeHandling.portOfLoading ? beforeHandling.portOfLoading : VisitContext.visit.portOfCall.port;
        stowage.portOfDischarge = beforeHandling.portOfDischarge;
      }
    }
  };

  renderType = (type: 'tank' | 'hold' | 'container' | 'breakBulk' | 'trailer'): string => {
    switch (type) {
      case 'tank':
        return "Tank";
      case 'hold':
        return "Hold";
      case 'container':
        return "Container";
      case 'trailer':
        return "Trailer";
      case 'breakBulk':
        return "Break bulk";
    }
  };

  onContainerChanged = (container: Container) => {
    this.stowages.forEach(s => {
      if (s !== container && s.type === 'container' && s.stowageNumber === container.stowageNumber) {
        const other = <Container>s;
        other.portOfDischarge = container.portOfDischarge;
        other.portOfLoading = container.portOfLoading;
        other.position = container.position;
      }
    });
  };

  onNewContainerNumberCreated = (stowage: Container, value: string) => {
    stowage.stowageNumber = value;
    const stowageWithSameNumber = <Container>this.stowages.find(s => s !== stowage && (<Container>s).stowageNumber === stowage.stowageNumber);
    if (stowageWithSameNumber) {
      stowage.position = stowageWithSameNumber.position;
      stowage.portOfLoading = stowageWithSameNumber.portOfLoading;
      stowage.portOfDischarge = stowageWithSameNumber.portOfDischarge;
    }
    sortStowage(this.stowages);
  };

  isContainerItem = (stowage: StowageModelUnion): boolean => {
    return this.hideContainerDetails(stowage);
  };

  hideContainerDetails = (stowage: StowageModelUnion): boolean => {
    if (stowage.type === 'container') {
      const index = this.stowages.indexOf(stowage);
      if (index > 0) {
        const previous = this.stowages[index - 1];
        return previous.type === 'container' && previous.stowageNumber !== undefined && previous.stowageNumber === stowage.stowageNumber;
      }
    }
    return false;
  };

  onUpload = (file: File) => {
    if (!this.loading) {
      this.upload.emit(file);
      return;
    }
    AppContext.clearAlerts();
    uploadDangerousGoods(file).subscribe(dangerousGoods => {
      this.loading.stowages = [];

      padTankNumbers(dangerousGoods.stowageAtArrival);
      const stowage = dangerousGoods.stowageAtArrival.filter(s => {
        if (!s.portOfLoading) {
          s.portOfLoading = VisitContext.visit.portOfCall.port;
        }
        if (s.portOfLoading.locationUnCode !== VisitContext.visit.portOfCall.port.locationUnCode) {
          return false;
        }
        if (!s.weight || s.weight < 0) {
          return false;
        }
        if (s.type === 'tank') {
          if (s.tankStatus === 'NOT_EMPTY') {
            if (this.loading.availableTanks.indexOf(s.stowageNumber) < 0) {
              AppContext.registerError("Tank " + s.stowageNumber + " is not available for loading");
              return false;
            }
            const existing = this.loading.stowageBefore.find(e => e.type === s.type
              && e.stowageNumber === s.stowageNumber);
            if (existing.weight > 0) {
              AppContext.registerError("Tank " + s.stowageNumber + " is not empty");
              return false;
            }
            return true;
          }
          return false;
        }
        return true;
      });

      if (!AppContext.hasErrors() && stowage.length === 0) {
        AppContext.registerError("The upload contains no items available for loading. Please check your sheet.");
      }

      if (!AppContext.hasErrors()) {
        this.loading.stowages = <any>stowage;
        let uniqueIds = uniqueItems(stowage.map(s => s.goodId));
        uniqueIds.filter(id => id
          && !VisitContext.dangerousGoodsDeclaration.dangerousGoods.goods.find(g => g.id === id))
          .forEach(id => VisitContext.dangerousGoodsDeclaration.dangerousGoods.goods.push(
            dangerousGoods.goods.find(g => g.id === id)));
      } else {
        scrollToTop();
      }
      dispatchChangeEvent(this.elementRef.nativeElement);
    });

    function padTankNumbers(stowage: StowageUnion[]) {
      stowage.forEach(s => {
        if (s.type === 'tank' && s.stowageNumber) {
          s.stowageNumber = pad(s.stowageNumber.toString().toUpperCase());
        }
      });

      function pad(val): string {
        let offset = Math.min(2, positionOfFirstNonDigit(val));
        let numericPart = val.substr(0, offset);
        return ('00' + numericPart).substr(-2) + val.substr(offset);
      }

      function positionOfFirstNonDigit(val) {
        let splitted = lodash.split(val, '');
        for (let i = 0; i < val.length; ++i) {
          if (isNaN(Number(splitted[i]))) {
            return i;
          }
        }
        return val.length;
      }
    }
  };

  findDangerousGood = (stowage) => {
    switch (stowage.type) {
      case 'tank':
        return PortvisitUtils.findTankGood;
      case 'container':
      case 'breakBulk':
        return PortvisitUtils.findContainerGood;
      case 'hold':
        return PortvisitUtils.findHoldGood;
      default:
        throw Error('Unknown stowage type: ' + stowage.type);
    }
  };

  toggleTankContainer = (stowage) => {
    if (stowage.uncleanTankContainer) {
      this.containerItemsOf(stowage).forEach(toRemove => removeItem(this.stowages, toRemove));
    }
    this.wipeOutContainerDetails(stowage, stowage.uncleanTankContainer);
  };

  private wipeOutContainerDetails(containerStowage: ContainerModel, uncleanTankContainer) {
    containerStowage.uncleanTankContainer = uncleanTankContainer;
    containerStowage.weight = uncleanTankContainer ? 0 : null;
    containerStowage.numberOfOuterPackages = null;
    containerStowage.outerPackageType = null;
    containerStowage.netExplosiveMass = null;
    containerStowage.netWeight = null;
    containerStowage.transportInLimitedQuantity = false;
    containerStowage.numberOfInnerPackages = null;
    containerStowage.innerPackageType = null;
  }

  private containerItemsOf(stowage) {
    const currentStowageIndex = this.stowages.indexOf(stowage);
    const containerItems: StowageModelUnion[] = [];
    for (let i = currentStowageIndex + 1; i < this.stowages.length; i++) {
      const nextStowage = this.stowages[i];
      if (nextStowage.type === stowage.type && nextStowage.stowageNumber === stowage.stowageNumber) {
        containerItems.push(nextStowage);
        continue;
      }
      break;
    }
    return containerItems;
  }

  isEmptyTank(stowage: TankModel) {
    if (stowage.type !== 'tank') {
      return false;
    }
    if (!stowage.tankStatus) {
      return false;
    }
    switch (stowage.tankStatus) {
      case 'EMPTY':
      case 'EMPTY_INERT':
        return true;
      default:
        return false;
    }
  }
}
