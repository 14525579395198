import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {
  BerthVisit,
  Bollards,
  GetBollardsOfTerminal,
  GetTerminalsOfOrganisation,
  RegisterTerminalPlanningData,
  RegisterTerminalPlanningUsingId,
  Terminal,
  TerminalInfo,
  TerminalMooring,
  TerminalVisit
} from "@portbase/bezoekschip-service-typescriptmodels";
import {Observable, of} from "rxjs";
import {VisitContext} from "../../../../../visit-context";
import {AppContext} from "../../../../../../app-context";
import {checkValidity, sendCommand, sendQuery} from "../../../../../../common/utils";

@Component({
  selector: 'app-register-terminal-planning',
  templateUrl: './register-terminal-planning.component.html',
  styleUrls: ['./register-terminal-planning.component.css']
})
export class RegisterTerminalPlanningComponent implements OnInit {
  context = VisitContext;
  appContext = AppContext;

  @Input() berthVisit: BerthVisit;
  data: RegisterTerminalPlanningData;

  eta: string;
  etd: string;
  discharge: number;
  load: number;
  restow: number;
  bollardsInfo: Bollards;

  constructor(private element: ElementRef) {
  }

  ngOnInit(): void {
    const terminalVisit: TerminalVisit = this.context.getTerminalVisit(this.berthVisit);
    this.data = <RegisterTerminalPlanningData>{
      berthVisitId: this.berthVisit.id,
      terminal: this.berthVisit.terminal,
      terminalInfo: terminalVisit?.info ?? <TerminalInfo>{}
    }
    this.eta = this.data.terminalInfo.rta ?? this.berthVisit.eta;
    this.etd = this.data.terminalInfo.etc ?? this.berthVisit.etd;
    this.load = this.data.terminalInfo.expectedLoad ?? this.berthVisit.load;
    this.discharge = this.data.terminalInfo.expectedDischarge ?? this.berthVisit.discharge;
    this.restow = this.data.terminalInfo.expectedRestow ?? this.berthVisit.restow;
  }

  moorings: TerminalMooring[] = [TerminalMooring.PORTSIDE, TerminalMooring.STARBOARD];
  mooringFormatter = (value: TerminalMooring) => {
    switch (value) {
      case TerminalMooring.PORTSIDE:
        return 'Port side';
      case TerminalMooring.STARBOARD:
        return 'Starboard side';
      default:
        throw Error('Unknown mooring value: ' + value);
    }
  };

  terminalNameFormatter = (terminal: Terminal) => terminal ? terminal.terminalCode + ' - ' + terminal.terminalName : '';

  getTerminalsProvider = (organisationShortName: string) => organisationShortName ?
    sendQuery('com.portbase.bezoekschip.common.api.visit.GetTerminalsOfOrganisation',
      <GetTerminalsOfOrganisation>{
        portUnCode: VisitContext.visit.portOfCall.port.locationUnCode,
        organisationShortName: organisationShortName
      }) : of([]);

  getBollardsOfTerminal = (terminalCode: string): Observable<Bollards> => terminalCode ?
    sendQuery('com.portbase.bezoekschip.common.api.visit.GetBollardsOfTerminal',
      <GetBollardsOfTerminal>{
        terminalCode: terminalCode
      }) : of([]);

  acceptPlanning() {
    if (checkValidity(this.element)) {
      this.data.terminalInfo.rta = this.eta;
      this.data.terminalInfo.etc = this.etd;
      this.data.terminalInfo.expectedLoad = this.load;
      this.data.terminalInfo.expectedDischarge = this.discharge;
      this.data.terminalInfo.expectedRestow = this.restow;
      sendCommand('com.portbase.bezoekschip.common.api.visit.RegisterTerminalPlanningUsingId',
        <RegisterTerminalPlanningUsingId>{
          id: this.context.getTerminalVisit(this.berthVisit).id,
          crn: this.context.visit.crn,
          callId: this.berthVisit.callId,
          data: this.data
        }, () => {
          VisitContext.replaceVisit(VisitContext.visit);
          AppContext.registerSuccess('The terminal planning was successfully registered.');
          $('#' + this.berthVisit.id + '-acceptPlanningModal').modal("hide");
        });
    }
  }

  updateBollardsAft($event: any) {
    this.data.terminalInfo.bollardAft = this.roundBollard($event.target.value);
  }

  updateBollardsFore($event: any) {
    this.data.terminalInfo.bollardFore = this.roundBollard($event.target.value);
  }

  roundBollard = (value: number): number => {
    if (value) {
      value = Math.round(2 * value) / 2;
    }
    return value;
  }

  onTerminalSelected(): void {
    this.bollardsInfo = null;
    if (this.data.terminal?.terminalCode) {
      this.getBollardsOfTerminal(this.data.terminal.terminalCode).subscribe(b => this.bollardsInfo = b);
    }
  }

  modalOpen(): boolean {
    return $('#' + this.berthVisit.id + '-acceptPlanningModal').is(":visible");
  }
}
