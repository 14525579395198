<app-form-group>
  <label>Entry point</label>
  <app-select [(ngModel)]="context.visit.visitDeclaration.portVisit.entryPoint" [optionsProvider]="entryPointProvider"
    [formatter]="entryPointFormatter" dataKey="name" placeholder="– Select entry point –" required
    [disabled]="isArrived()" id="entryPoint">
    <div class="invalid-feedback">Please select an entry point</div>
  </app-select>
</app-form-group>
<ng-container *ngIf="showEtaPort()" class="col">
  <div class="validatable">
    <app-form-group>
      <label for="portEta-card">ETA Port</label>
      <app-date-field [ngModel]="context.visit.visitDeclaration.portVisit.etaPort"
        (ngModelChange)="onEtaPortChanged($event)" [required]="!isWaitingForOrders()"
        [calendarStartDate]="context.visit.visitDeclaration.portVisit.etaPort" [disabled]="isArrived()"
        id="portEta-card"></app-date-field>
    </app-form-group>
  </div>
  <span *ngIf="validator.etaPortIsSameOrBeforePreviousPort()" class="ng-invalid invalid-feedback mb-2">Last previous port should
    be before eta port</span>
  <span *ngIf="validator.etaPortIsSameOrAfterFirstBerth() " class="ng-invalid invalid-feedback mb-2">First berth visit should be
    after eta port</span>
  <span *ngIf="validator.etaPortIsSameOrAfterEtdPort()" class="ng-invalid invalid-feedback mb-2">Eta port should be before etd
    port</span>
</ng-container>
