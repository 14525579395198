import {Component, Input} from '@angular/core';
import {AppContext} from "../../../../app-context";
import moment from "moment";
import {
  IE3ConsignmentProcessSummary,
  IE3ConsignmentStatus,
  IE3ControlNotification,
  IE3EnsStatus,
  IE3RefreshConsignmentProcessSummary,
  TaskMessageStatus
} from "@portbase/bezoekschip-service-typescriptmodels";
import lodash from "lodash";
import {CheckboxSelectionState, openEditModal, sendCommand} from "../../../../common/utils";
import {
  MasterConsignmentDetailsComponent,
  MasterConsignmentDetailsComponentData
} from "../../details/master-consignment-details/master-consignment-details.component";
import {ConsignmentUtils} from "../../consignment.utils";
import {DeclarationMessageStatus} from "../../../visit-overview/visit-overview-item/visit-overview.utils";
import {SentenceCasePipe} from "../../../../common/sentence-case.pipe";
import {PortvisitUtils} from "../../../../refdata/portvisit-utils";

@Component({
  selector: 'app-master-consignment-overview-item',
  templateUrl: './master-consignment-overview-item.component.html',
  styleUrls: ['./master-consignment-overview-item.component.scss']
})
export class MasterConsignmentOverviewItemComponent {
  utils = ConsignmentUtils;
  appContext = AppContext;

  @Input() term: string;
  @Input() depth: number;
  @Input() selectable: boolean;
  collapsible: boolean;

  selectionWidth: number = 40;
  paddingPerStep: number = 20;
  collapsed: boolean = true;

  protected _consignment: IE3ConsignmentProcessSummary;
  protected _ensStatus: DeclarationMessageStatus;
  protected _notificationOfArrivalStatus: DeclarationMessageStatus;
  protected _temporaryStorageStatus: DeclarationMessageStatus;
  protected _notificationOfArrivalStatuses: DeclarationMessageStatus[];
  protected _ensStatuses: DeclarationMessageStatus[] = [];
  protected _temporaryStorageStatuses: DeclarationMessageStatus[];

  formatDate = (dateString: string): string => !!dateString ? moment(dateString).format("DD MMM HH:mm") : null;

  @Input()
  set consignment(consignment: IE3ConsignmentProcessSummary) {
    this._consignment = consignment;
    this._ensStatus = this.getEnsStatus();
    this._notificationOfArrivalStatus = this.getNotificationOfArrivalStatus();
    this._notificationOfArrivalStatuses = [this._notificationOfArrivalStatus];
    this._ensStatuses = [this._ensStatus];
    this._temporaryStorageStatus = this.getTemporaryStorageStatus();
    this._temporaryStorageStatuses = [this._temporaryStorageStatus];
    this.collapsible = !!consignment.masterConsignment.equipments?.length || !!consignment.masterConsignment.goodsItems?.length
        || !!lodash.flatMap(consignment.masterConsignment.houseConsignments, h => h.goodsItems).length;
  }

  get indentionDepth() {
    return this.depth * this.paddingPerStep;
  }

  get terminalValue(): string {
    const time = this._consignment.ataPort || this._consignment.etaPort;
    const terminalName = SentenceCasePipe.format(this._consignment.masterConsignment.dischargeTerminal?.terminalName);
    return [time ? this.formatDate(time) : null, terminalName].filter(a => a).join(",");
  }

  get isVessel(): boolean {
    return !this._consignment.masterConsignment.vehicle || this._consignment.masterConsignment.vehicle.type === 'N10';
  }

  get vehicleValue(): string {
    return SentenceCasePipe.format(this._consignment.masterConsignment.vehicle?.name)
      || this._consignment.masterConsignment.vehicle?.identificationNumber || '';
  }

  get portOfLoading() {
    return this._consignment.masterConsignment.placeOfLoading
      ? `${this._consignment.masterConsignment.placeOfLoading.name} (${this._consignment.masterConsignment.placeOfLoading.countryUnCode})`
      : "";
  }

  get equipmentCount() {
    return lodash.sum([this._consignment.masterConsignment.equipments.length]);
  }

  get totalWeight() {
    return lodash.round(lodash.sum(this._consignment.masterConsignment.goodsItems.map(g => g.grossWeight)
      .concat(lodash.flatMap(this._consignment.masterConsignment.houseConsignments,
        h => h.goodsItems.map(g => g.grossWeight)))), 1);
  }

  get selectionState(): CheckboxSelectionState {
    const subItems = this.getSubItems();
    if ([CheckboxSelectionState.unselected, CheckboxSelectionState.selected].includes(this._consignment["selected"])) {
      subItems.forEach(s => s["selected"] = this._consignment["selected"]);
    }
    if (subItems.length > 0 && subItems.every(s => s["selected"] === CheckboxSelectionState.selected)) {
      return CheckboxSelectionState.selected;
    }
    if (subItems.length > 0 && subItems.some(s => [CheckboxSelectionState.selected, CheckboxSelectionState.indeterminate].includes(s["selected"]))) {
      return CheckboxSelectionState.indeterminate;
    }
    return CheckboxSelectionState.unselected;
  }

  set selectionState(value: CheckboxSelectionState) {
    this._consignment["selected"] = value;
    if (value === CheckboxSelectionState.selected || value === CheckboxSelectionState.unselected) {
      this.getSubItems().forEach(s => s["selected"] = value);
    }
  }

  editConsignment = () => openEditModal(MasterConsignmentDetailsComponent, <MasterConsignmentDetailsComponentData>{
    consignmentProcessId: this._consignment.consignmentProcessId,
  }, {
    warnOnClose: true,
    currentToStack: true
  });

  refreshSummary = () => {
    sendCommand('com.portbase.bezoekschip.common.api.consignments.commands.RefreshConsignmentProcessSummary', <IE3RefreshConsignmentProcessSummary>{
      consignmentProcessId: this._consignment.consignmentProcessId
    }, (summary: IE3ConsignmentProcessSummary) => {
      this.consignment = summary;
      AppContext.registerSuccess('Consignment summary has been rebuild successfully.');
    });
  }

  private getSubItems = () => this._consignment.masterConsignment.equipments;

  validSelector = (input: string): string => "SELECTOR_" + input.replace(/[^a-zA-Z0-9]/g, '');

  getEnsStatus = (): DeclarationMessageStatus => {
    const status = this._consignment.status;
    return {
      name: "Entry Summary Declaration",
      taskStatus: this._consignment.ensTaskStatus,
      messages: this.getMessagesEns(this._consignment.ensTaskStatus, status),
      customLabel: this._consignment.ensStatus != null ?
        PortvisitUtils.enumFormatter(this._consignment.ensStatus).replace("Ens", "ENS") : null,
      sentenceCase: false,
      asInnerHtml: [IE3EnsStatus.CONTROL_NOTIFICATION, IE3EnsStatus.ADVANCE_CONTROL_NOTIFICATION].includes(this._consignment.ensStatus)
    }
  }

  getTemporaryStorageStatus = (): DeclarationMessageStatus => {
    return {
      name: "Temporary storage",
      taskStatus: this._consignment.temporaryStorageTaskStatus,
      messages: this._consignment.temporaryStorageTaskStatus === TaskMessageStatus.REJECTED
        ? this._consignment.status?.filingStatusTemporaryStorage?.registrationResponse?.errors?.map(
          e => `${e.description}${e.code ? ' (' + e.code + ')' : ''}`)
        : [],
      customLabel: this._consignment.temporaryStorageStatus != null
        ? PortvisitUtils.enumFormatter(this._consignment.temporaryStorageStatus) : null
    }
  }

  getNotificationOfArrivalStatus = (): DeclarationMessageStatus => {
    const taskStatus = this._consignment.status?.notificationOfArrivalStatus || TaskMessageStatus.UNKNOWN;
    return {
      name: "Notification of Arrival",
      taskStatus: taskStatus,
      messages: [],
      customLabel: taskStatus === TaskMessageStatus.ACCEPTED ? "Accepted" : PortvisitUtils.enumFormatter(taskStatus)
    }
  }

  private getMessagesEns(taskStatus: TaskMessageStatus, status: IE3ConsignmentStatus): string[] {
    if (!status) {
      return [];
    }
    switch (taskStatus) {
      case TaskMessageStatus.ERROR:
        switch (status.latestEnsStatus) {
          case IE3EnsStatus.DO_NOT_LOAD:
            return status.doNotLoadRequest.doNotLoadDetails.transportEquipment.length
              ? [`Do not load equipment: ${status.doNotLoadRequest.doNotLoadDetails.transportEquipment.map(t => t.containerIdentificationNumber).join(", ")}`]
              : ["Do not load this consignment"];
          default:
            return [];
        }
      case TaskMessageStatus.REJECTED:
        switch (status.latestEnsStatus) {
          case IE3EnsStatus.HOUSE_CONSIGNMENT_INCORRECT_STATE_NOTIFICATION:
            return status.houseConsignmentInIncorrectStateNotification?.errors?.map(e => e.description || e.technicalErrorMessage);
          case IE3EnsStatus.ERROR_NOTIFICATION:
            return status.filingStatusENS.errornotification.errors.map(
              e => {
                if (e.description) {
                  return e.pointer != null ? e.description + ' at ' + e.pointer : e.description;
                } else {
                  return e.technicalErrorMessage;
                }
              }
            );
          case IE3EnsStatus.LIFECYCLE_VALIDATION_ERROR:
            return status.filingStatusENS.lifecycleValidationErrorNotification.errors.map(e => `Code: ${e.validationCode} – ${e.description}`);
          default:
            return [];
        }
      case TaskMessageStatus.WARNING:
        switch (status.latestEnsStatus) {
          case IE3EnsStatus.INFORMATION_REQUESTED:
            return [`Information requested at: ${this.formatDate(status.additionalInformationRequest.documentIssueTime)}`];
          case IE3EnsStatus.INFORMATION_REQUEST_NOTIFIED:
            return [`Information request notified at: ${this.formatDate(status.additionalInformationRequestNotification.documentIssueTime)}`];
          case IE3EnsStatus.CONTROL_NOTIFICATION:
            return [`<span>Control scheduled at: ${this.formatDate(status.controlNotification.scheduledControlTime)}</span><br/><i class="fa-light fa-fw fa-comment me-1 invisible"></i><span>Examination place: ${(this.examinationPlace(status.controlNotification))}</span>`];
          case IE3EnsStatus.ADVANCE_CONTROL_NOTIFICATION:
            return [`<span>Control scheduled at: ${this.formatDate(status.advanceControlNotification.scheduledControlTime)}</span><br/><i class="fa-light fa-fw fa-comment me-1 invisible"></i><span>Examination place: ${(this.examinationPlace(status.advanceControlNotification))}</span>`];
          default:
            return [];
        }
      default:
        return [];
    }
  }

  private examinationPlace(controlNotification: IE3ControlNotification) {
    return controlNotification.controls.map(control => control.examinationPlace).map(place => place.placeOfExamination || place.referenceNumber).join(", ");
  }
}
