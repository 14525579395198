import {Component} from '@angular/core';
import {VisitContext} from '../visit-context';

@Component({
  selector: 'app-ribbon',
  templateUrl: './history-ribbon.component.html',
  styleUrls: ['./history-ribbon.component.scss']
})
export class HistoryRibbonComponent {
  visitContext = VisitContext;
}
