<div class="dropdown always-enabled">
  <div class="bg-light rounded mx-1" aria-labelledby="dropdownMenuButton">
    <div class="p-3">
      <div class="row g-0">
        <div *ngFor="let option of options; let index = index" class="col-sm-6 col-md-4 col-lg-3">
          <div class="row narrow-gutters align-items-center">
            <div class="col-auto" style="min-width: 4rem">
              <div class="btn-group">
                <button type="button" class="btn px-2 py-1" style="font-size: 0.7rem;"
                        (click)="toggleOn(option)"
                        [ngClass]="isOn(option) ? 'btn-secondary' : 'btn-outline-secondary'">
                  <span class="fa fa-plus"></span>
                </button>
                <button type="button" class="btn px-0 py-0 btn-outline-dark" style="min-width: 1.5rem">
                  <span [title]="count(option)" class="d-inline-block small" style="min-width: 2rem">
                    {{count(option) | largeNumberFormat}}
                  </span>
                </button>
                <button type="button" class="btn px-2 py-1" style="font-size: 0.7rem;"
                        (click)="toggleOff(option)"
                        [ngClass]="isOff(option) ? 'btn-secondary' : 'btn-outline-secondary'">
                  <span class="fa fa-minus"></span>
                </button>
              </div>
            </div>
            <div class="col text-start">
              <span class="small" style="cursor: pointer;"
                    (click)="isToggled(option) ? reset(option) : toggleOn(option)">{{phaseDisplayName(option)}}</span>
            </div>

          </div>

        </div>

        <div class="col-auto ms-auto mt-2" *ngIf="selectedOptions.length > 0 || deselectedOptions.length > 0">
          <button class="me-1 p-0 btn btn-secondary border-0 bg-transparent text-secondary" (click)="resetAll()" style="font-size: 0.8rem;">
            <span class="fa fa-ban me-1"></span> Clear filters
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
