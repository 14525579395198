import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {
  Berth,
  CollectedItem,
  CollectorSummary,
  InputRegisterWasteCollection,
  WasteCollection,
  WasteCollector,
  WasteMaterial, WasteTypeSsn,
} from '@portbase/bezoekschip-service-typescriptmodels';
import {WasteCollectorUtils} from '../waste-utils';
import {checkValidity, lodash, nanoId, sendCommand, sendQuery, uuid} from '../../common/utils';
import {AppContext} from '../../app-context';
import {PortvisitUtils} from "../../refdata/portvisit-utils";
import {VisitContext} from "../../visit-details/visit-context";

@Component({
  selector: 'app-collector-summary',
  templateUrl: './collector-summary.component.html',
  styleUrls: ['./collector-summary.component.css']
})
export class CollectorSummaryComponent implements OnInit {

  @ViewChild('newCollectionEl') elementRef: ElementRef;

  appContext = AppContext;

  @Input() summary: CollectorSummary;
  @Input() collector: WasteCollector;
  @Input() newCollector: boolean;

  @Output() onCancel = new EventEmitter();

  utils = WasteCollectorUtils;

  allItems: { [index: string]: Item };

  showRegisterCollection: boolean = false;

  newCollection: WasteCollection;

  constructor(private element: ElementRef) {
  }

  searchVisit = term => sendQuery('com.portbase.bezoekschip.common.api.waste.collector.FindTemplateCollectorSummary', {term: term});
  visitFormatter = (summary: CollectorSummary) => summary ? this.formatSummary(summary) : '';

  private formatSummary(summary: CollectorSummary) {
    switch (summary.visitStatus) {
      case 'EXPECTED':
        return summary.vesselName + ' – ' + summary.visitStatus + ' at ' + summary.berthVisits[0].name + ' : ' + this.utils.formatDate(summary.berthVisits[0].eta);
      case 'ARRIVED':
        let currentBerthVisit = summary.berthVisits[summary.berthVisits.findIndex(value => value.ata && !value.atd)];
        currentBerthVisit = currentBerthVisit != null ? currentBerthVisit : {}; // This should never happen, but on KT it does.
        return summary.vesselName + ' – ' + summary.visitStatus + ' at ' + currentBerthVisit.name + ' : ' + this.utils.formatDate(currentBerthVisit.ata);
      case 'DEPARTED':
        let lastBerthVisit = summary.berthVisits[summary.berthVisits.length - 1];
        lastBerthVisit = lastBerthVisit != null ? lastBerthVisit : {}; // This should never happen, but on KT it does.
        return summary.vesselName + ' – ' + summary.visitStatus + ' at ' + lastBerthVisit.name + ' : ' + this.utils.formatDate(lastBerthVisit.atd);
    }
    return '';
  }

  ngOnInit() {
    if (this.summary) {
      this.allItems = this.getAllItems();
    }
  }

  private getAllItems(): { [index: string]: Item } {
    let result = {};
    if (this.summary.expectedItems) {
      this.summary.expectedItems.forEach(i => result[i.ssn.code] = <Item>{
        ssn: i.ssn,
        quantityToBeCollected: i.quantityToBeCollected,
        quantityCollected: 0,
        specificationFromAgent: i.specification
      });
    }

    lodash.flatMap(this.summary.collections, v => v.items).forEach(item => {
      let expectedItem = <Item>result[item.wasteMaterial.ssn.code];
      if (expectedItem) {
        result[item.wasteMaterial.ssn.code] = <Item>{
          ssn: item.wasteMaterial.ssn,
          quantityCollected: expectedItem.quantityCollected + (item.amount ? item.amount : 0),
          quantityToBeCollected: expectedItem.quantityToBeCollected,
          specificationFromAgent: expectedItem.specificationFromAgent,
          specification: expectedItem.specification
        };
      } else {
        result[item.wasteMaterial.ssn.code] = {
          ssn: item.wasteMaterial.ssn,
          quantityCollected: item.amount ? item.amount : 0,
          quantityToBeCollected: 0,
          specificationFromAgent: null,
          specification: item.specification
        };
      }
    });

    return result;
  }

  registerCollection() {
    const result = checkValidity(this.elementRef);
    if (!result) {
      return;
    }

    sendCommand('com.portbase.bezoekschip.common.api.waste.collector.input.InputRegisterWasteCollection', <InputRegisterWasteCollection>{
      timestamp: new Date().toISOString(),
      collection: WasteCollectorUtils.mapWasteCollection(this.newCollection, this.collector),
      crn: this.summary.crn,
      imo: this.summary.vesselImoCode,
    }, () => {
      this.summary.collections.push(this.newCollection);
      AppContext.registerSuccess('Registered waste Collection.');
      if (this.newCollector) {
        this.summary.expectedItems = this.summary.expectedItems.filter(v => !!this.newCollection.items.find(value => value.wasteMaterial.ssn.code === v.ssn.code));
        this.newCollector = false;
      }
      this.newCollection = null;
      this.showRegisterCollection = false;
      this.allItems = this.getAllItems();
    });

  }

  addWasteItem(collection: WasteCollection) {
    collection.items.push(<CollectedItem>{wasteMaterial: {}});
  }

  wasteTypeOptions(collection: WasteCollection) {
    let selectedTypes = collection.items
      .filter(i => i.wasteMaterial.ssn != null)
      .map(value => value.wasteMaterial.ssn != null ? value.wasteMaterial.ssn.code : "");
    let allTypes = this.utils.wasteDisplayItems.map(value => value.ssn);
    return allTypes.filter(value => selectedTypes.indexOf(value.code) < 0);
  }

  berthInputFormatter = (berth: Berth) => berth ? berth.name + ' – ' + berth.code : '';

  getBerthIds = () => this.summary.berthVisits.map(v => v.id);

  berthFormatter = (id) => {
    const berthVisit = this.summary.berthVisits.filter(v => v.id === id)[0];
    return berthVisit && berthVisit.name;
  };

  addNewCollection() {
    this.showRegisterCollection = !this.showRegisterCollection;
    let items = [];
    this.allItems = this.getAllItems();
    for (let key in this.allItems) {
      items.push(<CollectedItem>{
        wasteMaterial: <WasteMaterial>{ssn: this.allItems[key].ssn},
        type: key,
        specification: this.allItems[key].specification
      });
    }
    const berthId = this.summary.expectedItems && this.summary.expectedItems.length != 0 ? this.summary.expectedItems[0].berthIdForCollection : this.summary.berthVisits[0].id;
    this.newCollection = <WasteCollection>{
      id: nanoId(20),
      berthVisitId: berthId,
      collectorShortName: this.collector.organisation.shortName,
      shippingAgent: this.summary.shipOperatorName,
      items: items
    };
  }

  cancelNewCollection() {
    this.showRegisterCollection = false;
    this.newCollection = null;
    if (this.newCollector) {
      this.summary = null;
      this.onCancel.emit(null);
    }
  }


  deleteItem(list: any[], idx: number) {
    list.splice(idx, 1);
  }

  getToBeCollected(ssn: WasteTypeSsn) {
    if (ssn == null) {
      return 0;
    }
    let expectedItem = this.summary.expectedItems && this.summary.expectedItems.find(value => value.ssn.code === ssn.code);
    return expectedItem ? expectedItem.quantityToBeCollected : 0;
  }

  getSpecificationFromAgent(item: CollectedItem) {
    let expectedItem = this.summary.expectedItems && this.summary.expectedItems.filter(value => value.ssn.code === item.wasteMaterial.ssn.code)[0];
    return expectedItem && expectedItem.specification;
  }

  handleCompletion() {
    this.allItems = this.getAllItems();
  }

  setReceptionFacility(idx: number, $event: any) {
    if (idx === 0) {
      this.newCollection.items.forEach(item => item.receptionFacility = $event);
    }
  }

  onVisitSelect($event: any) {
    this.summary = $event;
    this.addNewCollection();
    this.summary.assignedCollectors = [];
  }
  openSwMessages() {
    PortvisitUtils.redirectToMessageManagement(this.summary.crn + '_war2sw')
  }

}

export class Item {
  ssn: WasteTypeSsn;
  quantityCollected: number;
  quantityToBeCollected: number;
  specificationFromAgent: string;
  specification: string;
}

