<ng-container *ngIf="context.visitInTerminalPlanning() else visitPortAuthority">
  <div class="row">
    <div class="col-md-6">
      <div *ngIf="context.visit.visitDeclaration.arrivalVoyage" class="fieldset"
           [ngClass]="{'disabled' : context.getVisitStatus() !== 'EXPECTED'}">
        <app-form-group *ngIf="appContext.isTerminalPlanner()">
          <app-info class="formGroupLabel">
            <label>Terminal planning?</label>
            <div class="tooltipContent">Select 'Yes' to coordinate the planning with the terminal before declaring the visit to the port authority.<br/>
              Select 'No' to start declaring to the port authority</div>
          </app-info>
          <app-yes-no [disabled]="context.visitHasTerminalPlannings()" [(ngModel)]="context.visit.terminalPlanningEnabled" required="true"></app-yes-no>
        </app-form-group>
        <app-form-group>
          <label for="arrivalVoyage-number">Voyage number</label>
          <input [(ngModel)]="context.visit.visitDeclaration.arrivalVoyage.voyageNumber" id="arrivalVoyage-number"
                 type="text" maxlength="17" class="form-control" [required]="inboundVoyageNumberRequired()">
        </app-form-group>
        <app-form-group>
          <label for="arrivalVoyage-carrierName">Carrier</label>
          <app-search [disabled]="!context.visit.visitDeclaration.arrivalVoyage.carrier?.name && !!context.visit.visitDeclaration.arrivalVoyage.carrier?.smdgCode"
                      [(ngModel)]="context.visit.visitDeclaration.arrivalVoyage.carrier"
                      dataKey="name" [searchFunction]="searchCarrier" id="arrivalVoyage-carrierName" required
                      [inputFormatter]="carrierFormatter">
          </app-search>
        </app-form-group>
      </div>
    </div>
    <div class="col-md-6">
      <app-form-group>
        <label for="vesselName">Vessel</label>
        <app-search [(ngModel)]="context.visit.vessel" [searchFunction]="searchVessel"
                    [inputFormatter]="vesselFormatter"
                    [minCharacters]="3" placeholder="Name or IMO number" dataKey="imoCode" id="vesselName" required>
        </app-search>
      </app-form-group>
      <app-form-group>
        <label for="arrivalVoyage-carriersmdgcode">Carrier smdg code</label>
        <input [ngModel]="context.visit.visitDeclaration.arrivalVoyage.carrier && context.visit.visitDeclaration.arrivalVoyage.carrier.smdgCode"
               (ngModelChange)="context.visit.visitDeclaration.arrivalVoyage.carrier = $event ? {smdgCode : $event} : null" required
               id="arrivalVoyage-carriersmdgcode" type="search" class="form-control" maxlength="17"
               [disabled]="context.visit.visitDeclaration.arrivalVoyage.carrier?.name && !!context.visit.visitDeclaration.arrivalVoyage.carrier?.smdgCode">
      </app-form-group>
    </div>
  </div>
</ng-container>

<ng-template #visitPortAuthority>
  <div class="row">
    <!--Dangerous goods-->
    <div class="declaration-block col-md" *ngIf="context.visit.portOfCall.paDeclarationRequired">
      <div *ngIf="!userIsNextOwnerOrDeclarant()">
        <app-form-group *ngIf="appContext.isTerminalPlanner()">
          <app-info class="formGroupLabel">
            <label>Terminal planning?</label>
            <div class="tooltipContent">Select 'Yes' to coordinate the planning with the terminal before declaring
              the visit to the Port Authority.<br/>
              Select 'No' to start declaring to the Port Authority</div>
          </app-info>
          <app-yes-no [disabled]="context.visitHasTerminalPlannings()" [(ngModel)]="context.visit.terminalPlanningEnabled" required="true"></app-yes-no>
        </app-form-group>
        <app-form-group>
          <label for="dangerousGoodsLoading">Loading DG</label>
          <app-yes-no [(ngModel)]="context.visit.visitDeclaration.portVisit.dangerousGoodsLoading" required
                      id="dangerousGoodsLoading"></app-yes-no>
          <div class="invalid-feedback">Are dangerous goods being loaded?</div>
        </app-form-group>
        <app-form-group>
          <label for="dangerousGoodsDischarge">Discharge DG</label>
          <app-yes-no [(ngModel)]="context.visit.visitDeclaration.portVisit.dangerousGoodsDischarge" required
                      id="dangerousGoodsDischarge"></app-yes-no>
          <div class="invalid-feedback">Are dangerous goods being discharged?</div>
        </app-form-group>
        <app-form-group>
          <label for="dangerousGoodsTransit">Transit DG</label>
          <app-yes-no [(ngModel)]="context.visit.visitDeclaration.portVisit.dangerousGoodsTransit" required
                      id="dangerousGoodsTransit"></app-yes-no>
          <div class="invalid-feedback">Are dangerous goods being transited?</div>
        </app-form-group>
      </div>
      <div *ngIf="userIsNextOwnerOrDeclarant()" class="always-enabled">
        <app-form-group>
          <label for="dangerousGoodsLoading">Loading DG</label>
          <app-yes-no [(ngModel)]="context.visit.nextVisitDeclaration.dangerousGoodsLoading"></app-yes-no>
          <div class="invalid-feedback">Are dangerous goods being loaded?</div>
        </app-form-group>
        <app-form-group>
          <label for="dangerousGoodsDischarge">Discharge DG</label>
          <app-yes-no [(ngModel)]="context.visit.nextVisitDeclaration.dangerousGoodsDischarge"></app-yes-no>
          <div class="invalid-feedback">Are dangerous goods being discharged?</div>
        </app-form-group>
        <app-form-group>
          <label for="dangerousGoodsTransit">Transit DG</label>
          <app-yes-no [(ngModel)]="context.visit.nextVisitDeclaration.dangerousGoodsTransit"></app-yes-no>
          <div class="invalid-feedback">Are dangerous goods being transited?</div>
        </app-form-group>
      </div>
      <app-form-group>
        <label for="vesselInspectionRequired">Requires inspection</label>
        <app-yes-no [(ngModel)]="context.visit.visitDeclaration.portVisit.vesselInspectionRequired" (ngModelChange)="context.visit.visitDeclaration.portVisit.shipConfiguration = $event
                  ? context.visit.visitDeclaration.portVisit.shipConfiguration : null" required
                    id="vesselInspectionRequired"></app-yes-no>
        <div class="invalid-feedback">Does the vessel require inspection?</div>
      </app-form-group>
      <app-form-group *ngIf="context.visit.visitDeclaration.portVisit.vesselInspectionRequired">
        <label for="shipConfiguration">Ship Configuration</label>
        <app-select [(ngModel)]="context.visit.visitDeclaration.portVisit.shipConfiguration" required
                    [options]="shipConfigurations" [formatter]="shipConfigurationFormatter" id="shipConfiguration">
        </app-select>
      </app-form-group>
    </div>

    <!--Other-->
    <div class="declaration-block col-md">
      <app-form-group>
        <label for="clientReferenceNumber">Client reference</label>
        <input [(ngModel)]="context.visit.visitDeclaration.clientReferenceNumber" id="clientReferenceNumber" type="text"
               maxlength="35" class="form-control">
      </app-form-group>
      <app-form-group>
        <label for="vesselEmailAddress">Vessel's email address</label>
        <input [(ngModel)]="context.visit.visitDeclaration.vesselEmailAddress" placeholder="Email" id="vesselEmailAddress"
               type="text" class="form-control">
      </app-form-group>
      <app-form-group *ngIf="context.visit.portOfCall.paDeclarationRequired">
        <label>Defects</label>
        <app-multiselect [(ngModel)]="context.visit.visitDeclaration.portVisit.defectTypes" [options]="defectTypes"
                         [formatter]="defectsFormatter" title="Select any number of defects"
                         (ngModelChange)="context.visit.visitDeclaration.portVisit.defectTypes.length == 0 ? context.visit.visitDeclaration.portVisit.defectTypeRemarks = null : null"
                         placement="top-start" id="defectTypes" [horizontal]="true"></app-multiselect>
      </app-form-group>
      <app-form-group *ngIf="context.visit.visitDeclaration.portVisit.defectTypes
  && context.visit.visitDeclaration.portVisit.defectTypes.length > 0">
        <label>Defect remarks</label>
        <textarea [(ngModel)]="context.visit.visitDeclaration.portVisit.defectTypeRemarks" maxlength="255" class="form-control" rows="1"></textarea>
      </app-form-group>
    </div>
  </div>
</ng-template>
