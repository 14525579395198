<div class="row">
  <div class="col-md-6">
    <app-form-group label="Id">
      <input disabled required class="form-control" [(ngModel)]="portOfCall['id']">
    </app-form-group>

    <app-form-group label="Sorting key">
      <input required type="number" min="0" [(ngModel)]="portOfCall.sortingKey">
    </app-form-group>

    <app-form-group label="Port">
      <app-search required [(ngModel)]="portOfCall.port" [searchFunction]="refData.findPort"
                  [inputFormatter]="refData.portFormatter" placeholder="Find port">
      </app-search>
    </app-form-group>

    <app-form-group label="Port authority">
      <app-search required [(ngModel)]="portOfCall.portAuthority" [searchFunction]="refData.findOrganisations"
                  [inputFormatter]="refData.portAuthorityFormatter" placeholder="Find port authority organisation">
      </app-search>
    </app-form-group>

    <app-form-group label="Customs office">
      <app-select required [(ngModel)]="portOfCall.customsOffice" [optionsProvider]="customsOffices"
                  [addSelectedIfNotExists]="true" [formatter]="refData.customsOfficeFormatter"
                  placeholder="– Select a customs office –">
      </app-select>
    </app-form-group>

    <app-form-group label="Authorise organisations">
      <app-yes-no required [(ngModel)]="portOfCall.authoriseOrganisationsDisabled" [formatter]="negativeLogicFormatter">
      </app-yes-no>
    </app-form-group>

    <app-form-group label="Actual time of arrival">
      <app-yes-no required [(ngModel)]="portOfCall.ataReported" [formatter]="reportingFormatter"></app-yes-no>
    </app-form-group>

    <app-form-group label="Actual time of departure">
      <app-yes-no required [(ngModel)]="portOfCall.atdReported" [formatter]="reportingFormatter"></app-yes-no>
    </app-form-group>

    <app-form-group label="Actual time of departure port">
      <app-yes-no required [(ngModel)]="portOfCall.atdPortReported" [formatter]="reportingFormatter"></app-yes-no>
    </app-form-group>
  </div>

  <div class="col-md-6">
    <app-form-group label="Waste">
      <app-yes-no required [(ngModel)]="portOfCall.wasteEnabled" [formatter]="positiveLogicFormatter"></app-yes-no>
    </app-form-group>

    <app-form-group label="Dangerous goods">
      <app-yes-no required [(ngModel)]="portOfCall.dangerousGoodsEnabled" [formatter]="positiveLogicFormatter">
      </app-yes-no>
    </app-form-group>

    <app-form-group label="Harbour dues">
      <app-yes-no required [(ngModel)]="portOfCall.harbourDuesEnabled" [formatter]="positiveLogicFormatter">
      </app-yes-no>
    </app-form-group>

    <app-form-group label="Order nautical services">
      <app-yes-no required [(ngModel)]="portOfCall.orderNauticalServicesEnabled" [formatter]="positiveLogicFormatter">
      </app-yes-no>
    </app-form-group>

    <app-form-group label="Notifications to port authority">
      <app-yes-no required [(ngModel)]="portOfCall.enableNotificationsToPa" [formatter]="positiveLogicFormatter">
      </app-yes-no>
    </app-form-group>

    <app-form-group label="Port authority declaration">
      <app-yes-no required [(ngModel)]="portOfCall.paDeclarationRequired" [formatter]="requirementFormatter">
      </app-yes-no>
    </app-form-group>

    <app-form-group label="Single window declaration">
      <app-yes-no required [(ngModel)]="portOfCall.swDeclarationRequired" [formatter]="requirementFormatter">
      </app-yes-no>
    </app-form-group>

    <app-form-group label="Tug boats">
      <app-yes-no required [(ngModel)]="portOfCall.tugBoatsRequired" [formatter]="requirementFormatter"></app-yes-no>
    </app-form-group>

    <app-form-group label="Explicit clearance by Port Authority">
      <app-yes-no required [(ngModel)]="portOfCall.portAuthorityClearance" [formatter]="positiveLogicFormatter">
      </app-yes-no>
    </app-form-group>
  </div>
</div>
