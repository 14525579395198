<a class="nav-link px-1 px-sm-3 text-white" [routerLink]="link"
  [queryParams]="{eventId: visitContext.eventId}" tabindex="-1" [ngClass]="{
    'active': appContext.getUrlPath().indexOf(link) >=0,
    'bg-light': status == undefined,
    'bg-portbase-light-blue': (status === 'DECLARED' || status === 'ACCEPTED') && !hamisHealthStatus,
    'bg-warning': status === 'ACCEPTED' && hamisHealthStatus === 'APPROVED_MEASURES_IMPOSED',
    'bg-portbase-green': status === 'ACCEPTED' && hamisHealthStatus === 'APPROVED',
    'bg-portbase-red': status === 'REJECTED' || hamisHealthStatus === 'REJECTED'}">
  <!-- Icon -->
  <i class="fa fa-fw {{icon}}" aria-hidden="true" data-bs-toggle="tooltip" title="{{getFullMenuTooltip()}}" [ngClass]="{'text-dark': status == undefined}"></i>
  <!-- Text (only display on smaller devices with vertical nav menu) -->
  <span class="d-sm-none ms-2 text-dark">
    <ng-content></ng-content>
  </span>
</a>
