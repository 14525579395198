import {Component, Input} from '@angular/core';
import {TerminalEmail} from "@portbase/bezoekschip-service-typescriptmodels";

@Component({
  selector: 'app-edit-terminal-email',
  templateUrl: './edit-terminal-email.component.html'
})
export class EditTerminalEmailComponent {
  @Input() terminalEmail : TerminalEmail;
}
