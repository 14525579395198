import {Component, ElementRef, OnInit} from '@angular/core';
import {VisitContext} from '../visit-context';
import {$try, checkValidity, clearValidation, sendCommand, sendQuery, wrapCommand} from '../../common/utils';
import moment from 'moment';
import {
  Availability,
  CompanySecurityOfficer,
  DeclarationType,
  DeclareSecurity,
  IsscSecurityDocument,
  SaveSecurity,
  SecurityDeclaration,
  SecurityReportRequired
} from '@portbase/bezoekschip-service-typescriptmodels';
import {uploadSecurityForm} from './security.upload';
import {AppContext} from '../../app-context';
import {of} from "rxjs";

@Component({
  selector: 'app-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.css']
})
export class SecurityComponent implements OnInit {
  VISIT = DeclarationType.VISIT;
  SECURITY = DeclarationType.SECURITY;
  MSV = DeclarationType.MSV;

  appContext = AppContext;
  context = VisitContext;
  enableReasonLessPortFacilities: boolean = false;
  enableSecurityRelatedMatters: boolean = false;
  reportRequiredFormatter = (type: SecurityReportRequired) => {
    switch (type) {
      case 'YES':
        return "Yes";
      case "NO":
        return "No, not required by law";
      case "EXEMPTION":
        return "No, based on exemption";
    }
  };
  availabilityFormatter = (option: Availability) => {
    switch (option) {
      case 'YES':
        return "Yes";
      case "NO":
        return "No";
      case "INTERIM":
        return "Interim";
    }
  };
  issuerProvider = sendQuery('com.portbase.bezoekschip.common.api.security.GetIsscIssuers', {});
  upload = uploadSecurityForm;
  uploadedXls: string;


  constructor(private element: ElementRef) {
  }

  ngOnInit(): void {
    this.context.visit.securityDeclaration = this.context.visit.securityDeclaration || <SecurityDeclaration>{shipToShipActivities: []};
    this.enableReasonLessPortFacilities = this.context.visit.securityDeclaration.reasonLessPortFacilities != null;
    this.enableSecurityRelatedMatters = this.context.visit.securityDeclaration.securityRelatedMatter != null;
  }

  initializeSecurityReport = (required: SecurityReportRequired) => {
    const declaration: SecurityDeclaration = this.context.visit.securityDeclaration;
    if (required && required === 'YES') {
      declaration.companySecurityOfficer = declaration.companySecurityOfficer || <CompanySecurityOfficer>{};
      declaration.isscSecurityDocument = declaration.isscSecurityDocument || <IsscSecurityDocument>{};
      declaration.shipToShipActivities = declaration.shipToShipActivities || [];
      declaration.securityReportRequired = required;
    } else {
      this.context.visit.securityDeclaration = <SecurityDeclaration>{
        securityReportRequired: required,
        shipToShipActivities: []
      }
    }
  };

  initializeIssc = (availability: Availability) => {
    const document: IsscSecurityDocument = this.context.visit.securityDeclaration.isscSecurityDocument;
    if (availability === 'NO') {
      document.reasonNoValidIsscOnBoard = null;
      document.issuer = null;
      document.expiryDate = null;
    }
    document.availability = availability;
  };

  get validSecurityMatters(): boolean {
    return this.enableSecurityRelatedMatters ===
      (this.context.visit.securityDeclaration.securityRelatedMatter != null
        && this.context.visit.securityDeclaration.securityRelatedMatter.length > 0);
  }

  get isscValid(): boolean {
    const isValid: boolean = this.isIsscValid();
    if (isValid) {
      this.context.visit.securityDeclaration.isscSecurityDocument.reasonNoValidIsscOnBoard = null;
    }
    return isValid
  }

  private isIsscValid = () => {
    const document = this.context.visit.securityDeclaration.isscSecurityDocument;
    if (!document.availability || (document.availability !== 'NO' && !document.expiryDate)) {
      return true;
    }
    return document.availability !== 'NO'
      && !moment(document.expiryDate).isBefore(moment(this.context.visit.visitDeclaration.portVisit.etaPort));
  };

  save = () => {
    if (this.context.isVlissingenOrTerneuzenVisit() && !checkValidity(this.element)) {
      return;
    }
    clearValidation(this.element);
    if (!this.incompleteListOfPortFacilities()) {
      this.context.visit.securityDeclaration.reasonLessPortFacilities = null;
    }
    const command = <SaveSecurity>{
      crn: this.context.visit.crn,
      securityDeclaration: this.context.visit.securityDeclaration,
      previousPorts: this.context.visit.visitDeclaration.previousPorts
    };

    sendCommand('com.portbase.bezoekschip.common.api.security.SaveSecurity',
      this.uploadedXls ? wrapCommand(command, this.uploadedXls) : command, () => {
        this.context.replaceVisit(this.context.visit);
        AppContext.registerSuccess('Security was saved successfully.');
      });
  };

  saveAndSend = () => {
    if (checkValidity(this.element)) {
      if (!this.incompleteListOfPortFacilities()) {
        this.context.visit.securityDeclaration.reasonLessPortFacilities = null;
      }
      const command = <DeclareSecurity>{
        crn: this.context.visit.crn,
        securityDeclaration: this.context.visit.securityDeclaration,
        previousPorts: this.context.visit.visitDeclaration.previousPorts
      };

      sendCommand('com.portbase.bezoekschip.common.api.security.DeclareSecurity',
        this.uploadedXls ? wrapCommand(command, this.uploadedXls) : command, () => {
          this.context.replaceVisit(this.context.visit);
          AppContext.registerSuccess('The visit was sent successfully.');
        });
    }
  };

  uploadCallback = (reasonFilled: boolean, mattersFilled: boolean) => {
    this.enableReasonLessPortFacilities = reasonFilled;
    this.enableSecurityRelatedMatters = mattersFilled;
  }

  getRejectReason() {
    return $try(() => this.context.findLatestDeclaration(DeclarationType.SECURITY).rejectReasons);
  }

  incompleteListOfPortFacilities() {
    return this.context.visit.visitDeclaration.previousPorts == null ||
      this.context.visit.visitDeclaration.previousPorts.filter(p => !!p)
        .map(p => p.portFacilityVisits)
        .filter(portFacilityVisits => !!portFacilityVisits)
        .map(portFacilityVisits => portFacilityVisits.length)
        .reduce((prev, next) => prev + next, 0) < 10;
  }

  clearReason() {
    if (!this.enableReasonLessPortFacilities) {
      this.context.visit.securityDeclaration.reasonLessPortFacilities = null;
    }
  }

  reasonProvider() {
    return of(["NEW_OWNER", "NEW_VESSEL"]);
  }

  clearSecurityRelatedMatter() {
    if (!this.enableSecurityRelatedMatters) {
      this.context.visit.securityDeclaration.securityRelatedMatter = null;
    }
  }

  reasonFormatter(reason) {
    if (reason === "NEW_OWNER") {
      return "Ship has a new owner"
    }
    if (reason === "NEW_VESSEL") {
      return "Ship is new and has visited less than 10 port facilities"
    }
    return null;
  }

  disabled(): boolean {
    return this.context.findLatestDeclaration(this.SECURITY)?.status === 'DECLARED'
  }

}
