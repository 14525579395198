<div class="mb-3" [ngClass]="{'always-enabled' : isEnabled()}">
  <div class="row g-0 bg-secondary text-light py-2">
    <div class="col">
      <app-info>
        <span class="required ms-5">Location</span>
        <span class="tooltipContent">
          Make sure the last previous port is at the top. At least one previous port is required for sending.
        </span>
      </app-info>
    </div>
    <div class="col-3 ps-2 d-none d-md-block" style="min-width: 15rem">
      Arrival
    </div>
    <div class="col-3 ps-2 d-none d-xl-block" style="min-width: 15rem">
      <span class="required">Departure</span>
    </div>
    <div class="col-3 ps-2 d-none d-xl-flex">
    </div>
    <div class="col-1 text-end d-xl-none" style="min-width: 5.5rem">
    </div>
  </div>

  <div dragula="previousPorts" [(dragulaModel)]="context.visit.visitDeclaration.previousPorts">
    <div *ngFor="let previousPort of context.visit.visitDeclaration.previousPorts; let idx = index">
      <div class="row g-0 my-2 validatable">
        <div class="col">
          <app-search [ngModel]="previousPort.port" [searchFunction]="searchPort" [inputFormatter]="portInputFormatter"
            (ngModelChange)="$event ? context.selectPreviousPort(previousPort, $event) : null" required
            [id]="'previousPort-port-' + idx" placeholder="Find port" dataKey="name">
            <span class="input-group-prepend input-group-text drag-handle grabbable bg-light border-0 drag-handle"><span
                class="fa fa-map-marker-alt text-portbase-blue drag-handle"></span></span>
          </app-search>
        </div>

        <div class="col-3 ps-2 d-none d-md-block" style="min-width: 15rem">
          <app-date-field [(ngModel)]="previousPort.arrival" defaultTime="12:00"
            [calendarStartDate]="previousPort.departure"></app-date-field>
        </div>

        <div class="col-3 ps-2 d-none d-xl-block" style="min-width: 15rem">
          <app-date-field [(ngModel)]="previousPort.departure" defaultTime="12:00"
            [calendarStartDate]="previousPort.arrival" [required]="idx === 0"></app-date-field>
        </div>

        <div class="col-3 ps-2 d-none d-xl-flex">
          <button (click)="context.deletePreviousPort(previousPort)" [id]="'previousPort-delete-' + idx"
            class="btn ms-auto pe-0" type="button" title="Delete port" tabindex="-1"><span
              class="fa fa-trash text-secondary"></span></button>
        </div>

        <div class="col-1 text-end d-xl-none" style="min-width: 5.5rem">
          <label class="btn btn-info ms-2" [id]="'previousPort-details' + idx"
            [attr.data-bs-target]="'#previousPort-details-collapse-' + idx"
            [ngClass]="{'btn-invalid' : previousPort.ngInvalid}" data-bs-toggle="collapse"
            onclick="">Details</label>
        </div>

        <div class="col-12 my-2 collapse" [id]="'previousPort-details-collapse-' + idx" [appValidator]="previousPort">
          <div class="card card-body bg-light">
            <div class="card-title row">
              <h5 class="col">{{portInputFormatter(previousPort.port)}}</h5>
              <button (click)="context.deletePreviousPort(previousPort)" [id]="'previousPort-delete-' + idx"
                class="btn mt-n2 col-auto" type="button" title="Delete port" tabindex="-1"><span
                  class="fa fa-trash text-secondary"></span></button>
            </div>

            <div class="row">
              <div class="col-md-6">
                <app-form-group>
                  <label [attr.for]="'previousPort-arrival-' + idx">Arrival</label>
                  <app-date-field [(ngModel)]="previousPort.arrival" defaultTime="12:00"
                    [calendarStartDate]="previousPort.departure"></app-date-field>
                </app-form-group>
              </div>
              <div class="col-md-6">
                <app-form-group>
                  <label [attr.for]="'previousPort-departure-' + idx">Departure</label>
                  <app-date-field [(ngModel)]="previousPort.departure" [calendarStartDate]="previousPort.arrival"
                    defaultTime="12:00" [required]="idx === 0"></app-date-field>
                </app-form-group>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span *ngIf="portNotChronological(previousPort)" class="ng-invalid invalid-feedback mb-2"
        style="margin-left: 2.25rem">Departure should be after arrival</span>
      <span *ngIf="twoPortsNotChronological(idx)" class="ng-invalid invalid-feedback mb-2" style="margin-left: 2.25rem">This port
        should be in chronological order with the port above</span>
      <span *ngIf="someFacilitiesAreOutsidePortVisit(previousPort)" class="ng-invalid invalid-feedback mb-2"
        style="margin-left: 2.5rem">All facility dates should be between the port arrival and departure</span>
    </div>
  </div>

  <div class="text-end mt-3">
    <button (click)="addPreviousPort()" id="addPreviousPort" type="button" class="btn btn-info" style="width: 8rem">Add
      Port
    </button>
  </div>
</div>
