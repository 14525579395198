import {Component, Input} from '@angular/core';
import {AppContext} from '../../../../../app-context';
import {VisitContext} from '../../../../visit-context';
import {Declaration, DeclarationType, IncomingMessage, RejectEns} from '@portbase/bezoekschip-service-typescriptmodels';
import {InjectorProvider} from '../../../../../common/injector-provider';
import {CommandGateway} from '../../../../../common/command-gateway';
import {ConsignmentModel} from '../../../cargo-import.model';
import {DeclarationUtils} from "../../../../../refdata/declaration-utils";
import {combineLatest, Observable, of} from "rxjs";
import {lodash} from "../../../../../common/utils";
import {environment} from "../../../../../../environments/environment";
import {PortvisitUtils} from '../../../../../refdata/portvisit-utils';

@Component({
  selector: 'app-consignments-admin',
  templateUrl: './consignments-admin.component.html',
  styleUrls: ['./consignments-admin.component.css']
})
export class ConsignmentsAdminComponent {
  appContext = AppContext;
  context = VisitContext;

  consignments: ConsignmentModel[];
  lastENSes: Declaration[];

  declaration: Declaration;

  @Input() set setConsignments(consignments: ConsignmentModel[]) {
    this.consignments = consignments;
    this.lastENSes = [].concat.apply([],
      consignments.map(consignment =>
        DeclarationUtils.getLastDeclarations(consignment.declarations, [DeclarationType.ENS])));
  }

  rejectAllowed() {
    return !environment.production && this.lastENSes.some(d => d && d.status === 'DECLARED');
  }

  reject(reason: string) {
    AppContext.clearVisitAlerts();
    const results = this.lastENSes.filter(d => d && d.status === 'DECLARED')
      .map(d => InjectorProvider.injector.get(CommandGateway).send(
        'com.portbase.bezoekschip.common.api.cargo.RejectEns', <RejectEns>{
          crn: this.context.visit.crn,
          cargoDeclarantShortName: this.context.cargoImportModel.cargoDeclarant.shortName,
          consignmentNumber: d.id,
          reason: reason
        }));
    const onComplete: Observable<any> = results.length > 0 ? combineLatest(results) : of(null);
    onComplete.subscribe(() => AppContext.registerSuccess('The consignment declarations were rejected successfully'));
  }

  getUniqueIftmcs(): IncomingMessage[] {
    const messages = this.consignments[0].incomingMessages;
    return messages && lodash.uniqBy(messages.filter(m => m.type === 'IFTMCS'), m => m.processId);
  }

  openMessages(processId: string) {
    PortvisitUtils.redirectToMessageManagement(processId);
  }
}
