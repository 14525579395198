import {Component, Input} from '@angular/core';
import {
  BezoekschipOrganisation,
  FindOrganisations,
  VesselService
} from "@portbase/bezoekschip-service-typescriptmodels";
import {PortvisitUtils} from "../portvisit-utils";
import {sendQuery} from "../../common/utils";

@Component({
  selector: 'app-edit-vessel-service',
  templateUrl: './edit-vessel-service.component.html',
  styleUrls: ['./edit-vessel-service.component.css']
})
export class EditVesselServiceComponent {
  formatter = PortvisitUtils.agentFormatter;
  @Input() vesselService: VesselService;

  searchOrganisation = term => sendQuery('com.portbase.bezoekschip.common.api.authorisation.FindOrganisations',
    <FindOrganisations>{term: term});

  setStevedoreOrganisationShortName = (stevedore: BezoekschipOrganisation) => {
    if (!stevedore) {
      this.vesselService.stevedoreOrganisationShortName = "";
    } else {
      this.vesselService.stevedoreOrganisationShortName = stevedore.shortName;
    }
  };

  setCarrierOrganisationsShortNames = (carriers: any[]) => {
    if (!carriers) {
      this.vesselService.carrierOrganisationsShortNames = [];
    } else {
      this.vesselService.carrierOrganisationsShortNames = carriers
        .map(carrier => carrier ? (typeof carrier === "string" ? carrier : carrier.shortName) : "")
        .filter(carrier => carrier && typeof carrier === "string" && carrier.trim().length > 0);
    }
  };
}
