import {Component, Input} from '@angular/core';
import {Vessel} from "@portbase/bezoekschip-service-typescriptmodels";

@Component({
  selector: 'app-edit-vessel',
  templateUrl: './edit-vessel.component.html',
  styleUrls: ['./edit-vessel.component.css']
})
export class EditVesselComponent {
  @Input() vessel: Vessel;
}
