<div class="fieldset" [ngClass]="{'disabled' : berthVisit.ata || berthVisit.atd || isBerthOfTransfer || (berthVisit.terminalPlanningEnabled && berthVisit.berth)}">
  <app-form-group>
    <app-info class="formGroupLabel" *ngIf="berthVisit.terminalPlanningEnabled && berthVisit.berth else noTerminalPlanning">
      <label>Berth</label>
      <div class="tooltipContent">Berth is automatically defined based on planning retrieved from the terminal</div>
    </app-info>
    <ng-template #noTerminalPlanning>
      <label>Berth</label>
    </ng-template>

    <app-search [(ngModel)]="berthVisit.berth" [searchFunction]="searchBerth" [inputFormatter]="berthInputFormatter"
      (ngModelChange)="onBerthChanged($event, berthVisit.berth)" required
      placeholder="Find berth or buoy" dataKey="name">
    </app-search>
    <div class="invalid-feedback">Please select a berth</div>
  </app-form-group>
</div>
