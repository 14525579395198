import {Component} from '@angular/core';
import {AttentionPointContext, SspiClearance} from "@portbase/bezoekschip-service-typescriptmodels";
import {ClearanceService} from "./clearance-service";
import {VisitContext} from "../../visit-context";
import {ClearanceUtil} from "./clearance-util";

@Component({
  selector: 'app-sspi-clearance',
  templateUrl: './sspi.clearance.component.html',
  styleUrls: ['./sspi.clearance.component.css']
})
export class SspiClearanceComponent {

  constructor(private clearanceService: ClearanceService) {
  }

  get clearance() {
    return <SspiClearance>this.clearanceService.getClearance(VisitContext.visit?.clearances, AttentionPointContext.sspi);
  }

  get notRequired(): Boolean {
    return this.clearance.notRequired;
  }

  get shouldShow(): Boolean {
    return ClearanceUtil.shouldShow(this.clearance)
  }
}
