import {Injectable} from '@angular/core';
import {QueryHandler} from '../common/query-handler';
import {Observable, of} from 'rxjs';
import {
  GetTrackerStats,
  GetTrackingResultMetrics,
  RefreshTrackerStats,
  RefreshTrackingResultMetrics,
  TrackerStats,
  TrackingMetric,
  MetricsResult, GetMessagingMetrics, RefreshMessagingMetrics
} from '@portbase/bezoekschip-service-typescriptmodels';
import {cloneObject, uuid} from "../common/utils";
import lodash from "lodash";
import moment from 'moment';
import {
  ServiceMonitoringContext
} from "../system-link/system-link-logged-in/service-monitoring/service-monitoring-context";

declare var require: any;

const trackingResultMetrics: MetricsResult = require('./metrics/tracking-result-metrics.json');
const trackingUserResultMetrics: MetricsResult = require('./metrics/tracker-stats-result.json');

@Injectable()
export class MetricsQueryHandlerMock extends QueryHandler {

  'com.portbase.bezoekschip.common.api.metrics.GetTrackingResultMetrics' = (query: GetTrackingResultMetrics): Observable<MetricsResult> => {
    return of(trackingResultMetrics);
  }

  'com.portbase.bezoekschip.common.api.metrics.RefreshTrackingResultMetrics' = (query: RefreshTrackingResultMetrics): Observable<MetricsResult> => {
    const result: MetricsResult = {
      data: lodash.range(1, Math.max(2, Math.floor(Math.random() * 40)), 1).map(n => {
        const timestamp = moment().subtract(5, "seconds");
        const eventTime = timestamp.add(n + 1, "seconds");
        const resultTime = timestamp.add(n + 1, "seconds");
        return <TrackingMetric> {
          id: uuid(),
          eventTime: eventTime.toISOString(),
          resultTime: resultTime.toISOString(),
          differenceMillis: Math.floor(Math.random() * 500)
        }
      })
    };

    result.lastMatch = {
      timestamp: moment(result.data[result.data.length-1].eventTime).valueOf()
    };

    return of(result);
  }

  'com.portbase.bezoekschip.common.api.metrics.GetTrackerStats' = (query: GetTrackerStats): Observable<MetricsResult> => {
    return of(trackingUserResultMetrics);
  }

  'com.portbase.bezoekschip.common.api.metrics.RefreshTrackerStats' = (query: RefreshTrackerStats): Observable<MetricsResult> => {
    const randomUser: string[] = ["bezoekschip-service@PORTINFOLINK", "hinterland-service@PORTINFOLINK", "test@PORTXCHANGE",
              "hamisprod@HBRRTM", "CARGOSYS@PORTINFOLINK"];
    const result: MetricsResult = {
      data: lodash.range(1, Math.max(2, Math.floor(Math.random() * 3)), 1).map(n => {
        const countPerUpdateType = {};
        const timestamp = moment().subtract(5, "seconds");
        const user = randomUser[Math.floor(Math.random() * randomUser.length)];
        const updateTypes = cloneObject(ServiceMonitoringContext.updatesPerType);
        updateTypes.sort(() => 0.5 - Math.random()).slice(0, updateTypes.length / 2)
          .forEach(u => countPerUpdateType[u] = Math.floor(Math.random() * 20));

        return <TrackerStats> {
          timestamp: timestamp.toISOString(),
          user: user,
          organisation: user.split("@")[1],
          countPerUpdateType: countPerUpdateType
        }
      })
    };

    result.lastMatch = {
      timestamp: moment(result.data[result.data.length-1].startTime).valueOf()
    };

    return of(result);
  }

  'com.portbase.bezoekschip.common.api.metrics.GetMessagingMetrics' = (query: GetMessagingMetrics): Observable<MetricsResult> => {
    return of({
      data: [],
      lastMatch: null
    });
  }

  'com.portbase.bezoekschip.common.api.metrics.RefreshMessagingMetrics' = (query: RefreshMessagingMetrics): Observable<MetricsResult> => {
    return of({
      data: [],
      lastMatch: null
    });
  }
}
