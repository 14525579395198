import {Component} from '@angular/core';
import {AppContext} from "../app-context";

@Component({
  selector: 'app-refdata',
  templateUrl: './refdata.component.html',
  styleUrls: ['./refdata.component.css']
})
export class RefdataComponent {
    protected readonly AppContext = AppContext;
}
