import {Component, Input} from '@angular/core';
import {GoodPlacement, ImportContainer} from '@portbase/bezoekschip-service-typescriptmodels';
import {PortvisitUtils} from '../../../../refdata/portvisit-utils';
import {filterByTerm, removeItem, replaceItem} from '../../../../common/utils';
import {of} from 'rxjs';
import {ConsignmentModel, GoodPlacementModel, GoodsItemModel, Manifest} from '../../cargo-import.model';
import {VisitContext} from '../../../visit-context';
import {createImportContainerFromDischargeResult} from "../overlanders/overlanders.component";

@Component({
  selector: 'app-goods-placement',
  templateUrl: './goods-placement.component.html',
  styleUrls: ['./goods-placement.component.css']
})
export class GoodsPlacementComponent {
  context = VisitContext;
  refData = PortvisitUtils;
  @Input() item: GoodsItemModel;
  @Input() consignment: ConsignmentModel;
  @Input() manifest: Manifest;

  findContainers = term => {
    const usedContainers = this.item.placements.map(p => p.equipmentNumber);
    return of((this.manifest.containers.concat(this.manifest.overlanders.map(o => createImportContainerFromDischargeResult(o, o.portOfLoading))))
      .filter(c => usedContainers.indexOf(c.number) < 0).filter(filterByTerm(term)));
  }

  containerFormatter = (value: ImportContainer) => value ? value.number : '';

  addGoodPlacement() {
    this.item.placements.push(<GoodPlacementModel>{});
  }

  removeGoodPlacement(placement: GoodPlacement) {
    return () => removeItem(this.item.placements, placement);
  }

  getContainer(placement: GoodPlacement): ImportContainer {
    if (placement && placement.equipmentNumber) {
      return this.manifest.containers.find(c => c.number === placement.equipmentNumber);
    }
    return null;
  }

  selectContainer(container: ImportContainer, placement: GoodPlacementModel) {
    const newNumber = container && container.number;
    placement.equipmentNumber = newNumber;
    placement.inspections = newNumber? this.consignment.inspections.filter(i => i?.containerNumber === newNumber) : [];
    if (container) {
      container.portOfLoading = this.consignment.portOfLoading;
      container.portOfDischarge = this.consignment.portOfDischarge;
      container.actualDeparture = this.consignment.actualDeparture;
      replaceItem(this.manifest.containers, this.manifest.containers.find(c => c.number === newNumber), container);
    }
  }
}
