<app-form-group label="License number">
  <input class="form-control" required [(ngModel)]="model.licenseNumber">
</app-form-group>
<app-form-group label="Customs id">
  <app-search required [ngModel]="model.customsAuthorityId" [searchFunction]="refData.findWarehouseLicenseHolders"
              (ngModelChange)="model.customsAuthorityId = $event?.customsEORINumber" [resultFormatter]="refData.wareHouseLicenseFormatter">
  </app-search>
</app-form-group>
<app-form-group label="Storage type">
  <app-select [(ngModel)]="model.storageType" [options]="refData.storageTypes"></app-select>
</app-form-group>
<app-form-group label="Name">
  <input class="form-control" [(ngModel)]="model.holderName">
</app-form-group>
<app-form-group label="Address">
  <input class="form-control" [(ngModel)]="model.address">
</app-form-group>
<app-form-group label="Zip code">
  <input class="form-control" [(ngModel)]="model.zipCode" [maxLength]="9">
</app-form-group>
<app-form-group label="City">
  <input class="form-control" [(ngModel)]="model.city">
</app-form-group>
<app-form-group *ngIf="!mergeAlways" label="{{editMode ? 'Update stored license' : 'Add to stored licenses'}}">
  <app-yes-no [(ngModel)]="mergeRefdata" [appDefaultValue]="editMode"></app-yes-no>
</app-form-group>
