import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FacetStats} from "../facet-filter.component";

@Component({
  selector: 'app-facet-filter-boolean-value',
  templateUrl: './facet-filter-boolean-value.component.html',
  styleUrls: ['./facet-filter-boolean-value.component.scss']
})
export class FacetFilterBooleanValueComponent {
  @Input() facet: FacetStats;
  @Input() formattedFacetName: string;
  @Output() valueSelected: EventEmitter<string[]> = new EventEmitter<string[]>();

  private _selectedValues: string[];
  private _selected: boolean;

  get count() {
    return this.facet.values.find(v => v.value === "true")?.count || 0;
  }

  get selected() {
    return this._selected;
  }

  set selected(selected: boolean) {
    this._selected = selected;
  }

  get selectedValues(): string[] {
    return this._selectedValues;
  }

  @Input()
  set selectedValues(selectedValues: string[]) {
    this._selectedValues = selectedValues;
    this.selected = this.selectedValues?.length > 0;
  }

  get facetValueId(): string {
    return `facet-${this.facet.name}`;
  }

  emitValueSelected = () => {
    const selection = this.selected ? ['true'] : [];
    this.valueSelected.emit(this.facet.valuesSelectedCallback
      ? this.facet.valuesSelectedCallback(this.facet, selection) : selection);
  }
}
