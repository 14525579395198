import {Component, OnInit} from '@angular/core';
import {VisitLogItem} from '@portbase/bezoekschip-service-typescriptmodels';
import {sendQuery} from "../../common/utils";
import {ComparatorChain} from "../../common/comparator-chain";
import {VisitContext} from "../visit-context";

@Component({
  selector: 'app-visit-history',
  templateUrl: './visit-history.component.html',
  styleUrls: ['./visit-history.component.scss']
})
export class VisitHistoryComponent implements OnInit {

  context = VisitContext;
  items: VisitLogItem[] = [];
  reverseOrder = true;

  private logComparator = new ComparatorChain("timestamp").compare;

  ngOnInit(): void {
    sendQuery("com.portbase.bezoekschip.common.api.history.GetVisitLog", {crn: this.context.visit.crn},
      {showSpinner: true, caching: false})
      .subscribe((items: VisitLogItem[]) => this.items = items.sort(this.logComparator));
  }
  cast = (item: VisitLogItem): VisitLogItem => item;

  toggleOrder() {
    this.reverseOrder = !this.reverseOrder;
  }
}
