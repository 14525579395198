import {Component, OnInit} from '@angular/core';
import {sendQuery} from "../../../../common/utils";
import {VisitContext} from "../../../visit-context";
import {PilotStation} from "@portbase/bezoekschip-service-typescriptmodels";
import {VisitValidator} from "../../../visit-validator";

@Component({
  selector: 'app-pilot-station',
  templateUrl: './pilot-station.component.html',
  styleUrls: ['./pilot-station.component.css']
})
export class PilotStationComponent implements OnInit {

	context = VisitContext;

	validator = VisitValidator;

	pilotStations = sendQuery(
		'com.portbase.bezoekschip.common.api.visit.GetPilotStations',
		{ portUnCode: VisitContext.visit.portOfCall.port.locationUnCode, atSea: false, isOrderIncomingMovement: false });

	pilotBoardingPlaceFormatter = (pilotBoardingPlace: PilotStation) => pilotBoardingPlace ? pilotBoardingPlace.name : '';

  constructor() { }

  ngOnInit() {
  }

}
