<div>
  <div class="row justify-content-between g-0 my-2 mx-0" style="min-height: 2.5rem ">
    <div class="col-5 col-md-4 ps-2">
      <ng-content></ng-content>
    </div>
    <div class="col-3 col-md-6">{{count}}</div>
    <div class="col-auto text-end" style="min-width: 5.5rem">
      <button [id]="'toggle-' + id" [attr.data-bs-target]="'#collapse-' + id"
              data-bs-toggle="collapse" class="btn btn-info ms-2" type="button" [hidden]="!first">Details
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col-12 my-2 collapse" [id]="'collapse-' + id">
      <div class="card card-body bg-light">
        <div class="row mb-2">
          <div class="col-5 col-md-4"></div>
          <div class="col-7 col-md-4 fw-bolder">First person</div>
          <div class="col-4 d-none d-md-block fw-bolder">Last person</div>
        </div>
        <div class="row">
          <div class="col-5 col-md-4">Family name</div>
          <div class="col-7 col-md-4">{{first?.familyName}}</div>
          <div class="col-4 d-none d-md-block">{{last?.familyName}}</div>
        </div>
        <div class="row">
          <div class="col-5 col-md-4">Given names</div>
          <div class="col-7 col-md-4">{{first?.givenNames}}</div>
          <div class="col-4 d-none d-md-block">{{last?.givenNames}}</div>
        </div>
        <div class="row">
          <div class="col-5 col-md-4">Job title or rank</div>
          <div class="col-7 col-md-4">{{first?.jobTitle}}</div>
          <div class="col-4 d-none d-md-block">{{last?.jobTitle}}</div>
        </div>
        <div class="row">
          <div class="col-5 col-md-4">Gender</div>
          <div class="col-7 col-md-4">{{first?.gender}}</div>
          <div class="col-4 d-none d-md-block">{{last?.gender}}</div>
        </div>
        <div class="row">
          <div class="col-5 col-md-4">Nationality</div>
          <div class="col-7 col-md-4">{{first?.nationality}}</div>
          <div class="col-4 d-none d-md-block">{{last?.nationality}}</div>
        </div>
        <div class="row">
          <div class="col-5 col-md-4">Date of Birth</div>
          <div class="col-7 col-md-4">{{first?.dateOfBirth | date: 'dd-MM-yyyy' : 'dd-MM-yyyy' }}</div>
          <div class="col-4 d-none d-md-block">{{last?.dateOfBirth | date: 'dd-MM-yyyy' : 'dd-MM-yyyy' }}</div>
        </div>
        <div class="row">
          <div class="col-5 col-md-4">Place of Birth</div>
          <div class="col-7 col-md-4">{{first?.placeOfBirth}}</div>
          <div class="col-4 d-none d-md-block">{{last?.placeOfBirth}}</div>
        </div>
        <div class="row">
          <div class="col-5 col-md-4">Embarks at port of call</div>
          <div class="col-7 col-md-4">{{first?.embarkingAtPortOfCall}}</div>
          <div class="col-4 d-none d-md-block">{{last?.embarkingAtPortOfCall}}</div>
        </div>
        <div class="row">
          <div class="col-5 col-md-4">Disembarks at port of call</div>
          <div class="col-7 col-md-4">{{first?.disembarkingAtPortOfCall}}</div>
          <div class="col-4 d-none d-md-block">{{last?.disembarkingAtPortOfCall}}</div>
        </div>
        <div class="row">
          <div class="col-5 col-md-4">Transit indicator</div>
          <div class="col-7 col-md-4">{{first?.transitIndicator}}</div>
          <div class="col-4 d-none d-md-block">{{last?.transitIndicator}}</div>
        </div>
        <div class="row">
          <div class="col-5 col-md-4">Special care (handling) information</div>
          <div class="col-7 col-md-4">{{first?.specialCareHandlingInformation}}</div>
          <div class="col-4 d-none d-md-block">{{last?.specialCareHandlingInformation}}</div>
        </div>
        <div *ngFor="let index of [0,1,2];" class="my-2">
          <div *ngIf="first?.travelDocuments[index] || last?.travelDocuments[index]">
            <div class="row">
              <div class="col-12 fw-bolder">Travel document {{index + 1}}</div>
            </div>
            <div class="row">
              <div class="col-5 col-md-4">Type</div>
              <div class="col-7 col-md-4">{{travelDocument(index)?.type}}</div>
              <div class="col-4 d-none d-md-block">{{travelDocument(index, true)?.type}}</div>
            </div>
            <div class="row">
              <div class="col-5 col-md-4">Number</div>
              <div class="col-7 col-md-4">{{travelDocument(index)?.number}}</div>
              <div class="col-4 d-none d-md-block">{{travelDocument(index, true)?.number}}</div>
            </div>
            <div class="row">
              <div class="col-5 col-md-4">Expiry date</div>
              <div class="col-7 col-md-4">{{travelDocument(index)?.expiryDate | date: 'dd-MM-yyyy' }}</div>
              <div
                class="col-4 d-none d-md-block">{{travelDocument(index, true)?.expiryDate | date: 'dd-MM-yyyy' }}</div>
            </div>
            <div class="row">
              <div class="col-5 col-md-4">Issuing country</div>
              <div class="col-7 col-md-4">{{travelDocument(index)?.issuingCountry}}</div>
              <div class="col-4 d-none d-md-block">{{travelDocument(index, true)?.issuingCountry}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
