import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {lodash, removeItem} from "../../common/utils";
import {CommercialRelease} from '@portbase/bezoekschip-service-typescriptmodels';
import {getPhase, ReleasePhase} from "../commercial-release.utils";

@Component({
  selector: 'app-commercial-release-filter',
  templateUrl: './commercial-release-filter.component.html',
  styleUrls: ['./commercial-release-filter.component.css']
})
export class CommercialReleaseFilterComponent implements CommercialReleaseFilter, OnInit {
  options: FilterableItems[] = ["declared", "accepted", "rejected", "cancel declared", "cancel accepted", "cancel rejected"];
  selectedOptions: FilterableItems[] = ["declared", "rejected"];
  deselectedOptions: FilterableItems[] = [];

  @Input() commercialReleases: CommercialRelease[];
  @Output() changed = new EventEmitter<CommercialReleaseFilter>();

  ngOnInit(): void {
    const storedOptions: { selectedOptions, deselectedOptions } = JSON.parse(localStorage.getItem('commercial-release-filter'));
    if (storedOptions) {
      this.selectedOptions = storedOptions.selectedOptions || [];
      this.deselectedOptions = storedOptions.deselectedOptions || [];
    }
    this.changed.emit(this);
  }

  onChanged() {
    this.changed.emit(this);
    localStorage.setItem('commercial-release-filter', JSON.stringify({
      selectedOptions: this.selectedOptions,
      deselectedOptions: this.deselectedOptions
    }));
  }

  phaseDisplayName(option: FilterableItems) {
    return lodash.startCase(option);
  }

  count(option: FilterableItems): number {
    return this.commercialReleases.filter(t => this.checkCondition(option, t)).length;
  }

  isOn(option: FilterableItems): boolean {
    return this.selectedOptions.some(o => option === o);
  }

  isOff(option: FilterableItems): boolean {
    return this.deselectedOptions.some(o => option === o);
  }

  isToggled(option: FilterableItems): boolean {
    return this.isOn(option) || this.isOff(option);
  }

  toggleOn(option: FilterableItems) {
    removeItem(this.deselectedOptions, option);
    const existingOption = this.selectedOptions.find(o => option === o);
    if (existingOption) {
      removeItem(this.selectedOptions, option);
    } else {
      this.selectedOptions.push(option);
    }
    this.onChanged();
  }

  toggleOff(option: FilterableItems) {
    removeItem(this.selectedOptions, option);
    const existingOption = this.deselectedOptions.find(o => option === o);
    if (existingOption) {
      removeItem(this.deselectedOptions, option);
    } else {
      this.deselectedOptions.push(option);
    }
    this.onChanged();
  }

  reset(option: FilterableItems) {
    removeItem(this.selectedOptions, option);
    removeItem(this.deselectedOptions, option);
    this.onChanged();
  }

  resetAll() {
    this.selectedOptions = [];
    this.deselectedOptions = [];
    this.onChanged();
  }

  checkCondition = (option: FilterableItems, t: CommercialRelease) => {
    return option == getPhase(t);
  };
}

export interface CommercialReleaseFilter {
  selectedOptions: FilterableItems[];
  deselectedOptions: FilterableItems[];
  checkCondition: (option: FilterableItems, t: CommercialRelease) => {};
}

export type FilterableItems = ReleasePhase
