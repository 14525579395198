<div class="position-fixed sticky-top w-100" style="left: 0; right: 0; z-index: 15000;">
  <app-alerting></app-alerting>
</div>
<app-overview [overviewFilters]="filters.overviewFilters" [searchFunction]="searchTermFunction" (termChanged)="searchTermChanged($event)" (resultsFound)="renderRecords($event, false)"
              [isEmpty]="isEmpty" [facetNameFormatter]="nameFormatter" [facets]="facets" (filtersCleared)="clearFilters($event)"
              (facetFiltersChanged)="facetFiltersChanged($event)" (loadNextPage)="loadNextPage()"
              [quickViews]="quickViews">
  <div class="header-bar d-flex">
    <div class="sub-text ms-3 align-items-center d-flex">
      <i class="fa fa-fw fa-light fa-xl fa-container-storage me-1"></i>
      <span>{{ containerCount }}</span>
    </div>
  </div>
  <div class="overview-items">
    <app-container-overview-item *ngFor="let container of data; trackBy: trackByRecord"
                                 [container]="container" [term]="filters.overviewFilters.term" [depth]="0"
                                 [collapsible]="!!container.consignmentDataSummaries?.length"></app-container-overview-item>
  </div>
  <div class="overview-footer">
    <ng-container *ngIf="nrOfSelectedContainers">
      <div class="row bg-light px-1 px-lg-3 pb-3 gy-3 border-top">
        <div class="col-12 col-md-auto col-lg d-flex align-items-center">
          <span class="sub-text me-2">
            <i class="fa fa-fw fa-light fa-xl fa-container-storage"></i>
          </span>
          <span class="d-inline-flex text-nowrap">{{nrOfSelectedContainers}} container{{nrOfSelectedContainers > 1 ? 's' : ''}}</span>
        </div>
        <div class="col ms-auto p-0"></div>
        <div class="col-12 col-sm-6 col-md-auto">
          <button type="button" class="btn btn-outline-secondary" (click)="createConsignment(getSelectedContainers())">Add to new consignment</button>
        </div>
        <div class="col-12 col-sm-6 col-md-auto d-sm-flex flex-column align-items-end" *ngIf="selectedOverlandedContainers.length">
          <button type="button" class="btn btn-outline-danger" (click)="refuseOverlandedContainers(selectedOverlandedContainers)">Refuse overlanded containers</button>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="empty-container col-12 h-100 d-flex align-items-center justify-content-center flex-column">
    <span class="h1 mb-2">No containers</span>
    <span class="text-secondary">Try adjusting your filters or search term</span>
  </div>
</app-overview>
