<!-- Exit point -->
<app-form-group>
  <label for="exitPoint">Exit point</label>
  <app-select [ngModel]="exitPoint" (ngModelChange)="onExitPointChanged($event)" [optionsProvider]="exitPointProvider"
    [formatter]="exitPointFormatter" dataKey="name" required placeholder="– Select exit point –" id="nextExitPoint">
    <div class="invalid-feedback">Please select an exit point</div>
  </app-select>
</app-form-group>

<!-- ETD -->
<app-form-group>
  <label for="portEtd">ETD port</label>
  <app-date-field [ngModel]="etdPort" (ngModelChange)="onPortEtdChanged($event);" id="nextPortEtd"></app-date-field>
</app-form-group>
