import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MergeOrganisationRefdata, Party} from '@portbase/bezoekschip-service-typescriptmodels';
import {lodash, sendCommand, uuid} from '../../../../common/utils';
import {QueryGateway} from '../../../../common/query-gateway';
import {PortvisitUtils} from '../../../../refdata/portvisit-utils';

@Component({
  selector: 'app-party',
  templateUrl: './party.component.html',
  styleUrls: ['./party.component.css']
})
export class PartyComponent implements OnInit, AfterViewInit, OnDestroy {
  utils = PortvisitUtils;
  @Input() model: Party;
  @Input() mergeAlways: boolean;
  mergeRefdata: boolean;
  editMode: boolean

  listener = () => this.mergeModel();

  @Output() saved = new EventEmitter<Party>();

  constructor(private queryGateway : QueryGateway, public elementRef: ElementRef) {
  }

  ngOnInit(): void {
    this.editMode = !!this.model.id;
  }

  ngAfterViewInit(): void {
    window.addEventListener("submit", this.listener);
  }

  ngOnDestroy(): void {
    setTimeout(() => window.removeEventListener("submit", this.listener), 0);
  }

  private mergeModel() {
    if (this.mergeAlways || this.mergeRefdata) {
      this.model.id = this.model.id || uuid();
      const value = lodash.assign({}, this.model);
      sendCommand('com.portbase.bezoekschip.common.api.refdata.MergeParty', <MergeOrganisationRefdata>{
        value: value,
        id: this.model.id,
        delete: false
      }, () => {
        this.saved.emit(value);
        this.queryGateway.removeFromCache("com.portbase.bezoekschip.common.api.cargo.FindMyParties");
      });
    }
  }
}
