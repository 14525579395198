import {Component, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {BerthVisit, Stevedore} from "@portbase/bezoekschip-service-typescriptmodels";
import {VisitContext} from "../../../../visit-context";

@Component({
  selector: 'app-multi-berth',
  templateUrl: './multi-berth.component.html',
  styleUrls: ['./multi-berth.component.css']
})
export class MultiBerthComponent implements OnChanges, OnDestroy {
  @Input() stevedore: Stevedore;
  @Input() berthVisit: BerthVisit;
  loadDischargeMap: Map<string, LoadDischargeInfo>;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.stevedore) {
      this.loadDischargeMap = new Map<string, LoadDischargeInfo>();
      this.stevedore.quaysForPlanning.forEach(value => this.loadDischargeMap.set(value, {
        quay: value,
        load: 0,
        discharge: 0,
        restow: 0
      }));
      this.updateVisitContextLoadDischargeInfo();
    }
  }

  ngOnDestroy(): void {
    this.loadDischargeMap = null;
    if (VisitContext.multiBerthLoadDischargeInfo.has(this.berthVisit.id)) {
      VisitContext.multiBerthLoadDischargeInfo.delete(this.berthVisit.id);
    }
  }

  updateVisitContextLoadDischargeInfo() {
    VisitContext.multiBerthLoadDischargeInfo.set(this.berthVisit.id, Array.from(this.loadDischargeMap.values()));
  }

  onValueChanged() {
    VisitContext.multiBerthLoadDischargeInfo.set(this.berthVisit.id, Array.from(this.loadDischargeMap.values()));
  }
}

export interface LoadDischargeInfo {
  quay: string;
  load: number;
  discharge: number;
  restow: number;
}
