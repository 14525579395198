import {Directive, Input} from '@angular/core'
import {NG_VALIDATORS, Validator, AbstractControl, Validators} from '@angular/forms'

@Directive({
  selector: '[max]',
  providers: [{provide: NG_VALIDATORS, useExisting: MaxValidatorDirective, multi: true}],
  host: {'[attr.max]': 'max'}
})
export class MaxValidatorDirective implements Validator {

  @Input() max: number;

  validate(control: AbstractControl): { [key: string]: any } {
    return Validators.max(this.max)(control)
  }
}
