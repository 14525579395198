<div *ngIf="goodsItems.length === 0" class="text-center text-muted" style="padding: 20px 0">
  You haven’t added any goods items yet.
</div>

<div *ngFor="let item of goodsItems" class="my-2">
  <app-collapse #goodsCollapse [collapse]="goodsItemTemplate"
                [showOnLoad]="goodsItems.length == 1 || !item.commodity.descriptionOfGoods"
                [animateOnLoad]="!item.commodity.descriptionOfGoods">
    <div class="row pe-3 g-0 align-items-center">
      <div class="col-auto me-2 p-2">
        <span class="fa fa-fw fa-boxes text-dark"></span>
      </div>
      <div class="col text-dark">
        <span class="d-none d-sm-inline-block align-top text-truncate"
              style="width: 16rem; max-width: 100%">{{(item.commodity.descriptionOfGoods || 'New good') | titlecase}}</span>
        <span class="d-inline-block d-sm-none align-top text-truncate"
              style="width: 8rem; max-width: 100%">{{(item.commodity.descriptionOfGoods || 'New good') | titlecase}}</span>
      </div>
      <div class="col d-none d-xl-flex text-dark" *ngIf="getContainerNumbers(item) as containerNrs">
        <span class="d-inline-block align-top text-truncate" style="width: 16rem; max-width: 100%"><span
          class="fa fa-cube me-2"></span>{{containerNrs}}</span>
      </div>
      <div class="col-auto me-n3 d-none d-md-block text-dark">
        <!--          <app-cargo-summary [good]="item" [keepTooltipOpen]="goodsCollapse.showing"></app-cargo-summary>-->
      </div>
    </div>
  </app-collapse>

  <ng-template #goodsItemTemplate>
    <div class="collapse">
      <div class="mx-3 pt-3">
        <app-transhipment-good-details [item]="item"></app-transhipment-good-details>
        <app-transhipment-goods-placement [item]="item" class="d-block my-3"></app-transhipment-goods-placement>
      </div>
    </div>
  </ng-template>
</div>
