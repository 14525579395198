<div class="row sticky-top bg-white" style="top:6.125rem">

  <div class="col-md-12">
    <app-alerting></app-alerting>
  </div>

  <!-- Reject reasons-->
  <div class="col-md-12 mt-2" *ngIf="getRejectReason() as rejectReason">
    <app-status-alert type="danger">Port authority: {{rejectReason}}</app-status-alert>
  </div>

  <!-- Nav bar -->
  <div class="col-md-12">
    <app-action-nav-bar>
      <ng-container>
        <div class="d-flex flex-row-reverse">
          <div class="ms-2">
            <app-waste-admin></app-waste-admin>
          </div>
          <div class="fieldset ms-2"
               [ngClass]="{'disabled' :context.findLatestDeclaration(WASTE)?.status === 'DECLARED'}">
            <div class="btn-group" *ngIf="!showSendWarning && !appContext.isPortAuthority()">
              <button [disabled]="!context.hasBeenAcceptedAtLeastOnce(VISIT)" (click)="trySend()" id="send"
                      type="button" class="btn btn-info" tabindex="-1">Save and send
              </button>
              <button type="button" class="btn btn-info dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">
                <span class="visually-hidden">Toggle Dropdown</span>
              </button>
              <div class="dropdown-menu dropdown-menu-end">
                <a *ngIf="context.hasBeenAcceptedAtLeastOnce(WASTE)" id="cancel" class="dropdown-item text-danger"
                   href="#" role="button" (click)="$event.preventDefault(); cancelWaste()">
                  <i class="fa fa-ban" aria-hidden="true"></i> Cancel
                </a>
                <a id="save" class="dropdown-item text-success" href="#" role="button"
                   (click)="$event.preventDefault(); save()">
                  <i class="fa fa-save" aria-hidden="true"></i> Save
                </a>
              </div>
            </div>
          </div>
          <div>
            <a class="btn btn-secondary" role="button" (click)="uploadInput.click()" title="Upload waste declaration">
              <span class="fa fa-file-upload"></span><input type="file" hidden
                                                            (change)="upload($event.target.files[0]); $event.target.value = '';"
                                                            #uploadInput>
            </a>
          </div>
        </div>
      </ng-container>
    </app-action-nav-bar>
  </div>

  <app-emas-clearance class="col-md-12"></app-emas-clearance>

  <div class="col-md-12" *ngIf="!showSendWarning && !appContext.isPortAuthority()">
    <div *ngIf="!context.hasBeenAcceptedAtLeastOnce(VISIT)" class="row justify-content-end hint-box">
      <div>Waste can be sent once the visit declaration has been accepted.</div>
    </div>
  </div>

  <!--Send warning-->
  <div class="col-md-12" *ngIf="showSendWarning">
    <div class="alert alert-warning fade show mb-0" role="alert">
      <div class="row justify-content-between">
        <div class="me-3 mb-2 mt-2 ms-2 d-inline-block">
          <span>The page contains warnings. Are you sure you want to send the declaration?</span>
        </div>
        <div class="d-inline-block text-nowrap me-2">
          <button (click)="this.showSendWarning = false" id="cancelSend" data-bs-dismiss="alert" type="button"
                  class="btn btn-secondary me-2" style="min-width: 6rem">Cancel
          </button>
          <button (click)="doSend()" id="doSend" data-bs-dismiss="alert" type="button" class="btn btn-warning"
                  style="min-width: 6rem">Send
          </button>
        </div>
      </div>
    </div>
  </div>

  <!--Last waste delivery-->
  <div class="declaration-block col-md-12">
    <div class="row">
      <div class="col-md-8">
        <h2>Last waste delivery</h2>
        <div class="row">
          <div class="col-md">
            <app-form-group>
              <label for="portOfLastDelivery">Port</label>
              <app-search [(ngModel)]="context.visit.wasteDeclaration.portOfLastDelivery" [searchFunction]="searchPort"
                          [inputFormatter]="portInputFormatter" id="portOfLastDelivery" placeholder="Find port"
                          dataKey="name">
              </app-search>
            </app-form-group>
          </div>
          <div class="col-md">
            <app-form-group>
              <label for="lastDeliveryDate">Delivery date</label>
              <app-date-field [required]="context.visit.wasteDeclaration.portOfLastDelivery"
                             [(ngModel)]="context.visit.wasteDeclaration.lastDeliveryDate" [dateOnly]="true"
                             id="lastDeliveryDate">
              </app-date-field>
            </app-form-group>
          </div>
        </div>
      </div>
      <!--Next waste delivery-->
      <div class="col-md-4">
        <h2>Next waste delivery</h2>
        <app-form-group>
          <label for="portOfNextDelivery">Port</label>
          <app-search [(ngModel)]="context.visit.wasteDeclaration.portOfNextDelivery" [searchFunction]="searchPort"
                      [inputFormatter]="portInputFormatter" id="portOfNextDelivery" placeholder="Find port"
                      dataKey="name">
          </app-search>
        </app-form-group>
      </div>
      <div class="col-md">
      </div>
    </div>
  </div>

  <!-- Waste headers -->
  <div class="declaration-block col-md-12">
    <div class="border-bottom d-none d-xl-block" style="height: 10rem">
      <div class="row g-0 item-labels d-xl-flex ms-1">
        <label class="col col-auto item-name"></label>
        <label class="col col-auto mx-1 quantity">To be delivered (m3)
          <app-info><span class="tooltipContent">The quantity to be delivered in the port of call.</span></app-info>
        </label>
        <label class="col mx-1 other">Collector</label>
        <label class="col mx-1 other">Berth of delivery</label>
        <label class="col col-auto mx-1 quantity">To be retained (m3)
          <app-info [wide]="true" [placement]="'bottom'">
            <div class="tooltipContent">
              <div class="text-start">
                <p>If next port is inside EU:</p>
                <ul>
                  <li>Annex 1: The master can refrain from delivery of sludge and/or bilge water if at least 50% tank
                    capacity remains for this type of waste.
                  </li>
                  <li>Annex 4: The master can refrain from delivery of Annex 4 waste if at least 50% tank capacity
                    remains for this type of waste.
                  </li>
                  <li>Annex 5: The master can refrain from delivery of annex 5 waste if at least 75% capacity remains
                    for this type of waste.
                  </li>
                  <li>Annex 6: The master can refrain from delivery of annex 6 waste if at least 25% capacity remains
                    for this type of waste.
                  </li>
                </ul>
                <p>If next port is outside EU or unknown:</p>
                <ul>
                  <li>Annex 1: The master can refrain from delivery of sludge and/or bilge water if at least 75% tank
                    capacity remains for this type of waste.
                  </li>
                  <li>Annex 4: The master can refrain from delivery of Annex 4 waste if at least 50% tank capacity
                    remains for this type of waste.
                  </li>
                  <li>Annex 5: The master can refrain from delivery of annex 5 waste if at least 80% capacity remains
                    for this type of waste.
                  </li>
                  <li>Annex 6: The master can refrain from delivery of annex 6 waste if at least 75% capacity remains
                    for this type of waste.
                  </li>
                </ul>
              </div>
            </div>
          </app-info>
        </label>
        <label class="col col-auto mx-1 quantity">To be generated (m3)
          <app-info><span class="tooltipContent">The quantity generated between departure from port of call and the
              next
              port of call.</span>
          </app-info>
        </label>
        <label class="col col-auto mx-1 quantity">Max. storage capacity (m3)</label>
        <label class="col mx-1 other">Port retained waste delivery</label>
        <label class="col ms-1 other">Specification</label>
        <label class="col col-auto d-xl-none" style="width: 5.5rem"></label>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <!--Waste items per category-->
  <div class="declaration-block col-md-12">
    <div *ngFor="let category of categories">
      <!--category name-->
      <h5 class="text-portbase-blue border-bottom mt-4 mb-2 pb-1">{{category.name}}</h5>

      <!--items-->
      <div *ngFor="let item of category.items; let index = index" (change)="afterItemUpdate(item, category)" class="row g-0 my-2">
        <div class="col col-auto align-self-center item-name">
          {{item.ssn.description}}
        </div>
        <div class="col col-auto d-none d-xl-block quantity mx-1">
          <input [(ngModel)]="item.wasteItem.quantityToBeDelivered" [appDefaultValue]="0"
                 [id]="'quantityToBeDelivered-' + index" type="number" class="form-control no-spin">
        </div>
        <div class="col d-none d-xl-block mx-1 other">
          <app-select [(ngModel)]="item.wasteItem.collector" [addSelectedIfNotExists]="true"
                      [disabled]="!item.wasteItem.quantityToBeDelivered" [refreshProvider]="getCollectorProvider"
                      [refreshWatch]="category.annex" dataKey="fullName" [id]="'collector-' + index"
                      placeholder="Collector">
          </app-select>
        </div>
        <div class="col d-none d-xl-block mx-1 other">
          <app-select [(ngModel)]="item.wasteItem.berthIdForPickup" [disabled]="!item.wasteItem.quantityToBeDelivered"
                      [options]="getBerthIds()" [formatter]="formatBerth" [id]="'berthForPickup-' + index"
                      placeholder="Select berth"></app-select>
        </div>
        <div class="col col-auto d-none d-xl-block quantity mx-1">
          <input [(ngModel)]="item.wasteItem.quantityToBeRetained" [appDefaultValue]="0"
                 [id]="'quantityToBeRetained-' + index" type="number" class="form-control no-spin">
        </div>
        <div class="col col-auto d-none d-xl-block quantity mx-1">
          <input [(ngModel)]="item.wasteItem.quantityToBeGenerated" [disabled]="!item.wasteItem.quantityToBeRetained"
                 [appDefaultValue]="0" [id]="'quantityToBeGenerated-' + index" type="number"
                 class="form-control no-spin">
        </div>
        <div class="col col-auto d-none d-xl-block quantity mx-1">
          <input [(ngModel)]="item.wasteItem.maxDedicatedCapacity"
                 [disabled]="!maxCapacityEnabled(item.wasteItem)"
                 [appDefaultValue]="0" [id]="'maxDedicatedCapacity-' + index" type="number"
                 class="form-control no-spin">
        </div>
        <div class="col d-none d-xl-block mx-1 other">
          <app-search [(ngModel)]="item.wasteItem.portForRetainedWaste"
                      [disabled]="!item.wasteItem.quantityToBeRetained && !item.wasteItem.quantityToBeGenerated"
                      [searchFunction]="searchPortOrWayPoint" [inputFormatter]="portInputFormatter"
                      [id]="'portForRetainedWaste-' + index" placeholder="Port" dataKey="name"></app-search>
        </div>
        <div class="col d-none d-xl-block ms-1 other">
          <input [(ngModel)]="item.wasteItem.specification" *ngIf="item.ssn.specificationRequired"
                 [id]="'specification-' + index" type="text" class="form-control">
        </div>


        <!--for mobile-->
        <div class="col d-xl-none text-end">
          <label [id]="'item-details-' + index" [attr.data-bs-target]="'#item-collapse-' + index"
                 style="width: 5.5rem" data-bs-toggle="collapse" class="btn btn-info ms-2">
            Details
          </label>
        </div>
        <div class="col-12 my-2 collapse" [id]="'item-collapse-' + index">
          <div class="card card-body bg-light">
            <div class="row">
              <div class="col-md-6">
                <app-form-group>
                  <label [attr.for]="'quantityToBeDelivered-' + index">
                    <app-info>
                      To be delivered (m3)
                      <span class="tooltipContent">The quantity to be delivered in the port of call.</span>
                    </app-info>
                  </label>
                  <input [(ngModel)]="item.wasteItem.quantityToBeDelivered" [appDefaultValue]="0"
                         [id]="'quantityToBeDelivered-' + index" type="number" class="form-control no-spin">
                </app-form-group>
                <app-form-group>
                  <label [attr.for]="'collector-' + index">Collector</label>
                  <app-select [(ngModel)]="item.wasteItem.collector"
                              [disabled]="item.wasteItem.quantityToBeDelivered <= 0"
                              [refreshProvider]="getCollectorProvider"
                              [refreshWatch]="category.annex" dataKey="fullName" [id]="'collector-' + index"
                              placeholder="Collector"></app-select>
                </app-form-group>
                <app-form-group>
                  <label [attr.for]="'berthForPickup-' + index">Berth of delivery</label>
                  <app-select [(ngModel)]="item.wasteItem.berthIdForPickup"
                              [disabled]="item.wasteItem.quantityToBeDelivered <= 0" [options]="getBerthIds()"
                              [formatter]="formatBerth" [id]="'berthForPickup-' + index"
                              placeholder="Select berth">
                  </app-select>
                </app-form-group>
              </div>


              <div class="col-md-6">
                <app-form-group>
                  <label [attr.for]="'quantityToBeRetained-' + index">
                    <app-info>
                      To be retained (m3)
                      <div class="tooltipContent">
                        <div class="text-start">
                          <i>If next port is inside EU:</i>
                          <p *ngIf="category.annex === 1">The master can refrain from delivery of sludge and/or
                            bilge water if at least 50% tank capacity remains for this type of waste.
                          </p>
                          <p *ngIf="category.annex === 4">The master can refrain from delivery of Annex 4 waste if
                            at least 50% tank capacity remains for this type of waste.
                          </p>
                          <p *ngIf="category.annex === 5">The master can refrain from delivery of annex 5 waste if
                            at least 75% capacity remains for this type of waste.
                          </p>
                          <p *ngIf="category.annex === 6">The master can refrain from delivery of annex 6 waste if
                            at least 25% capacity remains for this type of waste.
                          </p>

                          <i>If next port is outside EU or unknown:</i>
                          <p *ngIf="category.annex === 1">The master can refrain from delivery of sludge and/or
                            bilge water if at least 75% tank capacity remains for this type of waste.
                          </p>
                          <p *ngIf="category.annex === 4">The master can refrain from delivery of Annex 4 waste if
                            at least 50% tank capacity remains for this type of waste.
                          </p>
                          <p *ngIf="category.annex === 5">The master can refrain from delivery of annex 5 waste if
                            at least 80% capacity remains for this type of waste.
                          </p>
                          <p *ngIf="category.annex === 6">The master can refrain from delivery of annex 6 waste if
                            at least 75% capacity remains for this type of waste.
                          </p>
                        </div>
                      </div>
                    </app-info>
                  </label>
                  <input [(ngModel)]="item.wasteItem.quantityToBeRetained" [appDefaultValue]="0"
                         [id]="'quantityToBeRetained-' + index" type="number" class="form-control no-spin">
                </app-form-group>
                <app-form-group>
                  <label [attr.for]="'quantityToBeGenerated-' + index">
                    <app-info>
                      To be generated (m3)
                      <span class="tooltipContent">The quantity generated between departure from port of call and
                        the
                        next port of call.</span>
                    </app-info>
                  </label>
                  <input [(ngModel)]="item.wasteItem.quantityToBeGenerated"
                         [disabled]="item.wasteItem.quantityToBeRetained === 0" [appDefaultValue]="0"
                         [id]="'quantityToBeGenerated-' + index" type="number" class="form-control no-spin">
                </app-form-group>
                <app-form-group>
                  <label [attr.for]="'maxDedicatedCapacity-' + index">Max. storage capacity (m3)</label>
                  <input [(ngModel)]="item.wasteItem.maxDedicatedCapacity"
                         [disabled]="!maxCapacityEnabled(item.wasteItem)" [appDefaultValue]="0"
                         [id]="'maxDedicatedCapacity-' + index" type="number" class="form-control no-spin">
                </app-form-group>
                <app-form-group>
                  <label [attr.for]="'portForRetainedWaste-' + index">Port for retained waste delivery</label>
                  <app-search [(ngModel)]="item.wasteItem.portForRetainedWaste"
                              [disabled]="!item.wasteItem.quantityToBeRetained && !item.wasteItem.quantityToBeGenerated"
                              [searchFunction]="searchPortOrWayPoint" [inputFormatter]="portInputFormatter"
                              [id]="'portForRetainedWaste-' + index" placeholder="Port"
                              dataKey="name"></app-search>
                </app-form-group>
                <app-form-group *ngIf="item.ssn.specificationRequired">
                  <label [attr.for]="'specification-' + index">Specification</label>
                  <input [(ngModel)]="item.wasteItem.specification" [id]="'specification-' + index" type="text"
                         class="form-control">
                </app-form-group>
              </div>
            </div>
          </div>
        </div>

        <!--errors and warnings-->
        <div *ngFor="let error of item.errors" class="col-12 alert alert-danger mt-2 mb-0 px-2 py-1" role="alert">
          {{error}}
        </div>
        <div *ngFor="let warning of item.warnings" class="col-12 alert alert-warning mt-2 mb-0 px-2 py-1"
             role="alert">
          {{warning}}
        </div>
      </div>
    </div>
  </div>
</div>

<!-- MOBILE (buttons bottom right) -->
<div class="fieldset" [ngClass]="{'disabled' :context.findLatestDeclaration(WASTE)?.status === 'DECLARED'}">
  <div class="fixed-bottom mb-3 pe-2 btn-group d-block d-lg-none" style="max-width: 100vw;">
    <div class="row justify-content-end g-2">
      <div class="col-auto">
        <button type="button" class="btn btn-info dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
          Actions
        </button>
        <div class="dropdown-menu dropdown-menu-end">
          <ng-container *ngIf="!appContext.isPortAuthority()">
            <button [disabled]="!context.hasBeenAcceptedAtLeastOnce(VISIT)" (click)="trySend()" type="button"
                    class="dropdown-item" tabindex="-1">Save and send
            </button>
          </ng-container>
          <ng-container>
            <div>
              <a *ngIf="context.hasBeenAcceptedAtLeastOnce(WASTE)" class="dropdown-item text-danger"
                 href="#" role="button" (click)="$event.preventDefault(); cancelWaste()">
                <i class="fa fa-ban" aria-hidden="true"></i> Cancel
              </a>
            </div>
          </ng-container>
          <ng-container>
            <div>
              <a class="dropdown-item text-success" href="#" role="button"
                 (click)="$event.preventDefault(); save()">
                <i class="fa fa-save" aria-hidden="true"></i> Save
              </a>
            </div>
          </ng-container>
          <ng-container>
            <div>
              <a class="dropdown-item" role="button" (click)="uploadInput.click()" title="Upload waste declaration">
                Upload<input type="file" hidden (change)="upload($event.target.files[0]); $event.target.value = '';"
                             #uploadInput>
              </a>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="col-auto">
        <app-waste-admin></app-waste-admin>
      </div>
    </div>
  </div>
</div>
