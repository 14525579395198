import {Component, ElementRef} from '@angular/core';
import {checkValidity, sendCommand} from "../../../common/utils";
import {IE3InputDeclareTranshipment} from "@portbase/bezoekschip-service-typescriptmodels";
import {AppContext} from "../../../app-context";

@Component({
  selector: 'app-declare-transhipment-container-consignment',
  templateUrl: './declare-transhipment-container-consignment.component.html',
  styleUrls: ['./declare-transhipment-container-consignment.component.css']
})
export class DeclareTranshipmentContainerConsignmentComponent {

  constructor(private element: ElementRef) {
  }

  crn: string;
  consignmentNumber: string;
  containerNumber: string;

  reset() {
    this.crn = '';
    this.containerNumber = '';
    this.consignmentNumber = '';
  }

  submit() {
    if (checkValidity(this.element)) {
      const declaration = <IE3InputDeclareTranshipment>{
        consignmentNumber: this.consignmentNumber,
        containerNumber: this.containerNumber,
        crn: this.crn
      }

      sendCommand('com.portbase.bezoekschip.common.api.consignments.commands.input.InputDeclareTranshipment',
        declaration, () => {
          AppContext.registerSuccess("Declaration transhipment sent successfully");
        });
    }
  }
}
