<div class="row">
  <div class="col-md-6">
    <app-form-group><label>Id</label><input class="form-control" [(ngModel)]="bunkerProduct.id" disabled required>
    </app-form-group>
    <app-form-group><label>Type</label><input class="form-control" [(ngModel)]="bunkerProduct.type" required>
    </app-form-group>
    <app-form-group><label>Provision Type</label>
      <app-select id="provisionType" [(ngModel)]="bunkerProduct.provisionType" [optionsProvider]="provisionTypes"
                  [addSelectedIfNotExists]="false" placeholder="– Select a provsion type –" required></app-select>
    </app-form-group>
    <app-form-group><label>Unit Of Measure</label>
      <app-select id="unitOfMeasure" [(ngModel)]="bunkerProduct.unitOfMeasure" [optionsProvider]="unitsOfMeasure"
                  [addSelectedIfNotExists]="false" placeholder="– Select a unit of measure –" required></app-select>
    </app-form-group>
    <app-form-group><label>Density</label><input class="form-control" [(ngModel)]="bunkerProduct.density" required>
    </app-form-group>
  </div>
</div>
