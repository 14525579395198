import {Component, OnDestroy} from '@angular/core';
import {PortvisitUtils} from '../../../refdata/portvisit-utils';
import {VisitContext} from '../../visit-context';
import {filterByTerm} from '../../../common/utils';
import {AppContext} from '../../../app-context';
import {LoadList} from "../cargo-loading.model";

@Component({
  selector: 'app-load-list',
  templateUrl: './load-list.component.html',
  styleUrls: ['./load-list.component.css']
})
export class LoadListComponent implements OnDestroy {

  context = VisitContext;
  appContext = AppContext;
  utils = PortvisitUtils;

  get loadLists(): LoadList[] {
    return VisitContext.cargoLoadingModel.loadLists;
  }

  filteredLists: LoadList[] = [];

  allowedTerminals = ["APMII", "RWG", "ECTDELTA", "APMRTM", "RST", "RSTZ", "EUROMAX"];

  filterList(term: string): (value: LoadList) => boolean {
    return l => {
      const show = filterByTerm(term)(l);
      if (show) {
        l.overlanders.forEach(c => c['hidden'] = !filterByTerm(term)(c));
      }
      return show;
    }
  }


  listById(index: number, s: LoadList) {
    return s.terminal.terminalName;
  }

  get selectedLists(): LoadList[] {
    return this.filteredLists.filter(v => !!v['selected']);
  }

  filterAllowedTerminals(lists: LoadList[]) {
    return lists.filter(l => this.allowedTerminals.indexOf(l.terminal.organisationShortName) != -1);
  }

  ngOnDestroy(): void {
    this.loadLists.forEach(l => {
      delete l['hidden'];
      delete l['selected'];
      l.overlanders.forEach(c => {
        delete c['hidden'];
        delete c['selected'];
      });
    })
  }
}
