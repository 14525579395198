import {CollectorSummary} from "@portbase/bezoekschip-service-typescriptmodels";

export const WasteCollectionMock: CollectorSummary[] =
  <any[]>[
    {
      "crn": "NLTRM20050285",
      "vesselImoCode": "1234567",
      "vesselName": "Giant Lenski",
      "vesselCallSign": "12Ed@w",
      "vesselFlagCode": "Russia",
      "visitStatus": "EXPECTED",
      "portName": "Rotterdam",
      "portUnCode": "NLRTM",
      "collectorShortName": "GANSEWINKEL",
      "shipOperatorName": "BPA",
      "shipOperatorZipCode": "3161 LL",
      "shipOperatorAddress": "Kweeniehuizen 120",
      "startTime": "2020-11-26T15:00:00Z",
      "endTime": "2020-11-26T18:00:00Z",
      "etaPickup": "2020-11-26T14:00:00Z",
      "expectedItems": [
        {
          "ssn": {
            "code": "101",
            "description": "Oily Bilge water",
            "marpolCategory": "Annex I",
            "specificationRequired": false
          },
          "berthIdForCollection": "6fe0a53e-26b4-4fc1-9b26-a28637a13c73",
          "quantityToBeCollected": 5,
          "specification": null
        },
        {
          "ssn": {
            "code": "201",
            "description": "Category X substance",
            "marpolCategory": "Annex II",
            "specificationRequired": true
          },
          "berthIdForCollection": "6fe0a53e-26b4-4fc1-9b26-a28637a13c73",
          "quantityToBeCollected": 5,
          "specification": "palm oil"
        }
      ],
      "collections": [
        {
          "id": "4Z867KJXHBNWT0VIELJH",
          "wasteCollector": {
            "organisation": {
              "fullName": "ATM",
              "shortName": "ATM1",
              "iamConnectedId": null,
              "portAuthorityId": "ATM",
              "emailAddress": "info@atmmoerdijk.nl",
              "address": "Vlasweg 12",
              "city": "Moerdijk",
              "zipCode": "4785 PW",
              "countryUnCode": "NL",
              "countryName": "Netherlands",
              "phoneNumber": "+31-168-389289",
              "faxNumber": "+31-168-389270",
              "contact": "Danny van Leeuwen",
              "arrivalLocationHouseNumber": null,
              "customsZipCode": null,
              "customsEORINumber": null,
              "ean": null,
              "chamberOfCommerceNumber": null,
              "scacCode": null,
              "ensEnabled": false,
              "cargoImportEnabled": false,
              "cargoImportEnabledByDefault": false
            },
            "vihbNumber": "501809",
            "collectorNumber": "ATM01",
            "annexPerPort": {
              "NLRTM": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLAMS": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLWKD": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLNIJ": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLBZM": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLALB": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLGOR": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLGRA": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLHXG": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLITR": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLKAI": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLKAM": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLLAN": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLLWR": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLMSB": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLMIH": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLOMD": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLRID": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLSLD": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLSTD": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLURK": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLVEN": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLWTE": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLHAR": [
                1,
                2,
                4,
                5,
                6
              ]
            },
            "authorityIdPerPort": {
              "NLRTM": "ATM",
              "NLAMS": "ATM",
              "NLWKD": "ATM",
              "NLNIJ": "ATM",
              "NLBZM": "ATM",
              "NLALB": "ATM",
              "NLGOR": "ATM",
              "NLGRA": "ATM",
              "NLHXG": "ATM",
              "NLITR": "ATM",
              "NLKAI": "ATM",
              "NLKAM": "ATM",
              "NLLAN": "ATM",
              "NLLWR": "ATM",
              "NLMSB": "ATM",
              "NLMIH": "ATM",
              "NLOMD": "ATM",
              "NLRID": "ATM",
              "NLSLD": "ATM",
              "NLSTD": "ATM",
              "NLURK": "ATM",
              "NLVEN": "ATM",
              "NLWTE": "ATM",
              "NLHAR": "ATM",
              "NLSCE": "ATMG"
            }
          },
          "fileNumber": "ATM01/S123FIN",
          "berthVisitId": "6fe0a53e-26b4-4fc1-9b26-a28637a13c73",
          "locationName": "VLDGN NM VOPAK 628",
          "startTime": "2023-06-09T16:00:00Z",
          "endTime": "2023-06-09T17:00:00Z",
          "fixedCosts": 1200.23,
          "items": [
            {
              "wasteMaterial": {
                "typeCode": "Code_7008",
                "euralCode": "13.04.01",
                "iltCode": "iltheetje",
                "ssn": {
                  "code": "101",
                  "description": "Oily Bilge water",
                  "marpolCategory": "Annex I",
                  "specificationRequired": false
                },
                "start": "2023-01-01",
                "end": null,
                "nameNL": "Olie water (vrijkomend van schoonmaken MK)",
                "nameEN": "Oil/Water (derived form cleaning E/R)",
                "unitOfMeasure": "m3",
                "unitConversionToCubicMeters": 1
              },
              "receptionFacility": "knor",
              "amount": 511.62,
              "directCost": null,
              "indirectCost": null,
              "specification": null,
              "valueInCubicMeters": 5
            },
            {
              "wasteMaterial": {
                "typeCode": "Code_7009",
                "euralCode": "16.07.09",
                "ssn": {
                  "code": "201",
                  "description": "Category X substance",
                  "marpolCategory": "Annex II",
                  "specificationRequired": true
                },
                "start": "2023-01-01",
                "end": null,
                "nameNL": "Ladingrestanten Categorie X",
                "nameEN": "Cargo residue Category X",
                "unitOfMeasure": "m3",
                "unitConversionToCubicMeters": 1
              },
              "receptionFacility": "knor",
              "amount": 5,
              "directCost": null,
              "indirectCost": null,
              "specification": "palm oil",
              "valueInCubicMeters": 5
            }
          ],
          "remarks": null,
          "collectorShortName": "ATM1"
        },
        {
          "id": "4Z867KJXHBNWT0FELLJH",
          "wasteCollector": {
            "organisation": {
              "fullName": "ATM",
              "shortName": "ATM1",
              "iamConnectedId": null,
              "portAuthorityId": "ATM",
              "emailAddress": "info@atmmoerdijk.nl",
              "address": "Vlasweg 12",
              "city": "Moerdijk",
              "zipCode": "4785 PW",
              "countryUnCode": "NL",
              "countryName": "Netherlands",
              "phoneNumber": "+31-168-389289",
              "faxNumber": "+31-168-389270",
              "contact": "Danny van Leeuwen",
              "arrivalLocationHouseNumber": null,
              "customsZipCode": null,
              "customsEORINumber": null,
              "ean": null,
              "chamberOfCommerceNumber": null,
              "scacCode": null,
              "ensEnabled": false,
              "cargoImportEnabled": false,
              "cargoImportEnabledByDefault": false
            },
            "vihbNumber": "501809",
            "collectorNumber": "ATM01",
            "annexPerPort": {
              "NLRTM": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLAMS": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLWKD": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLNIJ": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLBZM": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLALB": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLGOR": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLGRA": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLHXG": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLITR": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLKAI": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLKAM": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLLAN": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLLWR": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLMSB": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLMIH": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLOMD": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLRID": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLSLD": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLSTD": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLURK": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLVEN": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLWTE": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLHAR": [
                1,
                2,
                4,
                5,
                6
              ]
            },
            "authorityIdPerPort": {
              "NLRTM": "ATM",
              "NLAMS": "ATM",
              "NLWKD": "ATM",
              "NLNIJ": "ATM",
              "NLBZM": "ATM",
              "NLALB": "ATM",
              "NLGOR": "ATM",
              "NLGRA": "ATM",
              "NLHXG": "ATM",
              "NLITR": "ATM",
              "NLKAI": "ATM",
              "NLKAM": "ATM",
              "NLLAN": "ATM",
              "NLLWR": "ATM",
              "NLMSB": "ATM",
              "NLMIH": "ATM",
              "NLOMD": "ATM",
              "NLRID": "ATM",
              "NLSLD": "ATM",
              "NLSTD": "ATM",
              "NLURK": "ATM",
              "NLVEN": "ATM",
              "NLWTE": "ATM",
              "NLHAR": "ATM",
              "NLSCE": "ATMG"
            }
          },
          "fileNumber": "ATM01/S3242424",
          "berthVisitId": "6fe0a53e-26b4-4fc1-9b26-a28637a13c73",
          "locationName": "VLDGN NM VOPAK 628",
          "startTime": "2023-05-09T16:00:00Z",
          "endTime": "2023-05-09T17:00:00Z",
          "fixedCosts": null,
          "items": [
            {
              "wasteMaterial": {
                "typeCode": "Code_7008",
                "euralCode": "13.04.01",
                "iltCode": "iltheetje",
                "ssn": {
                  "code": "101",
                  "description": "Oily Bilge water",
                  "marpolCategory": "Annex I",
                  "specificationRequired": false
                },
                "start": "2023-01-01",
                "end": null,
                "nameNL": "Olie water (vrijkomend van schoonmaken MK)",
                "nameEN": "Oil/Water (derived form cleaning E/R)",
                "unitOfMeasure": "m3",
                "unitConversionToCubicMeters": 1
              },
              "receptionFacility": "knor",
              "amount": 511.62,
              "directCost": null,
              "indirectCost": null,
              "specification": null,
              "valueInCubicMeters": 5
            },
            {
              "wasteMaterial": {
                "typeCode": "Code_7009",
                "euralCode": "16.07.09",
                "ssn": {
                  "code": "201",
                  "description": "Category X substance",
                  "marpolCategory": "Annex II",
                  "specificationRequired": true
                },
                "start": "2023-01-01",
                "end": null,
                "nameNL": "Ladingrestanten Categorie X",
                "nameEN": "Cargo residue Category X",
                "unitOfMeasure": "m3",
                "unitConversionToCubicMeters": 1
              },
              "receptionFacility": "knor",
              "amount": 5,
              "directCost": null,
              "indirectCost": null,
              "specification": "palm oil",
              "valueInCubicMeters": 5
            }
          ],
          "remarks": null,
          "collectorShortName": "ATM1"
        },
        {
          "id": "0X4LBR5Y3LWZ0230MG4X",
          "wasteCollector": {
            "organisation": {
              "fullName": "ATM",
              "shortName": "ATM1",
              "iamConnectedId": null,
              "portAuthorityId": "ATM",
              "emailAddress": "info@atmmoerdijk.nl",
              "address": "Vlasweg 12",
              "city": "Moerdijk",
              "zipCode": "4785 PW",
              "countryUnCode": "NL",
              "countryName": "Netherlands",
              "phoneNumber": "+31-168-389289",
              "faxNumber": "+31-168-389270",
              "contact": "Danny van Leeuwen",
              "arrivalLocationHouseNumber": null,
              "customsZipCode": null,
              "customsEORINumber": null,
              "ean": null,
              "chamberOfCommerceNumber": null,
              "scacCode": null,
              "ensEnabled": false,
              "cargoImportEnabled": false,
              "cargoImportEnabledByDefault": false
            },
            "vihbNumber": "501809",
            "collectorNumber": "ATM01",
            "annexPerPort": {
              "NLRTM": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLAMS": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLWKD": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLNIJ": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLBZM": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLALB": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLGOR": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLGRA": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLHXG": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLITR": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLKAI": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLKAM": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLLAN": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLLWR": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLMSB": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLMIH": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLOMD": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLRID": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLSLD": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLSTD": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLURK": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLVEN": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLWTE": [
                1,
                2,
                4,
                5,
                6
              ],
              "NLHAR": [
                1,
                2,
                4,
                5,
                6
              ]
            },
            "authorityIdPerPort": {
              "NLRTM": "ATM",
              "NLAMS": "ATM",
              "NLWKD": "ATM",
              "NLNIJ": "ATM",
              "NLBZM": "ATM",
              "NLALB": "ATM",
              "NLGOR": "ATM",
              "NLGRA": "ATM",
              "NLHXG": "ATM",
              "NLITR": "ATM",
              "NLKAI": "ATM",
              "NLKAM": "ATM",
              "NLLAN": "ATM",
              "NLLWR": "ATM",
              "NLMSB": "ATM",
              "NLMIH": "ATM",
              "NLOMD": "ATM",
              "NLRID": "ATM",
              "NLSLD": "ATM",
              "NLSTD": "ATM",
              "NLURK": "ATM",
              "NLVEN": "ATM",
              "NLWTE": "ATM",
              "NLHAR": "ATM",
              "NLSCE": "ATMG"
            }
          },
          "fileNumber": null,
          "berthVisitId": "6fe0a53e-26b4-4fc1-9b26-a28637a13c73",
          "locationName": "VLDGN NM VOPAK 628",
          "startTime": "2023-05-12T10:00:00Z",
          "endTime": null,
          "fixedCosts": null,
          "items": [
            {
              "wasteMaterial": {
                "typeCode": null,
                "euralCode": null,
                "ssn": {
                  "code": "101",
                  "description": "Oily Bilge water",
                  "marpolCategory": "Annex I",
                  "specificationRequired": false
                },
                "start": null,
                "end": null,
                "nameNL": null,
                "nameEN": null,
                "unitOfMeasure": null,
                "unitConversionToCubicMeters": null
              },
              "receptionFacility": "jhlkh",
              "amount": 0,
              "directCost": null,
              "indirectCost": null,
              "specification": null,
              "valueInCubicMeters": 0
            },
            {
              "wasteMaterial": {
                "typeCode": null,
                "euralCode": null,
                "ssn": {
                  "code": "201",
                  "description": "Category X substance",
                  "marpolCategory": "Annex II",
                  "specificationRequired": true
                },
                "start": null,
                "end": null,
                "nameNL": null,
                "nameEN": null,
                "unitOfMeasure": null,
                "unitConversionToCubicMeters": null
              },
              "receptionFacility": "jhlkh",
              "amount": 0,
              "directCost": null,
              "indirectCost": null,
              "specification": null,
              "valueInCubicMeters": 0
            }
          ],
          "remarks": null,
          "collectorShortName": "ATM1"
        }
      ],
      "berthVisits": [{
        "id": "6fe0a53e-26b4-4fc1-9b26-a28637a13c73",
        "code": "R1234",
        "name": "Amerikahaven Rietlanden Palen",
        "eta": "2020-11-26T12:00:00Z",
        "etd": "2020-11-26T20:00:00Z",
        "ata": "2020-11-26T08:00:00Z",
        "atd": null
      }]
    },
    {
      "crn": "NLRTM20953300",
      "portUnCode": "NLRTM",
      "portName": "Rotterdam",
      "vesselName": "CORONA",
      "vesselImoCode": "9169342",
      "vesselFlagCode": "MHL",
      "vesselCallSign": "V7EL5",
      "shipOperatorName": "Vopak Agencies Rotterdam B.V.",
      "shipOperatorZipCode": "3089 JN",
      "shipOperatorAddress": "Droogdokweg 71",
      "collectorShortName": "ATM1",
      "startTime": "2020-12-22T11:00:00Z",
      "endTime": "2020-12-22T12:00:00Z",
      "etaPickup": "2020-12-22T17:00:00Z",
      "visitStatus": "EXPECTED",
      "expectedItems": [],
      "collections": [],
      "berthVisits": [
        {
          "id": "e486d246-52eb-48e3-b470-779cfc480272",
          "code": "R4111",
          "name": "3E PET VOPAK 1",
          "eta": "2020-12-22T19:30:00Z",
          "etd": null,
          "ata": null,
          "atd": null
        }
      ]
    },
    {
      "crn": "NLRTM20953301",
      "portUnCode": "NLRTM",
      "portName": "Rotterdam",
      "vesselName": "INSANE TEST",
      "vesselImoCode": "9228021",
      "vesselFlagCode": "MLT",
      "vesselCallSign": "9HWT6",
      "shipOperatorName": "Cross-Ocean B.V.",
      "shipOperatorZipCode": "3013 AP",
      "shipOperatorAddress": "Conradstraat 38",
      "collectorShortName": "ATM1",
      "startTime": "2020-12-22T19:00:00Z",
      "endTime": "2020-12-22T20:00:00Z",
      "etaPickup": "2020-12-22T14:00:00Z",
      "visitStatus": "EXPECTED",
      "expectedItems": [],
      "collections": [],
      "berthVisits": [
        {
          "id": "a9afb4e5-42d4-4a36-b73e-c7eaf50e1723",
          "code": "R8230",
          "name": "EUROPAH RHENUS",
          "eta": "2020-12-22T15:30:00Z",
          "etd": "2020-12-22T21:00:00Z",
          "ata": null,
          "atd": null
        },
        {
          "id": "5f08f942-bc00-4c09-a382-74037448f50f",
          "code": "R8003",
          "name": "MISSISSIPPIH EMO PLAATS 2",
          "eta": "2020-12-22T22:45:00Z",
          "etd": "2020-12-23T15:00:00Z",
          "ata": null,
          "atd": null
        }
      ]
    }
  ]
