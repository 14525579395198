<div *ngIf="isClearancesActive()" [ngSwitch]="status">
  <span *ngIf="status == 'APPROVED'">
    <i class="fa fa-solid fa-circle fa-stack-1x background"></i>
    <i class="fa fa-circle-check fa-stack-1x approved" aria-hidden="true" data-bs-toggle="tooltip"
       title="Approved"></i>
  </span>
  <span *ngIf="status == 'REJECTED'">
    <i class="fa fa-solid fa-circle fa-stack-1x background"></i>
    <i class="fa fa-circle-xmark fa-stack-1x rejected" aria-hidden="true" data-bs-toggle="tooltip"
       title="Movement not allowed to commence"></i>
  </span>
  <span *ngIf="status == 'APPROVED_WITH_REMARKS'">
    <i class="fa fa-solid fa-circle fa-stack-1x background"></i>
    <i class="fa fa-circle-exclamation fa-stack-1x remarks" aria-hidden="true" data-bs-toggle="tooltip"
       title="Actions required"></i>
  </span>
  <span *ngIf="status == 'PENDING'">
    <i class="fa fa-solid fa-circle fa-stack-1x background"></i>
    <i class="fa fa-circle-location-arrow fa-stack-1x pending" aria-hidden="true" data-bs-toggle="tooltip"
       title="Pending"></i>
  </span>
  <span *ngIf="status == 'UNKNOWN'">
    <i class="fa fa-solid fa-circle fa-stack-1x background"></i>
    <i class="fa fa-circle-question fa-stack-1x" aria-hidden="true" data-bs-toggle="tooltip"
       title="Unknown"></i>
  </span>
</div>
