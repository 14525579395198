import {Component} from '@angular/core';
import {VisitContext} from '../../visit-context';
import {openConfirmationModal, sendCommand} from '../../../common/utils';
import {AcceptDangerousGoods, RejectDangerousGoods} from '@portbase/bezoekschip-service-typescriptmodels';
import {AppContext} from '../../../app-context';
import {PortvisitUtils} from '../../../refdata/portvisit-utils';

@Component({
  selector: 'app-dangerous-goods-admin',
  templateUrl: './dangerous-goods-admin.component.html',
  styleUrls: ['./dangerous-goods-admin.component.css']
})
export class DangerousGoodsAdminComponent {
  protected readonly openConfirmationModal = openConfirmationModal;

  context = VisitContext;
  appContext = AppContext;

  openMessages(type: string) {
    PortvisitUtils.redirectToMessageManagement(VisitContext.visit.crn + '_' + type);
  }

  openIftdgn(processId: string) {
    PortvisitUtils.redirectToMessageManagement(processId);
  }

  acceptDg() {
    sendCommand('com.portbase.bezoekschip.common.api.dangerousgoods.AcceptDangerousGoods', <AcceptDangerousGoods>{
      crn: VisitContext.visit.crn
    }, () => {
      VisitContext.replaceVisit(VisitContext.visit);
      AppContext.registerSuccess('The dangerous goods declaration was accepted successfully');
    });
  }

  rejectDg(reason: string) {
    sendCommand('com.portbase.bezoekschip.common.api.dangerousgoods.RejectDangerousGoods', <RejectDangerousGoods>{
      crn: VisitContext.visit.crn,
      reason: reason
    }, () => {
      VisitContext.replaceVisit(VisitContext.visit);
      AppContext.registerSuccess('The dangerous goods declaration was rejected successfully');
    });
  }
}
