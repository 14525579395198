<div class="row">
  <div class="col-md-6">
    <app-form-group><label>Type</label><input class="form-control" [(ngModel)]="oil.type" disabled required></app-form-group>
    <app-form-group><label>Id</label><input class="form-control" [(ngModel)]="oil['id']" disabled required></app-form-group>
  </div>
  <div class="col-md-6">
    <app-form-group><label>Name</label><input class="form-control" [(ngModel)]="oil.name" required></app-form-group>
    <app-form-group><label>Good code</label><input class="form-control" [(ngModel)]="oil.goodCode"></app-form-group>
  </div>
</div>
