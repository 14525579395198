import {Component} from '@angular/core';
import {AttentionPointContext} from "@portbase/bezoekschip-service-typescriptmodels";
import {ClearanceService} from "./clearance-service";
import {VisitContext} from "../../visit-context";
import {ClearanceUtil} from "./clearance-util";

@Component({
  selector: 'app-mdoh-clearance',
  templateUrl: './mdoh.clearance.component.html',
  styleUrls: ['./mdoh.clearance.component.css']
})
export class MdohClearanceComponent {

  constructor(private clearanceService: ClearanceService) {
  }

  get clearance() {
    return this.clearanceService.getClearance(VisitContext.visit?.clearances, AttentionPointContext.mdoh);
  }

  get shouldShow(): Boolean {
    return ClearanceUtil.shouldShow(this.clearance)
  }
}
