<div class="mx-auto mb-5 mt-4" style="max-width: 1150px">
  <app-tab-panel>
    <app-tab-item [templateRef]="updateRef">
      <span class="tab-item">Update</span>
      <ng-template #updateRef><div class="tab-content"><app-refdata-update></app-refdata-update></div></ng-template>
    </app-tab-item>
    <app-tab-item [templateRef]="importRef">
      <span class="tab-item">Import</span>
      <ng-template #importRef><div class="tab-content"><app-flux-search-import></app-flux-search-import></div></ng-template>
    </app-tab-item>
    <app-tab-item [templateRef]="exportRef">
      <span class="tab-item">Export</span>
      <ng-template #exportRef><div class="tab-content"><app-flux-search-export></app-flux-search-export></div></ng-template>
    </app-tab-item>
  </app-tab-panel>
</div>
