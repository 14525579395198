<div class="row">
  <div class="col-md-6">
    <app-form-group><label>Id</label><input class="form-control" [(ngModel)]="pilotExemption['id']" disabled required></app-form-group>
    <app-form-group><label>Port code</label>
      <app-select id="port" [(ngModel)]="pilotExemption['portCode']" [optionsProvider]="portsOfCall" required
                  placeholder="– Select a port –">
      </app-select>
    </app-form-group>
  </div>
    <div class="col-md-6">
        <app-form-group><label>Number</label><input class="form-control" [(ngModel)]="pilotExemption.number" required></app-form-group>
        <app-form-group><label>Holder name</label><input class="form-control" [(ngModel)]="pilotExemption.holderName" required></app-form-group>
  </div>
</div>
