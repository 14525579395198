import {Component, Input, OnInit} from '@angular/core';
import {VisitContext} from "../../visit-context";
import moment from "moment";
import {AppContext} from "../../../app-context";

@Component({
	selector: 'app-port-departure',
	templateUrl: './port-departure.component.html',
	styleUrls: ['./port-departure.component.css']
})
export class PortDepartureComponent implements OnInit {

	context = VisitContext;
	appContext = AppContext;
  @Input() readonly: boolean;

	constructor() {
	}

	ngOnInit() {
	}

	asTime(date: string) {
		if (date) {
			return moment(date).format('HH:mm');
		}
	}

	userIsNextOwnerOrDeclarant() {
		return VisitContext.isOrganisationNextDeclarant();
	}

	get portDepartureTitle(): string {
		let portDeparture = 'Port departure';
		if (!this.context.isOrganisationNextDeclarant() && this.context.visit.visitDeclaration.portVisit.exitPoint && this.context.visit.visitDeclaration.portVisit.exitPoint.name) {
			portDeparture = this.context.visit.visitDeclaration.portVisit.exitPoint.name;
		}
		else if (this.context.isOrganisationNextDeclarant() && this.context.visit.nextVisitDeclaration.exitPoint && this.context.visit.nextVisitDeclaration.exitPoint.name) {
			portDeparture = this.context.visit.nextVisitDeclaration.exitPoint.name;
		}
		return portDeparture;
	}
}
