<div class="alert alert-info fade show" role="alert" *ngIf="showEtaPortAis()" >
  <div class="me-3 mb-2 d-inline-block">
    Based on AIS data this vessel will reach the pilot boarding place at {{context.visit.etaPortAis | timestamp}}.
    <b>Update ETA pilot boarding place?</b>
  </div>
  <div class="d-inline-block text-nowrap">
    <button (click)="acceptEtaPortAis()" id="acceptEtaPortAis" data-bs-dismiss="alert"
            type="button" class="btn btn-info me-2" style="min-width: 5rem">Update</button>
    <button (click)="ignoreEtaPortAis()" id="ignoreEtaPortAis" data-bs-dismiss="alert"
            type="button" class="btn btn-secondary" style="min-width: 5rem">Ignore</button>
  </div>
</div>
