import {Component, Input} from '@angular/core';
import {BunkerProductSupplier} from "@portbase/bezoekschip-service-typescriptmodels";

@Component({
  selector: 'app-edit-bunker-product-supplier',
  templateUrl: './edit-bunker-product-supplier.component.html',
  styleUrls: ['./edit-bunker-product-supplier.component.css']
})
export class EditBunkerProductSupplierComponent {
  @Input() bunkerProductSupplier: BunkerProductSupplier;
}
