import {Component, Input} from '@angular/core';
import {VisitContext} from "../../../visit-context";
import {
  BaseForPlanning,
  BerthVisit,
  DeclarationType,
  Intention,
  PilotStation,
  PortEntryUnion,
  SeaEntry
} from "@portbase/bezoekschip-service-typescriptmodels";
import moment from "moment";

@Component({
  selector: 'app-port-entry',
  templateUrl: './port-entry.component.html',
  styleUrls: ['./port-entry.component.css']
})
export class PortEntryComponent {

  context = VisitContext;

  @Input() portEntry: PortEntryUnion;
  @Input() pilotStation: PilotStation;
  @Input() firstBerthVisit: BerthVisit;
  @Input() entryDependency;
  @Input() pilotOnBoard: string;


  constructor() {
  }

  intentionsOptions: Intention[] = [Intention.REQUEST_FOR_ENTRY, Intention.WAITING_FOR_ORDERS];
  intentionsFormatter = (purpose: Intention) => {
    switch (purpose) {
      case "REQUEST_FOR_ENTRY":
        return 'Request for entry';
      case "WAITING_FOR_ORDERS":
        return 'Waiting for orders';
    }
  };

  baseForPlanningOptions: BaseForPlanning[] = [BaseForPlanning.PILOT_BOARDING_PLACE, BaseForPlanning.FIRST_BERTH];
  baseForPlanningFormatter = (location: BaseForPlanning) => {
    switch (location) {
      case "PILOT_BOARDING_PLACE":
        return 'Pilot boarding place';
      case "FIRST_BERTH":
        return 'First berth';
    }
  };

  resolveIntention() {
    if (this.portEntry.intention === 'WAITING_FOR_ORDERS') {
      this.portEntry.etaPilotBoardingPlace = null;
      this.portEntry.requestedEtaPilotBoardingPlace = null;
      this.portEntry.baseForPlanning = null;
      this.portEntry.earliestTimeOfPortEntry = null;

      if (this.hasFirstBerth()) {
        this.firstBerthVisit.eta = null;
        this.firstBerthVisit.requestedEta = null;
      }
    } else if (!this.hasFirstBerth()) {
      this.portEntry.baseForPlanning = BaseForPlanning.PILOT_BOARDING_PLACE;
    }
  }

  hasFirstBerth() {
    return this.firstBerthVisit && this.firstBerthVisit.berth != null;
  }

  formatDate(date: string) {
    return date ? moment().isSame(date, 'day')
      ? 'Today, ' + moment(date).format('HH:mm') : moment(date).format('ddd D MMM, HH:mm') : "unknown";
  }

  resolveBaseForPlanning($event: BaseForPlanning) {
    if ($event == "FIRST_BERTH") {
      this.portEntry.earliestTimeOfPortEntry = null;
      this.portEntry.etaPilotBoardingPlace = null;
    } else if (this.pilotStation && this.pilotStation.code === "MC" &&
      !this.context.hasBeenAcceptedAtLeastOnce(DeclarationType.VISIT)) {
      this.portEntry.etaPilotBoardingPlace = (<SeaEntry>this.portEntry).etaSeaBuoy;
    }
    this.context.updateCalculatedEtas();
  }

  planningBasedOnDisabled(): boolean {
    return !this.firstBerthVisit || !(this.portEntry.origin === 'SEA' && this.portEntry.intention === 'REQUEST_FOR_ENTRY') || (!!this.context.visit.incomingTidalWindowStatus && this.context.visit.incomingTidalWindowStatus.tidalWindows.length > 0)
  }
}
