import {Component, Input} from '@angular/core';
import {CrewMember} from '@portbase/bezoekschip-service-typescriptmodels';

@Component({
  selector: 'app-passenger-summary',
  templateUrl: './passenger-summary.component.html',
  styleUrls: ['./passenger-summary.component.css']
})
export class PassengerSummaryComponent {
  count: number;
  first: CrewMember;
  last: CrewMember;

  @Input() set persons(persons: CrewMember[]) {
    this.count = persons.length;
    if (this.count > 0) {
      this.first = persons[0];
      this.last = this.count > 1 ? persons[this.count - 1] : null;
    }
  }
  @Input() id;

  travelDocument(index: number, lastPerson?:boolean) {
    const person = (lastPerson ? this.last : this.first);
    return person && person.travelDocuments[index];
  }
}
