import {AfterViewInit, Component} from '@angular/core';
import {VisitContext} from '../../visit-context';
import {DeclarationType, Port, PreviousPort} from '@portbase/bezoekschip-service-typescriptmodels';
import {v4 as uuid} from 'uuid';
import {DragulaService} from 'ng2-dragula';
import {sendQuery} from '../../../common/utils';
import moment from 'moment';
import {AppContext} from "../../../app-context";

@Component({
  selector: 'app-previous-ports',
  templateUrl: './previous-ports.component.html',
  styleUrls: ['./previous-ports.component.css']
})
export class PreviousPortsComponent implements AfterViewInit {

  context = VisitContext;
  appContext = AppContext;

  portInputFormatter = (port: Port) => port ? port.name + ' – ' + port.locationUnCode : "";

  searchPort = term => sendQuery("com.portbase.bezoekschip.common.api.visit.FindPorts", {term: term});

  addPreviousPort = () => this.context.visit.visitDeclaration.previousPorts.push(<PreviousPort>{
    id: uuid(),
    portFacilityVisits: []
  });

  constructor(private dragulaService: DragulaService) {
  }

  ngAfterViewInit(): void {
    this.dragulaService.find('previousPorts').options.invalid = (el, handle) => {
      return !handle.classList.contains('drag-handle');
    };
    this.dragulaService.find('previousPorts').options.moves = () => {
      return !this.context.isVisitReadonly() || this.appContext.isAdmin();
    };
    this.dragulaService.dropModel('previousPorts').subscribe(value => {
      const previousPort: PreviousPort = value.item;
      previousPort.arrival = null;
      previousPort.departure = null;
    });
  }

  private twoPortsNotChronological(index: number) {
    if (index === 0) {
      return false;
    }
    const port = this.context.visit.visitDeclaration.previousPorts[index];
    const date = port.departure ? port.departure : port.arrival;
    const nextPort = this.context.visit.visitDeclaration.previousPorts[index - 1]
    const nextDate = nextPort.arrival ? nextPort.arrival : nextPort.departure;
    return date && nextDate && moment(date).isAfter(moment(nextDate));
  }

  private portNotChronological(port: PreviousPort) {
    return port.arrival && port.departure && moment(port.arrival).isAfter(moment(port.departure));
  }

  private someFacilitiesAreOutsidePortVisit(port: PreviousPort) {
    if (port.portFacilityVisits.length === 0) {
      return false;
    }
    const arrivalMoment = moment(port.arrival);
    const departureMoment = moment(port.departure);
    let result = false;
    port.portFacilityVisits.forEach(facility => {
      if ((facility.arrivalDate && moment(facility.arrivalDate).isBefore(arrivalMoment, 'day'))
        || (facility.departureDate && departureMoment.isBefore(moment(facility.departureDate), 'day'))) {
        result = true;
      }
    });
    return result;
  }

  isEnabled = (): boolean => {
    return this.appContext.isAdmin() && !this.context.visit.cancelled && !(this.isDeclared())
  }

  private isDeclared(): boolean {
    const latestDeclaration = this.context.findLatestDeclaration(DeclarationType.VISIT);
    if (latestDeclaration) {
      return latestDeclaration.status === 'DECLARED';
    }
  }
}
