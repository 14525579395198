<app-table-view [fields]="fieldsDefinition" [data]="data" [recordTrackBy]="trackByForRow"
                (termUpdated)="term = $event" (addButtonClicked)="edit(null)"
                [editable]="editable && houseConsignmentsCanBeAdded"></app-table-view>

<ng-template #equipmentRef let-record="value">
  <div class="container-wrapper">
    <ng-container *ngFor="let container of record.equipments; index as i; trackBy: trackByEquipmentNumber">
      <div class="d-inline-flex placement-item pe-2">
        <span class="sub-text me-2">
          <i class="fa fa-fw fa-light fa-xl" [ngClass]="utils.getEquipmentIconCls(container.sizeType, container.empty)"></i>
        </span>
        <span class="d-inline-block text-truncate align-self-end cursor-pointer underline-tertiary link-underline-tertiary link-primary text-body"
              (click)="editEquipment(container.equipmentNumber, record.consignmentNumber)">
          <app-highlight [term]="term" [result]="container.equipmentNumber"></app-highlight>
        </span>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #goodsRef let-goods="value">
  <app-consignment-goods-column [term]="term" [goods]="goods"></app-consignment-goods-column>
</ng-template>

<ng-template #actionColumnRef let-record="value">
  <a class="btn rounded-circle p-1 text-primary h1 mb-0 action-btn" type="button" (click)="edit(record)">
    <i class="fa-fw fa-light fa-angle-right"></i>
  </a>
</ng-template>

<ng-template #houseConsignmentRef let-record="value">
  <app-consignment-house-column [houseConsignments]="[record]" [consignmentProcessId]="consignmentProcess.consignmentProcessId"
                                [consignmentProcess]="consignmentProcess"></app-consignment-house-column>
</ng-template>

