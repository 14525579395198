import {AfterViewInit, Component} from '@angular/core';
import {
  CargoAgentStatus,
  DeclarationType,
  GetVisits,
  SearchVisits,
  VisitOverview,
  VisitSummary
} from '@portbase/bezoekschip-service-typescriptmodels';
import moment from 'moment';
import {Observable} from 'rxjs';
import {lodash, removeItem, sendQuery} from '../common/utils';
import {AppContext} from '../app-context';
import {Router} from '@angular/router';
import {VisitFilter, VisitSelection} from "./visit-filter/visit-filter.component";
import {DateTimeRange} from "../common/date/date-range/date-time-range";
import {DateRange} from "../common/date/date-range/date-range";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-visit-overview',
  templateUrl: './visit-overview.component.html',
  styleUrls: ['./visit-overview.component.css']
})
export class VisitOverviewComponent implements AfterViewInit {

  private taskTypes: DeclarationType[] = getTaskTypesForUser();
  private static filter: OverviewFilter;

  appContext = AppContext;
  visitOverview: VisitOverview;
  cachedVisitOverview: VisitOverview;
  visitFilter: VisitFilter = {deselectedOptions: [], selectedOptions: []};
  overviewFilter = VisitOverviewComponent.filter || (VisitOverviewComponent.filter = {
    searchTerm: '',
    dateRange: getDateRange(),
    ownCallsOnly: AppContext.isVisitDeclarant() && !AppContext.isPortAuthority() && !AppContext.isAdmin()
  });

  categories: OverviewCategory[] = [
    {name: 'Planned', property: 'plannedVisits', showOnLoad: true, visible: AppContext.isTerminalPlanner()},
    {name: 'Expected', property: 'expectedVisits', showOnLoad: true, visible: true},
    {name: 'Arrived', property: 'arrivedVisits', showOnLoad: true, visible: true},
    {name: 'Departed', property: 'departedVisits', showOnLoad: true, visible: true},
    {name: 'Cancelled', property: 'cancelledVisits', showOnLoad: true, visible: true},
  ];

  from: number = 0;
  maxItems: number = 100;
  endReached: boolean;
  loading: boolean;

  searchFunction = (searchTerm: string): Observable<any> => {
    const dateTimeRange = !!searchTerm ? null : <DateTimeRange>{
      start: moment(this.overviewFilter.dateRange.start).toISOString(),
      end: moment(this.overviewFilter.dateRange.end).add(1, 'days').toISOString()
    };
    const payload = <GetVisits>{dateTimeRange: dateTimeRange};
    let type = 'com.portbase.bezoekschip.common.api.visit.GetVisits';

    if (this.isPagingSupported()) {
      payload.from = this.from;
      payload.maxHits = this.maxItems;
    }

    if (searchTerm) {
      payload.maxHits = this.maxItems;
      (<SearchVisits>payload).term = searchTerm;
      type = 'com.portbase.bezoekschip.common.api.visit.SearchVisits';
    }
    return sendQuery(type, payload, {caching: false, showSpinner: !this.visitOverview})
      .pipe(map((v: VisitOverview) => {
        v.plannedVisits = this.expandVisitSummary(v.plannedVisits);
        v.arrivedVisits = this.expandVisitSummary(v.arrivedVisits);
        v.cancelledVisits = this.expandVisitSummary(v.cancelledVisits);
        v.departedVisits = this.expandVisitSummary(v.departedVisits);
        v.expectedVisits = this.expandVisitSummary(v.expectedVisits);
        return v;
      }));
  };

  expandVisitSummary = (summaries: VisitSummary[]): VisitSummaryExpanded[] => {
    return summaries.map((v: VisitSummaryExpanded) => {
      v.calculatedOpenTasks = this.getOpenTasks(v);
      return v;
    });
  }

  constructor(public router: Router) {
  }


  /*
    Loading
   */

  ngAfterViewInit(): void {
    this.loadVisits();
  }

  loadVisits = (): void => {
    this.searchFunction(this.overviewFilter.searchTerm).subscribe(this.renderVisits);
  };

  renderVisits = (visitOverview: VisitOverview) => {
    this.cachedVisitOverview = lodash.cloneDeep(visitOverview);
    if (!this.appContext.isAdmin() && this.overviewFilter.ownCallsOnly) {
      let organisation = this.appContext.userProfile.organisation;
      if (!!organisation) {
        let fullName = this.appContext.userProfile.organisation.fullName;
        let shortName = this.appContext.userProfile.organisation.shortName;
        let filter = value => value.owner === fullName || value.declarant === fullName ||
          value.ownerShortName === shortName || value.declarantShortName === shortName;
        visitOverview.plannedVisits = visitOverview.plannedVisits.filter(filter);
        visitOverview.expectedVisits = visitOverview.expectedVisits.filter(filter);
        visitOverview.arrivedVisits = visitOverview.arrivedVisits.filter(filter);
        visitOverview.departedVisits = visitOverview.departedVisits.filter(filter);
        visitOverview.cancelledVisits = visitOverview.cancelledVisits.filter(filter);
      }
    }
    if (this.from > 0) {
      if (countVisits(visitOverview) < this.maxItems) {
        this.endReached = true;
      }
      visitOverview = append(this.visitOverview, visitOverview);
    }

    if (this.visitFilter.selectedOptions.length !== 0) {
      visitOverview.plannedVisits = visitOverview.plannedVisits.filter(expected => this.visitFilter.selectedOptions.every(option => filterItem(option, expected)));
      visitOverview.expectedVisits = visitOverview.expectedVisits.filter(expected => this.visitFilter.selectedOptions.every(option => filterItem(option, expected)));
      visitOverview.arrivedVisits = visitOverview.arrivedVisits.filter(arrived => this.visitFilter.selectedOptions.every(option => filterItem(option, arrived)));
      visitOverview.departedVisits = visitOverview.departedVisits.filter(departed => this.visitFilter.selectedOptions.every(option => filterItem(option, departed)));
      visitOverview.cancelledVisits = visitOverview.cancelledVisits.filter(cancelled => this.visitFilter.selectedOptions.every(option => filterItem(option, cancelled)));
    }
    if (this.visitFilter.deselectedOptions.length !== 0) {
      visitOverview.plannedVisits = visitOverview.plannedVisits.filter(expected => !this.visitFilter.deselectedOptions.every(option => filterItem(option, expected)));
      visitOverview.expectedVisits = visitOverview.expectedVisits.filter(expected => !this.visitFilter.deselectedOptions.every(option => filterItem(option, expected)));
      visitOverview.arrivedVisits = visitOverview.arrivedVisits.filter(arrived => !this.visitFilter.deselectedOptions.every(option => filterItem(option, arrived)));
      visitOverview.departedVisits = visitOverview.departedVisits.filter(departed => !this.visitFilter.deselectedOptions.every(option => filterItem(option, departed)));
      visitOverview.cancelledVisits = visitOverview.cancelledVisits.filter(cancelled => !this.visitFilter.deselectedOptions.every(option => filterItem(option, cancelled)));
    }
    this.visitOverview = visitOverview;

    function filterItem(option: VisitSelection, summary: VisitSummary) {
      return option.toUpperCase() === summary.visitStatus
        || (option === 'Overlanded' && isOverlanded(summary))
        || (option === 'Shortlanded' && isShortLanded(summary))
        || (option === 'Rejections' && hasRejections(summary))
        || (option === 'Inspection' && isInspected(summary))
        || (option === 'ServicesOrdered' && serviceOrdered(summary))
        || (option === 'HarbourMasterRemarks' && hasRemarks(summary))
        || (option === 'Tidal' && isTidalShip(summary));

      function greaterThanZero(cargoAgentStatus: CargoAgentStatus, property: string) {
        let value = cargoAgentStatus && cargoAgentStatus[property];
        return value && value > 0;
      }

      function isInspected(summary: VisitSummary) {
        if (AppContext.isAdmin() || AppContext.isInspectionHandler()) {
          return lodash.values(summary.cargoAgentStatuses).some(status => status.inspectionsStarted || status.inspectionsCompleted)
        }
        const cargoAgentStatus = summary.cargoAgentStatuses[AppContext.userProfile.organisation?.shortName];
        return cargoAgentStatus && (cargoAgentStatus.inspectionsStarted || cargoAgentStatus.inspectionsCompleted);
      }

      function isOverlanded(summary: VisitSummary) {
        if (AppContext.isAdmin()) {
          return lodash.values(summary.cargoAgentStatuses).some(status => greaterThanZero(status, "overlandedContainers"));
        }
        return greaterThanZero(summary.cargoAgentStatuses[AppContext.userProfile.organisation?.shortName],
          "overlandedContainers");
      }

      function isShortLanded(summary: VisitSummary) {
        if (AppContext.isAdmin()) {
          return lodash.values(summary.cargoAgentStatuses).some(status => greaterThanZero(status, "shortlandedContainers"));
        }
        return greaterThanZero(summary.cargoAgentStatuses[AppContext.userProfile.organisation?.shortName],
          "shortlandedContainers");
      }

      function hasRejections(summary: VisitSummary) {
        let taskTypesForUser = getTaskTypesForUser();
        return summary.rejectedTasks && summary.rejectedTasks.some(value => taskTypesForUser.includes(value));
      }

      function hasRemarks(summary: VisitSummary) {
        return summary.harbourMasterRemarks;
      }

      function isTidalShip(summary: VisitSummary) {
        return summary.tidalShip;
      }

      function serviceOrdered(summary: VisitSummary) {
        return summary.orderStatus === 'ORDERED';
      }
    }

    function countVisits(visitOverview: VisitOverview): number {
      return visitOverview.plannedVisits.length +
        visitOverview.expectedVisits.length +
        visitOverview.arrivedVisits.length +
        visitOverview.departedVisits.length +
        visitOverview.cancelledVisits.length;
    }

    function append(current: VisitOverview, added: VisitOverview): VisitOverview {
      if (!current) {
        return added;
      }
      return {
        plannedVisits: current.plannedVisits.concat(added.plannedVisits),
        expectedVisits: current.expectedVisits.concat(added.expectedVisits),
        arrivedVisits: current.arrivedVisits.concat(added.arrivedVisits),
        departedVisits: current.departedVisits.concat(added.departedVisits),
        cancelledVisits: current.cancelledVisits.concat(added.cancelledVisits),
      }
    }
  };

  onDateSelection = (dateRange: DateRange): void => {
    if (dateRange) {
      this.overviewFilter.dateRange = dateRange;
      this.from = 0;
      this.endReached = false;
      this.loadVisits();
      const now = moment().startOf('day');
      localStorage.setItem('overview-days-preference', JSON.stringify({
        daysBefore: now.diff(this.overviewFilter.dateRange.start, 'days'),
        daysAfter: moment(this.overviewFilter.dateRange.end).diff(now, 'days')
      }));
    }
  };

  isPagingSupported = (): boolean => AppContext.isAdminOrCustoms();

  loadNextPage = () => {
    if (this.isPagingSupported() && !this.endReached && !this.loading) {
      this.from += this.maxItems;
      this.loadVisits();
    }
  };


  /*
    Rendering
   */

  getVisits(category: OverviewCategory): VisitSummaryExpanded[] {
    return this.visitOverview[category.property];
  }

  formatDate = (dateString: string): string => !!dateString ? (moment().isSame(dateString, 'day')
    ? 'Today, ' + moment(dateString).format('HH:mm') : moment(dateString).format('ddd D MMM YYYY, HH:mm')) : "";

  getTaskCount = (visitSummary: VisitSummary): number => {
    let taskTypesForUser = this.taskTypes;
    let openTaskCount = visitSummary.openTasks.filter(task => taskTypesForUser.indexOf(task) > -1).length;
    let conditionallyApprovedTaskCount = visitSummary.conditionallyApprovedTasks ? visitSummary.conditionallyApprovedTasks.length : 0;
    return openTaskCount
      + visitSummary.rejectedTasks.length
      + conditionallyApprovedTaskCount;
  };

  responsibleParties(visitSummary: VisitSummary) {
    return visitSummary.declarant === visitSummary.owner ? [visitSummary.declarant] : visitSummary.declarant + ' for ' + visitSummary.owner;
  }

  forOwner(visitSummary: VisitSummary) {
    return visitSummary.declarant !== visitSummary.owner ? visitSummary.owner : null;
  }

  showEtaPortAis = (visitSummary: VisitSummary) => {
    if (visitSummary.visitStatus != 'EXPECTED'
      || visitSummary.cancelled || !visitSummary.etaPortAis || visitSummary.ignoreEtaPortAis) {
      return false;
    }
    const etaPortAis = moment(visitSummary.etaPortAis);
    return Math.abs(etaPortAis.diff(moment(visitSummary.etaPort), 'minutes')) > 30;
  };

  getInspectionStatus(summary: VisitSummary): OpenTask {
    let inspectionsStarted = false;
    let inspectionsCompleted = false;
    if (AppContext.isAdmin() || AppContext.isInspectionHandler()) {
      const statuses = lodash.values(summary.cargoAgentStatuses);
      inspectionsStarted = statuses.some(s => s.inspectionsStarted);
      inspectionsCompleted = inspectionsStarted && statuses.filter(s => s.inspectionsStarted).every(s => s.inspectionsCompleted);
    } else if (AppContext.isInspectionViewer()) {
      const status = summary.cargoAgentStatuses[AppContext.userProfile.organisation?.shortName];
      inspectionsStarted = status && status.inspectionsStarted;
      inspectionsCompleted = status && status.inspectionsCompleted;
    }
    if (inspectionsCompleted) {
      return {color: 'text-success', icon: 'fa-eye', tooltip: 'Inspections completed'};
    } else if (inspectionsStarted) {
      return {color: 'text-secondary', icon: 'fa-eye', tooltip: 'Inspections reported'};
    }
  }

  getOrderStatus(summary: VisitSummary): OpenTask {
    if (isUserOnlyCargoAgent(summary)) {
      return null;
    }
    switch (summary.orderStatus) {
      case 'CONFIRMED':
        return {color: 'text-success', icon: 'fa-clock', tooltip: 'Order confirmed'};
			case "CONFIRMED_NEW_PROPOSAL":
				return {color: 'text-warning', icon: 'fa-clock', tooltip: 'Received a new time proposal from Port Authority'};
			case 'ETD_CHANGED':
        return {color: 'text-warning', icon: 'fa-clock', tooltip: 'ETD has changed'};
      case 'ETA_CHANGED':
        return {color: 'text-warning', icon: 'fa-clock', tooltip: 'ETA has changed'};
      case 'REJECTED':
        return {color: 'text-danger', icon: 'fa-clock', tooltip: 'Order rejected'};
      case 'CANCELLED':
        return {color: 'text-danger', icon: 'fa-clock', tooltip: 'Order cancelled'};
      case 'ORDERED':
        return {color: 'text-portbase-blue', icon: 'fa-paper-plane', tooltip: 'Services ordered'};
    }
  }

  getOpenTasks(summary: VisitSummary): OpenTask[] {
    const result: OpenTask[] = [];
    var cargoAgentStatus = summary.cargoAgentStatuses[AppContext.userProfile.organisation?.shortName];
    let shortLandedContainersForAgent = cargoAgentStatus && cargoAgentStatus.shortlandedContainers;
    let overLandedContainersForAgent = cargoAgentStatus && cargoAgentStatus.overlandedContainers;
    if (isUserOnlyCargoAgent(summary)) {
      result.push({
        color: cargoAgentStatus.dangerousGoodsCompleted ? 'text-light' : 'text-secondary',
        icon: getIcon(DeclarationType.DANGEROUS_GOODS),
        tooltip: getName(DeclarationType.DANGEROUS_GOODS) + cargoAgentStatus.dangerousGoodsCompleted
          ? ' has been completed' : ' has not been completed'
      });
    } else {
      this.taskTypes.forEach(type => {
        if (summary.rejectedTasks.indexOf(type) >= 0) {
          result.push({color: 'text-danger', icon: getIcon(type), tooltip: getName(type) + ' has been rejected'});
        }
        if (summary.conditionallyApprovedTasks && summary.conditionallyApprovedTasks.indexOf(type) >= 0) {
          result.push({color: 'text-warning', icon: getIcon(type), tooltip: getName(type) + ' has been approved with measures imposed'});
        } else if (summary.openTasks.indexOf(type) >= 0) {
          if (type === 'DANGEROUS_GOODS' && hasCompletedDgdeclarations()) {
            result.push({
              color: 'text-info', icon: getIcon(type),
              tooltip: getName(type) + ' declarations have been completed but not declared'
            });
          } else {
            result.push({
              color: 'text-secondary',
              icon: getIcon(type),
              tooltip: getName(type) + ' has not been declared'
            });
          }
        }
      });
    }
    if (shortLandedContainersForAgent && shortLandedContainersForAgent > 0) {
      result.push({
        color: 'text-danger',
        icon: 'fa-balance-scale-left',
        tooltip: shortLandedContainersForAgent + ' shortlanded containers'
      });
    }
    if (overLandedContainersForAgent && overLandedContainersForAgent > 0) {
      result.push({
        color: 'text-danger',
        icon: 'fa-balance-scale-right',
        tooltip: overLandedContainersForAgent + ' overlanded containers'
      });
    }

    if (AppContext.isAdmin()) {
      let totalOverlanded = 0;
      let totalShortlanded = 0;
      let shortlandedToolTip = "";
      let overlandedToolTip = "";
      for (let v in summary.cargoAgentStatuses) {
        let shortlandedContainers = summary.cargoAgentStatuses[v].shortlandedContainers;
        totalShortlanded = totalShortlanded + shortlandedContainers;
        let overlandedContainers = summary.cargoAgentStatuses[v].overlandedContainers;
        totalOverlanded = totalOverlanded + overlandedContainers;
        if (shortlandedContainers && shortlandedContainers > 0) {
          shortlandedToolTip = shortlandedToolTip.concat(v + ": " + shortlandedContainers + "\n");
        }
        if (overlandedContainers && overlandedContainers > 0) {
          overlandedToolTip = overlandedToolTip.concat(v + ": " + overlandedContainers + "\n");
        }
      }

      if (totalShortlanded > 0) {
        result.push({
          color: 'text-danger',
          icon: 'fa-balance-scale-left',
          tooltip: totalShortlanded + ' shortlanded containers \n' + shortlandedToolTip
        });
      }
      if (totalOverlanded > 0) {
        result.push({
          color: 'text-danger',
          icon: 'fa-balance-scale-right',
          tooltip: totalOverlanded + ' overlanded containers \n' + overlandedToolTip
        });
      }
    }


    //add cargo import icon
    if (AppContext.isAdmin() && summary.cargoAgentStatuses) {
      cargoAgentStatus = lodash.values(summary.cargoAgentStatuses)
        .reduce((a, b) => b.cargoImportStatus === 'REJECTED' ? b : a && a.cargoImportStatus === 'REJECTED' ? a
          : b.cargoImportStatus === 'DECLARED' ? b : a && a.cargoImportStatus === 'DECLARED' ? a : b, null);
    }
    if (cargoAgentStatus && cargoAgentStatus.cargoImportEnabled) {
      if (cargoAgentStatus.cargoImportStatus === 'REJECTED') {
        result.push({color: 'text-danger', icon: getIcon(DeclarationType.SDT),
          tooltip: getName(DeclarationType.SDT) + ' has been rejected'});
      } else if (!cargoAgentStatus.cargoImportStatus) {
        result.push({color: 'text-secondary', icon: getIcon(DeclarationType.SDT),
          tooltip: getName(DeclarationType.SDT) + ' has not been declared'
        });
      }
    }
    return result;

    function getIcon(type: DeclarationType): string {
      switch (type) {
        case 'VISIT':
          return 'fa-route';
        case 'SECURITY':
          return 'fa-shield-alt';
        case 'WASTE':
          return 'fa-recycle';
        case 'HEALTH':
          return 'fa-medkit';
        case 'PAX':
          return 'fa-address-card';
        case 'MSV':
          return 'fa-wine-bottle';
        case 'DANGEROUS_GOODS':
          return 'fa-burn';
        case 'SDT':
          return 'fa-caret-square-down';
      }
    }

    function getName(type: DeclarationType): string {
      switch (type) {
        case 'VISIT':
          return 'Visit';
        case 'SECURITY':
          return 'Security';
        case 'WASTE':
          return 'Waste';
        case 'HEALTH':
          return 'Maritime declaration of health';
        case 'PAX':
          return 'Crew and passengers';
        case 'MSV':
          return 'Ship stores';
        case 'DANGEROUS_GOODS':
          return 'Dangerous goods';
        case 'SDT':
          return 'Cargo import';
      }
    }

    function hasCompletedDgdeclarations() {
      if (summary.cargoAgentStatuses) {
        for (let agent in summary.cargoAgentStatuses) {
          if (summary.cargoAgentStatuses[agent].dangerousGoodsCompleted) {
            return true;
          }
        }
      }
      return false;
    }
  }


  /*
    Navigation
   */

  navigateToVisit = (visitSummary: VisitSummary): void => {
    if (visitSummary.oldVisit) {
      const environmentMatch = window.location.href.match(/https:\/\/portvisit\.(.*)\.portbase\.com.*/);
      if (environmentMatch && environmentMatch[1] === 'pcs') {
        window.location.href = 'https://vesselcall.' + AppContext.environment +
          '.portbase.com/beta/index.html#!/details/' + visitSummary.crn + '/portauthorityDetails';
      } else {
        window.location.href = 'https://vesselcall.' + AppContext.environment +
          '.portbase.com/#!/details/' + visitSummary.crn + '/portauthorityDetails';
      }
    } else {
      this.router.navigate(['/details/' + visitSummary.crn]);
    }
  };


  applyVisitFilters = (visitFilter: VisitFilter) => {
    this.visitFilter = visitFilter;
    this.renderVisits(this.cachedVisitOverview);
  };

	hasUnreadRemarks(visitSummary: VisitSummary) {
		return visitSummary.unreadHarbourRemarks ? 'text-warning' : 'text-portbase-green';
	}
}

function getTaskTypesForUser(): DeclarationType[] {
  const result: DeclarationType[] = [DeclarationType.VISIT, DeclarationType.SECURITY, DeclarationType.PAX,
    DeclarationType.HEALTH, DeclarationType.WASTE, DeclarationType.MSV, DeclarationType.DANGEROUS_GOODS];
  if (!AppContext.hasRole('PaxDeclarant')) {
    removeItem(result, DeclarationType.PAX);
  }
  if (!AppContext.hasRole('ShipSuppliesDeclarant')) {
    removeItem(result, DeclarationType.MSV);
  }
  return result;
}

interface OverviewPreference {
  daysBefore: number;
  daysAfter: number;
}

function isUserOnlyCargoAgent(summary: VisitSummary) {
  const shortName = AppContext.userProfile.organisation?.shortName;
  return summary.cargoAgentStatuses && !!summary.cargoAgentStatuses[shortName]
    && !AppContext.isAdmin() && shortName != summary.ownerShortName && shortName != summary.declarantShortName;
}

function getDateRange(): DateRange {
  const preference: OverviewPreference = JSON.parse(localStorage.getItem('overview-days-preference'));
  return preference ? {
      start: moment().subtract(preference.daysBefore, 'd').format('YYYY-MM-DD'),
      end: moment().add(preference.daysAfter, 'd').format('YYYY-MM-DD')
    } :
    {
      start: moment().subtract(7, 'd').format('YYYY-MM-DD'),
      end: moment().add(7, 'd').format('YYYY-MM-DD')
    };
}

interface OverviewFilter {
  dateRange: DateRange;
  searchTerm;
  ownCallsOnly;
}

interface OverviewCategory {
  name: string,
  property: string,
  showOnLoad: boolean,
  visible: boolean
}

interface OpenTask {
  color: string,
  icon: string,
  tooltip: string
}

export interface VisitSummaryExpanded extends VisitSummary {
  calculatedOpenTasks?: OpenTask[];
  vesselImageUrl?: string;
}
