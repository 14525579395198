<app-collapse [rounded]="true" [collapse]="collapsePortArrivalDetails"
              [showOnLoad]="context.incomingMovementRelevantForAgent()" [animateOnLoad]="true"
              [renderWhenCollapsed]="true">
  <!-- SUMMARY -->
  <!-- Port arrival header -->
  <div class="row p-3 g-0 align-items-center">
    <div class="col">
      <!-- Display the pilot station and entry point -->
      <ng-container *ngIf="!context.visit.orderNauticalServices">
        <span class="fw-bold text-dark">{{context.visit.visitDeclaration.portVisit.entryPoint ?
            context.visit.visitDeclaration.portVisit.entryPoint.name : 'Port arrival'}}</span>
      </ng-container>
      <ng-container *ngIf="context.visit.orderNauticalServices">
        <ng-container *ngIf="context.visit.visitDeclaration.portVisit.pilotStation || context.visit.visitDeclaration.portVisit.entryPoint;
                                        then entryPointTitle
                                        else defaultTitle">
        </ng-container>
        <ng-template #entryPointTitle>
          <ng-container *ngIf="context.visit.incomingTidalWindowStatus as status">
          <span *ngIf="status.tidalShip" class="fa text-info fa-fw me-2"
                title="Tidal restricted" [ngClass]="status.tidalWindowType === 'ASTRO' ? 'fa-satellite-dish' : 'fa-water'"></span>
          </ng-container>
          <span class="fw-bold text-dark">
            {{context.visit.visitDeclaration.portVisit.pilotStation?.name}}
            <span *ngIf="context.visit.visitDeclaration.portVisit.entryPoint as entryPoint"> -
              {{entryPoint.name}}</span>
          </span>
        </ng-template>
        <ng-template #defaultTitle>
          <span class="fw-bold text-dark">
            Port arrival
          </span>
        </ng-template>
      </ng-container>
      <span
          *ngIf="context.visit.visitDeclaration.portVisit.portEntry && context.visit.visitDeclaration.portVisit.portEntry.earliestTimeOfPortEntry"
          class="ms-3 text-muted">
        Earliest time of port entry:
        {{asTime(context.visit.visitDeclaration.portVisit.portEntry.earliestTimeOfPortEntry)}},
        {{asDate(context.visit.visitDeclaration.portVisit.portEntry.earliestTimeOfPortEntry, "")}}
      </span>
    </div>
    <div class="col-auto d-none d-md-block text-dark">
      <!-- Placeholder icons -->
    </div>
  </div>
</app-collapse>
<ng-template #collapsePortArrivalDetails>
  <div class="collapse">
    <div class="border rounded-bottom p-3 mb-3">
      <app-port-entry-tab></app-port-entry-tab>
    </div>
  </div>
</ng-template>
