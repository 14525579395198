import {Component, Input} from '@angular/core';
import {PilotExemption} from "@portbase/bezoekschip-service-typescriptmodels";
import {sendQuery} from "../../common/utils";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-edit-pilot-exemption',
  templateUrl: './edit-pilot-exemption.component.html',
  styleUrls: ['./edit-pilot-exemption.component.css']
})
export class EditPilotExemptionComponent {
  @Input() pilotExemption: PilotExemption;
  portsOfCall = sendQuery('com.portbase.bezoekschip.common.api.visit.GetPcsPorts', {}).pipe(map(ports => ports.map(p => p.port.locationUnCode)));
}
