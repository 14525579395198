import {Component, EventEmitter, Input, Output, QueryList} from '@angular/core';
import {ChartData} from "chart.js";
import lodash from "lodash";
import {ServiceMonitoringBaseChart} from "../service-monitoring-base-chart.component";

@Component({
  selector: 'app-shared-chart-legend',
  templateUrl: './shared-chart-legend.component.html',
  styleUrls: ['./shared-chart-legend.component.scss']
})
export class SharedChartLegendComponent {
  @Input() charts: QueryList<ServiceMonitoringBaseChart>;
  @Input() chartData: ChartData[];
  @Input() collapsible: boolean;
  @Output() hiddenLabelsOutput: EventEmitter<string[]> = new EventEmitter<string[]>();
  protected hiddenLabels: string[] = [];

  getAllLabels = () => {
    return lodash.uniq(lodash.flatMap(this.chartData?.filter(c => c)
      .map(c => c.labels.filter(l => l) as string[])))
      .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
  }

  getDatasetColor = (label: string): string => {
    const color = lodash.flatMap(this.chartData?.filter(c => c)
      .map(c => {
        return c.labels.includes(label)
          ? c.datasets[0].backgroundColor[c.labels.indexOf(label)]
          : null;
      }).filter(c => c));
    return color[0];
  }

  toggleVisibility = (label: string) => {
    this.setVisibility(label, !this.hiddenLabels.includes(label), true);
  }

  private setVisibility(label: string, isHidden: boolean, updateCharts: boolean) {
    if (this.charts) {
      this.charts.forEach(c => {
        const index = this.getIndexOf(c, label);
        if (index < 0) {
          return;
        }
        if (c.chartDirective.chart.data.labels.indexOf(label) === index) {
          c.chartDirective.chart.toggleDataVisibility(index);
        } else {
          c.chartDirective.hideDataset(index, isHidden);
        }
        if (updateCharts) {
          c.chartDirective.chart.update("reset");
        }
      });
    }

    if (isHidden) {
      this.hiddenLabels.push(label);
    } else {
      this.hiddenLabels = this.hiddenLabels.filter(h => h !== label);
    }

    this.hiddenLabelsOutput.next(this.hiddenLabels);
  }

  private getIndexOf(c: ServiceMonitoringBaseChart, label: string): number {
    let dataset = c.chartDirective.chart.data.datasets.find(d => d.label === label);
    if (dataset) {
      return c.chartDirective.chart.data.datasets.indexOf(dataset);
    }
    return c.chartDirective.chart.data.labels.indexOf(label);
  }

  toggleSelect(): void {
    const allLabels = this.getAllLabels() as string[];
    const hidden = allLabels.length !== this.hiddenLabels.length;
    const labelsToToggle = hidden
      ? allLabels.filter(l => !this.hiddenLabels.includes(l))
      : allLabels.filter(l => this.hiddenLabels.includes(l));
    labelsToToggle.forEach(l => this.setVisibility(l, hidden, false));
    this.charts.forEach(c => {
      c.chartDirective.chart.update("reset");
    });
  }

  get selectText(): string {
    const allLabels = this.getAllLabels() as string[];
    return allLabels.length === this.hiddenLabels.length ? "Select all" : "Deselect all";
  }
}
