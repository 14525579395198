<div>
  <div *ngIf="pilotBoardingPlaceWarning != null">
    <app-status-alert type="warning">{{pilotBoardingPlaceWarning}}</app-status-alert>
  </div>
  <div *ngIf="additionalIncomingMovementInfo.entryDependencyRemarks != null">
    <app-status-alert type="warning">Remarks: <i>{{additionalIncomingMovementInfo.entryDependencyRemarks}}</i></app-status-alert>
  </div>
  <div *ngIf="cancellationReason != null">
    <app-status-alert type="danger">Cancellation reason: <i>{{cancellationReason}}</i></app-status-alert>
  </div>
  <div *ngIf="incomingMovementHarbourMasterInfo.rejectionReason">
    <app-status-alert type="danger">Harbour master rejection reason: <i>{{incomingMovementHarbourMasterInfo.rejectionReason}}</i></app-status-alert>
  </div>
  <div *ngIf="incomingMovementHarbourMasterInfo.remarks">
    <app-status-alert type="info">Harbour master remarks: <i>{{incomingMovementHarbourMasterInfo.remarks.text}}</i></app-status-alert>
  </div>

  <div *ngIf="getShiftedIncomingOrder">
    <app-status-alert type="warning">Your order of the incoming movement has been confirmed but the arrival time was changed.
    </app-status-alert>
  </div>
  <div *ngIf="movementOnHold">
    <app-status-alert type="warning">Your movement to the first berth has been put on hold by the port authorities and
      may proceed as conditions allow.</app-status-alert>
  </div>

  <!-- Display messages when the vessel has an dependency on another vessel -->
  <div *ngIf="entryDependency">

    <ng-container *ngIf="isAutoOrdered; then autoOrderedEnabled else autoOrderedDisabled"></ng-container>
    <ng-template #autoOrderedDisabled>
      <ng-container *ngIf="portEntryIsOrdered; then portEntryOrdered else portEntryNotOrdered"></ng-container>
    </ng-template>

    <!-- FIRST MOVEMENT IS NOT 'ORDERED', AUTO ORDER IS 'OFF' Scenario #1 -->
    <ng-template #portEntryNotOrdered>
      <div *ngIf="orderingPossible && !additionalIncomingMovementInfo.etdChanged">
        <app-status-alert type="info">
          <span>The movement of {{entryDependency.vesselName}} has been ordered. You may want to order the incoming movement</span><span *ngIf="etaPbpIsBeforeEtdBerth">, but you might have to change your ETA PBP then.</span>
        </app-status-alert>
      </div>

      <div *ngIf="orderingPossible && additionalIncomingMovementInfo.etdChanged">
        <app-status-alert type="info">
          <span>ETD of {{entryDependency.vesselName}} has been changed. You may want to order the incoming movement</span><span *ngIf="etaPbpIsBeforeEtdBerth">, but you might have to change your ETA PBP then.</span>
        </app-status-alert>
      </div>
      <div *ngIf="orderingPossible && additionalIncomingMovementInfo.etaChanged">
        <app-status-alert type="info">
          <span>ETA of {{entryDependency.vesselName}} has been changed. You may want to order the incoming movement</span><span *ngIf="etaPbpIsBeforeEtdBerth">, but you might have to change your ETA PBP then.</span>
        </app-status-alert>
      </div>
    </ng-template>

    <!-- FIRST MOVEMENT IS 'ORDERED', AUTO ORDER IS 'OFF' Scenario #2 -->
    <ng-template #portEntryOrdered>

      <div *ngIf="!orderingPossible && !orderCancelledOrRejected && entryDependency.vesselName">
        <app-status-alert type="warning">
          <span>The movement of {{entryDependency.vesselName}} has not been ordered yet. Harbour master might reject your order of the incoming movement.</span>
        </app-status-alert>
      </div>

      <div *ngIf="orderingPossible && !additionalIncomingMovementInfo.etdChanged">
        <app-status-alert type="info">
          <span>The movement of {{entryDependency.vesselName}} has been ordered. </span>
          <span *ngIf="!etaPbpIsBeforeEtdBerth">The incoming movement had already been ordered; no action required.</span>
          <span *ngIf="etaPbpIsBeforeEtdBerth">You might have to change your ETA PBP.</span>
        </app-status-alert>
      </div>

      <div *ngIf="orderingPossible && additionalIncomingMovementInfo.etdChanged">
        <app-status-alert type="info">
          <span>ETD of {{entryDependency.vesselName}} has been changed. The incoming movement had already been ordered; no action required.</span>
        </app-status-alert>
      </div>
      <div *ngIf="orderingPossible && additionalIncomingMovementInfo.etaChanged">
        <app-status-alert type="info">
          <span>ETA of {{entryDependency.vesselName}} has been changed. The incoming movement had already been ordered; no action required.</span>
        </app-status-alert>
      </div>
    </ng-template>

    <!-- AUTO ORDER IS 'ON' Scenario #3 -->
    <ng-template #autoOrderedEnabled>
        <app-status-alert type="info">
          <span *ngIf="!additionalIncomingMovementInfo.etdChanged">The incoming movement has been ordered automatically, due to order of the movement of {{entryDependency.vesselName}}.</span>
          <span *ngIf="additionalIncomingMovementInfo.etdChanged">ETA PBP has been changed automatically, due to change of ETD of {{entryDependency.vesselName}}.</span>
        </app-status-alert>
    </ng-template>

    <!--  Order of depending vessel got cancelled or rejected -->
    <div *ngIf="orderCancelledOrRejected">
      <app-status-alert type="warning">The movement of {{entryDependency.vesselName}} has been cancelled.
        <span *ngIf="!portEntryIsOrdered">The incoming movement had not been ordered yet; no action required.</span>
        <span *ngIf="portEntryIsOrdered">You may want to cancel your order of the incoming movement.</span>
      </app-status-alert>
    </div>

    <!-- Order of depending vessel is on hold -->
    <div *ngIf="dependentVesselOnHold">
      <app-status-alert type="warning">The movement of {{entryDependency.vesselName}} is on hold.</app-status-alert>
    </div>

  </div>

</div>
