import {Component, Input} from '@angular/core';
import {EkhClearance} from "@portbase/bezoekschip-service-typescriptmodels";
import {ClearanceService} from "./clearance-service";
import {VisitContext} from "../../visit-context";
import {ClearanceUtil} from "./clearance-util";

@Component({
  selector: 'app-ekh-clearance',
  templateUrl: './ekh.clearance.component.html',
  styleUrls: ['./ekh.clearance.component.css']
})
export class EkhClearanceComponent {

  constructor(private clearanceService: ClearanceService) {
  }

  @Input() arrivalBerthVisitId?: string;
  @Input() departureBerthVisitId?: string;

  get clearance() {
    return <EkhClearance>this.clearanceService.getEkhClearance(VisitContext.visit?.clearances, this.arrivalBerthVisitId, this.departureBerthVisitId);
  }

  get channelBound(): boolean {
    return this.clearance?.channelBound;
  }

  get tideRestricted(): boolean {
    return this.clearance?.tideRestricted;
  }

  get shouldShow(): Boolean {
    return ClearanceUtil.shouldShow(this.clearance) || this.channelBound || this.tideRestricted;
  }
}
