import {combineLatest, Observable, of} from 'rxjs';
import {
  Container,
  ContainerGoodData,
  DangerousGoods,
  FindDangerousGoods,
  GoodDataUnion,
  GoodUnion,
  PackageType,
  PackingGroup,
  Port,
  StowageUnion,
  TankStatus
} from '@portbase/bezoekschip-service-typescriptmodels';
import {
  ArrayTemplate,
  Cell,
  exportExcel,
  HardCodedField,
  MappedField,
  NonNegativeQuantityField,
  parseExcel,
  QuantityField,
  RequiredField,
  ValidatedField,
  WorkBookTemplate
} from '../../common/upload/excel.utils';
import {VisitContext} from '../visit-context';
import {invertMap, sendQuery, uuid} from '../../common/utils';
import {catchError, concatMap, map, tap} from 'rxjs/operators';
import {AppContext} from '../../app-context';
import lodash from 'lodash';
import {sortStowage, StowageModelUnion} from "./dangerous-goods.model";
import {isNumeric} from "rxjs/internal-compatibility";

export function downloadDangerousGoods(stowage: StowageUnion[]) {
  stowage.forEach(s => s['good'] = VisitContext.dangerousGoodsDeclaration.dangerousGoods.goods.find(g => g.id === s.goodId));
  const data = {
    imoNumber: VisitContext.visit.vessel.imoCode,
    vesselName: VisitContext.visit.vessel.name,
    portOfCall: VisitContext.visit.portOfCall.port.locationUnCode,
    tanks: stowage.filter(s => s.type === 'tank'),
    holds: stowage.filter(s => s.type === 'hold'),
    containers: stowage.filter(s => s.type === 'container' && !s.trailer),
    trailers: stowage.filter(s => s.type === 'container' && s.trailer),
    breakBulk: stowage.filter(s => s.type === 'breakBulk'),
  };
  return exportExcel("/assets/templates/dangerous-goods-declaration-template-v1.0.xls", template, data);
}

export function uploadDangerousGoods(file: File): Observable<DangerousGoods> {
  return parseExcel(file, template).pipe(concatMap(dg => addGoods(dg)));
}

class TankStatusMapper {
  private static entries = new Map<string, TankStatus>([['Not empty', TankStatus.NOT_EMPTY],
    ['Not empty, inert', TankStatus.NOT_EMPTY_INERT], ['Residue', TankStatus.RESIDUE],
    ['Residue, inert', TankStatus.RESIDUE_INERT], ['Empty', TankStatus.EMPTY],
    ['Empty, not gas free', TankStatus.EMPTY_NOT_GAS_FREE], ['Empty, inert', TankStatus.EMPTY_INERT],
    ['Empty, inert, not gas free', TankStatus.EMPTY_INERT_NOT_GAS_FREE]]);
  private static invertedEntries = invertMap(TankStatusMapper.entries);

  static mapValue(value: string, cell: Cell): TankStatus {
    if (!value) {
      return null;
    }
    if (!TankStatusMapper.entries.has(value)) {
      throw 'Cell ' + cell.cell + ' in sheet "' + cell.sheetName + '\" contains an unknown tank status: ' + value;
    }
    return TankStatusMapper.entries.get(value);
  }

  static exportValue(value: TankStatus): string {
    return TankStatusMapper.invertedEntries.get(value);
  }
}

const template: WorkBookTemplate = {
  sheets: [
    {
      name: 'General',
      template: {
        version: new ValidatedField(new RequiredField('B6'),
          value => {
            if (value !== '1.0') {
              throw 'The version of your Excel file is not supported. Please download the latest template and try again.';
            }
          }),
        imoNumber: new ValidatedField(new RequiredField('B4'),
          value => {
            const expectedImo = VisitContext.visit.vessel.imoCode;
            if (String(value) !== expectedImo) {
              throw 'The IMO code in the Excel file (' + value + ') does not match IMO code of the vessel (' + expectedImo + ').';
            }
          }),
        vesselName: 'A4',
        portOfCall: 'C4',
        goods: new HardCodedField(() => VisitContext.dangerousGoodsDeclaration.dangerousGoods.goods),
        handlings: new HardCodedField(() => VisitContext.dangerousGoodsDeclaration.dangerousGoods.handlings)
      }
    },
    {
      name: 'Verificatie',
      template: {
        verificationUuid: new ValidatedField(new RequiredField('A1'),
          value => {
            if (value !== '3fcdcb3d0e685f2886c129d8e7bb3a4c8cd8bd25') {
              throw 'Your Excel file could not be verified. Please download the latest template and try again.';
            }
          })
      }
    },
    {
      name: 'Dangerous goods - Tank',
      template: {
        tanks: new ArrayTemplate({
          type: new HardCodedField('tank'),
          stowageNumber: new RequiredField('A$'),
          tankStatus: new MappedField(new RequiredField('B$'), TankStatusMapper.mapValue, TankStatusMapper.exportValue),
          portOfLoading: new MappedField('C$', portOfLoadingMapper, portExporter),
          portOfDischarge: new MappedField('D$', portOfDischargeMapper, portExporter),
          weight: new NonNegativeQuantityField('F$'),

          good: {
            goodData: <any>{
              stowageType: new HardCodedField('tank'),
              name: 'E$',
              type: new HardCodedField('oil') //this is as yet unknown but will be replaced later
            },
            radioactive: new HardCodedField(false),
            radioactivity: new HardCodedField({}),
            flashPoint: new QuantityField('G$'),
            meltingPoint: new QuantityField('H$'),
            heatingOrder: new QuantityField('I$'),
            viscosity: new NonNegativeQuantityField('J$'),
            criticalTemperature: new QuantityField('K$'),
            remarks: 'L$',
            type: new HardCodedField('oil') //this is as yet unknown but will be replaced later
          }
        }, [5, 105])
      }
    },
    {
      name: 'Dangerous goods - Hold',
      template: {
        holds: new ArrayTemplate({
          type: new HardCodedField('hold'),
          stowageNumber: new RequiredField('A$'),
          portOfLoading: new MappedField('B$', portOfLoadingMapper, portExporter),
          portOfDischarge: new MappedField('C$', portOfDischargeMapper, portExporter),
          fumigated: new HardCodedField(false),
          weight: new NonNegativeQuantityField('G$'),

          good: {
            goodData: <any>{
              stowageType: new HardCodedField('hold'),
              unCode: 'D$',
              name: 'E$',
              type: new HardCodedField('solid')
            },
            remarks: 'H$',
            type: new HardCodedField('solid')
          }
        }, [5, 105])
      }
    },
    {
      name: 'Dangerous goods - Container',
      template: {
        containers: new ArrayTemplate({
          type: new HardCodedField('container'),
          stowageNumber: new RequiredField('A$'),
          position: new RequiredField('B$'),
          portOfLoading: new MappedField('C$', portOfLoadingMapper, portExporter),
          portOfDischarge: new MappedField('D$', portOfDischargeMapper, portExporter),
          uncleanTankContainer: new MappedField('H$', booleanMapperDefaultFalse),
          trailer: new HardCodedField(false),

          good: {
            goodData: <any>{
              stowageType: new HardCodedField('container'),
              unCode: 'E$',
              packingGroup: new MappedField('F$', packingGroupMapper, packingGroupExporter),
              name: 'G$',
              type: new HardCodedField('containerGood')
            },

            flashPoint: new QuantityField('P$'),
            radioactive: new HardCodedField(false),
            radioactivity: new HardCodedField({}),

            segregationInformation: 'R$',
            remarks: 'S$',
            type: new HardCodedField('containerGood')
          },
          weight: new NonNegativeQuantityField('I$', 0),
          netWeight: new NonNegativeQuantityField('J$'),
          transportInLimitedQuantity: new MappedField('K$', booleanMapper),
          numberOfOuterPackages: new NonNegativeQuantityField('L$'),
          outerPackageType: new MappedField('M$', packageTypeMapper, packageTypeExporter),
          numberOfInnerPackages: new NonNegativeQuantityField('N$'),
          innerPackageType: new MappedField('O$', packageTypeMapper, packageTypeExporter),
          netExplosiveMass: new NonNegativeQuantityField('Q$')
        }, [5, 1005])
      }
    },
    {
      name: 'Dangerous goods - Trailer',
      template: {
        trailers: new ArrayTemplate({
          type: new HardCodedField('container'),
          stowageNumber: new RequiredField('A$'),
          position: new RequiredField('B$'),
          portOfLoading: new MappedField('C$', portOfLoadingMapper, portExporter),
          portOfDischarge: new MappedField('D$', portOfDischargeMapper, portExporter),
          trailer: new HardCodedField(true),

          good: {
            goodData: <any>{
              stowageType: new HardCodedField('container'),
              unCode: 'E$',
              packingGroup: new MappedField('F$', packingGroupMapper, packingGroupExporter),
              name: 'G$',
              type: new HardCodedField('containerGood')
            },

            flashPoint: new QuantityField('O$'),
            radioactive: new HardCodedField(false),
            radioactivity: new HardCodedField({}),

            segregationInformation: 'Q$',
            remarks: 'R$',
            type: new HardCodedField('containerGood')
          },
          weight: new NonNegativeQuantityField('H$'),
          netWeight: new NonNegativeQuantityField('I$'),
          transportInLimitedQuantity: new MappedField('J$', booleanMapper),
          numberOfOuterPackages: new NonNegativeQuantityField('K$'),
          outerPackageType: new MappedField('L$', packageTypeMapper, packageTypeExporter),
          numberOfInnerPackages: new NonNegativeQuantityField('M$'),
          innerPackageType: new MappedField('N$', packageTypeMapper, packageTypeExporter),
          netExplosiveMass: new NonNegativeQuantityField('P$')
        }, [5, 1005])
      }
    },
    {
      name: 'Dangerous goods - Break bulk',
      template: {
        breakBulk: new ArrayTemplate({
          type: new HardCodedField('breakBulk'),
          position: new RequiredField('A$'),
          portOfLoading: new MappedField('B$', portOfLoadingMapper, portExporter),
          portOfDischarge: new MappedField('C$', portOfDischargeMapper, portExporter),

          good: {
            goodData: <any>{
              stowageType: new HardCodedField('container'),
              unCode: 'D$',
              packingGroup: new MappedField('E$', packingGroupMapper, packingGroupExporter),
              name: 'F$',
            },

            flashPoint: new QuantityField('N$'),
            radioactive: new HardCodedField(false),
            radioactivity: new HardCodedField({}),

            segregationInformation: 'P$',
            remarks: 'Q$',
            type: new HardCodedField('containerGood')
          },

          weight: new NonNegativeQuantityField('G$'),
          netWeight: new NonNegativeQuantityField('H$'),
          netExplosiveMass: new NonNegativeQuantityField('O$'),
          transportInLimitedQuantity: new MappedField('I$', booleanMapper),
          numberOfOuterPackages: new NonNegativeQuantityField('J$'),
          outerPackageType: new MappedField('K$', packageTypeMapper, packageTypeExporter),
          numberOfInnerPackages: new NonNegativeQuantityField('L$'),
          innerPackageType: new MappedField('M$', packageTypeMapper, packageTypeExporter)
        }, [5, 105])
      }
    }
  ]
};

function portOfLoadingMapper(portCode: string, cell: Cell): Port {
  if (!portCode) {
    return null;
  }
  portCode = String(portCode).toUpperCase();
  return VisitContext.visit.portOfCall.port.locationUnCode === portCode
    ? VisitContext.visit.portOfCall.port
    : VisitContext.visit.visitDeclaration.previousPorts.map(p => p.port).find(p => p && p.locationUnCode === portCode);
}

function portExporter(port: Port): string {
  return port && port.locationUnCode;
}

function portOfDischargeMapper(portCode: string): Port {
  if (!portCode) {
    return null;
  }
  portCode = String(portCode).toUpperCase();
  if (VisitContext.visit.portOfCall.port.locationUnCode === portCode) {
    return VisitContext.visit.portOfCall.port;
  }
  let port = VisitContext.visit.visitDeclaration.nextPorts.map(p => p.port).find(p => p && p.locationUnCode === portCode);
  if (!port) {
    port = VisitContext.visit.visitDeclaration.previousPorts.map(p => p.port).find(p => p && p.locationUnCode === portCode);
  }
  return port;
}

function packingGroupMapper(value: string, cell: Cell): PackingGroup {
  if (!value) {
    return null;
  }
  switch (value) {
    case 'I (great danger)':
      return PackingGroup.GREAT_DANGER;
    case 'II (medium danger)':
      return PackingGroup.MEDIUM_DANGER;
    case 'III (minor danger)':
      return PackingGroup.MINOR_DANGER;
  }
  throw 'Cell ' + cell.cell + ' in sheet "' + cell.sheetName + '\" contains an unknown enum value: ' + value;
}

function packingGroupExporter(value: PackingGroup): string {
  if (value) {
    switch (value) {
      case 'GREAT_DANGER':
        return "I (great danger)";
      case 'MEDIUM_DANGER':
        return "II (medium danger)";
      case 'MINOR_DANGER':
        return "III (minor danger)";
    }
  }
}

function booleanMapper(value: string, cell: Cell): boolean {
  if (!value) {
    return null;
  }
  switch (value) {
    case 'Yes':
      return true;
    case 'No':
      return false;
  }
  throw 'Cell ' + cell.cell + ' in sheet "' + cell.sheetName + '\" contains an unknown boolean value: ' + value;
}

function booleanMapperDefaultFalse(value: string, cell: Cell): boolean {
  return booleanMapper(value, cell) || false;
}

function packageTypeMapper(value: string, cell: Cell): Observable<PackageType> {
  if (!value) {
    return null;
  }
  return sendQuery('com.portbase.bezoekschip.common.api.visit.GetPackageTypes', {}).pipe(map((p: PackageType[]) => {
    const packageType = p.find(pt => value && value.split(' - ')[0] === pt.code);
    if (!packageType) {
      throw 'Cell ' + cell.cell + ' in sheet "' + cell.sheetName + '\" contains an unknown package type: ' + value;
    }
    return packageType;
  }));
}

function packageTypeExporter(value: PackageType): string {
  return value && value.code + " - " + value.name;
}

function addGoods(dangerousGoods: any): Observable<DangerousGoods> {
  try {
    const goodsMap: Map<string, GoodUnion> = new Map();
    dangerousGoods.containers = dangerousGoods.containers.concat(dangerousGoods.trailers);
    sanitizeContainers(dangerousGoods.containers);
    dangerousGoods.stowageAtArrival = dangerousGoods.tanks.concat(dangerousGoods.holds).concat(dangerousGoods.containers).concat(dangerousGoods.breakBulk);
    dangerousGoods.stowageAtArrival.forEach((s: StowageModelUnion) => {
      if (s['tankStatus'] === 'EMPTY' || s['tankStatus'] === 'EMPTY_INERT') {
        delete s['good'];
        return;
      }
      let stowageGood: GoodUnion = <GoodUnion>s['good'];
      if (isNumeric(stowageGood.goodData['unCode'])) {
        stowageGood.goodData.name = ("0000" + stowageGood.goodData['unCode']).slice(-4); // try searching on unCode
      }

      const key = JSON.stringify(stowageGood);
      let good = null;
      if (s.type !== 'container') {
        good = goodsMap.get(key);
      }
      if (!good && !lodash.isEmpty(stowageGood)) {
        good = stowageGood;
        good.id = uuid();
        goodsMap.set(key, good);
        dangerousGoods.goods.push(good);
      }
      delete s['good'];
      if (good) {
        s.goodId = good.id;
      }
    });
    const results = dangerousGoods.goods.filter(g => !!g.goodData)
      .map(g => !(g.goodData.name) ? of([]) : sendQuery('com.portbase.bezoekschip.common.api.dangerousgoods.FindDangerousGoods', <FindDangerousGoods>{
        stowageType: g.goodData.stowageType,
        term: g.goodData.name, // either unCode or name!
        untyped: true
      }).pipe(tap(val => {
        let items = <GoodDataUnion[]>val;
        if (g.type === 'containerGood') {
          items = matchOnPackingGroup(<ContainerGoodData[]>items, g.goodData.packingGroup);
        }
        if (g.goodData && g.goodData.stowageType === 'tank') {
          items = matchExact(items, g.goodData.name);
        }
        if (items.length === 1) {
          g.goodData = items[0];
          g.type = g.goodData.type;
          if (g.type === 'containerGood') {
            g.radioactive = g.goodData.hazardClass === '7';
          }
        } else {
          g.goodData = null;
          dangerousGoods.stowageAtArrival.filter(value => value.goodId === g['id']).forEach(s => {
            s['newGood'] = true;
          });
        }
      })));
    return results.length === 0 ? of(dangerousGoods) : combineLatest(results).pipe(catchError(e => {
      AppContext.registerError(e);
      throw e;
    }), map(r => dangerousGoods));
  } catch (e) {
    AppContext.registerError(e);
    throw e;
  }

  function matchOnPackingGroup(items: ContainerGoodData[], stowagePackingGroup: PackingGroup): ContainerGoodData[] {
    const result: ContainerGoodData[] = items.filter(value => value.packingGroup === stowagePackingGroup);
    return result.length === 0 ? items : result;
  }

  function matchExact(items: GoodDataUnion[], exactName: string): GoodDataUnion[] {
    const result: GoodDataUnion[] = items.filter(value => value.name === exactName);
    return result.length === 0 ? items : result;
  }

  function sanitizeContainers(containers: Container[]) {
    sortStowage(containers);
    assertTankContainersContainSingleGood(containers);
    assertEmptyTankContainerIsClean(containers);
    groupContainerItemsByNumber(containers);

    function assertTankContainersContainSingleGood(containers: Container[]) {
      const errorList: string[] = [];
      containers.forEach((value, index) => {
        const previous = index - 1;
        let uncleanTankContainerHasItems = index !== 0 && containers[previous].uncleanTankContainer && containers[previous].stowageNumber === value.stowageNumber;
        if (uncleanTankContainerHasItems) {
          errorList.push(value.stowageNumber);
        }
      });
      if (errorList.length > 0) {
        throw new Error('It is not allowed to report multiple goods in unclean tankcontainer(s): ' + errorList.join(', '));
      }
    }

    function assertEmptyTankContainerIsClean(containers: Container[]) {
      const errorList: string[] = [];
      containers.forEach(value => {
        let uncleanTankHasValues = value.uncleanTankContainer && (value.weight || value.numberOfOuterPackages || value.outerPackageType
          || value.transportInLimitedQuantity);
        if (uncleanTankHasValues) {
          errorList.push(value.stowageNumber);
        }
      });
      if (errorList.length > 0) {
        throw new Error('It is not allowed to report weight, outer packages and transport in limited qty in unclean tankcontainer(s): ' + errorList.join(', '));
      }
    }

    function groupContainerItemsByNumber(containers: Container[]) {
      const containerMap = new Map<string, container>();
      containers.forEach(c => {
        const existing = containerMap.get(c.stowageNumber);
        if (!existing) {
          containerMap.set(c.stowageNumber, {
            position: c.position,
            portOfLoading: c.portOfLoading,
            portOfDischarge: c.portOfDischarge
          });
        } else {
          c.position = existing.position;
          c.portOfLoading = existing.portOfLoading;
          c.portOfDischarge = existing.portOfDischarge;
        }
      });

      interface container {
        position: string
        portOfLoading: Port;
        portOfDischarge: Port;
      }
    }
  }

}
