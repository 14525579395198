import {Component, Input} from '@angular/core';
import {AppContext} from '../../../app-context';
import {DeclarationStatus, HealthDeclarationStatus} from '@portbase/bezoekschip-service-typescriptmodels';
import {VisitContext} from "../../visit-context";

@Component({
  selector: 'app-declaration-status-health',
  templateUrl: './declaration-status-health.component.html',
  styleUrls: ['./declaration-status-health.component.scss']
})
export class DeclarationStatusHealthComponent {
  @Input() link : string;
  @Input() icon : string;
  @Input() tooltipTitle : string;
  @Input() status : DeclarationStatus;
  @Input() hamisHealthStatus : HealthDeclarationStatus;

 
  appContext = AppContext;
  visitContext = VisitContext;

  getFullMenuTooltip(): string {
    return this.tooltipTitle + this.getTooltipStatusExtension();
  }

  getTooltipStatusExtension(): string {
    if (this.status === 'DECLARED' && !this.hamisHealthStatus) {
      return " - Declaration has been sent but we haven't received a response"
    }
    
    else if (this.status === 'ACCEPTED' && !this.hamisHealthStatus) {
      return " - Declaration has been received"
    }
    
    else if (this.status === 'REJECTED' && !this.hamisHealthStatus) {
      return " - Declaration has been technically rejected"
    }

    else if (this.status === 'ACCEPTED' && this.hamisHealthStatus === 'APPROVED') {
      return " - Declaration has been approved by Port Authority"
    }

    else if (this.status === 'ACCEPTED' && this.hamisHealthStatus === 'APPROVED_MEASURES_IMPOSED') {
      return " - Declaration has been approved by Port Authority with measures imposed"
    }

    else if (this.status === 'ACCEPTED' && this.hamisHealthStatus === 'REJECTED') {
      return " - Declaration has been rejected by Port Authority"
    }

    else {
      return ""
    }
  }
}
