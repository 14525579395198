import {Component, Input} from '@angular/core';
import {
  ContainerType,
  DischargeResult, GoodsItem,
  Port,
  SizeType
} from '@portbase/bezoekschip-service-typescriptmodels/common';
import {PortvisitUtils} from '../../../../refdata/portvisit-utils';
import {VisitContext} from '../../../visit-context';
import {lodash, removeIf, removeItem} from '../../../../common/utils';
import {ImportContainerModel, Manifest} from '../../cargo-import.model';

@Component({
  selector: 'app-import-container',
  templateUrl: './import-container.component.html',
  styleUrls: ['./import-container.component.css']
})
export class ImportContainerComponent {
  context = VisitContext;
  utils = PortvisitUtils;
  model: ImportContainerModel;
  dischargeResult : DischargeResult;
  manifest: Manifest;
  @Input() goodsItem: GoodsItem;

  @Input() set container(container : ImportContainerModel) {
    this.model = container;
    this.dischargeResult = VisitContext.cargoImportModel.discharges[container.number] || <any>{};
    this.manifest = VisitContext.cargoImportModel.manifests.find(
      m => m.containers.some(c => c.number === container.number));
    if (this.manifest && !this.goodsItem) {
      this.goodsItem = lodash.flatMap(this.manifest.consignments, c => c.goodsItems)
        .find(g => g.placements.some(p => p.equipmentNumber === container.number));
    }
  }

  changeContainerNumber($event){
    const containerNumber = $event.target.value.toUpperCase().trim();
    const existingContainer = lodash.flatMap(VisitContext.cargoImportModel.manifests, m => m.containers).find(
      c => c.number === containerNumber);
    if (existingContainer) {
      VisitContext.cargoImportModel.manifests.forEach(m => removeItem(m.containers, existingContainer));
      this.container = lodash.assign(this.model, existingContainer);
    } else {
      this.model.number = containerNumber;
      this.model.shippersOwned = this.model.number && !PortvisitUtils.isContainerNumber(this.model.number);
    }
  }

  getSizeType(containerType: ContainerType): SizeType {
    switch (containerType) {
      case 'CHASSIS':
        return {
          "code": "99U7",
          "name": "Chassis"
        };
      case 'SWAP_BODY':
        return {
          "code": "99U8",
          "name": "Swap body"
        };
      case 'TRAILER':
        return {
          "code": "99U9",
          "name": "Trailer"
        };
    }
    return null;
  }

  getPreviousPorts() : Port[] {
    return lodash.uniqBy(VisitContext.savedVisit.visitDeclaration.previousPorts.map(p => p.port), p => p.locationUnCode);
  }
}
