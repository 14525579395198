import {Component, Input} from '@angular/core';
import {ComparatorChain} from "../../../common/comparator-chain";
import {PortvisitUtils} from "../../../refdata/portvisit-utils";
import {LoadingResult} from "@portbase/bezoekschip-service-typescriptmodels";

@Component({
  selector: 'app-loading-overlanders',
  templateUrl: './loading-overlanders.component.html',
  styleUrls: ['./loading-overlanders.component.css']
})
export class LoadingOverlandersComponent {
  private static itemComparator = new ComparatorChain('empty', 'number').compare;

  utils = PortvisitUtils;
  overlanders : LoadingResult[] = [];
  @Input() showTerminal : boolean;
  @Input() readonly : boolean;

  @Input() set setOverlanders(overlanders : LoadingResult[]) {
    this.overlanders = overlanders.sort(LoadingOverlandersComponent.itemComparator);
  }

  get hiddenItems(): LoadingResult[] {
    return this.overlanders.filter(g => g['hidden']);
  }

  get visibleItems(): LoadingResult[] {
    return this.overlanders.filter(g => !g['hidden']);
  }

  showAll() {
    this.overlanders.forEach(g => {
      delete g['hidden'];
      g['forceVisible'] = true;
    });
  }

  get selectedItems() : LoadingResult[] {
    return this.overlanders.filter(s => !!s['selected'] && !s['hidden']);
  }

  toggleSelectAll() {
    if (this.selectedItems.length === this.visibleItems.length) {
      this.visibleItems.forEach(c => c['selected'] = false);
    } else {
      this.visibleItems.forEach(c => c['selected'] = true);
    }
  }
}
