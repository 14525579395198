import {Component, Input, OnInit} from '@angular/core';
import {GoodsItemWithHouseConsignments} from "../../../consignment.utils";
import {AppContext} from "../../../../../app-context";
import {openEditModal} from "../../../../../common/utils";
import {
  GoodsConsignmentDetailsComponent,
  GoodsConsignmentDetailsComponentData
} from "../../../details/goods-consignment-details/goods-consignment-details.component";
import {TaskMessageStatus} from "@portbase/bezoekschip-service-typescriptmodels";
import {DeclarationMessageStatus} from "../../../../visit-overview/visit-overview-item/visit-overview.utils";

@Component({
  selector: 'app-consignment-bulk-goods-overview-item',
  templateUrl: './consignment-bulk-goods-overview-item.component.html',
  styleUrls: ['./consignment-bulk-goods-overview-item.component.scss']
})
export class ConsignmentBulkGoodsOverviewItemComponent implements OnInit {
  appContext = AppContext;

  @Input() consignmentProcessId: string;
  @Input() goodsItem: GoodsItemWithHouseConsignments;
  @Input() term: string;
  @Input() depth: number;
  @Input() selectable: boolean;
  @Input() collapsible: boolean;

  selectionWidth: number = 40;
  paddingPerStep: number = 20;

  collapsed: boolean;

  dischargeStatus: DeclarationMessageStatus;
  dischargeStatuses: DeclarationMessageStatus[];

  get indentionDepth() {
    return this.depth * this.paddingPerStep;
  }

  ngOnInit() {
    const taskStatus = this.goodsItem.goodsItem.bulkDischarge
      ? this.goodsItem.goodsItem.bulkDischarge.grossWeight !== this.goodsItem.goodsItem.grossWeight
        ? TaskMessageStatus.WARNING : TaskMessageStatus.ACCEPTED : TaskMessageStatus.DISABLED;
    this.dischargeStatus = {
      taskStatus: taskStatus,
      name: "Discharged",
      messages: taskStatus === "WARNING" ? [`Terminal reported discharge of ${this.goodsItem.goodsItem.bulkDischarge.grossWeight}kg gross weight`] : []
    };
    this.dischargeStatuses = [this.dischargeStatus];
  }

  editGoodsItem = () => openEditModal(GoodsConsignmentDetailsComponent, <GoodsConsignmentDetailsComponentData>{
    consignmentProcessId: this.consignmentProcessId,
    goodsItemNumber: this.goodsItem.goodsItem.goodsItemNumber,
    houseConsignmentNumber: this.goodsItem.houseConsignments?.length === 1 ? this.goodsItem.houseConsignments[0] : null
  }, {
    warnOnClose: true,
    currentToStack: true
  });
}
