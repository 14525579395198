import {Injectable} from '@angular/core';
import {of, throwError} from 'rxjs';
import {CommandHandler} from '../common/command-handler';
import {VisitMock} from './visit-details.mock';
import {
  AcceptCargoImport,
  AuthoriseNextDeclarant,
  CancelCommercialRelease,
  CancelVisit,
  CancelWaste,
  CommercialRelease,
  CompleteDangerousGoods,
  CreateVisit,
  Declaration,
  DeclarationStatus,
  DeclarationType,
  DeclareCargoImport,
  DeclareCommercialRelease,
  DeclareDangerousGoods,
  DeclareDischargeList,
  DeclareManifestTransit,
  DeclarePax,
  DeclareSecurity,
  DeclareShipStores,
  DeclareTerminalPlanning,
  DeclareVisit,
  DeclareWaste,
  EncryptValue,
  FinishTerminalPlanning,
  IE3CancelTranshipment,
  InputDeclareBunkering,
  MergeOrganisationRefdata,
  RefuseDischargeOverlanded,
  RegisterCustomer,
  RegisterTerminalPlanningUsingId,
  RejectCargoImport,
  RejectCustomerReference,
  RejectEns,
  RemoveCustomer,
  ResendCargoImport,
  SaveDangerousGoods,
  SaveTransit,
  SaveVisit,
  SaveWaste,
  SyncVisitToWpcs,
  UnAuthoriseNextDeclarant,
  VerifyCustomerReference,
  Visit
} from '@portbase/bezoekschip-service-typescriptmodels';
import {delay} from 'rxjs/operators';
import {BezoekschipQueryHandlerMock} from './bezoekschip-query-handler.mock';
import {VisitContext} from '../visit-details/visit-context';
import {lodash, removeItem, replaceItem} from '../common/utils';
import {CommercialReleaseMock} from './commercial-release.mock';
import {initializeCommercialRelease} from '../commercial-release/commercial-release.utils';
import {InjectorProvider} from '../common/injector-provider';
import {EventGateway} from '../common/event-gateway';
import moment from "moment";

export const myParties = [
  {
    id: "test",
    name: "Crazy Pete",
    address: "partyStreet 123",
    city: "Party Ville",
    countryCode: "FR",
    eoriNumber: "eoriForParty",
    zipCode: "party321"
  }
];
export const myWarehouseLicenses = [{
  id: "test",
  holderName: "holder",
  customsAuthorityId: "authId",
  licenseNumber: "licenseNr"
}];

@Injectable()
export class BezoekschipCommandHandlerMock extends CommandHandler {

  'com.portbase.bezoekschip.common.api.visit.CreateVisit' = (command: CreateVisit) => {
    VisitMock[command.crn] = <Visit>{
      crn: command.crn,
      copyOfVisit: command.copyOfVisit,
      portOfCall: command.portOfCall,
      vessel: command.vessel,
      owner: command.owner,
      declarant: command.declarant,
      financialDeclarant: command.declarant,
      creditNumberHolderSameAsDeclarant: true,
      cargoDeclarants: command.cargoDeclarants,
      shipOperators: [command.owner, command.declarant, command.portOfCall.portAuthority],
      allowedViewers: [command.owner, command.declarant],
      visitDeclaration: command.visitDeclaration,
      securityDeclaration: command.securityDeclaration,
      orderNauticalServices: command.orderNauticalServices,
      orderIncomingMovement: command.orderIncomingMovement,
      ignoreEtaPortAis: false,
      cancelled: false,
      terminalPlanningEnabled: command.terminalPlanningEnabled,
      planned: command.visitDeclaration.portVisit.berthVisits.some(b => b.terminalPlanningEnabled),
      declarations: []
    };
  };

  'com.portbase.bezoekschip.common.api.visit.SaveVisit' = (command: SaveVisit) => {
    return of(null);
  };

  'com.portbase.bezoekschip.common.api.visit.SyncVisitToWpcs' = (command: SyncVisitToWpcs) => {
    return of(null);
  };

  'com.portbase.bezoekschip.common.api.visit.DeclareVisit' = (command: DeclareVisit) => {
    this.accept(DeclarationType.VISIT);
    return of(null).pipe(delay(500));
  };

  'com.portbase.bezoekschip.common.api.waste.SaveWaste' = (command: SaveWaste) => {
    return of(null);
  };

  'com.portbase.bezoekschip.common.api.waste.DeclareWaste' = (command: DeclareWaste) => {
    this.accept(DeclarationType.WASTE);
    return of(null).pipe(delay(500));
  };

  'com.portbase.bezoekschip.common.api.waste.CancelWaste' = (command: CancelWaste) => {
    return of(null);
  };

  'com.portbase.bezoekschip.common.api.dangerousgoods.SaveDangerousGoods' = (command: SaveDangerousGoods) => {
    return of(null);
  };

  'com.portbase.bezoekschip.common.api.dangerousgoods.CompleteDangerousGoods' = (command: CompleteDangerousGoods) => {
    VisitContext.dangerousGoodsDeclaration.completed = true;
    return of(null);
  };

  'com.portbase.bezoekschip.common.api.dangerousgoods.DeclareDangerousGoods' = (command: DeclareDangerousGoods) => {
    VisitContext.visit.declarations.push(<Declaration>{
      type: DeclarationType.DANGEROUS_GOODS,
      status: DeclarationStatus.DECLARED,
      timeStamp: moment().toISOString()
    });

    this.accept(DeclarationType.DANGEROUS_GOODS);
    return of(null).pipe(delay(100));
  };

  'com.portbase.bezoekschip.common.api.dangerousgoods.CancelDangerousGoods' = (command) => {
    return of(null);
  };

  'com.portbase.bezoekschip.common.api.pax.DeclarePax' = (command: DeclarePax) => {
    this.reject(DeclarationType.PAX, 'Gewoon omdat het kan!!!');
    return of(null).pipe(delay(500));
  };


  'com.portbase.bezoekschip.common.api.security.DeclareSecurity' = (command: DeclareSecurity) => {
    this.reject(DeclarationType.SECURITY, 'Gewoon niet veilig!!!');
    return of(null).pipe(delay(500));
  };

  'com.portbase.bezoekschip.common.api.shipstores.DeclareShipStores' = (command: DeclareShipStores) => {
    this.accept(DeclarationType.MSV);
    return of(null).pipe(delay(500));
  };

  'com.portbase.bezoekschip.common.api.pax.CancelPax' = (command) => {
    // return of(null);
    return throwError('jij, ouwe keeshond!');
  };

  'com.portbase.bezoekschip.common.api.visit.CancelVisit' = (command : CancelVisit) => {
    return of(null);
  };

  'com.portbase.bezoekschip.common.api.authorisation.UpdateCurrentOrganisationPreferences' = (command) => {
    BezoekschipQueryHandlerMock.organisationPreferences = command;
    return of(null);
  };

  'com.portbase.bezoekschip.common.api.authorisation.UpdateCurrentUserPreferences' = (command) => {
    BezoekschipQueryHandlerMock.userPreferences = command;
    return of(null);
  };

  'com.portbase.bezoekschip.common.api.accountmanagement.AuthoriseOrganisation' = (command) => {
    return of(null);
  };

  'com.portbase.bezoekschip.common.api.refdata.MergeTransitTemplate' = (command) => {
    return of(null);
  }

  'com.portbase.bezoekschip.common.api.transit.DeclareTransit' = (command) => {
    return of(null);
  };

  'com.portbase.bezoekschip.common.api.transit.RequestTransitRelease' = (command) => {
    return of(null);
  };

  'com.portbase.bezoekschip.common.api.transit.SaveTransit' = (command: SaveTransit) => {
    return of(null);
  };

  'com.portbase.bezoekschip.common.api.visit.RegisterAtaUsingId' = (command) => {
    return of(null);
  };

  'com.portbase.bezoekschip.common.api.visit.RegisterAtdUsingId' = (command) => {
    return of(null);
  };

  'com.portbase.bezoekschip.common.api.visit.DeclareTerminalPlanning' = (command: DeclareTerminalPlanning) => {
    this.accept(DeclarationType.TERMINAL_PLANNING);
    return of(null).pipe(delay(500));
  };

  'com.portbase.bezoekschip.common.api.visit.RegisterTerminalPlanningUsingId' = (command: RegisterTerminalPlanningUsingId) => {
    return of(null);
  };

  'com.portbase.bezoekschip.common.api.visit.terminal.FinishTerminalPlanning' = (command: FinishTerminalPlanning) => {
    return of(null);
  }

  'com.portbase.bezoekschip.common.api.visit.IgnoreAisEta' = command => {
    return of(null);
  };

  'com.portbase.bezoekschip.common.api.waste.collector.RegisterWasteCollection' = command => {
    return of(null);
  };

  'com.portbase.bezoekschip.common.api.waste.collector.SubmitFinancials' = command => {
    return of(null);
  };


  'com.portbase.bezoekschip.common.api.waste.collector.CompleteWasteCollection' = command => {
    return of(null);
  };

  'com.portbase.bezoekschip.common.api.waste.collector.CancelWasteCollection' = command => {
    return of(null);
  };

  "com.portbase.bezoekschip.common.api.admin.ResubmitDeclarations" = command => {
    return of(null);
  };

  "com.portbase.bezoekschip.common.api.refdata.MergeParty" = (command: MergeOrganisationRefdata) => {
    this.mergeOrgRefdata(myParties, command);
  };

  "com.portbase.bezoekschip.common.api.refdata.MergeWarehouseLicense" = (command: MergeOrganisationRefdata) => {
    this.mergeOrgRefdata(myWarehouseLicenses, command);
  };

  "com.portbase.bezoekschip.common.api.consignments.commands.CancelTranshipment" = (command: IE3CancelTranshipment) => {
    return of(null);
  }

  "com.portbase.bezoekschip.common.api.cargo.DeclareCargoImport" = (command: DeclareCargoImport) => {
    VisitContext.cargoImportModel.manifests.forEach(m => VisitContext.cargoImportModel.declarations.push(<Declaration>{
      type: 'SDT',
      status: 'DECLARED',
      id: m.id
    }));
  }

  "com.portbase.bezoekschip.common.api.cargo.AcceptCargoImport" = (command: AcceptCargoImport) => {
    lodash.first(VisitContext.cargoImportModel.declarations
      .filter(d => (d.status === 'DECLARED' || d.status === 'REJECTED')
        && d.type === 'SDT' && d.id === command.portOfLoadingUnCode)).status = DeclarationStatus.ACCEPTED;
    VisitContext.replaceVisit(VisitContext.visit);
  }

  "com.portbase.bezoekschip.common.api.cargo.RejectCargoImport" = (command: RejectCargoImport) => {
    const first = lodash.first(VisitContext.cargoImportModel.declarations
      .filter(d => d.status === 'DECLARED' && d.type === 'SDT' && d.id === command.portOfLoadingUnCode));
    first.status = DeclarationStatus.REJECTED;
    first.rejectReasons = command.reason;
    VisitContext.replaceVisit(VisitContext.visit);
  }

  "com.portbase.bezoekschip.common.api.cargo.ResendCargoImport" = (command: ResendCargoImport) => {
    VisitContext.cargoImportModel.manifests.forEach(m => {
      if (m.portOfLoading) {
        VisitContext.cargoImportModel.declarations.push(<Declaration>{type: 'SDT', status: 'DECLARED', id: m.portOfLoading.locationUnCode})
      }
    })
    VisitContext.replaceVisit(VisitContext.visit);
  }

  "com.portbase.bezoekschip.common.api.cargo.RejectEns" = (command: RejectEns) => {
    const first = lodash.first(VisitContext.cargoImportModel.declarations
      .filter(d => d.status === 'DECLARED' && d.type === 'ENS' && d.id === command.consignmentNumber));
    first.status = DeclarationStatus.REJECTED;
    first.rejectReasons = command.reason;
    VisitContext.replaceVisit(VisitContext.visit);
  }

  "com.portbase.bezoekschip.common.api.cargo.DeclareDischargeList" = (command: DeclareDischargeList) => {
  }

  "com.portbase.bezoekschip.common.api.messaging.TryRefreshRefData" = (command: DeclareDischargeList) => {
  }


  "com.portbase.bezoekschip.common.api.cargo.RefuseDischargeOverlanded" = (command: RefuseDischargeOverlanded) => {
  }

  "com.portbase.bezoekschip.common.api.authorisation.AuthoriseNextDeclarant" = (command: AuthoriseNextDeclarant) => {
    return of(null);
  }

  "com.portbase.bezoekschip.common.api.authorisation.UnAuthoriseNextDeclarant" = (command: UnAuthoriseNextDeclarant) => {
    return of(null);
  }

  "com.portbase.bezoekschip.common.api.authorisation.TransferVisit" = (command: UnAuthoriseNextDeclarant) => {
    return of(null);
  }

  'com.portbase.bezoekschip.common.api.transit.DeclareManifestTransit' = (command: DeclareManifestTransit) => {
    return command.declarations.length - 1;
  }

  'com.portbase.bezoekschip.common.api.transit.SaveManifestTransit' = (command: DeclareManifestTransit) => {
    console.log(command);
  }

  'com.portbase.bezoekschip.common.api.commercialrelease.DeclareCommercialRelease' = (command: DeclareCommercialRelease) => {
    this.updateCommercialRelease(command.id, r => {
      r = initializeCommercialRelease({crn: command.crn, releaseData: command.releaseData, id: command.id});
      r.releaseStatus.cancelled = false;
      r.releaseStatus.status = DeclarationStatus.DECLARED;
      r.releaseStatus.declarationDate = moment().toISOString();
      return r;
    });
  }

  'com.portbase.bezoekschip.common.api.commercialrelease.CancelCommercialRelease' = (command: CancelCommercialRelease) => {
    this.updateCommercialRelease(command.id, r => {
      r.releaseStatus.cancelled = true;
      r.releaseStatus.status = DeclarationStatus.DECLARED;
      return r;
    });
  }

  'com.portbase.bezoekschip.common.api.accountmanagement.RemoveCustomer' = (command: RemoveCustomer) => {}

  'com.portbase.bezoekschip.common.api.accountmanagement.RegisterCustomer' = (command : RegisterCustomer) => {}

  'com.portbase.bezoekschip.common.api.accountmanagement.VerifyCustomerReference' = (command: VerifyCustomerReference) => {
  }
  'com.portbase.bezoekschip.common.api.accountmanagement.RejectCustomerReference' = (command: RejectCustomerReference) => {
  }
  'com.portbase.bezoekschip.common.api.bunkering.input.InputDeclareBunkering' = (command: InputDeclareBunkering) => {
  }

  'com.portbase.bezoekschip.common.api.admin.EncryptValue' = (command: EncryptValue) =>
    "unencrypted|" + btoa(command.value);

  private updateCommercialRelease(id, patch : (r : CommercialRelease) => CommercialRelease) {
    let commercialRelease = CommercialReleaseMock.find(i => i.id === id);
    let newValue = patch(commercialRelease);
    replaceItem(CommercialReleaseMock, commercialRelease, newValue);
    InjectorProvider.injector.get(EventGateway).publish('commercialReleaseUpdate', newValue);
  }

  private mergeOrgRefdata(entries: any[], command: MergeOrganisationRefdata) {
    const current = entries.find(e => e.id === command.id);
    if (command.delete) {
      removeItem(entries, current);
    } else {
      const value = lodash.assign({}, command.value);
      value.id = command.id;
      replaceItem(entries, current, value);
    }
  }

  private accept(type: DeclarationType): void {
    setTimeout(() => {
      VisitContext.visit.declarations.slice().reverse().find(d => d.type === type).status = DeclarationStatus.ACCEPTED;
      VisitContext.replaceVisit(VisitContext.visit);
    }, 5000);
  }

  private reject(type: DeclarationType, rejectReason: string): void {
    setTimeout(() => {
      const declaration = VisitContext.visit.declarations.slice().reverse().find(d => d.type === type);
      declaration.status = DeclarationStatus.REJECTED;
      declaration.rejectReasons = rejectReason;
      VisitContext.replaceVisit(VisitContext.visit);
    }, 2000);
  }
}
