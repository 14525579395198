import {Component, Input} from '@angular/core';
import {ContainerType, GoodsItem, LoadingResult, Port, SizeType} from '@portbase/bezoekschip-service-typescriptmodels';
import {VisitContext} from "../../visit-context";
import {PortvisitUtils} from "../../../refdata/portvisit-utils";
import {lodash} from "../../../common/utils";
import {LoadingContainerModel} from "../cargo-loading.model";
import {Manifest} from "../../cargo-import/cargo-import.model";

@Component({
  selector: 'app-load-container',
  templateUrl: './load-container.component.html',
  styleUrls: ['./load-container.component.css']
})
export class LoadContainerComponent {
  context = VisitContext;
  utils = PortvisitUtils;
  model: LoadingContainerModel;
  loadingResult: LoadingResult;
  manifest: Manifest;
  @Input() goodsItem: GoodsItem;

  @Input() set container(container: LoadingContainerModel) {
    this.model = container;
    this.loadingResult = VisitContext.cargoLoadingModel.loadings[container.number] || <any>{};
  }

  changeContainerNumber($event) {
    this.model.number = $event.target.value.toUpperCase().trim();
    this.model.shippersOwned = this.model.number && !PortvisitUtils.isContainerNumber(this.model.number);
  }

  getSizeType(containerType: ContainerType): SizeType {
    switch (containerType) {
      case 'CHASSIS':
        return {
          "code": "99U7",
          "name": "Chassis"
        };
      case 'SWAP_BODY':
        return {
          "code": "99U8",
          "name": "Swap body"
        };
      case 'TRAILER':
        return {
          "code": "99U9",
          "name": "Trailer"
        };
    }
    return null;
  }

  getPreviousPorts(): Port[] {
    return lodash.uniqBy(VisitContext.savedVisit.visitDeclaration.previousPorts.map(p => p.port), p => p.locationUnCode);
  }
}
