import {Component, Input} from '@angular/core';
import {TaskMessageStatus} from "@portbase/bezoekschip-service-typescriptmodels";

@Component({
  selector: 'app-task-message-status-icon',
  templateUrl: './task-message-status-icon.component.html',
  styleUrls: ['./task-message-status-icon.component.scss']
})
export class TaskMessageStatusIconComponent {
  @Input() messageStatus: TaskMessageStatus;
}
