<!--Good details-->
<div class="row" *ngIf="stowage.good as good">
  <div class="declaration-block col-12">
    <h2>Good details</h2>
  </div>

  <div class="col-md-6">
    <app-form-group>
      <label>Name</label>
      <textarea class="form-control" readonly>{{stowage.type === 'container' ? refData.containerGoodDataFormatterFull(good.goodData) : refData.goodDataFormatter(good.goodData)}}</textarea>
    </app-form-group>

    <app-form-group *ngIf="good.type === 'chemical' || good.type === 'gas' || good.type === 'oil' || good.type === 'containerGood'">
      <label>Flashpoint (&deg;C)</label>
      <input [(ngModel)]="good.flashPoint" appDecimalNumber class="form-control">
    </app-form-group>

    <app-form-group *ngIf="good.type === 'containerGood'">
      <label>Segregation Information</label>
      <input [(ngModel)]="good.segregationInformation" type="text" maxlength="350" class="form-control">
    </app-form-group>

    <div *ngIf="good.type === 'chemical'">
      <app-form-group *ngIf="good.goodData.specOpRequirements?.indexOf('16.2.9') >= 0">
        <label>Melting point (&deg;C)</label>
        <input [(ngModel)]="good.meltingPoint" appDecimalNumber class="form-control">
      </app-form-group>
      <app-form-group *ngIf="good.goodData.specOpRequirements?.indexOf('16.2.9') >= 0 || good.goodData.specOpRequirements?.indexOf('16.2.6') >= 0">
        <label>Heating order (&deg;C)</label>
        <input [(ngModel)]="good.heatingOrder" appDecimalNumber maxlength="100" class="form-control">
      </app-form-group>
    </div>
  </div>

  <div class="col-md-6">
    <div *ngIf="good.type === 'chemical'">
      <app-form-group *ngIf="good.goodData.specOpRequirements?.indexOf('16.2.6') >= 0">
        <label>Viscosity (mPa/s at 20&deg;C)</label>
        <input [(ngModel)]="good.viscosity" appDecimalNumber class="form-control">
      </app-form-group>
      <app-form-group *ngIf="good.viscosity > 50">
        <label>Critical temperature (&deg;C)</label>
        <input [(ngModel)]="good.criticalTemperature" appDecimalNumber maxlength="100" class="form-control" required>
      </app-form-group>
    </div>
    <app-form-group>
      <label>Remarks</label>
      <textarea [(ngModel)]="good.remarks" type="text" maxlength="350" class="form-control"></textarea>
    </app-form-group>
    <div *ngIf="good && good.goodData && good.goodData.goodCode === '500029'">
      <app-form-group>
        <label>Crude oil washing</label>
        <app-yes-no required [(ngModel)]="good.crudeOilWashing"></app-yes-no>
      </app-form-group>
    </div>
  </div>
</div>

<!-- radioactivity -->
<div class="row" *ngIf="(stowage.good && stowage.good['radioactive'] && stowage.good['radioactivity']) as material">
  <div class="declaration-block col-12">
    <h2>Radioactivity details</h2>
  </div>

  <div class="col-md-6">
    <app-form-group>
      <label>Radionuclide</label>
      <app-search [(ngModel)]="material.radionuclide" [searchFunction]="refData.findRadionuclide"
                  dataKey="name" required placeholder="Find radionuclide"></app-search>
    </app-form-group>
    <app-form-group>
      <label>Package category</label>
      <app-select [(ngModel)]="material.packageCategory" [options]="refData.packageCategories"
                  [formatter]="refData.packageCategoryFormatter"></app-select>
    </app-form-group>
    <app-form-group>
      <label>Identification</label>
      <input [(ngModel)]="material.identification" class="form-control">
    </app-form-group>
    <app-form-group>
      <label>Level (Bq)</label>
      <input [(ngModel)]="material.level" appDecimalNumber maxDecimals="0" min="1" title="Must be positive (>0)" class="form-control">
    </app-form-group>
  </div>
  <div class="col-md-6">
    <app-form-group>
      <label>Transport index</label>
      <app-select [(ngModel)]="material.transportIndex" [options]="refData.transportIndexes"
                  [formatter]="refData.transportIndexFormatter"></app-select>
    </app-form-group>
    <app-form-group>
      <label>Critical safety index</label>
      <input [(ngModel)]="material.criticalSafetyIndex" appDecimalNumber maxDecimals="2" class="form-control">
    </app-form-group>
    <app-form-group>
      <label>License number</label>
      <input [(ngModel)]="material.licenseNumber" class="form-control">
    </app-form-group>
    <app-form-group>
      <label>Remarks</label>
      <textarea [(ngModel)]="material.remarks" type="text" maxlength="100" class="form-control"></textarea>
    </app-form-group>
  </div>
</div>

<!-- Container item details -->
<div class="row" *ngIf="stowage.type === 'container' || stowage.type === 'breakBulk'">
  <div class="declaration-block col-12">
    <h2>Stowage details</h2>
  </div>
  <div class="col-md">
    <app-form-group *ngIf="!stowage.trailer && stowage.type !== 'breakBulk' && !isContainerItem">
      <label>Unclean tank container</label>
      <app-yes-no [(ngModel)]="stowage.uncleanTankContainer" (ngModelChange)="toggleOption(stowage)" required></app-yes-no>
    </app-form-group>
    <ng-container *ngIf="!stowage.uncleanTankContainer">
      <app-form-group>
        <label>Number of outer packages</label>
        <input [(ngModel)]="stowage.numberOfOuterPackages" appDecimalNumber [maxDecimals]="0" [min]="1"
               class="form-control" [required]="!stowage.uncleanTankContainer">
      </app-form-group>
      <app-form-group>
        <label>Outer package type</label>
        <app-select [(ngModel)]="stowage.outerPackageType" dataKey="code"
                    [optionsProvider]="refData.getPackageTypes"
                    [formatter]="refData.packageTypeFormatter"></app-select>
      </app-form-group>
      <app-form-group>
        <label>Number of inner packages</label>
        <input [(ngModel)]="stowage.numberOfInnerPackages" appDecimalNumber [maxDecimals]="0" [min]="1"
               class="form-control">
      </app-form-group>
      <app-form-group *ngIf="stowage.numberOfInnerPackages > 0">
        <label>Inner package type</label>
        <app-select [(ngModel)]="stowage.innerPackageType" dataKey="code"
                    [optionsProvider]="refData.getPackageTypes"
                    [formatter]="refData.packageTypeFormatter"></app-select>
      </app-form-group>
    </ng-container>
  </div>

  <div class="col-md">
    <ng-container *ngIf="!stowage.uncleanTankContainer">
      <app-form-group>
        <label>Net weight (kg)</label>
        <input [(ngModel)]="stowage.netWeight" appDecimalNumber [maxDecimals]="3" class="form-control">
      </app-form-group>
      <app-form-group>
        <label>Net Explosive Mass (kg)</label>
        <input [(ngModel)]="stowage.netExplosiveMass" appDecimalNumber maxlength="10" class="form-control">
      </app-form-group>
      <app-form-group>
        <label>Transport in limited quantity</label>
        <app-yes-no [(ngModel)]="stowage.transportInLimitedQuantity" required></app-yes-no>
      </app-form-group>
    </ng-container>
  </div>
</div>

<!-- fumigation -->
<div class="row" *ngIf="stowage.type === 'hold'">
  <div class="declaration-block col-12">
    <h2>Fumigation details</h2>
  </div>

  <div class="col-md-6">
    <app-form-group>
      <label>Cargo Fumigated</label>
      <app-yes-no required [(ngModel)]="stowage.fumigated"
                  (ngModelChange)="stowage.fumigation = stowage.fumigated ? {} : null"></app-yes-no>
    </app-form-group>
    <div *ngIf="stowage.fumigated">
      <app-form-group>
        <label for="fumigant">Fumigant</label>
        <app-select id="fumigant" [options]="refData.fumigants" required
                    [formatter]="refData.fumigantFormatter"
                    [(ngModel)]="stowage.fumigation.fumigant"></app-select>
      </app-form-group>
      <app-form-group>
        <label>Concentration (ppm)</label>
        <input type="number" [(ngModel)]="stowage.fumigation.amount" class="form-control">
      </app-form-group>
      <app-form-group>
        <label for="ventilated">Ventilated</label>
        <app-yes-no id="ventilated" [(ngModel)]="stowage.fumigation.ventilated"></app-yes-no>
      </app-form-group>
    </div>
  </div>
  <div class="col-md-6" *ngIf="stowage.fumigated">
    <app-form-group>
      <label for="measurementEquipment">Measurement equipment</label>
      <app-yes-no id="measurementEquipment" [(ngModel)]="stowage.fumigation.measurementEquipment"></app-yes-no>
    </app-form-group>
    <app-form-group>
      <label for="fumigationDate">Date of fumigation</label>
      <app-date-field id="fumigationDate" [(ngModel)]="stowage.fumigation.fumigationTimestamp"></app-date-field>
    </app-form-group>
    <app-form-group>
      <label>Place of Fumigation</label>
      <input type="text" [(ngModel)]="stowage.fumigation.placeOfFumigation" class="form-control">
    </app-form-group>
  </div>
</div>
<!--<pre>{{stowage | json}}</pre>-->
