<div class="row sticky-top bg-white" style="top:6.125rem">

  <div class="col-md-12">
    <app-alerting></app-alerting>
  </div>

  <div class="col-md-12">
    <app-action-nav-bar></app-action-nav-bar>
  </div>
</div>

<div class="row g-0 mt-3 mb-5 fieldset"
     [ngClass]="{'always-enabled' : !context.savedVisit.cancelled && (appContext.isAdmin() || appContext.isCargoImportEditor()),
     'disabled' : !(appContext.isAdmin() || appContext.isCargoImportEditor())}">
  <div class="col">
    <ng-container *ngIf="context.cargoImportModel">

      <app-tab-panel>
        <app-tab-item [templateRef]="customsDeclarationRef">
          <span class="tab-item">Customs declaration</span>
          <ng-template #customsDeclarationRef>
            <app-import-declaration [filterTerm]="filterTerm" class="d-block mt-4"></app-import-declaration>
          </ng-template>
        </app-tab-item>
        <app-tab-item [templateRef]="dischargeListRef">
          <span class="tab-item">Discharge list</span>
          <ng-template #dischargeListRef>
            <app-discharge-list class="d-block mt-4"></app-discharge-list>
          </ng-template>
        </app-tab-item>
        <app-tab-item id="address-book-tab" *ngIf="context.cargoImportModel?.isEditor()" [templateRef]="addressBookRef">
          <span class="tab-item d-none d-md-inline">Address book</span>
          <ng-template #addressBookRef>
            <div class="d-block">
              <app-address-book></app-address-book>
            </div>
          </ng-template>
        </app-tab-item>
        <app-tab-item id="licenses-tab" *ngIf="context.cargoImportModel?.isEditor()" [templateRef]="licenseBookRef">
          <span class="tab-item d-none d-md-inline">Warehouse licenses</span>
          <ng-template #licenseBookRef>
            <div class="d-block">
              <app-license-book></app-license-book>
            </div>
          </ng-template>
        </app-tab-item>
      </app-tab-panel>
    </ng-container>
  </div>
</div>

<div *ngIf="!context.cargoImportModel" class="text-muted text-center" style="margin-top: 50px">
  Please add cargo declarants to this visit.
</div>
