<div class="row">
  <div class="col-md">
    <app-form-group>
      <label>B/L number</label>
      <input class="form-control" placeholder="Enter consignment number" required
             [(ngModel)]="consignment.consignmentNumber" [disabled]="consignment.declared && !appContext.isAdmin()"
             (change)="consignment.consignmentNumber=$event.target.value.toUpperCase()">
    </app-form-group>

    <app-form-group label="Movement type">
      <app-select [(ngModel)]="consignment.movementType" [options]="refData.movementTypes"
                  [formatter]="refData.enumFormatter"></app-select>
    </app-form-group>

    <app-form-group label="Customs status">
      <app-select [(ngModel)]="consignment.customsStatus" [options]="refData.customsStatuses"
                  [formatter]="refData.customsStatusFormatter"></app-select>
    </app-form-group>

    <app-form-group label="Customs process">
      <app-select [(ngModel)]="consignment.customsProcess" [options]="refData.customsProcesses"
                  [formatter]="refData.customsProcessFormatter"></app-select>
    </app-form-group>

    <app-form-group label="MRNs">
      <app-tags [(ngModel)]="consignment.movementReferenceNumbers" [itemTemplate]="mrn"
                placeholder="Add number"></app-tags>
    </app-form-group>

    <app-form-group label="Exchange rate" [disableInvalidFeedback]="true">
      <div class="input-group">
        <div class="me-1">
          <app-select [ngModel]="consignment.exchangeRate?.currency" [nullOption]="true"
                      (ngModelChange)="consignment.exchangeRate = (consignment.exchangeRate || $any({})); consignment.exchangeRate.currency = $event"
                      dataKey="unCode" [required]="!!consignment.exchangeRate?.rate"
                      [optionsProvider]="refData.getCurrencies"
                      [formatter]="refData.currenciesFormatter" [selectedFormatter]="refData.compactCurrenciesFormatter"
                      style="max-width: 5rem">
          </app-select>
        </div>
        <input [ngModel]="consignment.exchangeRate?.rate" placeholder="Enter rate for €1"
               (ngModelChange)="consignment.exchangeRate = (consignment.exchangeRate || $any({})); consignment.exchangeRate.rate = $event"
               [required]="!!consignment.exchangeRate?.currency"
               appDecimalNumber [maxDecimals]="6" class="form-control rounded">
      </div>
    </app-form-group>
  </div>

  <div class="col-md">
    <div class="row">
      <div class="col-md">
        <app-form-group>
          <label>Port of loading</label>
          <app-select required [(ngModel)]="consignment.portOfLoading"
                      [options]="getPreviousPorts()" [formatter]="refData.portFormatter" dataKey="name"></app-select>
        </app-form-group>
        <app-form-group>
          <label>Departure</label>
          <app-date-field required [(ngModel)]="consignment.actualDeparture"
                         (ngModelChange)="manifest.setActualDeparture($event)"></app-date-field>
        </app-form-group>
        <app-form-group>
          <label>Port of discharge</label>
          <app-select required [(ngModel)]="consignment.portOfDischarge"
                      [disabled]="!context.savedVisit.visitDeclaration.etaFirstEntryEu"
                      [options]="getDischargePorts()"
                      [formatter]="refData.portFormatter" dataKey="name"></app-select>
        </app-form-group>
        <app-form-group label="Terminal"
          *ngIf="context.savedVisit.portOfCall.port.locationUnCode === manifest.portOfDischarge.locationUnCode
                  && context.savedVisit.visitDeclaration.portVisit.berthVisits.length!==0">
          <app-select required [(ngModel)]="consignment.terminal"
                      [autoSelectSingleOption]="!consignment.consignmentNumber"
                      (ngModelChange)="$event ? setTerminal($event) : null"
                      [formatter]="refData.terminalFormatter" [optionsProvider]="refData.getVisitTerminals()"
                      placeholder="– Select a terminal –" dataKey="terminalCode"></app-select>
        </app-form-group>
        <app-form-group label="Place of origin">
          <app-search [(ngModel)]="consignment.placeOfOrigin" [inputFormatter]="refData.locationFormatter"
                      [searchFunction]="refData.findLocation"></app-search>
        </app-form-group>
        <app-form-group label="Place of destination">
          <app-search [(ngModel)]="consignment.placeOfDestination" [inputFormatter]="refData.locationFormatter"
                      [searchFunction]="refData.findLocation"></app-search>
        </app-form-group>
        <app-form-group label="Place of delivery">
          <input class="form-control" [(ngModel)]="consignment.placeOfDelivery">
        </app-form-group>
      </div>
    </div>
  </div>
</div>

<div class="declaration-block my-4">
  <h2>Parties</h2>
</div>
<div class="row">
  <div class="col-md">
    <app-form-group label="Shipper">
      <app-search [(ngModel)]="consignment.consignor"
                  required [minCharacters]="0" [newValueTemplate]="newParty" valueType="party"
                  [searchFunction]="refData.findParties" [inputFormatter]="refData.partyFormatter">
      </app-search>
    </app-form-group>

    <app-form-group>
      <app-info class="formGroupLabel">
        <label>Consignee</label>
        <div class="tooltipContent">If left empty, "to order" code 10600 is sent to customs</div>
      </app-info>
      <app-search [(ngModel)]="consignment.consignee"
                  [minCharacters]="0" [newValueTemplate]="newParty" valueType="party"
                  [searchFunction]="refData.findParties" [inputFormatter]="refData.partyFormatter">
      </app-search>
    </app-form-group>
  </div>
  <div class="col-md">
    <app-form-group label="Notify">
      <ng-container *ngFor="let party of consignment.partiesToNotify; let index = index; let last = last">
        <app-search class="mb-2" [ngModel]="party"
                    (ngModelChange)="$event ? consignment.partiesToNotify[index] = $event : consignment.partiesToNotify.splice(index, 1)"
                    [minCharacters]="0" [newValueTemplate]="newParty" valueType="party"
                    [searchFunction]="refData.findParties" [inputFormatter]="refData.partyFormatter">
        </app-search>
        <ng-container *ngIf="last">
          <app-search [ngModel]="null" placeholder="Add other"
                      (ngModelChange)="($event ? consignment.partiesToNotify.push($event) : null);"
                      [minCharacters]="0" [newValueTemplate]="newParty" valueType="party"
                      [searchFunction]="refData.findParties" [inputFormatter]="refData.partyFormatter">
          </app-search>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="consignment.partiesToNotify.length === 0">
        <app-search [ngModel]="null" [placeholder]="consignment.partiesToNotify.length === 0 ? '' : 'Add other'"
                    (ngModelChange)="($event ? consignment.partiesToNotify.push($event) : null);"
                    [minCharacters]="0" [newValueTemplate]="newParty" valueType="party"
                    [searchFunction]="refData.findParties" [inputFormatter]="refData.partyFormatter">
        </app-search>
      </ng-container>
    </app-form-group>

    <ng-container>
      <app-form-group label="Warehouse license">
        <app-search [(ngModel)]="consignment.warehouseLicense" [minCharacters]="0"
                    [newValueTemplate]="editWarehouseLicense" valueType="warehouse license"
                    [inputFormatter]="refData.warehouseLicenseFormatter"
                    [searchFunction]="refData.findWarehouseLicenses"></app-search>
      </app-form-group>
      <ng-template #editWarehouseLicense let-model="model"><app-warehouse-license [model]="model"></app-warehouse-license></ng-template>
    </ng-container>

    <app-form-group>
      <app-info class="formGroupLabel">
        <label>Authorisations</label>
        <div class="tooltipContent">Authorise organisations like surveyors to access the Discharge Information of this B/L (if bulk)</div>
      </app-info>
      <ng-container *ngFor="let party of consignment.bulkAuthorisations; let index = index; let last = last">
        <app-search class="mb-2" [ngModel]="party"
                    (ngModelChange)="$event ? consignment.bulkAuthorisations[index] = $event : consignment.bulkAuthorisations.splice(index, 1)"
                    [searchFunction]="refData.findBulkAuthorisations" [inputFormatter]="refData.bulkAuthorisationFormatter">
        </app-search>
        <ng-container *ngIf="last">
          <app-search [ngModel]="null" placeholder="Add other"
                      (ngModelChange)="($event ? consignment.bulkAuthorisations.push($event) : null);"
                      [searchFunction]="refData.findBulkAuthorisations" [inputFormatter]="refData.bulkAuthorisationFormatter">
          </app-search>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="consignment.bulkAuthorisations.length === 0">
        <app-search [ngModel]="null" [placeholder]="consignment.bulkAuthorisations.length === 0 ? '' : 'Add other'"
                    (ngModelChange)="($event ? consignment.bulkAuthorisations.push($event) : null);"
                    [searchFunction]="refData.findBulkAuthorisations" [inputFormatter]="refData.bulkAuthorisationFormatter">
        </app-search>
      </ng-container>
    </app-form-group>
  </div>
</div>

<div class="declaration-block my-4">
  <h2>On carriage details</h2>
</div>
<app-on-carriage-details [(ngModel)]="consignment.onCarriageDetails"></app-on-carriage-details>

<ng-template #newParty let-party="model"><app-party [model]="party"></app-party></ng-template>
<ng-template #mrn let-item="item"><span>{{item}}</span></ng-template>
