<div class="row">
  <div class="col-md-6">
    <app-form-group><label>Id</label><input class="form-control" [(ngModel)]="license.id" disabled required>
    </app-form-group>
    <app-form-group><label>Prt UN Code</label><input class="form-control" [(ngModel)]="license.portUnCode"
                                                     required>
    </app-form-group>
    <app-form-group><label>Organisation Short Name</label><input class="form-control"
                                                                 [(ngModel)]="license.organisationShortName"
                                                                 required>
    </app-form-group>
    <app-form-group><label>Vessel ENI</label><input class="form-control" [(ngModel)]="license.bunkerVessel.eni">
    </app-form-group>
    <app-form-group><label>Vessel IMO</label><input class="form-control" [(ngModel)]="license.bunkerVessel.imo">
    </app-form-group>
    <app-form-group><label>Vessel Name</label><input class="form-control" [(ngModel)]="license.bunkerVessel.name"
                                                     required>
    </app-form-group>
    <div>It is required to fill in either ENI or IMO.</div>
  </div>
</div>
