import {Component} from '@angular/core';
import {VisitContext} from '../../../visit-context';
import {sendCommand} from '../../../../common/utils';
import {DeclarationType, ResendCargoImport, ResubmitCargoImport} from '@portbase/bezoekschip-service-typescriptmodels';
import {AppContext} from '../../../../app-context';
import {DeclarationUtils} from "../../../../refdata/declaration-utils";

@Component({
  selector: 'app-cargo-import-admin',
  templateUrl: './cargo-import-admin.component.html',
  styleUrls: ['./cargo-import-admin.component.css']
})
export class CargoImportAdminComponent {
  context = VisitContext;
  appContext = AppContext;

  enableCargoImport() {
    const declarant = this.context.cargoImportModel.cargoDeclarant.shortName
    const indexOnVisit = this.context.savedVisit.cargoDeclarants.findIndex(c => c.shortName === declarant);
    sendCommand('com.portbase.bezoekschip.common.api.admin.ApplyPatch', {
      crn: this.context.visit.crn,
      patch: [
        {"op": "replace", "path": "/cargoImportEnabled", "value": true},
        {"op": "replace", "path": "/cargoDeclarants/" + indexOnVisit + "/cargoImportEnabled", "value": true}
      ]
    }, () => {
      this.context.visit.cargoImportEnabled = true;
      this.context.visit.cargoDeclarants[indexOnVisit].cargoImportEnabled = true;
      this.context.cargoImportModel.cargoDeclarant.cargoImportEnabled = true;
      this.context.replaceVisit(this.context.visit);
      AppContext.registerSuccess('Cargo import has been enabled on the visit and specific for agent ' + declarant + '.');
    });
  }

  resend() {
    AppContext.clearVisitAlerts();
    sendCommand('com.portbase.bezoekschip.common.api.cargo.ResendCargoImport', <ResendCargoImport>{
      crn: VisitContext.savedVisit.crn,
      cargoDeclarantShortName: VisitContext.cargoImportModel.cargoDeclarant.shortName
    }, () => AppContext.registerSuccess('The import declarations were resend successfully'))
  }

  resendAllowed() {
    return this.context.cargoImportModel.declarations.length !== 0 && !this.resubmitAllowed();
  }

  resubmit() {
    AppContext.clearVisitAlerts();
    sendCommand('com.portbase.bezoekschip.common.api.cargo.ResubmitCargoImport', <ResubmitCargoImport>{
      crn: VisitContext.savedVisit.crn,
      cargoDeclarantShortName: VisitContext.cargoImportModel.cargoDeclarant.shortName
    }, () => AppContext.registerSuccess('The import declarations were resubmitted successfully'))
  }

  resubmitAllowed() {
    return this.context.cargoImportModel.manifests.some(m => (m.consignments.length !== 0 || m.containers.length !== 0)
      && m.declarations &&
      (m.declarations.some(d => d && d.status === 'DECLARED' && d.type == 'ENS') ||
        (m.declarations.some(d => d && d.status === 'DECLARED' && d.type == 'SDT')
          && !DeclarationUtils.getLastDeclarations(m.declarations, [DeclarationType.ENS])
            .some(d => d && d.status === 'REJECTED'))));
  }
}
