import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  BezoekschipOrganisation,
  DeleteTransitGuarantee,
  FindOrganisations,
  Guarantee,
  Money
} from '@portbase/bezoekschip-service-typescriptmodels';
import {QueryGateway} from "../../common/query-gateway";
import {AppContext} from "../../app-context";
import {removeItem, sendCommand, sendQuery, toWebsocketUrl, uuid} from "../../common/utils";
import {environment} from "../../../environments/environment";
import {PortvisitUtils} from "../../refdata/portvisit-utils";
import lodash from "lodash";
import {EventGateway} from '../../common/event-gateway';

@Component({
  selector: 'app-transit-guarantees',
  templateUrl: './transit-guarantees.component.html',
  styleUrls: ['./transit-guarantees.component.css']
})
export class TransitGuaranteesComponent implements OnInit, OnDestroy {
  formatter = PortvisitUtils.agentFormatter;
  refData = PortvisitUtils;

  appContext = AppContext;
  guarantees: Guarantee[] = [];
  socket: WebSocket;

  filterOrganisationShortName: string = '';

  constructor(private queryGateway: QueryGateway, private eventGateway: EventGateway) {
    this.eventGateway.registerLocalHandler(this);
  }

  ngOnInit(): void {
    this.reload();
    this.subscribeToGuaranteeUpdates();
  }

  searchOrganisation = term => sendQuery('com.portbase.bezoekschip.common.api.authorisation.FindOrganisations',
    <FindOrganisations>{term: term});

  setOrganisationName = (organisation: BezoekschipOrganisation) => {
    this.filterOrganisationShortName = organisation ? organisation.shortName : null;
    this.reload();
  };

  ngOnDestroy(): void {
    try {
      this.socket.onclose = () => {
      };
      this.socket.close();
      this.socket = null;
    } catch (ignored) {
    }
  }

  add() {
    this.guarantees.splice(0, 0, <any>{
      id: uuid(),
      ownerShortName: AppContext.userProfile.organisation?.shortName,
      guaranteeData: {},
      new: true,
    });
  }

  remove(value: Guarantee) {
    return () => {
      if (value['new']) {
        removeItem(this.guarantees, value);
        return;
      }
      sendCommand('com.portbase.bezoekschip.common.api.transit.DeleteTransitGuarantee', <DeleteTransitGuarantee>{
        id: value.id
      }, () => {
        AppContext.registerSuccess("Guarantee was deleted successfully");
        removeItem(this.guarantees, value);
        this.queryGateway.removeFromCache("com.portbase.bezoekschip.common.api.transit.FindGuarantees");
      });
    }
  }

  badgeStatus(value: Guarantee) {
    const declaration = value.status;
    if (!declaration) {
      return null;
    } else if (declaration === 'ACCEPTED') {
      return {text: "Balance Received", color: "success"};
    } else if (declaration === 'REJECTED') {
      return {text: "Balance Rejected", color: "danger"};
    } else if (declaration === 'DECLARED') {
      return {text: "Balance Requested", color: "info"};
    } else {
      return null;
    }
  }

  private subscribeToGuaranteeUpdates = () => {
    if (environment.production || environment.docker) {
      try {
        this.socket = new WebSocket(toWebsocketUrl("/api/ui/transit-guarantees"));
      } catch (e) {
        console.info("Could not open websocket. Retrying every minute...", e);
        setTimeout(this.subscribeToGuaranteeUpdates, 60_000);
        return;
      }
      this.socket.onmessage = (message: MessageEvent) => {
        if (typeof message.data === 'string') {
          this.eventGateway.publish("handleGuaranteeUpdate", JSON.parse(message.data));
        }
      };
      this.socket.onclose = (event: CloseEvent) => {
        if (!event.wasClean) {
          console.warn("Websocket closed with reason: " + event.reason + " (" + event.code + "). Trying to reconnect...");
        }
        setTimeout(() => this.subscribeToGuaranteeUpdates(), 5_000);
      }
    }
  }

  handleGuaranteeUpdate = (g: Guarantee) => {
    this.guarantees.filter(i => i.id === g.id).forEach(i => lodash.merge(i, g));
  };

  reload = () => {
    sendQuery("com.portbase.bezoekschip.common.api.transit.FindGuarantees", {
      ownerShortName: this.filterOrganisationShortName
    }).subscribe(values => this.guarantees = values);
  };

  formatAmount = (amount: Money) => {
    return amount?.amount && amount?.currency ? amount.currency.unCode + ' ' + amount.amount : '';
  };

  trackById(index: number, obj: Guarantee): any {
    return obj.id;
  };
}
